import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import GenericNavbar from 'business/organizer/common/components/GenericNavbar';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import QuestionsSummary from 'business/organizer/assessment/pages/candidate-progress/candidate-detail/tabs/QuestionsSummary';
import Timeline from 'business/organizer/assessment/pages/candidate-progress/candidate-detail/tabs/Timeline';
import Proctoring from 'business/organizer/assessment/pages/candidate-progress/candidate-detail/tabs/Proctoring';

const CandidateDetailTabs = () => {
  const { url } = useCurrentUrl();
  const tabs = [
    {
      id: 1,
      label: 'Questions',
      route: `${url}/questions`,
      badge: false,
    },
    {
      id: 1,
      label: 'Timeline',
      route: `${url}/timeline`,
      badge: false,
    },
    {
      id: 2,
      label: 'Proctoring',
      route: `${url}/proctoring`,
      badge: false,
    },
  ];

  return (
    <>
      <Box>
        <GenericNavbar tabs={tabs} />
      </Box>
      <Switch>
        <Route path={tabs[0].route}>
          <QuestionsSummary />
        </Route>
        <Route path={tabs[1].route}>
          <Timeline />
        </Route>
        <Route path={tabs[2].route}>
          <Proctoring />
        </Route>

        <Redirect from={url} to={tabs[0].route} />
      </Switch>
    </>
  );
};

export default CandidateDetailTabs;

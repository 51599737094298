import React from 'react';
import { Switch, Route } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import TestsPage from 'business/organizer/assessment/pages/assessment-tests/TestsPage';
import LibraryPage from 'business/organizer/assessment/pages/LibraryPage';
import LibraryProvider from 'business/organizer/library/contexts/library/LibraryProvider';
import AddQuestionsToTestProvider from 'business/organizer/library/contexts/add-questions-to-test/AddQuestionsToTestProvider';
import DrawerProvider from 'business/organizer/library/contexts/drawer/DrawerProvider';
import GetTestIdProvider from 'business/organizer/assessment/contexts/get-testId/GetTestIdProvider';

const AssessmentPage = () => {
  const { url } = useCurrentUrl();

  return (
    <Switch>
      <GetTestIdProvider>
        <DrawerProvider>
          <>
            <Route path={`${url}/tests/`}>
              <TestsPage />
            </Route>
            <Route path={`${url}/library/`}>
              <LibraryProvider>
                <AddQuestionsToTestProvider>
                  <LibraryPage />
                </AddQuestionsToTestProvider>
              </LibraryProvider>
            </Route>
          </>
        </DrawerProvider>
      </GetTestIdProvider>
    </Switch>
  );
};

export default AssessmentPage;

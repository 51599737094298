import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

const CloseDrawerIcon: React.FC<ICloseDrawerIcon> = ({
  closeQuestionDrawer,
  bgcolor = '#FFFFFF',
  color = '#444444',
}) => {
  return (
    <Box
      position="absolute"
      left="-41px"
      top="8px"
      bgcolor={bgcolor}
      borderRadius="50%"
    >
      <Tooltip title="Close" placement="bottom" arrow>
        <IconButton
          size="small"
          onClick={(event) => closeQuestionDrawer(event)}
        >
          <CloseIcon htmlColor={color} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CloseDrawerIcon;

import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Divider, makeStyles } from '@material-ui/core';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const getTotalScore = (questionsSummary: any): number | undefined => {
  const allQuestionScores = questionsSummary
    ?.map((question: any) => question.submission?.score)
    .filter((score: number) => typeof score === 'number');

  if (!!allQuestionScores?.length) {
    return allQuestionScores.reduce(
      (prev: number, next: number) => prev + next
    );
  }
};

const CandidateTestDetails: React.FC<{
  candidateDetails: any;
  questionsSummary: any;
}> = ({ candidateDetails, questionsSummary }) => {
  const classes = useStyles();

  const candidateData = useMemo(
    () => [
      {
        id: 1,
        label: 'Test start time',
        value: `${dayjs(candidateDetails?.test_start_time)
          .tz(dayjs.tz.guess())
          .format("DD MMM[']YY HH[:]mm")}`,
      },
      {
        id: 2,
        label: 'Invitation date & time',
        value: `${dayjs(candidateDetails?.invitation_datetime)
          .tz(dayjs.tz.guess())
          .format("DD MMM[']YY HH[:]mm")}`,
      },
      {
        id: 3,
        label: 'Total Score',
        value: `${
          // Derive the score from questions score
          getTotalScore(questionsSummary) || 0
        }/${candidateDetails?.total_score}`,
      },
      {
        id: 4,
        label: 'Duration of test',
        value: `${candidateDetails?.assessment_duration} `,
      },
    ],
    [candidateDetails, questionsSummary]
  );

  return (
    <>
      <Container maxWidth={false} className={classes.container__root}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={4}
        >
          <Box>
            <Box>
              <Typography variant="h3">
                {candidateDetails?.name}
              </Typography>
            </Box>
          </Box>
          <Box></Box>
        </Box>
      </Container>
      <Divider />
      <Container maxWidth={false}>
        <Box py={4}>
          <Box
            bgcolor="#FFF"
            p={4}
            boxShadow="0px 3px 6px #DDE6ED"
            borderRadius={5}
          >
            <Box pb={3}>
              <Typography variant="h6">
                Test Details Summary
              </Typography>
            </Box>
            <Grid container style={{ gridRowGap: '16px' }}>
              {candidateData.map((candidate, idx) => (
                <Grid key={idx} item xs={3}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridRowGap={4}
                  >
                    <Typography variant="body2" color="textSecondary">
                      {candidate.label}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      {candidate.value}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default CandidateTestDetails;
const useStyles = makeStyles((theme) => ({
  container__root: {
    background: '#f1f6f9',
  },
}));

import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import Box from '@material-ui/core/Box';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import QuestionsEmptyPlaceholder from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/QuestionsEmptyPlaceholder';
import HttpError from 'common/components/http-error/HttpError';
import useTestQuestions from 'business/organizer/assessment/contexts/test-questions/useTestQuestions';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import QuestionList from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/QuestionList';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import { filterQuestions } from 'business/organizer/common/utils/filterQuestions';
import QuestionListSkeleton from 'business/organizer/library/components/question-list/skeletons/QuestionList';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import Container from '@material-ui/core/Container/Container';
import { QuestionType } from 'business/organizer/library/utils/utils';
import useOnHoverDropdown from 'common/components/onHover-dropdown/useOnHoverDropdown';
import CreateCustomQuestion from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/drawers/CreateCustomQuestion';
import LibraryDrawer from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/drawers/LibraryDrawer';

const organizerService = new OrganizerService();

const Questions = () => {
  const { url } = useCurrentUrl();

  const {
    testQuestions,
    setTestQuestions,
    setTestQuestionsCount,
    setTestQuestionTypes,
    hiddenQuestionResponse,
    setHiddenQuestionResponse,
    isQuestionDeleted,
  } = useTestQuestions();
  const { testParams, reload, setReload } = useTestDetail();
  const [error, setError] = useState<AxiosError | null>(null);
  const { addQuestionToTestResponse } = useAddQuestionsToTest();

  const {
    anchorEl,
    handleClick,
    handleClose,
    handleDropdownMouseEnter,
    closeDropdownWithDelay,
  } = useOnHoverDropdown();

  useEffect(() => {
    let mounted = true;
    organizerService
      .authGet(`/api/assessments/${testParams.testId}/questions/`)
      .then((response) => {
        if (mounted) {
          const { results } = response?.data;
          setTestQuestions(results);
          setTestQuestionsCount(
            results.filter(
              (result: any) => result.is_hidden === false
            ).length
          );
          setTestQuestionTypes({
            coding: filterQuestions(results, QuestionType.CODING),
            challenge: filterQuestions(
              results,
              QuestionType.CHALLENGE
            ),
            mcq: filterQuestions(results, QuestionType.MCQ),
            freeText: filterQuestions(
              results,
              QuestionType.FREE_TEXT
            ),
            fileUpload: filterQuestions(
              results,
              QuestionType.FILE_UPLOAD
            ),
            // project: filterQuestions(results, QuestionType.PROJECT),
            sql: filterQuestions(results, QuestionType.SQL),
          });
          if (hiddenQuestionResponse) {
            setReload(!reload);
            setHiddenQuestionResponse(null);
          }
        }
      })
      .catch((error) => setError(error));

    return () => {
      mounted = false;
    };
  }, [
    setTestQuestionTypes,
    setTestQuestions,
    setTestQuestionsCount,
    testParams.testId,
    addQuestionToTestResponse,
    hiddenQuestionResponse,
    isQuestionDeleted,
    setReload,
    reload,
    setHiddenQuestionResponse,
  ]);

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Questions', route: url }]}
      />
      {error !== null ? (
        <HttpError
          axiosError={error}
          message="Not able to load questions. Please try again."
        />
      ) : (
        <>
          <Box display="flex" width="100%" pt={8}>
            {testQuestions === null ? (
              <Container maxWidth={false}>
                <Box width="75%">
                  <QuestionListSkeleton />
                </Box>
              </Container>
            ) : (
              <>
                {testQuestions !== null &&
                testQuestions.length === 0 ? (
                  <QuestionsEmptyPlaceholder
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    closeDropdownWithDelay={closeDropdownWithDelay}
                  />
                ) : (
                  <QuestionList
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    closeDropdownWithDelay={closeDropdownWithDelay}
                  />
                )}
                {/* we are rendering the library and question drawer here because 
                    we want to use the same drawer even if the position of buttons  
                    which invokes these drawers change.
                  */}
                <LibraryDrawer />
                <CreateCustomQuestion
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  MenuListProps={{
                    onMouseEnter: handleDropdownMouseEnter,
                    onMouseLeave: handleClose,
                  }}
                />
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Questions;

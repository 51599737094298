import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CandidateListNavbarStyles from 'business/organizer/assessment/components/candidate-progress/candidate-list/styles/CandidateListNavbarStyles';
import DownloadCandidateList from 'business/organizer/assessment/components/candidate-progress/candidate-list/DownloadCandidateList';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useCandidateProgressList from 'business/organizer/assessment/components/candidate-progress/candidate-list/custom-hooks/useCandidateProgressList';
import TablePaginationBoxStyles from 'business/organizer/assessment/components/candidate-progress/common/TablePaginationBoxStyles';
import TableBase from 'common/components/table/TableBase';
import TableSortCell from 'common/components/table/TableSortCell';
import HttpError from 'common/components/http-error/HttpError';
import TimeCell from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/TimeCell';
import TableFilterCell from 'common/components/table/TableFilterCell';
import { testStatuses } from 'business/organizer/assessment/components/candidate-progress/candidate-list/utils';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import Actions from 'business/organizer/assessment/components/candidate-progress/candidate-list/all-candidates/Actions';
import { sliceString } from 'common/utils/sliceString';
import TypographyCell from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/TypographyCell';
import QuestionsAttemptedBar from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/QuestionsAttemptedBar';
import ShowAppliedParams from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/ShowAppliedParams';
import { toTitleSnakeCase } from 'common/utils/toTitleCase';

const AllCandidates = () => {
  const { testDetail } = useTestDetail();
  const {
    data: candidates,
    error,
    params,
    setParams,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSort,
    reset,
  } = useCandidateProgressList<IInvitesProps>({
    apiUrl: testDetail?.invite_url as string,
    score: 1,
  });

  const { setHasChanges } = useCandidateProgress();

  useEffect(() => {
    return () => {
      reset();
      setHasChanges(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilters = (
    event: React.ChangeEvent<HTMLInputElement>,
    state: 'test_status' | 'status'
  ) => {
    const { value } = event.target;
    reset();
    setHasChanges(true);
    setParams((params) => ({
      ...params,
      [state]: params[state]?.includes(value)
        ? params[state]?.filter((filter) => filter !== value)
        : [...(params[state] || []), value],
    }));
  };

  if (error !== null) {
    return (
      <Box py={12}>
        <HttpError axiosError={error} />
      </Box>
    );
  }

  return (
    <>
      <Box py={4}>
        <CandidateListNavbarStyles>
          <Box display="flex" justifyContent="flex-end">
            <DownloadCandidateList
              link={`${
                testDetail?.candidate_download_url
              }?status=${params.status?.join(
                ','
              )}&test_status=${params.test_status?.join(',')}`}
            />
          </Box>
        </CandidateListNavbarStyles>
      </Box>
      <ShowAppliedParams
        order={params.order}
        order_by={params.order_by || 'invitation_sent_on'}
        test_status={params.test_status}
      />
      <TableBase tableProps={{ 'aria-label': 'all-candidates' }}>
        <TableHead>
          <TableRow>
            <TableCell>Candidate</TableCell>
            <TableFilterCell
              title="Test Status"
              onFilter={(event) =>
                handleFilters(event, 'test_status')
              }
              items={testStatuses}
              value={params.test_status as string[]}
            />
            <TableSortCell
              label="Score"
              align="center"
              onOrderBy={(order: IOrder) =>
                handleSort('total_score', order)
              }
              isActive={params.order_by === 'total_score'}
            />
            <TableSortCell
              label="Questions Attempted"
              align="center"
              onOrderBy={(order: IOrder) =>
                handleSort('total_attempted_questions', order)
              }
              isActive={
                params.order_by === 'total_attempted_questions'
              }
            />
            <TableSortCell
              label="Invite sent on"
              align="center"
              onOrderBy={(order: IOrder) =>
                handleSort('invitation_sent_on', order)
              }
              isActive={
                params.order_by === 'invitation_sent_on' ||
                !params.order_by
              }
            />
            <TableCell>Report</TableCell>
          </TableRow>
        </TableHead>
        {candidates !== null && (
          <TableBody>
            {candidates?.results.map(
              ({
                id,
                first_name,
                last_name,
                email,
                total_score,
                total_attempted_questions,
                total_questions,
                total_questions_score,
                invitation_sent_on,
                test_status,
                detail_url,
              }) => (
                <TableRow key={id}>
                  <TypographyCell
                    title={
                      first_name
                        ? sliceString(
                            `${first_name} ${last_name}`,
                            36
                          )
                        : '-'
                    }
                    subtitle={sliceString(email, 24)}
                  />
                  <TableCell align="center">
                    {!!test_status
                      ? toTitleSnakeCase(test_status)
                      : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {!!total_score || total_score === 0
                      ? `${total_score}/${total_questions_score}`
                      : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {test_status &&
                    [
                      'shortlisted',
                      'rejected',
                      'ready_for_review',
                    ].includes(test_status) ? (
                      <QuestionsAttemptedBar
                        total_questions={total_questions as number}
                        total_attempted_questions={
                          total_attempted_questions as number
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TimeCell
                    align="center"
                    date={invitation_sent_on}
                  />
                  <TableCell align="center">
                    <Actions
                      id={id}
                      apiUrl={detail_url}
                      disableView={
                        test_status
                          ? ![
                              'shortlisted',
                              'rejected',
                              'ready_for_review',
                            ].includes(test_status)
                          : true
                      }
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        )}
      </TableBase>
      {candidates === null && <GenericListSkeleton items={4} />}
      {candidates?.results.length === 0 && (
        <Box py={12}>
          <Typography align="center" variant="h5">
            There are no candidates
          </Typography>
        </Box>
      )}
      <Box py={6}>
        <TablePaginationBoxStyles
          count={candidates?.count}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default AllCandidates;

import React from 'react';
import { Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useQuestionPreviewData from 'business/organizer/library/pages/question-preview/useQuestionPreviewData';
import Preview from 'business/organizer/library/components/question-preview/common/Preview';
import InterviewUpdatePage from 'business/organizer/library/pages/question-create-update/interview/InterviewUpdatePage';
import useInterviewQuestion from 'business/organizer/library/contexts/interview-question/useInterviewQuestion';

const InterviewPreview: React.FC<IQuestionPreviewProps> = ({
  questionUrl,
}) => {
  const { url } = useCurrentUrl();

  const { interviewQuestionResponse } = useInterviewQuestion();
  const hasResponse = !!interviewQuestionResponse;
  const { questionItem, responseError } = useQuestionPreviewData(
    questionUrl,
    hasResponse
  );

  return (
    <Preview
      responseError={responseError}
      questionItem={questionItem}
      updatePageRoute={
        <Route path={`${url}/edit/:interviewId`}>
          <InterviewUpdatePage />
        </Route>
      }
    >
      <Box px={6} py={2}>
        {questionItem?.question?.interview_question_type && (
          <Typography variant="h6">
            Interview Question Type:{' '}
            <Typography component="span">
              {questionItem?.question?.interview_question_type.replace(
                '_',
                ' '
              )}
            </Typography>
          </Typography>
        )}
      </Box>
    </Preview>
  );
};

export default InterviewPreview;

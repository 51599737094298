import { useState } from 'react';
import { AxiosError } from 'axios';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box/Box';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

import useAlert from 'common/components/alert-provider/useAlert';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import EditWrapper from 'common/components/edit-wrapper/EditWrapper';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const organizerService = new OrganizerService();
const TestName = () => {
  const { enqueueAlert } = useAlert();
  const classes = useStyles();

  const { testDetail, setTestDetail, setTestDetailError } =
    useTestDetail();
  const [editName, setEditTestName] = useState(false);
  const [name, setName] = useState('');
  const { isViewer } = useHasPermission();

  const handleClick = () => {
    setName(testDetail?.name as string);
    setEditTestName(true);
  };

  const handleNameChange = async () => {
    try {
      const data = {
        name,
      };

      const response = await organizerService.patchTest(
        testDetail?.url as string,
        data
      );

      if (response) {
        setTestDetail(response?.data);
        setEditTestName(false);
        enqueueAlert('Test name updated successfully', {
          alertProps: { severity: 'success' },
        });
      }
    } catch (error) {
      setTestDetailError((error as AxiosError)?.response);
      enqueueAlert('Not able to update the test name.', {
        alertProps: { severity: 'error' },
      });
    }
  };

  return (
    <Box>
      {testDetail === null ? (
        <Box width="40%">
          <GenericListSkeleton
            items={1}
            boxProps={{ height: '25px' }}
          />
        </Box>
      ) : (
        <>
          {editName ? (
            <Box className={classes.box__name}>
              <EditWrapper
                handleClick={handleNameChange}
                handleCancel={() => setEditTestName(false)}
              >
                <TextField
                  variant="outlined"
                  value={name}
                  onChange={(event) => setName(event?.target.value)}
                />
              </EditWrapper>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" gridColumnGap={4}>
              <Typography variant="h5">
                {testDetail?.name.length > 40
                  ? `${testDetail?.name.slice(0, 40)}...`
                  : testDetail?.name}
              </Typography>
              <HasPermission
                isViewer={isViewer}
                tooltipProps={{ placement: 'right', arrow: true }}
              >
                <IconButton onClick={handleClick} disabled={isViewer}>
                  <CreateOutlinedIcon
                    fontSize="inherit"
                    color="primary"
                  />
                </IconButton>
              </HasPermission>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TestName;

const useStyles = makeStyles((theme) => ({
  box__name: {
    '& .MuiOutlinedInput-input': {
      ...theme.typography.h5,
    },
    '& .MuiFormHelperText-root': {
      fontSize: theme.spacing(2),
    },
  },
}));

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const InterviewsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.98737 9.69049H10.2023L11.5027 10.9907C11.7773 11.2654 12.2227 11.2654 12.4973 10.9907L13.7977 9.69049H15.0126C16.2328 9.69049 17.2218 8.70135 17.2218 7.48132V2.20917C17.2218 0.989136 16.2347 0 15.0126 0H8.98737C7.76715 0 6.7782 0.987122 6.7782 2.20917V7.48132C6.7782 8.70135 7.76715 9.69049 8.98737 9.69049ZM9.76025 3.01263H14.2593C14.6587 3.01263 14.9808 3.34589 14.9615 3.74945C14.9438 4.12775 14.618 4.41888 14.2397 4.41888H9.74066C9.34131 4.41888 9.01923 4.08563 9.03845 3.68152C9.05621 3.30322 9.38196 3.01263 9.76025 3.01263ZM9.76025 5.27197H12C12.3994 5.27197 12.7214 5.60522 12.7022 6.00897C12.6844 6.38727 12.3585 6.67822 11.9802 6.67822H9.74066C9.34131 6.67822 9.01923 6.34497 9.03845 5.94086C9.05621 5.56274 9.38196 5.27197 9.76025 5.27197Z"
          fill="#666666"
        />
        <path
          d="M6.17487 15.7652H5.17163V12.3763C5.17163 10.9386 4.00122 9.77512 2.55743 9.79068C1.13434 9.8057 0 10.9911 0 12.4148V16.1422C0 17.9863 1.49487 19.4812 3.33893 19.4812H5.17163V23.2967C5.17163 23.6849 5.48676 23.9998 5.87476 23.9998H6.87799V16.4684C6.87799 16.0804 6.56342 15.7652 6.17487 15.7652Z"
          fill="#666666"
        />
        <path
          d="M15.0126 12.8027H8.98737C8.59918 12.8027 8.28424 13.1179 8.28424 13.5059V23.9998H15.7158V13.5059C15.7158 13.1179 15.4008 12.8027 15.0126 12.8027Z"
          fill="#666666"
        />
        <path
          d="M5.172 6.72791C5.172 8.15613 4.01422 9.3139 2.586 9.3139C1.15778 9.3139 0 8.15613 0 6.72791C0 5.29987 1.15778 4.14209 2.586 4.14209C4.01422 4.14209 5.172 5.29987 5.172 6.72791Z"
          fill="#666666"
        />
        <path
          d="M24 6.72791C24 8.15613 22.8422 9.3139 21.414 9.3139C19.9858 9.3139 18.828 8.15613 18.828 6.72791C18.828 5.29987 19.9858 4.14209 21.414 4.14209C22.8422 4.14209 24 5.29987 24 6.72791Z"
          fill="#666666"
        />
        <path
          d="M17.8251 15.7652H18.8284V12.3763C18.8284 10.9386 19.9988 9.77512 21.4426 9.79068C22.8657 9.8057 24 10.9911 24 12.4148V16.1422C24 17.9863 22.5051 19.4812 20.6611 19.4812H18.8284V23.2967C18.8284 23.6849 18.5132 23.9998 18.1252 23.9998H17.122V16.4684C17.122 16.0804 17.4366 15.7652 17.8251 15.7652Z"
          fill="#666666"
        />
      </svg>
    </SvgIcon>
  );
};

export default InterviewsIcon;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import CandidateProgressDetailProvider from 'business/organizer/assessment/contexts/candidate-progress-detail/CandidateProgressDetailProvider';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';
import PersistCandidateDrawer from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/PersistCandidateDrawer';
import CandidateDetailsDrawerBase from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/CandidateDetailsDrawerBase';
import ViewIcon from 'business/organizer/interviews/components/icons/ViewIcon';

const Actions: React.FC<IAllCandidateActionsProps> = ({
  apiUrl,
  id,
  disableView,
}) => {
  const { url } = useCurrentUrl();

  const { toggleDrawer } = useToggleDrawer();

  const [candidateInfoDrawer, setCandidateInfoDrawer] =
    useState(false);

  const { drawerList } = useDrawer();

  return (
    <>
      {!drawerList.includes('candidate') && (
        <PersistCandidateDrawer
          id={id}
          sidebar={candidateInfoDrawer}
          onSidebar={(drawer: boolean) =>
            setCandidateInfoDrawer(drawer)
          }
        />
      )}
      {disableView ? (
        <Tooltip
          title="Report will be ready after candidate takes the test"
          placement="top"
        >
          <span>
            <ViewIcon color="#A3A3A3" />
          </span>
        </Tooltip>
      ) : (
        <IconButton
          component={(props) => <Link {...props} />}
          onClick={toggleDrawer(
            true,
            setCandidateInfoDrawer,
            'candidate'
          )}
          size="small"
          disabled={disableView}
          to={`${url}/${id}`}
        >
          <ViewIcon />
        </IconButton>
      )}
      {!disableView && (
        <CustomDrawer
          drawer={candidateInfoDrawer}
          setDrawer={setCandidateInfoDrawer}
          drawerName="candidate"
        >
          <CandidateProgressDetailProvider>
            <CandidateDetailsDrawerBase
              closeDrawer={toggleDrawer(
                false,
                setCandidateInfoDrawer,
                'candidate'
              )}
              apiUrl={apiUrl}
            />
          </CandidateProgressDetailProvider>
        </CustomDrawer>
      )}
    </>
  );
};

export default Actions;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import Body from 'business/organizer/library/components/question-preview/common/Body';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import HttpError from 'common/components/http-error/HttpError';
import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import PreviewQuestionHeader from 'business/organizer/library/components/question-preview/common/PreviewQuestionHeader';
import QuestionDrawerProvider from 'business/organizer/library/contexts/question-drawer/QuestionDrawerProvider';
import ChallengeUpdatePage from 'business/organizer/library/pages/question-create-update/challenge/ChallengeUpdatePage';
import useChallengeQuestion from 'business/organizer/library/contexts/challenge-question/custom-hooks/useChallengeQuestion';
import useQuestionPreviewData from 'business/organizer/library/pages/question-preview/useQuestionPreviewData';
import QuestionPreviewSkeleton from 'business/organizer/library/components/common/QuestionPreviewSkeleton';

const ChallengePreview: React.FC<IQuestionPreviewProps> = ({
  questionUrl,
}) => {
  const classes = useStyles();
  const { url } = useCurrentUrl();
  const { problemStatementResponse } = useChallengeQuestion();

  const { questionItem, responseError } = useQuestionPreviewData(
    questionUrl,
    problemStatementResponse
  );

  const questionStats = [
    {
      content: questionItem?.question?.difficulty,
      IconImage: WhatshotIcon,
    },
    {
      content: 'Score: ' + questionItem?.question?.score,
      IconImage: TrackChangesOutlinedIcon,
    },
    {
      content: questionItem?.question?.display_time_duration,
      IconImage: AccessTimeIcon,
    },
  ];

  return (
    <>
      <>
        {responseError !== null ? (
          <HttpError axiosError={responseError} />
        ) : (
          <>
            {!questionItem ? (
              <QuestionPreviewSkeleton />
            ) : (
              <>
                <QuestionDrawerProvider>
                  <PreviewQuestionHeader
                    questionItem={questionItem}
                    questionStats={questionStats}
                  >
                    <Switch>
                      <Route path={`${url}/edit/:challengeId`}>
                        <ChallengeUpdatePage />
                      </Route>
                    </Switch>
                  </PreviewQuestionHeader>
                </QuestionDrawerProvider>
                {!!questionItem.question?.overview && (
                  <Body content={questionItem.question?.overview} />
                )}
                {!!questionItem.question?.data && (
                  <Body content={questionItem.question?.data} />
                )}
                <Box px={6} py={3}>
                  <Typography variant="h6" color="textPrimary">
                    Resources
                  </Typography>
                  <Box pl={1}>
                    {questionItem.question.datasets.map(
                      (dataset: any, idx: number) => (
                        <Typography variant="body1" key={idx}>
                          <MuiLink
                            download
                            href={dataset.file}
                            className={classes.link__dataset}
                            target="_blank"
                          >
                            <GetAppIcon fontSize="small" />{' '}
                            {dataset.filename}
                          </MuiLink>
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>

                <Box px={6} py={3}>
                  <Typography variant="h6" color="textPrimary">
                    Submission guidelines
                  </Typography>
                  {!!questionItem.question?.evaluation_metrics
                    .length && (
                    <Box pl={1}>
                      <Typography variant="body1">
                        1. Your predictions file must be a CSV and its
                        first row i.e header field must be{' '}
                        <b>
                          {
                            questionItem.question
                              ?.evaluation_metrics[0].column
                          }
                        </b>
                      </Typography>
                      <Typography variant="body1">
                        2. You must upload .ipynb notebook file as a
                        solution to the question
                      </Typography>
                    </Box>
                  )}
                </Box>
                {!!questionItem.question?.evaluation_metrics
                  .length && (
                  <Box px={6} py={3}>
                    <Typography variant="h6" color="textPrimary">
                      Evalution Metric
                    </Typography>
                    <Box>
                      <Typography variant="body1">
                        {
                          questionItem.question?.evaluation_metrics[0]
                            .evaluator.name
                        }{' '}
                        evaluation metric is used for evaluating model
                        predictions
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box px={6} py={3} className={classes.box__rich_text}>
                  <Typography variant="h6" color="textPrimary">
                    Additional guidelines
                  </Typography>
                  <RichTextRenderer
                    richTextHtml={questionItem.question?.evaluation}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default ChallengePreview;

const useStyles = makeStyles((theme) => ({
  box__rich_text: {
    '& *': {
      maxWidth: '100%',
    },
  },
  link__dataset: {
    display: 'flex',
    alignItems: 'center',
    gridGap: `${theme.spacing(1)}px`,
  },
}));

export const testStatuses = [
  {
    id: 1,
    value: 'registered',
    label: 'Registered',
  },
  {
    id: 2,
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    id: 3,
    value: 'ready_for_review',
    label: 'Ready For Review',
  },
  {
    id: 4,
    value: 'shortlisted',
    label: 'Shortlisted',
  },
  {
    id: 5,
    value: 'rejected',
    label: 'Rejected',
  },
];

export const inviteStatuses = [
  {
    id: 1,
    value: 'pending',
    label: 'Pending',
  },
  {
    id: 2,
    value: 'cancelled',
    label: 'Cancelled',
  },
  {
    id: 3,
    value: 'expired',
    label: 'Expired',
  },
  {
    id: 4,
    value: 'accepted',
    label: 'Accepted',
  },
];

import React from 'react';

import InputWithLabel from 'common/components/form/InputWithLabel';
import RichTextField from 'common/components/form/RichTextField';

const OtherDetails = () => {
  return (
    <>
      <InputWithLabel
        title="Rules"
        downMD="subtitle2"
        upMD="subtitle2"
        isRequired={true}
      >
        <RichTextField name="rules" />
      </InputWithLabel>
    </>
  );
};

export default OtherDetails;

import React, { useLayoutEffect } from 'react';

import { useLocation } from 'react-router-dom';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';

const PersistSidebar: React.FC<IPersistSidebarProps> = ({
  endingPath,
  sidebar,
  onSidebar,
  sidebarName,
  endPosition,
}) => {
  const { pathname } = useLocation();
  const { setDrawerList } = useDrawer();

  useLayoutEffect(() => {
    if (pathname.endsWith(endingPath, endPosition)) {
      if (!sidebar) {
        setDrawerList((drawerList) => [
          ...(drawerList || []),
          sidebarName,
        ]);
        onSidebar(true);
      }
    }
  }, [
    endPosition,
    endingPath,
    onSidebar,
    pathname,
    setDrawerList,
    sidebar,
    sidebarName,
  ]);

  return <></>;
};

export default PersistSidebar;

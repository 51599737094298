import React from 'react';
import InputWithLabel from 'common/components/form/InputWithLabel';

const FieldLabel: React.FC<IInputWithLabel> = ({
  children,
  ...props
}) => {
  return (
    <InputWithLabel downMD="subtitle2" upMD="subtitle2" {...props}>
      {children}
    </InputWithLabel>
  );
};

export default FieldLabel;

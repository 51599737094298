import React from 'react';

const YoutubeIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2616 2.63086H3.73837C1.67372 2.63086 0 4.30458 0 6.36923V11.6297C0 13.6944 1.67372 15.3681 3.73837 15.3681H14.2616C16.3263 15.3681 18 13.6944 18 11.6297V6.36923C18 4.30458 16.3263 2.63086 14.2616 2.63086ZM11.7334 9.25543L6.81133 11.603C6.68017 11.6655 6.52868 11.5699 6.52868 11.4246V6.58281C6.52868 6.43545 6.68415 6.33994 6.81558 6.40656L11.7377 8.90082C11.884 8.97497 11.8815 9.18484 11.7334 9.25543Z"
      fill="#F61C0D"
    />
  </svg>
);

export default YoutubeIcon;

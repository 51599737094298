const LogoLg = () => {
  return (
    <svg
      width="250"
      height="74"
      viewBox="0 0 250 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M176.885 68.3701H178.635V66.9371H178.785C179.109 67.5246 179.769 68.5502 181.447 68.5502C183.678 68.5502 185.297 66.7632 185.297 63.7837C185.297 60.7979 183.654 59.0411 181.43 59.0411C179.721 59.0411 179.103 60.0843 178.785 60.6541H178.678V56.0914H176.885V68.3701ZM178.642 63.7655C178.642 61.8411 179.481 60.5638 181.046 60.5638C182.671 60.5638 183.486 61.9371 183.486 63.7655C183.486 65.6121 182.647 67.0212 181.046 67.0212C179.505 67.0212 178.642 65.7019 178.642 63.7655ZM188.113 71.8235C189.594 71.8235 190.529 71.0502 191.063 69.599L194.87 59.1791L192.933 59.161L190.601 66.3076H190.505L188.173 59.161H186.254L189.624 68.4899L189.402 69.1013C188.946 70.3246 188.305 70.4263 187.322 70.157L186.89 71.6257C187.106 71.7218 187.574 71.8235 188.113 71.8235ZM206.921 68.3701C211.909 68.3701 214.795 65.2763 214.795 60.1604C214.795 55.0681 211.909 51.9984 207.089 51.9984H201.621V68.3701H206.921ZM204.091 66.2115V54.1568H206.937C210.518 54.1568 212.364 56.2992 212.364 60.1604C212.364 64.0371 210.518 66.2115 206.785 66.2115H204.091ZM217.867 68.3701H220.337V62.6144H223.694C227.491 62.6144 229.37 60.3201 229.37 57.2985C229.37 54.2847 227.507 51.9984 223.702 51.9984H217.867V68.3701ZM220.337 60.5201V54.1168H223.438C225.884 54.1168 226.884 55.4439 226.884 57.2985C226.884 59.153 225.884 60.5201 223.47 60.5201H220.337ZM234.464 61.0797C234.464 59.1212 235.703 58.0019 237.406 58.0019C239.053 58.0019 240.036 59.049 240.036 60.8479V68.3701H242.426V60.5598C242.426 57.4985 240.747 55.9315 238.221 55.9315C236.31 55.9315 235.159 56.7628 234.584 58.0899H234.432V51.9984H232.074V68.3701H234.464V61.0797ZM245.616 68.3701H248.006V56.0914H245.616V68.3701ZM246.823 54.1968C247.646 54.1968 248.334 53.5573 248.334 52.7739C248.334 51.9905 247.646 51.343 246.823 51.343C245.991 51.343 245.312 51.9905 245.312 52.7739C245.312 53.5573 245.991 54.1968 246.823 54.1968Z"
        fill="#333333"
      />
      <path
        d="M19.2238 15.9405C17.0643 13.637 13.657 12.8691 10.5857 12.8691C5.69069 12.8691 0.79573 15.2686 0.79573 20.7395C0.79573 29.6176 12.9372 26.4503 12.9372 30.1935C12.9372 31.4412 11.3055 31.8731 9.67387 31.8731C7.51433 31.8731 5.93063 30.9614 4.58693 29.3297L0.219849 34.1766C2.52336 36.5282 6.12262 37.344 9.38592 37.344C14.3769 37.344 20.1356 35.6164 20.1356 29.6656C20.1356 20.7875 7.99421 23.7149 7.99421 20.2596C7.99421 18.8679 9.43393 18.34 10.6336 18.34C12.3613 18.34 13.753 19.0119 14.8088 20.3076L19.2238 15.9405Z"
        fill="#0A66C2"
      />
      <path
        d="M22.3859 36.7684H29.5843V24.9629H29.6804L37.5507 36.7684H46.6208L37.1188 23.9551L46.3808 13.4453H37.5507L29.5843 22.6594V0.488037H22.3859V36.7684Z"
        fill="#0A66C2"
      />
      <path
        d="M47.7085 36.7683H54.9069V13.4452H47.7085V36.7683ZM47.1326 6.10276C47.1326 8.40623 49.0042 10.2779 51.3077 10.2779C53.6112 10.2779 55.4828 8.40623 55.4828 6.10276C55.4828 3.79922 53.6112 1.92761 51.3077 1.92761C49.0042 1.92761 47.1326 3.79922 47.1326 6.10276Z"
        fill="#0A66C2"
      />
      <path
        d="M58.7347 36.7684H65.933V0.488037H58.7347V36.7684Z"
        fill="#0A66C2"
      />
      <path
        d="M69.7615 36.7684H76.9598V0.488037H69.7615V36.7684Z"
        fill="#0A66C2"
      />
      <path
        d="M98.304 15.9405C96.1444 13.637 92.737 12.8691 89.666 12.8691C84.7711 12.8691 79.8756 15.2686 79.8756 20.7395C79.8756 29.6176 92.0171 26.4503 92.0171 30.1935C92.0171 31.4412 90.3858 31.8731 88.754 31.8731C86.5944 31.8731 85.0108 30.9614 83.6671 29.3297L79.3 34.1766C81.6035 36.5282 85.2029 37.344 88.466 37.344C93.4569 37.344 99.216 35.6164 99.216 29.6656C99.216 20.7875 87.0745 23.7149 87.0745 20.2596C87.0745 18.8679 88.5143 18.34 89.7137 18.34C91.4415 18.34 92.833 19.0119 93.8887 20.3076L98.304 15.9405Z"
        fill="#0A66C2"
      />
      <path
        d="M102.138 47.7098H109.337V34.2727H109.433C111.064 36.5282 113.847 37.344 116.823 37.344C123.446 37.344 127.477 31.3932 127.477 24.9146C127.477 17.8601 122.918 12.8691 116.007 12.8691C112.744 12.8691 109.96 14.8368 108.856 16.5164H108.76V13.445H102.138V47.7098ZM108.76 25.1066C108.76 21.6033 110.92 19.2038 114.52 19.2038C118.119 19.2038 120.278 21.6033 120.278 25.1066C120.278 28.6098 118.119 31.0093 114.52 31.0093C110.92 31.0093 108.76 28.6098 108.76 25.1066Z"
        fill="#0A66C2"
      />
      <path
        d="M144.892 36.7681H151.514V24.9146C151.514 17.1403 149.259 12.8691 140.86 12.8691C137.069 12.8691 133.23 14.1169 130.59 16.6124L134.43 20.5476C135.965 18.8199 137.933 17.7641 140.284 17.7641C142.78 17.7641 144.892 19.3958 144.892 21.9393C138.893 21.9393 129.102 22.0832 129.102 30.1455C129.102 34.8485 133.038 37.344 137.405 37.344C140.333 37.344 143.212 36.3362 144.796 33.8407H144.892V36.7681ZM144.892 26.5462V28.0819C144.892 30.9614 142.252 32.449 139.421 32.449C137.837 32.449 136.013 31.6332 136.013 29.9056C136.013 26.7382 140.908 26.5462 143.115 26.5462H144.892Z"
        fill="#0A66C2"
      />
      <path
        d="M175.13 15.9885C172.875 13.685 169.563 12.8691 166.828 12.8691C159.63 12.8691 153.871 17.6681 153.871 25.1066C153.871 32.545 159.63 37.344 166.828 37.344C169.563 37.344 172.875 36.5282 175.13 34.2247L170.379 29.2337C169.563 30.1935 168.412 31.0093 166.828 31.0093C163.229 31.0093 161.069 28.6098 161.069 25.1066C161.069 21.6033 163.229 19.2038 166.828 19.2038C168.315 19.2038 169.659 19.9716 170.379 20.9794L175.13 15.9885Z"
        fill="#0A66C2"
      />
      <path
        d="M193.339 29.0418C192.139 30.6734 190.555 31.8731 188.204 31.8731C185.42 31.8731 183.357 30.0975 182.781 27.3621H199.481V25.1066C199.481 17.6681 195.258 12.8691 188.539 12.8691C181.341 12.8691 175.583 17.6681 175.583 25.1066C175.583 32.545 181.341 37.344 188.539 37.344C192.283 37.344 196.074 35.6644 198.377 32.737L193.339 29.0418ZM182.781 22.4672C182.973 20.2116 184.701 18.052 187.868 18.052C190.459 18.052 192.331 20.0677 192.283 22.4672H182.781Z"
        fill="#0A66C2"
      />
      <path
        d="M202.563 32.6413C202.563 35.0888 204.531 37.0564 206.978 37.0564C209.426 37.0564 211.393 35.0888 211.393 32.6413C211.393 30.1938 209.426 28.2262 206.978 28.2262C204.531 28.2262 202.563 30.1938 202.563 32.6413Z"
        fill="#0A66C2"
      />
      <path
        d="M229.817 36.7681H236.439V24.9146C236.439 17.1403 234.184 12.8691 225.785 12.8691C221.994 12.8691 218.155 14.1169 215.515 16.6124L219.355 20.5476C220.89 18.8199 222.858 17.7641 225.209 17.7641C227.705 17.7641 229.817 19.3958 229.817 21.9393C223.818 21.9393 214.028 22.0832 214.028 30.1455C214.028 34.8485 217.963 37.344 222.33 37.344C225.257 37.344 228.137 36.3362 229.721 33.8407H229.817V36.7681ZM229.817 26.5462V28.0819C229.817 30.9614 227.177 32.449 224.346 32.449C222.762 32.449 220.938 31.6332 220.938 29.9056C220.938 26.7382 225.834 26.5462 228.041 26.5462H229.817Z"
        fill="#0A66C2"
      />
      <path
        d="M239.612 36.7683H246.81V13.4452H239.612V36.7683ZM239.036 6.10276C239.036 8.40623 240.908 10.2779 243.211 10.2779C245.514 10.2779 247.386 8.40623 247.386 6.10276C247.386 3.79922 245.514 1.92761 243.211 1.92761C240.908 1.92761 239.036 3.79922 239.036 6.10276Z"
        fill="#0A66C2"
      />
      <path
        d="M243.176 10.2993C245.546 10.2993 247.468 8.37769 247.468 6.00728C247.468 3.63687 245.546 1.71527 243.176 1.71527C240.805 1.71527 238.884 3.63687 238.884 6.00728C238.884 8.37769 240.805 10.2993 243.176 10.2993Z"
        fill="#FFC233"
      />
    </svg>
  );
};

export default LogoLg;

import { Link } from 'react-router-dom';

/**
 * Returns tab props for a react-router-dom <Link />
 * @param tabIndices
 * @param path
 * @param classes jss classes used in the nav component
 */
export function tabProps(route: any, path: any, classes: any) {
  return {
    id: `simple-tab-${route}`,
    className: classes.tab,
    'aria-controls': `simple-tabpanel-${route}`,
    component: Link,
    to: path,
  };
}

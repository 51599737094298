import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import WhatshotIcon from '@material-ui/icons/Whatshot';

import QuestionPreviewSkeleton from 'business/organizer/library/components/common/QuestionPreviewSkeleton';
import PreviewQuestionHeader from 'business/organizer/library/components/question-preview/common/PreviewQuestionHeader';
import QuestionDrawerProvider from 'business/organizer/library/contexts/question-drawer/QuestionDrawerProvider';
import Body from 'business/organizer/library/components/question-preview/common/Body';
import HttpError from 'common/components/http-error/HttpError';
import { QuestionType } from 'business/organizer/library/utils/utils';

const Preview: React.FC<IGenericQuestionPreviewProps> = ({
  responseError,
  questionItem,
  updatePageRoute,
  children,
}) => {
  let questionStats = [
    {
      id: 1,
      content: questionItem?.question?.difficulty || '-',
      IconImage: WhatshotIcon,
    },
    {
      id: 3,
      content: questionItem?.question?.display_time_duration || '-',
      IconImage: AccessTimeIcon,
    },
  ];

  if (questionItem?.type !== QuestionType.INTERVIEW) {
    questionStats = [
      ...questionStats,
      {
        id: 2,
        content: 'Score: ' + questionItem?.question?.score || '-',
        IconImage: TrackChangesOutlinedIcon,
      },
    ];

    questionStats.sort((prev, next) => prev.id - next.id);
  }

  return (
    <>
      {responseError !== null ? (
        <HttpError axiosError={responseError} />
      ) : (
        <>
          {!questionItem ? (
            <QuestionPreviewSkeleton />
          ) : (
            <>
              <QuestionDrawerProvider>
                <PreviewQuestionHeader
                  questionItem={questionItem}
                  questionStats={questionStats}
                >
                  {updatePageRoute}
                </PreviewQuestionHeader>
              </QuestionDrawerProvider>
              <Body
                content={
                  questionItem?.question?.description as string
                }
              />
              {children}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Preview;

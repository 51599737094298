import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Footer from 'common/components/footer/Footer';
import CustomMuiLink from 'common/components/custom-mui-link/CustomMuiLink';

const AuthPageBase: React.FC<{
  children: React.ReactChild;
  contentSection: React.ReactChild;
  showFooter?: boolean;
}> = ({ children, contentSection, showFooter = true }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.grid__root}>
        <Grid item md={6} xs={12}>
          {contentSection}
        </Grid>
        <Grid item md={6} xs={12}>
          {children}
        </Grid>
      </Grid>
      {showFooter && (
        <Box>
          <Footer
            RouteLink={(props) => (
              <CustomMuiLink href={props.to} {...props} />
            )}
          />
        </Box>
      )}
    </>
  );
};

export default AuthPageBase;

const useStyles = makeStyles((theme) => ({
  grid__root: {
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
}));

import { useEffect } from 'react';

import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import {
  FETCH_LIBRARY_COUNTS,
  FETCH_LIBRARY_COUNT_FAILURE,
} from 'business/organizer/library/contexts/library/libraryActionTypes';
import { LIBRARY_QUESTIONS_COUNT_URL } from 'business/organizer/library/utils/constants';

const libraryService = new LibraryService();

const useLibraryQuestionsCount = (params?: {}) => {
  const { state, dispatch } = useLibrary();

  useEffect(() => {
    let cancelLoad = false;

    if (!state.isLoaded) {
      libraryService
        .getLibraryQuestionsCount(LIBRARY_QUESTIONS_COUNT_URL, params)
        .then((response) => {
          if (!cancelLoad) {
            dispatch({
              type: FETCH_LIBRARY_COUNTS,
              payload: response.data,
            });
          }
        })
        .catch((error) =>
          dispatch({
            type: FETCH_LIBRARY_COUNT_FAILURE,
            payload: error,
          })
        );
    }

    return () => {
      // We're unmounting. Don't load this request's data.
      cancelLoad = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sharedLibraryCount:
      typeof state.shared.count === 'number' ? state.shared.count : 0,
    myLibraryCount:
      typeof state.my.count === 'number' ? state.my.count : 0,
  };
};

export default useLibraryQuestionsCount;

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const SettingsBoxOuterContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.box__outer_container}>{children}</Box>
  );
};

export default SettingsBoxOuterContainer;

const useStyles = makeStyles((theme) => ({
  box__outer_container: {
    background: '#FFFFFF',
    marginTop: theme.spacing(4),
    marginBotton: theme.spacing(4),
    padding: theme.spacing(6),
    paddingTop: theme.spacing(2),
  },
}));

import { useContext } from 'react';
import EmailVerificationContext from './EmailVerificationContext';

const useEmailVerification = (): IEmailVerificationContext => {
  const context = useContext(
    EmailVerificationContext
  ) as IEmailVerificationContext;
  if (context === undefined) {
    throw new Error(
      'useEmailVerification must be used within a EmailVerificationProvider.'
    );
  }
  return context;
};

export default useEmailVerification;

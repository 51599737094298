import React from 'react';
import { Formik } from 'formik';
import Container from '@material-ui/core/Container';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { validationSchema } from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/utils/validationSchema';
import useAlert from 'common/components/alert-provider/useAlert';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import {
  getDuration,
  getProctor,
  PROCTOR_NAME,
} from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/utils/helpers';
import SettingsForm from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/SettingsForm';

const organizerService = new OrganizerService();

const SettingsUpdate: React.FC<ISettingsUpdateProps> = ({
  testDetail,
}) => {
  const { setTestDetail, setTestDetailError } = useTestDetail();
  const { enqueueAlert } = useAlert();

  const initialState: IInitialStateTestSettingsFormProps = {
    name: testDetail?.name || '',
    invite_only: testDetail?.invite_only || false,
    candidate_assessment_url:
      testDetail?.candidate_assessment_url || '',
    start_datetime: testDetail?.start_datetime || null,
    end_datetime: testDetail?.end_datetime || null,
    days: getDuration(testDetail?.duration?.days),
    hours: getDuration(testDetail?.duration?.hours),
    minutes: getDuration(testDetail?.duration?.minutes),
    instructions: testDetail?.instructions || '',
    registration_fields:
      testDetail?.candidate_fields.registration_fields.map(
        (registration_field: IRegistrationFormFieldProps) =>
          registration_field.id
      ) || [],
    copy_detection:
      getProctor(
        testDetail?.proctor_settings,
        PROCTOR_NAME.COPY_DETECTION
      )?.is_enabled || false,
    window_switching:
      getProctor(
        testDetail?.proctor_settings,
        PROCTOR_NAME.WINDOW_SWITCHING
      )?.is_enabled || false,
    ai_proctoring:
      getProctor(
        testDetail?.proctor_settings,
        PROCTOR_NAME.AI_PROCTORING
      )?.is_enabled || false,
    languages: testDetail?.programming_languages.map(
      (language: IProgrammingLanguage) => language.id
    ),
  };

  const handleSubmit = async (
    data: IInitialStateTestSettingsFormProps
  ) => {
    try {
      const {
        name,
        invite_only,
        start_datetime,
        end_datetime,
        instructions,
        registration_fields,
        days,
        hours,
        minutes,
        copy_detection,
        window_switching,
        ai_proctoring,
        languages,
      } = data;

      let testData: { [key: string]: any } = {
        name: name,
        invite_only: invite_only,
        //condionally add start_datetime and end_datetime to the object based on it's value
        ...(start_datetime !== '' && {
          start_datetime: start_datetime,
        }),
        ...(end_datetime !== '' && {
          end_datetime: end_datetime,
        }),
        instructions: instructions,
        candidate_fields: {
          registration_fields: registration_fields,
        },
        proctor_settings: [
          {
            name: PROCTOR_NAME.COPY_DETECTION,
            is_enabled: copy_detection,
          },
          {
            name: PROCTOR_NAME.WINDOW_SWITCHING,
            is_enabled: window_switching,
          },
          {
            name: PROCTOR_NAME.AI_PROCTORING,
            is_enabled: ai_proctoring,
          },
        ],
        programming_languages: languages,
      };

      if (days || hours || minutes) {
        testData.duration = {
          days: !!days || days === 0 ? days : null,
          hours: !!hours || hours === 0 ? hours : null,
          minutes: !!minutes || minutes === 0 ? minutes : null,
        };
      }

      const response = await organizerService.patchTest(
        testDetail?.url as string,
        testData
      );

      if (response) {
        setTestDetail(response?.data);
        enqueueAlert('Updated successfully', {
          alertProps: { severity: 'success' },
        });
      }
    } catch (error) {
      setTestDetailError(error);
    }
  };

  return (
    <>
      <Container maxWidth={false} style={{ marginTop: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <SettingsForm />
        </Formik>
      </Container>
    </>
  );
};

export default SettingsUpdate;

import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  FRONTEND_HUBS_LOGIN_URL,
  FRONTEND_LOGIN_URL,
} from 'common/utils/constants';
import redirect from 'common/utils/redirect';

const AuthBlockFooter: React.FC<{
  title?: string;
  ctaText?: string;
  ctaLink?: string;
  redirectUrl?: string;
}> = ({
  title = 'Already have an account ?',
  ctaText = 'Log In',
  ctaLink = window.location.pathname.includes('hubs')
    ? FRONTEND_HUBS_LOGIN_URL
    : FRONTEND_LOGIN_URL,
}) => {
  const history = useHistory();

  // TEMP: remove this when we have a proper redirect url. For some reason redirectUrl is showing up as undefined in the console.
  const redirectUrl = undefined;

  return (
    <Box
      pt={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography>{title}</Typography>

      {typeof redirectUrl !== 'undefined' ? (
        <Button
          color="primary"
          onClick={() => {
            redirect(history, `./${redirectUrl}`);
          }}
        >
          {ctaText}
        </Button>
      ) : (
        <Link to={ctaLink}>
          <Button color="primary">{ctaText}</Button>
        </Link>
      )}
    </Box>
  );
};

export default AuthBlockFooter;

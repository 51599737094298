import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MyTypography from 'common/components/my-typography/MyTypography';
import theme from 'business/common/theme/theme';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import BillingFooterItem from 'business/organizer/assessment/components/billing/common/BillingFooterItem';
import FooterIcon from 'common/components/footer/FooterIcon';
import { socialMediaLinks } from 'business/organizer/assessment/components/billing/utils/utils';

const BillingPageFooter = () => {
  return (
    <Box textAlign="center" py={6}>
      <MyTypography
        content="Still have questions, reach out to us"
        responsiveVariants={{ downXl: 'h4', downMd: 'h5' }}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gridColumnGap={48}
        pt={6}
      >
        <BillingFooterItem>
          <Typography>Drop us mail</Typography>
          <Box
            color={theme.palette.info.main}
            display="flex"
            alignItems="center"
            justifyContent="center"
            pt={2}
          >
            <EmailOutlinedIcon color="inherit" />
            <Typography color="inherit">
              &nbsp;support@skillspace.app
            </Typography>
          </Box>
        </BillingFooterItem>
        <BillingFooterItem>
          <Typography>Use other mediums</Typography>
          <Box pt={2}>
            <List disablePadding>
              <ListItem disableGutters>
                {socialMediaLinks.map(({ id, route, icon }) => (
                  <FooterIcon key={id} route={route} Icon={icon} />
                ))}
              </ListItem>
            </List>
          </Box>
        </BillingFooterItem>
      </Box>
    </Box>
  );
};

export default BillingPageFooter;

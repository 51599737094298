import React from 'react';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const TestListItemButtons: React.FC<ITestActionsProps> = ({
  testItem,
}) => {
  const { url, isHubsUrl } = useCurrentUrl();
  return (
    <Box>
      <Link
        to={`${url}/${testItem.id}/${
          isHubsUrl ? 'results' : 'candidate-progress'
        }`}
      >
        <Button variant="outlined" color="primary" size="small">
          {isHubsUrl ? 'Results' : 'Candidates progress'}
        </Button>
      </Link>
    </Box>
  );
};

export default TestListItemButtons;

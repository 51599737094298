import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import {
  getProctor,
  PROCTOR_NAME,
} from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/utils/helpers';

const HandleError = () => {
  const { testDetail } = useTestDetail();

  return (
    <Box px={4} py={12}>
      {getProctor(
        testDetail?.proctor_settings,
        PROCTOR_NAME.AI_PROCTORING
      )?.has_permission ? (
        <>
          {getProctor(
            testDetail?.proctor_settings,
            PROCTOR_NAME.AI_PROCTORING
          )?.is_enabled ? (
            <Typography variant="h6" align="center">
              Proctoring data is not found for this candidate. Maybe
              proctoring was off during the test.
            </Typography>
          ) : (
            <Typography variant="h6" align="center">
              AI proctoring is not enabled for this test
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="h6" align="center">
          You're not eligible to use AI proctoring. Please upgrade
          your plan
        </Typography>
      )}
    </Box>
  );
};

export default HandleError;

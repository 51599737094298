import React from 'react';
import { useFormikContext } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';

import InputWithLabel from 'common/components/form/InputWithLabel';
import GenericDateTimePickerComponent from 'common/components/mui-pickers/GenericDateTimePickerComponent';
import { addDays } from 'common/utils/addDays';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const ExpiryDatePicker = () => {
  const { values } = useFormikContext<{
    expireDate: null | string;
  }>();
  const { isViewer } = useHasPermission();

  return (
    <InputWithLabel
      title="Invitation expires after"
      downMD="subtitle2"
      upMD="subtitle2"
      isRequired={false}
      tooltip
      tooltipTitle="Invitation will be expired after this particular date. The exact hour will be same as the time at which you send the invite. For example, if you sent an invite today at 9:00 am and set the expiry date as 12th April, then the invite will expire at 9 am, 12th April"
    >
      <HasPermission
        isViewer={isViewer}
        tooltipProps={{ placement: 'top', arrow: true }}
      >
        <GenericDateTimePickerComponent
          PickerComponent={KeyboardDatePicker}
          format="DD-MM-YYYY"
          name="expireDate"
          date={values.expireDate}
          InputAdornmentProps={{
            position: 'start',
          }}
          disablePast
          minDate={addDays(1)}
          variant="dialog"
          placeholder="Add Invite Expire Date"
          disabled={isViewer}
        />
      </HasPermission>
    </InputWithLabel>
  );
};

export default ExpiryDatePicker;

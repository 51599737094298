import React from 'react';
import { Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FileUploadUpdatePage from 'business/organizer/library/pages/question-create-update/file-upload/FileUploadUpdatePage';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useFileUpload from 'business/organizer/library/contexts/file-upload-question/custom-hooks/useFileUpload';
import useQuestionPreviewData from 'business/organizer/library/pages/question-preview/useQuestionPreviewData';
import Preview from 'business/organizer/library/components/question-preview/common/Preview';

const FileUploadPreview: React.FC<IQuestionPreviewProps> = ({
  questionUrl,
}) => {
  const { url } = useCurrentUrl();

  const { problemStatementResponse } = useFileUpload();
  const hasResponse = !!problemStatementResponse;
  const { questionItem, responseError } = useQuestionPreviewData(
    questionUrl,
    hasResponse
  );

  return (
    <Preview
      questionItem={questionItem}
      responseError={responseError}
      updatePageRoute={
        <Route path={`${url}/edit/:fileUploadId`}>
          <FileUploadUpdatePage />
        </Route>
      }
    >
      <Box px={6} py={3}>
        <Typography variant="h6" color="textPrimary">
          Additional guidelines
        </Typography>
        <Box pl={1}>
          <Typography variant="body1">
            1. Allowed file type - {questionItem?.question?.file_type}
          </Typography>
          <Typography variant="body1">
            2. Maximum file size -{' '}
            {questionItem?.question?.max_file_size} MB
          </Typography>
        </Box>
      </Box>
    </Preview>
  );
};

export default FileUploadPreview;

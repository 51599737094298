import BaseService from 'common/services/baseService';
import {
  HUBS_CANDIDATE_API_SIGNUP_URL,
  HUBS_CANDIDATE_API_LOGIN_URL,
  HUBS_CANDIDATE_USER_PROFILE_API,
  HUBS_CANDIDATE_API_TESTS_COUNT_URL,
  HUBS_CANDIDATE_API_TEST_URL,
  HUBS_CANDIDATE_API_LOGOUT_URL,
} from 'hubs-candidate/utils/constants';

export class HubsCandidateService extends BaseService {
  async hubsCandidateSignUp(data: {}, params?: object) {
    return await this.post(
      HUBS_CANDIDATE_API_SIGNUP_URL,
      data,
      params
    );
  }

  async hubsCandidateLogin(data: {}) {
    return await this.post(HUBS_CANDIDATE_API_LOGIN_URL, data);
  }

  async hubsCandidateLogout() {
    return await this.post(HUBS_CANDIDATE_API_LOGOUT_URL, {});
  }

  async getUserProfile() {
    return await this.get(HUBS_CANDIDATE_USER_PROFILE_API);
  }

  async getTestsCount() {
    return await this.get(HUBS_CANDIDATE_API_TESTS_COUNT_URL);
  }

  async getTestLists(params?: object) {
    return await this.get(HUBS_CANDIDATE_API_TEST_URL, {
      app_type: this.appTypeParam,
      ...(params || {}),
    });
  }
}

import BaseService from 'common/services/baseService';
import { AxiosRequestConfig } from 'axios';
import objectToFormData from 'common/utils/objectToFormData';

class ProctoringService extends BaseService {
  /**
   * Log an event
   * @param data
   * @param axiosConfig The configs we want to send
   */
  async postEvent(
    url: string,
    data: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.post(url, data, axiosConfig);
  }

  async saveCandidatePhoto(url: string, data: { photo: Blob }) {
    const formData = objectToFormData(data);
    return await this.post(url, formData);
  }
}

export default ProctoringService;

import { useEffect, useState } from 'react';
import DayJsUtils from '@date-io/dayjs';
import { Dayjs } from 'dayjs';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useFormikContext, ErrorMessage } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const TimePickerField: React.FC<ITimePickerProps> = ({
  time,
  name,
  timePickerProps,
}) => {
  const { setFieldValue } =
    useFormikContext<IGenericDateTimePickerUseFormikContext>();
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(
    null
  );

  useEffect(() => {
    if (!!time) {
      setSelectedTime(time as Dayjs);
    }
  }, [time]);

  const handleTimeChange = (time: Dayjs | null) => {
    setFieldValue(name, time);
    setSelectedTime(time as Dayjs);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <KeyboardTimePicker
          value={selectedTime}
          id={`${name}-picker`}
          keyboardIcon={<QueryBuilderIcon />}
          {...timePickerProps}
          onChange={handleTimeChange}
        />
      </MuiPickersUtilsProvider>
      <ErrorMessage name={name}>
        {(msg) => <FormHelperText error={true}>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

export default TimePickerField;

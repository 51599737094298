import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Box } from '@material-ui/core';

import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';

const AssessmentInstructions: React.FC<{
  duration: string;
  showPartially?: boolean;
}> = ({ duration, showPartially = false }) => {
  const instructionsElement = (
    <ul>
      {!showPartially && (
        <li>
          It will take approximately {duration} to complete test.
        </li>
      )}
      <li>
        Please ensure you are using a desktop or laptop with stable
        internet connection.
      </li>
      <li>
        This assessment must be completed within the specified time
        duration. Please make sure there are no interruptions once you
        begin the assessment.
      </li>
      {!showPartially && (
        <li>
          We recommend you try out the{' '}
          <a
            href="/business/candidate/assessments/sample"
            target="_blank"
          >
            sample test
          </a>{' '}
          before proceeding.
        </li>
      )}
    </ul>
  );

  const richText = ReactDOMServer.renderToStaticMarkup(
    instructionsElement
  );

  return (
    <Box maxWidth="100%">
      <RichTextRenderer richTextHtml={richText} />
    </Box>
  );
};

export default AssessmentInstructions;

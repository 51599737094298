import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import HttpError from 'common/components/http-error/HttpError';
import NotebookHtmlSkeleton from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/challenge/NotebookHtmlSkeleton';
import DownloadNotebook from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/challenge/DownloadNotebook';
import { OrganizerService } from 'business/organizer/common/services/organizerService';

const organizerService = new OrganizerService();

const NotebookPreview = ({ apiUrl }: { apiUrl: string }) => {
  const [notebook, setNotebook] = useState<any | null>(null);
  const [responseError, setResponseError] =
    useState<AxiosError | null>(null);
  const [notebookHtml, setNotebookHtml] = useState<any | null>(null);

  const { notebook_html, title, notebook_file } = notebook || {};

  useEffect(() => {
    let mounted = true;

    organizerService
      .get(apiUrl)
      .then((response: AxiosResponse) => {
        if (mounted) {
          setNotebook(response.data);
        }
      })
      .catch((error: AxiosError) => setResponseError(error));

    return () => {
      // We're unmounting. Don't load this request's data.
      mounted = false;
    };
  }, [apiUrl]);

  // Fetch notebook html
  useEffect(() => {
    if (notebook_html === null) {
      setNotebookHtml('');
    } else if (notebook_html) {
      organizerService
        .getNotebookHtml(notebook_html)
        .then((html) => {
          setNotebookHtml(html);
        })
        .catch((error) => {
          setResponseError(error);
        });
    }
  }, [notebook_html]);

  return (
    <>
      {responseError !== null ? (
        <HttpError axiosError={responseError} />
      ) : notebook === null ? (
        <Box width="100%">
          <NotebookHtmlSkeleton />
        </Box>
      ) : (
        <Box p={4}>
          <div>
            {notebookHtml !== null ? (
              notebookHtml !== '' ? (
                <Paper>
                  <Box p={4}>
                    <Box
                      py={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography variant="h4">{title}</Typography>
                      <DownloadNotebook
                        notebookLink={notebook_file}
                      />
                    </Box>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: notebookHtml,
                      }}
                      className="use-notebook-styles"
                    />
                  </Box>
                </Paper>
              ) : (
                <Typography variant="body1">
                  This notebook has not been processed yet. This
                  usually takes a few seconds.
                </Typography>
              )
            ) : (
              <NotebookHtmlSkeleton />
            )}
          </div>
        </Box>
      )}
    </>
  );
};

export default NotebookPreview;

import React, { useMemo, useCallback } from 'react';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
// import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
// import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';

const useQuestionRouteData = (
  setDrawer: React.Dispatch<any>,
  drawerName: string
) => {
  const { url } = useCurrentUrl();
  const { toggleDrawer } = useToggleDrawer();
  // const { isFreeTrial, isMicorPlan } = useSubscription();
  // const { testId } = useTestId();

  const toggleQuestionDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent, name: string) => {
      toggleDrawer(
        true,
        setDrawer,
        drawerName,
        `${url}/my/questions/${name}`
      )(event);
    },
    [drawerName, setDrawer, toggleDrawer, url]
  );

  const questionData = useMemo(() => {
    let data = [
      {
        id: 1,
        route: (event: React.KeyboardEvent | React.MouseEvent) =>
          toggleQuestionDrawer(event, 'coding'),
        label: 'Coding Assessment',
        divider: true,
        disable: false,
      },
      {
        id: 2,
        route: (event: React.KeyboardEvent | React.MouseEvent) =>
          toggleQuestionDrawer(event, 'challenge'),
        label: 'AI/Machine Learning Challenge',
        divider: true,
        disable: false,
      },
      {
        id: 3,
        route: (event: React.KeyboardEvent | React.MouseEvent) =>
          toggleQuestionDrawer(event, 'free-text'),
        label: 'Free Text (Subjective Question)',
        divider: true,
        disable: false,
      },
      {
        id: 4,
        route: (event: React.KeyboardEvent | React.MouseEvent) =>
          toggleQuestionDrawer(event, 'file-upload'),
        label: 'File Upload',
        divider: true,
        disable: false,
      },
      {
        id: 5,
        route: (event: React.KeyboardEvent | React.MouseEvent) =>
          toggleQuestionDrawer(event, 'mcq'),
        label: 'Multiple Choice Question',
        divider: true,
        disable: false,
      },
      // {
      //   id: 6,
      //   route: (event: React.KeyboardEvent | React.MouseEvent) =>
      //     toggleQuestionDrawer(event, 'project'),
      //   label: 'Take Home Assignment',
      //   divider: true,
      //   disable: isFreeTrial || isMicorPlan,
      // },
      {
        id: 8,
        route: (event: React.KeyboardEvent | React.MouseEvent) =>
          toggleQuestionDrawer(event, 'sql'),
        label: 'SQL',
        divider: false,
        disable: false,
      },
    ];

    // if (!testId) {
    //   data.push({
    //     id: 7,
    //     route: (event: React.KeyboardEvent | React.MouseEvent) =>
    //       toggleQuestionDrawer(event, 'interview'),
    //     label: 'Interview Question',
    //     divider: true,
    //     disable: false,
    //   });
    // }

    return data.sort((prev, next) => prev.id - next.id);
  }, [toggleQuestionDrawer]);

  return { questionData };
};

export default useQuestionRouteData;

import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';

import GetTestsContext from 'business/organizer/library/contexts/get-tests/GetTestsContext';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { TEST_API_URL } from 'business/organizer/common/utils/constants';

const organizerService = new OrganizerService();

const GetTestsProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [tests, setTests] = useState<any | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    let mounted = true;

    axios
      .all([
        organizerService.authGet(TEST_API_URL, { list: 'active' }),
        organizerService.authGet(TEST_API_URL, { list: 'draft' }),
      ])
      .then((response) => {
        if (mounted) {
          setTests([
            ...(response[0].data.results || []),
            ...(response[1].data.results || []),
          ]);
        }
      })
      .catch((error) => setError(error));

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <GetTestsContext.Provider value={{ error, tests }}>
      {children}
    </GetTestsContext.Provider>
  );
};

export default GetTestsProvider;

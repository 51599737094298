import React, { useEffect } from 'react';

import LibraryPage from 'business/organizer/assessment/pages/LibraryPage';
import useTestQuestions from 'business/organizer/assessment/contexts/test-questions/useTestQuestions';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';

const LibraryDrawer = () => {
  const { library, setLibrary } = useTestQuestions();
  const { testParams } = useTestDetail();
  const { setTestId } = useTestId();
  const { toggleDrawer } = useToggleDrawer();

  useEffect(() => {
    setTestId(parseInt(testParams.testId));
    return () => {
      setTestId(null);
    };
  }, [setTestId, testParams.testId]);

  return (
    <CustomDrawer
      drawer={library}
      setDrawer={setLibrary}
      drawerName="library"
    >
      <>
        <CloseDrawerIcon
          closeQuestionDrawer={toggleDrawer(
            false,
            setLibrary,
            'library'
          )}
        />
        <LibraryPage />
      </>
    </CustomDrawer>
  );
};

export default LibraryDrawer;

import React from 'react';
import MuiLink from '@material-ui/core/Link';
import { Box, makeStyles } from '@material-ui/core';

interface ICustomLinkProps {
  href: string;
  children: React.ReactNode;
  className?: any;
  target?: string;
}

const CustomMuiLink: React.FC<ICustomLinkProps> = ({
  href,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <MuiLink href={href} underline="none" {...props}>
        {children}
      </MuiLink>
    </Box>
  );
};

export default CustomMuiLink;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiLink-underlineNone': {
      textDecoration: 'none !important',
    },
  },
}));

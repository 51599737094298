import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import useCodingQuestion from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestion';
import BackButton from 'business/organizer/library/components/common/BackButton';
import SaveButton from 'business/organizer/library/components/common/SaveButton';
import FormError from 'business/organizer/library/components/common/FormError';
import useTestCases from 'business/organizer/library/contexts/coding-question/custom-hooks/useTestCases';

export enum StepLabel {
  PROBLEM_STATEMENT = 0,
  TESTCASES = 1,
}

const CodingFormActions: React.FC<ICodingFormActionsProps> = ({
  isUpdate,
}) => {
  const {
    activeStep,
    prevStep,
    handleAddCodingQuestionToTest,
  } = useCodingQuestion();
  const { testCases } = useTestCases();
  const { isSubmitting, values } = useFormikContext<{
    testId: number;
  }>();

  return (
    <Box display="flex" alignItems="center" gridColumnGap={12}>
      <FormError />
      <Box position="relative">
        {activeStep === StepLabel.PROBLEM_STATEMENT ? (
          <>
            <SaveButton
              disabled={isSubmitting}
              buttonText={
                !!values.testId && !isUpdate
                  ? 'Next'
                  : 'Save & Proceed'
              }
            />
          </>
        ) : (
          <Box display="flex" gridColumnGap={10}>
            <BackButton prevStep={prevStep} />
            {!!values.testId && !isUpdate && (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !testCases || !testCases.length
                    ? true
                    : isSubmitting
                }
                onClick={() =>
                  handleAddCodingQuestionToTest(values.testId)
                }
              >
                Add To Test
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CodingFormActions;

import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { countryCodeData } from 'common/utils/countryCode';
import { MixedSchema } from 'yup/lib/mixed';
import { BLACKLIST_EMAILS } from 'business/pre-subscription/auth/components/common/helpers';

export const personalDetailsValidationSchema = Yup.object({
  first_name: Yup.string()
    .required('First name field is required')
    .typeError('Text type is required'),
  current_role: Yup.string()
    .required('Job title field is required')
    .typeError('Text type is required'),
  country_code: Yup.string()
    .test('is-valid', 'Please enter a valid country code', (val) => {
      if (!val) return false;
      return (
        countryCodeData.find(
          (data) => data.phone === val?.toString()
        ) !== undefined
      );
    })
    .required('This field is required'),
  contact_number: Yup.string().when(
    'country_code',
    (countryCode: string, schema: MixedSchema) => {
      return schema
        .test('is-valid', 'Phone number is not valid', (val) => {
          if (!val) return false;
          if (!countryCode) return false;
          const phoneUtil = PhoneNumberUtil.getInstance();
          const number = `+${countryCode}${val}`;
          try {
            return phoneUtil.isValidNumber(phoneUtil.parse(number));
          } catch (err) {
            return false;
          }
        })
        .required('This field is required');
    }
  ),
});

export const OrganizationDetailsValidationSchema = Yup.object({
  name: Yup.string()
    .required('First name field is required')
    .typeError('Text type is required'),
});

export const InviteMemberFormValidations = Yup.object({
  email: Yup.string()
    .email()
    .test('is-valid', 'Please enter work email address', (val) => {
      if (!val) return false;
      return !BLACKLIST_EMAILS.includes(
        val.split('@')[1].split('.')[0]
      );
    })
    .required('Email is required'),
  permission: Yup.string()
    .required('Please select a valid choice')
    .typeError('Please select a valid choice'),
});

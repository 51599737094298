import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import theme from 'business/common/theme/theme';

const SqlSubQuestion: React.FC<any> = (questionData) => {
  const questionItem = questionData?.questionData;
  return (
    <>
      <Box px={6} py={3}>
        <Typography variant="h6" color="textPrimary">
          Resources
        </Typography>
        <Box pl={1}>
          {questionItem?.question?.sql_sub_questions.map(
            (subQuestion: any, idx: number) => (
              <Typography variant="body1" key={idx}>
                <MuiLink
                  download
                  href={subQuestion.expected_output}
                  target="_blank"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    gridColumnGap="5px"
                    color={theme.palette.primary.main}
                  >
                    <GetAppIcon fontSize="small" />
                    <Typography color="inherit">
                      {' '}
                      Expected Output
                    </Typography>
                  </Box>
                </MuiLink>
              </Typography>
            )
          )}
        </Box>
        <Box pl={1}>
          <Typography
            variant="body1"
            key={questionItem?.question?.id}
          >
            <MuiLink
              download
              href={questionItem?.question?.db_schema}
              target="_blank"
            >
              <Box
                display="flex"
                flexDirection="row"
                gridColumnGap="5px"
                color={theme.palette.primary.main}
              >
                <GetAppIcon fontSize="small" />
                <Typography color="inherit"> DB Schema</Typography>
              </Box>
            </MuiLink>
          </Typography>
        </Box>
      </Box>
      {/* <Box px={6} py={3}>
        <Typography variant="h6" color="textPrimary">
          Expected Output Query
        </Typography>
        <Box pl={1}>
          {questionItem?.question?.sql_sub_questions.map(
            (subQuestion: any, idx: number) => (
              <Typography variant="body1" key={idx}>
                {subQuestion?.expected_result_query}
              </Typography>
            )
          )}
        </Box>
      </Box> */}
    </>
  );
};

export default SqlSubQuestion;

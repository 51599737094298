import React from 'react';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box/Box';

import SaveButton from 'business/organizer/library/components/common/SaveButton';
import InputWithLabel from 'common/components/form/InputWithLabel';
import MyInput from 'common/components/form/MyInput';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { OrganizationDetailsValidationSchema } from 'business/organizer/settings/utils/validationSchema';
import useAlert from 'common/components/alert-provider/useAlert';
import LogoUpload from 'business/organizer/settings/components/organization/LogoUpload';

const organizerService = new OrganizerService();

const OrganizationDetailsUpdate: React.FC<IOrganizationDetailsUpdate> =
  ({ organizationDetails }) => {
    const { enqueueAlert } = useAlert();

    const initialState: IInitialStateOrganizationDetailsFormProps = {
      name: organizationDetails?.name || '',
      org_url: organizationDetails?.org_url || '',
      logo: organizationDetails?.logo || '',
    };

    const handleSubmit = async (data: any) => {
      try {
        const { name, org_url, logo } = data;
        const organizationDetailsData = {
          name: name,
          org_url: org_url,
          //don't send logo if the user does not upload any new logo. By defualt logo contains the url of already uploaded logo.
          ...((logo === '' || typeof logo !== 'string') && {
            logo: logo,
          }),
        };
        const response = await organizerService.patchOrganization(
          organizationDetails.url,
          organizationDetailsData
        );
        if (response) {
          enqueueAlert('Updated successfully', {
            alertProps: { severity: 'success' },
          });
        }
      } catch (error) {
        enqueueAlert(
          'Something went wrong. Please try again after sometime',
          {
            alertProps: { severity: 'error' },
          }
        );
      }
    };

    return (
      <>
        <Formik
          initialValues={initialState}
          validationSchema={OrganizationDetailsValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputWithLabel
                title="Company name"
                downMD="subtitle2"
                upMD="subtitle2"
                isRequired={true}
              >
                <MyInput
                  placeholder="Company name"
                  name="name"
                  type="text"
                />
              </InputWithLabel>

              <InputWithLabel
                title="Company url"
                downMD="subtitle2"
                upMD="subtitle2"
                isRequired={false}
              >
                <MyInput
                  placeholder="https://example.com"
                  name="org_url"
                  type="text"
                />
              </InputWithLabel>

              <InputWithLabel
                title="Logo"
                downMD="subtitle2"
                upMD="subtitle2"
                isRequired={false}
              >
                <LogoUpload initialStateLogo={initialState.logo} />
              </InputWithLabel>

              <Box display="flex" justifyContent="flex-end">
                <SaveButton
                  buttonText="Save"
                  disabled={isSubmitting}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  };

export default OrganizationDetailsUpdate;

import { useRef } from 'react';
import useDropdown from 'common/components/use-dropdown/useDropdown';

const useOnHoverDropdown = () => {
  const { anchorEl, handleClick, handleClose } = useDropdown();
  const closeTimeoutRef = useRef<number | null>(null);

  /**
   * Hides the dropdown after a small delay. This is so the user gets a chance to
   * move the cursor into the dropdown menu. Which is responsible for cancelling
   * this timeout id.
   */
  const closeDropdownWithDelay = () => {
    closeTimeoutRef.current = window.setTimeout(handleClose, 100);
  };

  const handleDropdownMouseEnter = () => {
    // The cursor left the dropdown button and entered the dropdown menu.
    // So we cancel the close call we made on mouse leave from the button.
    if (closeTimeoutRef.current)
      clearTimeout(closeTimeoutRef.current);
  };

  return {
    anchorEl,
    handleClick,
    handleClose,
    handleDropdownMouseEnter,
    closeDropdownWithDelay,
  };
};

export default useOnHoverDropdown;

export default function toTitleCase(s: string) {
  if (s.length <= 0) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const toTitleSnakeCase = (string: string) =>
  string
    .split('_')
    .map((word) => toTitleCase(word))
    .join(' ');

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SpinnerButton from 'common/components/spinner-button/SpinnerButton';

const InterviewFormActions: React.FC<{
  onClose: () => void;
  isSubmitting: boolean;
  spinnerButtonName: string;
}> = ({ onClose, isSubmitting, spinnerButtonName }) => {
  return (
    <Box
      bgcolor="#FAFAFA"
      display="flex"
      justifyContent="flex-end"
      gridColumnGap={12}
      borderRadius={8}
      px={8}
      py={4}
    >
      <Button variant="outlined" color="primary" onClick={onClose}>
        Cancel
      </Button>
      <SpinnerButton
        type="submit"
        disabled={isSubmitting}
        showDisableSpinner
      >
        {spinnerButtonName}
      </SpinnerButton>
    </Box>
  );
};

export default InterviewFormActions;

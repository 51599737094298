import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TestStats from 'business/organizer/assessment/components/dashboard/common/TestStats';
import useAlert from 'common/components/alert-provider/useAlert';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { TESTS_COUNT_API_URL } from 'business/organizer/common/utils/constants';

const organizerService = new OrganizerService();

/**
 * we are creating the test ifo component
 * because we dont want to render the home
 * component when count changes
 * */
const TestsInfo = () => {
  const { enqueueAlert } = useAlert();

  const [testsCount, setTestsCount] = useState({
    active: 0,
    archive: 0,
    draft: 0,
  });

  useEffect(() => {
    let mounted = true;

    organizerService
      .authGet(TESTS_COUNT_API_URL)
      .then((response) => {
        if (mounted) {
          const { active, archive, draft } = response?.data;
          setTestsCount({ active, archive, draft });
        }
      })
      .catch((error) =>
        enqueueAlert(
          'Something went wrong, please try again after sometime',
          {
            alertProps: { severity: 'error' },
          }
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <TestStats
          list="active"
          title="Active tests"
          count={
            testsCount && typeof testsCount.active === 'number'
              ? testsCount.active
              : 0
          }
          color="primary"
        />
      </Grid>
      <Grid item xs={12}>
        <TestStats
          list="archive"
          title="Archived tests"
          count={
            testsCount && typeof testsCount.archive === 'number'
              ? testsCount.archive
              : 0
          }
          color="error"
        />
      </Grid>
    </>
  );
};

export default TestsInfo;

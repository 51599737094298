import React, { useState } from 'react';

const useDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDropdown = (
    event: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (anchorEl === null && event) handleClick(event);
    else handleClose();
  };

  return { anchorEl, handleClick, handleClose, toggleDropdown };
};

export default useDropdown;

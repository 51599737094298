import React from 'react';
import { FieldAttributes, useField, Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core';

const MyCheckbox: React.FC<FieldAttributes<{}>> = ({
  children,
  ...inputProps
}) => {
  const classes = useStyles();

  const [field, meta] = useField<{}>({
    ...inputProps,
    type: 'checkbox',
  });
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.root}
    >
      <Box display="flex" alignItems="flex-start">
        <Field type="checkbox" {...field} as={Checkbox} />
        {children}
      </Box>
      <Box>
        {meta.touched && meta.error ? (
          <FormHelperText error={!!errorText}>
            {meta.error}
          </FormHelperText>
        ) : null}
      </Box>
    </Box>
  );
};

export default MyCheckbox;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .PrivateSwitchBase-root-94, .MuiIconButton-root': {
      padding: 4,
      [theme.breakpoints.down('md')]: {
        padding: 2,
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: theme.spacing(3),
    },
  },
}));

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const SectionHeader: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.container__root}>
      <Box
        py={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{title}</Typography>
        {children}
      </Box>
    </Container>
  );
};

export default SectionHeader;

const useStyles = makeStyles((theme: Theme) => ({
  container__root: {
    background: '#f1f6f9',
  },
}));

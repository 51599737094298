import {
  SAVE_TIME_IMAGE,
  UNBAISED_HIRING_IMAGE,
  HIRE_RATIO_IMAGE,
  TECHNICAL_SKILLS_IMAGE,
  PEKING,
  CAPGEMINI,
  CISCO,
  TRELL,
} from 'business/pre-subscription/auth/utils/constants';

export const whyDphiData = [
  {
    id: 1,
    imageUrl: SAVE_TIME_IMAGE,
    altProp: 'save time',
    content: 'Saves Time and Find Great Talent Faster',
  },
  {
    id: 2,
    imageUrl: UNBAISED_HIRING_IMAGE,
    altProp: 'unbaised hiring',
    content: 'Unbiased Hiring',
  },
  {
    id: 3,
    imageUrl: HIRE_RATIO_IMAGE,
    altProp: 'improve hire ratio',
    content: 'Improves Interview to Hire ratio',
  },
  {
    id: 4,
    imageUrl: TECHNICAL_SKILLS_IMAGE,
    altProp: 'technical skills',
    content: 'Focused on practical technical skills',
  },
];

export const statsData = [
  {
    id: 1,
    title: '60k+',
    subtitle: 'Assessments',
  },
  {
    id: 2,
    title: '25k+',
    subtitle: 'Developer Community',
  },
  {
    id: 3,
    title: '140+',
    subtitle: 'Countries',
  },
  {
    id: 4,
    title: '80+',
    subtitle: 'Programming Languages',
  },
];

export const enterpriseLogos = [
  {
    id: 1,
    image: CISCO,
    altProp: 'Cisco',
  },

  {
    id: 2,
    image: CAPGEMINI,
    altProp: 'Capgemini',
  },
  {
    id: 3,
    image: PEKING,
    altProp: 'Peking University',
  },
  {
    id: 4,
    image: TRELL,
    altProp: 'Trell',
  },
];

export const freeTrialBenefits = [
  'Create and conduct 10 developer hiring tests',
  'Auto evaluate developers in your hiring pipeline without bias',
  'Get support to 80+ programming languages',
  'Access 100+ repository of coding and data science questions',
];

export const hubsFreeTrialBenefits = [
  'Create and conduct tests for the learners of your organization',
  'Auto evaluate learners on coding and data science projects',
  'Get support to 40+ programming languages',
];
// A function which takes a string and array and pushes the string to the array if it is not already in the array
export const pushToArray = (
  array: Array<string>,
  string: string
): Array<string> => {
  if (!array.includes(string)) {
    array.push(string);
  }
  return array;
};

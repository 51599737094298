import { useEffect, useState } from 'react';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useAlert from 'common/components/alert-provider/useAlert';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';

const organizerService = new OrganizerService();

const useCandidateListCount = () => {
  const { enqueueAlert } = useAlert();

  const { testDetail } = useTestDetail();
  const [count, setCount] =
    useState<IUseCandidateListCountProps | null>(null);
  const { hasChanges } = useCandidateProgress();

  useEffect(() => {
    if (testDetail?.candidate_count_url && hasChanges) {
      organizerService
        .get(testDetail?.candidate_count_url)
        .then((response) => {
          setCount(response?.data);
        })
        .catch((error) =>
          enqueueAlert(
            `Error while getting the count, ${error.message}`,
            {
              alertProps: { severity: 'error' },
            }
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testDetail?.candidate_count_url, hasChanges]);

  return { count };
};

export default useCandidateListCount;

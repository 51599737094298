import { useState, useCallback, useRef } from 'react';
import { AxiosError } from 'axios';

/**
 * @HowToUseIt
 * Checkout the example here: /business/organizer/library/components/question-create-update/coding/testcases/TestcasesUploadButton.tsx
 */

const usePolling = <T>(maxPollCount: number = 20) => {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [pollingStatus, setPollingStatus] = useState<PollingStatus>(
    'idle'
  );

  const pollCount = useRef(0);
  const previousPollDone = useRef(true);

  const handlePollingStatusFn = useCallback(
    (status: PollingStatus) => {
      setPollingStatus(status);
    },
    []
  );

  const resetPolling = useCallback((reset?: () => void) => {
    if (reset) {
      reset();
    }
    setPollingStatus('idle');
    pollCount.current = 0;
    previousPollDone.current = true;
  }, []);

  const runPolling = useCallback(
    ({
      promise,
      pollIntervalId,
      onPreviousPollDone,
    }: IRunPollingProps) => {
      if (!promise) return;

      if (!previousPollDone.current) return;
      if (pollCount.current >= maxPollCount) {
        pollCount.current = 0;
        setPollingStatus('stopped');
        window.clearInterval(pollIntervalId);
        return;
      }
      previousPollDone.current = false;
      onPreviousPollDone(previousPollDone.current);

      setPollingStatus('ongoing');
      promise
        .then((response) => setResponse(response?.data))
        .catch((error) => setError(error))
        .finally(() => {
          previousPollDone.current = true;
          onPreviousPollDone(previousPollDone.current);
        });

      pollCount.current += 1;
    },
    [maxPollCount]
  );

  return {
    response,
    setResponse,
    setError,
    error,
    runPolling,
    pollingStatus,
    setPollingStatus,
    handlePollingStatusFn,
    resetPolling,
    isOngoing: pollingStatus === 'ongoing',
    isStopped: pollingStatus === 'stopped',
    isIdle: pollingStatus === 'idle',
  };
};

export default usePolling;

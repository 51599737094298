import React from 'react';
import Box from '@material-ui/core/Box';

import InputWithLabel from 'common/components/form/InputWithLabel';
import MyInput from 'common/components/form/MyInput';

const TargetVariableName = () => (
  <InputWithLabel
    title="Target variable name"
    downMD="subtitle2"
    upMD="subtitle2"
    isRequired={true}
    tooltip={true}
    tooltipTitle="The target variable name should match the one you uploaded above (under target variable predictions)"
  >
    <Box display="flex" width="30%">
      <MyInput
        name="targetVariableName"
        placeholder="Enter name"
        type="text"
      />
    </Box>
  </InputWithLabel>
);

export default TargetVariableName;

import { useEffect } from 'react';
import Box from '@material-ui/core/Box';

import HubsCandidateDetailTabs from 'hubs-candidate/common/components/CandidateDetailTabs';
import CandidateTestDetails from 'hubs-candidate/common/components/candidate-detail/CandidateTestDetails';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import { HubsCandidateService } from 'hubs-candidate/services/hubsCandidateService';
import useAsync from 'common/custom-hooks/useAsync';

const hubsCandidateService = new HubsCandidateService();

const HubsCandidateProgressDetailPage = ({
  viewResultsUrl,
  testItem,
}: {
  viewResultsUrl: string;
  testItem: ITestItemProps;
}) => {
  const {
    status,
    error,
    data,
    run,
  }: IUseAsyncReturnProps<IHubsCandidateDetailPageProps> = useAsync();

  useEffect(() => {
    if (viewResultsUrl !== null) {
      run(hubsCandidateService.get(viewResultsUrl));
    }
  }, [run, viewResultsUrl]);
  const question_summary = data?.results;

  return (
    <>
      <GenericResponseHandler status={status} error={error}>
        <>
          <CandidateTestDetails
            candidateDetails={testItem}
            questionsSummary={question_summary}
          />
          <Box py={4}>
            <HubsCandidateDetailTabs
              question_summary={question_summary}
            />
          </Box>
        </>
      </GenericResponseHandler>
    </>
  );
};

export default HubsCandidateProgressDetailPage;

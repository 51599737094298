import { useState } from 'react';
import { useHistory } from 'react-router';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import SidebarItem from 'business/organizer/assessment/components/candidate-progress/candidate-list/SidebarItem';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useInterviews from 'business/organizer/interviews/context/useInterviews';

const Sidebar: React.FC<Interviews.ISidebarProps> = ({
  statusRoutes,
  all,
  verdictRoutes,
}) => {
  const { url } = useCurrentUrl();
  const history = useHistory();
  const [selectedRadio, setSelectedRadio] = useState<null | string>(
    null
  );
  const { isLoaded } = useInterviews();

  const handleSelectedRadio = (value: string) => {
    setSelectedRadio(value);
  };

  const handleChange = (_: any, value: string) => {
    handleSelectedRadio(value);
    history.push(`${url}/${value}`);
  };

  return (
    <Box bgcolor="#fff" borderRadius="5px" border="1px solid #eee">
      <Box px={4} pt={4} pb={2}>
        <Typography variant="h6">Filter</Typography>
      </Box>
      <Box px={1} mb={4}>
        <Divider />
      </Box>
      <SidebarItem
        title="All Interviews"
        routes={all}
        value={selectedRadio}
        onChange={handleChange}
        onSelectedRadio={handleSelectedRadio}
      />
      <SidebarItem
        title="Interview Status"
        routes={statusRoutes}
        value={selectedRadio}
        onChange={handleChange}
        onSelectedRadio={handleSelectedRadio}
        isLoaded={isLoaded}
      />
      <SidebarItem
        title="Verdict"
        routes={verdictRoutes}
        value={selectedRadio}
        onChange={handleChange}
        onSelectedRadio={handleSelectedRadio}
        isLoaded={isLoaded}
      />
    </Box>
  );
};

export default Sidebar;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import FileUploadPage from 'business/organizer/library/pages/question-create-update/file-upload/FileUploadPage';
import useQuestionUpdateData from 'business/organizer/library/custom-hooks/useQuestionUpdateData';
import useFileUploadData from 'business/organizer/library/contexts/file-upload-question/custom-hooks/useFileUploadData';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import QuestionErrorHandler from 'business/organizer/library/components/question-create-update/common/QuestionErrorHandler';
import QuestionFormSkeleton from 'business/organizer/common/components/skeletons/QuestionFormSkeleton';

const FileUploadUpdatePage = () => {
  let { fileUploadId } = useParams<{ fileUploadId: string }>();
  const {
    questionItem,
    responseError,
    status,
  } = useQuestionUpdateData(
    fileUploadId,
    MY_LIBRARY_QUESTION_API_URL
  );
  const { setFileUpload } = useFileUploadData();

  // clear the effects
  useEffect(() => {
    if (questionItem) {
      setFileUpload(questionItem);
    }
    return () => {
      setFileUpload(null);
    };
  }, [setFileUpload, questionItem]);

  return (
    <GenericResponseHandler
      status={status}
      error={responseError}
      errorPlaceholder={
        <QuestionErrorHandler error={responseError} />
      }
      skeleton={<QuestionFormSkeleton />}
    >
      <FileUploadPage fileUpload={questionItem} drawerName="edit" />
    </GenericResponseHandler>
  );
};

export default FileUploadUpdatePage;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { PopperProps } from '@material-ui/core';

const SelectLanguage = ({
  languages,
  onLanguageChange,
  defaultValue,
  popperComponent,
}: {
  languages: ILanguage[];
  defaultValue: ILanguage;
  onLanguageChange: any;
  popperComponent?: React.ComponentType<PopperProps> | undefined;
}) => {
  const filterOptions = createFilterOptions({
    ignoreCase: true,
    // Internally, the autocomplete filter will check for the inputted text
    // in this string.
    stringify: (option: ILanguage) => `${option.name}`,
    trim: true,
  });

  return (
    <Autocomplete
      id="select programming language"
      onChange={(_, value) => {
        if (value) {
          onLanguageChange(value);
        }
      }}
      options={languages as ILanguage[]}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.label}
      filterOptions={filterOptions}
      disableClearable
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose Language"
          margin="normal"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
      PopperComponent={popperComponent}
    />
  );
};

export default SelectLanguage;

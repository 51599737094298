import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import MyCheckbox from 'common/components/form/MyCheckbox';

const ProctorCheckbox: React.FC<IProctorCheckboxProps> = ({
  name,
  disabled,
  display_name,
  description,
}) => (
  <FormControl disabled={disabled}>
    <MyCheckbox
      name={name}
      disabled={disabled}
      children={
        <Box pl={1}>
          <Typography
            variant="body1"
            color={disabled ? 'inherit' : 'textPrimary'}
          >
            {display_name}
          </Typography>
          <Typography
            variant="body1"
            color={disabled ? 'inherit' : 'textSecondary'}
          >
            {description}
          </Typography>
        </Box>
      }
    />
  </FormControl>
);

export default ProctorCheckbox;

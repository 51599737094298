import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import RestoreIcon from '@material-ui/icons/Restore';
import { makeStyles } from '@material-ui/core';

import CustomModal from 'common/components/custom-modal/CustomModal';
import CreateInterviewForm from './CreateInterviewForm';
import useOnHoverDropdown from 'common/components/onHover-dropdown/useOnHoverDropdown';
import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import { dropdownStyles } from 'business/organizer/common/utils/styles/dropdownStyles';

const CreateInterview = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const {
    anchorEl,
    handleClick,
    handleClose,
    handleDropdownMouseEnter,
    closeDropdownWithDelay,
  } = useOnHoverDropdown();

  const handleModal = (isScheduled: boolean) => {
    setOpen(true);
    setIsScheduled(isScheduled);
  };

  return (
    <>
      <Button
        onMouseEnter={handleClick}
        onMouseLeave={closeDropdownWithDelay}
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={
          anchorEl !== null ? <ExpandLessIcon /> : <ExpandMoreIcon />
        }
      >
        Create Interview
      </Button>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
        MenuListProps={{
          onMouseEnter: handleDropdownMouseEnter,
          onMouseLeave: handleClose,
        }}
      >
        <Box
          onClick={() => handleModal(false)}
          className={classes.box_root}
        >
          <ListItem divider>
            <ListItemIcon>
              <FlashOnIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
              Instant Interview
            </ListItemText>
          </ListItem>
        </Box>
        <Box
          onClick={() => handleModal(true)}
          className={classes.box_root}
        >
          <ListItem>
            <ListItemIcon>
              <RestoreIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
              Schedule for later
            </ListItemText>
          </ListItem>
        </Box>
      </DropdownMenu>
      <CustomModal open={open} width="590px">
        <CreateInterviewForm
          onClose={() => setOpen(false)}
          isScheduled={isScheduled}
        />
      </CustomModal>
    </>
  );
};

export default CreateInterview;

const useStyles = makeStyles((theme) => ({
  box_root: {
    cursor: 'pointer',
    '& .MuiListItem-root:hover': {
      width: '100%',
      backgroundColor: 'rgba(182, 217, 251, 0.15)',
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(10),
    },
  },
}));

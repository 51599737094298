export const cbStartUpConfigMonthly = {
  type: 'checkout',
  planId: 'startup-monthly',
};

export const cbStartUpConfigYearly = {
  type: 'checkout',
  planId: 'startup-yearly',
};

export const cbMicroConfigMonthly = {
  type: 'checkout',
  planId: 'micro-monthly',
};

export const cbMicroConfigYearly = {
  type: 'checkout',
  planId: 'micro-yearly',
};

export const cbGrowthConfigMonthly = {
  type: 'checkout',
  planId: 'growth-monthly',
};

export const cbGrowthConfigYearly = {
  type: 'checkout',
  planId: 'growth-yearly',
};

export const cbInterviewsConfigMonthly = {
  type: 'checkout',
  planId: 'interview-monthly',
};

export const cbInterviewsConfigYearly = {
  type: 'checkout',
  planId: 'interview-yearly',
};

export const cbNewGrowthConfigMonthly = {
  type: 'checkout',
  planId: 'premium',
};

export const cbNewGrowthConfigYearly = {
  type: 'checkout',
  planId: 'premium-yearly',
};

// Hubs
// Monthly
export const cbEduInstitutesStartUpConfigMonthly = {
  type: 'checkout',
  planId: 'edu-institutes-starter-plan-monthly',
};

export const cbOrganizationStartUpConfigMonthly = {
  type: 'checkout',
  planId: 'edu-org-starter-plan-monthly',
};

// Yearly
export const cbEduInstitutesStartUpConfigYearly = {
  type: 'checkout',
  planId: 'edu-institution-starter-plan-yearly',
};

export const cbOrganizationStartUpConfigYearly = {
  type: 'checkout',
  planId: 'edu-org-starter-plan-yearly',
};

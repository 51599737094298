export const getCandidateRegistrationField = (
  registration_responses: ICandidateRegistrationField[],
  field: string
) =>
  registration_responses.filter(
    ({ field_name }: ICandidateRegistrationField) =>
      field_name === field
  )[0].response;

export const submissionStatusList: { [key: string]: string } = {
  WT: 'Waiting',
  QD: 'Queued',
  JD: 'Judging',
  IE: 'Internal Error',
  EE: 'Evaluation Error',
  AC: 'Accepted',
  WA: 'Wrong Answer',
};

import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import CustomModal from 'common/components/custom-modal/CustomModal';
import HttpError from 'common/components/http-error/HttpError';
import ImageHelper from 'common/components/image-helper/ImageHelper';
import useTests from 'business/organizer/assessment/contexts/assessment-tests/useTests';

import {
  ROLES_API_URL,
  NO_SKILLS_IMAGE,
} from 'business/organizer/common/utils/constants';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const organizerService = new OrganizerService();

const filter = createFilterOptions<any>();

const TestCreationModal: React.FC<ITestCreationModalProps> = ({
  openTestCreationModal,
  closeTestCreationModal,
  addQuestionToTest,
}) => {
  const classes = useStyles();
  const [error, setError] = useState<AxiosError | null>(null);
  const [currentRole, setCurrentRole] =
    useState<IRoleItemProps | null>(null);
  const [skills, setSkills] = useState<ISkillItemProp[] | null>(null);
  const [hasAutoAddQuestion, setHasAutoAddQuestion] =
    useState<boolean>(false);
  const [autoAddQuestion, setAutoAddQuestion] =
    useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const {
    handleSelectedRole,
    handleTestCreation,
    roles,
    setRoles,
    customRole,
    onCustomRole,
    handleCustomRole,
  } = useTests();
  const { isHubsUrl } = useCurrentUrl();
  const getRoleByName = (role_name: string) => {
    if (roles === null) {
      return null;
    }
    var role = roles.filter(
      (role: IRoleItemProps) => role.name === role_name
    );
    if (!(role.length > 0)) {
      return null;
    }
    return role[0];
  };
  const setSelectedRole = (role_name: string) => {
    const role = getRoleByName(role_name);
    if (role !== null) {
      // Handle existing roles
      handleSelectedRole(role.id);
      setCurrentRole(role);
      if (role.skills.length > 0) {
        setSkills(role.skills);
      } else {
        setSkills(null);
      }
      setHasAutoAddQuestion(role.has_auto_add_question);
    } else {
      // handle custom roles
      resetCurrentRoleStates();
      handleCustomRole();
      onCustomRole(role_name);
    }
    setDisabled(false);
  };
  const resetCurrentRoleStates = () => {
    setCurrentRole(null);
    setSkills(null);
    setHasAutoAddQuestion(false);
  };

  if (customRole && currentRole !== null) {
    resetCurrentRoleStates();
  }

  const handleRoleSearchBarOnChange = (
    _: any,
    newValue: string | IRoleSearchOption | null
  ) => {
    if (typeof newValue === 'string') {
      setSelectedRole(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input (custom role handling)
      setSelectedRole(newValue.inputValue);
    } else {
      if (newValue !== null) {
        setSelectedRole(newValue.name);
      } else {
        // Reset states and disable proceed button when search bar is empty
        resetCurrentRoleStates();
        setDisabled(true);
      }
    }
  };

  useEffect(() => {
    let mounted = true;

    organizerService
      .authGet(ROLES_API_URL)
      .then((response) => {
        if (mounted) {
          setRoles(response?.data);
        }
      })
      .catch((error) => setError(error));

    return () => {
      mounted = false;
    };
  }, [setRoles]);
  return (
    <CustomModal open={openTestCreationModal}>
      <Container maxWidth="xl">
        <Box py={4}>
          <Box textAlign="center" py={4}>
            <Typography variant="h3" align="center">
              {isHubsUrl ? 'Choose Test type' : 'Choose Role to Hire'}
            </Typography>
          </Box>
          {error !== null ? (
            <HttpError
              axiosError={error}
              message="Not able to load the roles. Please try again."
            />
          ) : roles === null ? (
            <p>skeleton</p>
          ) : (
            <Box
              textAlign="left"
              py={4}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Box py={6} width="100%">
                <Autocomplete
                  onChange={handleRoleSearchBarOnChange}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Create new role "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="role-search-bar"
                  options={roles?.map(
                    (role: IRoleItemProps): IRoleSearchOption => ({
                      name: role.name,
                    })
                  )}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option?.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option?.name || '';
                  }}
                  renderOption={(option: IRoleSearchOption | null) =>
                    option?.name
                  }
                  style={{ width: '100%' }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      textAlign="left"
                      variant="standard"
                      className={classes.role_search_text}
                      placeholder="Search Roles"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </Box>
              {skills !== null ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={12}
                  height="200px"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gridGap={8}
                    py={2}
                  >
                    <Typography variant="h5" align="left">
                      Skills:
                    </Typography>
                    <Tooltip
                      title="Skills belonging to this role"
                      placement="top"
                    >
                      <InfoIcon
                        color="disabled"
                        className={classes.tooltip_icon}
                      />
                    </Tooltip>
                  </Box>
                  <Box
                    display="flex"
                    gridGap={4}
                    flexDirection="row"
                    flexWrap="wrap"
                  >
                    {skills.map((skill: ISkillItemProp) => (
                      <Chip
                        color="primary"
                        label={skill.name}
                        className={classes.chips}
                      />
                    ))}
                  </Box>
                </Box>
              ) : (
                <ImageHelper
                  imageUrl={NO_SKILLS_IMAGE}
                  altProp="noSkillsImage"
                  lazyLoad
                  className={classes.no_skills_image}
                />
              )}
            </Box>
          )}
          <Box
            py={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridColumnGap={12}
          >
            <Tooltip
              title={
                hasAutoAddQuestion
                  ? 'Auto add questions that are related to this role'
                  : 'Auto adding questions are disabled for this role. We request you to create the test and select questions from our vast library'
              }
              placement="right"
              arrow
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gridGap={2}
              >
                <FormControlLabel
                  className={classes.auto_add_question_checkbox}
                  control={
                    <Checkbox
                      checked={autoAddQuestion}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => setAutoAddQuestion(event.target.checked)}
                      disabled={!hasAutoAddQuestion}
                      color="primary"
                    />
                  }
                  label="Auto add questions"
                />
                <InfoIcon
                  color="disabled"
                  className={classes.tooltip_icon}
                />
              </Box>
            </Tooltip>

            <Box display="flex" gridGap={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={closeTestCreationModal}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setDisabled(true);
                  handleTestCreation(
                    'hiring',
                    '',
                    autoAddQuestion && hasAutoAddQuestion,
                    addQuestionToTest
                  );
                }}
                disabled={disabled}
                endIcon={<ArrowRightAltIcon />}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </CustomModal>
  );
};

export default TestCreationModal;

const useStyles = makeStyles((theme) => ({
  chips: {
    backgroundColor: '#DBEDFF',
    color: '#444444',
  },
  role_search_text: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(10, 102, 194, 0.15)',
    borderRadius: '4px',
    padding: 4,
  },
  no_skills_image: {
    alignSelf: 'center',
  },
  auto_add_question_checkbox: {
    marginRight: '5px',
  },
  tooltip_icon: {
    fontSize: 20,
  },
}));

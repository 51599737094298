import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import TableBase from 'common/components/table/TableBase';
import TablePaginationBoxStyles from 'business/organizer/assessment/components/candidate-progress/common/TablePaginationBoxStyles';
import InviteMemberModal from 'business/organizer/settings/components/team-management/InviteMemberModal';
import useAsync from 'common/custom-hooks/useAsync';
import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import HttpError from 'common/components/http-error/HttpError';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import Actions from 'business/organizer/settings/components/team-management/Actions';
import usePaginationParams from 'common/custom-hooks/usePaginationParams';
import { getDayjsDate } from 'common/utils/dayjsUtils';
import { sliceString } from 'common/utils/sliceString';

const organizerService = new OrganizerService();
const Members = () => {
  const { organizer } = useOrganizer();
  const {
    run,
    data: members,
    error,
    reset,
  }: IUseAsyncReturnProps<IOrganizationMembersList> = useAsync();
  const [openModal, setOpenModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const onReset = () => {
    reset();
    setIsLoaded(false);
  };

  const { params, handlePageChange, handleRowsPerPageChange } =
    usePaginationParams({ resetFn: onReset });

  useEffect(() => {
    if (organizer?.invitation_url && !isLoaded) {
      run(
        organizerService.getOrganizationMembers(
          organizer?.invitation_url as string,
          params
        )
      );
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    run,
    organizer?.invitation_url,
    params.page,
    params.pageSize,
    isLoaded,
  ]);

  if (error !== null) {
    return (
      <Box py={12}>
        <HttpError axiosError={error} />
      </Box>
    );
  }

  return (
    <>
      <Box py={8} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Add Team Member
        </Button>
        <InviteMemberModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onReset={onReset}
        />
      </Box>
      <TableBase tableProps={{ 'aria-label': 'members' }}>
        <TableHead>
          <TableRow>
            <TableCell>Email Address</TableCell>
            <TableCell align="center">Full Name</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Job Title</TableCell>
            <TableCell align="center">Last Activity</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        {members !== null && (
          <TableBody>
            {members.results.map((member) => (
              <TableRow key={member.id}>
                <TableCell>
                  {sliceString(member.to_email, 20)}
                </TableCell>
                <TableCell align="center">
                  {member?.full_name
                    ? sliceString(member.full_name, 20)
                    : '-'}
                </TableCell>
                <TableCell align="center">{member.status}</TableCell>
                <TableCell align="center">
                  {member?.job_title
                    ? sliceString(member.job_title, 20)
                    : '-'}
                </TableCell>
                <TableCell align="center">
                  {member?.last_activity
                    ? getDayjsDate({
                        date: member?.last_activity,
                        format: "DD MMM[']YY HH[:]mm:ss",
                      })
                    : '-'}
                </TableCell>
                <TableCell align="center">
                  {member.user_role.display_name}
                </TableCell>
                <TableCell>
                  <Actions onReset={onReset} member={member} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </TableBase>
      {members === null && <GenericListSkeleton items={3} />}
      <Box py={6}>
        <TablePaginationBoxStyles
          count={members?.count}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </Box>
    </>
  );
};

export default Members;

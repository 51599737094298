import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const HasPermissionBase: React.FC<{
  children: React.ReactNode;
  condition: boolean;
  tooltipProps: Omit<TooltipProps, 'children'>;
}> = ({ children, condition, tooltipProps }) => {
  return (
    <>
      {condition ? (
        <Tooltip {...tooltipProps}>
          <span>{children}</span>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default HasPermissionBase;

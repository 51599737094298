import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import useTestQuestions from 'business/organizer/assessment/contexts/test-questions/useTestQuestions';
import TestSummary from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/TestSummary';
import QuestionsGroup from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/QuestionsGroup';

const QuestionList: React.FC<ITestQuestionListProps> = ({
  anchorEl,
  handleClick,
  closeDropdownWithDelay,
}) => {
  const classes = useStyles();
  const { testQuestionTypes } = useTestQuestions();

  const getQuestionsCount = useCallback(
    (questions: { is_hidden: boolean }[]) => {
      return questions.filter(({ is_hidden }) => is_hidden === false)
        .length;
    },
    []
  );

  return (
    <>
      <Box display="flex" width="100%">
        <Container maxWidth={false}>
          <Box display="flex" flexDirection="column" gridRowGap={16}>
            {testQuestionTypes.coding !== null &&
              testQuestionTypes.coding.length !== 0 && (
                <QuestionsGroup
                  title="CODING"
                  count={getQuestionsCount(testQuestionTypes.coding)}
                  questions={testQuestionTypes.coding}
                />
              )}
            {testQuestionTypes.challenge !== null &&
              testQuestionTypes.challenge.length !== 0 && (
                <QuestionsGroup
                  title="AI/MACHINE LEARNING CHALLENGES"
                  count={getQuestionsCount(
                    testQuestionTypes.challenge
                  )}
                  questions={testQuestionTypes.challenge}
                />
              )}
            {testQuestionTypes.mcq !== null &&
              testQuestionTypes.mcq.length !== 0 && (
                <QuestionsGroup
                  title="Multiple Choice Questions"
                  count={getQuestionsCount(testQuestionTypes.mcq)}
                  questions={testQuestionTypes.mcq}
                />
              )}
            {testQuestionTypes.freeText !== null &&
              testQuestionTypes.freeText.length !== 0 && (
                <QuestionsGroup
                  title="FREE TEXT"
                  count={getQuestionsCount(
                    testQuestionTypes.freeText
                  )}
                  questions={testQuestionTypes.freeText}
                />
              )}
            {testQuestionTypes.fileUpload !== null &&
              testQuestionTypes.fileUpload.length !== 0 && (
                <QuestionsGroup
                  title="FILE UPLOAD"
                  count={getQuestionsCount(
                    testQuestionTypes.fileUpload
                  )}
                  questions={testQuestionTypes.fileUpload}
                />
              )}
            {/* {testQuestionTypes.project !== null &&
              testQuestionTypes.project.length !== 0 && (
                <QuestionsGroup
                  title="TAKE HOME ASSIGNMENT"
                  count={getQuestionsCount(testQuestionTypes.project)}
                  questions={testQuestionTypes.project}
                />
              )} */}
            {testQuestionTypes.sql !== null &&
              testQuestionTypes.sql.length !== 0 && (
                <QuestionsGroup
                  title="SQL"
                  count={getQuestionsCount(testQuestionTypes.sql)}
                  questions={testQuestionTypes.sql}
                />
              )}
          </Box>
        </Container>
        <Container
          maxWidth={false}
          className={classes.container__test_summary}
        >
          <TestSummary
            anchorEl={anchorEl}
            handleClick={handleClick}
            closeDropdownWithDelay={closeDropdownWithDelay}
          />
        </Container>
      </Box>
    </>
  );
};

export default QuestionList;

const useStyles = makeStyles((theme) => ({
  container__test_summary: {
    width: '500px !important',
    borderLeft: '1px solid #ddd',
  },
}));

const getDrawerWidth = (drawerList: string[], drawerName: string) => {
  if (
    Math.abs(drawerList.indexOf(drawerName)) + 1 ===
    drawerList.length
  ) {
    return 60;
  } else if (
    Math.abs(drawerList.indexOf(drawerName)) + 2 ===
    drawerList.length
  ) {
    return 90;
  } else if (
    Math.abs(drawerList.indexOf(drawerName)) + 3 ===
    drawerList.length
  ) {
    return 100;
  } else {
    return 60;
  }
};

export default getDrawerWidth;

import React from 'react';
import { useParams } from 'react-router-dom';

import useQuestionUpdateData from 'business/organizer/library/custom-hooks/useQuestionUpdateData';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import QuestionErrorHandler from 'business/organizer/library/components/question-create-update/common/QuestionErrorHandler';
import QuestionFormSkeleton from 'business/organizer/common/components/skeletons/QuestionFormSkeleton';
import SqlPage from './SqlPage';

const SqlUpdatePage = () => {
  let { sqlId } = useParams<{ sqlId: string }>();
  const { questionItem, responseError, status } =
    useQuestionUpdateData(sqlId, MY_LIBRARY_QUESTION_API_URL);

  return (
    <GenericResponseHandler
      status={status}
      errorPlaceholder={
        <QuestionErrorHandler error={responseError} />
      }
      skeleton={<QuestionFormSkeleton />}
    >
      <SqlPage sql={questionItem} drawerName="edit" />
    </GenericResponseHandler>
  );
};

export default SqlUpdatePage;

import React from 'react';
import CustomMuiLink from 'common/components/custom-mui-link/CustomMuiLink';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const FooterIcon: React.FC<IFooterIconProps> = ({ Icon, route }) => (
  <ListItemIcon>
    <CustomMuiLink href={route} target="_blank">
      <Icon />
    </CustomMuiLink>
  </ListItemIcon>
);

export default FooterIcon;

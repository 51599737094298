import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import authService from 'common/services/authService';

interface IPrivateRoute {
  children: JSX.Element;
  path: string;
  exact?: boolean;
  redirectUrl: string;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  children,
  path,
  exact,
  redirectUrl,
}) => {
  const { url } = useRouteMatch();

  if (!authService.isAuthenticated) {
    window.location.href = `${redirectUrl}?next=${url}`;
    return <></>;
  }
  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

export default PrivateRoute;

import React from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';

import BillingCardStyles from 'business/organizer/assessment/components/billing/common/BillingCardStyles';
import Features from 'business/organizer/assessment/components/billing/common/Features';

const CustomPlan: React.FC<ICustomPlanProps> = ({
  children,
  planTitle,
  planSubtitle,
  planSubtitleDescription,
  planDescription,
}) => {
  return (
    <BillingCardStyles>
      <Box
        py={4}
        px={2}
        height={60}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" align="center">
          {planTitle}
        </Typography>
        <Typography align="center">{planSubtitle}</Typography>
      </Box>
      <Divider />
      <Box p={4} height={320}>
        <Box py={4}>
          <Typography variant="subtitle1" align="center">
            {planSubtitleDescription}
          </Typography>
        </Box>
        <Features features={planDescription} />
      </Box>
      <Box
        height={144}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box py={8}>{children}</Box>
      </Box>
    </BillingCardStyles>
  );
};

export default CustomPlan;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useQuestionSubmission from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useQuestionSubmission';
import QuestionSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionSolution';
import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';

const FreeTextSolution: React.FC<IFreeTextQuestionSolutionProps> = ({
  submissionUrl,
}) => {
  const { submission, responseError } =
    useQuestionSubmission(submissionUrl);

  return (
    <QuestionSolution
      submission={submission}
      responseError={responseError}
    >
      <Box py={2}>
        <Box py={2}>
          <Typography variant="h6">Candidate Solution</Typography>
        </Box>
        <Box p={2} border="1px solid #A3A3A3" borderRadius={5}>
          <RichTextRenderer
            richTextHtml={submission?.submission?.text}
          />
        </Box>
      </Box>
    </QuestionSolution>
  );
};

export default FreeTextSolution;

import React, { useState } from 'react';
import { AxiosError } from 'axios';

import useAlert from 'common/components/alert-provider/useAlert';
import TestCasesContext from 'business/organizer/library/contexts/coding-question/contexts/TestCasesContext';
import useCodingQuestionData from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestionData';
import useCodingQuestion from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestion';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import { hasMessageProperty } from 'common/utils/errors';

const libraryService = new LibraryService();

const TestCasesProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { enqueueAlert } = useAlert();
  const { coding } = useCodingQuestionData();
  const { problemStatementResponse } = useCodingQuestion();
  const [testCases, setTestCases] = useState<any | null>(null);
  const [testCasesResponseError, setTestCasesResponseError] =
    useState<AxiosError | null>(null);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const onCloseModal = () => setShowModal(false);
  const onShowModal = () => setShowModal(true);

  const handleTestCaseForm = async (
    data: IInitialStateTestCaseFormProps,
    closeModal: () => void
  ) => {
    try {
      const {
        score,
        time_limit,
        input_file,
        output_file,
        is_sample,
        url,
      } = data;
      let testcasesData: any = {
        score,
        time_limit,
        is_sample,
      };

      if (!!input_file) {
        testcasesData.input_file = input_file;
      }
      if (!!output_file) {
        testcasesData.output_file = output_file;
      }

      url
        ? await libraryService.patchTestCase(url, testcasesData)
        : await libraryService.createTestCase(
            coding?.question?.testcases_url ||
              problemStatementResponse?.question?.testcases_url,
            testcasesData
          );

      closeModal();
      setTestCases(null);
      setIsLoading(true);
      enqueueAlert(`Test case is ${url ? 'updated' : 'added'}.`, {
        alertProps: { severity: 'success' },
      });
    } catch (error) {
      enqueueAlert(
        `Not able to save the test case: ${
          hasMessageProperty(error) ? error.message : ''
        }. Please try again.`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <TestCasesContext.Provider
      value={{
        testCases,
        testCasesResponseError,
        showModal,
        onCloseModal,
        onShowModal,
        handleTestCaseForm,
        setTestCases,
        setTestCasesResponseError,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </TestCasesContext.Provider>
  );
};

export default TestCasesProvider;

import * as Yup from 'yup';

export const validationSchema = [
  Yup.object({
    name: Yup.string()
      .min(5, 'Must be 5 characters or more')
      .required('This field is required')
      .typeError('Text type is required'),
    description: Yup.string()
      .min(20, 'Must be 20 characters or more')
      .required('This field is required')
      .typeError('Text type is required'),
    tags: Yup.array()
      .min(1, 'Please add at least 1 tag')
      .required('This field is required'),
    time_duration: Yup.number()
      .required('This field is required')
      .typeError('Value must be numbers'),
    score: Yup.number()
      .required('This field is required')
      .typeError('Value must be numbers'),
    difficulty: Yup.string()
      .oneOf(
        ['easy', 'medium', 'hard'],
        'This is not the valid choice'
      )
      .required('This field is required'),
    file_type: Yup.string()
      .oneOf(
        ['pdf', 'txt', 'zip', 'ipynb'],
        'This is not the valid choice'
      )
      .required('This field is required'),
    max_file_size: Yup.number()
      .lessThan(
        101,
        'Max file size should be less than or equal to 100 mb'
      )
      .required('This field is required')
      .typeError('Value must be numbers'),
  }),
];

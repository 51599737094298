import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import SearchBar from 'common/components/search-bar/SearchBar';
import debounce from 'common/utils/debounce';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import SidebarItem from 'business/organizer/assessment/components/candidate-progress/candidate-list/SidebarItem';
import redirect from 'common/utils/redirect';
import { HTTP_DEBOUNCE_TIME } from 'business/organizer/library/utils/constants';

const CandidateListSidebar: React.FC<ICandidateListSidebarProps> = ({
  submissionRoutes,
  inviteRoutes,
  allRoutes,
}) => {
  const history = useHistory();

  const { setData, setSearch } = useCandidateProgress();

  const handleSearch = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setData(null);
        setSearch(event.target.value);
      }, HTTP_DEBOUNCE_TIME),
    [setData, setSearch]
  );

  const [selectedRadio, setSelectedRadio] = useState<null | string>(
    null
  );

  const handleSelectedRadio = (value: string) => {
    setSelectedRadio(value);
  };

  const handleChange = (_: any, value: string) => {
    handleSelectedRadio(value);
    redirect(history, `../${value}`);
  };

  return (
    <Box bgcolor="#fff" borderRadius="5px" border="1px solid #eee">
      <Box px={1} py={4}>
        <SearchBar handleSearch={handleSearch} />
      </Box>
      <Divider />
      <Box pt={4}>
        <SidebarItem
          title="All Candidates"
          routes={allRoutes}
          value={selectedRadio}
          onChange={handleChange}
          onSelectedRadio={handleSelectedRadio}
        />
        <SidebarItem
          title="Candidate Submission"
          routes={submissionRoutes}
          value={selectedRadio}
          onChange={handleChange}
          onSelectedRadio={handleSelectedRadio}
        />
        <SidebarItem
          title="Candidate Invites"
          routes={inviteRoutes}
          value={selectedRadio}
          onChange={handleChange}
          onSelectedRadio={handleSelectedRadio}
        />
      </Box>
    </Box>
  );
};

export default CandidateListSidebar;

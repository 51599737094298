import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const TestcasesTable: React.FC<{
  testCases?:
    | ICodingSolutionTestcasesProps[]
    | ISqlSubQuestionProps[];
}> = ({ testCases }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>TestCase</TableCell>
            <TableCell align="center">Score</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">Memory</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!testCases &&
            testCases.map((testcase: any, idx: number) => (
              <TableRow key={idx}>
                <TableCell>Testcase {idx + 1}</TableCell>
                <TableCell align="center">
                  {testcase?.score || '-'}
                </TableCell>
                <TableCell align="center">
                  {testcase?.submission_status?.name}
                </TableCell>
                <TableCell align="center">{testcase?.time}</TableCell>
                <TableCell align="center">
                  {testcase?.memory}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TestcasesTable;

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import MonacoEditor from 'common/components/monaco-editor/MonacoEditor';

const CandidateCode: React.FC<{
  language: IProgrammingLanguage | null;
  code: string | null;
  menuOption: ILanguage;
}> = ({ language, code, menuOption }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box py={2}>
        <Typography variant="h6">Candidate Code</Typography>
      </Box>
      <Box border="1px solid #000000" bgcolor="#fff">
        {!!language && (
          <MonacoEditor
            value={code || ''}
            languages={[menuOption]}
            options={{
              readOnly: true,
              minimap: {
                enabled: false,
              },
            }}
            onChange={() => {}}
            className={classes.monaco__editor}
          />
        )}
      </Box>
    </Box>
  );
};

export default CandidateCode;

const useStyles = makeStyles((theme) => ({
  monaco__editor: {
    minHeight: theme.spacing(100),
  },
}));

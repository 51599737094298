import React from 'react';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Box from '@material-ui/core/Box';
import { makeStyles, Typography } from '@material-ui/core';

import FileField from 'common/components/form/file-field/FileField';
import FileFieldResults from 'common/components/form/file-field/FileFieldResults';
import useOnDropFile from 'common/components/form/custom-hooks/useOnDropFile';
import SampleCsvDropdown from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/SampleCsvDropdown';
import useOnHoverDropdown from 'common/components/onHover-dropdown/useOnHoverDropdown';
import { disableFileField } from 'common/components/form/file-field/utils';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const BulkUploadField = ({ name }: { name: string }) => {
  const classes = useStyles();

  const {
    anchorEl,
    handleClick,
    closeDropdownWithDelay,
    handleClose,
    handleDropdownMouseEnter,
  } = useOnHoverDropdown();

  const { onDrop } = useOnDropFile({ name });

  const { values } = useFormikContext<IInviteFormInitialState>();
  const { isViewer } = useHasPermission();

  const dropzoneOptions = {
    accept: '.csv',
    maxFiles: 1,
    onDrop,
    multiple: false,
    maxSize: 5242880, //5mb
  };

  return (
    <>
      <HasPermission
        isViewer={isViewer}
        tooltipProps={{ placement: 'top', arrow: true }}
      >
        <FileField
          dropzoneOptions={dropzoneOptions}
          name={name}
          rootProps={{
            onClick: disableFileField(isViewer),
          }}
        >
          <>
            <Box display="flex" alignItems="center" gridColumnGap={4}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={isViewer}
              >
                {!values.bulk_invite_csv
                  ? 'Bulk Upload Emails'
                  : 'Change File'}
              </Button>
              <Typography
                onMouseEnter={handleClick}
                onMouseLeave={closeDropdownWithDelay}
              >
                <HelpOutlineIcon
                  className={classes.icon__bulk_upload}
                />
              </Typography>
            </Box>
            <ErrorMessage name={name}>
              {(msg) => (
                <FormHelperText error={true}>{msg}</FormHelperText>
              )}
            </ErrorMessage>
            <FileFieldResults />
          </>
        </FileField>
      </HasPermission>
      <SampleCsvDropdown
        anchorEl={anchorEl}
        handleClose={handleClose}
        MenuListProps={{
          onMouseEnter: handleDropdownMouseEnter,
          onMouseLeave: handleClose,
        }}
      />
    </>
  );
};

export default BulkUploadField;

const useStyles = makeStyles((theme) => ({
  icon__bulk_upload: {
    cursor: 'pointer',
    color: '#666',
    fontSize: 18,
  },
}));

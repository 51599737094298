import React from 'react';
import Box from '@material-ui/core/Box';

import Shortlist from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/Shortlist';
import Reject from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/Reject';

const Reviewed: React.FC<{
  disableShortlist: boolean;
  disableReject: boolean;
  isListPage?: boolean;
}> = ({ disableShortlist, disableReject, isListPage = false }) => {
  return (
    <Box px={2} display="flex" alignItems="center" gridColumnGap={8}>
      <Shortlist
        disabled={disableShortlist}
        isListPage={isListPage}
      />
      <Reject disabled={disableReject} isListPage={isListPage} />
    </Box>
  );
};

export default Reviewed;

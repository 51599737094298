import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Tooltip, useTheme, Zoom } from '@material-ui/core';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import NotebookPreview from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/challenge/NotebookPreview';
import { submissionStatusList } from 'business/organizer/assessment/components/candidate-progress/candidate-detail/utils/helpers';

const ChallengeSolutionBase: React.FC<{
  submission: IChallengeSubmissionBaseProps;
}> = ({ submission }) => {
  const theme = useTheme();
  const { url } = useCurrentUrl();

  const [nbDrawer, setNbDrawer] = useState(false);

  const { toggleDrawer } = useToggleDrawer();

  const getNotebookId = (submission: any) => {
    if (submission && submission?.notebook_url) {
      const splitNotebookUrl = submission?.notebook_url.split('/');
      const notebookId =
        splitNotebookUrl[splitNotebookUrl.length - 2];
      return notebookId;
    }
  };

  const notebookId = getNotebookId(submission);

  return (
    <>
      <Box
        bgcolor="#FFFFFF"
        py={4}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="0px 3px 6px #DDE6ED"
        borderRadius={5}
      >
        <Box>
          <Typography variant="subtitle2">Result</Typography>
          <Typography variant="body1" align="center">
            {submissionStatusList[submission?.status]}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            Model Prediction Score
          </Typography>
          <Typography variant="body1" align="center">
            {submission?.practice_score}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            Evaluation Metric
          </Typography>
          <Typography variant="body1" align="center">
            {submission?.evaluation_metric[0]?.evaluator?.name}
          </Typography>
        </Box>
      </Box>
      <Box
        py={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={theme.palette.info.main}
      >
        <Tooltip
          title={
            !submission?.notebook_url
              ? 'Notebook is not Uploaded'
              : 'Click to Open Notebook'
          }
          placement="top"
          TransitionComponent={Zoom}
          arrow
        >
          <span>
            <Button
              variant="text"
              disabled={!submission?.notebook_url}
              color="inherit"
              endIcon={<OpenInNewIcon color="inherit" />}
              onClick={toggleDrawer(
                true,
                setNbDrawer,
                'notebook',
                `${url}/notebook/${notebookId}`
              )}
            >
              View Notebook
            </Button>
          </span>
        </Tooltip>
        <CustomDrawer
          drawer={nbDrawer}
          setDrawer={setNbDrawer}
          drawerName="notebook"
        >
          <Route path={`${url}/notebook/${notebookId}`}>
            <>
              <CloseDrawerIcon
                closeQuestionDrawer={toggleDrawer(
                  false,
                  setNbDrawer,
                  'notebook'
                )}
              />
              {!!submission.notebook_url && (
                <NotebookPreview apiUrl={submission.notebook_url} />
              )}
            </>
          </Route>
        </CustomDrawer>
      </Box>
    </>
  );
};

export default ChallengeSolutionBase;

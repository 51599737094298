import React, { useState, useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';

import useAlert from 'common/components/alert-provider/useAlert';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import { hasMessageProperty } from 'common/utils/errors';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const organizerService = new OrganizerService();

const CommonQuestionSummaryTableItem: React.FC<ICommonQuestionSummaryTableItemProps> =
  ({
    submission,
    questionScore,
    children,
    idx,
    questionName,
    questionType,
    showSolution = true,
  }) => {
    const { enqueueAlert } = useAlert();
    const classes = useStyles();

    const [addScore, setAddScore] = useState(false);
    const [score, setScore] = useState('');
    const [candidateScore, setCandidateScore] = useState(0);
    const [error, setError] = useState(false);

    const { setQuestionsSummary, setUpdatedScoreLifeCycle } =
      useCandidateProgressDetail();
    const { isViewer } = useHasPermission();

    const handleScoreChange = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      setError(false);
      setScore(event.target.value);
    };

    const handleClose = () => {
      setScore(JSON.stringify(candidateScore));
      setError(false);
      setAddScore(false);
    };

    const handleAddScore = async () => {
      try {
        const errors: { [key: string]: string } = {};
        let schema = Yup.number().required();

        let isValidScore = await schema.isValid(score);

        if (!isValidScore) {
          setError(true);
          errors.message = 'please add valid score';
        }

        let isLessThan = await schema
          .max(questionScore)
          .isValid(score);

        if (!isLessThan) {
          setError(true);
          errors.message =
            'Score should be less than or equal to total score.';
        }

        if (isValidScore && isLessThan) {
          const data = {
            score: parseInt(score),
          };

          // keep track of score to update candidates list
          setUpdatedScoreLifeCycle((scores) => [
            ...(scores || []),
            submission?.score || 0,
          ]);

          const response = await organizerService.patch(
            submission?.url,
            data
          );

          setQuestionsSummary((questionsSummary: any) => {
            return questionsSummary.map((question: any) => {
              if (question.id === response?.data.id) {
                question.submission.score =
                  response?.data.submission.score;
                return question;
              }
              return question;
            });
          });
          setCandidateScore(response?.data.submission.score);
          setAddScore(false);

          // keep track of score to update candidates list
          setUpdatedScoreLifeCycle((scores) => [
            ...(scores || []),
            response?.data.submission.score,
          ]);

          enqueueAlert('Score is added.', {
            alertProps: { severity: 'success' },
          });
        } else {
          enqueueAlert(errors.message, {
            alertProps: { severity: 'error' },
          });
        }
      } catch (error) {
        setError(true);

        enqueueAlert(
          submission?.url
            ? `Not able to add the score. ${
                hasMessageProperty(error) ? error.message : ''
              }`
            : `Candidate hasn't submitted the solution. ${
                hasMessageProperty(error) ? error.message : ''
              }`,
          {
            alertProps: { severity: 'error' },
          }
        );
      }
    };

    useEffect(() => {
      if (submission?.score) {
        setCandidateScore(submission?.score);
        setScore(submission?.score);
      }
    }, [submission?.score]);

    return (
      <>
        <TableRow>
          <TableCell>{idx + 1}</TableCell>
          <TableCell>{questionName}</TableCell>
          <TableCell align="center">
            {questionType.replace('-', ' ')}
          </TableCell>
          <TableCell align="center">
            {submission !== null && (!candidateScore || addScore) ? (
              <>
                {addScore ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Box width={100}>
                      <TextField
                        placeholder="score"
                        variant="outlined"
                        value={score}
                        onChange={handleScoreChange}
                        error={error}
                      />
                    </Box>
                    <Box display="flex">
                      <CheckCircleRoundedIcon
                        onClick={handleAddScore}
                        color="primary"
                        className={classes.icon__cursor}
                      />
                      <CancelRoundedIcon
                        onClick={handleClose}
                        color="error"
                        className={classes.icon__cursor}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pl={8}
                    fontSize="inherit"
                  >
                    <Typography variant="inherit">
                      0/{`${questionScore}`}
                    </Typography>
                    <HasPermission
                      isViewer={isViewer}
                      tooltipProps={{ placement: 'top', arrow: true }}
                    >
                      <IconButton
                        disabled={isViewer}
                        onClick={() => setAddScore(true)}
                      >
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </HasPermission>
                  </Box>
                )}
              </>
            ) : (
              <>
                {candidateScore ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pl={8}
                    fontSize="inherit"
                  >
                    <Typography variant="inherit">
                      {candidateScore}/{questionScore}
                    </Typography>
                    <HasPermission
                      isViewer={isViewer}
                      tooltipProps={{ placement: 'top', arrow: true }}
                    >
                      <IconButton
                        disabled={isViewer}
                        onClick={() => setAddScore(true)}
                      >
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </HasPermission>
                  </Box>
                ) : (
                  '-'
                )}
              </>
            )}
          </TableCell>
          {showSolution && (
            <TableCell align="center">{children}</TableCell>
          )}
        </TableRow>
      </>
    );
  };

export default CommonQuestionSummaryTableItem;

const useStyles = makeStyles((theme) => ({
  icon__cursor: {
    cursor: 'pointer',
  },
}));

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useDropdown from 'common/components/use-dropdown/useDropdown';
import TestActionMenu from 'business/organizer/assessment/components/assessment-tests/test-list/TestActionMenu';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import useAlert from 'common/components/alert-provider/useAlert';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import {
  FRONTEND_ACTIVE_URL,
  FRONTEND_ARCHIVE_URL,
} from 'business/organizer/common/utils/constants';
import useTests from 'business/organizer/assessment/contexts/assessment-tests/useTests';

enum TestStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  ARCHIVE = 'archived',
}

const organizerService = new OrganizerService();

const TestActions: React.FC<ITestActionsProps> = ({ testItem }) => {
  const { enqueueAlert } = useAlert();
  const classes = useStyles();

  const { url, isHubsUrl } = useCurrentUrl();
  const history = useHistory();
  const { anchorEl, handleClick, handleClose } = useDropdown();

  const { setArchiveResponse, setPublishResponse } = useTests();
  const [archiveNotification, setArchiveNotification] =
    useState(false);
  const [publishNotification, setPublishNotification] =
    useState(false);

  const handleEditClick = () => {
    history.push(`${url}/${testItem.id}`);
  };

  const handleArchiveClick = async () => {
    try {
      const response = await organizerService.archiveTest(
        testItem.archive_url
      );

      if (response) {
        setArchiveNotification(false);
        enqueueAlert(`Test is archived.`, {
          alertProps: { severity: 'success' },
        });
        setArchiveResponse(response);
        history.push(
          isHubsUrl ? `hubs/${FRONTEND_ARCHIVE_URL}` : FRONTEND_ARCHIVE_URL
        );
      }
    } catch (error) {
      setArchiveNotification(false);
      enqueueAlert(`Not able to archive test`, {
        alertProps: { severity: 'error' },
      });
    }
  };

  const handlePublishClick = async () => {
    try {
      const response = await organizerService.publishtTest(
        testItem.publish_url
      );
      if (response) {
        setPublishNotification(false);
        enqueueAlert(`Test is archived.`, {
          alertProps: { severity: 'success' },
        });
        setPublishResponse(response);
        history.push(
          isHubsUrl ? `hubs/${FRONTEND_ACTIVE_URL}` : FRONTEND_ACTIVE_URL
        );
      }
    } catch (error) {
      setPublishNotification(false);
      enqueueAlert(
        `Not able to publish the test. Please try again.`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  const activeTestsMenu = [
    {
      id: 0,
      label: 'Edit',
      handleClick: handleEditClick,
    },
    {
      id: 1,
      label: 'Archive',
      handleClick: () => setArchiveNotification(true),
    },
  ];

  const draftTestsMenu = [
    {
      id: 0,
      label: 'Archive',
      handleClick: () => setArchiveNotification(true),
    },
    {
      id: 2,
      label: 'Edit',
      handleClick: handleEditClick,
    },
    {
      id: 3,
      label: 'Publish',
      handleClick: () => setPublishNotification(true),
    },
  ];

  const archiveTestsMenu = [
    {
      id: 0,
      label: 'Edit',
      handleClick: handleEditClick,
    },
    {
      id: 1,
      label: 'Publish',
      handleClick: () => setPublishNotification(true),
    },
  ];

  const getMessage = () => {
    if (archiveNotification) {
      return 'Are you sure you want to archive it ?';
    } else if (testItem?.restrict_publish) {
      return 'Please make sure that the test has at least one question added and all questions in the assessment should include score and time.';
    } else if (!testItem?.is_complete) {
      return 'This test contains one or more incomplete questions which may cause problems while solving test by candidate, Do you still want to publish it?';
    } else {
      return 'Do you want to publish the test ?';
    }
  };

  return (
    <>
      <NotificationDialog
        open={archiveNotification || publishNotification}
        variant={
          archiveNotification ||
            !testItem?.is_complete ||
            testItem?.restrict_publish
            ? 'warning'
            : 'info'
        }
        agreeText={
          archiveNotification
            ? 'Archive'
            : testItem?.restrict_publish
              ? undefined
              : 'Publish'
        }
        disagreeText="Close"
        onDisagree={() => {
          setArchiveNotification(false);
          setPublishNotification(false);
        }}
        onAgree={
          archiveNotification
            ? handleArchiveClick
            : handlePublishClick
        }
        content={getMessage()}
      />
      <IconButton
        onClick={handleClick}
        className={classes.icon_button__question_action}
      >
        <MoreVertIcon />
      </IconButton>
      <TestActionMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        actionsMenu={
          testItem.status === TestStatus.ACTIVE
            ? activeTestsMenu
            : testItem.status === TestStatus.DRAFT
              ? draftTestsMenu
              : archiveTestsMenu
        }
      />
    </>
  );
};

export default TestActions;

const useStyles = makeStyles((theme) => ({
  icon_button__question_action: {
    padding: '9px',
  },
}));

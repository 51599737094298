import React from 'react';

const LinkedInIcon = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M3.64195 14.8596V4.9419H0.369813V14.8596H3.64195ZM2.00631 3.587C3.14736 3.587 3.85761 2.82544 3.85761 1.87374C3.83635 0.90058 3.1474 0.160156 2.02796 0.160156C0.908691 0.160156 0.176758 0.900595 0.176758 1.87374C0.176758 2.82548 0.886833 3.587 1.98494 3.587H2.0062H2.00631ZM5.45307 14.8596H8.72521V9.32106C8.72521 9.02465 8.74647 8.72855 8.83288 8.51665C9.06943 7.92443 9.60783 7.31105 10.5117 7.31105C11.6958 7.31105 12.1695 8.22053 12.1695 9.55377V14.8595H15.4414V9.17281C15.4414 6.12649 13.8271 4.70907 11.6742 4.70907C9.90905 4.70907 9.13401 5.70307 8.70344 6.38008H8.72528V4.9417H5.45315C5.49609 5.87232 5.45315 14.8594 5.45315 14.8594L5.45307 14.8596Z"
        fill="#0A66C2"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="15.2648"
          height="14.6994"
          fill="white"
          transform="translate(0.175781 0.160156)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedInIcon;

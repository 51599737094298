import { useEffect, useState } from 'react';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';

const organizerService = new OrganizerService();
const useCandidateProgressList = <T extends unknown>({
  apiUrl,
  candidateStatus,
  score,
}: {
  apiUrl: string;
  candidateStatus?: string;
  score? : 1 | 0 ;
}): IUseCandidateProgressListProps<T> => {
  const [params, setParams] =
    useState<IGetCandidateProgressListParams>({
      page: 0,
      pageSize: 10,
      search: '',
      status: !candidateStatus ? [] : [candidateStatus],
      test_status: [],
      order: 'desc',
      order_by: '',
      score,
    });

  const {
    run,
    data,
    error,
    status,
    setData,
    reset,
    setHasChanges,
    hasChanges,
    search,
    ...otherProps
  } = useCandidateProgress();

  const handleChangePage = (e: any, page: number) => {
    reset();
    setHasChanges(true);
    setParams((params) => ({ ...params, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    reset();
    setHasChanges(true);
    setParams((params) => ({
      ...params,
      pageSize: parseInt(event.target.value),
      page: 0,
    }));
  };

  const handleSort = (field: string, order: IOrder) => {
    reset();
    setHasChanges(true);
    setParams((params) => ({
      ...params,
      page: 0,
      order,
      order_by: field,
    }));
  };

  useEffect(() => {
    if (search) {
      setParams((params) => ({ ...params, search }));
      setHasChanges(true);
    }
  }, [search, setHasChanges]);

  useEffect(() => {
    if (hasChanges) {
      setHasChanges(false);
      run(organizerService.getCandidateProgressList(apiUrl, params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiUrl,
    candidateStatus,
    params.page,
    params.pageSize,
    params.search,
    params.order_by,
    params.order,
    params.test_status,
    params.status,
    params.score,
    hasChanges,
    run,
  ]);

  return {
    data,
    error,
    status,
    setData,
    params,
    setParams,
    reset,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSort,
    ...otherProps,
  };
};

export default useCandidateProgressList;

import React, { useState } from 'react';

import IncompleteQuestionsContext from 'business/organizer/library/contexts/incomplete-questions/IncompleteQuestionsContext';

const IncompleteQuestionsProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [incompleteQuestions, setIncompleteQuestions] = useState<
    IIncompleteQuestionProps[]
  >([]);

  return (
    <IncompleteQuestionsContext.Provider
      value={{ incompleteQuestions, setIncompleteQuestions }}
    >
      {children}
    </IncompleteQuestionsContext.Provider>
  );
};

export default IncompleteQuestionsProvider;

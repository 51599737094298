import React from 'react';
import { FieldAttributes, useField } from 'formik';
import TextField, {
  TextFieldProps,
} from '@material-ui/core/TextField';

const MyInput: React.FC<
  FieldAttributes<{}> & { muiInputProps?: TextFieldProps }
> = ({ placeholder, muiInputProps, type, ...inputProps }) => {
  const [field, meta] = useField<{}>(inputProps);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      fullWidth
      variant="outlined"
      {...field}
      type={type}
      placeholder={placeholder}
      {...(muiInputProps || {})}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

export default MyInput;

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import MyInput from 'common/components/form/MyInput';
import InputWithLabel from 'common/components/form/InputWithLabel';
import TagsField from 'common/components/form/TagsField';
import MySelect from 'common/components/form/MySelect';
import RichTextField from 'common/components/form/RichTextField';
import useTags from 'business/organizer/library/custom-hooks/useTags';
import { difficultyItems } from 'business/organizer/library/components/question-create-update/challenge/utils/constants';
import { LIBRARY_QUESTION_TAG_TYPE } from 'business/organizer/library/utils/constants';

const ProblemStatement = () => {
  const { tagSuggestions } = useTags({
    type: LIBRARY_QUESTION_TAG_TYPE,
  });

  return (
    <Box display="flex" flexDirection="column" gridRowGap={12}>
      <InputWithLabel
        title="Question"
        downMD="subtitle2"
        upMD="subtitle2"
        isRequired={true}
      >
        <MyInput placeholder="" name="name" type="text" />
      </InputWithLabel>
      <InputWithLabel
        title="Question Description"
        downMD="subtitle2"
        upMD="subtitle2"
        isRequired={true}
      >
        <RichTextField name="description" />
      </InputWithLabel>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <InputWithLabel
            title="Maximum Score"
            downMD="subtitle2"
            upMD="subtitle2"
            isRequired={false}
            tooltip
            tooltipTitle="Add maximum score for this question"
          >
            <MyInput placeholder="Score" name="score" type="number" />
          </InputWithLabel>
        </Grid>
        <Grid item xs={4}>
          <InputWithLabel
            title="Max time duration"
            downMD="subtitle2"
            upMD="subtitle2"
            isRequired={false}
            tooltip
            tooltipTitle="Mention maximum time duration to solve this question"
          >
            <MyInput
              placeholder="Minutes"
              name="time_duration"
              type="number"
            />
          </InputWithLabel>
        </Grid>
        <Grid item xs={4}>
          <InputWithLabel
            title="Difficulty level"
            downMD="subtitle2"
            upMD="subtitle2"
            isRequired={false}
            tooltip
            tooltipTitle="For your reference"
          >
            <FormControl variant="outlined">
              <MySelect
                name="difficulty"
                placeholder="Level of Difficulty"
                type="string"
              >
                {difficultyItems.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </MySelect>
            </FormControl>
          </InputWithLabel>
        </Grid>
      </Grid>
      <InputWithLabel
        title="Tags"
        downMD="subtitle2"
        upMD="subtitle2"
        isRequired={false}
      >
        <TagsField tagSuggestions={tagSuggestions} />
      </InputWithLabel>
    </Box>
  );
};

export default ProblemStatement;

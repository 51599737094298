import { useState } from 'react';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography';

import useAlert from 'common/components/alert-provider/useAlert';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useIncompleteQuestions from 'business/organizer/library/contexts/incomplete-questions/useIncompleteQuestions';
import ConfirmationDialog from 'common/components/confirmation-dialog/ConfirmationDialog';
import ShowIncompleteQuestions from 'business/organizer/assessment/components/common/ShowIncompleteQuestions';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { hasMessageProperty } from 'common/utils/errors';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const organizerService = new OrganizerService();

const PublishTest = () => {
  const { enqueueAlert } = useAlert();
  const history = useHistory();
  const [publish, setPublish] = useState(false);
  const { testDetail, setTestDetail } = useTestDetail();

  const { incompleteQuestions } = useIncompleteQuestions();
  const { isHubsUrl } = useCurrentUrl();
  const handlePublish = async () => {
    try {
      const response = await organizerService.publishtTest(
        testDetail?.publish_url as string
      );
      setTestDetail(response?.data);

      setPublish(false);
      enqueueAlert('Test is published successfully', {
        alertProps: { severity: 'success' },
      });
      history.push(
        `${isHubsUrl ? '/hubs' : ''}/tests/${
          response?.data?.status
        }/${response?.data?.id}/questions`
      );
    } catch (error) {
      enqueueAlert(
        `Not able to publish the test. ${
          hasMessageProperty(error) ? error.message : ''
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
      setPublish(false);
    }
  };

  const incompleQuestionsLength = incompleteQuestions.length;
  return (
    <>
      <ConfirmationDialog
        open={publish}
        variant={
          !!incompleQuestionsLength || testDetail?.restrict_publish
            ? 'warning'
            : 'info'
        }
        disagreeText={testDetail?.restrict_publish ? 'Close' : 'No'}
        agreeText={testDetail?.restrict_publish ? undefined : 'Yes'}
        onDisagree={() => setPublish(false)}
        onAgree={handlePublish}
      >
        {testDetail?.restrict_publish ? (
          <Typography>
            Please make sure that the test has at least one question
            added and all questions in the assessment should include
            score and time.
          </Typography>
        ) : (
          <>
            {!!incompleQuestionsLength ? (
              <>
                <Typography>
                  Following questions are incomplete, Please complete
                  those questions otherwise it may cause confusion to
                  candidates, If you still want to continue, Click on
                  "Yes"
                </Typography>
                <ShowIncompleteQuestions />
              </>
            ) : (
              <Typography>
                Are you sure you want to publish the test?
              </Typography>
            )}
          </>
        )}
      </ConfirmationDialog>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setPublish(true)}
      >
        Publish Test
      </Button>
    </>
  );
};

export default PublishTest;

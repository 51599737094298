import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import MuiLink from '@material-ui/core/Link';
import GetAppIcon from '@material-ui/icons/GetApp';

import TestCaseForm from 'business/organizer/library/components/question-create-update/coding/testcases/TestCaseForm';
import useTestCases from 'business/organizer/library/contexts/coding-question/custom-hooks/useTestCases';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import useAlert from 'common/components/alert-provider/useAlert';
import { hasMessageProperty } from 'common/utils/errors';

const libraryService = new LibraryService();

const TestCasesListItem: React.FC<ITestCaseListItemProps> = ({
  testCase,
  idx,
}) => {
  const { enqueueAlert } = useAlert();

  const { setIsLoading, setTestCases } = useTestCases();

  const [showEditModal, setEditModal] = useState(false);

  const onCloseEditModal = () => setEditModal(false);
  const onShowEditModal = () => setEditModal(true);

  const [deleteTestCase, setDeleteTestCase] = useState(false);

  const handleDelete = async () => {
    try {
      await libraryService.delete(testCase.url);
      setDeleteTestCase(false);
      setTestCases(null);
      setIsLoading(true);
      enqueueAlert('Test case is deleted successfully.', {
        alertProps: { severity: 'success' },
      });
    } catch (error) {
      enqueueAlert(
        `Not able to delete the test case: ${
          hasMessageProperty(error) ? error.message : ''
        }. Please try again.`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <>
      <NotificationDialog
        open={deleteTestCase}
        variant="warning"
        onAgree={handleDelete}
        agreeText="Delete"
        disagreeText="Close"
        onDisagree={() => setDeleteTestCase(false)}
        content="Do you want delete the testcase. Once its deleted it can not be reverted"
      />
      <TableRow>
        <TableCell component="th" scope="row">
          Test Case&nbsp;{idx}&nbsp;
          <Typography
            component="span"
            variant="body1"
            color="secondary"
          >
            {testCase.is_sample ? '(sample)' : ''}
          </Typography>
        </TableCell>
        <TableCell align="center">{testCase?.score}</TableCell>
        <TableCell align="center">{testCase?.time_limit}</TableCell>
        <TableCell align="center">
          <MuiLink href={testCase?.input_file} target="_blank">
            <IconButton>
              <GetAppIcon />
            </IconButton>
          </MuiLink>
        </TableCell>
        <TableCell align="center">
          <MuiLink href={testCase?.output_file} target="_blank">
            <IconButton>
              <GetAppIcon />
            </IconButton>
          </MuiLink>
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={onShowEditModal}>
            <CreateIcon />
          </IconButton>
          <TestCaseForm
            showModal={showEditModal}
            onCloseModal={onCloseEditModal}
            testCase={testCase}
          />
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={() => setDeleteTestCase(true)}>
            <DeleteOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TestCasesListItem;

import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { countryCodeData } from 'common/utils/countryCode';
import { MixedSchema } from 'yup/lib/mixed';
import { jobTitlesList } from 'business/organizer/settings/utils/constants';
import { pushToArray } from '../../utils/utils';
import { FRONTNED_HUBS_CANDIDATE_URL } from 'common/utils/constants';

/**
 * Generates a yup schema for form.
 * @param form
 * @returns
 */

export const BLACKLIST_EMAILS = [
  'gmail',
  'hotmail',
  'yahoo',
  'outlook',
];

export const getValidationSchema = <T>(state: T) => {
  const keys = Object.keys(state);
  const schema: { [key: string]: Yup.BaseSchema } = {};

  const invalidEmailMessage = window.location.pathname.startsWith(
    FRONTNED_HUBS_CANDIDATE_URL
  )
    ? 'Please enter your email address'
    : 'Please enter work email address';

  keys.forEach((key) => {
    let fieldSchema;

    switch (key) {
      case 'email':
        fieldSchema = Yup.string()
          .email()
          .test('is-valid', invalidEmailMessage, (val) => {
            if (!val) return false;
            return window.location.pathname.startsWith(
              FRONTNED_HUBS_CANDIDATE_URL
            )
              ? true
              : !BLACKLIST_EMAILS.includes(
                  val.split('@')[1].split('.')[0]
                );
          })
          .required('Email is required');
        break;
      case 'password':
        fieldSchema = Yup.string()
          .min(6, 'Password length should be 6 characters or more')
          .required('Password is required');
        break;
      case 'confirmPassword':
        fieldSchema = Yup.string()
          .oneOf([Yup.ref('password'), null], 'Password must match')
          .required('Confirm your password');
        break;
      case 'first_name':
        fieldSchema = Yup.string()
          .max(
            16,
            'First name length should be 16 characters or less'
          )
          .required('First name is required');
        break;
      case 'last_name':
        fieldSchema = Yup.string().max(
          16,
          'Last name length should be 16 characters or less'
        );
        break;
      case 'country_code':
        fieldSchema = Yup.string()
          .test('is-valid', 'Invalid', (val) => {
            if (!val) return false;
            return (
              countryCodeData.find(
                (data) => data.phone === val?.toString()
              ) !== undefined
            );
          })
          .required('Required');
        break;
      case 'contact_number':
        fieldSchema = Yup.string()
          .when(
            'country_code',
            (countryCode: string, schema: MixedSchema) => {
              return schema.test(
                'is-valid',
                'Phone number is not valid',
                (val) => {
                  if (!val) return false;
                  if (!countryCode) return false;
                  const phoneUtil = PhoneNumberUtil.getInstance();
                  const number = `+${countryCode}${val}`;
                  try {
                    return phoneUtil.isValidNumber(
                      phoneUtil.parse(number)
                    );
                  } catch (err) {
                    return false;
                  }
                }
              );
            }
          )
          .required('Phone number is required');
        break;
      case 'company_name':
        fieldSchema = Yup.string().required(
          'Company name is required'
        );
        break;
      case 'current_role':
        let job_titles: Array<string> = [];

        jobTitlesList.edu_institution.forEach((job_title) => {
          pushToArray(job_titles, job_title.value);
        });
        jobTitlesList.edu_organization.forEach((job_title) => {
          pushToArray(job_titles, job_title.value);
        });
        jobTitlesList.skillspace_organization.forEach((job_title) => {
          pushToArray(job_titles, job_title.value);
        });

        fieldSchema = Yup.string()
          .oneOf(job_titles, 'Please select the valid option.')
          .required('Current role/Job title is required');
        break;
      default:
        break;
    }

    if (fieldSchema) {
      schema[key] = fieldSchema;
    }
  });

  return Yup.object(schema);
};

export const getErrorMessage = (
  error: IBusinessFormErrorProps | null
) => {
  if (error !== null) {
    let errorMessage;
    if (typeof error?.detail === 'string') {
      errorMessage = error?.detail;
    } else if (error instanceof Object) {
      errorMessage = Object.values(error)[0];
    } else {
      errorMessage =
        'Error, Please try again, If error persists contact at support@skillspace.app';
    }
    return errorMessage;
  }
};

export const AssessToHubsValidationSchema = [
  Yup.object({
    org_type: Yup.string()
      .oneOf(
        ['edu_organization', 'edu_institution'],
        'Please select the valid option.'
      )
      .required('Organization type is required'),
  }),
];

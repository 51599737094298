import { useState } from 'react';

const usePaginationParams = <T extends {}>({
  resetFn,
  otherInitialParams,
}: {
  resetFn: Function;
  otherInitialParams?: T;
}) => {
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    ...(otherInitialParams || {}),
  });

  const handlePageChange = (e: any, page: number) => {
    resetFn();
    setParams((params) => ({ ...params, page }));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetFn();
    setParams((params) => ({
      ...params,
      pageSize: parseInt(event.target.value),
      page: 0,
    }));
  };

  return {
    params,
    setParams,
    handlePageChange,
    handleRowsPerPageChange,
  };
};

export default usePaginationParams;

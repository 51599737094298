import React, { useState } from 'react';
import VisibilityOutlinedIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

import CustomModal from 'common/components/custom-modal/CustomModal';
import ImageVerificationModal from 'business/organizer/assessment/components/candidate-progress/candidate-detail/proctoring/ImageVerificationModal';

const ImageVerification: React.FC<{ apiUrl: string }> = ({
  apiUrl,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton size="small" color="default" onClick={() => setOpen(true)}>
        <VisibilityOutlinedIcon />
      </IconButton>
      <CustomModal open={open}>
        <ImageVerificationModal
          onClose={() => setOpen(false)}
          apiUrl={apiUrl}
        />
      </CustomModal>
    </div>
  );
};

export default ImageVerification;

export const INTERVIEW_API_ROOT_URL = '/api/interview/';

export const WS_EVENTS = {
  SYNC_CODE: 'sync_code',
  RUN_CODE: 'run_code',
  SAVE_WHITEBOARD: 'save_whiteboard',
  CHANGE_PROGRAMMING_LANGUAGE: 'change_programming_language',
  SYNC_INPUT: 'sync_code_input',
  JOIN: 'join',
  DISCONNECT: 'disconnect',
  END: 'end',
  ADD_QUESTION: 'add_question',
  EDITOR_EVENTS: 'editor_events',
  START_CALL: 'start_call',
};

export const THANK_YOU_IMAGE =
  '/static/images/interview/thank-you.png';

import React from 'react';

import CodingQuestionProvider from 'business/organizer/library/contexts/coding-question/provider/CodingQuestionProvider';
import CodingUpdatePageProvider from 'business/organizer/library/contexts/coding-question/provider/CodingUpdatePageProvider';
import ChallengeUpdatePageProvider from 'business/organizer/library/contexts/challenge-question/providers/ChallengeUpdatePageProvider';
import ChallengeQuestionProvider from 'business/organizer/library/contexts/challenge-question/providers/ChallengeQuestionProvider';
import FreeTextUpdateProvider from 'business/organizer/library/contexts/free-text-question/providers/FreeTextUpdateProvider';
import FreeTextProvider from 'business/organizer/library/contexts/free-text-question/providers/FreeTextProvider';
import FileUploadProvider from 'business/organizer/library/contexts/file-upload-question/providers/FileUploadProvider';
import FileUploadUpdateProvider from 'business/organizer/library/contexts/file-upload-question/providers/FileUploadUpdateProvider';
import McqProvider from 'business/organizer/library/contexts/mcq/McqProvider';
import ProjectProvider from 'business/organizer/library/contexts/project-question/ProjectProvider';
import SqlProvider from 'business/organizer/library/contexts/sql/SqlProvider';

const QuestionsProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  return (
    <ChallengeUpdatePageProvider>
      <ChallengeQuestionProvider>
        <CodingUpdatePageProvider>
          <CodingQuestionProvider>
            <FreeTextUpdateProvider>
              <FreeTextProvider>
                <FileUploadUpdateProvider>
                  <FileUploadProvider>
                    <McqProvider>
                      <SqlProvider>
                        <ProjectProvider>{children}</ProjectProvider>
                      </SqlProvider>
                    </McqProvider>
                  </FileUploadProvider>
                </FileUploadUpdateProvider>
              </FreeTextProvider>
            </FreeTextUpdateProvider>
          </CodingQuestionProvider>
        </CodingUpdatePageProvider>
      </ChallengeQuestionProvider>
    </ChallengeUpdatePageProvider>
  );
};

export default QuestionsProvider;

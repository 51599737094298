import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useHeader from 'business/organizer/common/context/header/useHeader';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CandidateListPage from 'business/organizer/assessment/pages/candidate-progress/candidate-list/CandidateListPage';
import CandidateProgressProvider from 'business/organizer/assessment/contexts/candidate-progress/CandidateProgressProvider';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';

const CandidateProgress = () => {
  const { setOpenSidebar } = useHeader();
  const { url } = useCurrentUrl();
  const { testDetail } = useTestDetail();

  useEffect(() => {
    setOpenSidebar(false);
  }, [setOpenSidebar]);

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Candidate Progress', route: url }]}
      />
      {testDetail === null ? (
        <Box py={8} width="100%">
          <GenericListSkeleton items={4} />
        </Box>
      ) : (
        <>
          {testDetail?.status === 'draft' ? (
            <Box
              width="100%"
              py={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h5" align="center">
                You haven't published the test.
              </Typography>
            </Box>
          ) : (
            <CandidateProgressProvider>
              <Switch>
                <Route path={`${url}/`}>
                  <CandidateListPage />
                </Route>
                <Redirect from={url} to={`${url}/`} />
              </Switch>
            </CandidateProgressProvider>
          )}
        </>
      )}
    </>
  );
};

export default CandidateProgress;

import React from 'react';
import { useDropzone } from 'react-dropzone';

import FileFieldContext from 'common/components/form/file-field/FileFieldContext';

const FileField: React.FC<IFileFieldProps> = ({
  dropzoneOptions,
  name,
  children,
  rootProps,
}) => {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
  } = useDropzone(dropzoneOptions);

  const maxFileSize = dropzoneOptions.maxSize;

  return (
    <div
      {...getRootProps({
        ...(rootProps || {}),
      })}
    >
      <input {...getInputProps()} name={name} />
      <FileFieldContext.Provider
        value={{ acceptedFiles, fileRejections, maxFileSize }}
      >
        {children}
      </FileFieldContext.Provider>
    </div>
  );
};

export default FileField;

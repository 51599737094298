import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import Card from 'business/organizer/assessment/components/dashboard/Card';
import {
  HOMEPAGE_TEST_IMAGE,
  HOMEPAGE_BILLING_IMAGE,
  HOMEPAGE_LIBRARY_IMAGE,
  FRONTEND_BUSINESS_TESTS_URL,
  FRONTEND_BUSINESS_BILLING_URL,
  FRONTEND_BUSINESS_LIBRARY_URL,
} from 'business/organizer/common/utils/constants';
import InviteStats from 'business/organizer/assessment/components/dashboard/InviteStats';
import TestsInfo from 'business/organizer/assessment/components/dashboard/TestsInfo';
import CustomMuiLink from 'common/components/custom-mui-link/CustomMuiLink';
import useUserContext from 'common/components/get-user/useUserContext';
import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';

const Dashboard = () => {
  const classes = useStyles();
  const { user } = useUserContext();
  const { isInterviewPlan, organizer } = useSubscription();
  const { isHubsUrl } = useCurrentUrl();

  const testCardData = useMemo(
    () => ({
      edu_institution: {
        description:
          'Create technical assessments for student assessments and learning programs',
        route: isHubsUrl
          ? `/hubs${FRONTEND_BUSINESS_TESTS_URL}`
          : FRONTEND_BUSINESS_TESTS_URL,
      },
      skillspace_organization: {
        description:
          'Create/modify technical tests and invite candidates to assess their skills',
        route: FRONTEND_BUSINESS_TESTS_URL,
      },
      edu_organization: {
        description:
          'Create technical assessments for employee upskilling or engagement programs',
        route: isHubsUrl
          ? `/hubs${FRONTEND_BUSINESS_TESTS_URL}`
          : FRONTEND_BUSINESS_TESTS_URL,
      },
    }),
    [isHubsUrl]
  );

  const homepageData = useMemo(
    () => [
      {
        id: 1,
        image: HOMEPAGE_TEST_IMAGE,
        title: 'Tests',
        route:
          !!user && !!user?.org_type
            ? testCardData[user.org_type].route
            : '',
        description:
          !!user && !!user?.org_type
            ? testCardData[user?.org_type].description
            : '',
        RouteLink: (props: any) => <Link to={props.to} {...props} />,
      },
      {
        id: 2,
        image: HOMEPAGE_LIBRARY_IMAGE,
        title: 'Library',
        route: isHubsUrl
          ? `/hubs${FRONTEND_BUSINESS_LIBRARY_URL}`
          : FRONTEND_BUSINESS_LIBRARY_URL,
        description:
          'Create/modify questions and access Skillspace library of questions',
        RouteLink: (props: any) => <Link to={props.to} {...props} />,
      },
      {
        id: 3,
        image: HOMEPAGE_BILLING_IMAGE,
        title: 'Billing',
        route: isHubsUrl
          ? `/hubs${FRONTEND_BUSINESS_BILLING_URL}`
          : FRONTEND_BUSINESS_BILLING_URL,
        description:
          'Subscribe/modify your plan and check your billing history',
        RouteLink: (props: any) => (
          <CustomMuiLink href={props.to} {...props} />
        ),
      },
    ],
    [user, isHubsUrl, testCardData]
  );

  return (
    <>
      <Box className={classes.box__welcome_container}>
        <Container maxWidth="xl" className={classes.container__home}>
          {!!user?.first_name && (
            <Typography variant="h1">
              Welcome {user?.first_name}!
            </Typography>
          )}
        </Container>
      </Box>
      {!!user ? (
        <Box className={classes.box__options_container}>
          <Container
            maxWidth="xl"
            className={classes.container__home}
          >
            <Grid container spacing={5} justify="space-between">
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gridGap={20}
                >
                  {homepageData.map(
                    ({ id, title, ...otherProps }) => (
                      <Card key={id} title={title} {...otherProps} />
                    )
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <InviteStats />
              </Grid>
              {organizer && !isInterviewPlan && <TestsInfo />}
            </Grid>
          </Container>
        </Box>
      ) : (
        <GenericListSkeleton items={3} />
      )}
    </>
  );
};

export default Dashboard;

const useStyles = makeStyles((theme) => ({
  container__home: {
    flexGrow: 1,
  },
  box__welcome_container: {
    width: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: '#FFF',
  },
  box__options_container: {
    width: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

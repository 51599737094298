import Axios, {
  AxiosResponse,
  AxiosRequestConfig,
  CancelToken,
} from 'axios';
import {
  APP_TYPE_PARAM,
  HUBS_APP_TYPE,
  SKILLSPACE_APP_TYPE,
} from 'business/organizer/common/utils/constants';

import getCookie from 'common/utils/getCookie';
import objectToFormData from 'common/utils/objectToFormData';

/**
 * Base service for all DPhi API services.
 */
export default class BaseService implements IBaseService {
  baseUrl: string;
  appTypeParam = window.location.pathname.includes('hubs')
    ? `${HUBS_APP_TYPE}`
    : `${SKILLSPACE_APP_TYPE}`;

  constructor() {
    this.baseUrl = '/api/';
  }

  getAxiosConfig(): AxiosRequestConfig {
    return {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
      },
    };
  }

  buildUrl(path: string) {
    return `${this.baseUrl}${path}`;
  }

  async get(
    url: string,
    params?: object,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<any>> {
    const response = await Axios.get(url, {
      method: 'GET',
      params,
      cancelToken,
      ...this.getAxiosConfig(),
    });
    return response;
  }

  async post(
    url: string,
    body: object,
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    const response = await Axios.post(url, body, {
      ...this.getAxiosConfig(),
      ...axiosConfig,
    });
    return response;
  }

  async put(
    url: string,
    body: object,
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    const response = await Axios.put(url, body, {
      ...this.getAxiosConfig(),
      ...axiosConfig,
    });
    return response;
  }

  async patch(
    url: string,
    body: object,
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    const response = Axios.patch(url, body, {
      ...this.getAxiosConfig(),
      ...axiosConfig,
    });
    return response;
  }

  // formdata request for uploading files
  async postFormData(
    url: string,
    body: any,
    axiosConfig?: AxiosRequestConfig
  ) {
    const formData = objectToFormData(body);
    return await this.patch(
      `${url}${
        url.includes(APP_TYPE_PARAM)
          ? ''
          : `?${APP_TYPE_PARAM}=${this.appTypeParam}`
      }`,
      formData,
      axiosConfig
    );
  }

  async delete(
    url: string,
    body?: object
  ): Promise<AxiosResponse<any>> {
    const response = await Axios.delete(url, {
      data: { ...(body || {}) },
      ...this.getAxiosConfig(),
    });
    return response;
  }
}

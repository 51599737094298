import React from 'react';

import MCQSolution from './MCQSolution';
import CodingSolution from './CodingSolution';
import FreeTextSolution from './FreeTextSolution';
import FileUploadSolution from './FileUploadSolution';
import ChallengeSolution from './ChallengeSolution';
// import ProjectSolution from './ProjectSolution';
import SqlSolution from './SqlSolution';

const RenderQuestionSolution: React.FC<IHubsCandidateRenderQuestionSolutionProps> =
  ({ question_type, question }) => {
    switch (question_type) {
      case 'coding':
        return (
          <CodingSolution
            question={question as ICodingQuestionSolutionBaseProps}
          />
        );
      case 'challenge':
        return (
          <ChallengeSolution
            question={
              question as IHubsCandidateChallengeSolutionProps
            }
          />
        );
      case 'free-text':
        return (
          <FreeTextSolution
            question={question as IFreeTextQuestionSolutionBaseProps}
          />
        );
      case 'file-upload':
        return (
          <FileUploadSolution
            question={
              question as IFileUploadQuestionSolutionBaseProps
            }
          />
        );
      case 'mcq':
        return (
          <MCQSolution question={question as IMCQSolutionBaseProps} />
        );
      // case 'project':
      //   return (
      //     <ProjectSolution
      //       question={question as IProjectSolutionBaseProps}
      //     />
      //   );
      case 'sql':
        return (
          <SqlSolution
            question={question as ISqlQuestionSolutionBaseProps}
          />
        );
      default:
        throw new Error(
          `${question_type}, Unexpected question type.`
        );
    }
  };

export default RenderQuestionSolution;

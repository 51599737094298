import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SpinnerButton from 'common/components/spinner-button/SpinnerButton';

import useAlert from 'common/components/alert-provider/useAlert';
import { getErrorMessage } from 'business/pre-subscription/auth/components/common/helpers';
import { Hubs_TO_ASSESS_IMAGE } from 'business/organizer/common/utils/constants';
import ContentSection from '../ContentSection';
import AuthPageBase from 'business/pre-subscription/common/components/AuthPageBase';
import Box from '@material-ui/core/Box';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import ImageHelper from 'common/components/image-helper/ImageHelper';
import { SWITCH_APP_API_URL } from 'business/pre-subscription/auth/utils/constants';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';

const businessAuthService = new BusinessAuthService();

const HubsToAssessmentModal = () => {
  const classes = useStyles();
  const { enqueueAlert } = useAlert();
  const [disabled, setdisabled] = useState(false);

  const handleClick: AuthSubmissionHandler = async () => {
    const data = {
      switch_to: 'hiring',
    };
    setdisabled(true);

    businessAuthService
      .post(SWITCH_APP_API_URL, data)
      .then((response) => {
        setdisabled(false);
        if (response) {
          window.location.reload();
        }
      })
      .catch((error) => {
        setdisabled(false);
        enqueueAlert(getErrorMessage(error?.response.data), {
          alertProps: { severity: 'error' },
        });
      });
  };

  const handleBack = () => {
    // go back to previous page
    window.history.back();
  };
  return (
    <AuthPageBase
      contentSection={<ContentSection next={true} />}
      showFooter={false}
    >
      <FormSectionBase logo={false} title="Just one click away">
        <>
          <Box height="100%" width="100%" textAlign={'center'}>
            <ImageHelper
              imageUrl={Hubs_TO_ASSESS_IMAGE}
              altProp="Hubs to assess"
              lazyLoad
              className={classes.imagehelper__card}
            />
          </Box>

          <Box
            display="flex"
            gridColumnGap={20}
            justifyContent={'center'}
          >
            <Button
              color="primary"
              onClick={handleBack}
              variant="contained"
              className={classes.button_back}
            >
              Back
            </Button>
            <SpinnerButton
              disabled={disabled}
              color="primary"
              variant="contained"
              className={classes.button_trial}
              onClick={handleClick}
            >
              Start Free Trial
            </SpinnerButton>
          </Box>
        </>
      </FormSectionBase>
    </AuthPageBase>
  );
};

export default HubsToAssessmentModal;

const useStyles = makeStyles((theme) => ({
  imagehelper__card: {
    maxWidth: theme.spacing(75),
    maxHeight: theme.spacing(40),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
  },
  button_trial: {
    width: '100%',
    '& .MuiButton-root': {
      width: '100%',
    },
  },
  button_back: {
    backgroundColor: '#666666',
    '&:hover': {
      backgroundColor: '#666666',
    },
  },
}));

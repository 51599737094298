import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/core';

import QuestionsSummaryTableHead from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionsSummaryTableHead';
import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';
import QuestionSummaryTable from '../candidate-detail/QuestionSummary';

const QuestionSolution: React.FC<HubsCandidateQuestionSolutionProps> =
  ({ question, children }) => {
    const classes = useStyles();

    const [expandDescription, setExpandDescription] = useState(false);

    const handleExpandDescription = () => {
      setExpandDescription(!expandDescription);
    };

    return (
      <Box bgcolor="#FAFAFA">
        <Container
          maxWidth={false}
          className={classes.container__root}
        >
          <QuestionsSummaryTableHead showSolution={false}>
            <QuestionSummaryTable
              idx={0}
              questionName={question?.question_name}
              questionType={question?.question_type}
              questionScore={question?.question_score}
              showSolution={false}
              score={question?.submission?.score}
              children={undefined}
            />
          </QuestionsSummaryTableHead>
        </Container>

        {!!question?.question_description && (
          <Box py={4} className={classes.box__rich_text}>
            <Box py={2}>
              <Typography variant="h6">
                <b>Question Description</b>
              </Typography>
            </Box>
            <Box>
              <Box className={classes.box__richtextrenderer}>
                <RichTextRenderer
                  richTextHtml={
                    question?.question_description.length < 500 ||
                    expandDescription
                      ? question?.question_description
                      : `${question?.question_description.slice(
                          0,
                          500
                        )}...`
                  }
                />
              </Box>
              {question?.question_description.length > 500 && (
                <Box textAlign="center" py={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleExpandDescription}
                    endIcon={
                      expandDescription ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                  >
                    {expandDescription ? 'View Less' : 'View More'}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        )}

        <Container
          maxWidth={false}
          className={classes.container__root}
        >
          <Box py={2}>
            <Typography variant="h6">
              <b>Candidate Solution</b>
            </Typography>
          </Box>
          <Box>{children}</Box>
        </Container>
      </Box>
    );
  };

export default QuestionSolution;

const useStyles = makeStyles((theme) => ({
  box__rich_text: {
    background: '#FFFFFF',
    padding: theme.spacing(8),
    '& *': {
      maxWidth: '100%',
    },
  },
  container__root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  box__richtextrenderer: {
    wordBreak: 'break-word',
  },
}));

const RemainingInvitesIcon = () => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 13L17.5 18L14 14.5L15.5 13L17.5 15L21 11.5L22.5 13ZM12 14H2V4L10 9L18 4V9H20V2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H12V14ZM18 2L10 7L2 2H18Z"
      fill="#0A66C2"
    />
  </svg>
);

export default RemainingInvitesIcon;

import React from 'react';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import useTags from 'business/organizer/library/custom-hooks/useTags';
import { LIBRARY_QUESTION_TAG_TYPE } from 'business/organizer/library/utils/constants';
import FilterByTagsBase from './FilterByTagsBase';

const FilterByTags: React.FC<{
  tagName: LibraryTagName;
}> = ({ tagName }) => {
  const { tagSuggestions } = useTags({
    type: LIBRARY_QUESTION_TAG_TYPE,
  });

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    // Internally, the autocomplete filter will check for the inputted text
    // in this string. So we include synonyms also.
    stringify: (tag: any) => `${tag.name} ${tag.synonyms.join(' ')}`,
    trim: true,
  });

  return (
    <FilterByTagsBase
      tagName={tagName}
      label="Tags"
      inputPlaceholder="Data Science"
      tagSuggestions={tagSuggestions}
      filterOptions={filterOptions}
    />
  );
};

export default FilterByTags;

import { useEffect } from 'react';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import useAsync from 'common/custom-hooks/useAsync';

const libraryService = new LibraryService();

const useQuestionPreviewData = (
  questionUrl: string,
  response: any
) => {
  const {
    data: questionItem,
    error: responseError,
    status,
    reset,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    run,
  } = useAsync();

  useEffect(() => {
    run(libraryService.getQuestion(`${questionUrl}`));
  }, [questionUrl, response, run]);

  return {
    questionItem,
    responseError,
    status,
    reset,
    isIdle,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useQuestionPreviewData;

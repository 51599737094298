import React, { useReducer } from 'react';
import LibraryContext from 'business/organizer/library/contexts/library/LibraryContext';
import { libraryReducer } from 'business/organizer/library/contexts/library/libraryReducer';

const commonState: ICommonStateProps = {
  questions: null,
  count: 0,
  isLoaded: false,
  params: {
    page: 1,
  },
  responseError: null,
};

const initialState: IInitialStateProps = {
  isLoaded: false,
  shared: { ...commonState },
  my: { ...commonState },
  globalParams: {
    // @ts-ignore
    type: JSON.parse(sessionStorage.getItem('type')) || [],
    difficulty:
      // @ts-ignore
      JSON.parse(sessionStorage.getItem('difficulty')) || [],
    // @ts-ignore
    tags: JSON.parse(sessionStorage.getItem('tags')) || [],
    programmingLanguages:
      // @ts-ignore
      JSON.parse(sessionStorage.getItem('programmingLanguages')) ||
      [],
    search: '',
  },
  responseError: null,
};

const LibraryProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(libraryReducer, initialState);

  return (
    <LibraryContext.Provider value={{ state, dispatch }}>
      {children}
    </LibraryContext.Provider>
  );
};

export default LibraryProvider;

import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import HttpError from 'common/components/http-error/HttpError';
import { CANDIDATE_REGISTRATION_FIELDS_API_URL } from 'business/organizer/common/utils/constants';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import RegistrationFieldItem from './RegistrationFieldItem';
import { Typography } from '@material-ui/core';

const organizerService = new OrganizerService();
const FIELDS_TO_EXCLUDE = ['privacy_policy', 'candidate_consent'];

const RegistrationFieldList = () => {
  const classes = useStyles();
  const [registrationFields, setRegistrationFields] = useState<
    any | null
  >(null);
  const [
    responseError,
    setResponseError,
  ] = useState<AxiosError | null>(null);

  const registrationFieldCategories: any = [
    {
      category: 'basic_details',
      label: 'Basic details',
    },
    {
      category: 'educational_details',
      label: 'Educational details',
    },
    {
      category: 'work_experience_details',
      label: 'Work experience',
    },
  ];

  useEffect(() => {
    let mounted = true;
    organizerService
      .getCandidateRegistrationFields(
        CANDIDATE_REGISTRATION_FIELDS_API_URL
      )
      .then((response) => {
        if (mounted) {
          setRegistrationFields(response?.data);
        }
      })
      .catch((error) => setResponseError(error));
    return () => {
      mounted = false;
    };
  }, []);
  // console.log(registrationFields);

  return (
    <>
      <Box>
        <Box width="100%">
          {responseError !== null ? (
            <HttpError
              axiosError={responseError}
              message="something went wrong, please try again in sometime."
            />
          ) : registrationFields === null ? (
            <GenericListSkeleton items={1} />
          ) : (
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {registrationFieldCategories.map(
                (
                  registrationFieldCategory: IregistrationFieldCategoryProps
                ) => (
                  <Box
                    key={registrationFieldCategory.label}
                    className={classes.box__category_container}
                  >
                    <Box pb={1}>
                      <Typography variant="subtitle2">
                        {registrationFieldCategory.label}
                      </Typography>
                    </Box>
                    <Box
                      className={
                        classes.box__category_inner_container
                      }
                    >
                      {registrationFields.map(
                        (
                          registrationField: IRegistrationFormFieldProps
                        ) =>
                          registrationField.category ===
                            registrationFieldCategory.category &&
                          !FIELDS_TO_EXCLUDE.includes(
                            registrationField.name
                          ) && (
                            <RegistrationFieldItem
                              key={registrationField.id}
                              id={registrationField.id}
                              label={registrationField.label}
                              name={registrationField.name}
                              category={registrationField.category}
                            />
                          )
                      )}
                    </Box>
                  </Box>
                )
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RegistrationFieldList;

const useStyles = makeStyles((theme) => ({
  box__category_container: {
    padding: theme.spacing(4),
    margin: theme.spacing(4),
    borderRadius: theme.spacing(1),
    border: '1px solid #ddd',
    marginLeft: theme.spacing(0),
  },
  box__category_inner_container: {
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(34.5),
    width: theme.spacing(75.5),
    flexWrap: 'wrap',
  },
}));

import React, { useState } from 'react';
import GetTestIdContext from 'business/organizer/assessment/contexts/get-testId/GetTestIdContext';

const GetTestIdProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [testId, setTestId] = useState<number | null>(null);
  return (
    <GetTestIdContext.Provider value={{ testId, setTestId }}>
      {children}
    </GetTestIdContext.Provider>
  );
};

export default GetTestIdProvider;

import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import HttpError from 'common/components/http-error/HttpError';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import QuestionsSummaryTableItem from 'business/organizer/assessment/components/candidate-progress/candidate-detail/questions/QuestionsSummaryTableItem';
import QuestionsSummaryTableHead from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionsSummaryTableHead';

const organizerService = new OrganizerService();

const QuestionsSummary = () => {
  const [
    responseError,
    setResponseError,
  ] = useState<AxiosError | null>(null);

  const {
    candidateDetails,
    questionsSummary,
    setQuestionsSummary,
  } = useCandidateProgressDetail();

  useEffect(() => {
    let mounted = true;

    organizerService
      .get(`${candidateDetails?.url}submissions/`)
      .then((response) => {
        if (mounted) {
          setQuestionsSummary(response?.data?.results);
        }
      })
      .catch((error) => setResponseError(error));

    return () => {
      mounted = false;
      setQuestionsSummary(null);
    };
  }, [candidateDetails?.url, setQuestionsSummary]);


  return (
    <Container maxWidth={false}>
      {responseError !== null ? (
        <HttpError axiosError={responseError} />
      ) : questionsSummary === null ? (
        <Box py={4}>
          <GenericListSkeleton items={3} />
        </Box>
      ) : (
        <Box py={4} width="100%">
          <QuestionsSummaryTableHead>
            <>
              {questionsSummary.map((question: any, idx: number) => (
                <QuestionsSummaryTableItem
                  key={idx}
                  idx={idx}
                  question={question}
                />
              ))}
            </>
          </QuestionsSummaryTableHead>
        </Box>
      )}
    </Container>
  );
};

export default QuestionsSummary;

import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import { getTimeLeft } from 'common/utils/dayjsUtils';
import {
  cbGrowthConfigMonthly,
  cbGrowthConfigYearly,
  cbInterviewsConfigMonthly,
  cbInterviewsConfigYearly,
  cbMicroConfigMonthly,
  cbMicroConfigYearly,
  cbStartUpConfigMonthly,
  cbStartUpConfigYearly,
} from 'business/organizer/assessment/components/billing/utils/billingUtils';

/**
 * utility custom hook for getting subscripition related boolean
 */
const useSubscription = () => {
  const { organizer } = useOrganizer();
  const { current_subscription } = organizer || {};
  const { next_billing_datetime } = current_subscription || {};
  const chargebeePlanId =
    organizer?.current_subscription &&
    organizer?.current_subscription.plan.chargebee_plan_id;

  /**
   * checks subscription expired or not
   * */
  const isSubscriptionExpired = (() => {
    let timeLeft;
    if (next_billing_datetime) {
      timeLeft = getTimeLeft(next_billing_datetime);
      return timeLeft < 0;
    } else {
      return false;
    }
  })();

  /**
   * Check plan type
   * */
  const isFreeTrial = chargebeePlanId === 'free-trial';
  const isStartUpPlan =
    !!chargebeePlanId &&
    [
      cbStartUpConfigMonthly.planId,
      cbStartUpConfigYearly.planId,
    ].includes(chargebeePlanId);
  const isMicorPlan =
    !!chargebeePlanId &&
    [
      cbMicroConfigMonthly.planId,
      cbMicroConfigYearly.planId,
    ].includes(chargebeePlanId);
  const isGrowthPlan =
    !!chargebeePlanId &&
    [
      cbGrowthConfigMonthly.planId,
      cbGrowthConfigYearly.planId,
    ].includes(chargebeePlanId);
  const isInterviewPlan =
    !!chargebeePlanId &&
    [
      cbInterviewsConfigMonthly.planId,
      cbInterviewsConfigYearly.planId,
    ].includes(chargebeePlanId);

  // old plans
  const isStarterPlan =
    !!chargebeePlanId && chargebeePlanId === 'starter';
  const isGrowthOldPlan =
    !!chargebeePlanId && chargebeePlanId === 'growth';

  /**
   * check freetrial ended or not
   * */
  const isFreeTrialEnded = (() => {
    return isFreeTrial && isSubscriptionExpired;
  })();

  /**
   * checks starter or growth plan is expired or not
   * */
  const isPaidSubscriptionEnded = (() => {
    return (
      (isMicorPlan ||
        isGrowthPlan ||
        isStartUpPlan ||
        isStarterPlan ||
        isGrowthOldPlan) &&
      isSubscriptionExpired
    );
  })();

  return {
    isSubscriptionExpired,
    isFreeTrialEnded,
    isPaidSubscriptionEnded,
    isFreeTrial,
    isStartUpPlan,
    isMicorPlan,
    isGrowthPlan,
    organizer,
    isInterviewPlan,
  };
};

export default useSubscription;

import React from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import useQuestionRouteData from 'business/organizer/assessment/custom-hooks/useQuestionRouteData';
import CreateCustomQuestionDrawer from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/drawers/CreateCustomQuestionDrawer';
import useQuestionDrawer from 'business/organizer/library/contexts/question-drawer/useQuestionDrawer';
import { dropdownStyles } from 'business/organizer/common/utils/styles/dropdownStyles';

const CreateCustomQuestion: React.FC<IMenuProps> = ({
  anchorEl,
  handleClose,
  ...otherProps
}) => {
  const classes = useStyles();
  const { setQuestionDrawer } = useQuestionDrawer();

  const { questionData } = useQuestionRouteData(
    setQuestionDrawer,
    'create'
  );

  return (
    <>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
        {...otherProps}
      >
        {questionData.map(({ id, label, divider, route, disable }) =>
          disable ? (
            <Tooltip
              title="Please upgrade your plan to use these feature"
              placement="left"
              arrow
              key={id}
            >
              <Box className={classes.box_root}>
                <ListItem divider={divider} disabled={disable}>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'h6' }}
                  >
                    {label}
                  </ListItemText>
                </ListItem>
              </Box>
            </Tooltip>
          ) : (
            <Box
              key={id}
              onClick={route}
              className={classes.box_root}
            >
              <ListItem divider={divider}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h6' }}
                >
                  {label}
                </ListItemText>
              </ListItem>
            </Box>
          )
        )}
      </DropdownMenu>
      <CreateCustomQuestionDrawer />
    </>
  );
};

export default CreateCustomQuestion;

const useStyles = makeStyles((theme) => ({
  box_root: {
    cursor: 'pointer',
    '& .MuiListItem-root:hover': {
      width: '100%',
      backgroundColor: 'rgba(182, 217, 251, 0.15)',
    },
  },
}));

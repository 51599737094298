import React from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Badge from '@material-ui/core/Badge';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';

import { tabProps } from 'common/utils/tabProps';

const GenericNavbar: React.FC<IGenericNavbarProps> = ({
  tabs,
  pathnameMethod = 'startsWith',
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const tabIdx = tabs.findIndex((tab) =>
    pathname[pathnameMethod](tab.route)
  );

  return (
    <AppBar
      position="static"
      color="inherit"
      style={{ boxShadow: 'none' }}
      className={classes.appbar_root}
    >
      <Tabs
        value={tabIdx !== -1 ? tabIdx : 0}
        /* we override the primary color here*/
        indicatorColor="secondary"
        variant="standard"
        TabIndicatorProps={{
          className: classes.tabIndicator,
        }}
        className={classes.tabs}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.route}
            fullWidth
            label={
              tab?.badge ? (
                <Badge
                  badgeContent={
                    typeof tab.count === 'number' ? tab.count : 0
                  }
                  color="secondary"
                  children={tab.label}
                  max={99}
                  showZero
                />
              ) : (
                tab.label
              )
            }
            {...tabProps(tab.route, tab.route, classes)}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default GenericNavbar;

const useStyles = makeStyles((theme) => ({
  appbar_root: {
    '& .PrivateTabIndicator-colorPrimary-50': {
      backgroundColor: '#B87D00',
    },
  },
  tabIndicator: {
    borderRadius: '3px',
  },
  tabs: {
    flexGrow: 1,
    background: '#f1f6f9',
  },
  tab: {
    textTransform: 'initial',
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#B87D00',
      color: '#FCFCFC',
    },
    '& .MuiBadge-badge': {
      right: theme.spacing(2),
      top: 14,
      fontSize: theme.spacing(3),
      position: 'relative',
    },
  },
}));

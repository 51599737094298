const HubsLogo = ({
  textColor = '#0A66C2',
}: {
  textColor?: '#0A66C2' | '#ffffff';
}) => {
  return (
    <svg
      width="180"
      height="50"
      viewBox="0 2 241 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M113 52.4912H114.129V51.5242H114.226C114.435 51.9206 114.86 52.6128 115.943 52.6128C117.382 52.6128 118.426 51.4069 118.426 49.396C118.426 47.3809 117.366 46.1953 115.932 46.1953C114.829 46.1953 114.431 46.8993 114.226 47.2839H114.157V44.2046H113V52.4912ZM114.133 49.3837C114.133 48.0849 114.675 47.2229 115.684 47.2229C116.732 47.2229 117.258 48.1498 117.258 49.3837C117.258 50.63 116.717 51.5809 115.684 51.5809C114.69 51.5809 114.133 50.6905 114.133 49.3837ZM120.243 54.8219C121.198 54.8219 121.802 54.3 122.146 53.3207L124.602 46.2885L123.352 46.2762L121.848 51.0993H121.786L120.281 46.2762H119.044L121.217 52.5722L121.075 52.9847C120.781 53.8104 120.366 53.879 119.732 53.6972L119.454 54.6885C119.594 54.7533 119.895 54.8219 120.243 54.8219ZM132.375 52.4912C135.593 52.4912 137.455 50.4034 137.455 46.9507C137.455 43.5141 135.593 41.4424 132.484 41.4424H128.957V52.4912H132.375ZM130.55 51.0345V42.899H132.385C134.695 42.899 135.887 44.3449 135.887 46.9507C135.887 49.567 134.695 51.0345 132.288 51.0345H130.55ZM139.436 52.4912H141.029V48.6068H143.195C145.645 48.6068 146.857 47.0585 146.857 45.0192C146.857 42.9853 145.655 41.4424 143.201 41.4424H139.436V52.4912ZM141.029 47.1934V42.872H143.031C144.608 42.872 145.253 43.7676 145.253 45.0192C145.253 46.2708 144.608 47.1934 143.051 47.1934H141.029ZM150.143 47.5712C150.143 46.2494 150.942 45.494 152.04 45.494C153.103 45.494 153.737 46.2006 153.737 47.4147V52.4912H155.279V47.2203C155.279 45.1542 154.196 44.0967 152.566 44.0967C151.334 44.0967 150.591 44.6577 150.22 45.5534H150.122V41.4424H148.601V52.4912H150.143V47.5712ZM157.336 52.4912H158.878V44.2046H157.336V52.4912ZM158.115 42.9259C158.646 42.9259 159.089 42.4944 159.089 41.9656C159.089 41.4369 158.646 41 158.115 41C157.579 41 157.14 41.4369 157.14 41.9656C157.14 42.4944 157.579 42.9259 158.115 42.9259Z"
        fill={textColor === '#ffffff' ? '#ffffff' : '#333333'}
      />
      <path
        d="M12.2589 19.8228C10.8658 18.2683 8.66789 17.75 6.68671 17.75C3.52908 17.75 0.371485 19.3694 0.371485 23.0616C0.371485 29.0533 8.20359 26.9157 8.20359 29.442C8.20359 30.284 7.15106 30.5754 6.09852 30.5754C4.70546 30.5754 3.68387 29.9602 2.81708 28.859L0 32.1301C1.48593 33.7171 3.80771 34.2677 5.91277 34.2677C9.13226 34.2677 12.8471 33.1018 12.8471 29.0857C12.8471 23.094 5.01502 25.0696 5.01502 22.7377C5.01502 21.7985 5.94374 21.4422 6.71765 21.4422C7.83211 21.4422 8.72989 21.8957 9.41086 22.7701L12.2589 19.8228Z"
        fill={textColor}
      />
      <path
        d="M14.2985 33.8795H18.942V25.9121H19.0039L24.0809 33.8795H29.9316L23.8022 25.232L29.7769 18.1391H24.0809L18.942 24.3575V9.39453H14.2985V33.8795Z"
        fill={textColor}
      />
      <path
        d="M30.6338 33.8786H35.2774V18.1382H30.6338V33.8786ZM30.2623 13.183C30.2623 14.7375 31.4697 16.0007 32.9556 16.0007C34.4415 16.0007 35.6488 14.7375 35.6488 13.183C35.6488 11.6283 34.4415 10.3652 32.9556 10.3652C31.4697 10.3652 30.2623 11.6283 30.2623 13.183Z"
        fill={textColor}
      />
      <path
        d="M37.7466 33.8795H42.39V9.39453H37.7466V33.8795Z"
        fill={textColor}
      />
      <path
        d="M44.8593 33.8795H49.5027V9.39453H44.8593V33.8795Z"
        fill={textColor}
      />
      <path
        d="M63.2714 19.8228C61.8783 18.2683 59.6803 17.75 57.6992 17.75C54.5417 17.75 51.3837 19.3694 51.3837 23.0616C51.3837 29.0533 59.2159 26.9157 59.2159 29.442C59.2159 30.284 58.1636 30.5754 57.1109 30.5754C55.7178 30.5754 54.6963 29.9602 53.8295 28.859L51.0125 32.1301C52.4983 33.7171 54.8202 34.2677 56.9251 34.2677C60.1446 34.2677 63.8596 33.1018 63.8596 29.0857C63.8596 23.094 56.0275 25.0696 56.0275 22.7377C56.0275 21.7985 56.9563 21.4422 57.73 21.4422C58.8446 21.4422 59.7422 21.8957 60.4232 22.7701L63.2714 19.8228Z"
        fill={textColor}
      />
      <path
        d="M65.7446 41.2634H70.3881V32.1949H70.45C71.5027 33.7171 73.2978 34.2677 75.2173 34.2677C79.4895 34.2677 82.0898 30.2517 82.0898 25.8793C82.0898 21.1184 79.1487 17.75 74.691 17.75C72.5861 17.75 70.7905 19.0779 70.0783 20.2115H70.0164V18.1387H65.7446V41.2634ZM70.0164 26.0089C70.0164 23.6446 71.4095 22.0252 73.7314 22.0252C76.0533 22.0252 77.4461 23.6446 77.4461 26.0089C77.4461 28.3732 76.0533 29.9925 73.7314 29.9925C71.4095 29.9925 70.0164 28.3732 70.0164 26.0089Z"
        fill={textColor}
      />
      <path
        d="M93.3231 33.8791H97.5948V25.8793C97.5948 20.6325 96.1402 17.75 90.7228 17.75C88.2769 17.75 85.8006 18.5921 84.098 20.2762L86.5743 22.932C87.5649 21.7661 88.8346 21.0535 90.3508 21.0535C91.9606 21.0535 93.3231 22.1547 93.3231 23.8713C89.4531 23.8713 83.1379 23.9685 83.1379 29.4095C83.1379 32.5835 85.6766 34.2677 88.4938 34.2677C90.382 34.2677 92.239 33.5876 93.2615 31.9034H93.3231V33.8791ZM93.3231 26.9805V28.0168C93.3231 29.9602 91.6205 30.9641 89.7939 30.9641C88.7722 30.9641 87.596 30.4135 87.596 29.2476C87.596 27.1101 90.7532 26.9805 92.1774 26.9805H93.3231Z"
        fill={textColor}
      />
      <path
        d="M112.829 19.8552C111.374 18.3006 109.239 17.75 107.474 17.75C102.831 17.75 99.1157 20.9888 99.1157 26.0089C99.1157 31.0289 102.831 34.2677 107.474 34.2677C109.239 34.2677 111.374 33.7171 112.829 32.1625L109.764 28.7942C109.238 29.442 108.495 29.9925 107.474 29.9925C105.152 29.9925 103.759 28.3732 103.759 26.0089C103.759 23.6445 105.152 22.0251 107.474 22.0251C108.434 22.0251 109.3 22.5434 109.764 23.2235L112.829 19.8552Z"
        fill={textColor}
      />
      <path
        d="M124.576 28.6647C123.801 29.7658 122.78 30.5754 121.263 30.5754C119.467 30.5754 118.137 29.3772 117.765 27.531H128.538V26.0089C128.538 20.9888 125.814 17.75 121.48 17.75C116.837 17.75 113.122 20.9888 113.122 26.0089C113.122 31.0289 116.837 34.2677 121.48 34.2677C123.895 34.2677 126.34 33.1341 127.826 31.1584L124.576 28.6647ZM117.765 24.2276C117.889 22.7053 119.003 21.2478 121.047 21.2478C122.718 21.2478 123.925 22.6081 123.895 24.2276H117.765Z"
        fill={textColor}
      />
      <path
        d="M130.525 31.0949C130.525 32.7467 131.795 34.0746 133.374 34.0746C134.952 34.0746 136.222 32.7467 136.222 31.0949C136.222 29.4432 134.952 28.1152 133.374 28.1152C131.795 28.1152 130.525 29.4432 130.525 31.0949Z"
        fill={textColor}
      />
      <path
        d="M148.107 33.8791H152.379V25.8793C152.379 20.6325 150.924 17.75 145.506 17.75C143.061 17.75 140.584 18.5921 138.882 20.2762L141.358 22.932C142.348 21.7661 143.618 21.0535 145.134 21.0535C146.745 21.0535 148.107 22.1547 148.107 23.8713C144.237 23.8713 137.922 23.9685 137.922 29.4095C137.922 32.5835 140.46 34.2677 143.277 34.2677C145.166 34.2677 147.023 33.5876 148.045 31.9034H148.107V33.8791ZM148.107 26.9805V28.0168C148.107 29.9602 146.404 30.9641 144.577 30.9641C143.556 30.9641 142.38 30.4135 142.38 29.2476C142.38 27.1101 145.538 26.9805 146.962 26.9805H148.107Z"
        fill={textColor}
      />
      <path
        d="M154.425 33.8786H159.069V18.1382H154.425V33.8786ZM154.054 13.183C154.054 14.7375 155.26 16.0007 156.747 16.0007C158.232 16.0007 159.44 14.7375 159.44 13.183C159.44 11.6283 158.232 10.3652 156.747 10.3652C155.26 10.3652 154.054 11.6283 154.054 13.183Z"
        fill={textColor}
      />
      <path
        d="M156.724 16.0139C158.253 16.0139 159.493 14.717 159.493 13.1173C159.493 11.5175 158.253 10.2207 156.724 10.2207C155.195 10.2207 153.955 11.5175 153.955 13.1173C153.955 14.717 155.195 16.0139 156.724 16.0139Z"
        fill="#FFC233"
      />
      <path d="M170 13H168V39H170V13Z" fill="#FFCE5C" />
      <path
        d="M180.289 33.7851H183.647V27.1758H190.463V33.7851H193.813V17.7851H190.463V24.3867H183.647V17.7851H180.289V33.7851ZM206.392 17.7851V27.8867C206.392 29.7148 205.121 31.0351 203.166 31.0351C201.219 31.0351 199.94 29.7148 199.94 27.8867V17.7851H196.582V28.1758C196.582 31.6757 199.203 34.0117 203.166 34.0117C207.113 34.0117 209.75 31.6757 209.75 28.1758V17.7851H206.392ZM212.513 33.7851H219.298C222.866 33.7851 224.766 31.9101 224.766 29.3633C224.766 26.9961 223.083 25.5664 221.276 25.4804V25.3242C222.928 24.9492 224.146 23.7695 224.146 21.8867C224.146 19.4883 222.377 17.7851 218.872 17.7851H212.513V33.7851ZM215.87 31.0195V26.7695H218.686C220.299 26.7695 221.3 27.707 221.3 29.0273C221.3 30.2305 220.485 31.0195 218.608 31.0195H215.87ZM215.87 24.4805V20.5195H218.422C219.911 20.5195 220.757 21.293 220.757 22.4491C220.757 23.7148 219.733 24.4805 218.36 24.4805H215.87ZM235.567 22.3867H238.785C238.738 19.5351 236.412 17.5664 232.875 17.5664C229.394 17.5664 226.842 19.5039 226.857 22.4101C226.85 24.7695 228.502 26.121 231.185 26.7695L232.914 27.207C234.644 27.6289 235.605 28.1288 235.613 29.207C235.605 30.3789 234.504 31.1758 232.798 31.1758C231.053 31.1758 229.797 30.3633 229.689 28.7617H226.439C226.524 32.2227 228.983 34.0117 232.837 34.0117C236.714 34.0117 238.994 32.1445 239.002 29.2148C238.994 26.5507 237.001 25.1367 234.24 24.5117L232.814 24.1679C231.433 23.8476 230.277 23.332 230.301 22.1836C230.301 21.1523 231.208 20.3945 232.852 20.3945C234.458 20.3945 235.442 21.1288 235.567 22.3867Z"
        fill="url(#paint0_linear_814_56)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_814_56"
          x1="164.566"
          y1="25.6504"
          x2="241"
          y2="25.6504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HubsLogo;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import FileUploadPage from 'business/organizer/library/pages/question-create-update/file-upload/FileUploadPage';
import FileUploadUpdatePage from 'business/organizer/library/pages/question-create-update/file-upload/FileUploadUpdatePage';

const FileUploadRoutes = () => {
  const { url } = useCurrentUrl();
  return (
    <Switch>
      <Route path={`${url}/edit/:fileUploadId`}>
        <FileUploadUpdatePage />
      </Route>
      <Route path={`${url}/create`}>
        <FileUploadPage drawerName="create" />
      </Route>
      <Redirect from={url} to={`${url}/create`} />
    </Switch>
  );
};

export default FileUploadRoutes;

import React from 'react';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import PasswordUpdate from 'business/organizer/settings/components/password/PasswordUpdate';

const Password = () => {
  const { url } = useCurrentUrl();

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Password', route: url }]}
      />
      <PasswordUpdate />
    </>
  );
};

export default Password;

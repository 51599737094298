import React, { useState } from 'react';
import CandidateProgressDetailContext from './CandidateProgressDetailContext';

const CandidateProgressDetailProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [candidateDetails, setCandidateDetails] =
    useState<ICandidateProgressDetailsProps | null>(null);
  const [questionsSummary, setQuestionsSummary] = useState<
    any | null
  >(null);

  const [updatedScoreLifeCycle, setUpdatedScoreLifeCycle] = useState<
    number[]
  >([]);

  return (
    <CandidateProgressDetailContext.Provider
      value={{
        candidateDetails,
        setCandidateDetails,
        setQuestionsSummary,
        questionsSummary,
        setUpdatedScoreLifeCycle,
        updatedScoreLifeCycle,
      }}
    >
      {children}
    </CandidateProgressDetailContext.Provider>
  );
};

export default CandidateProgressDetailProvider;

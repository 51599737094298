import React from 'react';

import { getLanguage } from 'common/components/monaco-editor/helpers';
import TestcasesTable from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/TestcasesTable';
import CompilationInfo from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CompilationInfo';
import CandidateCode from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CandidateCode';

const CodingSolutionBase: React.FC<{
  submission: ICodingSubmissionBaseProps;
}> = ({ submission }) => {
  return (
    <>
      <TestcasesTable testCases={submission?.test_cases} />
      <CompilationInfo streamData={submission?.stream_data} />
      {!!submission?.programming_language && (
        <CandidateCode
          language={submission?.programming_language}
          code={submission?.code}
          menuOption={getLanguage(submission?.programming_language)}
        />
      )}
    </>
  );
};

export default CodingSolutionBase;

import React from 'react';
import { useHistory } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import useDropdown from 'common/components/use-dropdown/useDropdown';
import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import { dropdownStyles } from 'business/organizer/common/utils/styles/dropdownStyles';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAlert from 'common/components/alert-provider/useAlert';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const organizerService = new OrganizerService();
const Actions: React.FC<{
  id: number;
  cancelInvitationUrl: string;
  email: string;
  showCancelInvitation: boolean;
}> = ({ id, cancelInvitationUrl, email, showCancelInvitation }) => {
  const { enqueueAlert } = useAlert();
  const history = useHistory();

  const { testDetail } = useTestDetail();
  const { anchorEl, handleClick, handleClose } = useDropdown();
  const { setHasChanges } = useCandidateProgress();
  const { isViewer } = useHasPermission();
  const { isHubsUrl } = useCurrentUrl()

  const cancelInvitation = async (apiUrl: string, email: string) => {
    try {
      await organizerService.post(apiUrl, {});
      setHasChanges(true);
      enqueueAlert(
        `Invitation is cancelled for ${email} candidate.`,
        {
          alertProps: { severity: 'success' },
        }
      );
    } catch (error) {
      enqueueAlert(
        'Not able to cancel invitation. Please try again',
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <>
      <HasPermission
        isViewer={isViewer}
        tooltipProps={{ placement: 'top', arrow: true }}
      >
        <IconButton
          onClick={anchorEl !== null ? handleClose : handleClick}
          size="small"
          disabled={isViewer}
        >
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
      </HasPermission>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
      >
        <MenuItem
          onClick={() =>
            history.push(
              `${isHubsUrl ? '/hubs' : ''}/tests/${testDetail?.status}/${testDetail?.id}/invite/${id}`
            )
          }
        >
          Re-Invite
        </MenuItem>
        {showCancelInvitation && (
          <MenuItem
            onClick={() =>
              cancelInvitation(cancelInvitationUrl, email)
            }
          >
            Cancel Invite
          </MenuItem>
        )}
      </DropdownMenu>
    </>
  );
};

export default Actions;

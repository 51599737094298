import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import HttpError from 'common/components/http-error/HttpError';
import QuestionListSkeleton from 'business/organizer/library/components/question-list/skeletons/QuestionList';
import useTestList from 'business/organizer/assessment/components/common/custom-hooks/useTestList';
import TestItem from 'business/organizer/assessment/components/dashboard/common/TestItem';
import CreateTest from 'business/organizer/assessment/components/assessment-tests/test-list/CreateTest';
import { OrganizerService } from 'business/organizer/common/services/organizerService';

const TestList: React.FC<ITestListProps> = ({ list }) => {
  const { error, testsList } = useTestList(list, OrganizerService);

  return (
    <Container maxWidth={false} disableGutters>
      <Box px={5}>
        {error !== null ? (
          <HttpError
            axiosError={error}
            message="Not able to load the test. Please refresh the page."
          />
        ) : (
          <>
            {testsList === null ? (
              <QuestionListSkeleton />
            ) : testsList !== null && testsList.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="subtitle1">
                  {list === 'active'
                    ? 'There are no active tests, please create one'
                    : 'There are no archived tests at the moment'}
                </Typography>
                {list === 'active' && (
                  <Box py={4}>
                    <CreateTest />
                  </Box>
                )}
              </Box>
            ) : (
              <Grid container spacing={4}>
                {testsList.map((testItem: any) => (
                  <Grid item xs={12} key={testItem.id}>
                    <TestItem testItem={testItem} list={list} />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default TestList;

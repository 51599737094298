import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const getTotalScore = (questionsSummary: any): number | undefined => {
  const allQuestionScores = questionsSummary
    ?.map((question: any) => question.submission?.score)
    .filter((score: number | undefined) => typeof score === 'number');

  if (!!allQuestionScores?.length) {
    return allQuestionScores.reduce(
      (prev: number, next: number) => prev + next
    );
  }
};

const CandidateDetails = () => {
  const { candidateDetails, questionsSummary } =
    useCandidateProgressDetail();
  const { testDetail } = useTestDetail();

  const candidateData = useMemo(
    () => [
      {
        id: 1,
        label: 'Test start time',
        value: `${dayjs(candidateDetails?.test_start_time)
          .tz(dayjs.tz.guess())
          .format("DD MMM[']YY HH[:]mm")}`,
      },
      {
        id: 2,
        label: 'Invitation date & time',
        value: `${dayjs(candidateDetails?.invitation_datetime)
          .tz(dayjs.tz.guess())
          .format("DD MMM[']YY HH[:]mm")}`,
      },
      {
        id: 3,
        label: 'Total Score',
        value: `${
          // Derive the score from questions score
          getTotalScore(questionsSummary) || candidateDetails?.score
        }/${candidateDetails?.question_score}`,
      },
      {
        id: 4,
        label: 'Duration of test',
        value: `${testDetail?.assessment_duration} `,
      },
    ],
    [
      candidateDetails?.test_start_time,
      candidateDetails?.invitation_datetime,
      candidateDetails?.score,
      candidateDetails?.question_score,
      questionsSummary,
      testDetail?.assessment_duration,
    ]
  );

  return (
    <Container maxWidth={false}>
      <Box
        bgcolor="#FFF"
        p={4}
        boxShadow="0px 3px 6px #DDE6ED"
        borderRadius={5}
      >
        <Box pb={3}>
          <Typography variant="h6">
            {testDetail?.name} Summary
          </Typography>
        </Box>
        <Grid container style={{ gridRowGap: '16px' }}>
          {candidateData.map((candidate, idx) => (
            <Grid key={idx} item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                gridRowGap={4}
              >
                <Typography variant="body2" color="textSecondary">
                  {candidate.label}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {candidate.value}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CandidateDetails;

import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const DownloadNotebook: React.FC<{notebookLink: string}> = ({
  notebookLink,
}) => {
  return (
    <Tooltip title="Download" placement="right">
      <IconButton
        size="small"
        color="default"
        href={notebookLink}
        target="_blank"
        download
      >
        <GetAppIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadNotebook;


import React from 'react';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'formik';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';

import useOnDropFile from 'common/components/form/custom-hooks/useOnDropFile';
import FileField from 'common/components/form/file-field/FileField';
import FileFieldResults from 'common/components/form/file-field/FileFieldResults';

const SqlFileFieldBase: React.FC<{
  name: 'db_schema' | 'expected_output';
  format: string;
}> = ({ name, format }) => {
  const { onDrop } = useOnDropFile({ name });
  const { values } = useFormikContext<ISqlInitialStateProps>();

  const dropzoneOptions = {
    accept: format,
    maxFiles: 1,
    onDrop,
    multiple: false,
    maxSize: 10485760, //10MB
  };

  return (
    <FileField dropzoneOptions={dropzoneOptions} name={name}>
      <Box>
        <Box display="flex" gridColumnGap="5px" alignItems="center">
          <Button size="small" variant="outlined" color="primary">
            Upload
          </Button>
          {!!values?.[name] && (
            <Typography>{values?.[name]?.name}</Typography>
          )}
          {!!values?.[name] && typeof values?.[name] === 'string' && (
            <Typography variant="body1">
              <MuiLink href={values?.[name]} target="_blank">
                Download
              </MuiLink>
            </Typography>
          )}
        </Box>
        <Box>
          <ErrorMessage name={name}>
            {(msg) => (
              <FormHelperText error={true}>{msg}</FormHelperText>
            )}
          </ErrorMessage>
          <FileFieldResults showAcceptedFiles={false} />
        </Box>
      </Box>
    </FileField>
  );
};

export default SqlFileFieldBase;

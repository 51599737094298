import { useState } from 'react';

const useCustomStepper = (totalSteps: number) => {
  // states are for showing steps
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(
    new Set<number>()
  );
  const newCompleted = new Set(completedSteps);

  const nextStep = () => {
    if (totalSteps - 1 !== activeStep) {
      setActiveStep(activeStep + 1);
    }
    newCompleted.add(activeStep);
    setCompletedSteps(newCompleted);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  return {
    nextStep,
    prevStep,
    activeStep,
    completedSteps,
    setActiveStep,
  };
};

export default useCustomStepper;

import React from 'react';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box/Box';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import SaveButton from 'business/organizer/library/components/common/SaveButton';
import InputWithLabel from 'common/components/form/InputWithLabel';
import MyInput from 'common/components/form/MyInput';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { personalDetailsValidationSchema } from 'business/organizer/settings/utils/validationSchema';
import useAlert from 'common/components/alert-provider/useAlert';
import MySelect from 'common/components/form/MySelect';
import SelectCountryCode from 'common/components/form/SelectCountryCode';
import useUserContext from 'common/components/get-user/useUserContext';
import { AllJobTitlesList } from 'business/organizer/settings/utils/constants';

const organizerService = new OrganizerService();

const PersonalDetailsUpdate: React.FC<IPersonalDetailsUpdate> = ({
  personalDetails,
}) => {
  const classes = useStyles();
  const { enqueueAlert } = useAlert();
  const { user } = useUserContext();

  const initialState: IInitialStatePersonalDetailsFormProps = {
    first_name: personalDetails?.first_name || '',
    last_name: personalDetails?.last_name || '',
    email: personalDetails?.email || '',
    current_role: personalDetails?.profile?.current_role || '',
    country_code: personalDetails?.profile?.country_code || '',
    contact_number: personalDetails?.profile?.contact_number || '',
  };

  const handleSubmit = async (
    data: IInitialStatePersonalDetailsFormProps
  ) => {
    try {
      const {
        first_name,
        last_name,
        current_role,
        country_code,
        contact_number,
      } = data;
      const personalDetailsData = {
        first_name: first_name,
        last_name: last_name,
        profile: {
          current_role: current_role,
          country_code: country_code,
          contact_number: contact_number,
        },
      };
      const response = await organizerService.patchProfile(
        personalDetails.url,
        personalDetailsData
      );
      if (response) {
        enqueueAlert('Updated successfully', {
          alertProps: { severity: 'success' },
        });
      }
    } catch (error) {
      enqueueAlert(
        'Something went wrong. Please try again after sometime',
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <>
      <Formik
        initialValues={initialState}
        validationSchema={personalDetailsValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <fieldset disabled className={classes.fieldset__disabled}>
              <InputWithLabel
                title="Email"
                downMD="subtitle2"
                upMD="subtitle2"
                isRequired={false}
              >
                <MyInput
                  placeholder="Email"
                  name="email"
                  type="email"
                  className={classes.input__disabled}
                />
              </InputWithLabel>
            </fieldset>

            <InputWithLabel
              title="First Name"
              downMD="subtitle2"
              upMD="subtitle2"
              isRequired={true}
            >
              <MyInput
                placeholder="First Name"
                name="first_name"
                type="text"
              />
            </InputWithLabel>

            <InputWithLabel
              title="Last Name"
              downMD="subtitle2"
              upMD="subtitle2"
              isRequired={false}
            >
              <MyInput
                placeholder="Last Name"
                name="last_name"
                type="text"
              />
            </InputWithLabel>

            <InputWithLabel
              title="Job Title"
              downMD="subtitle2"
              upMD="subtitle2"
              isRequired={true}
            >
              <FormControl variant="outlined">
                <MySelect
                  name="current_role"
                  placeholder="Job title"
                  type="text"
                >
                  {user?.org_type &&
                    AllJobTitlesList.map(
                      (jobTitle: IJobTitleProps) => (
                        <MenuItem
                          key={jobTitle.id}
                          value={jobTitle.value}
                        >
                          {jobTitle.label}
                        </MenuItem>
                      )
                    )}
                </MySelect>
              </FormControl>
            </InputWithLabel>

            <InputWithLabel
              title="Contact no"
              downMD="subtitle2"
              upMD="subtitle2"
              isRequired={true}
            >
              <Box display="flex" gridColumnGap={5}>
                <SelectCountryCode
                  placeholder="Ex: 91"
                  name="country_code"
                  type="number"
                />
                <MyInput
                  placeholder="Enter number"
                  name="contact_number"
                  type="number"
                />
              </Box>
            </InputWithLabel>
            <br />
            <Box display="flex" justifyContent="flex-end">
              <SaveButton buttonText="Save" disabled={isSubmitting} />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalDetailsUpdate;

const useStyles = makeStyles((theme) => ({
  fieldset__disabled: {
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  input__disabled: {
    '&:hover': {
      border: 'none',
      cursor: 'not-allowed',
    },
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';

const QuestionSummaryTable: React.FC<IHubsCandidateCommonQuestionSummaryTableItemProps> =
  ({
    score,
    questionName,
    questionType,
    showSolution = false,
    children,
    idx,
    questionScore,
  }) => {
    return (
      <TableRow>
        <TableCell>{idx + 1}</TableCell>
        <TableCell>{questionName}</TableCell>
        <TableCell align="center">{questionType}</TableCell>
        <TableCell align="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pl={8}
            fontSize="inherit"
          >
            {score === null ? (
              <Tooltip
                title="To be graded by organizer"
                placement="top"
                arrow
              >
                <HourglassEmptyOutlinedIcon htmlColor="#666" />
              </Tooltip>
            ) : (
              <Typography variant="inherit">
                {score}/{questionScore}
              </Typography>
            )}
          </Box>
        </TableCell>
        {showSolution && (
          <TableCell align="center">{children}</TableCell>
        )}
      </TableRow>
    );
  };

export default QuestionSummaryTable;

import { AxiosError } from 'axios';
import { useLayoutEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import {
  API_BUSINESS_EMAIL_CONFIRM,
  FRONTEND_BUSINESS_RESEND_CONFIRMATION,
} from 'business/pre-subscription/auth/utils/constants';
import {
  FRONTEND_BUSINESS_DASHBOARD_URL,
  HUBS_APP_TYPE_PARAM,
} from 'business/organizer/common/utils/constants';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import { getErrorMessage } from 'business/pre-subscription/auth/components/common/helpers';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import { FRONTEND_LOGIN_URL } from 'common/utils/constants';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import useEmailVerification from 'business/pre-subscription/auth/context/email-verification/useEmailVerification';

const businessAuthService = new BusinessAuthService();
const EmailConfirmationPage = () => {
  const { key } = useParams<{ key: string }>();
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const hasAppTye = params.has(HUBS_APP_TYPE_PARAM);
  const { setEmailVerificationResponse } = useEmailVerification();

  const [error, setError] = useState<IBusinessFormErrorProps | null>(
    null
  );

  useLayoutEffect(() => {
    const handleVerification = async () => {
      try {
        const response = await businessAuthService.authPost(
          API_BUSINESS_EMAIL_CONFIRM,
          {
            key,
          }
        );
        setEmailVerificationResponse(response?.data);
        if (response) {
          history.push(
            response.data.app_type === 'hubs'
              ? `/hubs${FRONTEND_BUSINESS_DASHBOARD_URL}`
              : FRONTEND_BUSINESS_DASHBOARD_URL
          );
        }
      } catch (error) {
        setError(
          (error as AxiosError).response
            ?.data as IBusinessFormErrorProps
        );
      }
    };
    handleVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const errorMessage = getErrorMessage(error);
  const isEmailAlreadyVerified =
    errorMessage === 'This email is already verfied.';

  const resetError = () => {
    setError(null);
  };

  const handleOnAgree = () => {
    if (isEmailAlreadyVerified) {
      console.log(
        hasAppTye ? `/hubs${FRONTEND_LOGIN_URL}` : FRONTEND_LOGIN_URL,
        '<<<<<<<<<<<<<<<'
      );
      history.push(
        hasAppTye ? `/hubs${FRONTEND_LOGIN_URL}` : FRONTEND_LOGIN_URL
      );
      return;
    }
    history.push(FRONTEND_BUSINESS_RESEND_CONFIRMATION);
    resetError();
  };

  return (
    <>
      {error !== null ? (
        <FormSectionBase>
          <NotificationDialog
            open={error !== null}
            variant="error"
            disagreeText="Close"
            onDisagree={resetError}
            agreeText={
              isEmailAlreadyVerified
                ? 'Go to Login'
                : 'Resend Confirmation'
            }
            onAgree={handleOnAgree}
            content={errorMessage}
          />
        </FormSectionBase>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          px={12}
        >
          <GenericListSkeleton items={3} />
        </Box>
      )}
    </>
  );
};

export default EmailConfirmationPage;

export const HUBS_CANDIDATE_API_SIGNUP_URL =
  '/api/hubs/candidates/signup/';
export const HUBS_CANDIDATE_API_LOGIN_URL =
  '/api/hubs/candidates/login/';
export const HUBS_CANDIDATE_USER_PROFILE_API =
  '/api/hubs/candidates/profile/';
export const HUBS_CANDIDATE_API_TEST_URL =
  '/api/hubs/candidates/assessments/';
export const HUBS_CANDIDATE_API_TESTS_COUNT_URL =
  '/api/hubs/candidates/assessment-type-counts/';
export const HUBS_CANDIDATE_API_LOGOUT_URL =
  '/api/hubs/candidates/logout/';
export const HUBS_CANDIDATE_API_FORGOT_PASSWORD_URL =
  '/api/hubs/candidates/forgot-password/';
export const HUBS_CANDIDATE_API_CONFIRM_FORGOT_PASSWORD_URL =
  '/api/hubs/candidates/confirm-forgot-password/';
export const HUBS_CANDIDATE_API_CHANGE_PASSWORD_URL =
  '/api/hubs/candidates/profile/change-password/';

import React, { useState } from 'react';
import Button from '@material-ui/core/Button/Button';

import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import QuestionPreviewPage from 'business/organizer/library/pages/question-preview/QuestionPreviewPage';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import PersistSidebar from 'common/components/utility-components/PersistSidebar';
import { useLocation } from 'react-router-dom';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';

const QuestionItemPreview: React.FC<IQuestionItemPreviewProps> = ({
  type,
  id,
  questionUrl,
}) => {
  const { url } = useCurrentUrl();
  const [previewDrawer, setPreviewDrawer] = useState(false);

  const { toggleDrawer } = useToggleDrawer();
  const { pathname } = useLocation();
  const { drawerList } = useDrawer();

  return (
    <>
      {!drawerList.includes('preview') && (
        <PersistSidebar
          endingPath={
            pathname.endsWith(`/preview/${id}`)
              ? `/preview/${id}`
              : `/preview/${id}/edit/${id}`
          }
          sidebar={previewDrawer}
          onSidebar={(preview: boolean) => setPreviewDrawer(preview)}
          sidebarName="preview"
        />
      )}
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={toggleDrawer(
          true,
          setPreviewDrawer,
          'preview',
          `${url}/${type}/preview/${id}`
        )}
      >
        View
      </Button>
      <CustomDrawer
        drawer={previewDrawer}
        setDrawer={setPreviewDrawer}
        drawerName="preview"
      >
        <>
          <QuestionPreviewPage
            questionUrl={questionUrl}
            setPreviewDrawer={setPreviewDrawer}
          />
        </>
      </CustomDrawer>
    </>
  );
};

export default QuestionItemPreview;

import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button/Button';
import MuiLink from '@material-ui/core/Link';

import QuestionItemPreview from 'business/organizer/assessment/components/common/question/QuestionItemPreview';
import QuestionItemTags from 'business/organizer/assessment/components/common/question/QuestionItemTags';
import QuestionItemStatsList from 'business/organizer/assessment/components/common/question/QuestionItemStatsList';
import QuestionItemActions from 'business/organizer/assessment/components/common/question/QuestionItemActions';
import QuestionDrawerProvider from 'business/organizer/library/contexts/question-drawer/QuestionDrawerProvider';
import useIncompleteQuestions from 'business/organizer/library/contexts/incomplete-questions/useIncompleteQuestions';
import IncompleteLabel from 'business/organizer/common/components/IncompleteLabel';
import useTestQuestions from 'business/organizer/assessment/contexts/test-questions/useTestQuestions';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import SubscribeTooltip from 'business/organizer/common/components/SubscribeTooltip';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import QuestionItemType from 'business/organizer/assessment/components/common/question/QuestionItemType';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';

const organizerService = new OrganizerService();

const QuestionListItem: React.FC<ITestQuestionListItemProps> = ({
  item,
  idx,
  isUpdated,
}) => {
  const [response, setResponse] = useState<any | null>(null);

  const {
    library_question,
    is_hidden,
    url: testQuestionUrl,
    try_url,
  } = response !== null ? response : item || {};

  useEffect(() => {
    if (isUpdated) {
      organizerService
        .get(testQuestionUrl)
        .then((response) => setResponse(response?.data))
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const {
    is_complete,
    id,
    type,
    is_shared,
    can_edit,
    question,
    url: questionUrl,
  } = library_question || {};
  const { score, difficulty, display_time_duration } = question || {};
  const { setIncompleteQuestions } = useIncompleteQuestions();
  const { setHiddenQuestionResponse, setIsQuestionDeleted } =
    useTestQuestions();
  const { testDetail } = useTestDetail();
  const { isSubscriptionExpired, isViewer } = useHasPermission();
  const [isHidden, setIsHidden] = useState(is_hidden);

  const onSetIsHidden = (value: boolean) => {
    setIsHidden(value);
  };

  const onSetHiddenResponse = (data: AxiosResponse) => {
    setHiddenQuestionResponse(data);
  };

  const onSetDeleteResponse = () => {
    setIsQuestionDeleted((deleted) => !deleted);
  };

  useEffect(() => {
    if (!is_complete) {
      setIncompleteQuestions((questions) => [
        ...(questions || []),
        { id: id, name: question?.name },
      ]);
    }
  }, [is_complete, question?.name, id, setIncompleteQuestions]);

  return (
    <QuestionDrawerProvider>
      <Box
        boxShadow="0px 3px 6px #DDE6ED"
        borderRadius={5}
        p={4}
        bgcolor="#FFFFFF"
      >
        <Grid container style={{ opacity: isHidden ? '0.5' : '1' }}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box px={4}>
                <Typography variant="h5">
                  {idx < 10 ? `0${idx + 1}` : idx + 1}.&nbsp;{' '}
                  {question?.name}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={2}
              >
                <MuiLink
                  href={try_url}
                  target="_blank"
                  underline="none"
                >
                  <Button
                    variant="text"
                    color="default"
                    endIcon={
                      <OpenInNewIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    }
                  >
                    Try
                  </Button>
                </MuiLink>

                {isViewer || isSubscriptionExpired ? (
                  <HasPermission
                    isViewer={isViewer}
                    isSubscriptionExpired={isSubscriptionExpired}
                    tooltipProps={{ placement: 'top', arrow: true }}
                  >
                    <IconButton
                      disabled={isViewer || isSubscriptionExpired}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </HasPermission>
                ) : (
                  <QuestionItemActions
                    is_shared={is_shared}
                    can_edit={can_edit}
                    id={id}
                    type={type}
                    testQuestionUrl={testQuestionUrl}
                    onSetIsHidden={onSetIsHidden}
                    isHidden={isHidden}
                    onSetHiddenResponse={onSetHiddenResponse}
                    onSetDeleteResponse={onSetDeleteResponse}
                    testStatus={testDetail?.status}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" gridColumnGap={16} px={4}>
              <QuestionItemStatsList
                score={score}
                difficulty={difficulty}
                time_duration={display_time_duration}
              />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box
              display="flex"
              flexWrap="wrap"
              gridRowGap={8}
              gridColumnGap={8}
              maxWidth="75%"
              px={4}
              mt={2}
            >
              <QuestionItemType type={type} />
              {!!library_question?.tags.length && (
                <QuestionItemTags tags={library_question.tags} />
              )}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" p={2}>
              {isSubscriptionExpired ? (
                <SubscribeTooltip>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={isSubscriptionExpired}
                  >
                    View
                  </Button>
                </SubscribeTooltip>
              ) : (
                <QuestionItemPreview
                  id={id}
                  type={type}
                  questionUrl={questionUrl}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        {(!is_complete || isHidden) && (
          <Box display="flex" gridColumnGap={4}>
            {!is_complete && (
              <IncompleteLabel
                label="Incomplete"
                hint="This questions is missing one or more fields which would help candidates understand and answer the question better"
              />
            )}
            {isHidden && (
              <IncompleteLabel
                label="Hidden"
                hint="This question is removed from assessment"
              />
            )}
          </Box>
        )}
      </Box>
    </QuestionDrawerProvider>
  );
};

export default React.memo(QuestionListItem);

import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'business/pre-subscription/auth/pages/Login';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import ForgotPassword from 'business/pre-subscription/auth/pages/ForgotPassword';
import ResetPassword from 'business/pre-subscription/auth/pages/ResetPassword';
import ResendConfirmation from 'business/pre-subscription/auth/pages/ResendConfirmation';
import ConfirmEmail from 'business/pre-subscription/auth/pages/ConfirmEmail';

const AuthRoutes = () => {
  const { url } = useCurrentUrl();

  return (
    <Switch>
      <Route path={`${url}/confirm-email`}>
        <ConfirmEmail />
      </Route>
      <Route path={`${url}/resend-confirmation-link`}>
        <ResendConfirmation />
      </Route>
      <Route path={`${url}/password-reset-confirm/:uid/:token/`}>
        <ResetPassword />
      </Route>
      <Route path={`${url}/forgot-password`}>
        <ForgotPassword />
      </Route>
      <Route path={`${url}/login`}>
        <Login />
      </Route>
      <Redirect from={url} to={`${url}/signup`} />
    </Switch>
  );
};

export default AuthRoutes;

import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useTheme } from '@material-ui/core';

import useQuestionSubmission from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useQuestionSubmission';
import QuestionSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionSolution';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import NotebookPreview from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/challenge/NotebookPreview';
import { submissionStatusList } from 'business/organizer/assessment/components/candidate-progress/candidate-detail/utils/helpers';

const ChallengeSolution: React.FC<IChallengeQuestionSolutionProps> =
  ({ submissionUrl }) => {
    const theme = useTheme();
    const { submission, responseError } =
      useQuestionSubmission(submissionUrl);
    const { url } = useCurrentUrl();

    const [nbDrawer, setNbDrawer] = useState(false);

    const { toggleDrawer } = useToggleDrawer();

    const getNotebookId = (submission: any) => {
      if (submission) {
        const splitNotebookUrl =
          submission?.submission?.notebook_url.split('/');
        const notebookId =
          splitNotebookUrl[splitNotebookUrl.length - 2];
        return notebookId;
      }
    };

    const notebookId = getNotebookId(submission);

    return (
      <QuestionSolution
        submission={submission}
        responseError={responseError}
      >
        <>
          <Box
            bgcolor="#FFFFFF"
            py={4}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            boxShadow="0px 3px 6px #DDE6ED"
            borderRadius={5}
          >
            <Box>
              <Typography variant="subtitle2">Result</Typography>
              <Typography variant="body1" align="center">
                {submissionStatusList[submission?.submission?.status]}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Model Prediction Score
              </Typography>
              <Typography variant="body1" align="center">
                {submission?.submission?.practice_score || '-'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Evaluation Metric
              </Typography>
              <Typography variant="body1" align="center">
                {
                  submission?.submission?.evaluation_metric[0]
                    ?.evaluator?.name
                }
              </Typography>
            </Box>
          </Box>
          <Box
            py={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={theme.palette.info.main}
          >
            <Button
              variant="text"
              color="inherit"
              endIcon={<OpenInNewIcon color="inherit" />}
              onClick={toggleDrawer(
                true,
                setNbDrawer,
                'notebook',
                `${url}/notebook/${notebookId}`
              )}
            >
              View Notebook
            </Button>
            <CustomDrawer
              drawer={nbDrawer}
              setDrawer={setNbDrawer}
              drawerName="notebook"
            >
              <Route path={`${url}/notebook/${notebookId}`}>
                <>
                  <CloseDrawerIcon
                    closeQuestionDrawer={toggleDrawer(
                      false,
                      setNbDrawer,
                      'notebook'
                    )}
                  />
                  <NotebookPreview
                    apiUrl={submission?.submission?.notebook_url}
                  />
                </>
              </Route>
            </CustomDrawer>
          </Box>
        </>
      </QuestionSolution>
    );
  };

export default ChallengeSolution;

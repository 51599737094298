import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import TestCaseForm from 'business/organizer/library/components/question-create-update/coding/testcases/TestCaseForm';
import useTestCases from 'business/organizer/library/contexts/coding-question/custom-hooks/useTestCases';
import useCodingQuestion from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestion';
import TestCasesList from 'business/organizer/library/components/question-create-update/coding/testcases/TestCasesList';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import HttpError from 'common/components/http-error/HttpError';
import { testCasesWarningMessage } from 'business/organizer/library/components/question-create-update/coding/utils/constants';
import TestcasesUploadButton from 'business/organizer/library/components/question-create-update/coding/testcases/TestcasesUploadButton';
import TestCasesHeadSection from 'business/organizer/library/components/question-create-update/coding/testcases/TestCasesHeadSection';

const libraryService = new LibraryService();

const Testcases = () => {
  const {
    showModal,
    onShowModal,
    onCloseModal,
    testCases,
    setTestCases,
    setTestCasesResponseError,
    testCasesResponseError,
    isLoading,
    setIsLoading,
  } = useTestCases();
  const { problemStatementResponse } = useCodingQuestion();
  const { values } = useFormikContext<{ name: string }>();

  useEffect(() => {
    let mounted = true;

    if (isLoading) {

      libraryService
        .get(`${problemStatementResponse?.question.testcases_url}`)
        .then((response) => {
          if (mounted) {
            setTestCases(response?.data);
            setIsLoading(false);
          }
        })
        .catch((error) => setTestCasesResponseError(error));
    }
    return () => {
      mounted = false;
    };
  }, [
    isLoading,
    problemStatementResponse?.url,
    setIsLoading,
    setTestCases,
    setTestCasesResponseError,
    problemStatementResponse?.question.testcases_url,
  ]);

  return (
    <>
      <GenerateBreadcrumb
        data={[
          {
            label: `${values.name.slice(0, 12)}...`,
            route: '',
          },
        ]}
      />
      <TestCasesHeadSection />

      {!!testCases && (
        <Box py={2}>
          <Alert severity="warning">{testCasesWarningMessage}</Alert>
        </Box>
      )}
      <Box py={4} display="flex" gridColumnGap={12}>
        <Box flexShrink={0}>
          <Button
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={onShowModal}
          >
            Add Test Case
          </Button>
        </Box>
        <TestcasesUploadButton />
      </Box>
      {showModal && (
        <TestCaseForm
          showModal={showModal}
          onCloseModal={onCloseModal}
        />
      )}
      {testCasesResponseError !== null ? (
        <HttpError
          axiosError={testCasesResponseError}
          message="Not able to load the test cases."
        />
      ) : testCases === null ? (
        <GenericListSkeleton items={3} />
      ) : (
        testCases !== null && !!testCases.length && <TestCasesList />
      )}
    </>
  );
};

export default Testcases;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CodingUpdatePage from 'business/organizer/library/pages/question-create-update/coding/CodingUpdatePage';
import CodingPage from 'business/organizer/library/pages/question-create-update/coding/CodingPage';
import TestCasesProvider from 'business/organizer/library/contexts/coding-question/provider/TestCasesProvider';

const CodingRoutes = () => {
  const { url } = useCurrentUrl();
  return (
    <TestCasesProvider>
      <Switch>
        <Route path={`${url}/edit/:codingId`}>
          <CodingUpdatePage />
        </Route>
        <Route path={`${url}/create`}>
          <CodingPage drawerName="create" />
        </Route>
        <Redirect from={url} to={`${url}/create`} />
      </Switch>
    </TestCasesProvider>
  );
};

export default CodingRoutes;

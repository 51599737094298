import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import ReInvite from 'business/organizer/assessment/pages/assessment-tests/test-detail/tabs/ReInvite';
import Invite from 'business/organizer/assessment/pages/assessment-tests/test-detail/tabs/Invite';

const InviteRoutes = () => {
  const { url } = useCurrentUrl();

  return (
    <Switch>
      <Route path={`${url}/:invitationId`}>
        <ReInvite />
      </Route>
      <Route path={`${url}`}>
        <Invite />
      </Route>
      <Redirect from={url} to={url} />
    </Switch>
  );
};

export default InviteRoutes;

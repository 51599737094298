import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const NotebookHtmlSkeleton: React.FC<{}> = () => {
  return (
    <Grid container direction="column">
      {[...Array(6)].map((_, idx) => (
        <Grid item xs={12} key={idx}>
          <Box
            display="flex"
            // alignItems="center"
            justifyContent="flex-start"
            padding={1}
            width="100%"
            height="100%"
            flexDirection="column"
          >
            <Skeleton width="100%" component="div" />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default NotebookHtmlSkeleton;

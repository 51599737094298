import React, { useState } from 'react';

import useCustomStepper from 'common/components/custom-stepper/useCustomStepper';
import CodingQuestionContext from 'business/organizer/library/contexts/coding-question/contexts/CodingQuestionContext';
import { stepsInfo } from 'business/organizer/library/pages/question-create-update/coding/CodingPage';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import useCodingQuestionData from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestionData';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import { getTagsList } from 'business/organizer/library/utils/getTagsList';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { UPDATE_LIBRARY } from 'business/organizer/library/contexts/library/libraryActionTypes';
import useAlert from 'common/components/alert-provider/useAlert';
import { hasMessageProperty } from 'common/utils/errors';

const libraryService = new LibraryService();

enum StepLabel {
  PROBLEM_STATEMENT = 0,
  TESTCASES = 1,
}

const CodingQuestionProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { enqueueAlert } = useAlert();

  const { dispatch } = useLibrary();

  const { coding } = useCodingQuestionData();
  const { handleAddQuestionsToTest } = useAddQuestionsToTest();
  const {
    activeStep,
    completedSteps,
    prevStep,
    nextStep,
    setActiveStep,
  } = useCustomStepper(stepsInfo.length);

  const [problemStatementResponse, setProblemStatementResponse] =
    useState<any | null>(null);

  const handleProblemStatement = async (
    data: IInitialStateCodingFormProps
  ) => {
    try {
      const {
        name,
        description,
        time_duration,
        difficulty,
        languages,
        tags,
      } = data;

      const questionData = {
        type: 'coding',
        tags: getTagsList(tags),
        question: {
          name,
          description,
          time_duration,
          difficulty,
          languages,
        },
      };

      const response =
        coding !== null ||
        completedSteps.has(StepLabel.PROBLEM_STATEMENT)
          ? await libraryService.patchQuestion(
              coding?.url || problemStatementResponse.url,
              questionData
            )
          : await libraryService.createQuestion(
              MY_LIBRARY_QUESTION_API_URL,
              questionData
            );

      if (response) {
        setProblemStatementResponse(response?.data);
        dispatch({ type: UPDATE_LIBRARY, payload: { list: 'my' } });
        nextStep();
      }
    } catch (error) {
      enqueueAlert(
        `${
          hasMessageProperty(error)
            ? error.message
            : '' ||
              `Something went wrong while ${
                coding !== null ? 'updating' : 'adding'
              } question. Please try again after sometime`
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  const handleAddCodingQuestionToTest = (testId: number) => {
    if (!coding) {
      handleAddQuestionsToTest(testId, problemStatementResponse?.id);
    } else {
      enqueueAlert('Question is updated successfully.', {
        alertProps: { severity: 'success' },
      });
    }
  };

  return (
    <CodingQuestionContext.Provider
      value={{
        activeStep,
        completedSteps,
        prevStep,
        nextStep,
        setActiveStep,
        handleProblemStatement,
        problemStatementResponse,
        setProblemStatementResponse,
        handleAddCodingQuestionToTest,
      }}
    >
      {children}
    </CodingQuestionContext.Provider>
  );
};

export default CodingQuestionProvider;

import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import useIncompleteQuestions from 'business/organizer/library/contexts/incomplete-questions/useIncompleteQuestions';
import ConfirmationDialog from 'common/components/confirmation-dialog/ConfirmationDialog';
import ShowIncompleteQuestions from 'business/organizer/assessment/components/common/ShowIncompleteQuestions';
import AddQuestionsModal from './AddQuestionsModal';

const QuestionsActions: React.FC<IQuestionsActionsProps> = ({
  testId,
}) => {
  const classes = useStyles();
  const {
    handleClearQuestions,
    unCheck,
    setUnCheck,
    handleAddQuestionsToTest,
  } = useAddQuestionsToTest();

  const { incompleteQuestions } = useIncompleteQuestions();

  const [confirm, setConfirm] = useState(false);
  const [openTestsModal, setOpenTestsModal] = useState(false);

  const closeAddQuestionsModal = () => setOpenTestsModal(false);

  useEffect(() => {
    setUnCheck(true);
  }, [setUnCheck]);

  const handleAddToTest = () => {
    if (testId) {
      handleAddQuestionsToTest(testId);
      setConfirm(false);
    } else {
      setOpenTestsModal(true);
    }
  };

  const { length } = incompleteQuestions;

  return (
    <>
      <ConfirmationDialog
        open={confirm}
        variant="warning"
        disagreeText="Cancel"
        onDisagree={() => setConfirm(false)}
        onAgree={handleAddToTest}
        agreeText="Add"
      >
        <>
          <Typography>
            Following questions are incomplete, Please complete those
            questions otherwise it may cause confusion to candidates,
            If you still want to continue, Click on "Add"
          </Typography>
          <ShowIncompleteQuestions />
        </>
      </ConfirmationDialog>
      <Box className={classes.box_questions_actions}>
        <FormControlLabel
          control={
            <Checkbox
              checked={unCheck}
              onChange={handleClearQuestions}
              name="clearQuestions"
              indeterminate
            />
          }
          label="Uncheck all"
        />
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={
            !!length ? () => setConfirm(true) : handleAddToTest
          }
        >
          Add to test
        </Button>
        <AddQuestionsModal
          open={openTestsModal}
          closeAddQuestionsModal={closeAddQuestionsModal}
        />
      </Box>
    </>
  );
};

export default QuestionsActions;

const useStyles = makeStyles((theme) => ({
  box_questions_actions: {
    paddingLeft: theme.spacing(7),
    '& .MuiFormControlLabel-label': {
      ...theme.typography.h6,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.warning.main,
    },
  },
}));

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const ShowStatus: React.FC<IShowStatusProps> = ({
  status,
  idleIcon = false,
}) => {
  switch (status) {
    case 'idle':
      return idleIcon ? (
        <CheckCircleRoundedIcon color="disabled" />
      ) : (
        <></>
      );
    case 'pending':
      return <CircularProgress size={22} />;
    case 'resolved':
      return <CheckCircleRoundedIcon color="primary" />;
    case 'rejected':
      return <CancelRoundedIcon color="error" />;
    default:
      throw new Error('This is impossible, there is no such status.');
  }
};

export default ShowStatus;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import ReportDrawer from 'business/organizer/interviews/components/report/ReportDrawer';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';
import PersistSidebar from 'common/components/utility-components/PersistSidebar';
import ViewIcon from '../icons/ViewIcon';
import { makeStyles } from '@material-ui/core';

const InterviewReport: React.FC<{
  interview: Interviews.IInterviewsListItem;
}> = ({ interview }) => {
  const classes = useStyles();
  const [reportDrawer, setReportDrawer] = useState(false);

  const { toggleDrawer } = useToggleDrawer();
  const { url } = useCurrentUrl();
  const { drawerList } = useDrawer();

  return (
    <>
      {!drawerList.includes('interviewReport') && (
        <PersistSidebar
          endingPath={`${interview.id}`}
          sidebarName="interviewReport"
          sidebar={reportDrawer}
          onSidebar={(drawer: boolean) => {
            setReportDrawer(drawer);
          }}
        />
      )}
      <Link
        to={`${url}/${interview.id}`}
        className={classes.link_button}
      >
        <Button
          startIcon={<ViewIcon />}
          onClick={toggleDrawer(
            true,
            setReportDrawer,
            'interviewReport'
          )}
        >
          View
        </Button>
      </Link>
      <CustomDrawer
        drawer={reportDrawer}
        setDrawer={setReportDrawer}
        drawerName="interviewReport"
      >
        <>
          <CloseDrawerIcon
            closeQuestionDrawer={toggleDrawer(
              false,
              setReportDrawer,
              'interviewReport'
            )}
          />
          <ReportDrawer interview={interview} />
        </>
      </CustomDrawer>
    </>
  );
};

export default InterviewReport;

const useStyles = makeStyles((theme) => ({
  link_button: {
    '& .MuiButton-root': {
      color: '#333333',
      fontSize: 14,
      fontWeight: 400,
    },
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box';

import MCQSolutionBase from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/mcq/MCQSolutionBase';
import QuestionSolution from 'hubs-candidate/common/components/candidate-progress/QuestionSolution';

const MCQSolution: React.FC<{ question: IMCQSolutionBaseProps }> = ({
  question,
}) => {
  return (
    <QuestionSolution question={question as IMCQSolutionBaseProps}>
      <Box py={2}>
        <MCQSolutionBase submission={question.submission} />
      </Box>
    </QuestionSolution>
  );
};

export default MCQSolution;

import React from 'react';
import Button from '@material-ui/core/Button';

import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useReviewed from 'business/organizer/assessment/components/candidate-progress/candidate-list/custom-hooks/useReviewed';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';

const Shortlist: React.FC<{
  disabled: boolean;
  isListPage: boolean;
}> = ({ disabled, isListPage }) => {
  const { testDetail } = useTestDetail();
  const { isViewer } = useHasPermission();

  const { handleReview } = useReviewed({
    apiUrl: testDetail?.candidate_shortlist_url as string,
    errorMessage: 'Error, While shortlisting the candidates',
    successMessage: 'Successfully, Shortlisted the candidates.',
    isListPage,
  });

  return (
    <HasPermission
      isViewer={isViewer}
      tooltipProps={{ placement: 'top', arrow: true }}
    >
      <Button
        onClick={handleReview}
        variant="outlined"
        color="primary"
        size="small"
        disabled={isViewer || disabled}
      >
        Shortlist
      </Button>
    </HasPermission>
  );
};

export default Shortlist;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | undefined;

interface MyTypographyType {
  content: string;
  responsiveVariants: responsiveVariantsTypes;
  className?: any;
  color?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
    | undefined;
}

interface responsiveVariantsTypes {
  downXl?: Variant;
  downLg?: Variant;
  downMd?: Variant;
  downSm?: Variant;
  downXs?: Variant;
}

const MyTypography = React.forwardRef<any, MyTypographyType>(
  (
    { content, responsiveVariants, ...otherProps },
    ref
  ): JSX.Element => {
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down('xs'));
    const downSm = useMediaQuery(theme.breakpoints.down('sm'));
    const downMd = useMediaQuery(theme.breakpoints.down('md'));
    const downLg = useMediaQuery(theme.breakpoints.down('lg'));
    const downXl = useMediaQuery(theme.breakpoints.down('xl'));

    const getTypographyVariant = () => {
      if (responsiveVariants.hasOwnProperty('downXs') && downXs)
        return responsiveVariants.downXs;
      if (responsiveVariants.hasOwnProperty('downSm') && downSm)
        return responsiveVariants.downSm;
      if (responsiveVariants.hasOwnProperty('downMd') && downMd)
        return responsiveVariants.downMd;
      if (responsiveVariants.hasOwnProperty('downLg') && downLg)
        return responsiveVariants.downLg;
      if (responsiveVariants.hasOwnProperty('downXl') && downXl)
        return responsiveVariants.downXl;
    };

    let responsiveVariant: Variant = getTypographyVariant();

    return (
      <Typography
        variant={responsiveVariant}
        {...otherProps}
        ref={ref}
      >
        {content}
      </Typography>
    );
  }
);

export default MyTypography;

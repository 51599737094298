import React from 'react';
import { FileWithPath } from 'react-dropzone';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import DatasetFileField from 'business/organizer/library/components/question-create-update/challenge/DatasetFileField';
import useChallengeQuestion from 'business/organizer/library/contexts/challenge-question/custom-hooks/useChallengeQuestion';
import { getDataset } from 'business/organizer/library/utils/getDataset';
import TargetVariableName from 'business/organizer/library/components/question-create-update/challenge/datasets/TargetVariableName';

const DatasetsUpload = () => {
  const { challenge, problemStatementResponse } =
    useChallengeQuestion();

  const predictionData = (value: FileWithPath) => {
    const predictions = {
      prediction_csv: value,
    };
    return predictions;
  };

  const predictionsUrls = {
    createUrl:
      problemStatementResponse?.question?.predictions_url ||
      challenge?.question?.predictions_url,
    editUrl: '',
  };

  const datasetCreateUrl =
    problemStatementResponse?.question?.datasets_url ||
    challenge?.question?.datasets_url;

  const datasetData = challenge?.question?.datasets;

  const trainingData = (value: FileWithPath) => {
    const training = {
      filename: 'Train Data',
      summary: 'training',
      file: value,
      type: 'train',
    };
    return training;
  };

  const trainingDatasetUrls = {
    createUrl: datasetCreateUrl,
    editUrl: datasetData
      ? getDataset(datasetData, 'train', 'url')
      : '',
  };

  const testingData = (value: FileWithPath) => {
    const testing = {
      filename: 'Test Data',
      summary: 'testing',
      file: value,
      type: 'test',
    };
    return testing;
  };

  const testinDatasetUrls = {
    createUrl: datasetCreateUrl,
    editUrl: datasetData
      ? getDataset(datasetData, 'test', 'url')
      : '',
  };

  const sampleSubmissionData = (value: FileWithPath) => {
    const sampleSubmission = {
      filename: 'Sample Submission',
      summary: 'sample submission',
      file: value,
      type: 'sample_submission',
    };
    return sampleSubmission;
  };

  const sampleSubmissionDatasetUrls = {
    createUrl: datasetCreateUrl,
    editUrl: datasetData
      ? getDataset(datasetData, 'sample_submission', 'url')
      : '',
  };

  return (
    <>
      <Box py={4}>
        <Typography variant="h5">Train Data</Typography>
        <DatasetFileField
          title="Upload training dataset"
          tooltipTitle="This dataset is used by candidates to train their model."
          isRequired={true}
          nameAttribute="trainingDataset"
          buttonText="Choose File"
          dataset={trainingData}
          apiUrls={trainingDatasetUrls}
        />
      </Box>
      <Divider />
      <Box py={4}>
        <Typography variant="h5">
          Test Data{' '}
          <Typography component="span" variant="h6">
            (Exclude target)
          </Typography>
        </Typography>
        <DatasetFileField
          title="Upload your test data excluding target variable"
          tooltipTitle="This dataset is used by candidates to apply their train models to predict the target variable."
          isRequired={true}
          nameAttribute="testingDataset"
          buttonText="Choose File"
          dataset={testingData}
          apiUrls={testinDatasetUrls}
        />
      </Box>
      <Divider />
      <Box py={4}>
        <Typography variant="h5">Solution Data</Typography>
        <Box display="flex" flexDirection="column" gridRowGap={8}>
          <DatasetFileField
            title="Upload target variable predictions (of test data)"
            tooltipTitle="The purpose of the dataset is to evaluate a candidate's model performance. This dataset is hidden from candidates as it contains true target variable predictions."
            isRequired={true}
            nameAttribute="evaluationDataset"
            buttonText="Choose File"
            dataset={predictionData}
            apiUrls={predictionsUrls}
          />
          <TargetVariableName />
          <DatasetFileField
            title="Sample submission"
            tooltipTitle="This dataset is shown as a sample submission to the candidates."
            isRequired={true}
            nameAttribute="sampleSubmission"
            buttonText="Choose File"
            dataset={sampleSubmissionData}
            apiUrls={sampleSubmissionDatasetUrls}
          />
        </Box>
      </Box>
    </>
  );
};

export default DatasetsUpload;

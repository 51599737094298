import React from 'react';

import FieldLabel from 'business/organizer/library/components/question-create-update/common/FieldLabel';
import useTags from 'business/organizer/library/custom-hooks/useTags';
import { LIBRARY_QUESTION_TAG_TYPE } from 'business/organizer/library/utils/constants';

import TagsField from 'common/components/form/TagsField';

const QuestionTagField: React.FC<IQuestionTagFieldProps> = ({
  ...props
}) => {
  const { tagSuggestions } = useTags({
    type: LIBRARY_QUESTION_TAG_TYPE,
  });
  return (
    <FieldLabel title="Tags" isRequired={false} {...props}>
      <TagsField tagSuggestions={tagSuggestions} />
    </FieldLabel>
  );
};

export default QuestionTagField;

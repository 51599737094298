import React, { useState } from 'react';
import FileUploadUpdatePageContext from 'business/organizer/library/contexts/file-upload-question/contexts/FileUploadUpdatePageContext';

const FileUploadUpdateProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [fileUpload, setFileUpload] = useState<any | null>(null);

  return (
    <FileUploadUpdatePageContext.Provider
      value={{ fileUpload, setFileUpload }}
    >
      {children}
    </FileUploadUpdatePageContext.Provider>
  );
};

export default FileUploadUpdateProvider;

import React from 'react';
import { TooltipProps } from '@material-ui/core';

import HasPermissionBase from 'business/organizer/common/components/HasPermissionBase';

const HasPermission: React.FC<{
  children: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  isViewer: boolean;
  isSubscriptionExpired?: boolean;
  isInterviewQuestion?: boolean;
}> = ({
  children,
  tooltipProps,
  isViewer,
  isSubscriptionExpired,
  isInterviewQuestion,
}) => {
  return (
    <HasPermissionBase
      condition={
        isViewer || !!isSubscriptionExpired || !!isInterviewQuestion
      }
      tooltipProps={{
        title: !!isInterviewQuestion
          ? "You can't use interview question for tests"
          : isViewer
          ? 'You have view only access'
          : 'Your current plan has been expired. Subscribe now, to continue using Skillspace Assessment Platform.',
        ...(tooltipProps || {}),
      }}
    >
      {children}
    </HasPermissionBase>
  );
};

export default HasPermission;

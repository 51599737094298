import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';

import useTestQuestions from 'business/organizer/assessment/contexts/test-questions/useTestQuestions';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import TestDuration from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/TestDuration';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const TestSummary: React.FC<ITestSummaryProps> = ({
  anchorEl,
  handleClick,
  closeDropdownWithDelay,
}) => {
  const classes = useStyles();
  const { testQuestionsCount } = useTestQuestions();
  const { testDetail } = useTestDetail();
  const { isSubscriptionExpired, isViewer } = useHasPermission();
  const { isHubsUrl } = useCurrentUrl();

  const { setLibrary } = useTestQuestions();
  const { toggleDrawer } = useToggleDrawer();

  return (
    <Box className={classes.box_root}>
      <Box display="flex" gridRowGap={12} flexDirection="column">
        <HasPermission
          isViewer={isViewer}
          isSubscriptionExpired={isSubscriptionExpired}
          tooltipProps={{ placement: 'left', arrow: true }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleDrawer(true, setLibrary, 'library')}
            startIcon={<AddIcon />}
            size="small"
            disabled={isViewer || isSubscriptionExpired}
          >
            Add From Library
          </Button>
        </HasPermission>
        <HasPermission
          isViewer={isViewer}
          isSubscriptionExpired={isSubscriptionExpired}
          tooltipProps={{ placement: 'left', arrow: true }}
        >
          <Button
            onMouseEnter={handleClick}
            onMouseLeave={closeDropdownWithDelay}
            onClick={handleClick}
            variant="outlined"
            color="primary"
            endIcon={
              anchorEl !== null ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )
            }
            size="small"
            disabled={isViewer || isSubscriptionExpired}
          >
            Create Question
          </Button>
        </HasPermission>
      </Box>
      <Box
        bgcolor="#f1f6f9"
        border="1px solid #EAEEFF"
        boxShadow="0px 6px 12px #DDE6ED"
        borderRadius={5}
        p={2}
        my={6}
        display="flex"
        flexDirection="column"
        gridRowGap={8}
      >
        <Box className={classes.box__test_summary_item}>
          <TestDuration />
        </Box>
        <Box className={classes.box__test_summary_item}>
          <TrackChangesOutlinedIcon
            color="inherit"
            fontSize="inherit"
          />
          <Typography variant="h6" color="textSecondary">
            Total Score: {testDetail?.total_score}
          </Typography>
        </Box>
        <Box className={classes.box__test_summary_item}>
          <AssignmentIcon color="inherit" fontSize="inherit" />
          <Typography variant="h6" color="textSecondary">
            Total Questions: {testQuestionsCount}
          </Typography>
        </Box>
      </Box>
      <Box py={0}>
        {!isHubsUrl && testDetail?.role && (
          <>
            <Typography variant="subtitle1">
              Role: {testDetail?.role}
            </Typography>
            <Divider orientation="vertical" flexItem />
          </>
        )}
      </Box>
    </Box>
  );
};

export default TestSummary;

const useStyles = makeStyles((theme) => ({
  box_root: {
    padding: theme.spacing(4, 0),
    position: 'sticky',
    top: 176,
  },
  box__test_summary_item: {
    display: 'inline-flex',
    alignItems: 'center',
    gridColumnGap: theme.spacing(4),
  },
}));

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import {
  FRONTEND_BUSINESS_RESEND_CONFIRMATION,
  SIGNUP_API_URL,
} from 'business/pre-subscription/auth/utils/constants';
import useAsync from 'common/custom-hooks/useAsync';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import SignUpForm from 'business/pre-subscription/auth/components/signup/SignUpForm';
import ContentSection from 'business/pre-subscription/auth/components/ContentSection';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import AuthPageBase from 'business/pre-subscription/common/components/AuthPageBase';
import Caption from 'business/pre-subscription/auth/components/signup/Caption';
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from 'common/utils/constants';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const businessAuthService = new BusinessAuthService();

const SignUp: React.FC<{
  title: string;
  subtitle: string;
}> = ({ title, subtitle }) => {
  const params = new URLSearchParams(useLocation().search);
  const {
    run,
    data: inviteData,
    status,
    error: inviteDataError,
  } = useAsync();
  const { isHubsUrl } = useCurrentUrl();

  const hasInvitation = params.has('invitation');
  const invitationId = params.get('invitation');

  useEffect(() => {
    if (hasInvitation) {
      run(
        businessAuthService.get(SIGNUP_API_URL, {
          invitation: invitationId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInvitation, run]);

  return (
    <AuthPageBase contentSection={<ContentSection />}>
      <FormSectionBase
        title={title}
        subtitle={subtitle}
        caption={
          <>
            <Caption
              link={
                isHubsUrl
                  ? `/hubs${FRONTEND_BUSINESS_RESEND_CONFIRMATION}`
                  : FRONTEND_BUSINESS_RESEND_CONFIRMATION
              }
            />
            <Box color="#666" py={2}>
              <Typography
                variant="body2"
                color="inherit"
                align="center"
              >
                By signing up, you agree to our{' '}
                <MuiLink href={TERMS_OF_SERVICE_URL} target="_blank">
                  Terms of Service{' '}
                </MuiLink>
                and our{' '}
                <MuiLink href={PRIVACY_POLICY_URL} target="_blank">
                  Privacy Policy
                </MuiLink>
                .
              </Typography>
            </Box>
          </>
        }
      >
        <Box>
          {hasInvitation ? (
            <GenericResponseHandler
              error={inviteDataError}
              status={status}
            >
              <SignUpForm
                hasInvitation={hasInvitation}
                inviteData={inviteData}
                invitationId={invitationId}
              />
            </GenericResponseHandler>
          ) : (
            <SignUpForm />
          )}
        </Box>
      </FormSectionBase>
    </AuthPageBase>
  );
};

export default SignUp;

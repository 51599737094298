import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';

import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import RoleList from 'business/organizer/assessment/components/assessment-tests/test-list/choose-role/RoleList';
import useTests from 'business/organizer/assessment/contexts/assessment-tests/useTests';
import { dropdownStyles } from 'business/organizer/common/utils/styles/dropdownStyles';
import TestCreationModal from 'business/organizer/assessment/components/assessment-tests/test-list/TestCreationModal';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const TestsMenu: React.FC<
  IMenuProps & {
    addQuestionToTest?:
      | ((testId: number) => Promise<void>)
      | undefined;
    onRolesModal: (value: boolean) => void;
    openRolesModal: boolean;
  }
> = ({
  anchorEl,
  handleClose,
  addQuestionToTest,
  openRolesModal,
  onRolesModal,
  ...otherProps
}) => {
  const classes = useStyles();

  const { handleTestCreation } = useTests();
  const { isHubsUrl } = useCurrentUrl();

  const testsData = useMemo(
    () => [
      {
        id: 1,
        label: 'Hiring',
        divider: true,
        handleClick: () => onRolesModal(true),
      },
      {
        id: 2,
        label: 'Internal',
        divider: true,
        handleClick: () =>
          handleTestCreation('internal', '', false, addQuestionToTest),
      },
      {
        id: 3,
        label: 'Custom',
        divider: false,
        handleClick: () =>
          handleTestCreation('custom', '', false, addQuestionToTest),
      },
    ],
    [addQuestionToTest, handleTestCreation, onRolesModal]
  );

  return (
    <>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
        {...otherProps}
      >
        {testsData.map(({ id, label, divider, handleClick }) => (
          <Box
            key={id}
            onClick={handleClick}
            className={classes.box_root}
          >
            <ListItem divider={divider}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h6' }}
              >
                {label}
              </ListItemText>
            </ListItem>
          </Box>
        ))}
      </DropdownMenu>
      {isHubsUrl ? (
        <RoleList
          openRolesModal={openRolesModal}
          closeRolesModal={() => onRolesModal(false)}
          addQuestionToTest={addQuestionToTest}
        />
      ) : (
        <TestCreationModal
          openTestCreationModal={openRolesModal}
          closeTestCreationModal={() => onRolesModal(false)}
          addQuestionToTest={addQuestionToTest}
        />
      )}
    </>
  );
};

export default TestsMenu;

const useStyles = makeStyles((theme) => ({
  box_root: {
    width: 142,
    cursor: 'pointer',
    '& .MuiListItem-root:hover': {
      width: '100%',
      backgroundColor: 'rgba(182, 217, 251, 0.15)',
    },
  },
}));

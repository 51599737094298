import { useState } from 'react';
import Container from '@material-ui/core/Container';

import RequestDemoField from 'business/pre-subscription/request-demo/components/RequestDemoField';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import ContentSection from 'business/pre-subscription/auth/components/ContentSection';
import AuthPageBase from 'business/pre-subscription/common/components/AuthPageBase';

const RequestDemo = () => {
  const [next, setNext] = useState(false);

  const handleNext = () => {
    setNext(true);
  };

  return (
    <AuthPageBase
      contentSection={<ContentSection next={next} showSignUpText />}
    >
      <FormSectionBase
        title={!next ? 'Request A Demo' : "You're Almost Done"}
        subtitle={
          !next
            ? 'We are glad that you showed interest in us, Let us know the following details to get started'
            : undefined
        }
      >
        <Container maxWidth="xl">
          <RequestDemoField next={next} onNext={handleNext} />
        </Container>
      </FormSectionBase>
    </AuthPageBase>
  );
};

export default RequestDemo;

import React, { useState } from 'react';
import McqContext from 'business/organizer/library/contexts/mcq/McqContext';

const McqProvider = ({ children }: { children: JSX.Element }) => {
  const [
    mcqResponse,
    setMcqResponse,
  ] = useState<IMcqQuestionResponseProps | null>(null);
  const [mcqChoices, setMcqChoices] = useState<IMcqChoicesProps>({
    remove: null,
    create: null,
    update: null,
  });

  return (
    <McqContext.Provider
      value={{
        mcqChoices,
        setMcqChoices,
        mcqResponse,
        setMcqResponse,
      }}
    >
      {children}
    </McqContext.Provider>
  );
};

export default McqProvider;

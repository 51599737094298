import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import HttpError from 'common/components/http-error/HttpError';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import Invite from 'business/organizer/assessment/pages/assessment-tests/test-detail/tabs/Invite';

const organizerService = new OrganizerService();

const ReInvite = () => {
  const { invitationId } = useParams<{ invitationId: string }>();
  const { testDetail } = useTestDetail();

  const [inviteResponse, setInviteResponse] = useState<any | null>(
    null
  );
  const [responseError, setResponseError] =
    useState<AxiosError | null>(null);

  useEffect(() => {
    let mounted = true;

    organizerService
      .get(
        (function () {
          const params = testDetail?.invite_url.split('?')[1];
          const InviteUrl = testDetail?.invite_url.split('?')[0];
          return `${InviteUrl}${invitationId}/?${params}`;
        })()
      )
      .then((response) => {
        if (mounted) {
          setInviteResponse(response?.data);
        }
      })
      .catch((error) => setResponseError(error));

    return () => {
      mounted = false;
    };
  }, [invitationId, testDetail?.invite_url]);

  return (
    <>
      {responseError !== null ? (
        <HttpError axiosError={responseError} />
      ) : inviteResponse === null ? (
        <GenericListSkeleton items={3} />
      ) : (
        <>
          <Invite invitationResponse={inviteResponse} />
        </>
      )}
    </>
  );
};

export default ReInvite;

import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { dropdownStyles } from 'business/organizer/common/utils/styles/dropdownStyles';
import useDialog from 'common/components/dialog-provider/useDialog';

import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import useDropdown from 'common/components/use-dropdown/useDropdown';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAlert from 'common/components/alert-provider/useAlert';
import EditMemberAccess from 'business/organizer/settings/components/team-management/EditMemberAccess';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermissionBase from 'business/organizer/common/components/HasPermissionBase';

const organizerService = new OrganizerService();
const Actions: React.FC<{
  onReset: () => void;
  member: IOrganizationMember;
}> = ({ onReset, member }) => {
  const { enqueueAlert } = useAlert();
  const { anchorEl, handleClick, handleClose } = useDropdown();
  const { confirm } = useDialog();
  const [openModal, setOpenModal] = useState(false);
  const { isSuperAdmin } = useHasPermission();

  const {
    can_cancel,
    to_email,
    revoke_access_url,
    user_role,
    edit_url,
  } = member;

  const handleRevokeAccess = () => {
    confirm(
      can_cancel
        ? 'Do you want to cancel invite?'
        : 'Do you want to revoke access?',
      can_cancel
        ? `Once you cancel the invite of ${to_email}, the action will not be reverted.`
        : `Once you revoke the access of ${to_email}, the action will not be reverted.`,
      {
        variant: 'warning',
        showIcon: false,
      }
    )
      .then((confirmed) => {
        if (confirmed) {
          return organizerService.post(revoke_access_url, {});
        }
      })
      .then((response) => {
        if (response) {
          onReset();
          enqueueAlert(`Access of ${to_email} is revoked.`);
        }
      })
      .catch((error) => {
        enqueueAlert(
          `Could not revoke the access: ${error.message}. Please try again`,
          {
            alertProps: { severity: 'error' },
          }
        );
      });
  };

  const onCloseModal = () => {
    handleClose();
    setOpenModal(false);
  };

  return (
    <>
      <HasPermissionBase
        condition={user_role.name === 'admin' && !isSuperAdmin}
        tooltipProps={{
          title: 'Only super admins can edit permissions of admins.',
          arrow: true,
          placement: 'top',
        }}
      >
        <IconButton
          onClick={anchorEl !== null ? handleClose : handleClick}
          disabled={user_role.name === 'admin' && !isSuperAdmin}
        >
          <MoreVertIcon />
        </IconButton>
      </HasPermissionBase>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
      >
        <MenuItem onClick={() => setOpenModal(true)}>Edit</MenuItem>
        <EditMemberAccess
          open={openModal}
          onClose={onCloseModal}
          editUrl={edit_url}
          onReset={onReset}
        />
        <MenuItem
          onClick={handleRevokeAccess}
          disabled={
            member.status === 'cancelled' ||
            member.status === 'revoked'
          }
        >
          {can_cancel ? 'Cancel Invite' : 'Revoke Access'}
        </MenuItem>
      </DropdownMenu>
    </>
  );
};

export default Actions;

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MyInput from 'common/components/form/MyInput';
import { makeStyles } from '@material-ui/core';

const ReminderBox: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <Box width={460} p={4} bgcolor="#F8FAFC" borderRadius={4}>
      <Box pb={4}>
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
      <Typography>{subtitle}</Typography>
      <Box
        width={150}
        pt={2}
        className={classes.box__noOfDaysForReminder}
      >
        <MyInput
          type="number"
          placeholder="number of days"
          name="noOfDaysForReminder"
        />
      </Box>
    </Box>
  );
};

export default ReminderBox;

const useStyles = makeStyles((theme) => ({
  box__noOfDaysForReminder: {
    '& .MuiFormHelperText-contained': {
      margin: 0,
      fontSize: theme.spacing(3),
    },
  },
}));

interface IDatasetProps {
  [key: string]: string;
}

export const getDataset = (
  datasets: IDatasetProps[],
  name: string,
  item: string = 'file'
) => {
  return datasets.filter((dataset) => dataset.type === name)
    .length !== 0
    ? datasets.filter((dataset) => dataset.type === name)[0][item]
    : '';
};

import React, { useState, useRef, useCallback } from 'react';
import Alert from '@material-ui/lab/Alert';
import { v4 as uuid } from 'uuid';

import AlertContext from 'common/components/alert-provider/AlertContext';
import isDefined from 'common/utils/isDefined';

import DEFAULTS from './constants';
import AlertContainer from './AlertContainer';

const AlertProvider: React.FC<IAlertProviderProps> = ({
  children,
  styles,
}): JSX.Element => {
  // Alert queue
  // const [queue, setQueue] = useState<Array<IAlert>>([]);
  // Currently displayed alerts
  const [inView, setInView] = useState<Array<IAlert>>([]);

  // We useRefs to make sure all useAlerts receive the same callback
  const closeAlertRef = useRef<IAlertProviderContext['closeAlert']>();
  closeAlertRef.current = useCallback(
    (key) => {
      if (!isDefined(key)) throw new Error('Invalid alert key');
      const alert = inView.find((item) => item.key === key);
      if (alert !== undefined) {
        setInView(inView.filter((item) => item.key !== key));
      }
    },
    [inView]
  );

  /**
   * Set a close callback for alert at timeout alert.autohideDuration.
   * @param alert
   */
  const setCloseTimeout = (alert: IAlert): number | undefined => {
    if (!alert.autohideDuration) return;
    const closeTimeoutId = window.setTimeout(() => {
      //@ts-ignore
      closeAlertRef.current(alert.key);
    }, alert.autohideDuration);
    alert.closeTimeoutId = closeTimeoutId;
    return closeTimeoutId;
  };

  /**
   * Clears the alert's close timeout if it exists.
   * @param alert
   */
  const cancelCloseTimeout = (alert: IAlert) => {
    if (alert.closeTimeoutId) clearTimeout(alert.closeTimeoutId);
  };

  const enqueueAlertRef = useRef<
    IAlertProviderContext['enqueueAlert']
  >();
  enqueueAlertRef.current = useCallback(
    (message: AlertMessage, options: AlertOptions = {}) => {
      options = { ...DEFAULTS, ...options };
      const { key, preventDuplicate, persist } = options;
      if (preventDuplicate) {
        // Check if alert already exists in queue or is already on screen
        const compare = (item: IAlert) => {
          return isDefined(key)
            ? key === item.key
            : item.message === message;
        };
        const exists = inView.findIndex(compare) > -1;
        if (exists) return false;
      }

      if (persist && options.autohideDuration)
        console.warn(
          'Both persist and autohideDuration supplied to enqueueAlert()'
        );
      if (persist) options.autohideDuration = null;

      // Assign a key to alert if it does not exist
      options.key = isDefined(options.key) ? options.key : uuid();

      const alert: IAlert = {
        message,
        ...options,
        key: options.key as AlertKey,
      };

      // Close after autoHideDuration
      if (alert.autohideDuration) {
        setCloseTimeout(alert);
      }

      setInView((inView) => {
        return [alert, ...inView];
      });

      return alert.key;
    },
    [inView]
  );

  const alertContext: IAlertProviderContext = {
    // @ts-ignore
    enqueueAlert: (...args) => enqueueAlertRef.current(...args),
    // @ts-ignore
    closeAlert: (...args) => closeAlertRef.current(...args),
  };

  return (
    <AlertContext.Provider value={alertContext}>
      <AlertContainer styles={styles}>
        {inView.map((alert: IAlert) => {
          const {
            message,
            key,
            onClose,
            action,
            showClose = true,
          } = alert;
          const alertProps = alert.alertProps || {};

          if (onClose && showClose)
            throw new Error(
              "onClose and showClose can't be used together"
            );

          const defaultOnClose = () => {
            // @ts-ignore
            closeAlertRef.current(key);
          };
          // Wrap onClose to work with MUI's onClose prop
          const handleClose =
            onClose !== undefined
              ? (e: object) => {
                  return onClose(key, e);
                }
              : showClose
              ? defaultOnClose
              : undefined;

          // Get action component for MUI's action prop
          const actionNode =
            action !== undefined ? action(key) : null;

          const { onMouseLeave, onMouseEnter } = alertProps;
          const handleMouseEnter = (
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
          ) => {
            // Cancel close timeout if it exists
            cancelCloseTimeout(alert);
            if (onMouseEnter) {
              return onMouseEnter(e);
            }
          };

          const handleMouseLeave = (
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
          ) => {
            if (alert.autohideDuration) setCloseTimeout(alert);
            if (onMouseLeave) {
              return onMouseLeave(e);
            }
          };

          delete alertProps?.onMouseEnter;
          delete alertProps?.onMouseLeave;

          return (
            <Alert
              key={key}
              onClose={handleClose}
              action={actionNode}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              {...alertProps}
            >
              {message}
            </Alert>
          );
        })}
      </AlertContainer>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;

const CopyIcon: React.FC<{ color?: '#333333' | '#A3A3A3' }> = ({
  color = '#333333',
}) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 3.1275V12.75C1.74985 13.7021 2.11185 14.6186 2.76256 15.3136C3.41327 16.0086 4.30397 16.43 5.254 16.4925L5.5 16.5H12.121C11.9659 16.9386 11.6787 17.3184 11.2988 17.587C10.919 17.8557 10.4652 17.9999 10 18H4.75C3.55653 18 2.41193 17.5259 1.56802 16.682C0.724106 15.8381 0.25 14.6935 0.25 13.5V5.25C0.249756 4.78451 0.393892 4.3304 0.662542 3.95026C0.931193 3.57012 1.31113 3.28266 1.75 3.1275ZM13 0C13.5967 0 14.169 0.237053 14.591 0.65901C15.0129 1.08097 15.25 1.65326 15.25 2.25V12.75C15.25 13.3467 15.0129 13.919 14.591 14.341C14.169 14.7629 13.5967 15 13 15H5.5C4.90326 15 4.33097 14.7629 3.90901 14.341C3.48705 13.919 3.25 13.3467 3.25 12.75V2.25C3.25 1.65326 3.48705 1.08097 3.90901 0.65901C4.33097 0.237053 4.90326 0 5.5 0H13Z"
      fill={color}
    />
  </svg>
);

export default CopyIcon;

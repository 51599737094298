import React from 'react';
import Logo from 'common/components/header/Logo';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';

import FooterSection from 'common/components/footer/FooterSection';
import {
  featuresList,
  solutionsList,
  organizationSectionData,
} from 'common/components/footer/utils/footerData';
import FooterInfo from 'common/components/footer/FooterInfo';

interface IFooterProps {
  RouteLink: React.ComponentType<any>;
}

const Footer: React.FC<IFooterProps> = ({ RouteLink }) => {
  const classes = useStyles();
  return (
    <footer>
      <Box
        position="relative"
        boxShadow="rgb(0 0 0 / 15%) 0px -1.5px 3px"
        borderTop="1px solid rgb(0 0 0 / 0%)"
      >
        <Container maxWidth="xl">
          <Grid container className={classes.root}>
            <Grid item xs={12} xl={3}>
              <Box pl={4} pr={4}>
                <Logo />
              </Box>
              <Box mt={4} pl={4} pr={4} color="#666">
                <Typography color="inherit">
                  The most efficient and reliable technical assessment
                  tool by{' '}
                  <MuiLink
                    href="https://aiplanet.com/"
                    target="_blank"
                    underline="none"
                  >
                    AI Planet (formerly DPhi)
                  </MuiLink>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <FooterSection
                label="Features"
                footerData={featuresList}
                RouteLink={RouteLink}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4} xl={3}>
              <FooterSection
                label="Solutions"
                footerData={solutionsList}
                RouteLink={RouteLink}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <FooterSection
                label="Organization"
                footerData={organizationSectionData}
                RouteLink={RouteLink}
              />
            </Grid>
          </Grid>
          <FooterInfo />
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(12),
    rowGap: `${theme.spacing(4)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(7.5),
    },
    '& .MuiListItem-root': {
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
  },
}));

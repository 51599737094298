import { useState } from 'react';
import { Formik, Form } from 'formik';
import CreateIcon from '@material-ui/icons/Create';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import DurationField from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/DurationField';
import CustomModal from 'common/components/custom-modal/CustomModal';
import SpinnerButton from 'common/components/spinner-button/SpinnerButton';
import useAlert from 'common/components/alert-provider/useAlert';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import { getDuration } from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/utils/helpers';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { getErrorMessage } from 'common/utils/errors';
import { AxiosError } from 'axios';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';

const organizerService = new OrganizerService();

const TestDuration = () => {
  const classes = useStyles();
  const { enqueueAlert } = useAlert();
  const { testDetail, setTestDetail } = useTestDetail();
  const { isViewer } = useHasPermission();

  const initialState: IDuration & {
    start_datetime: string | null;
    end_datetime: string | null;
  } = {
    days: getDuration(testDetail?.duration?.days),
    hours: getDuration(testDetail?.duration?.hours),
    minutes: getDuration(testDetail?.duration?.minutes),
    start_datetime: testDetail?.start_datetime || null,
    end_datetime: testDetail?.end_datetime || null,
  };

  const [open, setOpen] = useState(false);

  const handleSubmit = async (values: IDuration) => {
    try {
      const { days, hours, minutes } = values;
      const data = {
        duration: {
          days: !!days || days === 0 ? days : null,
          hours: !!hours || hours === 0 ? hours : null,
          minutes: !!minutes || minutes === 0 ? minutes : null,
        },
      };

      const response = await organizerService.patchTest(
        testDetail?.url as string,
        data
      );

      setTestDetail((testDetail: any) => ({
        ...testDetail,
        duration: response.data.duration,
        assessment_duration: response.data.assessment_duration,
      }));

      enqueueAlert('Test duration is successfully updated.', {
        alertProps: { severity: 'success' },
      });
      setOpen(false);
    } catch (error) {
      enqueueAlert(
        getErrorMessage((error as AxiosError)?.response?.data),
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <>
      <AccessTimeIcon color="inherit" fontSize="inherit" />
      <Typography variant="h6" color="textSecondary">
        Total Duration:{' '}
        <span
          onClick={() => setOpen(true)}
          className={classes.span__duration_highlighter}
        >
          {testDetail?.assessment_duration}
        </span>
      </Typography>
      <HasPermission
        isViewer={isViewer}
        tooltipProps={{ placement: 'top', arrow: true }}
      >
        <IconButton
          className={classes.icon_button__create}
          onClick={() => setOpen(true)}
          disabled={isViewer}
        >
          <CreateIcon fontSize="small" />
        </IconButton>
      </HasPermission>
      <CustomModal open={open} width="525px">
        <Formik initialValues={initialState} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <Form>
              <Box px={4} py={4}>
                <DurationField title="Test Duration" />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gridColumnGap={12}
                  pt={6}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    size="small"
                  >
                    Close
                  </Button>
                  <SpinnerButton
                    type="submit"
                    disabled={
                      isSubmitting ||
                      (!values.days &&
                        !values.hours &&
                        !values.minutes)
                    }
                    showDisableSpinner={
                      !!values.days &&
                      !!values.hours &&
                      !!values.minutes
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Update
                  </SpinnerButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default TestDuration;

const useStyles = makeStyles((theme) => ({
  icon_button__create: {
    padding: theme.spacing(0),
  },
  span__duration_highlighter: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    cursor: 'pointer',
  },
}));

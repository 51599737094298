import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';

import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';

const TestActionMenu: React.FC<ITestActionMenuProps> = ({
  anchorEl,
  handleClose,
  actionsMenu,
}) => {
  return (
    <DropdownMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      useStyles={dropdownStyles}
    >
      {actionsMenu.map((action: any) => (
        <MenuItem key={action.id} onClick={action.handleClick}>
          {action.label}
        </MenuItem>
      ))}
    </DropdownMenu>
  );
};

export default TestActionMenu;

const dropdownStyles = makeStyles((theme) => ({
  box_root: {
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(68, 146, 76, 0.04)',
    },
    // enable pointer events on the popover menu
    '& .MuiPopover-paper': {
      pointerEvents: 'initial',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 1.5px 3px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}));

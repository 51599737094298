import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import RichTextFieldWithLabel from 'business/organizer/library/components/question-create-update/challenge/datasets/RichTextFieldWithLabel';
import DatasetsUpload from 'business/organizer/library/components/question-create-update/challenge/datasets/DatasetsUpload';
import EvaluationMetric from 'business/organizer/library/components/question-create-update/challenge/datasets/EvaluationMetric';

const Datasets = () => {
  const { values } = useFormikContext<IDatasetsUseFormikContext>();

  return (
    <Box>
      <GenerateBreadcrumb
        data={[
          {
            label: `${values.name.slice(0, 10)}...`,
            route: '',
          },
        ]}
      />
      <RichTextFieldWithLabel />
      <Box
        boxShadow="0px 2px 8px rgba(221, 230, 237, 0.7)"
        borderRadius={5}
        p={4}
        my={4}
      >
        <DatasetsUpload />
      </Box>
      <EvaluationMetric />
    </Box>
  );
};

export default Datasets;

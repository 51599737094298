import { useContext } from 'react';

import CandidateProgressDetailContext from 'business/organizer/assessment/contexts/candidate-progress-detail/CandidateProgressDetailContext';

const useCandidateProgressDetail = (): ICandidateProgressDetailContext => {
  return useContext(
    CandidateProgressDetailContext
  ) as ICandidateProgressDetailContext;
};

export default useCandidateProgressDetail;

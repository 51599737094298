import React, { useState } from 'react';
import { Form, useFormikContext } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { useClipboard } from 'use-clipboard-copy';
import Tooltip from '@material-ui/core/Tooltip';

import InputWithLabel from 'common/components/form/InputWithLabel';
import MyInput from 'common/components/form/MyInput';
import MySelect from 'common/components/form/MySelect';
import RichTextField from 'common/components/form/RichTextField';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import RegistrationFieldList from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/RegistrationFieldList';
import GenericDateTimePickerComponent from 'common/components/mui-pickers/GenericDateTimePickerComponent';
import AssessmentInstructions from 'business/common/components/AssessmentInstructions';
import DurationField from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/DurationField';
import {
  getProctor,
  PROCTOR_NAME,
} from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/utils/helpers';
import ProctorCheckboxItem from 'business/organizer/assessment/components/assessment-tests/test-detail/common/ProctorCheckboxItem';
import SettingsBoxStyles from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxStyles';
import SettingsBoxOuterContainer from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxOuterContainer';
import SettingsBoxInnerContainer from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxInnerContainer';
import LanguagesList from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/LanguagesList';
import SpinnerButton from 'common/components/spinner-button/SpinnerButton';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';
// import PlagiarismRun from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/PlagiarismRun';

type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

const SettingsForm = () => {
  const { testDetail } = useTestDetail();
  const { values, isSubmitting } =
    useFormikContext<IInitialStateTestSettingsFormProps>();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snakbarTransition, setSnakbarTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);
  const clipboard = useClipboard();
  const { isViewer } = useHasPermission();

  const handleCopyLinkClick =
    (Transition: React.ComponentType<TransitionProps>) => () => {
      setSnakbarTransition(() => Transition);
      setShowSnackbar(true);
      clipboard.copy(values.candidate_assessment_url);
    };

  return (
    <Form>
      <SettingsBoxStyles>
        <InputWithLabel
          title="Name"
          downMD="subtitle2"
          upMD="subtitle2"
          isRequired={true}
        >
          <HasPermission
            isViewer={isViewer}
            tooltipProps={{ placement: 'top-start', arrow: true }}
          >
            <MyInput
              placeholder=""
              name="name"
              type="text"
              muiInputProps={{ disabled: isViewer }}
            />
          </HasPermission>
        </InputWithLabel>
        <InputWithLabel
          title="Test type"
          downMD="subtitle2"
          upMD="subtitle2"
          isRequired={true}
        >
          <HasPermission
            isViewer={isViewer}
            tooltipProps={{ placement: 'top-start', arrow: true }}
          >
            <FormControl variant="outlined" disabled={isViewer}>
              <MySelect
                name="invite_only"
                placeholder="Test type"
                type="text"
              >
                <MenuItem key="true" value="true">
                  Invite Only
                </MenuItem>
                <MenuItem key="false" value="false">
                  Public
                </MenuItem>
              </MySelect>
              {!values.invite_only && (
                <Box maxWidth="400px" mt={2}>
                  <Alert severity="warning">
                    Anyone with the test link can participate
                  </Alert>
                </Box>
              )}
            </FormControl>
          </HasPermission>
        </InputWithLabel>
        {testDetail?.published_at && (
          <InputWithLabel
            title="Test link"
            downMD="subtitle2"
            upMD="subtitle2"
            isRequired={false}
          >
            <TextField
              name="candidate_assessment_url"
              type="text"
              fullWidth
              variant="outlined"
              value={values.candidate_assessment_url}
              InputProps={{
                readOnly: true,
                disabled: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Copy" placement="right">
                      <IconButton
                        color="primary"
                        onClick={handleCopyLinkClick(TransitionUp)}
                        edge="end"
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Snackbar
              open={showSnackbar}
              onClose={() => {
                setShowSnackbar(false);
              }}
              autoHideDuration={1000}
              TransitionComponent={snakbarTransition}
              message="Copied!"
              key={snakbarTransition ? snakbarTransition.name : ''}
            />
          </InputWithLabel>
        )}
        <InputWithLabel
          title="Start date"
          downMD="subtitle2"
          upMD="subtitle2"
          isRequired={false}
        >
          <HasPermission
            isViewer={isViewer}
            tooltipProps={{ placement: 'top-start', arrow: true }}
          >
            <GenericDateTimePickerComponent
              PickerComponent={KeyboardDateTimePicker}
              format="DD-MM-YYYY hh:m a"
              date={values.start_datetime}
              name="start_datetime"
              disablePast={
                testDetail?.start_datetime &&
                testDetail?.status !== 'draft'
                  ? false
                  : true
              }
              disabled={
                isViewer ||
                (testDetail?.start_datetime &&
                  testDetail?.status !== 'draft')
                  ? true
                  : false
              }
              variant="dialog"
              placeholder="Add Start Date"
              minDateMessage="Start date should be greater than current time."
            />
          </HasPermission>
        </InputWithLabel>

        <InputWithLabel
          title="End date"
          downMD="subtitle2"
          upMD="subtitle2"
          isRequired={false}
        >
          <HasPermission
            isViewer={isViewer}
            tooltipProps={{ placement: 'top-start', arrow: true }}
          >
            <GenericDateTimePickerComponent
              PickerComponent={KeyboardDateTimePicker}
              format="DD-MM-YYYY hh:m a"
              date={values.end_datetime}
              name="end_datetime"
              disablePast
              variant="dialog"
              placeholder="Add End Date"
              minDate={values.start_datetime || new Date()}
              minDateMessage="End date should be greater than start date."
              disabled={isViewer}
            />
          </HasPermission>
        </InputWithLabel>
        <DurationField />
      </SettingsBoxStyles>
      <SettingsBoxOuterContainer>
        <SettingsBoxInnerContainer maxWidth={600}>
          <Typography variant="subtitle2">Instructions</Typography>
          <AssessmentInstructions
            duration={testDetail?.assessment_duration as string}
          />
        </SettingsBoxInnerContainer>
        <HasPermission
          isViewer={isViewer}
          tooltipProps={{ placement: 'top-start', arrow: true }}
        >
          <SettingsBoxInnerContainer
            maxWidth={600}
            style={{ pointerEvents: isViewer ? 'none' : 'all' }}
          >
            <InputWithLabel
              title="Additional instructions"
              downMD="subtitle2"
              upMD="subtitle2"
              isRequired={false}
            >
              <RichTextField name="instructions" />
            </InputWithLabel>
          </SettingsBoxInnerContainer>
        </HasPermission>
      </SettingsBoxOuterContainer>
      <SettingsBoxStyles>
        <Box pb={1} pt={2}>
          <Typography
            component="span"
            variant="subtitle2"
            color="textPrimary"
          >
            Proctor settings
          </Typography>
        </Box>
        <ProctorCheckboxItem
          name="copy_detection"
          display_name="Disable copy paste in the editor"
          description="This will disable the copy-paste function for the candidate while giving the test."
          disabled={
            !getProctor(
              testDetail?.proctor_settings,
              PROCTOR_NAME.COPY_DETECTION
            )?.has_permission
          }
        />
        <ProctorCheckboxItem
          name="window_switching"
          display_name="Capture tab switching"
          description="This will capture and report tab switching events of the candidate."
          disabled={
            !getProctor(
              testDetail?.proctor_settings,
              PROCTOR_NAME.WINDOW_SWITCHING
            )?.has_permission
          }
        />
        <ProctorCheckboxItem
          name="ai_proctoring"
          display_name="AI Proctoring"
          description="This will enable AI-powered proctoring and report any anomaly activites of candidate impersonation under candidate reports."
          disabled={
            !getProctor(
              testDetail?.proctor_settings,
              PROCTOR_NAME.AI_PROCTORING
            )?.has_permission
          }
        />
      </SettingsBoxStyles>

      {/* Plagiarism Section */}
      {/* TODO: Uncomment this once bugs are fixed */}
      {/* <PlagiarismRun /> */}

      {/* Candidate Registration Details Section */}
      <SettingsBoxStyles>
        <Box pb={1} pt={2}>
          <Typography
            component="span"
            variant="subtitle2"
            color="textPrimary"
          >
            Candidate registration details
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="textSecondary">
            Select the fields you would want candidates to fill during
            registration
          </Typography>
        </Box>
        {/* candidate registration field checkboxes */}
        <RegistrationFieldList />
      </SettingsBoxStyles>

      {/* Programming Language Section */}
      <LanguagesList />
      <br />

      <Box position="fixed" right={20} bottom={20} display="flex">
        <HasPermission
          isViewer={isViewer}
          tooltipProps={{ placement: 'top', arrow: true }}
        >
          <SpinnerButton
            type="submit"
            disabled={isViewer || isSubmitting}
            showDisableSpinner={!isViewer}
          >
            Save
          </SpinnerButton>
        </HasPermission>
      </Box>
    </Form>
  );
};

export default SettingsForm;

import React from 'react';
import { useFormikContext } from 'formik';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import SaveButton from 'business/organizer/library/components/common/SaveButton';

const TestCaseFormActions: React.FC<ITestCaseFormActionsProps> = ({
  onCloseModal,
}) => {
  const { isSubmitting } = useFormikContext();
  return (
    <Box
      py={4}
      width="100%"
      display="flex"
      justifyContent="flex-end"
      gridColumnGap={12}
    >
      <Button
        color="primary"
        variant="outlined"
        onClick={onCloseModal}
      >
        Cancel
      </Button>
      <SaveButton disabled={isSubmitting} buttonText="Save" />
    </Box>
  );
};

export default TestCaseFormActions;

import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import InterviewUpdatePage from 'business/organizer/library/pages/question-create-update/interview/InterviewUpdatePage';
import InterviewPage from 'business/organizer/library/pages/question-create-update/interview/InterviewPage';

const InterviewRoutes = () => {
  const { url } = useCurrentUrl();

  return (
    <Switch>
      <Route path={`${url}/edit/:interviewId`}>
        <InterviewUpdatePage />
      </Route>
      <Route path={`${url}/create`}>
        <InterviewPage drawerName="create" />
      </Route>
      <Redirect from={url} to={`${url}/create`} />
    </Switch>
  );
};

export default InterviewRoutes;

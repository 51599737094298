import React, { useEffect, useState } from 'react';
import DayJsUtils from '@date-io/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormikContext, ErrorMessage } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';

const GenericDateTimePickerComponent: React.FC<IGenericDatePickerProps> = ({
  PickerComponent,
  date,
  name,
  inputVariant = 'outlined',
  variant = 'inline',
  ...props
}) => {
  const {
    setFieldValue,
  } = useFormikContext<IGenericDateTimePickerUseFormikContext>();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );

  useEffect(() => {
    setSelectedDate(date as Dayjs | null);
  }, [date]);

  const handleDateChange = (date: Dayjs | null) => {
    setFieldValue(name, date);
    setSelectedDate(date);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <PickerComponent
          variant={variant}
          id={`${name}-picker`}
          inputVariant={inputVariant}
          value={selectedDate}
          {...props}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      <ErrorMessage name={name}>
        {(msg) => <FormHelperText error={true}>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

export default GenericDateTimePickerComponent;

import { Switch, Route } from 'react-router-dom';

import AuthRoutes from 'business/pre-subscription/auth/components/AuthRoutes';
import AuthPageBase from 'business/pre-subscription/common/components/AuthPageBase';
import ContentSection from 'business/pre-subscription/auth/components/ContentSection';
import {
  FRONTEND_BUSINESS_AUTH_URL,
  FRONTEND_SIGNUP_URL,
} from 'common/utils/constants';
import SignUp from 'business/pre-subscription/auth/pages/SignUp';
import EmailConfirmationPage from 'business/pre-subscription/auth/pages/EmailConfirmationPage';

const Auth = () => {
  return (
    <Switch>
      <Route path={FRONTEND_SIGNUP_URL}>
        <SignUp
          title="Great decision!"
          subtitle="You are one step away from accessing the most reliable hiring assessment tool"
        />
      </Route>
      <Route path="/auth/confirm-email/:key">
        <EmailConfirmationPage />
      </Route>
      <Route path={FRONTEND_BUSINESS_AUTH_URL}>
        <AuthPageBase contentSection={<ContentSection />}>
          <AuthRoutes />
        </AuthPageBase>
      </Route>
    </Switch>
  );
};

export default Auth;

import { useContext } from 'react';
import ChallengeUpdatePageContext from 'business/organizer/library/contexts/challenge-question/contexts/ChallengeUpdatePageContext';

const useChallengeQuestionData = (): IChallengeQuestionUpdatePageContext => {
  return useContext(
    ChallengeUpdatePageContext
  ) as IChallengeQuestionUpdatePageContext;
};

export default useChallengeQuestionData;

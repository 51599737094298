import { FRONTEND_BUSINESS_AUTH_URL } from 'common/utils/constants';

export const HIRE_RATIO_IMAGE =
  '/static/images/business/auth/hireRatio.png';
export const UNBAISED_HIRING_IMAGE =
  '/static/images/business/auth/unbaised.png';
export const SAVE_TIME_IMAGE =
  '/static/images/business/auth/saveTime.png';
export const TECHNICAL_SKILLS_IMAGE =
  '/static/images/business/auth/technicalSkills.png';

export const HERO_IMAGE_RIGHT =
  '/static/images/business/auth/heroimage-right.png';
export const TESTIMONIAL_IMAGE =
  '/static/images/business/auth/testimonial-image.png';
export const LEFT_QUOTE_IMAGE =
  '/static/images/business/auth/quote-left.png';
export const RIGHT_QUOTE_IMAGE =
  '/static/images/business/auth/quote-right.png';

// api urls
export const SIGNUP_API_URL = '/api/signup/?auth_type=business';
export const LOGIN_API_URL = '/api/login/?auth_type=business';
export const API_PASSWORD_RESET_URL =
  '/api/password-reset/?auth_type=business';
export const API_BUSINESS_PASSWORD_RESET_CONFIRM =
  '/api/password-reset-confirm/';
export const API_BUSINESS_EMAIL_CONFIRM =
  '/api/email-confirm/?auth_type=business';
export const API_BUSINESS_RESEND_EMAIL_CONFIRM =
  '/api/email-confirm/resend/?auth_type=business';
export const API_BUSINESS_LOGOUT_URL = '/api/logout/';

// frontend urls
export const FRONTEND_BUSINESS_SIGN_UP_URL = `${FRONTEND_BUSINESS_AUTH_URL}signup`;
export const FRONTEND_BUSINESS_LOGIN_URL = `${FRONTEND_BUSINESS_AUTH_URL}login`;
export const FRONTEND_BUSINESS_FORGOT_PASSWORD = `${FRONTEND_BUSINESS_AUTH_URL}forgot-password`;
export const FRONTEND_BUSINESS_RESET_PASSWORD = `${FRONTEND_BUSINESS_AUTH_URL}reset-password`;
export const FRONTEND_BUSINESS_RESEND_CONFIRMATION = `${FRONTEND_BUSINESS_AUTH_URL}resend-confirmation-link`;
export const FRONTEND_BUSINESS_CONFIRM_EMAIL = `${FRONTEND_BUSINESS_AUTH_URL}confirm-email`;

export const CAPGEMINI = '/static/images/business/auth/Capgemini.svg';
export const CISCO = '/static/images/business/auth/Cisco.svg';
export const PEKING = '/static/images/business/auth/Peking.svg';
export const TRELL = '/static/images/business/auth/Trell.svg';

export const SWITCH_APP_API_URL = '/api/switch-app/';

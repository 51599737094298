import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import PercentageCoveredWithLabel from 'common/components/utility-components/PercentageCoveredWithLabel';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import AbnormalEvents from 'business/organizer/assessment/components/candidate-progress/candidate-detail/proctoring/AbnormalEvents';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import useAsync from 'common/custom-hooks/useAsync';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { getDayjsDate } from 'common/utils/dayjsUtils';
import ImageHelper from 'common/components/image-helper/ImageHelper';
import HandleError from 'business/organizer/assessment/components/candidate-progress/candidate-detail/proctoring/HandleError';

const organizerService = new OrganizerService();
const Proctoring = () => {
  const classes = useStyles();
  const { candidateDetails } = useCandidateProgressDetail();
  const {
    run,
    data,
    status,
  }: IUseAsyncReturnProps<ICandidateFaceIndexProps> = useAsync();

  useEffect(() => {
    run(
      organizerService.get(candidateDetails?.proctoring_url as string)
    );
  }, [candidateDetails?.proctoring_url, run]);

  return (
    <GenericResponseHandler
      status={status}
      errorPlaceholder={<HandleError />}
    >
      <Box py={6}>
        <Box py={6}>
          <Typography variant="h6" align="center">
            Original Photo Captured at{' '}
            {getDayjsDate({
              date: data?.created_at,
              format: "DD MMM[']YY HH[:]mm:ss",
            })}
          </Typography>
          <Box pt={6} display="flex" justifyContent="center">
            <ImageHelper
              imageUrl={data?.image}
              altProp={candidateDetails?.email as string}
              className={classes.imagehelper__face_index}
              lazyLoad
            />
          </Box>
        </Box>
        <Box pt={12}>
          <Typography variant="h6" align="center">
            Overall Confidence Score That The User Above Took The Test
          </Typography>
          <Box display="flex" justifyContent="center" py={4}>
            <PercentageCoveredWithLabel
              size={100}
              percentage={data?.overall_confidence_score.toFixed(2)}
            />
          </Box>
        </Box>
        <AbnormalEvents />
      </Box>
    </GenericResponseHandler>
  );
};

export default Proctoring;

const useStyles = makeStyles((theme) => ({
  imagehelper__face_index: {
    maxWidth: theme.spacing(134),
    maxHeight: theme.spacing(90),
    borderRadius: theme.spacing(1),
  },
}));

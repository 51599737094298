import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme } from '@material-ui/core';

interface ICustomModalProps {
  open: boolean;
  children: any;
  width?: string;
  rootBoxProps?: BoxProps;
}

const CustomModal: React.FC<ICustomModalProps> = ({
  open,
  children,
  width,
  rootBoxProps,
}) => {
  const classes = useStyles({ width });

  return (
    <div>
      <Modal open={open}>
        <Box className={classes.modalStyle}>
          <Box
            borderRadius={8}
            className={classes.root}
            {...rootBoxProps}
          >
            {children}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomModal;

const useStyles = makeStyles<Theme, { width?: string }>((theme) => ({
  modalStyle: {
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    overflowY: 'scroll',
  },
  root: {
    background: '#fff',
    width: ({ width }) => width || '60%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}));

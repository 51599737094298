export const getErrorMessage = (
  error: IGetErrorMessageProps | null,
  message?: string
) => {
  if (error !== null) {
    let errorMessage;
    if (typeof error?.detail === 'string') {
      errorMessage = error?.detail;
    } else if (error instanceof Object) {
      errorMessage = Object.values(error)[0];
    } else {
      errorMessage = !!message
        ? message
        : 'Error, Please try again, If error persists contact at support@skillspace.app';
    }
    return errorMessage;
  }
};

export const hasMessageProperty = (
  e: any
): e is { message: string } => {
  return typeof e?.message === 'string' ? e.message : '';
};

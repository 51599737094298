const Logo = ({
  textColor = '#0A66C2',
}: {
  textColor?: '#0A66C2' | '#ffffff';
}) => {
  return (
    <svg
      width="126"
      height="36"
      viewBox="0 0 126 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.1544 34.2568H90.0379V33.5337H90.1135C90.277 33.8302 90.6099 34.3477 91.4569 34.3477C92.5826 34.3477 93.3995 33.4459 93.3995 31.9423C93.3995 30.4355 92.5703 29.5489 91.448 29.5489C90.5855 29.5489 90.2738 30.0754 90.1135 30.363H90.0591V28.0604H89.1544V34.2568ZM90.041 31.9331C90.041 30.962 90.4645 30.3174 91.2542 30.3174C92.0742 30.3174 92.4857 31.0104 92.4857 31.9331C92.4857 32.865 92.0622 33.5761 91.2542 33.5761C90.4766 33.5761 90.041 32.9103 90.041 31.9331ZM94.8209 35.9996C95.5681 35.9996 96.0401 35.6094 96.3093 34.877L98.2307 29.6186L97.2532 29.6094L96.0765 33.216H96.028L94.851 29.6094H93.8827L95.5833 34.3173L95.4712 34.6259C95.2412 35.2432 94.9175 35.2945 94.4214 35.1586L94.2035 35.8998C94.3125 35.9483 94.5485 35.9996 94.8209 35.9996ZM104.312 34.2568C106.83 34.2568 108.286 32.6956 108.286 30.1138C108.286 27.544 106.83 25.9948 104.397 25.9948H101.637V34.2568H104.312ZM102.884 33.1675V27.0841H104.32C106.127 27.0841 107.059 28.1652 107.059 30.1138C107.059 32.0702 106.127 33.1675 104.244 33.1675H102.884ZM109.836 34.2568H111.082V31.3522H112.777C114.693 31.3522 115.641 30.1944 115.641 28.6695C115.641 27.1486 114.701 25.9948 112.781 25.9948H109.836V34.2568ZM111.082 30.2953V27.0639H112.648C113.882 27.0639 114.386 27.7336 114.386 28.6695C114.386 29.6054 113.882 30.2953 112.664 30.2953H111.082ZM118.212 30.5777C118.212 29.5894 118.837 29.0245 119.697 29.0245C120.527 29.0245 121.024 29.553 121.024 30.4608V34.2568H122.23V30.3154C122.23 28.7705 121.383 27.9797 120.108 27.9797C119.144 27.9797 118.563 28.3992 118.272 29.0689H118.196V25.9948H117.006V34.2568H118.212V30.5777ZM123.84 34.2568H125.046V28.0604H123.84V34.2568ZM124.449 27.1042C124.864 27.1042 125.211 26.7815 125.211 26.3862C125.211 25.9908 124.864 25.6641 124.449 25.6641C124.029 25.6641 123.686 25.9908 123.686 26.3862C123.686 26.7815 124.029 27.1042 124.449 27.1042Z"
        fill={textColor === '#ffffff' ? '#ffffff' : '#333333'}
      />
      <path
        d="M9.5904 7.80388C8.50058 6.6414 6.78109 6.25391 5.23115 6.25391C2.76087 6.25391 0.29062 7.46481 0.29062 10.2257C0.29062 14.7061 6.41783 13.1077 6.41783 14.9967C6.41783 15.6264 5.59441 15.8443 4.771 15.8443C3.68118 15.8443 2.88196 15.3842 2.20386 14.5608L0 17.0068C1.16247 18.1935 2.97885 18.6052 4.62568 18.6052C7.14438 18.6052 10.0506 17.7333 10.0506 14.7303C10.0506 10.2499 3.92335 11.7272 3.92335 9.98352C3.92335 9.28119 4.64991 9.01479 5.25535 9.01479C6.12722 9.01479 6.82955 9.35385 7.36233 10.0077L9.5904 7.80388Z"
        fill={textColor}
      />
      <path
        d="M11.1861 18.309H14.8188V12.3513H14.8673L18.8391 18.309H23.4163L18.6211 11.8427L23.2952 6.53893H18.8391L14.8188 11.1888V0H11.1861V18.309Z"
        fill={textColor}
      />
      <path
        d="M23.9651 18.3168H27.5979V6.54675H23.9651V18.3168ZM23.6745 2.84137C23.6745 4.00382 24.619 4.94835 25.7815 4.94835C26.944 4.94835 27.8885 4.00382 27.8885 2.84137C27.8885 1.67889 26.944 0.734375 25.7815 0.734375C24.619 0.734375 23.6745 1.67889 23.6745 2.84137Z"
        fill={textColor}
      />
      <path
        d="M29.5297 18.309H33.1623V0H29.5297V18.309Z"
        fill={textColor}
      />
      <path
        d="M35.0943 18.309H38.7269V0H35.0943V18.309Z"
        fill={textColor}
      />
      <path
        d="M49.4985 7.80388C48.4087 6.6414 46.6891 6.25391 45.1393 6.25391C42.6691 6.25391 40.1986 7.46481 40.1986 10.2257C40.1986 14.7061 46.3258 13.1077 46.3258 14.9967C46.3258 15.6264 45.5026 15.8443 44.6791 15.8443C43.5892 15.8443 42.7901 15.3842 42.112 14.5608L39.9081 17.0068C41.0706 18.1935 42.887 18.6052 44.5337 18.6052C47.0524 18.6052 49.9588 17.7333 49.9588 14.7303C49.9588 10.2499 43.8315 11.7272 43.8315 9.98352C43.8315 9.28119 44.5581 9.01479 45.1634 9.01479C46.0354 9.01479 46.7376 9.35385 47.2703 10.0077L49.4985 7.80388Z"
        fill={textColor}
      />
      <path
        d="M51.4334 23.8363H55.066V17.0552H55.1145C55.938 18.1935 57.3424 18.6052 58.8441 18.6052C62.1862 18.6052 64.2206 15.6021 64.2206 12.3327C64.2206 8.77261 61.9199 6.25391 58.4323 6.25391C56.7856 6.25391 55.3809 7.24687 54.8238 8.09448H54.7753V6.54454H51.4334V23.8363ZM54.7753 12.4296C54.7753 10.6616 55.8652 9.45071 57.6816 9.45071C59.4981 9.45071 60.5877 10.6616 60.5877 12.4296C60.5877 14.1975 59.4981 15.4084 57.6816 15.4084C55.8652 15.4084 54.7753 14.1975 54.7753 12.4296Z"
        fill={textColor}
      />
      <path
        d="M73.0089 18.3146H76.3508V12.3327C76.3508 8.40935 75.2127 6.25391 70.9745 6.25391C69.0611 6.25391 67.1237 6.88358 65.7918 8.14294L67.7293 10.1288C68.5043 9.25696 69.4973 8.72418 70.6838 8.72418C71.9431 8.72418 73.0089 9.54759 73.0089 10.8312C69.9816 10.8312 65.0408 10.9038 65.0408 14.9725C65.0408 17.3459 67.0268 18.6052 69.2306 18.6052C70.7081 18.6052 72.161 18.0966 72.9604 16.8373H73.0089V18.3146ZM73.0089 13.1561V13.9311C73.0089 15.3842 71.6767 16.1349 70.2479 16.1349C69.4488 16.1349 68.5284 15.7232 68.5284 14.8514C68.5284 13.253 70.9986 13.1561 72.1126 13.1561H73.0089Z"
        fill={textColor}
      />
      <path
        d="M88.2687 7.82811C87.1304 6.66563 85.4593 6.25391 84.079 6.25391C80.4463 6.25391 77.54 8.67572 77.54 12.4296C77.54 16.1834 80.4463 18.6052 84.079 18.6052C85.4593 18.6052 87.1304 18.1935 88.2687 17.031L85.871 14.5123C85.4593 14.9967 84.8781 15.4084 84.079 15.4084C82.2625 15.4084 81.1726 14.1975 81.1726 12.4296C81.1726 10.6616 82.2625 9.45071 84.079 9.45071C84.8296 9.45071 85.5077 9.8382 85.871 10.3468L88.2687 7.82811Z"
        fill={textColor}
      />
      <path
        d="M97.4577 14.4155C96.8522 15.2389 96.053 15.8443 94.8662 15.8443C93.4615 15.8443 92.4204 14.9482 92.1296 13.5678H100.558V12.4296C100.558 8.67572 98.4263 6.25391 95.0357 6.25391C91.4031 6.25391 88.497 8.67572 88.497 12.4296C88.497 16.1834 91.4031 18.6052 95.0357 18.6052C96.925 18.6052 98.8381 17.7576 100.001 16.2803L97.4577 14.4155ZM92.1296 11.0976C92.2266 9.95929 93.0985 8.86947 94.6968 8.86947C96.0046 8.86947 96.9491 9.88666 96.925 11.0976H92.1296Z"
        fill={textColor}
      />
      <path
        d="M102.113 16.2281C102.113 17.4632 103.106 18.4562 104.341 18.4562C105.576 18.4562 106.569 17.4632 106.569 16.2281C106.569 14.993 105.576 14 104.341 14C103.106 14 102.113 14.993 102.113 16.2281Z"
        fill={textColor}
      />
      <path
        d="M115.866 18.3146H119.209V12.3327C119.209 8.40935 118.07 6.25391 113.832 6.25391C111.919 6.25391 109.982 6.88358 108.649 8.14294L110.587 10.1288C111.362 9.25696 112.355 8.72418 113.541 8.72418C114.801 8.72418 115.866 9.54759 115.866 10.8312C112.839 10.8312 107.899 10.9038 107.899 14.9725C107.899 17.3459 109.885 18.6052 112.088 18.6052C113.566 18.6052 115.019 18.0966 115.818 16.8373H115.866V18.3146ZM115.866 13.1561V13.9311C115.866 15.3842 114.534 16.1349 113.105 16.1349C112.306 16.1349 111.386 15.7232 111.386 14.8514C111.386 13.253 113.856 13.1561 114.97 13.1561H115.866Z"
        fill={textColor}
      />
      <path
        d="M120.81 18.3168H124.443V6.54675H120.81V18.3168ZM120.519 2.84137C120.519 4.00382 121.464 4.94835 122.626 4.94835C123.789 4.94835 124.733 4.00382 124.733 2.84137C124.733 1.67889 123.789 0.734375 122.626 0.734375C121.464 0.734375 120.519 1.67889 120.519 2.84137Z"
        fill={textColor}
      />
      <path
        d="M122.608 4.95694C123.804 4.95694 124.774 3.9872 124.774 2.79097C124.774 1.59474 123.804 0.625 122.608 0.625C121.412 0.625 120.442 1.59474 120.442 2.79097C120.442 3.9872 121.412 4.95694 122.608 4.95694Z"
        fill="#FFC233"
      />
    </svg>
  );
};

export default Logo;

import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';

import RenderFreeTextStep from 'business/organizer/library/components/question-create-update/free-text/RenderFreeTextStep';
import FreeTextFormActions from 'business/organizer/library/components/question-create-update/free-text/form-actions/FreeTextFormActions';
import useFreeText from 'business/organizer/library/contexts/free-text-question/custom-hooks/useFreeText';
import { validationSchema } from 'business/organizer/library/components/question-create-update/free-text/utils/validationSchema';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import useIsUpdate from 'common/custom-hooks/useIsUpdate';
import QuestionBase from 'business/organizer/library/components/question-create-update/common/QuestionBase';

const FreeTextPage: React.FC<IFreeTextQuestionProps> = ({
  freeText,
  drawerName,
}) => {
  const { testId } = useTestId();

  const {
    handleProblemStatementSubmit,
    problemStatementResponse,
    setProblemStatementResponse,
  } = useFreeText();

  const question = freeText?.question;
  const problemStatementResponseQuestion =
    problemStatementResponse?.question;

  // clear the effects
  useEffect(() => {
    return () => {
      setProblemStatementResponse(null);
    };
  }, [setProblemStatementResponse]);

  const [isUpdate] = useIsUpdate(!!question);

  const initialState: IInitialStateFreeTextFormProps = {
    name: question?.name
      ? problemStatementResponseQuestion?.name || question?.name
      : '',
    description: question?.description
      ? problemStatementResponseQuestion?.description ||
        question?.description
      : '',
    time_duration: question?.time_duration
      ? problemStatementResponseQuestion?.time_duration ||
        question?.time_duration
      : '',
    score: question?.score
      ? problemStatementResponseQuestion?.score || question?.score
      : '',
    difficulty: question?.difficulty
      ? problemStatementResponseQuestion?.difficulty ||
        question?.difficulty
      : '',
    tags: freeText?.tags || [],
    min_word_count: question?.min_word_count
      ? problemStatementResponseQuestion?.min_word_count ||
        question?.min_word_count
      : '',
    max_word_count: question?.max_word_count
      ? problemStatementResponseQuestion?.max_word_count ||
        question?.max_word_count
      : '',
    testId: !!testId ? testId : 0,
  };

  return (
    <QuestionBase
      name="Free Text (Subjective Question)"
      drawerProps={{ drawerName }}
    >
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema[0]}
        onSubmit={handleProblemStatementSubmit}
      >
        <Form>
          <RenderFreeTextStep />
          <Box display="flex" justifyContent="flex-end" py={8}>
            <FreeTextFormActions isUpdate={isUpdate} />
          </Box>
        </Form>
      </Formik>
    </QuestionBase>
  );
};

export default FreeTextPage;

import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core';

const TablePaginationBoxStyles: React.FC<TablePaginationProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Paper>
      <Box className={classes.box__table_pagination}>
        <TablePagination {...props} />
      </Box>
    </Paper>
  );
};

export default TablePaginationBoxStyles;

const useStyles = makeStyles((theme) => ({
  box__table_pagination: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiTablePagination-selectRoot': {
      margin: 10,
    },
    '& .MuiTablePagination-caption': {
      fontSize: theme.spacing(3.5),
    },
    '& .MuiTablePagination-select': {
      textAlignLast: 'left',
      marginTop: theme.spacing(1),
    },
    '& .MuiSelect-icon': {
      left: theme.spacing(7.5),
    },
  },
}));

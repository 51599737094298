import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import ImageHelper from 'common/components/image-helper/ImageHelper';

const Card: React.FC<IHomeCardProps> = ({
  title,
  image,
  description,
  route,
  RouteLink,
}) => {
  const classes = useStyles();
  return (
    <RouteLink to={route}>
      <Box
        m="0 auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius={5}
        maxWidth={315}
        height={260}
        className={classes.box__card}
      >
        <Box height={95} p={4}>
          <ImageHelper
            imageUrl={image}
            altProp="tests"
            lazyLoad
            className={classes.imagehelper__card}
          />
        </Box>
        <Box px={4}>
          <Typography variant="h4" align="center" className={classes.typography__title}>
            {title}
          </Typography>
        </Box>
        <Box pt={4} px={4} textAlign="center">
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </RouteLink>
  );
};

export default Card;

const useStyles = makeStyles((theme) => ({
  imagehelper__card: {
    height: '100%',
  },
  box__card: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 6px 0px #DDE6ED',
    '&:hover': {
      boxShadow: '0px 6px 18px 0px #DDE6ED',
    },
  },
  typography__title:{
    fontWeight: 'bold',
  },
}));

import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';

import FieldLabel from 'business/organizer/library/components/question-create-update/common/FieldLabel';
import MyInput from 'common/components/form/MyInput';
import { validationSchema } from 'business/organizer/interviews/utils/helpers';
import InterviewFormActions from 'business/organizer/interviews/components/common/InterviewFormActions';

const InstantInterviewForm: React.FC<Interviews.IInterviewFormProps> =
  ({ handleSubmit, onClose }) => {
    const initialState: Interviews.ICreateInterviewFormProps = {
      email: '',
      title: '',
    };

    return (
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box
              p={8}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gridRowGap={12}
            >
              <FieldLabel title="Candidate Email" isRequired={true}>
                <MyInput
                  name="email"
                  placeholder="Email"
                  aria-label="email"
                />
              </FieldLabel>
              <FieldLabel title="Interview Title" isRequired={true}>
                <MyInput
                  name="title"
                  placeholder="Interview Title"
                  aria-label="Interview Title"
                />
              </FieldLabel>
            </Box>
            <InterviewFormActions
              onClose={onClose}
              spinnerButtonName="Generate Link"
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  };

export default InstantInterviewForm;

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FooterInfoItem from './FooterInfoItem';

const FooterInfo = () => (
  <Box
    width="100%"
    display="flex"
    justifyContent="center"
    gridColumnGap={10}
    pt={8}
    pb={2}
  >
    <Typography variant="body2" color="textSecondary">
      &copy; {new Date().getFullYear()} Skillspace
    </Typography>
    <>
      <Divider flexItem orientation="vertical" />
      <FooterInfoItem
        label="Terms"
        route="https://skillspace.ai/terms-of-service/"
      />
    </>
    <Divider flexItem orientation="vertical" />
    <FooterInfoItem
      label="Privacy"
      route="https://skillspace.ai/privacy-policy/"
    />
  </Box>
);

export default FooterInfo;

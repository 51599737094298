import React from 'react';
import { Route } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import useQuestionRouteData from 'business/organizer/assessment/custom-hooks/useQuestionRouteData';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import QuestionCreateUpdatePage from 'business/organizer/library/pages/question-create-update/QuestionCreateUpdatePage';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useQuestionDrawer from 'business/organizer/library/contexts/question-drawer/useQuestionDrawer';
import { dropdownStyles } from 'business/organizer/common/utils/styles/dropdownStyles';

const QuestionsMenu: React.FC<IMenuProps> = ({
  anchorEl,
  handleClose,
  ...others
}) => {
  const classes = useStyles();
  const { url } = useCurrentUrl();
  const { questionDrawer, setQuestionDrawer } = useQuestionDrawer();

  const { questionData } = useQuestionRouteData(
    setQuestionDrawer,
    'create'
  );

  return (
    <>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
        {...others}
      >
        {questionData.map(
          ({ id, label, divider, route, disable }) => (
            <>
              {disable ? (
                <Tooltip
                  title="Please upgrade your plan to use these feature"
                  placement="left"
                  arrow
                  key={id}
                >
                  <Box className={classes.box_root}>
                    <ListItem divider={divider} disabled={disable}>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'h6' }}
                      >
                        {label}
                      </ListItemText>
                    </ListItem>
                  </Box>
                </Tooltip>
              ) : (
                <Box
                  key={id}
                  onClick={route}
                  className={classes.box_root}
                >
                  <ListItem divider={divider}>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'h6' }}
                    >
                      {label}
                    </ListItemText>
                  </ListItem>
                </Box>
              )}
            </>
          )
        )}
      </DropdownMenu>
      <CustomDrawer
        drawer={questionDrawer}
        setDrawer={setQuestionDrawer}
        drawerName="create"
      >
        <Route path={`${url}/my/questions`}>
          <QuestionCreateUpdatePage />
        </Route>
      </CustomDrawer>
    </>
  );
};

export default QuestionsMenu;

const useStyles = makeStyles((theme) => ({
  box_root: {
    cursor: 'pointer',
    '& .MuiListItem-root:hover': {
      width: '100%',
      backgroundColor: 'rgba(182, 217, 251, 0.15)',
    },
  },
}));

// @ts-nocheck
import React from 'react';
import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button/Button';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import { API_CREATE_CUSTOM_PORTAL_URL } from 'business/organizer/common/utils/constants';

const organizerService = new OrganizerService();

enum CbDefaults {
  DEVELOPMENT_ENV = 'development',
  DEV_CB_INSTANCE = 'dphi-test',
  PROD_CB_INSTANCE = 'dphi',
}

const BillingCbDetailsPortal = () => {
  const { organizer } = useOrganizer();

  const handleBilling = () => {
    const cbInstance = window.Chargebee.init({
      site:
        process.env.NODE_ENV === CbDefaults.DEVELOPMENT_ENV
          ? CbDefaults.DEV_CB_INSTANCE
          : CbDefaults.PROD_CB_INSTANCE,
    });
    const callbacks = {};

    const cbPortal = cbInstance.createChargebeePortal();

    cbInstance.setPortalSession(async () => {
      const response = await organizerService.post(
        API_CREATE_CUSTOM_PORTAL_URL,
        {
          customerId:
            organizer?.current_subscription.chargebee_subscription_id,
        }
      );
      return response?.data;
    });
    cbPortal.openSection(
      {
        sectionType: Chargebee.getPortalSections().BILLING_HISTORY,
        params: {
          subscriptionId:
            organizer?.current_subscription.chargebee_subscription_id,
        },
      },
      callbacks
    );
  };
  return (
    <MuiLink
      // eslint-disable-next-line no-script-url
      href="javascript:void(0)"
      id="cb-portal"
      onClick={handleBilling}
      underline="none"
    >
      <Button variant="contained" color="primary">
        Billing history & Subscription details
      </Button>
    </MuiLink>
  );
};

export default BillingCbDetailsPortal;

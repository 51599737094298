import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import McqUpdatePage from 'business/organizer/library/pages/question-create-update/mcq/McqUpdatePage';
import McqPage from 'business/organizer/library/pages/question-create-update/mcq/McqPage';

const McqRoutes = () => {
  const { url } = useCurrentUrl();

  return (
    <Switch>
      <Route path={`${url}/edit/:mcqId`}>
        <McqUpdatePage />
      </Route>
      <Route path={`${url}/create`}>
        <McqPage drawerName="create" />
      </Route>
      <Redirect from={url} to={`${url}/create`} />
    </Switch>
  );
};

export default McqRoutes;

import React from 'react';
import { Field, useFormikContext } from 'formik';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import InputWithLabel from 'common/components/form/InputWithLabel';
import {
  getDifferenceInMins,
  getTotalMinutes,
} from 'common/utils/dayjsUtils';
import ErrorHelperText from 'common/components/form/ErrorHelperText';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';

const DurationField: React.FC<{ title?: string }> = ({
  title = 'Duration',
}) => {
  const classes = useStyles();
  const { values, errors, touched } =
    useFormikContext<IInitialStateTestSettingsFormProps>();
  const { start_datetime, end_datetime, days, hours, minutes } =
    values;

  const error =
    (touched.days || touched.hours || touched.minutes) &&
    (errors.days || errors.hours || errors.minutes);

  const { isViewer } = useHasPermission();

  const validateDuration = (value: number) => {
    let error: string = '';
    if (start_datetime && end_datetime) {
      if (days || hours || minutes) {
        if (
          getTotalMinutes({ days, hours, minutes }) >
          getDifferenceInMins(start_datetime, end_datetime)
        ) {
          error =
            'Test duration is greater than difference between end and start date.';
        }
      }
    }
    return error;
  };

  return (
    <InputWithLabel
      title={title}
      downMD="subtitle2"
      upMD="subtitle2"
      isRequired={false}
    >
      <Box>
        <HasPermission
          isViewer={isViewer}
          tooltipProps={{ placement: 'top-start', arrow: true }}
        >
          <Box className={classes.box__root}>
            <Field
              name="days"
              as={TextField}
              label="Day(s)"
              variant="outlined"
              margin="dense"
              validate={validateDuration}
              error={error}
              disabled={isViewer}
            />
            <Field
              name="hours"
              as={TextField}
              label="Hr(s)"
              variant="outlined"
              margin="dense"
              validate={validateDuration}
              error={error}
              disabled={isViewer}
            />
            <Field
              name="minutes"
              as={TextField}
              label="Min(s)"
              variant="outlined"
              margin="dense"
              validate={validateDuration}
              error={error}
              disabled={isViewer}
            />
          </Box>
        </HasPermission>
        {error && (
          <ErrorHelperText>
            {errors.days || errors.hours || errors.minutes}
          </ErrorHelperText>
        )}
      </Box>
    </InputWithLabel>
  );
};

export default DurationField;

const useStyles = makeStyles((theme) => ({
  box__root: {
    width: theme.spacing(100),
    display: 'flex',
    columnGap: theme.spacing(2),
    '& .MuiFormLabel-root': {
      color: '#a9a9a9',
      top: '-3px',
    },
  },
}));

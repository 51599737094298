import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const IncompleteLabel: React.FC<IIncompleteLabelProps> = ({
  label,
  hint,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.typography_incomplete_question}>
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
      <Tooltip title={hint} placement="top">
        <HelpOutlineIcon
          className={classes.icon_incomplete_question}
          fontSize="inherit"
        ></HelpOutlineIcon>
      </Tooltip>
    </Box>
  );
};

export default IncompleteLabel;

const useStyles = makeStyles((theme) => ({
  typography_incomplete_question: {
    display: 'inline-flex',
    padding: theme.spacing(0, 1.5),
    borderRadius: theme.spacing(1),
    background: '#FBEED0',
    alignItems: 'center',
  },
  icon_incomplete_question: {
    color: '#666666',
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import Typography from '@material-ui/core/Typography';

import useQuestionSubmission from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useQuestionSubmission';
import QuestionSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionSolution';

const FileUploadSolution: React.FC<IFileUploadQuestionSolutionProps> = ({
  submissionUrl,
}) => {
  const { submission, responseError } = useQuestionSubmission(
    submissionUrl
  );

  return (
    <QuestionSolution
      submission={submission}
      responseError={responseError}
    >
      <Box display="flex" alignItems="center" gridColumnGap={4}>
        <Typography variant="h6">Candidate Solution -</Typography>
        <Button
          variant="text"
          color="primary"
          component={(props) => <MuiLink {...props} />}
          href={submission?.submission?.file}
          target="_blank"
          endIcon={<SystemUpdateAltIcon />}
        >
          Download
        </Button>
      </Box>
    </QuestionSolution>
  );
};

export default FileUploadSolution;

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BulkUploadField from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/BulkUploadField';
import InviteToField from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/InviteToField';

const InviteField = ({
  invitationResponse,
}: {
  invitationResponse: any;
}) => {
  return (
    <Box py={2}>
      <Grid container justify="space-between">
        <Grid item xs={!invitationResponse ? 7 : 12}>
          <InviteToField />
        </Grid>
        {!invitationResponse && (
          <>
            <Grid item xs={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                top={12}
              >
                <Typography align="center">--OR--</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" height="100%" alignItems="center" justifyContent="flex-end">
                <BulkUploadField name="bulk_invite_csv" />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default InviteField;

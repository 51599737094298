import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';

import CustomModal from 'common/components/custom-modal/CustomModal';
import TestCaseModal from './TestCaseModal';

const TestCaseForm: React.FC<ITestCaseFormProps> = ({
  testCase,
  showModal,
  onCloseModal,
}) => {
  const classes = useStyles();

  return (
    <CustomModal open={showModal}>
      <Container maxWidth="xl">
        <Box py={3}>
          <Typography variant="h4">Add Test Case</Typography>
        </Box>
      </Container>
      <Divider />
      <Container
        maxWidth="xl"
        className={classes.container_testcaseform}
      >
        <TestCaseModal
          testCase={testCase}
          onCloseModal={onCloseModal}
        />
      </Container>
    </CustomModal>
  );
};

export default TestCaseForm;

const useStyles = makeStyles((theme) => ({
  container_testcaseform: {
    '& .MuiIconButton-colorSecondary': {
      color: '#233D4D',
    },
  },
}));

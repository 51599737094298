import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core';

import { inputWithLabelRootStyles } from 'common/components/form/utils';
import MyFileField from 'common/components/form/MyFileField';
import InputWithLabel from 'common/components/form/InputWithLabel';
import useOnDropFile from 'common/components/form/custom-hooks/useOnDropFile';

const TestCaseFileField: React.FC<ITestCaseFileFieldProps> = ({
  name,
  title,
  tooltipTitle,
  fileLink,
}) => {
  const classes = useStyles();
  const { onDrop } = useOnDropFile({ name });
  const {
    values,
  } = useFormikContext<IUseFormikContextTestCaseFileField>();

  const dropzoneOptions = {
    accept: '.txt, .in, .out',
    maxFiles: 1,
    onDrop,
    multiple: false,
    maxSize: 20971520, //20mb
  };

  return (
    <Box
      height={135}
      border="1px dashed #666666"
      borderRadius={5}
      py={8}
      px={8}
      pb={8}
    >
      <InputWithLabel
        title={title}
        downMD="subtitle2"
        upMD="subtitle2"
        isRequired={false}
        tooltip
        tooltipTitle={tooltipTitle}
        useStyles={inputWithLabelStyles}
        icon={<InfoOutlinedIcon fontSize="small" />}
      >
        <Box
          position="relative"
          right={10}
          bottom={12}
          textAlign="center"
        >
          <MyFileField
            dropzoneOptions={dropzoneOptions}
            name={name}
            buttonText="Choose file"
            icon={false}
          />
        </Box>
      </InputWithLabel>
      {!values[name] && !!fileLink && (
        <Box width="100%" textAlign="center">
          <Typography variant="body2" align="center">
            <MuiLink
              href={fileLink}
              target="_blank"
              className={classes.muilink_file}
            >
              Download file
            </MuiLink>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TestCaseFileField;

const useStyles = makeStyles((theme) => ({
  muilink_file: {
    marginRight: theme.spacing(4.5),
  },
}));

const inputWithLabelStyles = makeStyles((theme) => ({
  root: {
    ...inputWithLabelRootStyles,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import React from 'react';
import { Route } from 'react-router-dom';

import CodingUpdatePage from 'business/organizer/library/pages/question-create-update/coding/CodingUpdatePage';
import TestCasesProvider from 'business/organizer/library/contexts/coding-question/provider/TestCasesProvider';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useQuestionPreviewData from 'business/organizer/library/pages/question-preview/useQuestionPreviewData';
import useCodingQuestion from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestion';
import Preview from 'business/organizer/library/components/question-preview/common/Preview';

const CodingPreview: React.FC<IQuestionPreviewProps> = ({
  questionUrl,
}) => {
  const { url } = useCurrentUrl();

  const { problemStatementResponse } = useCodingQuestion();
  const hasResponse = !!problemStatementResponse;
  const { questionItem, responseError } = useQuestionPreviewData(
    questionUrl,
    hasResponse
  );

  return (
    <Preview
      questionItem={questionItem}
      responseError={responseError}
      updatePageRoute={
        <TestCasesProvider>
          <Route path={`${url}/edit/:codingId`}>
            <CodingUpdatePage />
          </Route>
        </TestCasesProvider>
      }
    />
  );
};

export default CodingPreview;

import { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { getDayjsDate } from 'common/utils/dayjsUtils';

const InterviewDetails: React.FC<{
  interviewDetails: Interview.IInterviewDetailProps;
}> = ({ interviewDetails }) => {
  const interviewData = useMemo(
    () => [
      {
        id: 1,
        label: 'Title',
        value: interviewDetails.title,
      },
      {
        id: 2,
        label: 'Status',
        value: interviewDetails.status,
      },
      {
        id: 3,
        label: 'Interviewer',
        value:
          interviewDetails.interviewers[0].organization_member.name,
      },
      {
        id: 4,
        label: 'Interview start time',
        value: getDayjsDate({
          date: interviewDetails.interview_session.start_time,
          format: 'DD[/]MM[/]YY hh[:]mma',
        }),
      },
      {
        id: 5,
        label: 'Duration',
        value: interviewDetails.interview_session.duration,
      },
      {
        id: 6,
        label: 'Verdict',
        value: interviewDetails.verdict,
      },
    ],
    [
      interviewDetails.interview_session.duration,
      interviewDetails.interview_session.start_time,
      interviewDetails.interviewers,
      interviewDetails.status,
      interviewDetails.title,
      interviewDetails.verdict,
    ]
  );

  return (
    <Container maxWidth={false}>
      <Box
        bgcolor="#FFF"
        p={4}
        boxShadow="0px 3px 6px #DDE6ED"
        borderRadius={5}
      >
        <Box pb={3}>
          <Typography variant="h6">Interview Details</Typography>
        </Box>
        <Grid container style={{ gridRowGap: '16px' }}>
          {interviewData.map((data) => (
            <Grid key={data.id} item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                gridRowGap={4}
              >
                <Typography variant="body2" color="textSecondary">
                  {data.label}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {data.value || '-'}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default InterviewDetails;

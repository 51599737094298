import React from 'react';
import { Switch, Route } from 'react-router-dom';

import OrganizerPage from 'business/organizer/OrganizerPage';
import HeaderProvider from 'business/organizer/common/context/header/HeaderProvider';
import BreadcrumbProvider from 'business/organizer/common/context/breadcrumb/BreadcrumbProvider';
import OrganizerProvider from 'business/organizer/common/context/organizer/OrganizerProvider';
import PrivateRoute from 'common/components/private-route/PrivateRoute';
import {
  FRONTEND_BUSINESS_URL,
  FRONTEND_BUSINESS_AUTH_URL,
  FRONTEND_REQUEST_DEMO,
} from 'common/utils/constants';
import { FRONTEND_BUSINESS_LOGIN_URL } from 'business/pre-subscription/auth/utils/constants';
import UserProvider from 'common/components/get-user/UserProvider';
import RequestDemo from 'business/pre-subscription/request-demo/pages/RequestDemo';
import Auth from 'business/pre-subscription/auth/Auth';
import BaseProvider from 'common/components/utility-components/BaseProvider';
import EmailVerificationProvider from 'business/pre-subscription/auth/context/email-verification/EmailVerificationProvider';

const App: React.FC = (): JSX.Element => {
  return (
    <BaseProvider>
      <Switch>
        <Route path={FRONTEND_REQUEST_DEMO}>
          <RequestDemo />
        </Route>
        <EmailVerificationProvider>
          <Switch>
            <Route path={FRONTEND_BUSINESS_AUTH_URL}>
              <Auth />
            </Route>
            <PrivateRoute
              path={FRONTEND_BUSINESS_URL}
              redirectUrl={FRONTEND_BUSINESS_LOGIN_URL}
            >
              <HeaderProvider>
                <BreadcrumbProvider>
                  <UserProvider>
                    <OrganizerProvider>
                      <OrganizerPage />
                    </OrganizerProvider>
                  </UserProvider>
                </BreadcrumbProvider>
              </HeaderProvider>
            </PrivateRoute>
          </Switch>
        </EmailVerificationProvider>
      </Switch>
    </BaseProvider>
  );
};

export default App;

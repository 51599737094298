import { useContext } from 'react';
import CandidateProgressContext from 'business/organizer/assessment/contexts/candidate-progress/CandidateProgressContext';

const useCandidateProgress = (): ICadidateProgressContext => {
  return useContext(
    CandidateProgressContext
  ) as ICadidateProgressContext;
};

export default useCandidateProgress;

/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, useMediaQuery } from '@material-ui/core';

import { countryCodeData } from 'common/utils/countryCode';
import theme from 'business/common/theme/theme';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const CustomPopper = function (props: any) {
  const isDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Popper
      {...props}
      style={{ width: isDownSM ? 200 : 300 }}
      placement="bottom-start"
    />
  );
};

const SelectCountryCode: React.FC<FieldAttributes<{}>> = ({
  placeholder,
  ...inputProps
}) => {
  const classes = useStyles();
  const [field, meta] = useField<{}>(inputProps);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const { values, setFieldValue } = useFormikContext<{
    country_code: string;
  }>();

  const getCountry = () => {
    return countryCodeData.filter(
      (data) => data.phone === values.country_code
    )[0];
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    // Internally, the autocomplete filter will check for the inputted text
    // in this string.
    stringify: (option: CountryType) =>
      `${option.phone} ${option.label}`,
    trim: true,
  });

  return (
    <Autocomplete
      className={classes.root}
      id="country-select-demo"
      onChange={(_, value) => {
        if (value) {
          setFieldValue('country_code', value.phone);
        }
      }}
      options={countryCodeData as CountryType[]}
      defaultValue={getCountry() || ''}
      autoHighlight
      getOptionLabel={(option) => option.phone}
      filterOptions={filterOptions}
      renderOption={(option) => (
        <Box display="flex" gridColumnGap={5} alignItems="flex-start">
          <Typography>{countryToFlag(option.code)}</Typography>
          <Typography variant="body2">
            {option.label} ({option.code}) +{option.phone}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...field}
          variant="outlined"
          helperText={errorText}
          error={!!errorText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      PopperComponent={CustomPopper}
    />
  );
};

export default SelectCountryCode;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 100,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(2),
    },
  },
}));

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

import { Grid, Theme } from '@material-ui/core';
import { enterpriseLogos } from '../utils/utils';
import ImageHelper from 'common/components/image-helper/ImageHelper';

const Companies: React.FC<BoxProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Box color="#F5F5F5" pb={4}>
        <Typography
          variant="h4"
          color="inherit"
          align="center"
          className={classes.companies__heading}
        >
          Trusted by ambitious teams at
        </Typography>
      </Box>
      <Grid
        container
        justifyContent="center"
        component="section"
        className={classes.grid__section}
      >
        {enterpriseLogos.map((logo) => (
          <Grid item xs={12} sm={6} key={logo.id}>
            <Box
              p={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
              component="section"
            >
              <ImageHelper
                imageUrl={logo.image}
                altProp={logo.altProp}
                lazyLoad
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Companies;

const useStyles = makeStyles((theme: Theme) => ({
  companies__heading: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(6),
    },
  },
  grid__section: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

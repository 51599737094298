import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { FILTER_BY_TAGS } from 'business/organizer/library/contexts/library/libraryActionTypes';

const FilterByTagsBase: React.FC<IFilterByTagsBaseProps> = ({
  tagName,
  tagSuggestions,
  filterOptions,
  label,
  inputPlaceholder,
}) => {
  const { state, dispatch } = useLibrary();

  const tagsLoading = tagSuggestions === null;

  return (
    <Box mt={4}>
      <Autocomplete
        value={state.globalParams[tagName]}
        onChange={(_, value) => {
          if (value) {
            dispatch({
              type: FILTER_BY_TAGS,
              payload: { value, tagName },
            });
          }
        }}
        multiple
        autoComplete
        filterSelectedOptions
        loading={tagsLoading}
        id={`search-questions-by-${tagName}`}
        defaultValue={state.globalParams[tagName]}
        options={tagSuggestions || []}
        getOptionLabel={(option) => {
          if (option) {
            return option.name;
          }
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        filterOptions={filterOptions}
        renderOption={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`e.g ${inputPlaceholder}`}
            label={`Search ${label}`}
            type="search"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default FilterByTagsBase;

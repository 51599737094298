import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';

import FormError from 'business/organizer/library/components/common/FormError';
import SaveButton from 'business/organizer/library/components/common/SaveButton';

const FileUploadFormActions: React.FC<IFileUploadFormActionsProps> = ({
  isUpdate,
}) => {
  const { isSubmitting, values } = useFormikContext<{
    testId: number;
  }>();

  return (
    <Box display="flex" alignItems="center" gridColumnGap={12}>
      <FormError />
      <Box position="relative">
        <Box display="flex">
          <SaveButton
            buttonText={
              !!values.testId
                ? `${isUpdate ? 'Save' : 'Save & Add To Test'}`
                : 'Save'
            }
            disabled={isSubmitting}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FileUploadFormActions;

import React from 'react';
import Box from '@material-ui/core/Box';

import useQuestionSubmission from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useQuestionSubmission';
import QuestionSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionSolution';
import CodingSolutionBase from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CodingSolutionBase';

const CodingSolution: React.FC<ICodingQuestionSolutionProps> = ({
  submissionUrl,
}) => {
  const { submission, responseError } =
    useQuestionSubmission(submissionUrl);

  return (
    <QuestionSolution
      submission={submission}
      responseError={responseError}
    >
      <Box py={2}>
        {!!submission && (
          <CodingSolutionBase submission={submission?.submission} />
        )}
      </Box>
    </QuestionSolution>
  );
};

export default CodingSolution;

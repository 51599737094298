import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useAlert from 'common/components/alert-provider/useAlert';
import TestsContext from 'business/organizer/assessment/contexts/assessment-tests/TestsContext';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { TEST_API_URL } from 'business/organizer/common/utils/constants';
import { hasMessageProperty } from 'common/utils/errors';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const organizerService = new OrganizerService();

const TestsProvider = ({ children }: { children: JSX.Element }) => {
  const { enqueueAlert } = useAlert();
  const history = useHistory();
  const { isHubsUrl } = useCurrentUrl();

  const [roles, setRoles] = useState<any | null>(null);
  const [selectedRole, setSelectedRole] = useState<number | string>();
  const [customRole, setCustomRole] = useState(false);
  const [testsCount, setTestsCount] = useState({
    active: 0,
    draft: 0,
    archive: 0,
  });
  const [archiveResponse, setArchiveResponse] = useState<any | null>(
    null
  );
  const [publishResponse, setPublishResponse] = useState<any | null>(
    null
  );

  // new created test
  const [test, setTest] = useState<any | null>(null);

  const handleSelectedRole = (role: number | string) => {
    setCustomRole(false);
    setSelectedRole(role);
  };

  const onCustomRole = (role: string) => {
    setSelectedRole(role);
  };

  const handleCustomRole = () => {
    setSelectedRole('');
    setCustomRole(true);
  };

  const handleTestCreation = async (
    type: string,
    url?: string,
    autoAddQuestion?: boolean,
    addQuestionToTest?: (testId: number) => Promise<void> | undefined
  ) => {
    try {
      const testData: { [key: string]: any } = {
        type,
      };

      if (type === 'hiring') {
        testData.name = `${
          typeof selectedRole === 'number'
            ? roles.filter((role: any) => role.id === selectedRole)[0]
                .name
            : selectedRole
        }`;
        testData.name = isHubsUrl
          ? `${testData.name}`
          : `${testData.name} hiring Test`;
        testData.role = selectedRole;
        testData.auto_add_question = autoAddQuestion;
      }

      if (type !== 'hiring') {
        testData.name = `${type} Test`;
      }

      const redirect = () =>
        history.push(
          `${
            isHubsUrl ? '/hubs' : ''
          }/tests/${response?.data?.status.toLowerCase()}/${
            response?.data?.id
          }`
        );

      const response = await organizerService.createTest(
        url || TEST_API_URL,
        testData
      );
      setTest(response?.data);

      if (typeof addQuestionToTest !== 'undefined') {
        addQuestionToTest(response?.data?.id)?.then((res) =>
          redirect()
        );
      } else {
        redirect();
      }
    } catch (error) {
      enqueueAlert(
        `Something went wrong: ${
          hasMessageProperty(error) ? error.message : ''
        }. Please try again.`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <TestsContext.Provider
      value={{
        selectedRole,
        handleSelectedRole,
        customRole,
        handleCustomRole,
        onCustomRole,
        handleTestCreation,
        test,
        testsCount,
        setTestsCount,
        archiveResponse,
        setArchiveResponse,
        publishResponse,
        setPublishResponse,
        setRoles,
        roles,
      }}
    >
      {children}
    </TestsContext.Provider>
  );
};

export default TestsProvider;

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const TestInfoItem: React.FC<ITestInfoItemProps> = ({
  Icon,
  children,
  tooltipTitle,
}) => (
  <Box
    display="flex"
    gridColumnGap={4}
    alignItems="center"
    color="#666"
  >
    <Tooltip title={tooltipTitle} placement="bottom" arrow>
      <Icon fontSize="inherit" color="inherit" />
    </Tooltip>
    <Typography variant="body1" color="textSecondary">
      {children}
    </Typography>
  </Box>
);

export default TestInfoItem;

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const QuestionItemTags: React.FC<{ tags: IQuestionTags[] }> = ({
  tags,
}) => {
  return (
    <>
      {tags.map((tag) => (
        <Box
          key={tag.id}
          py={1}
          px={2}
          border="0.5px solid #eee"
          borderRadius={4}
          bgcolor="#fafafa"
        >
          <Typography color="textSecondary" variant="body2">
            {tag.name}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default QuestionItemTags;

import { useState, useLayoutEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import {
  cbMicroConfigMonthly,
  cbMicroConfigYearly,
  cbStartUpConfigMonthly,
  cbStartUpConfigYearly,
  cbInterviewsConfigMonthly,
  cbInterviewsConfigYearly,
  cbEduInstitutesStartUpConfigMonthly,
  cbEduInstitutesStartUpConfigYearly,
  cbOrganizationStartUpConfigMonthly,
  cbOrganizationStartUpConfigYearly,
  cbNewGrowthConfigMonthly,
  cbNewGrowthConfigYearly,
} from 'business/organizer/assessment/components/billing/utils/billingUtils';
import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import BuyButton from 'business/organizer/assessment/components/billing/common/BuyButton';
import BillingCards from 'business/organizer/assessment/components/billing/common/BillingCards';
import BillingSupport from 'business/organizer/assessment/components/billing/common/BillingSupport';
import {
  CHARGEBEE_ID,
  CHARGEBEE_WEBSITE,
} from 'business/organizer/assessment/components/billing/utils/utils';
import HubsBillingCards from 'hubs/billing/HubsBillingCards';
import useUserContext from 'common/components/get-user/useUserContext';

const BillingPlans = () => {
  const classes = useStyles();
  const { organizer, setIsFreeTrial, setIsLoaded } = useOrganizer();
  const { user } = useUserContext();

  const [monthly, setMonthly] = useState(true);
  const chargebeeEl = useRef<HTMLScriptElement | null>(null);

  useLayoutEffect(() => {
    const handleCbInstance = async () => {
      try {
        chargebeeEl.current = document.createElement('script');
        chargebeeEl.current.onload = () => {
          // @ts-ignore
          window.Chargebee.init({
            site: CHARGEBEE_ID,
          });
          // @ts-ignore
          window.Chargebee.registerAgain();
        };
        chargebeeEl.current.setAttribute('src', CHARGEBEE_WEBSITE);

        document.body.appendChild(chargebeeEl.current);
        // @ts-ignore
        const cbInstance = await Chargebee.getInstance();

        if (cbInstance) {
          const cart = cbInstance.getCart();
          cart.setCustomer({ email: organizer?.owner.email });

          cbInstance.setCheckoutCallbacks(function (cart: any) {
            return {
              loaded: function () {
                // console.log('checkout opened');
              },
              close: function () {
                // console.log('checkout closed');
              },
              success: function (hostedPageId: any) {
                setIsFreeTrial(false);
                setIsLoaded(false);
              },
              // step: function (value: any) {
              //   // value -> which step in checkout
              // },
            };
          });
        }
      } catch (error) {
        // console.log(error);
        console.log('error');
      }
    };

    handleCbInstance();

    return () => {
      document.body.removeChild(
        chargebeeEl.current as HTMLScriptElement
      );
    };
  }, [
    organizer?.owner.email,
    setIsFreeTrial,
    monthly,
    setIsLoaded,
    user?.org_type,
  ]);

  return (
    <Box>
      <Box py={12}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gridColumnGap={24}
        >
          <Box
            className={
              monthly
                ? classes.box__switch_active
                : classes.box__switch
            }
            onClick={() => setMonthly(true)}
          >
            <Typography
              variant="h6"
              color="inherit"
              className={classes.typography__monthly}
            >
              Pay Monthly
            </Typography>
          </Box>
          <Box
            className={
              monthly
                ? classes.box__switch
                : classes.box__switch_active
            }
            onClick={() => setMonthly(false)}
          >
            <Typography
              variant="h6"
              color="inherit"
              className={classes.typography__monthly}
            >
              Pay Yearly (save upto 50%)
            </Typography>
          </Box>
        </Box>
      </Box>
      {user?.org_type === 'skillspace_organization' &&
      !!organizer?.current_subscription ? (
        <BillingCards
          interviewCta={
            <BuyButton
              totalDisCountedPrice={monthly ? 15 : 162}
              cbConfigMonthly={cbInterviewsConfigMonthly}
              cbConfigYearly={cbInterviewsConfigYearly}
              monthly={monthly}
            />
          }
          starterCta={
            <BuyButton
              totalPrice={monthly ? 0 : 0}
              totalDisCountedPrice={monthly ? 90 : 720}
              cbConfigMonthly={cbMicroConfigMonthly}
              cbConfigYearly={cbMicroConfigYearly}
              monthly={monthly}
            />
          }
          growthCta={
            <BuyButton
              totalPrice={monthly ? 199 : 1199}
              totalDisCountedPrice={monthly ? 199 : 1199}
              cbConfigMonthly={cbNewGrowthConfigMonthly}
              cbConfigYearly={cbNewGrowthConfigYearly}
              monthly={monthly}
            />
          }
          startupCta={
            [
              cbStartUpConfigMonthly.planId,
              cbStartUpConfigYearly.planId,
            ].includes(
              organizer?.current_subscription.plan
                .chargebee_plan_id as string
            ) ? (
              <BuyButton
                totalDisCountedPrice={monthly ? 149 : 1188}
                cbConfigMonthly={cbStartUpConfigMonthly}
                cbConfigYearly={cbStartUpConfigYearly}
                monthly={monthly}
              />
            ) : undefined
          }
          customCta={<BillingSupport />}
          monthly={monthly}
        />
      ) : (
        <HubsBillingCards
          hubsInstituteStaterPlanCta={
            <BuyButton
              totalDisCountedPrice={monthly ? 100 : 960}
              cbConfigMonthly={cbEduInstitutesStartUpConfigMonthly}
              cbConfigYearly={cbEduInstitutesStartUpConfigYearly}
              monthly={monthly}
            />
          }
          hubsOrganizationStaterPlanCta={
            <BuyButton
              totalDisCountedPrice={monthly ? 100 : 960}
              cbConfigMonthly={cbOrganizationStartUpConfigMonthly}
              cbConfigYearly={cbOrganizationStartUpConfigYearly}
              monthly={monthly}
            />
          }
          customCta={<BillingSupport />}
          monthly={monthly}
        />
      )}
    </Box>
  );
};

export default BillingPlans;

const useStyles = makeStyles((theme) => ({
  typography__monthly: {
    fontWeight: 600,
  },
  box__switch_active: {
    backgroundColor: '#064079',
    boxShadow:
      '0px 6.69914px 24px rgba(10, 102, 194, 0.12), inset 0px 2.51218px 1.67479px #FFFFFF, inset 0px -2.51218px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: 22,
    color: '#fff',
    padding: theme.spacing(2, 10),
    cursor: 'pointer',
  },
  box__switch: {
    cursor: 'pointer',
  },
}));

import React, { useCallback } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import MyCheckbox from 'common/components/form/MyCheckbox';
import ChoicesItem from 'business/organizer/library/components/question-create-update/mcq/ChoicesItem';
import theme from 'business/common/theme/theme';
import ErrorHelperText from 'common/components/form/ErrorHelperText';
import {
  hasInvalidAnswers,
  hasInvalidChoices,
} from 'business/organizer/library/components/question-create-update/mcq/utils/helpers';

const ChoicesBase: React.FC<IChoicesBaseProps> = ({
  onSetDeletedChoices,
}) => {
  const classes = useStyles();
  const { values, touched, errors } =
    useFormikContext<IMcqInitialStateProps>();
  const { choices, has_multiple_correct_answers } = values;

  const handleMultipleAnswers = useCallback(() => {
    let error = '';
    if (choices.length < 2) {
      error = 'Please add at least 2 choices.';
    } else if (
      hasInvalidChoices(choices) &&
      !errors.choices &&
      touched.choices
    ) {
      error = 'Please select at least one correct answer.';
    } else if (
      hasInvalidAnswers(choices, has_multiple_correct_answers)
    ) {
      error = 'Please select only one correct answer.';
    } else {
      return '';
    }
    return !!error && <ErrorHelperText>{error}</ErrorHelperText>;
  }, [
    choices,
    has_multiple_correct_answers,
    touched.choices,
    errors.choices,
  ]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        py={2}
        pt={10}
        className={classes.box__choices}
      >
        <Box mt={2}>
          <Typography variant="h6">Answer Choices</Typography>
          <Tooltip
            title="Please select the radio button corresponding to the correct choice."
            placement="right"
            arrow
          >
            <Typography variant="subtitle2">
              How to mark correct choices ?
            </Typography>
          </Tooltip>
        </Box>
        <Box>
          <MyCheckbox
            name="has_multiple_correct_answers"
            children={
              <Box>
                <Typography variant="body1">
                  Allow candidate to select multiple choices
                </Typography>
              </Box>
            }
          />
        </Box>
      </Box>
      <FieldArray
        name="choices"
        render={(arrayHelpers) => {
          return (
            <>
              {choices && !!choices.length
                ? choices.map((choice, index) => (
                    <ChoicesItem
                      key={index}
                      id={choice.id}
                      arrayHelpers={arrayHelpers}
                      index={index}
                      content={choice.content}
                      is_correct_choice={choice.is_correct_choice}
                      onSetDeletedChoices={onSetDeletedChoices}
                    />
                  ))
                : null}
              {handleMultipleAnswers()}
              <Box
                display="flex"
                justifyContent="center"
                py={2}
                color={theme.palette.info.main}
              >
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      content: '',
                      is_correct_choice: false,
                    })
                  }
                  startIcon={<AddOutlinedIcon />}
                  color="inherit"
                >
                  Add more choices
                </Button>
              </Box>
            </>
          );
        }}
      />
    </>
  );
};

export default ChoicesBase;

const useStyles = makeStyles((theme) => ({
  box__choices: {
    '& .MuiIconButton-colorSecondary': {
      color: '#233D4D',
    },
  },
}));

import Box, { BoxProps } from '@material-ui/core/Box';

const FormBoxStyles: React.FC<BoxProps> = (props) => (
  <Box
    boxShadow="0px 0px 32px rgba(10, 102, 194, 0.1), 0px 0px 16px rgba(10, 102, 194, 0.15)"
    borderRadius={16}
    bgcolor="#fff"
    component="section"
    {...props}
  />
);

export default FormBoxStyles;

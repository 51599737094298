export const FAQ_DATA = [
  {
    id: 1,
    question: 'What are invites?',
    answer:
      'For every invitation you send to a candidate one invite will be consumed.',
  },
  {
    id: 2,
    question:
      "Will my invites be restored if the candidate doesn't appear for the test?",
    answer:
      "In the case of absence or if the candidate doesn't show up to your test, the invites will be restored and they can be re-used in the next test within the validity period. You can even cancel the invites and re-use them.",
  },
  {
    id: 3,
    question: 'What is the duration of trial period?',
    answer:
      'The trial period is for 14 days and you can use up to 10 invites.',
  },
  {
    id: 4,
    question: 'How does the support work?',
    answer:
      'Please write to us at support@skillspace.app and we shall reach out to you at the soonest possible.',
  },
];

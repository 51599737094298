import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import TestListItem from 'business/organizer/assessment/components/assessment-tests/test-list/TestListItem';
import HttpError from 'common/components/http-error/HttpError';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import QuestionListSkeleton from 'business/organizer/library/components/question-list/skeletons/QuestionList';
import useTestList from 'business/organizer/assessment/components/common/custom-hooks/useTestList';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { HubsCandidateService } from 'hubs-candidate/services/hubsCandidateService';

const TestList: React.FC<ITestListProps> = ({
  list,
  isHubsCandidate = false,
}) => {
  const { url } = useCurrentUrl();
  const [params, setParams] = useState({ page_size: 10, page: 1 });

  const Service = isHubsCandidate
    ? HubsCandidateService
    : OrganizerService;
  const { error, testsList, count, setTestsList } = useTestList(
    list,
    Service,
    params
  );

  const getEmptyPlaceholderMessage = () => {
    if (list === 'active') {
      return 'There are no active tests, please create one';
    } else if (list === 'archive') {
      return 'There are no archived tests at the moment';
    } else {
      return 'There are no draft tests at the moment';
    }
  };

  const handleLoadPage = (e: unknown, page: number) => {
    setTestsList(null);
    setParams((params) => ({ ...params, page }));
  };

  return (
    <>
      {error !== null ? (
        <HttpError
          axiosError={error}
          message="Not able to load the test. Please refresh the page."
        />
      ) : (
        <>
          <GenerateBreadcrumb
            data={[
              {
                label: `${list.charAt(0).toUpperCase()}${list.slice(
                  1
                )}`,
                route: url,
              },
            ]}
          />
          {testsList === null ? (
            <QuestionListSkeleton />
          ) : testsList !== null && testsList.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtitle1">
                {getEmptyPlaceholderMessage()}
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={4}>
              {testsList.map((testItem: any) => (
                <Grid item xs={12} key={testItem.id}>
                  <TestListItem
                    testItem={testItem}
                    isHubsCandidate={!!isHubsCandidate}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {count > 10 && (
            <Box
              pb={6}
              pt={12}
              display="flex"
              justifyContent="center"
              m="auto"
            >
              <Pagination
                count={Math.ceil(count / 10)}
                page={params.page}
                color="primary"
                onChange={handleLoadPage}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default TestList;

import React from 'react';
import { AxiosError } from 'axios';

import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import useCloseQuestionDrawer from 'business/organizer/library/custom-hooks/useCloseQuestionDrawer';
import HttpError from 'common/components/http-error/HttpError';

const QuestionErrorHandler = ({ error }: { error: AxiosError }) => {
  const closeQuestionDrawer = useCloseQuestionDrawer({
    drawerName: 'edit',
  });

  return (
    <>
      <CloseDrawerIcon closeQuestionDrawer={closeQuestionDrawer} />
      <HttpError axiosError={error} />
    </>
  );
};

export default QuestionErrorHandler;

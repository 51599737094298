import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import theme from 'business/common/theme/theme';

const appRenderer = (children: JSX.Element) =>
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <React.StrictMode>{children}</React.StrictMode>
    </ThemeProvider>,
    document.getElementById('root')
  );

export default appRenderer;

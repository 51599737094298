import React from 'react';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core';

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  anchorEl,
  handleClose,
  children,
  useStyles = dropdownStyles,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoFocus={false}
      disableScrollLock={true}
      TransitionComponent={Fade}
      className={classes.box_root}
      {...other}
    >
      {children}
    </Menu>
  );
};

export default DropdownMenu;

// these are the default styles for dropdown menu
const dropdownStyles = makeStyles((theme) => ({
  box_root: {
    // disable pointer events on the overlay
    pointerEvents: 'none',
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(68, 146, 76, 0.04)',
    },
    // enable pointer events on the popover menu
    '& .MuiPopover-paper': {
      pointerEvents: 'initial',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 1.5px 3px',
      marginTop: theme.spacing(3),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}));

import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import useAsync from 'common/custom-hooks/useAsync';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import ChoicesBase from 'business/organizer/library/components/question-create-update/mcq/ChoicesBase';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import useMcq from 'business/organizer/library/contexts/mcq/useMcq';

const libraryService = new LibraryService();

const Choices: React.FC<IChoicesProps> = ({
  onSetDeletedChoices,
  choicesUrl,
}) => {
  const { error, status, run, data: choicesResponse } = useAsync();
  const { setFieldValue } = useFormikContext();
  const { mcqResponse } = useMcq();

  useEffect(() => {
    run(
      libraryService.get(
        choicesUrl || (mcqResponse?.question.choices_url as string)
      )
    );
  }, [choicesUrl, mcqResponse, run]);

  useEffect(() => {
    if (choicesResponse) {
      setFieldValue(
        'choices',
        choicesResponse.map(
          ({ id, content, is_correct_choice }: IChoiceItemProps) => ({
            id,
            content,
            is_correct_choice,
          })
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choicesResponse]);

  return (
    <GenericResponseHandler
      status={status}
      error={error}
      skeleton={<GenericListSkeleton items={3} />}
    >
      <ChoicesBase onSetDeletedChoices={onSetDeletedChoices} />
    </GenericResponseHandler>
  );
};

export default Choices;

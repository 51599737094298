export const difficultyItems = [
  { label: 'Easy', value: 'easy' },
  { label: 'Medium', value: 'medium' },
  { label: 'Hard', value: 'hard' },
];

// IMP: Add the value in validation schema also
export const fileTypesAllowed = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Txt', value: 'txt' },
  { label: 'Zip', value: 'zip' },
  { label: 'ipynb', value: 'ipynb' },
];

import {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { AxiosError } from 'axios';
import {
  DropEvent,
  FileRejection,
  FileWithPath,
} from 'react-dropzone';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

import FileField from 'common/components/form/file-field/FileField';
import FileFieldResults from 'common/components/form/file-field/FileFieldResults';
import ShowStatus from 'common/components/utility-components/ShowStatus';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import useCodingQuestion from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestion';
import useTestCases from 'business/organizer/library/contexts/coding-question/custom-hooks/useTestCases';
import usePolling from 'common/custom-hooks/usePolling';
import theme from 'business/common/theme/theme';
import { disableFileField } from 'common/components/form/file-field/utils';

const libraryService = new LibraryService();

const TestcasesUploadButton = () => {
  const [status, setStatus] = useState<IStatus>('idle');
  const [error, setError] = useState<AxiosError | null>(null);
  const [file, setFile] =
    useState<ITestCasesBulkUploadResponse | null>(null);

  const {
    response: pollingResponse,
    error: pollingError,
    runPolling,
    isOngoing,
    isStopped,
    resetPolling,
    handlePollingStatusFn,
  } = usePolling<ITestCasesBulkUploadResponse>();
  const [isPreviousPollDone, setIsPreviousPollDone] = useState(true);

  const { problemStatementResponse } = useCodingQuestion();
  const { setIsLoading, setTestCases } = useTestCases();

  const bulk_upload_poll_url =
    problemStatementResponse?.question?.bulk_upload_poll_url;

  const [restartPolling, setRestartPolling] = useState(
    !!bulk_upload_poll_url
  );

  const showStatus = (status: string) => {
    switch (status) {
      case 'waiting':
        setStatus('pending');
        break;
      case 'failed':
        setStatus('rejected');
        break;
      case 'completed':
        setStatus('resolved');
        break;
      default:
        throw new Error(
          `You passed wrong type.${status} this status doesn't exist.`
        );
    }
  };

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      setStatus('pending');
      try {
        const response = await libraryService.addFiles(
          `${problemStatementResponse?.url}testcases/bulk-upload/`,
          { bulk_testcases_zip: acceptedFiles[0] }
        );
        setFile(response?.data);
      } catch (error) {
        setStatus('rejected');
        setError(error as AxiosError);
      }
    },
    [problemStatementResponse?.url]
  );

  // for checking previous poll is done or not
  const handlePreviousPollDone = (previousPollDone: boolean) => {
    setIsPreviousPollDone(previousPollDone);
  };

  useLayoutEffect(() => {
    if (restartPolling && pollingResponse?.status !== 'completed') {
      setStatus('pending');
      handlePollingStatusFn('ongoing');
    }
    if (pollingResponse) setFile(pollingResponse);
    if (pollingError) setError(pollingError);
    if (pollingResponse?.status === 'completed') {
      resetPolling(() => {
        setFile(null);
        setRestartPolling(false);
        setStatus('resolved');
        setTestCases(null);
        setIsLoading(true);
      });
    }
    if (pollingResponse?.status === 'failed') {
      resetPolling(() => {
        setFile(null);
        setRestartPolling(false);
        setStatus('rejected');
      });
    }
    if (
      ((status !== 'resolved' && file !== null) || restartPolling) &&
      isPreviousPollDone
    ) {
      const pollIntervalId = window.setInterval(() => {
        runPolling({
          promise: libraryService.get(
            `${problemStatementResponse?.url}testcases/bulk-upload/`
          ),
          pollIntervalId,
          onPreviousPollDone: handlePreviousPollDone,
        });
      }, 2000);

      return () => window.clearInterval(pollIntervalId);
    }
  }, [
    file,
    pollingError,
    pollingResponse,
    problemStatementResponse?.url,
    runPolling,
    setIsLoading,
    restartPolling,
    setTestCases,
    status,
    handlePollingStatusFn,
    resetPolling,
    isPreviousPollDone,
  ]);

  useEffect(() => {
    if (file !== null) {
      showStatus(file.status);
      if (
        pollingResponse !== null &&
        isStopped &&
        file.status !== 'completed'
      ) {
        setStatus('idle');
        setFile(null);
        setRestartPolling(false);
      }
    }
  }, [file, pollingResponse, isStopped]);

  const dropzoneOptions = {
    accept: '.zip',
    maxFiles: 1,
    onDrop,
    multiple: false,
    maxSize: 262144000, //250mb
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={8}
      flexShrink={0}
    >
      <FileField
        dropzoneOptions={dropzoneOptions}
        name="upload testcases"
        rootProps={{
          onClick: disableFileField(
            isOngoing || status === 'pending'
          ),
        }}
      >
        <Box display="flex" alignItems="center" gridColumnGap={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SystemUpdateAltIcon />}
            disabled={isOngoing || status === 'pending'}
          >
            Bulk upload test cases .zip
          </Button>
          <ShowStatus status={status} />
          <Box>{error !== null && <FileFieldResults />}</Box>
        </Box>
      </FileField>
      {(error !== null || pollingResponse?.status === 'failed') && (
        <Typography color="error" variant="body2">
          *something went wrong on our side. Please try again.
        </Typography>
      )}
      {error === null && (
        <>
          {' '}
          {isOngoing && bulk_upload_poll_url && (
            <Typography>
              Please wait test cases are being created
            </Typography>
          )}
          {isStopped && pollingResponse?.status !== 'completed' && (
            <Typography>
              Thanks for your patience. It is taking more time to
              create test cases. Click
              <Typography
                component="span"
                style={{
                  color: theme.palette.info.main,
                  cursor: 'pointer',
                }}
                onClick={() => setRestartPolling(true)}
              >
                {' '}
                here{' '}
              </Typography>
              to know the status
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default TestcasesUploadButton;

import React from 'react';
import InputWithLabel from 'common/components/form/InputWithLabel';
import RichTextField from 'common/components/form/RichTextField';

const RichTextFieldWithLabel = () => {
  return (
    <InputWithLabel
      title="Data Description"
      downMD="subtitle2"
      upMD="subtitle2"
      isRequired={true}
    >
      <RichTextField name="dataDescription" />
    </InputWithLabel>
  );
};

export default RichTextFieldWithLabel;

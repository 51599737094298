import React from 'react';

const HomeSvg: React.FC<ISvgIconProps> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 14.828V24H21V14.828L12 6.453L3 14.828ZM14 22H10V16H14V22ZM24 12.148L22.639 13.613L12 3.73L1.361 13.598L0 12.133L12 1L24 12.148Z"
      fill={color}
    />
  </svg>
);

export default HomeSvg;

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core';

import QuestionStatsItem from 'business/organizer/library/components/question-preview/common/QuestionStatsItem';
import IncompleteLabel from 'business/organizer/common/components/IncompleteLabel';
import QuestionItemTags from 'business/organizer/assessment/components/common/question/QuestionItemTags';
import QuestionItemType from 'business/organizer/assessment/components/common/question/QuestionItemType';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const Header: React.FC<IQuestionPreviewHeaderProps> = ({
  name,
  questionStats,
  type,
  id,
  toggleDrawerOpen,
  isComplete,
  canEdit,
  tags,
}) => {
  const classes = useStyles();
  const { isViewer } = useHasPermission();

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={11}>
          <Typography variant="h4" color="textPrimary">
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Box display="flex" gridColumnGap={24} mt={2} mb={2}>
        {questionStats.map((questionStat: any, idx: number) => (
          <QuestionStatsItem
            key={idx}
            IconImage={questionStat.IconImage}
            content={questionStat.content}
          />
        ))}
      </Box>
      <Grid container>
        <Grid item xs={10}>
          <Box display="flex">
            <QuestionItemType type={type} />
            <QuestionItemTags tags={tags} />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            display="flex"
            gridColumnGap={24}
            justifyContent="flex-end"
          >
            <HasPermission
              isViewer={isViewer}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={toggleDrawerOpen}
                endIcon={<CreateIcon />}
                disabled={!canEdit || isViewer}
              >
                Edit
              </Button>
            </HasPermission>
            {/* <Button
              variant="outlined"
              size="small"
              color="primary"
              endIcon={<OpenInNewIcon />}
            >
              Try
            </Button> */}
          </Box>
        </Grid>
      </Grid>

      {!isComplete && (
        <IncompleteLabel
          label="Incomplete"
          hint="This questions is missing one or more fields which would help candidates understand and answer the question better"
        />
      )}
    </Box>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ccc',
    padding: theme.spacing(6),
    background: '#f1f6f9',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

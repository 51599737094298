import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const RegistrationFieldItem: React.FC<IRegistrationFormFieldProps> =
  ({ id, name, label }) => {
    const { values, setFieldValue } =
      useFormikContext<IUseFormikContextTestSettingsProps>();

    const [addedRegistrationField, setAddedRegistrationField] =
      useState(false);
    const { isViewer } = useHasPermission();

    useEffect(() => {
      setAddedRegistrationField(
        values.registration_fields.includes(id) ? true : false
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegistrationFieldChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { checked } = event.target;
      setAddedRegistrationField(checked);
      if (checked) {
        setFieldValue('registration_fields', [
          ...(values.registration_fields || []),
          id,
        ]);
      } else {
        setFieldValue(
          'registration_fields',
          values.registration_fields.filter(
            (registration_field) => registration_field !== id
          )
        );
      }
    };

    return (
      <>
        {['first_name', 'last_name', 'email_address'].includes(
          name
        ) ? (
          <Tooltip
            title="This field is shown by default."
            placement="top"
            arrow
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name={name}
                  checked={addedRegistrationField}
                  onChange={handleRegistrationFieldChange}
                />
              }
              disabled={true}
              label={label}
            />
          </Tooltip>
        ) : (
          <HasPermission
            isViewer={isViewer}
            tooltipProps={{ placement: 'top', arrow: true }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name={name}
                  checked={addedRegistrationField}
                  onChange={handleRegistrationFieldChange}
                  disabled={isViewer}
                />
              }
              label={label}
            />
          </HasPermission>
        )}
      </>
    );
  };

export default RegistrationFieldItem;

import React, { useState } from 'react';
import DrawerContext from 'business/organizer/library/contexts/drawer/DrawerContext';

const DrawerProvider = ({ children }: { children: JSX.Element }) => {
  const [drawerList, setDrawerList] = useState<string[]>([]);

  // console.log('list', drawerList);

  return (
    <DrawerContext.Provider value={{ drawerList, setDrawerList }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;

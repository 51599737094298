import React from 'react';
import Box from '@material-ui/core/Box';

import McqChoice from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/mcq/McqChoice';

const MCQSolutionBase: React.FC<{
  submission: IMCQQuestionSubmissionBaseProps;
}> = ({ submission }) => {
  return (
    <Box>
      {submission.question_choices.map((choice: any, idx: number) => (
        <McqChoice
          key={idx}
          choice={choice}
          userChoices={submission?.submitted_choices}
        />
      ))}
    </Box>
  );
};

export default MCQSolutionBase;

export const subjectContent =
  '{{CompanyName}} invited you for {{TestName}}';
export const bodyContent = `<p>Hello,&nbsp;</p><p>You have been invited to take {{TestName}} by {{CompanyName}}&nbsp;</p><p><strong>Below are Test details&nbsp;</strong></p><ul><li>Test Duration: {{TestDuration}}</li><li>&nbsp;Invite Expires In: {{InviteExpiry}}&nbsp;</li><li>Test Link: {{TestLink}}&nbsp;</li></ul><p><strong>Things to Note:&nbsp;</strong></p><ul><li>You can take this test anytime before the invite expiry date.&nbsp;</li><li>Once the test starts, you cannot pause it. The test will run continuously for {{TestDuration}}.</li></ul><p><strong>Get hang of the platform:&nbsp;</strong></p><p>To get acquainted with the Skillspace.ai platform, you may consider taking a practice test before you take your actual test.&nbsp;</p><p>&nbsp;</p><p>Regards,&nbsp;</p><p>Team {{CompanyName}}</p>`;

export const reminderBodyContent = `<p>Hello,&nbsp;</p>

<p>This is a friendly reminder that you have been invited to take {{TestName}} by {{CompanyName}}&nbsp;</p>

<p>Below are Test details&nbsp;</p>

<p>Test Duration: {{TestDuration}}<br />
&nbsp;Invite Expires In: {{InviteExpiry}}&nbsp;<br />
Test Link: {{TestLink}}&nbsp;<br />
Things to Note:&nbsp;</p>

<p>You can take this test anytime before the invite expiry date.&nbsp;<br />
Once the test starts, you cannot pause it. The test will run continuously for {{TestDuration}}.<br />
Get hang of the platform:&nbsp;</p>

<p>To get acquainted with the Skillspace.ai platform, you may consider taking a practice test before you take your actual test.&nbsp;</p>

<p>Regards,&nbsp;</p>

<p>Team {{CompanyName}}</p>`;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';

const GenericError: React.FC<IGenericErrorProps> = ({
  imageUrl,
  heading,
  description,
}): JSX.Element => {
  const theme = useTheme();
  return (
    <Box my={10}>
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={5}
      >
        {imageUrl && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <img src={imageUrl} alt="" width={theme.spacing(40)} />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gridRowGap={5}
          >
            <Typography variant="h5" color="inherit">
              {heading || 'Something went wrong!'}
            </Typography>
            <Typography variant="body1" color="inherit">
              {description || ''}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(GenericError);

const RemainingInterviewInvites = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2V14H20V2H2ZM2 0H20C21.1 0 22 0.89 22 2V14C22 14.53 21.79 15.04 21.41 15.41C21.04 15.79 20.53 16 20 16H13V18H15V20H7V18H9V16H2C1.47 16 0.96 15.79 0.59 15.41C0.21 15.04 0 14.53 0 14V2C0 0.89 0.89 0 2 0ZM9.84 6.93C10.15 6.63 10.57 6.45 11 6.45C11.43 6.46 11.85 6.63 12.16 6.94C12.46 7.24 12.64 7.66 12.64 8.09C12.64 8.53 12.46 8.94 12.16 9.25C11.85 9.56 11.43 9.73 11 9.73C10.57 9.73 10.15 9.55 9.84 9.25C9.54 8.94 9.36 8.53 9.36 8.09C9.36 7.66 9.54 7.24 9.84 6.93ZM9.07 10C9.58 10.53 10.28 10.82 11 10.82C11.72 10.82 12.42 10.53 12.93 10C13.44 9.5 13.73 8.81 13.73 8.09C13.73 7.37 13.44 6.67 12.93 6.16C12.42 5.65 11.72 5.36 11 5.36C10.28 5.36 9.58 5.65 9.07 6.16C8.56 6.67 8.27 7.37 8.27 8.09C8.27 8.81 8.56 9.5 9.07 10ZM5 8.09C5.94 5.7 8.27 4 11 4C13.73 4 16.06 5.7 17 8.09C16.06 10.5 13.73 12.18 11 12.18C8.27 12.18 5.94 10.5 5 8.09Z"
      fill="#0A66C2"
    />
  </svg>
);

export default RemainingInterviewInvites;

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import InviteStat from 'business/organizer/assessment/components/dashboard/common/InviteStat';
import HttpError from 'common/components/http-error/HttpError';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import { getDayjsDate } from 'common/utils/dayjsUtils';
import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
import CustomMuiLink from 'common/components/custom-mui-link/CustomMuiLink';
import {
  cbStartUpConfigMonthly,
  cbStartUpConfigYearly,
} from '../billing/utils/billingUtils';
import TotalInvitesIcon from './icons/TotalInvitesIcon';
import RemainingInvitesIcon from './icons/RemainingInvitesIcon';
import CancelledInvitesIcon from './icons/CancelledInvitesIcon';
import TotalInterviewInvites from './icons/TotalInterviewInvites';
import RemainingInterviewInvites from './icons/RemainingInterviewInvites';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const InviteStats = () => {
  const classes = useStyles();
  const { organizer, isFreeTrial, error } = useOrganizer();
  const { isSubscriptionExpired, isInterviewPlan } =
    useSubscription();

  const { isHubsUrl } = useCurrentUrl();

  const getSubscriptionMessages = () => {
    if (isSubscriptionExpired) {
      return 'Plan ended on ';
    }
    if (isFreeTrial) {
      return 'Plan ends on ';
    }
    if (!isSubscriptionExpired && !isFreeTrial) {
      return 'Plan renews on ';
    }
    return 'Plan ends on ';
  };

  return (
    <>
      {error !== null ? (
        <HttpError axiosError={error} />
      ) : (
        <>
          {organizer === null ? (
            <GenericListSkeleton items={1} />
          ) : (
            <Box
              width="100%"
              bgcolor="#fff"
              boxShadow="0px 6px 12px #DDE6ED"
              border="1px solid #FCFCFC"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {!isInterviewPlan && !!organizer?.current_subscription && (
                <>
                  <Box p={4}>
                    <Typography variant="h5">
                      Test invite details
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gridColumnGap={8}
                      py={2}
                    >
                      <InviteStat
                        label="Total Invites"
                        noOfInvites={
                          [
                            cbStartUpConfigMonthly.planId,
                            cbStartUpConfigYearly.planId,
                          ].includes(
                            organizer?.current_subscription?.plan
                              .chargebee_plan_id
                          ) ? (
                            <AllInclusiveIcon />
                          ) : (
                            organizer?.current_subscription?.invites
                          )
                        }
                        icon={<TotalInvitesIcon />}
                      />
                      <Divider flexItem orientation="vertical" />
                      <InviteStat
                        label="Remaining Invites"
                        noOfInvites={
                          [
                            cbStartUpConfigMonthly.planId,
                            cbStartUpConfigYearly.planId,
                          ].includes(
                            organizer?.current_subscription?.plan
                              .chargebee_plan_id
                          ) ? (
                            <AllInclusiveIcon />
                          ) : (
                            organizer?.current_subscription
                              ?.invites_left
                          )
                        }
                        icon={<RemainingInvitesIcon />}
                      />
                      <Divider flexItem orientation="vertical" />
                      <InviteStat
                        label="Cancellable Invites"
                        noOfInvites={
                          organizer?.current_subscription
                            ?.cancellable_invites
                        }
                        icon={<CancelledInvitesIcon />}
                      />
                    </Box>
                  </Box>
                </>
              )}
              {isInterviewPlan &&
                !isHubsUrl &&
                !!organizer?.current_subscription && (
                  <>
                    <Divider
                      flexItem
                      orientation="vertical"
                      className={classes.divider}
                    />
                    <Box p={4}>
                      <Typography variant="h5">
                        Interview invite details
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gridColumnGap={8}
                        py={2}
                      >
                        <InviteStat
                          label="Interviews left"
                          noOfInvites={
                            organizer.current_subscription
                              ?.interviews_left
                          }
                          icon={<TotalInterviewInvites />}
                        />
                        <Divider flexItem orientation="vertical" />
                        <InviteStat
                          label="Interviews used"
                          noOfInvites={
                            organizer.current_subscription
                              ?.interviews_used
                          }
                          icon={<RemainingInterviewInvites />}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              <Box className={classes.box__plan_details}>
                <Typography
                  variant="h6"
                  className={classes.typography__plan_text}
                >
                  Current Plan:{' '}
                  {organizer?.current_subscription?.plan.name}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.typography__plan_text}
                >
                  {!!organizer?.current_subscription &&
                    getSubscriptionMessages() +
                      getDayjsDate({
                        date: organizer?.current_subscription
                          ?.next_billing_datetime,
                        format: 'DD MMMM YYYY',
                      })}
                </Typography>
                {(isSubscriptionExpired || isFreeTrial) && (
                  <Box pt={3} display="flex" justifyContent="center">
                    <CustomMuiLink
                      href={`${isHubsUrl ? '/hubs' : ''}/billing`}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        Subscribe
                      </Button>
                    </CustomMuiLink>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default InviteStats;

const useStyles = makeStyles((theme) => ({
  box__plan_details: {
    padding: theme.spacing(10.5, 4),
    background: '#042649',
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    height: '100%',
  },
  typography__plan_text: {
    color: '#FFFFFF',
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));

import React from 'react';

import Button from '@material-ui/core/Button';
import AddOnBase from 'business/organizer/assessment/components/billing/common/AddOnBase';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';

const Addons = () => {
  const { organizer } = useOrganizer();

  const handleContact = () => {
    // @ts-ignore
    $crisp.push(['set', 'user:email', organizer?.owner.email]);
    // @ts-ignore
    $crisp.push(['do', 'chat:open']);
  };

  return (
    <Box py={12}>
      <Box py={12}>
        <Typography variant="h2" align="center">
          Customize Your Plan With Add-ons
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
      >
        <AddOnBase
          title="AI-powered proctoring"
          boxProps={{ height: 200 }}
        >
          <Box py={6}>
            <Typography>
              <Typography component="span" variant="h3">
                $100{' '}
              </Typography>
              /100 candidates per month
            </Typography>
          </Box>
          <Button
            href="https://skillspace.ai/proctoring/"
            target="_blank"
            variant="outlined"
            color="primary"
          >
            More Info
          </Button>
        </AddOnBase>
        <AddOnBase title="Team Management" boxProps={{ height: 120 }}>
          <Box py={6}>
            <Button
              // Open chat when clicked here
              onClick={handleContact}
              variant="outlined"
              color="primary"
            >
              Contact us
            </Button>
          </Box>
        </AddOnBase>
        {/* <AddOnBase
          title="Take-home assignments on real-world projects"
          boxProps={{ height: 200 }}
        >
          <Box py={6}>
            <Typography>
              <Typography component="span" variant="h3">
                $50{' '}
              </Typography>
              /5 invites per month
            </Typography>
          </Box>
          <Button
            href="https://skillspace.ai/take-home-assignments/"
            target="_blank"
            variant="outlined"
            color="primary"
          >
            More Info
          </Button>
        </AddOnBase> */}
      </Box>
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        py={12}
      > */}
      {/* <AddOnBase
          title="One on One Coding Interview Platform"
          boxProps={{ height: 120 }}
        >
          <Box py={6}>
            <Button
              // Open chat when clicked here
              onClick={handleContact}
              variant="outlined"
              color="primary"
            >
              Contact us
            </Button>
          </Box>
        </AddOnBase> */}
      {/* <AddOnBase title="Team Management" boxProps={{ height: 120 }}>
          <Box py={6}>
            <Button
              // Open chat when clicked here
              onClick={handleContact}
              variant="outlined"
              color="primary"
            >
              Contact us
            </Button>
          </Box>
        </AddOnBase> */}
      {/* </Box> */}
    </Box>
  );
};

export default Addons;

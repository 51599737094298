import { useEffect, useState } from 'react';
import { exportToCanvas } from '@excalidraw/excalidraw';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SectionHeader from 'business/organizer/interviews/components/report/SectionHeader';

const WhiteboardSection: React.FC<{ whiteboardElements: any }> = ({
  whiteboardElements,
}) => {
  const [canvasUrl, setCanvasUrl] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');

  useEffect(() => {
    const canvas = exportToCanvas({
      elements: whiteboardElements?.elements,
    });
    setCanvasUrl(canvas.toDataURL());
    setDownloadUrl(
      canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
    );
  }, [whiteboardElements]);

  return (
    <>
      <SectionHeader title="Whiteboard">
        <Button
          startIcon={<GetAppIcon />}
          color="primary"
          href={downloadUrl}
          disabled={!whiteboardElements}
          download="whiteboard.png"
        >
          Download Image
        </Button>
      </SectionHeader>
      <Container maxWidth={false}>
        {whiteboardElements ? (
          <Box
            p={4}
            bgcolor="#fff"
            border="1px solid #666666"
            borderRadius={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={6}
          >
            <img src={canvasUrl} alt="whiteboard" />
          </Box>
        ) : (
          <Box p={4}>
            <Typography>Whiteboard image not found</Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default WhiteboardSection;

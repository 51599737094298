import React, { useState } from 'react';

import HeaderContext from 'business/organizer/common/context/header/HeaderContext';

const HeaderProvider = ({ children }: { children: JSX.Element }) => {
  const [openSidebar, setOpenSidebar] = useState(true);

  return (
    <HeaderContext.Provider value={{ openSidebar, setOpenSidebar }}>
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;

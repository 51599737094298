import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Testimonial from 'business/pre-subscription/auth/components/Testimonial';
import Companies from 'business/pre-subscription/auth/components/Companies';
import {
  freeTrialBenefits,
  hubsFreeTrialBenefits,
} from 'business/pre-subscription/auth/utils/utils';
import { FRONTEND_SIGNUP_URL } from 'common/utils/constants';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const ContentSection: React.FC<IContentSectionProps> = ({
  showSignUpText,
  title = 'Complete SignUp to get',
  underlineTitle = 'free 14 days trial',
  next = false,
}) => {
  const classes = useStyles();
  const { isHubsUrl } = useCurrentUrl();
  const trialBenefits = isHubsUrl
    ? hubsFreeTrialBenefits
    : freeTrialBenefits;

  return (
    <Box
      height="100%"
      bgcolor="#064079"
      color="#fff"
      component="section"
    >
      <Container maxWidth="xl">
        <Box py={12}>
          {!next ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Companies />
              <Testimonial />
            </Box>
          ) : (
            <>
              <Box
                pb={6}
                className={classes.box__title}
                component="article"
              >
                <Typography variant="h3" color="inherit">
                  {title}{' '}
                  <Typography
                    variant="h3"
                    color="inherit"
                    component="span"
                    className={classes.typography__trial}
                  >
                    {underlineTitle}
                  </Typography>
                </Typography>
              </Box>
              {showSignUpText && (
                <Box py={6}>
                  <Typography color="inherit">
                    Meanwhile you can{' '}
                    <Link
                      to={FRONTEND_SIGNUP_URL}
                      className={classes.link}
                    >
                      sign up
                    </Link>{' '}
                    to get free 14 days trial
                  </Typography>
                </Box>
              )}
              <Box pt={6}>
                <Typography variant="h5" color="inherit">
                  What are the benefits of free trial?
                </Typography>
              </Box>
              <List className={classes.list__root} component="ul">
                {trialBenefits.map((benefit) => (
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <FiberManualRecordIcon
                        color="secondary"
                        fontSize="small"
                        style={{ fontSize: 8 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ color: 'inherit' }}
                    >
                      {benefit}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              <Box pt={6} pb={12}>
                <Typography color="inherit">
                  Have any questions? Get in touch with us at{' '}
                  <strong>support@skillspace.app</strong>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ContentSection;

const useStyles = makeStyles((theme: Theme) => ({
  typography__trial: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    paddingBottom: 6,
  },
  box__title: {
    '& .MuiTypography-h3': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    '& .MuiTypography-h5': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  },
  list__root: {
    '& .MuiListItemIcon-root': {
      minWidth: 23,
    },
  },
  link: {
    color: '#fff',
    textDecoration: 'underline',
  },
}));

import React from 'react';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import QuestionsMenu from 'business/organizer/common/components/QuestionsMenu';
import useOnHoverDropdown from 'common/components/onHover-dropdown/useOnHoverDropdown';
import QuestionDrawerProvider from 'business/organizer/library/contexts/question-drawer/QuestionDrawerProvider';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const CreateQuestion = () => {
  const {
    anchorEl,
    handleClick,
    handleClose,
    handleDropdownMouseEnter,
    closeDropdownWithDelay,
  } = useOnHoverDropdown();
  const { isViewer, isSubscriptionExpired } = useHasPermission();

  return (
    <div>
      <HasPermission
        isViewer={isViewer}
        isSubscriptionExpired={isSubscriptionExpired}
        tooltipProps={{ placement: 'top', arrow: true }}
      >
        <Button
          onMouseEnter={handleClick}
          onMouseLeave={closeDropdownWithDelay}
          onClick={handleClick}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          endIcon={
            anchorEl !== null ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )
          }
          disabled={isViewer || isSubscriptionExpired}
        >
          Create Question
        </Button>
      </HasPermission>
      <QuestionDrawerProvider>
        <QuestionsMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          MenuListProps={{
            onMouseEnter: handleDropdownMouseEnter,
            onMouseLeave: handleClose,
          }}
        />
      </QuestionDrawerProvider>
    </div>
  );
};

export default CreateQuestion;

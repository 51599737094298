import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

interface IFieldStylesProps {
  children: JSX.Element;
}

const FieldStyles: React.FC<IFieldStylesProps & BoxProps> = ({
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Box py={2} {...otherProps} className={classes.root}>
      {children}
    </Box>
  );
};

export default FieldStyles;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormHelperText-contained': {
      margin: 'unset',
    },
    '& .MuiFormHelperText-root': {
      fontSize: theme.spacing(3),
    },
  },
}));

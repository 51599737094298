import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';

const FreeTextSolutionBase: React.FC<{
  submission: IFreeTextSubmissionBaseProps;
}> = ({ submission }) => {
  const classes = useStyles();
  return (
    <Box
      p={2}
      border="1px solid #A3A3A3"
      borderRadius={5}
      className={classes.box__free_text}
    >
      <RichTextRenderer richTextHtml={submission?.text} />
    </Box>
  );
};

export default FreeTextSolutionBase;

const useStyles = makeStyles((theme: Theme) => ({
  box__free_text: {
    wordBreak: 'break-word',
  },
}));

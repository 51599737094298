import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAlert from 'common/components/alert-provider/useAlert';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import redirect from 'common/utils/redirect';

const organizerService = new OrganizerService();
const useReviewed = ({
  apiUrl,
  errorMessage,
  successMessage,
  isListPage,
}: {
  apiUrl: string;
  errorMessage: string;
  successMessage: string;
  isListPage: boolean;
}) => {
  const { enqueueAlert } = useAlert();
  const history = useHistory();

  const { candidateIds, setCandidateIds, setHasChanges } =
    useCandidateProgress();
  const { setDrawerList } = useDrawer();

  useEffect(() => {
    return () => {
      setCandidateIds([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReview = async () => {
    try {
      await organizerService.shortlistOrReject(apiUrl, {
        candidate_ids: candidateIds,
      });

      if (!isListPage) {
        /*  we are adding this condition here to remove name of the sidebar 
        which is responsible for width of the sidebar if we dont remove this
        it will affect the width of sidebar which is dependent on this list
      **/
        setDrawerList((drawerList) =>
          drawerList.filter((list) => list !== 'candidate')
        );
        redirect(history, '../../');
      }

      setHasChanges(true);

      enqueueAlert(successMessage, {
        alertProps: { severity: 'success' },
      });
    } catch (error) {
      // Check message property is available or not
      const isApiError = (e: any): e is { message: string } => {
        return typeof e?.message === 'string';
      };
      enqueueAlert(
        `${errorMessage}, ${
          isApiError(error) ? error.message : ''
        }.Please try again.`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return {
    handleReview,
  };
};

export default useReviewed;

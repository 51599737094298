import {
  FETCH_LIBRARY_SUCCESS,
  FETCH_LIBRARY_FAILURE,
  FETCH_LIBRARY_COUNT_FAILURE,
  ADD_QUESTION_TYPE,
  ADD_DIFFICULTY_LEVEL,
  REMOVE_QUESTION_TYPE,
  REMOVE_DIFFICULTY_LEVEL,
  SEARCH_QUESTIONS,
  LOAD_PAGE,
  FETCH_LIBRARY_COUNTS,
  REFRESH_PAGE,
  UPDATE_LIBRARY,
  CLEAR_FILTERS,
  FILTER_BY_TAGS,
  REMOVE_TAG,
} from 'business/organizer/library/contexts/library/libraryActionTypes';
import {
  addFilters,
  removeFilters,
  markTabsToUnload,
  clearFilters,
  filterByTags,
  removeTag,
} from 'business/organizer/library/contexts/library/utils';

export const libraryReducer = (
  state: IInitialStateProps,
  action: Action
): IInitialStateProps => {
  switch (action.type) {
    case FETCH_LIBRARY_COUNTS:
      return {
        ...state,
        shared: {
          ...state.shared,
          count: action.payload.shared_count,
        },
        my: {
          ...state.my,
          count: action.payload.my_count,
        },
      };
    case FETCH_LIBRARY_SUCCESS:
      return {
        ...state,
        [action.payload.list]: {
          count: action.payload.response?.data.count,
          isLoaded: true,
          questions: action.payload.response?.data?.results,
          params: {
            // @ts-ignore
            ...state[action.payload.list].params,
          },
          responseError: null,
        },
      };
    case FETCH_LIBRARY_FAILURE:
      return {
        ...state,
        [action.payload.list]: {
          // @ts-ignore
          ...state[action.payload.list],
          responseError: action.payload.error,
        },
      };
    case FETCH_LIBRARY_COUNT_FAILURE:
      return {
        ...state,
        responseError: action.payload,
      };
    case ADD_QUESTION_TYPE: {
      return markTabsToUnload(state, {
        type: addFilters(
          state.globalParams.type,
          action.payload,
          'type'
        ),
      });
    }
    case REMOVE_QUESTION_TYPE: {
      return markTabsToUnload(state, {
        type: removeFilters(
          state.globalParams.type,
          action.payload,
          'type'
        ),
      });
    }
    case ADD_DIFFICULTY_LEVEL: {
      return markTabsToUnload(state, {
        difficulty: addFilters(
          state.globalParams.difficulty,
          action.payload,
          'difficulty'
        ),
      });
    }
    case REMOVE_DIFFICULTY_LEVEL: {
      return markTabsToUnload(state, {
        difficulty: removeFilters(
          state.globalParams.difficulty,
          action.payload,
          'difficulty'
        ),
      });
    }
    case FILTER_BY_TAGS: {
      return markTabsToUnload(state, {
        [action.payload.tagName]: filterByTags(
          action.payload.value,
          action.payload.tagName
        ),
      });
    }
    case REMOVE_TAG:
      return markTabsToUnload(state, {
        [action.payload.tagName]: removeTag(
          state.globalParams.tags,
          action.payload.tagId,
          action.payload.tagName
        ),
      });
    case CLEAR_FILTERS: {
      return clearFilters(state);
    }
    case SEARCH_QUESTIONS: {
      return markTabsToUnload(state, {
        search: action.payload,
      });
    }
    case LOAD_PAGE:
      return {
        ...state,
        [action.payload.list]: {
          // @ts-ignore
          ...state[action.payload.list],
          questions: null,
          isLoaded: false,
          params: {
            // @ts-ignore
            ...state[action.payload.list].params,
            page: action.payload.page,
          },
        },
      };
    case REFRESH_PAGE:
      return {
        ...state,
        [action.payload.list]: {
          // @ts-ignore
          ...state[action.payload.list],
          questions: null,
          isLoaded: false,
          params: {
            ...state,
            page: 1,
          },
        },
      };
    case UPDATE_LIBRARY:
      return {
        ...state,
        [action.payload.list]: {
          // @ts-ignore
          ...state[action.payload.list],
          isLoaded: false,
        },
      };
    default:
      throw new Error();
  }
};

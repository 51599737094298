import { useEffect } from 'react';

import InviteMemberModal from 'business/organizer/settings/components/team-management/InviteMemberModal';
import useAsync from 'common/custom-hooks/useAsync';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAlert from 'common/components/alert-provider/useAlert';

const organizerService = new OrganizerService();
const EditMemberAccess: React.FC<{
  open: boolean;
  onClose: () => void;
  editUrl: string;
  onReset: () => void;
}> = ({ open, onClose, editUrl, onReset }) => {
  const { run, data, error } = useAsync();
  const { enqueueAlert } = useAlert();

  useEffect(() => {
    run(organizerService.get(editUrl));
  }, [run, editUrl]);

  if (error !== null) {
    enqueueAlert(`Error: ${error.message}. Please try again`, {
      alertProps: { severity: 'error' },
    });
  }

  return (
    <InviteMemberModal
      member={data}
      onReset={onReset}
      open={open}
      onClose={onClose}
      editUrl={editUrl}
    />
  );
};

export default EditMemberAccess;

import React from 'react';

import SettingsBoxOuterContainer from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxOuterContainer';
import SettingsBoxInnerContainer from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxInnerContainer';

const SettingsBoxStyles: React.FC<ISettingsBoxStyles> = (props) => (
  <SettingsBoxOuterContainer>
    <SettingsBoxInnerContainer {...props} />
  </SettingsBoxOuterContainer>
);

export default SettingsBoxStyles;

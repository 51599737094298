import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import MonacoEditor from 'common/components/monaco-editor/Editor';
import { getLanguage } from 'common/components/monaco-editor/helpers';
import SettingsProvider from 'common/components/monaco-editor/SettingsProvider';
import SectionHeader from 'business/organizer/interviews/components/report/SectionHeader';

const CodeSection: React.FC<{
  interviewDetails: Interview.IInterviewDetailProps;
}> = ({ interviewDetails }) => {
  const classes = useStyles();
  const [downloadUrl, setDownloadUrl] = useState('');

  useEffect(() => {
    if (interviewDetails.code.code) {
      setDownloadUrl(
        'data:text/plain;charset=utf-8,' +
          encodeURIComponent(interviewDetails.code.code)
      );
    }
  }, [interviewDetails.code.code]);

  return (
    <>
      <SectionHeader title="Code Snippet">
        <Button
          href={downloadUrl}
          disabled={!downloadUrl}
          download="snippet.txt"
          startIcon={<GetAppIcon />}
          color="primary"
        >
          Download snippet
        </Button>
      </SectionHeader>
      <Container maxWidth={false}>
        <>
          {interviewDetails.code.code &&
          interviewDetails.code.programming_language ? (
            <Box
              border="1px solid #000000"
              bgcolor="#fff"
              borderRadius={8}
              my={6}
            >
              <SettingsProvider>
                <MonacoEditor
                  value={interviewDetails.code.code || ''}
                  languages={[
                    getLanguage(
                      interviewDetails.code.programming_language
                    ),
                  ]}
                  options={{
                    readOnly: true,
                    minimap: {
                      enabled: false,
                    },
                  }}
                  onChange={() => {}}
                  className={classes.monaco__editor}
                  rootBoxProps={{ borderRadius: 8 }}
                />
              </SettingsProvider>
            </Box>
          ) : (
            <Box p={4}>
              <Typography>Code is not available</Typography>
            </Box>
          )}
        </>
      </Container>
    </>
  );
};

export default CodeSection;

const useStyles = makeStyles((theme: Theme) => ({
  monaco__editor: {
    minHeight: theme.spacing(100),
  },
}));

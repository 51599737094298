import { useParams } from 'react-router-dom';

import FileUploadPreview from 'business/organizer/library/components/question-preview/file-upload/FileUploadPreview';
import FreeTextPreview from 'business/organizer/library/components/question-preview/free-text/FreeTextPreview';
import ChallengePreview from 'business/organizer/library/components/question-preview/challenge/ChallengePreview';
import CodingPreview from 'business/organizer/library/components/question-preview/coding/CodingPreview';
import McqPreview from 'business/organizer/library/components/question-preview/mcq/McqPreview';
// import ProjectPreview from 'business/organizer/library/components/question-preview/project/ProjectPreview';
import { QuestionType } from 'business/organizer/library/utils/utils';
import InterviewPreview from 'business/organizer/library/components/question-preview/interview/InterviewPreview';
import SqlQPreview from 'business/organizer/library/components/question-preview/sql/SqlPreview';

const RenderQuestionPreview = ({
  questionUrl,
}: {
  questionUrl: string;
}) => {
  const { questionType } = useParams<{ questionType: string }>();
  switch (questionType) {
    case QuestionType.CHALLENGE:
      return <ChallengePreview questionUrl={questionUrl} />;
    case QuestionType.CODING:
      return <CodingPreview questionUrl={questionUrl} />;
    case QuestionType.FREE_TEXT:
      return <FreeTextPreview questionUrl={questionUrl} />;
    case QuestionType.FILE_UPLOAD:
      return <FileUploadPreview questionUrl={questionUrl} />;
    case QuestionType.MCQ:
      return <McqPreview questionUrl={questionUrl} />;
    // case QuestionType.PROJECT:
    //   return <ProjectPreview questionUrl={questionUrl} />;
    case QuestionType.INTERVIEW:
      return <InterviewPreview questionUrl={questionUrl} />;
    case QuestionType.SQL:
      return <SqlQPreview questionUrl={questionUrl} />;
    default:
      throw new Error(`${questionType} question is not available.`);
  }
};

export default RenderQuestionPreview;

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const CompilationInfo: React.FC<{ streamData: StreamData_ }> = ({
  streamData,
}) => {
  return (
    <Box py={4}>
      {(streamData?.comp_info || streamData?.error) && (
        <>
          <Box py={2}>
            <Typography variant="h6">Compilation log</Typography>
          </Box>
          <Box
            border="1px solid #000000"
            bgcolor="#ECECEC"
            maxHeight={200}
            overflow="scroll"
            px={2}
          >
            <pre>{streamData?.comp_info || streamData?.error}</pre>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CompilationInfo;

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid/Grid';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TestActions from 'business/organizer/assessment/components/assessment-tests/test-list/TestActions';

const TestViewPermissionChecker: React.FC<ITestActionsProps> = ({
  testItem,
}) => {
  const { isViewer } = useHasPermission();

  return (
    <Grid item xs={2}>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          {isViewer ? (
            <HasPermission
              isViewer={isViewer}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <IconButton disabled={isViewer}>
                <MoreVertIcon />
              </IconButton>
            </HasPermission>
          ) : (
            <TestActions testItem={testItem} />
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default TestViewPermissionChecker;

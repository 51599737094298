import React from 'react';

import ChallengeSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/challenge/ChallengeSolution';
import CodingSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CodingSolution';
import FileUploadSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/FileUploadSolution';
import FreeTextSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/FreeTextSolution';
import MCQSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/mcq/MCQSolution';
// import ProjectSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/project/ProjectSolution';
import { QuestionType } from 'business/organizer/library/utils/utils';
import SqlSolution from './SqlSolution';

interface IRenderQuestionSolutionProps {
  questionType: string;
  submissionUrl: string;
}

const RenderQuestionSolution: React.FC<IRenderQuestionSolutionProps> =
  ({ questionType, submissionUrl }) => {
    switch (questionType) {
      case QuestionType.CODING:
        return <CodingSolution submissionUrl={submissionUrl} />;
      case QuestionType.CHALLENGE:
        return <ChallengeSolution submissionUrl={submissionUrl} />;
      case QuestionType.FREE_TEXT:
        return <FreeTextSolution submissionUrl={submissionUrl} />;
      case QuestionType.FILE_UPLOAD:
        return <FileUploadSolution submissionUrl={submissionUrl} />;
      case QuestionType.MCQ:
        return <MCQSolution submissionUrl={submissionUrl} />;
      // case QuestionType.PROJECT:
      //   return <ProjectSolution submissionUrl={submissionUrl} />;
      case QuestionType.SQL:
        return <SqlSolution submissionUrl={submissionUrl} />;
      default:
        throw new Error(`${questionType}, Unexpected question type.`);
    }
  };

export default RenderQuestionSolution;

import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Formik, Form } from 'formik';

import AuthPageButton from 'business/pre-subscription/auth/components/common/AuthPageButton';
import AuthBlockFooter from 'business/pre-subscription/auth/components/common/AuthBlockFooter';
import MyInput from 'common/components/form/MyInput';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import {
  getErrorMessage,
  getValidationSchema,
} from 'business/pre-subscription/auth/components/common/helpers';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import {
  API_BUSINESS_PASSWORD_RESET_CONFIRM,
  FRONTEND_BUSINESS_FORGOT_PASSWORD,
  FRONTEND_BUSINESS_LOGIN_URL,
} from 'business/pre-subscription/auth/utils/constants';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import useAlert from 'common/components/alert-provider/useAlert';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';

const businessAuthService = new BusinessAuthService();
const ResetPassword: React.FC<IForgotPasswordPageProps> = ({
  apiUrl = API_BUSINESS_PASSWORD_RESET_CONFIRM,
  redirectUrl = FRONTEND_BUSINESS_LOGIN_URL,
  verifyExpiry = true,
}) => {
  const { enqueueAlert } = useAlert();

  const { uid, token } = useParams<{ uid: string; token: string }>();
  const history = useHistory();

  const [error, setError] = useState<IBusinessFormErrorProps | null>(
    null
  );

  const resetPasswordApiUrl = `${apiUrl}?uid=${uid}&token=${token}`;

  useEffect(() => {
    const handleExpiry = async () => {
      if (verifyExpiry) {
        try {
          await businessAuthService.get(resetPasswordApiUrl);
        } catch (error) {
          const errorMessage = getErrorMessage(
            (error as AxiosError).response
              ?.data as IBusinessFormErrorProps
          );
          enqueueAlert(
            `${
              errorMessage ||
              'The link is expired, Please request for the new link.'
            }`,
            {
              alertProps: { severity: 'error' },
            }
          );
          history.push(FRONTEND_BUSINESS_FORGOT_PASSWORD);
        }
      }
    };
    handleExpiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = getValidationSchema(initialState);

  const handleSubmit: AuthSubmissionHandler = async (
    values: {
      password: string;
      confirmPassword: string;
    },
    actions
  ) => {
    try {
      const { password, confirmPassword } = values;
      const data = {
        new_password1: password,
        new_password2: confirmPassword,
        uid,
        token,
      };
      const response = await businessAuthService.authPost(
        resetPasswordApiUrl,
        data
      );
      actions.resetForm();
      enqueueAlert(response?.data?.detail, {
        alertProps: { severity: 'success' },
      });
      history.push(redirectUrl);
    } catch (error) {
      setError(
        (error as AxiosError).response
          ?.data as IBusinessFormErrorProps
      );
    }
  };

  const errorMessage = getErrorMessage(error);

  return (
    <FormSectionBase
      title="Reset Password"
      subtitle="Enter your new password"
    >
      <div>
        <NotificationDialog
          open={error !== null}
          variant="error"
          agreeText="Try Again"
          onAgree={() => setError(null)}
          content={errorMessage}
        />
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FieldStyles>
              <MyInput
                placeholder="Password"
                name="password"
                type="password"
              />
            </FieldStyles>
            <FieldStyles>
              <MyInput
                placeholder="Confirm Password"
                name="confirmPassword"
                type="password"
              />
            </FieldStyles>
            <AuthPageButton>Reset Password</AuthPageButton>
          </Form>
        </Formik>
        <AuthBlockFooter ctaLink={redirectUrl} />
      </div>
    </FormSectionBase>
  );
};

export default ResetPassword;

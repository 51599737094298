import { createTheme } from '@material-ui/core/styles';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 545,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0A66C2',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFB10A',
      contrastText: '#FFFFFF',
    },
    neutral: {
      main: '#222222',
      contrastText: '#000000',
    },
    text: {
      primary: '#222222',
      secondary: '#333333',
    },
  },

  spacing: 4,

  typography: {
    htmlFontSize: 14,
    fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
      color: '#222222',
    },
    h2: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 600,
      fontSize: 32,
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
      color: '#222222',
    },
    h3: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: 28,
      lineHeight: 1.167,
      letterSpacing: '0em',
      color: '#222222',
    },
    h4: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
      color: '#222222',
    },
    h5: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.334,
      letterSpacing: '0em',
      color: '#222222',
    },
    h6: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      color: '#222222',
    },
    subtitle1: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
      color: '#222222',
    },
    subtitle2: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
      color: '#222222',
    },
    body1: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
      color: '#222222',
    },

    body2: {
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.5,
      letterSpacing: '0.01071em',
      color: '#222222',
    },
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: '2rem',
      textTransform: 'none',
      minWidth: '100px',
      padding: '6px 18px',
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
    sizeSmall: {
      padding: '3px 12px',
    },
    outlinedPrimary: {
      '&:hover': {
        backgroundColor: '#0A66C2 !important',
        color: '#FFFFFF !important',
      },
    },
  },
  MuiInputBase: {
    root: {
      fontSize: 14,
      // top: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },

  MuiOutlinedInput: {
    input: {
      padding: '0 !important',
    },
  },
  MuiInputAdornment: {
    root: {
      padding: '0 !important',
    },
  },

  MuiMenuItem: {
    root: {
      fontSize: 14,
    },
  },
  MuiSelect: {
    root: {
      padding: '0 !important',
      minWidth: '60px !important',
    },
    outlined: {
      paddingRight: '32px !important',
    },
  },

  MuiContainer: {
    root: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
  },
  MuiLink: {
    root: {
      color: theme.palette.info.dark,
      textDecoration: 'none',
    },
  },
  MuiChip: {
    root: {
      fontSize: '12px',
    },
  },
  MuiTab: {
    root: {
      textTransform: 'none',
    },
  },
  MuiBadge: {
    badge: {
      padding: 5,
    },
  },
  MuiTable: {
    root: {
      '& .MuiTableCell-body': {
        fontSize: '0.9em',
        fontWeight: 500,
      },
      '& .MuiTableCell-head': {
        fontWeight: 600,
        fontSize: '1em',
      },
    },
  },
  MuiCheckbox: {
    root: {
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
    },
  },
};

declare module '@material-ui/core/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

export default theme;

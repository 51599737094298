export enum QuestionType {
  CHALLENGE = 'challenge',
  CODING = 'coding',
  FREE_TEXT = 'free-text',
  FILE_UPLOAD = 'file-upload',
  MCQ = 'mcq',
  PROJECT = 'project',
  SQL = 'sql',
  INTERVIEW = 'interview',
}

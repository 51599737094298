import { useRouteMatch } from 'react-router-dom';

const useCurrentUrl = () => {
  let { url } = useRouteMatch();

  // Ensure we don't have a trailing slash.
  if (url[url.length - 1] === '/') {
    url = url.slice(0, url.length - 1);
  }

  const isHubsUrl = url.includes('hubs');

  return { url, isHubsUrl };
};

export default useCurrentUrl;

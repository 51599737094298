import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box/Box';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import GenericNavbar from 'business/organizer/common/components/GenericNavbar';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import Organization from 'business/organizer/settings/pages/tabs/Organization';
import Password from 'business/organizer/settings/pages/tabs/Password';
import Personal from 'business/organizer/settings/pages/tabs/Personal';
import { FRONTEND_SETTINGS_URL } from 'common/utils/constants';
import TeamManagement from './tabs/TeamManagement';
import SettingsBoxOuterContainer from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxOuterContainer';

const SettingsPage = () => {
  const classes = useStyles();
  const { url } = useCurrentUrl();

  const tabs = [
    {
      id: 0,
      label: 'Personal',
      route: `${url}/personal`,
      badge: false,
    },
    {
      id: 1,
      label: 'Company',
      route: `${url}/company`,
      badge: false,
    },
    {
      id: 3,
      label: 'Password',
      route: `${url}/password`,
      badge: false,
    },
    {
      id: 4,
      label: 'Team Management',
      route: `${url}/team-management`,
      badge: false,
    },
  ];

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Settings', route: FRONTEND_SETTINGS_URL }]}
      />
      <Container
        maxWidth={false}
        className={classes.container__header}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <GenericNavbar tabs={tabs} />
          </Box>
        </Box>
      </Container>
      <Container maxWidth={false} className={classes.container__root}>
        <SettingsBoxOuterContainer>
          <Switch>
            <Route path={tabs[0].route}>
              <Box className={classes.box__inner_container}>
                <Personal />
              </Box>
            </Route>
            <Route path={tabs[1].route}>
              <Box className={classes.box__inner_container}>
                <Organization />
              </Box>
            </Route>
            <Route path={tabs[2].route}>
              <Box className={classes.box__inner_container}>
                <Password />
              </Box>
            </Route>
            <Route path={tabs[3].route}>
              <TeamManagement />
            </Route>
            <Redirect from={url} to={tabs[0].route} />
          </Switch>
        </SettingsBoxOuterContainer>
      </Container>
    </>
  );
};

export default SettingsPage;

const useStyles = makeStyles((theme) => ({
  container__header: {
    background: '#f1f6f9',
    boxShadow: '0px 4px 8px rgba(221, 230, 237, 0.5)',
    position: 'sticky',
    top: 64,
    zIndex: 9,
  },
  container__root: {
    marginTop: theme.spacing(4),
  },
  box__inner_container: {
    maxWidth: theme.spacing(100),
    minHeight: theme.spacing(75),
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#233D4D',
    },
    '& .MuiFormGroup-root': {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: theme.spacing(0),
      },
    },
  },
}));

import {
  useRef,
  useCallback,
  useLayoutEffect,
  useReducer,
} from 'react';
import { AxiosError, AxiosResponse } from 'axios';

const useSafeDispatch = (dispatch: React.Dispatch<any>) => {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback(
    (args) => (mounted.current ? dispatch({ ...args }) : void 0),
    [dispatch]
  );
};

// Example usage:
// const {data, error, status, run} = useAsync()
// useEffect(() => {
//   run(service.get(url))
// }, [run])
const defaultInitialState: useAsyncDefaultInitialsState = {
  status: 'idle',
  data: null,
  error: null,
};

const useAsync = (
  initialState?: useAsyncDefaultInitialsState
): IUseAsyncReturnProps => {
  const initialStateRef = useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = useReducer(
    (state: any, action: any) => ({ ...state, ...action }),
    initialStateRef.current
  );

  const safeSetState = useSafeDispatch(setState);

  const setData = useCallback(
    (data) => safeSetState({ data, status: 'resolved' }),
    [safeSetState]
  );
  const setError = useCallback(
    (error) => safeSetState({ error, status: 'rejected' }),
    [safeSetState]
  );
  const reset = useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState]
  );

  const run = useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: 'pending' });
      return promise
        .then((response: AxiosResponse) => {
          const { data } = response;
          setData(data);
          return data;
        })
        .catch((error: AxiosError) => {
          setError(error);
          return Promise.reject(error);
        });
    },
    [safeSetState, setData, setError]
  );

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
};

export default useAsync;

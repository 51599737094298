import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';

import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useCandidateProgressList from 'business/organizer/assessment/components/candidate-progress/candidate-list/custom-hooks/useCandidateProgressList';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import TablePaginationBoxStyles from 'business/organizer/assessment/components/candidate-progress/common/TablePaginationBoxStyles';
import HttpError from 'common/components/http-error/HttpError';
import CandidateListNavbarStyles from 'business/organizer/assessment/components/candidate-progress/candidate-list/styles/CandidateListNavbarStyles';
import DownloadCandidateList from 'business/organizer/assessment/components/candidate-progress/candidate-list/DownloadCandidateList';
import TableBase from 'common/components/table/TableBase';
import Reviewed from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/Reviewed';
import TableSortCell from 'common/components/table/TableSortCell';
import SubmissionsBaseItem from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/SubmissionsBaseItem';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import ShowAppliedParams from '../common/ShowAppliedParams';

const SubmissionsBase: React.FC<ISubmissionsBaseProps> = ({
  label,
  candidateStatus,
}) => {
  const classes = useStyles();
  const { testDetail } = useTestDetail();
  const {
    data: candidates,
    error,
    params,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSort,
    reset,
  } = useCandidateProgressList<ISubmissionsProps>({
    apiUrl: testDetail?.candidate_url as string,
    candidateStatus,
  });

  const { candidateIds, setCandidateIds, setHasChanges } =
    useCandidateProgress();

  useEffect(() => {
    return () => {
      reset();
      setHasChanges(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAllClick = () => {
    if (!!candidateIds.length) {
      setCandidateIds([]);
    } else {
      setCandidateIds([
        ...candidates?.results.map((candidate: any) => candidate?.id),
      ]);
    }
  };

  if (error !== null) {
    return (
      <Box py={12}>
        <HttpError axiosError={error} />
      </Box>
    );
  }

  return (
    <>
      <Box py={4}>
        <CandidateListNavbarStyles>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              {!!candidateIds.length && (
                <Reviewed
                  disableShortlist={
                    candidateStatus === 'shortlisted' ? true : false
                  }
                  disableReject={
                    candidateStatus === 'rejected' ? true : false
                  }
                  isListPage
                />
              )}
            </div>
            <DownloadCandidateList
              link={`${testDetail?.candidate_download_url}?test_status=${candidateStatus}`}
            />
          </Box>
        </CandidateListNavbarStyles>
      </Box>
      <ShowAppliedParams
        order={params.order}
        order_by={params.order_by}
      />
      <TableBase
        tableProps={{ 'aria-label': 'candidate-submission' }}
      >
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  !!candidateIds.length &&
                  candidates?.count > candidateIds.length
                }
                checked={
                  candidates?.count === candidateIds.length &&
                  candidates?.count !== 0
                }
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all candidates',
                }}
              />
            </TableCell>
            <TableCell className={classes.tablecell__candidate}>
              Candidate
            </TableCell>
            {candidateStatus !== 'in_progress' && (
              <TableSortCell
                label="Score"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('total_score', order)
                }
                isActive={params.order_by === 'total_score'}
              />
            )}
            {candidateStatus !== 'in_progress' && (
              <TableSortCell
                label="Questions Attempted"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('total_attempted_questions', order)
                }
                isActive={
                  params.order_by === 'total_attempted_questions'
                }
              />
            )}
            {candidateStatus === 'in_progress' && (
              <TableSortCell
                label="Started on"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('test_start_time', order)
                }
                isActive={params.order_by === 'test_start_time'}
              />
            )}
            {candidateStatus !== 'in_progress' && (
              <TableCell align="center">Duration</TableCell>
            )}
            {candidateStatus === 'ready_for_review' && (
              <TableSortCell
                label="Completed on"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('test_end_time', order)
                }
                isActive={params.order_by === 'test_end_time'}
              />
            )}
            {label && (
              <TableSortCell
                label={`${label} on`}
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort(
                    label === 'Shortlisted'
                      ? 'shortlisted_on'
                      : 'rejected_on',
                    order
                  )
                }
                isActive={
                  params.order_by ===
                  (label === 'Shortlisted'
                    ? 'shortlisted_on'
                    : 'rejected_on')
                }
              />
            )}
            {candidateStatus === 'in_progress' && (
              <TableSortCell
                label="Invite sent on"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('invited_on', order)
                }
                isActive={params.order_by === 'invited_on'}
              />
            )}
            <TableCell className={classes.tablecell__actions}>
              Report
            </TableCell>
          </TableRow>
        </TableHead>
        {candidates !== null && (
          <TableBody>
            {candidates?.results.map((candidate, idx) => (
              <SubmissionsBaseItem
                key={candidate.id}
                idx={idx}
                candidate={candidate}
                candidateList={candidates?.results}
                label={label}
                candidateStatus={candidateStatus}
              />
            ))}
          </TableBody>
        )}
      </TableBase>
      {candidates === null && <GenericListSkeleton items={3} />}
      {candidates?.results.length === 0 && (
        <Box py={12}>
          <Typography align="center" variant="h5">
            There are no candidates
          </Typography>
        </Box>
      )}
      {candidates?.results.length !== 0 && (
        <Box py={6}>
          <TablePaginationBoxStyles
            count={candidates?.count}
            rowsPerPage={params.pageSize}
            page={params.page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </>
  );
};

export default SubmissionsBase;

const useStyles = makeStyles((theme) => ({
  tablecell__candidate: {
    width: theme.spacing(30),
  },
  tablecell__actions: {
    width: theme.spacing(20),
  },
}));

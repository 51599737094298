import { useContext } from 'react';
import InterviewContext from './InterviewsContext';

const useInterviews = (): Interviews.IInterviewContext => {
  const context = useContext(
    InterviewContext
  ) as Interviews.IInterviewContext;
  if (context === undefined) {
    throw new Error(
      'useInterviews must be used within a InterviewsProvider.'
    );
  }
  return context;
};

export default useInterviews;

import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import useDropdown from 'common/components/use-dropdown/useDropdown';
import DropdownMenu from '../header/dropdowns/common/DropdownMenu';

const DropdownButton: React.FC<IDropdownButtonProps> = ({
  label,
  buttonProps,
  Menu,
}) => {
  const { anchorEl, handleClose, toggleDropdown } = useDropdown();

  return (
    <>
      <Button
        onClick={toggleDropdown}
        variant="outlined"
        color="primary"
        endIcon={
          anchorEl !== null ? <ExpandLessIcon /> : <ExpandMoreIcon />
        }
        size="small"
        {...(buttonProps || {})}
      >
        {label}
      </Button>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
      >
        <Menu handleClose={handleClose} />
      </DropdownMenu>
    </>
  );
};

const dropdownStyles = makeStyles((theme) => ({
  box_root: {
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(68, 146, 76, 0.04)',
    },
    '& .MuiPopover-paper': {
      // enable pointer events on the popover menu
      pointerEvents: 'initial',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 1.5px 3px',
      marginTop: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}));

export default DropdownButton;

import { useState } from 'react';
import InterviewQuestionContext from 'business/organizer/library/contexts/interview-question/InterviewQuestionContext';

const InterviewQuestionProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [interviewQuestionResponse, setInterviewQuestionResponse] =
    useState<InterviewQuestion.IInterviewQuestionResponseProps | null>(
      null
    );

  return (
    <InterviewQuestionContext.Provider
      value={{
        interviewQuestionResponse,
        setInterviewQuestionResponse,
      }}
    >
      {children}
    </InterviewQuestionContext.Provider>
  );
};

export default InterviewQuestionProvider;

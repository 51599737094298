import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

import TableBase from 'common/components/table/TableBase';
import TablePaginationBoxStyles from 'business/organizer/assessment/components/candidate-progress/common/TablePaginationBoxStyles';
import { getDayjsDate } from 'common/utils/dayjsUtils';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAsync from 'common/custom-hooks/useAsync';
import usePaginationParams from 'common/custom-hooks/usePaginationParams';
import { INTERVIEW_API_URL } from 'business/organizer/interviews/utils/constants';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import useInterviews from 'business/organizer/interviews/context/useInterviews';
import InterviewReport from 'business/organizer/interviews/components/report/InterviewReport';
import CopyInterviewLink from 'business/organizer/interviews/components/CopyInterviewLink';
import TypographyCell from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/TypographyCell';
import { sliceString } from 'common/utils/sliceString';
import ViewIcon from 'business/organizer/interviews/components/icons/ViewIcon';
import CopyIcon from 'business/organizer/interviews/components/icons/CopyIcon';

const organizerService = new OrganizerService();
const InterviewsListBase: React.FC<Interviews.IInterviewsListBaseProps> =
  ({ status, search, verdict }) => {
    const classes = useStyles();

    const {
      run,
      data: interviews,
      reset,
    }: IUseAsyncReturnProps<Interviews.IInterviewsListResponse> = useAsync();
    const { params, handlePageChange, handleRowsPerPageChange } =
      usePaginationParams({ resetFn: reset });

    const { isLoaded, setIsLoaded } = useInterviews();

    /**
     * Reset state
     * */
    useEffect(() => {
      if (!isLoaded) {
        setIsLoaded(true);
        reset();
      }
    }, [reset, search, isLoaded, setIsLoaded]);

    useEffect(() => {
      if (interviews === null) {
        run(
          organizerService.getInterviewsList(INTERVIEW_API_URL, {
            ...params,
            search,
            status,
            verdict,
          })
        );
      }
    }, [params, run, search, status, interviews, verdict]);

    return (
      <>
        <TableBase tableProps={{ 'aria-label': 'interviews' }}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Interview Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Verdict</TableCell>
              {(!status ||
                ['in_progress', 'not_started'].includes(status)) && (
                <TableCell align="center">Interview Link</TableCell>
              )}
              {(!status || status === 'completed') && (
                <TableCell align="center">Report</TableCell>
              )}
            </TableRow>
          </TableHead>
          {interviews !== null &&
            interviews?.results.length !== 0 &&
            interviews.results.map((interview, idx) => (
              <TableBody key={interview.candidate.id}>
                <TableRow>
                  <TableCell>{idx + 1}</TableCell>
                  <TypographyCell
                    title={sliceString(interview.title, 30)}
                    subtitle={interview.candidate.email}
                  />
                  <TableCell>
                    {interview.status.replace('_', ' ')}
                  </TableCell>
                  <TableCell>
                    {getDayjsDate({
                      date: interview.created_at,
                      format: 'DD[/]MM[/]YY hh[:]mm',
                    })}
                  </TableCell>
                  <TableCell>{interview.verdict}</TableCell>
                  {(!status ||
                    ['in_progress', 'not_started'].includes(
                      status
                    )) && (
                    <TableCell
                      align="center"
                      className={classes.tablecell_button}
                    >
                      {interview.status === 'completed' ? (
                        <Tooltip
                          title="Interview is completed."
                          placement="top"
                          arrow
                        >
                          <span>
                            <Button
                              startIcon={<CopyIcon color="#A3A3A3" />}
                              disabled
                            >
                              Copy
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <CopyInterviewLink interview={interview} />
                      )}
                    </TableCell>
                  )}
                  {(status === 'completed' || !status) && (
                    <TableCell align="center">
                      {interview.status === 'completed' ? (
                        <InterviewReport interview={interview} />
                      ) : (
                        <Tooltip
                          title="Interview is not completed."
                          placement="top"
                          arrow
                        >
                          <span className={classes.tablecell_button}>
                            <Button
                              startIcon={<ViewIcon color="#A3A3A3" />}
                              disabled
                            >
                              View
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            ))}
        </TableBase>
        {interviews === null && <GenericListSkeleton items={3} />}
        {interviews?.results.length === 0 && (
          <Box py={12}>
            <Typography align="center" variant="h5">
              There are no candidates
            </Typography>
          </Box>
        )}
        <Box py={6}>
          <TablePaginationBoxStyles
            count={interviews?.count}
            rowsPerPage={params.pageSize}
            page={params.page}
            onPageChange={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Box>
      </>
    );
  };

export default InterviewsListBase;

const useStyles = makeStyles((theme) => ({
  tablecell_button: {
    '& .MuiButton-root': {
      color: '#333333',
      fontSize: 14,
      fontWeight: 400,
    },
  },
}));

import Container, {
  ContainerProps,
} from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';

const HeaderStyles: React.FC<Omit<ContainerProps, 'className'>> = (
  props
) => {
  const classes = useStyles();

  return (
    <Container
      className={classes.container__header}
      maxWidth={false}
      {...props}
    />
  );
};

export default HeaderStyles;

const useStyles = makeStyles((theme) => ({
  container__header: {
    background: '#f1f6f9',
    paddingTop: theme.spacing(2),
    boxShadow: '0px 4px 8px rgba(221, 230, 237, 0.5)',
    position: 'sticky',
    top: 64,
    zIndex: 9,
  },
}));

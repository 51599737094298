import React from 'react';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import MyCheckbox from 'common/components/form/MyCheckbox';
import MyInput from 'common/components/form/MyInput';
import InputWithLabel from 'common/components/form/InputWithLabel';
import useTestCases from 'business/organizer/library/contexts/coding-question/custom-hooks/useTestCases';
import TestCaseFileField from 'business/organizer/library/components/question-create-update/coding/testcases/TestCaseFileField';
import TestCaseFormActions from 'business/organizer/library/components/question-create-update/coding/testcases/TestCaseFormActions';
import { testcasesFormValidation } from 'business/organizer/library/components/question-create-update/coding/utils/validationSchema';
import { testCasesWarningMessage } from 'business/organizer/library/components/question-create-update/coding/utils/constants';

interface ITestCaseModal {
  testCase: any;
  onCloseModal: any;
}

const TestCaseModal: React.FC<ITestCaseModal> = ({
  testCase,
  onCloseModal,
}) => {
  const { handleTestCaseForm } = useTestCases();

  const initialState = {
    score: testCase?.score || '',
    time_limit: testCase?.time_limit || '',
    input_file: '',
    output_file: '',
    is_sample: testCase?.is_sample || false,
    url: testCase?.url || '',
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={testcasesFormValidation(
        testCase ? true : false
      )}
      onSubmit={(data) => handleTestCaseForm(data, onCloseModal)}
    >
      <Form>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <InputWithLabel
              title="Score"
              downMD="subtitle2"
              upMD="subtitle2"
              isRequired={true}
            >
              <MyInput
                placeholder="score"
                name="score"
                type="number"
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={4}>
            <InputWithLabel
              title="Time Limit"
              downMD="subtitle2"
              upMD="subtitle2"
              isRequired={true}
            >
              <MyInput
                placeholder="seconds"
                name="time_limit"
                type="number"
              />
            </InputWithLabel>
          </Grid>
        </Grid>
        <Box py={8}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TestCaseFileField
                name="input_file"
                title="Input"
                tooltipTitle="Input data of the test case will be delivered to the program to the standard input (i.e., stdin stream)."
                fileLink={testCase?.input_file}
              />
            </Grid>
            <Grid item xs={6}>
              <TestCaseFileField
                name="output_file"
                title="Expected Output"
                tooltipTitle="Output data of the test case is used to verify the correctness of the solution. Usually, it is compared with the data generated by the user's program in the standard output. In the case of conformity, the test case is deemed solved."
                fileLink={testCase?.output_file}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <MyCheckbox
            name="is_sample"
            children={
              <Box>
                <Typography variant="body1">
                  Mark this as sample test
                </Typography>
              </Box>
            }
          />
          {!!testCase && (
            <Box py={2}>
              <Alert severity="warning">
                {testCasesWarningMessage}
              </Alert>
            </Box>
          )}
        </Box>
        <TestCaseFormActions onCloseModal={onCloseModal} />
      </Form>
    </Formik>
  );
};

export default TestCaseModal;

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import QuestionListItem from 'business/organizer/assessment/components/assessment-tests/test-detail/questions/QuestionListItem';
import useChallengeQuestion from 'business/organizer/library/contexts/challenge-question/custom-hooks/useChallengeQuestion';
import useCodingQuestion from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestion';
import useFreeText from 'business/organizer/library/contexts/free-text-question/custom-hooks/useFreeText';
import useFileUpload from 'business/organizer/library/contexts/file-upload-question/custom-hooks/useFileUpload';
import { QuestionType } from 'business/organizer/library/utils/utils';
import useMcq from 'business/organizer/library/contexts/mcq/useMcq';
import useProjectQuestion from 'business/organizer/library/contexts/project-question/useProjectQuestion';
import useSql from 'business/organizer/library/contexts/sql/useSql';

const QuestionsGroup: React.FC<IQuestionsGroupProps> = ({
  title,
  count,
  questions,
}) => {
  const { problemStatementResponse: challengeResponse } =
    useChallengeQuestion();
  const { problemStatementResponse: codingResponse } =
    useCodingQuestion();
  const { problemStatementResponse: freeTextResponse } =
    useFreeText();
  const { problemStatementResponse: fileUploadResponse } =
    useFileUpload();
  const { mcqResponse } = useMcq();
  const { projectQuestionResponse } = useProjectQuestion();
  const { sqlResponse } = useSql();

  const getIsUpdated = (questionItem: any) => {
    let response;
    switch (questionItem?.library_question.type) {
      case QuestionType.CHALLENGE:
        response = challengeResponse;
        break;
      case QuestionType.CODING:
        response = codingResponse;
        break;
      case QuestionType.FREE_TEXT:
        response = freeTextResponse;
        break;
      case QuestionType.FILE_UPLOAD:
        response = fileUploadResponse;
        break;
      case QuestionType.MCQ:
        response = mcqResponse;
        break;
      case QuestionType.PROJECT:
        response = projectQuestionResponse;
        break;
      case QuestionType.SQL:
        response = sqlResponse;
        break;
      default:
        throw new Error('This type of question does not exist.');
    }

    return response;
  };

  return (
    <Box>
      <Box py={4}>
        <Typography variant="body1" color="textSecondary">
          {title} ({count})
        </Typography>
      </Box>
      <Grid container spacing={8}>
        {questions !== null &&
          questions.map((questionItem: any, idx: number) => (
            <Grid key={questionItem.id} item xs={12}>
              <QuestionListItem
                item={questionItem}
                idx={idx}
                isUpdated={
                  getIsUpdated(questionItem)?.id ===
                  questionItem?.library_question.id
                }
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default QuestionsGroup;

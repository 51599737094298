import React from 'react';
import Box from '@material-ui/core/Box';

import QuestionSolution from 'hubs-candidate/common/components/candidate-progress/QuestionSolution';
import FileUploadSolutionBase from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/FileUploadSolutionBase';

const FileUploadSolution: React.FC<{
  question: IFileUploadQuestionSolutionBaseProps;
}> = ({ question }) => {
  return (
    <QuestionSolution
      question={question as IFileUploadQuestionSolutionBaseProps}
    >
      <Box py={2}>
        <FileUploadSolutionBase submission={question.submission} />
      </Box>
    </QuestionSolution>
  );
};

export default FileUploadSolution;

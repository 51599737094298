import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
import useUserContext from 'common/components/get-user/useUserContext';

const useHasPermission = () => {
  const { isSubscriptionExpired } = useSubscription();
  const { user } = useUserContext();
  const isViewer = user?.user_role.name === 'viewer';
  const isMaintainer = user?.user_role.name === 'maintainer';
  const isAdmin = user?.user_role.name === 'admin';
  const isSuperAdmin = user?.organization_member.is_superadmin;

  return {
    isViewer,
    isMaintainer,
    isAdmin,
    isSuperAdmin,
    isSubscriptionExpired,
    user,
  };
};

export default useHasPermission;

import BaseService from 'common/services/baseService';
import { AxiosRequestConfig, CancelToken } from 'axios';
import { LIBRARY_QUESTIONS_PAGE_SIZE } from '../utils/constants';
import objectToFormData from 'common/utils/objectToFormData';
import { APP_TYPE_PARAM } from 'business/organizer/common/utils/constants';

export class LibraryService extends BaseService {
  /**
   * Query the organizer services from here
   *  */

  async getLibraryQuestionList(
    url: UrlType,
    {
      search,
      query,
      difficulty,
      type,
      tags,
      programmingLanguages,
      page_size = LIBRARY_QUESTIONS_PAGE_SIZE,
      page = 1,
      types,
    }: IGetLibraryQuestionListParams,
    cancelToken?: CancelToken | undefined
  ) {
    const params: any = { page_size, page };

    if (query && !!query.length) {
      params.query = `{${query.join(',')}}`;
    }
    if (difficulty && !!difficulty.length) {
      params.difficulty = difficulty.join(',');
    }
    if (type && !!type.length) {
      params.type = type.join(',');
    }
    if (tags && !!tags.length) {
      params.tags = tags.map(({ name }) => name).join(',');
    }
    if (programmingLanguages && !!programmingLanguages.length) {
      params.programming_languages = programmingLanguages
        .map(({ name }) => name)
        .join(',');
    }
    if (!!types?.length) {
      params.types = types.join(',');
    }
    if (search) {
      params.search = search;
    }
    params.app_type = this.appTypeParam;
    return await this.get(url, params, cancelToken);
  }

  async getLibraryQuestionsCount(url: string, params?: {}) {
    return await this.get(
      `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`,
      params
    );
  }

  /**
   * create a new question
   * @param url API endpoint to POST
   * @param question
   * @param axiosConfig The configs we want to send
   */
  async createQuestion(
    url: string,
    question: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.post(
      `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`,
      question,
      axiosConfig
    );
  }

  /**
   * Patch question
   * @param url API endpoint to PATCH
   * @param question
   * @param axiosConfig The configs we want to send
   */
  async patchQuestion(
    url: string,
    question: any,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.patch(url, question, axiosConfig);
  }

  /**
   * Add datasets
   * @param url API endpoint to add datasets
   * @param question
   * @param axiosConfig The configs we want to send
   */
  async addFiles(
    url: string,
    files: any,
    axiosConfig?: AxiosRequestConfig
  ) {
    const formData = objectToFormData(files);
    return await this.post(
      `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`,
      formData,
      axiosConfig
    );
  }

  async patchFiles(
    url: string,
    files: any,
    axiosConfig?: AxiosRequestConfig
  ) {
    const formData = objectToFormData(files);
    return await this.patch(
      `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`,
      formData,
      axiosConfig
    );
  }

  async getQuestion(url: string) {
    return await this.get(
      !url.includes(`${APP_TYPE_PARAM}=${this.appTypeParam}`)
        ? `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`
        : `${url}`
    );
  }

  async getProgrammingLanguages(url: string) {
    return await this.get(url);
  }

  /**
   * Add testcase
   * @param url API endpoint to add testcase
   * @param testcase
   * @param axiosConfig The configs we want to send
   */
  async createTestCase(
    url: string,
    testcase: any,
    axiosConfig?: AxiosRequestConfig
  ) {
    const formData = objectToFormData(testcase);
    return await this.post(url, formData, axiosConfig);
  }

  /**
   * Update testcase
   * @param url API endpoint to PATCH
   * @param testcase
   * @param axiosConfig The configs we want to send
   */
  async patchTestCase(
    url: string,
    testcase: any,
    axiosConfig?: AxiosRequestConfig
  ) {
    const formData = objectToFormData(testcase);
    return await this.patch(url, formData, axiosConfig);
  }

  /**
   * add questions to test
   * @param url API endpoint to POST
   * @param add_questions
   * @param axiosConfig The configs we want to send
   */
  async addQuestionsToTest(
    url: string,
    questions: any,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.post(
      `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`,
      questions,
      axiosConfig
    );
  }
}

import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';

import FormError from 'business/organizer/library/components/common/FormError';
import SaveButton from 'business/organizer/library/components/common/SaveButton';

const InterviewFormActions = () => {
  const { isSubmitting } = useFormikContext<IMcqInitialStateProps>();

  return (
    <Box display="flex" alignItems="center" gridColumnGap={12}>
      <FormError />
      <Box position="relative">
        <Box display="flex">
          <SaveButton buttonText="Save" disabled={isSubmitting} />
        </Box>
      </Box>
    </Box>
  );
};

export default InterviewFormActions;

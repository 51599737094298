import React from 'react';

import ProblemStatement from 'business/organizer/library/pages/question-create-update/challenge/steps/ProblemStatement';
import Datasets from 'business/organizer/library/pages/question-create-update/challenge/steps/Datasets';
import OtherDetails from 'business/organizer/library/pages/question-create-update/challenge/steps/OtherDetails';
import { StepLabel } from 'business/organizer/library/pages/question-create-update/challenge/ChallengePage';

const RenderChallengeStep: React.FC<IRenderchallengeStepProps> = ({
  step,
}) => {
  switch (step) {
    case StepLabel.PROBLEM_STATEMENT:
      return <ProblemStatement />;
    case StepLabel.DATASET:
      return <Datasets />;
    case StepLabel.OTHER_DETAILS:
      return <OtherDetails />;
    default:
      return <div>Not Found</div>;
  }
};

export default RenderChallengeStep;

import BaseService from 'common/services/baseService';
import { TAGS_API_URL } from 'common/utils/constants';

export class FormService extends BaseService {
  /**
   * get tags
   */
  async getTags(type: string) {
    const response = await this.get(`${TAGS_API_URL}?type=${type}`);
    return response;
  }
}

import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/core';

import QuestionsSummaryTableHead from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionsSummaryTableHead';
import CommonQuestionSummaryTableItem from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/CommonQuestionSummaryTableItem';
import HttpError from 'common/components/http-error/HttpError';
import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';

const QuestionSolution: React.FC<IQuestionSolutionProps> = ({
  submission,
  responseError,
  children,
}) => {
  const classes = useStyles();

  const [expandDescription, setExpandDescription] = useState(false);

  const handleExpandDescription = () => {
    setExpandDescription(!expandDescription);
  };

  return (
    <Box bgcolor="#FAFAFA">
      {responseError !== null ? (
        <HttpError axiosError={responseError} />
      ) : submission === null ? (
        <Container maxWidth={false}>
          <Box py={2}>
            <GenericListSkeleton items={2} />
          </Box>
          <Box py={4}>
            <GenericListSkeleton items={1} />
          </Box>
        </Container>
      ) : (
        <>
          <Container
            maxWidth={false}
            className={classes.container__root}
          >
            <QuestionsSummaryTableHead showSolution={false}>
              <CommonQuestionSummaryTableItem
                idx={0}
                questionName={submission?.question_name}
                questionType={submission?.question_type}
                questionScore={submission?.question_score}
                submission={submission?.submission}
                showSolution={false}
              />
            </QuestionsSummaryTableHead>
          </Container>

          {!!submission?.question_description && (
            <Box py={4} className={classes.box__rich_text}>
              <Box py={2}>
                <Typography variant="h6">
                  Question Description
                </Typography>
              </Box>
              <Box>
                <Box className={classes.box__richtextrenderer}>
                  <RichTextRenderer
                    richTextHtml={
                      submission?.question_description.length < 500 ||
                      expandDescription
                        ? submission?.question_description
                        : `${submission?.question_description.slice(
                            0,
                            500
                          )}...`
                    }
                  />
                </Box>
                {submission?.question_description.length > 500 && (
                  <Box textAlign="center" py={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleExpandDescription}
                      endIcon={
                        expandDescription ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      }
                    >
                      {expandDescription ? 'View Less' : 'View More'}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}

          <Container
            maxWidth={false}
            className={classes.container__root}
          >
            <Box>{children}</Box>
          </Container>
        </>
      )}
    </Box>
  );
};

export default QuestionSolution;

const useStyles = makeStyles((theme) => ({
  box__rich_text: {
    background: '#FFFFFF',
    padding: theme.spacing(8),
    '& *': {
      maxWidth: '100%',
    },
  },
  container__root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  box__richtextrenderer: {
    wordBreak: 'break-word',
  },
}));

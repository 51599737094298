import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core';

import AuthPageButton from 'business/pre-subscription/auth/components/common/AuthPageButton';
import MyInput from 'common/components/form/MyInput';
import MySelect from 'common/components/form/MySelect';
import SelectCountryCode from 'common/components/form/SelectCountryCode';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import {
  getValidationSchema,
  getErrorMessage,
} from 'business/pre-subscription/auth/components/common/helpers';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import useUserContext from 'common/components/get-user/useUserContext';
import ContentSection from 'business/pre-subscription/auth/components/ContentSection';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import AuthPageBase from 'business/pre-subscription/common/components/AuthPageBase';
import useAlert from 'common/components/alert-provider/useAlert';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useEmailVerification from 'business/pre-subscription/auth/context/email-verification/useEmailVerification';
import {
  HIRING_APP_TYPE_PARAM,
  HUBS_APP_TYPE,
  HUBS_APP_TYPE_PARAM,
} from 'common/utils/constants';
import { getJobTitles } from 'business/organizer/settings/utils/utils';

const businessAuthService = new BusinessAuthService();
const SignUpSecondStep = () => {
  const classes = useStyles();
  const { enqueueAlert } = useAlert();
  const { isHubsUrl } = useCurrentUrl();

  const { user, setUser } = useUserContext();
  const { emailVerificationResponse } = useEmailVerification();
  const jobTitles = getJobTitles(user?.org_type as IOrgType);

  const initialState: SignUpFormSecondStep = {
    first_name: '',
    last_name: '',
    country_code: '',
    contact_number: '',
    current_role: '',
  };

  const validationSchema =
    getValidationSchema<SignUpFormSecondStep>(initialState);

  const handleSubmit: AuthSubmissionHandler = async (
    values: SignUpFormSecondStep
  ) => {
    const {
      first_name,
      last_name,
      country_code,
      current_role,
      contact_number,
    } = values;
    const data = {
      first_name,
      last_name,
      profile: {
        profile_pic: null,
        country: 'IN',
        country_code,
        current_role,
        contact_number,
      },
    };
    businessAuthService
      .patch(
        `${user?.url as string}?${
          isHubsUrl ||
          (emailVerificationResponse !== null &&
            emailVerificationResponse.app_type === HUBS_APP_TYPE)
            ? HUBS_APP_TYPE_PARAM
            : HIRING_APP_TYPE_PARAM
        }`,
        data
      )
      .then((response) => {
        if (response) {
          setUser(response?.data);
          window.location.reload();
        }
      })
      .catch((error) => {
        enqueueAlert(getErrorMessage(error?.response.data), {
          alertProps: { severity: 'error' },
        });
      });
  };

  return (
    <AuthPageBase
      contentSection={<ContentSection next={true} />}
      showFooter={false}
    >
      <FormSectionBase logo={false} title="You're Almost Done">
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <FieldStyles>
                <Box display="flex" gridColumnGap={20}>
                  <MyInput
                    placeholder="First Name"
                    name="first_name"
                    type="text"
                  />
                  <MyInput
                    placeholder="Last Name(optional)"
                    name="last_name"
                    type="text"
                  />
                </Box>
              </FieldStyles>
              <FieldStyles>
                <Box display="flex" gridColumnGap={20}>
                  <SelectCountryCode
                    placeholder="Ex: 91"
                    name="country_code"
                    type="number"
                  />
                  <MyInput
                    placeholder="Enter Phone number"
                    name="contact_number"
                    type="tel"
                  />
                </Box>
              </FieldStyles>
              <FieldStyles>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.form_control}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    margin="dense"
                    shrink={false}
                  >
                    {/* @ts-ignore */}
                    {!values.current_role ? 'Job Title' : ''}
                  </InputLabel>

                  <MySelect
                    name="current_role"
                    placeholder="Job title"
                    type="text"
                  >
                    {jobTitles.map((jobTitle: IJobTitleProps) => (
                      <MenuItem
                        key={jobTitle.id}
                        value={jobTitle.value}
                      >
                        {jobTitle.label}
                      </MenuItem>
                    ))}
                  </MySelect>
                </FormControl>
              </FieldStyles>
              <AuthPageButton>Complete Sign Up</AuthPageButton>
            </Form>
          )}
        </Formik>
      </FormSectionBase>
    </AuthPageBase>
  );
};

export default SignUpSecondStep;

const useStyles = makeStyles((theme) => ({
  form_control: {
    '& .MuiFormLabel-root': {
      color: '#c0c0c0',
    },
  },
}));

import React from 'react';

import FileFieldContext from 'common/components/form/file-field/FileFieldContext';

const useFileField = (): IFileFieldContext => {
  const context = React.useContext(FileFieldContext);
  if (!context) {
    throw new Error(
      'useFileField must be used within a FileField component.'
    );
  }
  return context;
};

export default useFileField;

import React, { useState, useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';

import AddQuestionsModal from 'business/organizer/library/components/question-list/AddQuestionsModal';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import QuestionItemActions from 'business/organizer/assessment/components/common/question/QuestionItemActions';
import QuestionItemTags from 'business/organizer/assessment/components/common/question/QuestionItemTags';
import QuestionItemPreview from 'business/organizer/assessment/components/common/question/QuestionItemPreview';
import QuestionItemStatsList from 'business/organizer/assessment/components/common/question/QuestionItemStatsList';
import useIncompleteQuestions from 'business/organizer/library/contexts/incomplete-questions/useIncompleteQuestions';
import IncompleteLabel from 'business/organizer/common/components/IncompleteLabel';
import SubscribeTooltip from 'business/organizer/common/components/SubscribeTooltip';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { REFRESH_PAGE } from 'business/organizer/library/contexts/library/libraryActionTypes';
import QuestionItemType from 'business/organizer/assessment/components/common/question/QuestionItemType';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import { QuestionType } from 'business/organizer/library/utils/utils';

const QuestionListItem: React.FC<IQuestionListItemProps> = ({
  questionItem,
}) => {
  const {
    question,
    id,
    is_complete,
    is_shared,
    can_edit,
    type,
    url: questionUrl,
    clone_url: cloneUrl,
  } = questionItem || {};

  const classes = useStyles();

  const { testId } = useTestId();
  const { setIncompleteQuestions } = useIncompleteQuestions();
  const { isSubscriptionExpired, isViewer } = useHasPermission();

  const {
    addQuestionsToTest,
    setAddQuestionsToTest,
    unCheck,
    handleAddQuestionsToTest,
  } = useAddQuestionsToTest();
  const [checkedQuestion, setCheckedQuestion] = useState(
    addQuestionsToTest?.includes(id) ? true : false
  );

  const [openTestsModal, setOpenTestsModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const { dispatch } = useLibrary();

  useEffect(() => {
    if (!unCheck) {
      setCheckedQuestion(false);
    }
  }, [unCheck]);

  const closeAddQuestionsModal = () => setOpenTestsModal(false);

  const handleSnackbar = (show: boolean) => {
    setShowSnackbar(show);
  };

  const handleAddQuestionClick = () => {
    if (testId) {
      handleAddQuestionsToTest(testId, id);
    } else {
      setOpenTestsModal(true);
    }
  };

  const handleCheckedQuestion = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
      const { checked } = event.target;
      setCheckedQuestion(checked);
      if (checked) {
        setAddQuestionsToTest((addQuestionsToTest: number[]) => [
          ...(addQuestionsToTest || []),
          id,
        ]);

        if (!is_complete) {
          setIncompleteQuestions((questions) => [
            ...(questions || []),
            { id: id, name: question?.name },
          ]);
        }
      } else {
        setAddQuestionsToTest((addQuestionsToTest: any) =>
          addQuestionsToTest.filter(
            (questionId: number) => questionId !== id
          )
        );
        if (!is_complete) {
          setIncompleteQuestions((questions) =>
            questions.filter((question) => question.id !== id)
          );
        }
      }
    },
    [
      is_complete,
      question?.name,
      setAddQuestionsToTest,
      setIncompleteQuestions,
    ]
  );

  const onCloneQuestion = () => {
    dispatch({ type: REFRESH_PAGE, payload: { list: 'my' } });
  };

  return (
    <Box className={classes.box__list_item} p={3}>
      <Grid container>
        <Grid item xs={10}>
          <Box display="flex" alignItems="center">
            <Box>
              <HasPermission
                isViewer={isViewer}
                isSubscriptionExpired={isSubscriptionExpired}
                isInterviewQuestion={type === QuestionType.INTERVIEW}
                tooltipProps={{ placement: 'top', arrow: true }}
              >
                <Checkbox
                  name={question?.name}
                  checked={checkedQuestion}
                  color="secondary"
                  onChange={(event) =>
                    handleCheckedQuestion(event, questionItem.id)
                  }
                  disabled={
                    isViewer ||
                    isSubscriptionExpired ||
                    type === QuestionType.INTERVIEW ||
                    type === QuestionType.PROJECT
                  }
                />
              </HasPermission>
            </Box>
            <Box width="100%">
              <Typography variant="h6">
                {question?.name.length > 60
                  ? `${question?.name.slice(0, 60)}...`
                  : question?.name}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="flex-end">
            <HasPermission
              isViewer={isViewer}
              isSubscriptionExpired={isSubscriptionExpired}
              isInterviewQuestion={type === QuestionType.INTERVIEW}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <Tooltip title="Add to test" placement="top">
                <IconButton
                  onClick={handleAddQuestionClick}
                  className={classes.icon_button__question_action}
                  disabled={
                    isViewer ||
                    isSubscriptionExpired ||
                    type === QuestionType.INTERVIEW ||
                    type === QuestionType.PROJECT
                  }
                >
                  <AddIcon
                    color={
                      isViewer ||
                      isSubscriptionExpired ||
                      type === QuestionType.INTERVIEW
                        ? 'disabled'
                        : 'primary'
                    }
                  />
                </IconButton>
              </Tooltip>
            </HasPermission>
            <AddQuestionsModal
              open={openTestsModal}
              closeAddQuestionsModal={closeAddQuestionsModal}
              questionId={id}
            />

            {can_edit &&
              (isViewer || isSubscriptionExpired ? (
                <HasPermission
                  isViewer={isViewer}
                  isSubscriptionExpired={isSubscriptionExpired}
                  tooltipProps={{ placement: 'top', arrow: true }}
                >
                  <IconButton
                    style={{ padding: 9 }}
                    disabled={isViewer || isSubscriptionExpired}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </HasPermission>
              ) : (
                <>
                  <QuestionItemActions
                    is_shared={is_shared}
                    can_edit={can_edit}
                    id={id}
                    type={type}
                    questionUrl={questionUrl}
                    cloneUrl={cloneUrl}
                    onCloneQuestion={onCloneQuestion}
                    questionName={question?.name}
                    handleSnackbar={handleSnackbar}
                  />
                  <Snackbar
                    open={showSnackbar}
                    message="Please wait we are in the process of cloning..."
                    ContentProps={{
                      className: classes.snackbar_content,
                    }}
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'top',
                    }}
                  />
                </>
              ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" gridColumnGap={16} ml={8}>
            <QuestionItemStatsList
              score={question?.score}
              difficulty={question?.difficulty}
              time_duration={question?.display_time_duration}
              type={type}
            />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box
            display="flex"
            flexWrap="wrap"
            gridRowGap={8}
            gridColumnGap={8}
            maxWidth="75%"
            mt={2}
            ml={8}
          >
            <QuestionItemType type={type} />
            {!!questionItem?.tags.length && (
              <QuestionItemTags tags={questionItem?.tags} />
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="flex-end" p={2}>
            {isSubscriptionExpired ? (
              <SubscribeTooltip>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  disabled={isSubscriptionExpired}
                >
                  View
                </Button>
              </SubscribeTooltip>
            ) : (
              <QuestionItemPreview
                id={id}
                type={type}
                questionUrl={questionUrl}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      {!is_complete && (
        <IncompleteLabel
          label="Incomplete"
          hint="This questions is missing one or more fields which would help candidates understand and answer the question better"
        />
      )}
    </Box>
  );
};

export default QuestionListItem;

const useStyles = makeStyles((theme) => ({
  box__list_item: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #DDE6ED',
    borderRadius: theme.spacing(1),
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#233D4D',
    },
  },
  icon_button__question_action: {
    padding: '9px',
  },
  snackbar_content: {
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
  },
}));

import React, { useMemo, useRef, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import GenericNavbar from 'business/organizer/common/components/GenericNavbar';
import CreateQuestion from 'business/organizer/library/components/CreateQuestion';
import SearchBar from 'common/components/search-bar/SearchBar';
import QuestionList from 'business/organizer/library/pages/question-list/tab/QuestionList';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import {
  CLEAR_FILTERS,
  SEARCH_QUESTIONS,
} from 'business/organizer/library/contexts/library/libraryActionTypes';
import useLibraryQuestionsCount from 'business/organizer/library/custom-hooks/useLibraryQuestionsCount';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import {
  MY_LIBRARY_QUESTION_API_URL,
  SHARED_LIBRARY_QUESTION_API_URL,
  HTTP_DEBOUNCE_TIME,
} from 'business/organizer/library/utils/constants';
import debounce from 'common/utils/debounce';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import Filters from 'business/organizer/library/components/filter/Filters';
import QuestionsActions from 'business/organizer/library/components/question-list/QuestionsActions';
import ActiveFilters from 'business/organizer/library/components/filter/ActiveFilters';

export enum ListType {
  SHARED = 'shared',
  MY = 'my',
}

const QuestionListPage = () => {
  const classes = useStyles();
  const { url } = useCurrentUrl();
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const { state, dispatch } = useLibrary();
  const { addQuestionsToTest } = useAddQuestionsToTest();
  const { testId } = useTestId();
  const { sharedLibraryCount, myLibraryCount } =
    useLibraryQuestionsCount(
      testId
        ? {
            types: [
              'challenge',
              'coding',
              'free-text',
              'file-upload',
              'mcq',
              // 'project',
            ].join(','),
          }
        : undefined
    );

  const handleSearch = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
          type: SEARCH_QUESTIONS,
          payload: event.target.value,
        });
      }, HTTP_DEBOUNCE_TIME),
    [dispatch]
  );

  const hasFilters =
    !!state.globalParams.type.length ||
    !!state.globalParams.difficulty.length ||
    !!state.globalParams.tags.length ||
    !!state.globalParams.programmingLanguages.length;

  const tabs = useMemo(
    () => [
      {
        id: 1,
        label: 'Skillspace Library',
        route: `${url}/skillspace`,
        count: sharedLibraryCount,
        badge: true,
      },
      {
        id: 2,
        label: 'My Library',
        route: `${url}/my`,
        count: myLibraryCount,
        badge: true,
      },
    ],
    [sharedLibraryCount, myLibraryCount, url]
  );

  // clear filters on unmount
  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_FILTERS });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.container__header}
        style={{ top: testId ? '0px' : '64px' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <GenericNavbar tabs={tabs} />
          </Box>
          <Box width="350px">
            <SearchBar handleSearch={handleSearch} />
          </Box>
          {!testId && <CreateQuestion />}
        </Box>
      </Container>
      <Box pt={6}>
        {addQuestionsToTest !== null &&
          addQuestionsToTest.length !== 0 && (
            <QuestionsActions testId={testId} />
          )}
      </Box>
      <Box display="flex" width="100%" pt={2} pb={10}>
        <Container maxWidth={false}>
          <Switch>
            {/* Why keys? https://stackoverflow.com/questions/49001001/using-same-component-for-different-route-path-in-react-router-v4 */}
            <Route key="shared" path={`${url}/skillspace`}>
              <>
                {hasFilters && (
                  <ActiveFilters
                    questionsCount={sharedLibraryCount}
                  />
                )}
                <QuestionList
                  breadcrumbLabel="Skillspace Library"
                  list={ListType.SHARED}
                  apiUrl={SHARED_LIBRARY_QUESTION_API_URL}
                  navbarRef={navbarRef}
                />
              </>
            </Route>
            <Route key="my" path={`${url}/my`}>
              <>
                {hasFilters && (
                  <ActiveFilters questionsCount={myLibraryCount} />
                )}
                <QuestionList
                  breadcrumbLabel="My Library"
                  list={ListType.MY}
                  apiUrl={MY_LIBRARY_QUESTION_API_URL}
                  navbarRef={navbarRef}
                />
              </>
            </Route>
            <Redirect from={url} to={`${url}/skillspace`} />
          </Switch>
        </Container>
        <Container
          className={classes.container__filter}
          maxWidth={false}
        >
          <Box className={classes.box__filter}>
            <Box>
              <Filters />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default QuestionListPage;

const useStyles = makeStyles((theme) => ({
  container__header: {
    background: '#f1f6f9',
    paddingTop: theme.spacing(2),
    boxShadow: '0px 4px 8px rgba(221, 230, 237, 0.5)',
    position: 'sticky',
    zIndex: 9,
  },
  container__filter: {
    width: '400px !important',
    paddingLeft: '0px !important',
  },
  box__filter: {
    background: '#FFF',
    position: 'sticky',
    padding: '24px',
    border: '1px solid #eee',
    borderRadius: '5px',
    top: theme.spacing(16),
  },
}));

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';

const useToggleDrawer = () => {
  const history = useHistory();
  const { url } = useCurrentUrl();

  const { setDrawerList } = useDrawer();

  const toggleDrawer = useCallback(
    (
      open: boolean,
      setDrawer: React.Dispatch<React.SetStateAction<boolean>>,
      drawerName: string,
      drawerUrl: string = url
    ) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      if (open) {
        setDrawerList((drawerList) => [
          ...(drawerList || []),
          drawerName,
        ]);
      } else {
        setDrawerList((drawerList) =>
          drawerList.slice(0, drawerList.length - 1)
        );
      }

      history.push(drawerUrl);
      setDrawer(open);
    },
    [history, setDrawerList, url]
  );

  return { toggleDrawer };
};

export default useToggleDrawer;

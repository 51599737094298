export const LIBRARY_QUESTION_API_URL = '/api/libraries/';
export const SHARED_LIBRARY_QUESTION_API_URL =
  '/api/libraries/shared/';
export const MY_LIBRARY_QUESTION_API_URL = '/api/libraries/my/';
export const LIBRARY_QUESTIONS_COUNT_URL = '/api/libraries/counts/';
export const PROJECT_FRAMEWORK_NAMES_API =
  '/api/libraries/project-frameworks/';

export const HTTP_DEBOUNCE_TIME = 600;
export const LIBRARY_QUESTIONS_PAGE_SIZE = 10;

// coding
export const PROGRAMMING_LANGUAGES_API_URL =
  '/api/libraries/programming-languages/';

// frontend urls
export const FRONTEND_MY_LIBRARY_URL = '/library/my';

export const LIBRARY_QUESTION_TAG_TYPE = 'library_question';

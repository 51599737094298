import React from 'react';
import { PASSWORD_CHANGE_IFRAME_URL } from 'business/organizer/settings/utils/constants';

const PasswordUpdate = () => {

  return (
    <>
      <iframe
        src={PASSWORD_CHANGE_IFRAME_URL}
        title="Password change"
        style={{
          width: '100%',
          height: '300px',
          border: 'none',
          marginTop:'16px',
        }}
      />
    </>
  );
};

export default PasswordUpdate;

import { useState, useEffect } from 'react';

import useAlert from 'common/components/alert-provider/useAlert';
import { FormService } from 'common/components/form/services/formService';

const formService = new FormService();

const useTags = ({ type }: { type: string }) => {
  const { enqueueAlert } = useAlert();

  const [tagSuggestions, setTagSuggestions] = useState<any[] | null>(
    null
  );

  useEffect(() => {
    let mounted = true;
    formService
      .getTags(type)
      .then((response) => {
        if (mounted) {
          setTagSuggestions(response?.data);
        }
      })
      .catch((error) => {
        enqueueAlert(`Could not load tags: ${error.message}`, {
          alertProps: { severity: 'error' },
        });
      });
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  return { tagSuggestions };
};

export default useTags;

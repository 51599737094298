import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const QuestionListItemSkeleton: React.FC = (): JSX.Element => {
  return (
    <Box
      p={6}
      boxShadow="0px 3px 6px #DDE6ED"
      borderRadius={5}
      bgcolor="#FFFFFF"
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            height="100%"
            flexDirection="column"
            gridRowGap={8}
          >
            <Skeleton width="80%" component="div" />
            <Skeleton width="40%" component="div" />
            <Skeleton width="40%" component="div" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuestionListItemSkeleton;

import BaseService from 'common/services/baseService';
import { INTERVIEW_API_ROOT_URL } from 'interview/utils/constants';

export class InterviewService extends BaseService {
  async getInterviewDetails(roomId: string) {
    return await this.get(`${INTERVIEW_API_ROOT_URL}${roomId}/`);
  }

  async updateInterviewDetails(roomId: string, data: {}) {
    return await this.patch(
      `${INTERVIEW_API_ROOT_URL}${roomId}/`,
      data
    );
  }
}

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import NavigationButton from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/NavigationButton';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import CandidateDetailsDrawerBase from '../common/CandidateDetailsDrawerBase';

const CandidateDetailsDrawer: React.FC<ICandidateSubmissionDetailProps> =
  ({ candidateList, closeDrawer, idx }) => {
    const { url } = useCurrentUrl();

    const history = useHistory();

    const { setCandidateDetails } = useCandidateProgressDetail();

    const [move, setMove] = useState({
      current: idx,
      prev: idx - 1,
      next: idx + 1,
    });

    const onNext = () => {
      setCandidateDetails(null);
      history.push(`${url}/${candidateList[move.next]?.id}`);
      setMove({
        prev: move.current,
        current: move.next,
        next: move.next + 1,
      });
    };

    const onPrev = () => {
      setCandidateDetails(null);
      history.push(`${url}/${candidateList[move.prev]?.id}`);
      setMove({
        prev: move.prev - 1,
        current: move.prev,
        next: move.current,
      });
    };

    return (
      <CandidateDetailsDrawerBase
        apiUrl={candidateList[move.current]?.detail_url}
        closeDrawer={closeDrawer}
        navigationEl={
          <>
            <NavigationButton
              title="next"
              disabled={candidateList.length - 1 === move.current}
              onClick={onNext}
              Icon={ArrowForwardIcon}
              top={100}
            />
            <NavigationButton
              title="previous"
              disabled={move.current === 0}
              onClick={onPrev}
              Icon={ArrowBackIcon}
              top={200}
            />
          </>
        }
      />
    );
  };

export default CandidateDetailsDrawer;

import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import LanguageListItem from 'business/organizer/library/components/question-create-update/coding/languages/LanguageListItem';
import HttpError from 'common/components/http-error/HttpError';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import { PROGRAMMING_LANGUAGES_API_URL } from 'business/organizer/library/utils/constants';
import useAsync from 'common/custom-hooks/useAsync';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import theme from 'business/common/theme/theme';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const libraryService = new LibraryService();

const LanguagesListBase = () => {
  const classes = useStyles();
  const [selectAllLanguages, setSelectAllLanguages] = useState(false);
  const {
    run,
    data: languages,
    error: responseError,
    status,
  }: IUseAsyncReturnProps<IProgrammingLanguage[]> = useAsync();

  const { values, setFieldValue } =
    useFormikContext<IUseFormikContextLanguageProps>();
  const { isViewer } = useHasPermission();

  useEffect(() => {
    run(
      libraryService.getProgrammingLanguages(
        PROGRAMMING_LANGUAGES_API_URL
      )
    );
  }, [run]);

  useEffect(() => {
    if (selectAllLanguages || values.languages.length) {
      setSelectAllLanguages(
        languages !== null &&
          values.languages.length === languages?.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllLanguages, values.languages, languages]);

  const handleSetSelectAllLanguages = () => {
    setSelectAllLanguages(true);
    setFieldValue('languages', [
      ...(languages.map(
        (language: IProgrammingLanguage) => language.id
      ) || []),
    ]);
  };

  const handleClearAllLanguages = () => {
    setSelectAllLanguages(false);
    setFieldValue('languages', []);
  };

  return (
    <GenericResponseHandler
      status={status}
      errorPlaceholder={
        <HttpError
          axiosError={responseError}
          message="Not able to load programming languages. Please try again."
        />
      }
      skeleton={<GenericListSkeleton items={1} />}
    >
      <Grid container className={classes.grid_languagelist}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            pb={4}
            color={theme.palette.info.main}
          >
            <HasPermission
              isViewer={isViewer}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <Button
                onClick={handleSetSelectAllLanguages}
                disabled={isViewer || selectAllLanguages}
                color="inherit"
              >
                Select all
              </Button>
            </HasPermission>
            <HasPermission
              isViewer={isViewer}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <Button
                onClick={handleClearAllLanguages}
                disabled={isViewer || !selectAllLanguages}
                color="inherit"
              >
                Clear all
              </Button>
            </HasPermission>
          </Box>
        </Grid>
        {languages?.map((language: IProgrammingLanguage) => (
          <Grid item xs={4} key={language.id}>
            <LanguageListItem
              id={language.id}
              name={language.name}
              selectAllLanguages={selectAllLanguages}
            />
          </Grid>
        ))}
      </Grid>
    </GenericResponseHandler>
  );
};

export default LanguagesListBase;

const useStyles = makeStyles((theme) => ({
  grid_languagelist: {
    '& .MuiIconButton-colorSecondary': {
      color: '#233D4D',
    },
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import ProctorCheckbox from 'business/organizer/assessment/components/assessment-tests/test-detail/common/ProctorCheckbox';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const ProctorCheckboxItem: React.FC<IProctorCheckboxItemProps> = ({
  disabled,
  name,
  description,
  display_name,
}) => {
  const { isViewer } = useHasPermission();

  return (
    // this color is used for showing disabled checkbox
    <Box py={2} color="rgba(0, 0, 0, 0.38)">
      {disabled ? (
        <Tooltip
          title="Please upgrade your plan to access this feature"
          placement="top-start"
          arrow
        >
          <span>
            <ProctorCheckbox
              disabled={disabled}
              name={name}
              description={description}
              display_name={display_name}
            />
          </span>
        </Tooltip>
      ) : (
        <HasPermission
          isViewer={isViewer}
          tooltipProps={{ placement: 'top', arrow: true }}
        >
          <ProctorCheckbox
            disabled={disabled || isViewer}
            name={name}
            description={description}
            display_name={display_name}
          />
        </HasPermission>
      )}
    </Box>
  );
};

export default ProctorCheckboxItem;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const SidebarItem: React.FC<ICandidateListSidebarItemProps> = ({
  routes,
  title,
  value,
  onChange,
  onSelectedRadio,
  isLoaded,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (value === null || !isLoaded) {
      routes.map(({ route, value }) => {
        if (pathname.startsWith(route)) {
          return onSelectedRadio(value);
        } else {
          return undefined;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, value, isLoaded]);

  return (
    <Box px={4} pb={4}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <Typography variant="h6">{title}</Typography>
        </FormLabel>
        <Box pl={4} pr={1}>
          <RadioGroup
            aria-label={title}
            name={title}
            value={value}
            onChange={onChange}
          >
            {routes.map(({ value, label, id, count }) => (
              <Box
                key={id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={200}
              >
                <FormControlLabel
                  value={value}
                  control={<Radio color="primary" size="small" />}
                  label={label}
                />
                <Typography>({count})</Typography>
              </Box>
            ))}
          </RadioGroup>
        </Box>
      </FormControl>
    </Box>
  );
};

export default SidebarItem;

import React from 'react';

import GenericError from 'common/components/generic-error/GenericError';

import { SERVER_ERROR_IMAGE } from 'common/utils/constants';

const HttpError: React.FC<IHttpErrorProps> = ({
  name,
  message,
  axiosError,
}): JSX.Element => {
  return (
    <GenericError
      imageUrl={SERVER_ERROR_IMAGE}
      heading={`Error ${axiosError?.response?.status}` || name}
      description={message || axiosError?.response?.data?.detail}
    />
  );
};

export default HttpError;

import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import Box from '@material-ui/core/Box';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core';

import useDropdown from 'common/components/use-dropdown/useDropdown';
import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import useAlert from 'common/components/alert-provider/useAlert';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import {
  API_BUSINESS_LOGOUT_URL,
  FRONTEND_BUSINESS_LOGIN_URL,
} from 'business/pre-subscription/auth/utils/constants';
import { getErrorMessage } from 'business/pre-subscription/auth/components/common/helpers';
import useUserContext from 'common/components/get-user/useUserContext';
import { FRONTEND_SETTINGS_URL } from 'common/utils/constants';
import useHasPermission from '../../custom-hooks/useHasPermission';
import HasPermission from '../HasPermission';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const businessAuthService = new BusinessAuthService();

const OrganizerProfile = () => {
  const { enqueueAlert } = useAlert();
  const classes = useStyles();
  const { anchorEl, handleClick, handleClose } = useDropdown();
  const history = useHistory();
  const { user } = useUserContext();
  const { isViewer } = useHasPermission();
  const { url, isHubsUrl } = useCurrentUrl();
  const [error, setError] =
    useState<IBusinessFormSuccessProps | null>(null);

  const handleLogout = async () => {
    try {
      const response = await businessAuthService.authPost(
        API_BUSINESS_LOGOUT_URL,
        {}
      );
      enqueueAlert(
        `${response?.data?.detail || 'You logged out successfully.'}`,
        {
          alertProps: { severity: 'success' },
        }
      );
      history.push(`${url}${FRONTEND_BUSINESS_LOGIN_URL}`);
    } catch (error) {
      setError((error as AxiosError)?.response?.data);
    }
  };

  const errorMessage = getErrorMessage(error);
  const appSwitchText = (
    subscription_list?: Array<String> | null
  ) => {
    if (isHubsUrl) {
      return subscription_list?.includes('hiring')
        ? 'Switch to Assessments'
        : 'Try Assessments';
    } else {
      return subscription_list?.includes('hubs')
        ? 'Switch to Hubs'
        : 'Try Hubs';
    }
  };

  const handleSwitch = () => {
    if (isHubsUrl) {
      window.location.href = '/dashboard';
    } else {
      window.location.href = '/hubs/dashboard';
    }
  };

  return (
    <>
      <NotificationDialog
        open={error !== null}
        variant="error"
        agreeText="Try Again"
        onAgree={() => setError(null)}
        content={errorMessage}
      />
      <Box
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        onClick={handleClick}
        className={classes.profile}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {isViewer && (
            <HasPermission
              isViewer={isViewer}
              tooltipProps={{ arrow: true }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gridColumnGap={6}
                bgcolor="#F2C94C"
                borderRadius={2}
                p={2}
                m={4}
              >
                <InfoOutlinedIcon fontSize="small" />
                <Typography>View Only</Typography>
              </Box>
            </HasPermission>
          )}
          <Box display="flex" alignItems="center" gridColumnGap={8}>
            <Avatar>
              {user?.first_name.charAt(0).toUpperCase()}
            </Avatar>
          </Box>
          {anchorEl !== null ? (
            <IconButton onClick={handleClick}>
              <ExpandLessIcon className={classes.icon__styles} />
            </IconButton>
          ) : (
            <IconButton
              onMouseEnter={handleClick}
              onClick={handleClick}
            >
              <ExpandMoreIcon className={classes.icon__styles} />
            </IconButton>
          )}
        </Box>
        <DropdownMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          useStyles={dropdownStyles}
          MenuListProps={{
            onMouseLeave: handleClose,
          }}
        >
          <Link to={`${url}${FRONTEND_SETTINGS_URL}`}>
            <MenuItem>Settings</MenuItem>
          </Link>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>

          <MenuItem
            onClick={handleSwitch}
            className={classes.box_color}
          >
            {!!user && appSwitchText(user.subscription_list)}
          </MenuItem>
        </DropdownMenu>
      </Box>
    </>
  );
};

export default OrganizerProfile;

// these are the default styles for dropdown menu
const dropdownStyles = makeStyles((theme) => ({
  box_root: {
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(68, 146, 76, 0.04)',
    },

    // enable pointer events on the popover menu
    '& .MuiPopover-paper': {
      pointerEvents: 'initial',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 1.5px 3px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  profile: {
    '& .MuiBox-root:hover': {
      cursor: 'pointer',
    },
  },
  icon__styles: {
    color: '#ffffff',
  },
  box_color: {
    justifyContent: 'center',
    backgroundColor: '#c0e4fc',
    margin: '10px 25px 0 5px',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#dceffc !important',
    },
  },
}));

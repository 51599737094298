import React from 'react';
import Box from '@material-ui/core/Box';

import QuestionSolution from 'hubs-candidate/common/components/candidate-progress/QuestionSolution';
import FreeTextSolutionBase from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/FreeTextSolutionBase';

const FreeTextSolution: React.FC<{
  question: IFreeTextQuestionSolutionBaseProps;
}> = ({ question }) => {
  return (
    <QuestionSolution
      question={question as IFreeTextQuestionSolutionBaseProps}
    >
      <Box py={2}>
        <FreeTextSolutionBase submission={question.submission} />
      </Box>
    </QuestionSolution>
  );
};

export default FreeTextSolution;

import React from 'react';

const PricingFeatureIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M13.4141 7.198C13.4669 7.53802 13.5025 7.88417 13.5025 8.23953C13.5025 11.9681 10.4798 14.9908 6.75125 14.9908C3.02272 14.9908 0 11.9681 0 8.23953C0 4.511 3.02272 1.48828 6.75125 1.48828C8.19172 1.48828 9.52417 1.94307 10.6203 2.71149L9.74758 3.60572C8.88342 3.04536 7.856 2.71578 6.75125 2.71578C3.70582 2.71578 1.2275 5.1941 1.2275 8.23953C1.2275 11.285 3.70582 13.7633 6.75125 13.7633C9.75494 13.7633 12.2007 11.3525 12.2689 8.36535L13.4141 7.198ZM12.8298 2.20453L7.05813 8.12046L4.69948 5.90237L2.79993 7.80377L7.05813 11.922L14.73 4.1047L12.8298 2.20453Z"
        fill="#2F80ED"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="14.73"
          height="14.73"
          fill="white"
          transform="translate(0 0.874023)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PricingFeatureIcon;

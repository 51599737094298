import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import CustomModal from 'common/components/custom-modal/CustomModal';
import HttpError from 'common/components/http-error/HttpError';
import RoleItem from 'business/organizer/assessment/components/assessment-tests/test-list/choose-role/RoleItem';
import CustomRole from 'business/organizer/assessment/components/assessment-tests/test-list/choose-role/CustomRole';
import useTests from 'business/organizer/assessment/contexts/assessment-tests/useTests';

import { ROLES_API_URL } from 'business/organizer/common/utils/constants';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const organizerService = new OrganizerService();

const RoleList: React.FC<IRoleListProps> = ({
  openRolesModal,
  closeRolesModal,
  addQuestionToTest,
}) => {
  const [error, setError] = useState<AxiosError | null>(null);
  const { selectedRole, handleTestCreation, roles, setRoles } =
    useTests();
  const { isHubsUrl } = useCurrentUrl();

  useEffect(() => {
    let mounted = true;

    organizerService
      .authGet(ROLES_API_URL)
      .then((response) => {
        if (mounted) {
          setRoles(response?.data);
        }
      })
      .catch((error) => setError(error));

    return () => {
      mounted = false;
    };
  }, [setRoles]);

  return (
    <CustomModal open={openRolesModal}>
      <Container maxWidth="xl">
        <Box py={4}>
          <Box textAlign="center" py={4}>
            <Typography variant="h3" align="center">
              {isHubsUrl ? 'Choose Test type' : 'Choose Role to Hire'}
            </Typography>
          </Box>
          {error !== null ? (
            <HttpError
              axiosError={error}
              message="Not able to load the roles. Please try again."
            />
          ) : roles === null ? (
            <p>skeleton</p>
          ) : (
            <Grid container spacing={4}>
              {roles.map((role: IRoleItemProps) => (
                <Grid item xs={4} key={role.id}>
                  <RoleItem id={role.id} name={role.name} />
                </Grid>
              ))}
              <Grid item xs={4}>
                <CustomRole />
              </Grid>
            </Grid>
          )}
          <Box
            py={4}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gridColumnGap={12}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={closeRolesModal}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleTestCreation('hiring', '', false, addQuestionToTest)
              }
              disabled={!selectedRole && true}
              endIcon={<ArrowRightAltIcon />}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Container>
    </CustomModal>
  );
};

export default RoleList;

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const SettingsBoxInnerContainer: React.FC<ISettingsBoxStyles> = (
  props
) => {
  const classes = useStyles();
  return <Box className={classes.box__inner_container} {...props} />;
};

export default SettingsBoxInnerContainer;

const useStyles = makeStyles((theme) => ({
  box__inner_container: {
    '& .MuiFormControl-root': {
      width: theme.spacing(100),
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#233D4D',
    },
    '& .MuiFormGroup-root': {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: theme.spacing(0),
      },
    },
  },
}));

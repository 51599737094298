import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const RoleItemStyles: React.FC<IRoleItemStylesProps> = ({
  styleCondition,
  handleClick,
  children,
}) => {
  const classes = useStyles();
  return (
    <Box
      p={4}
      boxShadow="2px 4px 4px rgba(182, 217, 251, 0.15)"
      borderRadius={5}
      onClick={handleClick}
      bgcolor={styleCondition ? '#233D4D' : '#fefefe'}
      color={styleCondition ? '#fff' : '#444'}
      className={classes.box_roleitem_styles}
    >
      {children}
    </Box>
  );
};

export default RoleItemStyles;

const useStyles = makeStyles((theme) => ({
  box_roleitem_styles: {
    cursor: 'pointer',
  },
}));

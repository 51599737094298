
import Interviews from 'business/organizer/interviews/pages/Interviews';
import InterviewsProvider from 'business/organizer/interviews/context/InterviewsProvider';
import DrawerProvider from 'business/organizer/library/contexts/drawer/DrawerProvider';

const InterviewsPage = () => {

  return (
    <InterviewsProvider>
      <DrawerProvider>
        <Interviews />
      </DrawerProvider>
    </InterviewsProvider>
  );
};

export default InterviewsPage;

import React from 'react';

const InstagramIcon = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0252 6.40344C8.35905 6.40344 6.99931 7.76317 6.99931 9.42936C6.99931 11.0955 8.35905 12.4553 10.0252 12.4553C11.6914 12.4553 13.0512 11.0955 13.0512 9.42936C13.0512 7.76317 11.6914 6.40344 10.0252 6.40344ZM19.1007 9.42936C19.1007 8.17632 19.1121 6.93462 19.0417 5.68385C18.9713 4.23104 18.6399 2.94168 17.5775 1.87932C16.5129 0.814685 15.2258 0.485534 13.773 0.415164C12.52 0.344794 11.2783 0.356144 10.0275 0.356144C8.77446 0.356144 7.53277 0.344794 6.28199 0.415164C4.82919 0.485534 3.53983 0.816955 2.47746 1.87932C1.41283 2.94395 1.08368 4.23104 1.01331 5.68385C0.942938 6.93689 0.954288 8.17859 0.954288 9.42936C0.954288 10.6801 0.942938 11.9241 1.01331 13.1749C1.08368 14.6277 1.4151 15.917 2.47746 16.9794C3.5421 18.044 4.82919 18.3732 6.28199 18.4436C7.53504 18.5139 8.77673 18.5026 10.0275 18.5026C11.2805 18.5026 12.5222 18.5139 13.773 18.4436C15.2258 18.3732 16.5152 18.0418 17.5775 16.9794C18.6422 15.9148 18.9713 14.6277 19.0417 13.1749C19.1143 11.9241 19.1007 10.6824 19.1007 9.42936ZM10.0252 14.0851C7.44878 14.0851 5.36945 12.0058 5.36945 9.42936C5.36945 6.8529 7.44878 4.77358 10.0252 4.77358C12.6017 4.77358 14.681 6.8529 14.681 9.42936C14.681 12.0058 12.6017 14.0851 10.0252 14.0851ZM14.8717 5.67023C14.2701 5.67023 13.7844 5.18445 13.7844 4.5829C13.7844 3.98134 14.2701 3.49556 14.8717 3.49556C15.4732 3.49556 15.959 3.98134 15.959 4.5829C15.9592 4.72574 15.9312 4.86721 15.8766 4.99921C15.822 5.13121 15.742 5.25115 15.641 5.35216C15.54 5.45316 15.42 5.53325 15.288 5.58783C15.156 5.64241 15.0145 5.67041 14.8717 5.67023Z"
      fill="url(#paint0_radial)"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.2713 -4.92906) rotate(125.727) scale(35.7267 35.7273)"
      >
        <stop offset="0.0260417" stopColor="#6F00FF" />
        <stop offset="0.348958" stopColor="#EE2A7B" />
        <stop offset="1" stopColor="#F9ED32" />
      </radialGradient>
    </defs>
  </svg>
);

export default InstagramIcon;

import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Zoom from '@material-ui/core/Zoom';

import PersistSidebar from 'common/components/utility-components/PersistSidebar';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import RenderQuestionSolution from 'hubs-candidate/common/components/candidate-progress/RenderQuestionSolution';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import { disableViewSolutionButton } from 'hubs-candidate/utils/utils';

const ViewSolution: React.FC<IHubsCandidateViewSolutionProps> = ({
  question,
}) => {
  const [solutionDrawer, setSolutionDrawer] = useState(false);
  const { url } = useCurrentUrl();
  const { toggleDrawer } = useToggleDrawer();

  return (
    <>
      <PersistSidebar
        endingPath={`/solution/${question?.id}`}
        sidebarName="solution"
        sidebar={solutionDrawer}
        onSidebar={(drawer: boolean) => setSolutionDrawer(drawer)}
      />
      <Tooltip
        title={
          disableViewSolutionButton(question.question_type, question)
            ? 'View Solution is not available'
            : 'View Solution'
        }
        arrow={true}
        placement="top"
        TransitionComponent={Zoom}
      >
        <span>
          <Button
            variant="text"
            color="default"
            endIcon={<OpenInNewIcon color="action" />}
            onClick={toggleDrawer(
              true,
              setSolutionDrawer,
              'solution',
              `${url}/solution/${question?.id}`
            )}
            disabled={disableViewSolutionButton(
              question.question_type,
              question
            )}
          >
            View
          </Button>
        </span>
      </Tooltip>
      <CustomDrawer
        drawer={solutionDrawer}
        setDrawer={setSolutionDrawer}
        drawerName="solution"
      >
        <Route path={`${url}/solution/${question?.id}`}>
          <>
            <CloseDrawerIcon
              closeQuestionDrawer={toggleDrawer(
                false,
                setSolutionDrawer,
                'solution'
              )}
            />
            <RenderQuestionSolution
              question_type={question.question_type}
              question={question}
            />
          </>
        </Route>
      </CustomDrawer>
    </>
  );
};

export default ViewSolution;

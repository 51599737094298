import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FormHelperText from '@material-ui/core/FormHelperText';

import LanguagesListBase from 'business/organizer/library/components/question-create-update/coding/languages/LanguagesListBase';

const LanguagesList = () => {
  const [hidden, setHidden] = useState(false);

  const {
    errors,
    touched,
  } = useFormikContext<IUseFormikContextLanguageProps>();

  return (
    <>
      <Box border="1px solid #cccccc" borderRadius={5} p={4} mt={4}>
        <Box display="flex" justifyContent="space-between">
          <Box p={2}>
            <Typography variant="subtitle2">
              Allowed programming languages
            </Typography>
          </Box>
          <IconButton size="small" onClick={() => setHidden(!hidden)}>
            {hidden ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
        <Box width="100%" hidden={hidden}>
          <LanguagesListBase />
        </Box>
      </Box>
      {!!errors.languages && touched.languages && (
        <FormHelperText error={true}>
          {errors.languages}
        </FormHelperText>
      )}
    </>
  );
};

export default LanguagesList;

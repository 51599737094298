import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import TestsMenu from 'business/organizer/assessment/components/assessment-tests/test-list/TestsMenu';
import useOnHoverDropdown from 'common/components/onHover-dropdown/useOnHoverDropdown';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const CreateTest: React.FC<{
  addQuestionToTest?: ((testId: number) => Promise<void>) | undefined;
}> = ({ addQuestionToTest }) => {
  const {
    anchorEl,
    handleClick,
    handleClose,
    handleDropdownMouseEnter,
    closeDropdownWithDelay,
  } = useOnHoverDropdown();
  const { isViewer, isSubscriptionExpired } = useHasPermission();
  const [openRolesModal, setOpenRolesModal] = useState(false);

  const { isHubsUrl } = useCurrentUrl();

  const handleRolesModal = (value: boolean) => {
    setOpenRolesModal(value);
  };

  return (
    <>
      <HasPermission
        isViewer={isViewer}
        isSubscriptionExpired={isSubscriptionExpired}
        tooltipProps={{ placement: 'left', arrow: true }}
      >
        {isHubsUrl ? (
          <Button
            onClick={() => handleRolesModal(true)}
            variant="contained"
            color="primary"
            disabled={isSubscriptionExpired || isViewer}
          >
            Create Test
          </Button>
        ) : (
          <Button
            onMouseEnter={handleClick}
            onMouseLeave={closeDropdownWithDelay}
            onClick={handleClick}
            variant="contained"
            color="primary"
            endIcon={
              anchorEl !== null ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )
            }
            disabled={isSubscriptionExpired || isViewer}
          >
            Create Test
          </Button>
        )}
      </HasPermission>
      <TestsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        MenuListProps={{
          onMouseEnter: handleDropdownMouseEnter,
          onMouseLeave: handleClose,
        }}
        addQuestionToTest={addQuestionToTest}
        onRolesModal={handleRolesModal}
        openRolesModal={openRolesModal}
      />
    </>
  );
};

export default CreateTest;

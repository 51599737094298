import React from 'react';
import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const SubmitButton: React.FC<ISubmitButtonProps & ButtonProps> = ({
  children,
  disabled,
  boxClassName,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" position="relative" className={boxClassName}>
      <Button disabled={disabled} type="submit" {...otherProps}>
        {children}
      </Button>
      {disabled && (
        <CircularProgress
          size={24}
          className={classes.circular_progress}
        />
      )}
    </Box>
  );
};

export default SubmitButton;

const useStyles = makeStyles((theme) => ({
  circular_progress: {
    color: 'green[500]',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

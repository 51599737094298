import React from 'react';
import {
  Box,
  MenuItem,
  Typography,
  makeStyles,
  TextField,
} from '@material-ui/core';

import DropdownButton from 'common/components/dropdown-button/DropdownButton';
import useSettings from './useSettings';

const EditorSettings: React.FC<{
  menuItemStyle?: React.CSSProperties;
}> = ({ menuItemStyle }) => {
  const { settings, setSettings } = useSettings();
  const { theme, fontSize } = settings;

  const updateSettings = (payload: Partial<IEditorSettings>) => {
    setSettings((settings) => ({
      ...settings,
      ...payload,
    }));
  };

  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={4}
      gridGap={4}
    >
      <Box>
        <Typography variant="body1">Theme</Typography>
        <DropdownButton
          label={theme}
          Menu={({ handleClose }) => {
            return (
              <>
                {(['vs-light', 'vs-dark'] as EditorTheme[]).map(
                  (themeName) => (
                    <MenuItem
                      key={themeName}
                      onClick={() => {
                        handleClose();
                        updateSettings({ theme: themeName });
                      }}
                      style={menuItemStyle}
                    >
                      {themeName}
                    </MenuItem>
                  )
                )}
              </>
            );
          }}
          buttonProps={{
            color: 'default',
            className: classes.dropdown_button,
          }}
        />
      </Box>
      <Box>
        <Typography variant="body1">Font size</Typography>
        <TextField
          type="number"
          inputProps={{ min: 1 }}
          value={fontSize}
          onChange={(e) =>
            updateSettings({ fontSize: parseInt(e.target.value) })
          }
          variant="outlined"
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  dropdown_button: {
    borderRadius: '0.2em',
    fontSize: '0.8em',
    height: 'fit-content',
  },
}));

export default EditorSettings;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MySelect from 'common/components/form/MySelect';
import Button from '@material-ui/core/Button';

import { Formik, Form } from 'formik';
import useAlert from 'common/components/alert-provider/useAlert';
import {
  AssessToHubsValidationSchema,
  getErrorMessage,
} from 'business/pre-subscription/auth/components/common/helpers';
import { HUBS_IMAGE } from 'business/organizer/common/utils/constants';
import ContentSection from '../ContentSection';
import AuthPageBase from 'business/pre-subscription/common/components/AuthPageBase';
import Box from '@material-ui/core/Box';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import SubmitButton from 'common/components/form/SubmitButton';
import ImageHelper from 'common/components/image-helper/ImageHelper';
import { SWITCH_APP_API_URL } from 'business/pre-subscription/auth/utils/constants';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import { HUBS_ORGANIZATION_TYPE } from 'business/organizer/settings/utils/constants';
import InputWithLabel from 'common/components/form/InputWithLabel';

const businessAuthService = new BusinessAuthService();

const AssessmentToHubsModal = () => {
  const classes = useStyles();
  const { enqueueAlert } = useAlert();
  const [disabled, setdisabled] = useState(false);

  const handleSubmit: AuthSubmissionHandler = async (data) => {
    const request_data = {
      switch_to: 'hubs',
      org_type: data.org_type,
    };
    setdisabled(true);

    businessAuthService
      .post(SWITCH_APP_API_URL, request_data)
      .then((response) => {
        setdisabled(false);
        if (response) {
          window.location.reload();
        }
      })
      .catch((error) => {
        setdisabled(false);
        enqueueAlert(getErrorMessage(error?.response.data), {
          alertProps: { severity: 'error' },
        });
      });
  };

  const handleBack = () => {
    // go back to previous page
    window.history.back();
  };

  const initialState = {
    org_type: '',
  };

  return (
    <AuthPageBase
      contentSection={<ContentSection next={true} />}
      showFooter={false}
    >
      <FormSectionBase logo={false} title="">
        <>
          <Box height="100%" width="100%" textAlign={'center'}>
            <ImageHelper
              imageUrl={HUBS_IMAGE}
              altProp="Hubs to assess"
              lazyLoad
              className={classes.imagehelper__card}
            />
            <Formik
              initialValues={initialState}
              validationSchema={AssessToHubsValidationSchema[0]}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <>
                  <Form>
                    <FieldStyles>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.form_control}
                      >
                        <InputWithLabel
                          title={'Select an Organization Type'}
                          isRequired={true}
                        ></InputWithLabel>
                        <MySelect
                          name="org_type"
                          placeholder="Organization Type"
                          type="text"
                        >
                          {HUBS_ORGANIZATION_TYPE.map(
                            (hubsTitle: IJobTitleProps) => (
                              <MenuItem
                                key={hubsTitle.id}
                                value={hubsTitle.value}
                              >
                                {hubsTitle.label}
                              </MenuItem>
                            )
                          )}
                        </MySelect>
                      </FormControl>
                    </FieldStyles>
                    <Box
                      display="flex"
                      gridColumnGap={20}
                      justifyContent={'center'}
                      paddingTop={'50px'}
                    >
                      <Button
                        color="primary"
                        type="button"
                        variant="contained"
                        onClick={handleBack}
                        className={classes.button_back}
                      >
                        Back
                      </Button>
                      <SubmitButton
                        disabled={disabled}
                        color="primary"
                        variant="contained"
                        boxClassName={classes.box__button_trial}
                        onSubmit={handleSubmit}
                      >
                        Start Free Trial
                      </SubmitButton>
                    </Box>
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        </>
      </FormSectionBase>
    </AuthPageBase>
  );
};

export default AssessmentToHubsModal;

const useStyles = makeStyles((theme) => ({
  imagehelper__card: {
    maxWidth: theme.spacing(75),
    maxHeight: theme.spacing(40),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
  },
  box__button_trial: {
    width: '100%',
    '& .MuiButton-root': {
      width: '100%',
    },
  },
  button_back: {
    backgroundColor: '#666666',
    width: '30%',
    '&:hover': {
      backgroundColor: '#3d3c3c',
    },
  },
  form_control: {
    '& .MuiFormLabel-root': {
      color: '#c0c0c0',
    },
  },
}));

import * as Yup from 'yup';

const commonValidations = {
  email: Yup.string().email().required('Email is required'),
  title: Yup.string()
    .typeError('Please enter the text')
    .required('Interview title is required'),
};

export const validationSchema = Yup.object(commonValidations);

export const scheduleInterviewFormValidations = Yup.object({
  ...commonValidations,
  interview_date: Yup.string().required(
    'Please add the interview date.'
  ),
  interview_time: Yup.string().required(
    'Please add the interview time.'
  ),
  interview_tz: Yup.string().required('Please select the timezone.'),
});

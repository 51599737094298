const TotalInvitesIcon = () => (
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0.5H9C7.9 0.5 7 1.4 7 2.5V11.5C7 12.61 7.9 13.5 9 13.5H22C23.11 13.5 24 12.61 24 11.5V2.5C24 1.4 23.11 0.5 22 0.5ZM22 11.5H9V4.17L15.5 7.5L22 4.17V11.5ZM15.5 5.81L9 2.5H22L15.5 5.81ZM5 11.5C5 11.67 5.03 11.83 5.05 12H1C0.448 12 0 11.55 0 11C0 10.45 0.448 10 1 10H5V11.5ZM3 2H5.05C5.03 2.17 5 2.33 5 2.5V4H3C2.45 4 2 3.55 2 3C2 2.45 2.45 2 3 2ZM1 7C1 6.45 1.45 6 2 6H5V8H2C1.45 8 1 7.55 1 7Z"
      fill="#0A66C2"
    />
  </svg>
);

export default TotalInvitesIcon;

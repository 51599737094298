import React, { useState } from 'react';

import FileUploadContext from 'business/organizer/library/contexts/file-upload-question/contexts/FileUploadContext';
import useFileUploadData from 'business/organizer/library/contexts/file-upload-question/custom-hooks/useFileUploadData';
import useAlert from 'common/components/alert-provider/useAlert';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import { getTagsList } from 'business/organizer/library/utils/getTagsList';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { UPDATE_LIBRARY } from 'business/organizer/library/contexts/library/libraryActionTypes';

const libraryService = new LibraryService();

export enum StepLabel {
  PROBLEM_STATEMENT = 0,
}

const FileUploadProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { enqueueAlert } = useAlert();

  const { dispatch } = useLibrary();

  const { fileUpload } = useFileUploadData();
  const { handleAddQuestionsToTest } = useAddQuestionsToTest();

  const [
    problemStatementResponse,
    setProblemStatementResponse,
  ] = useState<any | null>(null);

  const handleProblemStatementSubmit = async (
    data: IInitialStateFileUploadFormProps
  ) => {
    try {
      const {
        name,
        description,
        tags,
        time_duration,
        difficulty,
        score,
        file_type,
        max_file_size,
        testId,
      } = data;

      const questionData = {
        question: {
          name,
          description,
          time_duration,
          difficulty,
          score,
          file_type,
          max_file_size,
        },
        type: 'file-upload',
        tags: getTagsList(tags),
      };

      const response =
        fileUpload !== null || problemStatementResponse !== null
          ? await libraryService.patchQuestion(
              fileUpload !== null
                ? fileUpload.url
                : problemStatementResponse.url,
              questionData
            )
          : await libraryService.createQuestion(
              MY_LIBRARY_QUESTION_API_URL,
              questionData
            );
      if (response) {
        setProblemStatementResponse(response?.data);
        dispatch({ type: UPDATE_LIBRARY, payload: { list: 'my' } });
        enqueueAlert(
          `Question ${
            fileUpload !== null ? 'updated' : 'added'
          } successfully`,
          {
            alertProps: { severity: 'success' },
          }
        );
        if (!!testId && !fileUpload) {
          handleAddQuestionsToTest(testId, response?.data?.id);
        }
      }
    } catch (error) {
      enqueueAlert(
        `Something went wrong while ${
          fileUpload !== null ? 'updating' : 'adding'
        } question. Please try again after sometime`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <FileUploadContext.Provider
      value={{
        handleProblemStatementSubmit,
        problemStatementResponse,
        setProblemStatementResponse,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

export default FileUploadProvider;

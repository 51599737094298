import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Faqs from 'common/components/faqs/Faqs';
import { FAQ_DATA } from 'business/organizer/common/utils/faqsData';
import BillingPageFooter from 'business/organizer/assessment/components/billing/BillingPageFooter';
import BillingPlans from 'business/organizer/assessment/components/billing/BillingPlans';
import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import BillingHistory from 'business/organizer/assessment/components/billing/BillingHistory';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
import Header from 'business/organizer/assessment/components/billing/Header';
import Addons from '../components/billing/Addons';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const BillingPage = () => {
  const { organizer } = useOrganizer();
  const { isFreeTrialEnded } = useSubscription();
  const { isHubsUrl } = useCurrentUrl();

  return (
    <Container maxWidth="xl">
      <Box width="100%" py={12}>
        <>
          {isFreeTrialEnded ? (
            <Header
              title="Your free trial has ended"
              subtitle="Upgrade now to continue using Skillspace assessment platform"
            />
          ) : (
            <Header
              title="Simplify Your Hiring Process"
              subtitle="Hire from data scientists, developers, AI engineers,
              Project Managers and just about any role you wish to
              hire for."
            />
          )}
          <BillingPlans />
          {!isHubsUrl && <Addons />}
          {organizer === null ? (
            <GenericListSkeleton items={1} />
          ) : (
            <BillingHistory />
          )}
          <Box py={2}>
            <Faqs
              faqs={FAQ_DATA}
              responsiveVariants={{ downXl: 'h4', downMd: 'h5' }}
            />
          </Box>
          <BillingPageFooter />
        </>
      </Box>
    </Container>
  );
};

export default BillingPage;

import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box/Box';

import CustomPlan from 'business/organizer/assessment/components/billing/common/CustomPlan';
import BillingPlanItem from 'business/organizer/assessment/components/billing/common/BillingPlanItem';
import StartUpPlan from 'business/organizer/assessment/components/billing/StartUpPlan';

export const getDefaultBoxProps = (props?: BoxProps): BoxProps => {
  return {
    display: 'flex',
    gridColumnGap: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    ...props,
  };
};

const BillingCards: React.FC<IBillingCardsProps> = ({
  starterCta,
  growthCta,
  customCta,
  getParentBoxProps,
  monthly,
  startupCta,
  interviewCta,
}) => {
  const parentBoxProps = getParentBoxProps
    ? getParentBoxProps
    : getDefaultBoxProps();

  return (
    <Box py={6}>
      <Box {...parentBoxProps}>
        {/* <BillingPlanItem
          perInviteDiscountedPrice={monthly ? 1 : 0.9}
          perInvitePrice={1}
          plan="Interview Plan"
          planDescription="For hiring"
          features={[
            '15 interview invites/month',
            'Email and chat support',
            'upto 3 user accounts per company',
          ]}
          monthly={monthly}
          showDiscount={!monthly}
          discount="10% off"
        >
          {interviewCta}
        </BillingPlanItem> */}
        {/* <BillingPlanItem
          perInviteDiscountedPrice={monthly ? 0 : 0}
          plan="Starter Plan"
          planDescription="For small teams"
          features={[
            '20 tests/year',
            '20 candidate invites/month',
            'Access to Tests',
            'Standard Questions Library',
            'Basic Proctoring',
            'Email support',
          ]}
          monthly={monthly}
        >
          {starterCta}
        </BillingPlanItem> */}
        <BillingPlanItem
          perInviteDiscountedPrice={monthly ? 199 : 1199}
          plan="Premium Plan"
          planDescription="For growing teams"
          features={[
            'Unlimited Test Invites (upto 50 candidate/month)',
            'Access to Tests',
            'Premium Questions Library',
            'AI Powered Proctoring',
            'Email and phone support',
          ]}
          monthly={monthly}
        >
          {growthCta}
        </BillingPlanItem>
        {!!startupCta && (
          <StartUpPlan
            perInviteDiscountedPrice={monthly ? 149 : 99}
            plan="Startup Plan"
            planDescription="Perfect for small teams"
            features={[
              'Unlimited Tests',
              'Max. 50 candidate invites/day',
              'Premium Questions Library',
              // 'Take-Home Assignments',
              'AI Powered proctoring features',
              'Email support',
            ]}
            monthly={monthly}
          >
            {startupCta}
          </StartUpPlan>
        )}
        <CustomPlan
          planTitle="Enterprise Plan"
          planSubtitle="Based on quantity and billing timeline"
          planSubtitleDescription="Connect with us and create a plan that suits your requirement"
          planDescription={[
            'Unlimited Tests',
            'Unlimited Test Invites',
            'AI Powered proctoring',
            'Premium Questions Library',
            // 'Take-Home Assignments',
            'Dedicated Success Manager',
            'Email and Phone Support',
          ]}
        >
          {customCta}
        </CustomPlan>
      </Box>
    </Box>
  );
};

export default BillingCards;

import React, { useEffect } from 'react';
import Interweave from 'interweave';

import { rootTransformer } from './transformers';
import { MATHJAX_JS } from 'common/utils/constants';

/**
 * Embeds a <script src /> tag in the DOM (if not already present) to load
 * the mathjax renderer.
 */
const loadMathJax = () => {
  if (!document.querySelector(`script[src='${MATHJAX_JS}']`)) {
    const d = document.createElement('script');
    d.type = 'text/javascript';
    d.async = true;
    d.src = MATHJAX_JS;
    (
      document.getElementsByTagName('head')[0] ||
      document.getElementsByTagName('body')[0]
    ).appendChild(d);
  }
};

const RichTextRenderer: React.FC<IRichTextRendererProps> = ({
  richTextHtml,
}) => {
  const mathClassName = 'math-tex';
  const MathJax = (window as any).MathJax;

  useEffect(() => {
    // Load the MathJax renderer script if there is a reference
    // to the mathjax classname in the html
    if ((richTextHtml || '').indexOf(mathClassName) >= 0) {
      loadMathJax();
    }
  }, [richTextHtml]);

  useEffect(() => {
    if (MathJax) {
      MathJax.Hub.Typeset();
    }
  });

  return (
    <Interweave content={richTextHtml} transform={rootTransformer} />
  );
};

export default React.memo(RichTextRenderer);

import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TestInfo from 'business/organizer/assessment/components/common/TestInfo';
import { Divider, makeStyles } from '@material-ui/core';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const TestItem: React.FC<ITestItemHomeProps> = ({
  testItem,
  list,
}) => {
  const classes = useStyles();
  const { isHubsUrl } = useCurrentUrl();
  return (
    <Link
      to={`${isHubsUrl ? '/hubs' : ''}/tests/${list}/${testItem?.id}`}
    >
      <Box
        borderRadius={5}
        border="1px solid #CCCCCC"
        p={4}
        className={classes.box__root}
      >
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Box display="flex" alignItems="center" pb={4}>
              <Typography color="textSecondary" variant="h5">
                {testItem.name}
              </Typography>
            </Box>
            <Box pb={2}>
              <TestInfo
                total_score={testItem?.total_score}
                assessment_duration={testItem?.assessment_duration}
                start_datetime={testItem?.start_datetime}
                end_datetime={testItem?.end_datetime}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gridColumnGap={22}
              pt={2}
            >
              <Typography>
                <Typography variant="body1" component="span">
                  {testItem?.invites_sent}{' '}
                </Typography>
                Invites sent
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography>
                <Typography variant="body1" component="span">
                  {testItem?.number_of_candidates_attempted}{' '}
                </Typography>
                Candidates attempted
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
};

export default TestItem;

const useStyles = makeStyles((theme) => ({
  box__root: {
    boxShadow: '0px 2px 6px 0px #DDE6ED',
    '&:hover': {
      boxShadow: '0px 6px 18px 0px #DDE6ED',
    },
  },
}));

import React, { useRef, useCallback, useState } from 'react';

import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import DialogContext from './DialogContext';

const DialogProvider: React.FC<IDialogProviderProps> = ({
  children,
}) => {
  const [props, setProps] = useState<INotificationDialogProps>({
    open: false,
  });

  const closeDialog = useCallback(() => {
    setProps((props) => ({ ...props, open: false }));
  }, []);

  const displayDialogRef = useRef<
    IDialogProviderContext['displayDialog']
  >(
    useCallback(
      (props) => {
        setProps({
          ...props,
          open: true,
          onAgree: () => props.onAgree(closeDialog),
          onDisagree: () => props.onDisagree(closeDialog),
        });
      },
      [closeDialog]
    )
  );

  const context: IDialogProviderContext = {
    //@ts-ignore
    displayDialog: (...args) => displayDialogRef.current(...args),
    confirm: (title, content, props) => {
      return new Promise((resolve, reject) => {
        displayDialogRef.current({
          onAgree: (close) => {
            resolve(true);
            close();
          },
          onDisagree: (close) => {
            resolve(false);
            close();
          },
          agreeText: 'Yes',
          disagreeText: 'No',
          title,
          content,
          variant: 'info',
          ...(props || {}),
        });
      });
    },
  };

  return (
    <DialogContext.Provider value={context}>
      <NotificationDialog {...props} />
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;

import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';

import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import CommonQuestionSummaryTableItem from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/CommonQuestionSummaryTableItem';
import RenderQuestionSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/RenderQuestionSolution';
import PersistSidebar from 'common/components/utility-components/PersistSidebar';

const QuestionsSummaryTableItem: React.FC<IHubsCandidateQuestionsSummaryTableItemProps> =
  ({ question, idx }) => {
    const { url } = useCurrentUrl();

    const [solutionDrawer, setSolutionDrawer] = useState(false);

    const { toggleDrawer } = useToggleDrawer();

    return (
      <>
        <CommonQuestionSummaryTableItem
          idx={idx}
          questionName={question?.question_name}
          questionType={question?.question_type}
          questionScore={question?.question_score}
          submission={question?.submission}
        >
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {question?.submission === null ? (
                <Tooltip title="Unsolved" placement="top" arrow>
                  <span>
                    <Button
                      variant="text"
                      color="default"
                      endIcon={<OpenInNewIcon color="action" />}
                      onClick={toggleDrawer(
                        true,
                        setSolutionDrawer,
                        'solution',
                        `${url}/solution/${question?.id}`
                      )}
                      disabled={question?.submission === null}
                    >
                      View
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <>
                  <PersistSidebar
                    endingPath={`/solution/${question?.id}`}
                    sidebarName="solution"
                    sidebar={solutionDrawer}
                    onSidebar={(drawer: boolean) =>
                      setSolutionDrawer(drawer)
                    }
                  />
                  <Button
                    variant="text"
                    color="default"
                    endIcon={<OpenInNewIcon color="action" />}
                    onClick={toggleDrawer(
                      true,
                      setSolutionDrawer,
                      'solution',
                      `${url}/solution/${question?.id}`
                    )}
                  >
                    View
                  </Button>
                </>
              )}
            </Box>
            <CustomDrawer
              drawer={solutionDrawer}
              setDrawer={setSolutionDrawer}
              drawerName="solution"
            >
              <Route path={`${url}/solution/${question?.id}`}>
                <>
                  <CloseDrawerIcon
                    closeQuestionDrawer={toggleDrawer(
                      false,
                      setSolutionDrawer,
                      'solution'
                    )}
                  />
                  <RenderQuestionSolution
                    questionType={question?.question_type}
                    submissionUrl={question?.submission?.url}
                  />
                </>
              </Route>
            </CustomDrawer>
          </>
        </CommonQuestionSummaryTableItem>
      </>
    );
  };

export default QuestionsSummaryTableItem;

import React from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import BillingCardStyles from 'business/organizer/assessment/components/billing/common/BillingCardStyles';
import Features from 'business/organizer/assessment/components/billing/common/Features';
import theme from 'business/common/theme/theme';

const StartUpPlan: React.FC<
  Omit<IBillingPlanItemProps, 'perInvitePrice'>
> = ({
  plan,
  planDescription,
  features,
  perInviteDiscountedPrice,
  children,
  monthly,
}) => {
  const classes = useStyles();

  return (
    <BillingCardStyles>
      <Box py={4} height={80}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridColumnGap={8}
        >
          <Box display="flex">
            <Typography variant="subtitle2">$</Typography>
            <Typography
              variant="h2"
              className={classes.typography__discounted_price}
            >
              {perInviteDiscountedPrice}
              <Typography component="span" color="textSecondary">
                {monthly ? '/month' : '/year'}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gridColumnGap={4}
          pt={1}
        >
          {!monthly && (
            <Typography
              color="textSecondary"
              className={classes.typography__price}
              align="center"
            >
              $149
            </Typography>
          )}
          <Box color={monthly ? '#fff' : theme.palette.info.main}>
            <Typography color="inherit">
              {monthly ? '-' : '(33.5% off)'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" p={4} height={320}>
        <Box textAlign="center" py={4}>
          <Typography variant="h4">{plan}</Typography>
          <Typography>{planDescription}</Typography>
        </Box>
        <Features features={features} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={8}
        gridRowGap={4}
        height={100}
      >
        {children}
      </Box>
    </BillingCardStyles>
  );
};

export default StartUpPlan;

const useStyles = makeStyles((theme) => ({
  typography__price: {
    textDecoration: 'line-through',
  },
  infoicon__invite: {
    color: '#666',
    fontSize: 14,
  },
  typography__discounted_price: {
    fontSize: theme.spacing(8),
  },
}));

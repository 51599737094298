export const FETCH_LIBRARY_SUCCESS = 'FETCH_LIBRARY_SUCCESS';
export const FETCH_LIBRARY_FAILURE = 'FETCH_LIBRARY_FAILURE';
export const FETCH_LIBRARY_COUNT_FAILURE = 'FETCH_MY_LIBRARY_FAILURE';
export const ADD_QUESTION_TYPE = 'ADD_QUESTION_TYPE';
export const ADD_DIFFICULTY_LEVEL = 'ADD_DIFFICULTY_LEVEL';
export const REMOVE_QUESTION_TYPE = 'REMOVE_QUESTION_TYPE';
export const REMOVE_DIFFICULTY_LEVEL = 'REMOVE_DIFFICULTY_LEVEL';
export const SEARCH_QUESTIONS = 'SEARCH_QUESTIONS';
export const LOAD_PAGE = 'LOAD_PAGE';
export const FETCH_LIBRARY_COUNTS = 'FETCH_LIBRARY_COUNTS';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const UPDATE_LIBRARY = 'UPDATE_LIBRARY';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const FILTER_BY_TAGS = 'FILTER_BY_TAGS';
export const REMOVE_TAG = 'REMOVE_TAG';

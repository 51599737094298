import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import ImageHelper from 'common/components/image-helper/ImageHelper';
import { TEAM_MANAGEMENT_EMPTY_IMAGE } from 'business/organizer/settings/utils/constants';
import InviteMemberModal from 'business/organizer/settings/components/team-management/InviteMemberModal';

const EmptyPlaceholder = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box
      py={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <ImageHelper
        imageUrl={TEAM_MANAGEMENT_EMPTY_IMAGE}
        altProp="Team Management"
        className={classes.imagehelper__team_management}
      />
      <Box pt={8} pb={4}>
        <Typography variant="h2" align="center">
          Add your hiring team and start <br /> collaborating today!
        </Typography>
      </Box>
      <Box pb={8}>
        <Typography align="center">
          Easily manage and scale your hiring process with a large
          team
        </Typography>
      </Box>
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Add Team Member
        </Button>
        <InviteMemberModal
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      </>
    </Box>
  );
};

export default EmptyPlaceholder;

const useStyles = makeStyles((theme) => ({
  imagehelper__team_management: {
    maxWidth: 330,
    maxHeight: 232,
  },
}));

import React from 'react';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import {
  REMOVE_QUESTION_TYPE,
  REMOVE_DIFFICULTY_LEVEL,
  REMOVE_TAG,
} from 'business/organizer/library/contexts/library/libraryActionTypes';

const ActiveFilters: React.FC<{ questionsCount: number }> = ({
  questionsCount,
}) => {
  const { state, dispatch } = useLibrary();

  return (
    <Box pb={4}>
      <Typography>
        Showing <b>{questionsCount}</b> results matching filters -
      </Typography>
      <Box
        py={2}
        display="flex"
        alignItems="center"
        gridGap={8}
        flexWrap="wrap"
      >
        {!!state.globalParams.type &&
          state.globalParams.type.map((type) => (
            <Chip
              label={type}
              onDelete={() =>
                dispatch({
                  type: REMOVE_QUESTION_TYPE,
                  payload: type,
                })
              }
            />
          ))}
        {!!state.globalParams.difficulty &&
          state.globalParams.difficulty.map((difficulty) => (
            <Chip
              label={difficulty}
              onDelete={() =>
                dispatch({
                  type: REMOVE_DIFFICULTY_LEVEL,
                  payload: difficulty,
                })
              }
            />
          ))}
        {!!state.globalParams.tags &&
          state.globalParams.tags.map((tag) => (
            <Chip
              label={tag.name}
              onDelete={() =>
                dispatch({
                  type: REMOVE_TAG,
                  payload: {
                    tagId: tag.id,
                    tagName: 'tags',
                  },
                })
              }
            />
          ))}
        {!!state.globalParams.programmingLanguages &&
          state.globalParams.programmingLanguages.map((tag) => (
            <Chip
              label={tag.name}
              onDelete={() =>
                dispatch({
                  type: REMOVE_TAG,
                  payload: {
                    tagId: tag.id,
                    tagName: 'programmingLanguages',
                  },
                })
              }
            />
          ))}
      </Box>
    </Box>
  );
};

export default ActiveFilters;

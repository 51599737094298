import React from 'react';
import Button from '@material-ui/core/Button';

const BackButton: React.FC<IChallengeQuestionBackButtonProps> = ({
  prevStep,
}) => (
  <Button variant="outlined" color="primary" onClick={prevStep}>
    Back
  </Button>
);

export default BackButton;

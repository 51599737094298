import React from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box/Box';

interface IEditWrapperProps {
  children: JSX.Element;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
}

const EditWrapper: React.FC<IEditWrapperProps> = ({
  children,
  handleClick,
  handleCancel,
}) => (
  <Box display="flex" alignItems="center">
    {children}
    <Box display="flex">
      <IconButton onClick={handleClick}>
        <CheckCircleRoundedIcon color="primary" />
      </IconButton>
      <IconButton onClick={handleCancel}>
        <CancelRoundedIcon color="error" />
      </IconButton>
    </Box>
  </Box>
);

export default EditWrapper;

import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import AntSwitch from 'common/components/ant-switch/AntSwitch';
import Reminder from './Reminder';

const ReminderSwitch = () => {
  const {
    setFieldValue,
    values: { showReminderSettings },
  } = useFormikContext<IInviteFormInitialState>();

  const handleShowReminderSettings = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFieldValue('showReminderSettings', checked);
  };

  return (
    <>
      <Box
        py={4}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gridColumnGap={6}
      >
        <Typography variant="h6">Schedule a reminder</Typography>
        <AntSwitch
          checked={showReminderSettings}
          onChange={handleShowReminderSettings}
        />
      </Box>
      {showReminderSettings && <Reminder />}
    </>
  );
};

export default ReminderSwitch;

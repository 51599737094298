import React from 'react';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const QuestionFormItemSkeleton = () => (
  <Box p={2} width="100%" height="50px">
    <Skeleton
      width="100%"
      height="100%"
      component="div"
      variant="rect"
    />
  </Box>
);

export default QuestionFormItemSkeleton;

import React, { useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core';

interface ISearchBarProps {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar: React.FC<ISearchBarProps> = ({ handleSearch }) => {
  const classes = useStyles();
  const searchRef = useRef<HTMLInputElement | null>(null);

  return (
    <TextField
      id="nb-search"
      className={classes.navInputField}
      placeholder="Search"
      type="text"
      size="small"
      fullWidth
      variant="outlined"
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      inputRef={searchRef}
      style={{ borderRadius: '2em' }}
    />
  );
};

export default SearchBar;

const useStyles = makeStyles((theme) => ({
  navInputField: {
    '& fieldset': {
      borderRadius: '2em',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '14px',
    },
  },
}));

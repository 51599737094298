// Add contsnta here
export const HOMEPAGE_TEST_IMAGE =
  '/static/images/business/organizer/test-business.png';
export const HOMEPAGE_LIBRARY_IMAGE =
  '/static/images/business/organizer/library-business.png';
export const HOMEPAGE_BILLING_IMAGE =
  '/static/images/business/organizer/billing-business.png';
export const NO_TEST_IMAGE =
  '/static/images/business/organizer/no-tests.png';
export const NO_SKILLS_IMAGE = '/static/images/business/organizer/no-skills-image.png';

// urls
export const ROLES_API_URL = '/api/roles/';
export const TEST_API_URL = '/api/assessments/?auth_type=business';
export const TESTS_COUNT_API_URL = '/api/user_assessment_counts/';
export const TEST_URL = '/api/assessments/';
export const CANDIDATE_REGISTRATION_FIELDS_API_URL =
  '/api/registration-forms/fields/';

// frontend urls
export const FRONTEND_ARCHIVE_URL = '/tests/archive';
export const FRONTEND_DRAFT_URL = '/tests/draft';
export const FRONTEND_ACTIVE_URL = '/tests/active';
export const FRONTEND_BUSINESS_DASHBOARD_URL = '/dashboard';
export const FRONTEND_BUSINESS_TESTS_URL = '/tests';
export const FRONTEND_BUSINESS_LIBRARY_URL = '/library';
export const FRONTEND_BUSINESS_BILLING_URL = '/billing';

export const ORGANIZATION_API_URL = '/api/organizations/';
export const API_CREATE_CUSTOM_PORTAL_URL =
  '/api/create-customer-portal/';

export const APP_TYPE_PARAM = 'app_type';
export const HUBS_APP_TYPE = 'hubs';
export const SKILLSPACE_APP_TYPE = 'hiring';
export const SKILLSPACE_APP_TYPE_PARAM = `${APP_TYPE_PARAM}=${SKILLSPACE_APP_TYPE}`;
export const HUBS_APP_TYPE_PARAM = `${APP_TYPE_PARAM}=${HUBS_APP_TYPE}`;

export const Hubs_TO_ASSESS_IMAGE =
  '/static/images/business/organizer/hubs_to_assess.png';

export const HUBS_IMAGE =
  '/static/images/business/organizer/skillspace_hubs.png';

import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import TestDetailContext from 'business/organizer/assessment/contexts/test-detail/TestDetailContext';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { TEST_URL } from 'business/organizer/common/utils/constants';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';

const organizerService = new OrganizerService();

const TestDetailProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { addQuestionToTestResponse } = useAddQuestionsToTest();
  const testParams = useParams<{
    testId: string;
    testStatus: string;
  }>();
  const [
    testDetail,
    setTestDetail,
  ] = useState<ITestDetailProps | null>(null);
  const [
    testDetailError,
    setTestDetailError,
  ] = useState<AxiosError | null>(null);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    let mounted = true;
    organizerService
      .authGet(`${TEST_URL}${testParams.testId}/`)
      .then((response) => {
        if (mounted) {
          setTestDetail(response?.data);
        }
      })
      .catch((error) => setTestDetailError(error));

    return () => {
      mounted = false;
    };
  }, [testParams.testId, reload, addQuestionToTestResponse]);

  return (
    <TestDetailContext.Provider
      value={{
        testParams,
        testDetail,
        testDetailError,
        setTestDetail,
        setTestDetailError,
        reload,
        setReload,
      }}
    >
      {children}
    </TestDetailContext.Provider>
  );
};

export default TestDetailProvider;

// This utility function is used to disable the view solution button if any of the required fields are missing.
export const disableViewSolutionButton = (
  question_type: string,
  question: any
): boolean => {
  const _submission = question?.submission;

  if (_submission === null) {
    return true;
  }

  switch (question_type) {
    case 'coding':
      return (
        _submission === null ||
        !_submission.hasOwnProperty('test_cases')
      ); // TODO(shreehari@dphi.tech): Improve this logic.
    case 'challenge':
      return _submission === null; // TODO(shreehari@dphi.tech): Improve this logic.
    case 'free-text':
      return _submission.hasOwnProperty('text') === false;
    case 'file-upload':
      return _submission.hasOwnProperty('file') === false;
    case 'mcq':
      return (
        _submission.hasOwnProperty('submitted_choices') === false ||
        _submission.hasOwnProperty('question_choices') === false
      );
    case 'project':
      return _submission.hasOwnProperty('testcases') === false;
    case 'sql':
      return _submission.hasOwnProperty('sub_questions') === false;
    default:
      throw new Error(`${question_type}, Unexpected question type.`);
  }
};

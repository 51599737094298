import React from 'react';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import MuiLink from '@material-ui/core/Link';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import HomeSvg from 'business/organizer/common/components/navigation/assets/HomeSvg';
import LibrarySvg from 'business/organizer/common/components/navigation/assets/LibrarySvg';
import TestsSvg from 'business/organizer/common/components/navigation/assets/TestsSvg';
import PriceSvg from 'business/organizer/common/components/navigation/assets/PriceSvg';
import SettingsSvg from 'business/organizer/common/components/navigation/assets/SettingsSvg';
import CustomMuiLink from 'common/components/custom-mui-link/CustomMuiLink';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import InterviewsIcon from 'business/organizer/common/components/navigation/assets/InterviewsIcon';
import { sortBy } from 'common/utils/utils';

const useSidebarTabs = () => {
  const { url, isHubsUrl } = useCurrentUrl();
  const { isSuperAdmin } = useHasPermission();

  const tabs = useMemo(() => {
    let tabsList: ITabList = {
      home: {
        id: 1,
        name: 'Dashboard',
        route: `${url}/dashboard`,
        Icon: <HomeSvg color="#666666" />,
        disabled: false,
        RouteLink: (props: any) => (
          <NavLink to={props.to} {...props} />
        ),
      },
      test: {
        id: 2,
        name: 'Tests',
        route: `${url}/tests`,
        Icon: <TestsSvg color="#666666" />,
        disabled: false,
        RouteLink: (props: any) => (
          <NavLink to={props.to} {...props} />
        ),
      },
      library: {
        id: 4,
        name: 'Library',
        route: `${url}/library`,
        Icon: <LibrarySvg color="#666666" />,
        disabled: false,
        RouteLink: (props: any) => (
          <NavLink to={props.to} {...props} />
        ),
      },
      settings: {
        id: 5,
        name: 'Settings',
        route: `${url}/settings`,
        Icon: <SettingsSvg color="#666666" />,
        disabled: false,
        RouteLink: (props: any) => (
          <NavLink to={props.to} {...props} />
        ),
      },
      billing: {
        id: 6,
        name: 'Billing',
        route: `${url}/billing`,
        Icon: <PriceSvg color="#666666" />,
        disabled: !isSuperAdmin,
        RouteLink: (props: any) => (
          <MuiLink href={props.to} underline="none" {...props} />
        ),
      },
      needHelp: {
        id: 7,
        name: 'Help',
        route: 'https://dphi.freshdesk.com/support/home',
        Icon: <HelpIcon color="inherit" />,
        disabled: false,
        RouteLink: (props: any) => (
          <CustomMuiLink href={props.to} target="_blank" {...props} />
        ),
      },
    };
    if (!isHubsUrl) {
      tabsList.interviews = {
        id: 3,
        name: 'Interviews',
        route: `${url}/interviews`,
        Icon: <InterviewsIcon />,
        disabled: false,
        RouteLink: (props: any) => (
          <NavLink to={props.to} {...props} />
        ),
      };
    }

    // Sort tabs using 'id' to show them in order
    tabsList = sortBy(tabsList, 'id');

    return tabsList;
  }, [url, isHubsUrl, isSuperAdmin]);

  return tabs;
};

export default useSidebarTabs;

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Popover,
  PopoverProps,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import { editor } from 'monaco-editor';
import Editor, { EditorProps, OnChange } from '@monaco-editor/react';

import { LANGUAGE_DEFAULT_CONTENT } from 'common/components/monaco-editor/constants';
import EditorSettings from 'common/components/monaco-editor/Settings';
import useSettings from 'common/components/monaco-editor/useSettings';
import SelectLanguage from 'common/components/monaco-editor/SelectLanguage';

const MonacoEditor: React.FC<
  IMonacoEditorProps &
    Omit<EditorProps, 'onChange' | 'defaultLanguage'>
> = ({
  languages,
  defaultLanguage,
  onLanguageChange,
  onChange,
  rootBoxProps,
  ...otherProps
}) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const monacoRef = useRef<Monaco>();
  const firstMountRef = useRef(true);

  const { settings } = useSettings();
  const { theme, fontSize } = settings;
  const [language, setLanguage] = useState(
    defaultLanguage || languages[0]
  );
  const [value, setValue] = useState<string>(
    otherProps.value || otherProps.defaultValue || ''
  );

  const [settingsAnchor, setSettingsAnchor] =
    useState<PopoverProps['anchorEl']>();

  useEffect(() => {
    if (!firstMountRef.current || !value) {
      setValue(LANGUAGE_DEFAULT_CONTENT[language.name] || '');
    }
    if (onLanguageChange) onLanguageChange(language);
    firstMountRef.current = false;
    // language.id is an exhaustive check on language objects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language.id]);

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const handleChange = useCallback<OnChange>((value, ev) => {
    setValue(value || '');
  }, []);

  const showSettings = Boolean(settingsAnchor);
  const handleOpenSettings: React.MouseEventHandler<HTMLButtonElement> =
    (event) => {
      setSettingsAnchor(event.currentTarget);
    };
  const handleCloseSettings = () => {
    setSettingsAnchor(undefined);
  };

  const classes = useStyles();
  const otherOptions = otherProps.options || {};
  delete otherProps.options;
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      bgcolor="#fff"
      flexDirection="column"
      boxSizing="border-box"
      className={classes.root}
      border="1px solid #ccc"
      {...(rootBoxProps || {})}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        borderBottom="1px solid #ccc"
        height="50px"
      >
        <Box width={225}>
          <SelectLanguage
            languages={languages}
            onLanguageChange={(language: ILanguage) =>
              setLanguage(language)
            }
            defaultValue={language}
          />
        </Box>

        <IconButton onClick={handleOpenSettings}>
          <SettingsIcon fontSize="small" />
        </IconButton>

        <Popover
          open={showSettings}
          anchorEl={settingsAnchor}
          onClose={handleCloseSettings}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <EditorSettings />
        </Popover>
      </Box>
      <Box height="calc(100% - 50px)">
        <Editor
          language={language.name}
          value={value}
          onChange={handleChange}
          onMount={(editor, monaco) => {
            editorRef.current = editor;
            monacoRef.current = monaco;
          }}
          theme={theme}
          options={{ fontSize: fontSize || 14, ...otherOptions }}
          {...otherProps}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .dropdown_button': {
      borderRadius: '0.2em',
      fontSize: '0.8em',
      height: 'fit-content',
    },
    '& 	.MuiAutocomplete-input': {
      fontWeight: 500,
      height: 18,
    },
  },
}));

export default MonacoEditor;

import React from 'react';
import { makeStyles } from '@material-ui/core';

import SubmitButton from 'common/components/form/SubmitButton';
import { useFormikContext } from 'formik';

const AuthPageButton = ({ children }: { children: string }) => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  return (
    <SubmitButton
      disabled={isSubmitting}
      color="primary"
      variant="contained"
      boxClassName={classes.box__button}
    >
      {children}
    </SubmitButton>
  );
};

export default AuthPageButton;

const useStyles = makeStyles((theme) => ({
  box__button: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(4, 0),
    '& .MuiButton-root': {
      width: '100%',
    },
  },
}));

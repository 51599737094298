import { useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AuthPageButton from 'business/pre-subscription/auth/components/common/AuthPageButton';
import MyInput from 'common/components/form/MyInput';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import useAlert from 'common/components/alert-provider/useAlert';
import {
  getErrorMessage,
  getValidationSchema,
} from 'business/pre-subscription/auth/components/common/helpers';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import {
  FRONTEND_BUSINESS_CONFIRM_EMAIL,
  API_BUSINESS_RESEND_EMAIL_CONFIRM,
} from 'business/pre-subscription/auth/utils/constants';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import Caption from 'business/pre-subscription/auth/components/signup/Caption';
import {
  FRONTEND_HUBS_LOGIN_URL,
  FRONTEND_LOGIN_URL,
} from 'common/utils/constants';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const businessAuthService = new BusinessAuthService();
const ResendConfirmation = () => {
  const { enqueueAlert } = useAlert();
  const history = useHistory();
  const { isHubsUrl } = useCurrentUrl();

  const [error, setError] = useState<IBusinessFormErrorProps | null>(
    null
  );

  const initialState = {
    email: '',
  };

  const validationSchema = getValidationSchema(initialState);

  const handleSubmit: AuthSubmissionHandler = async (
    values,
    actions
  ) => {
    try {
      const response = await businessAuthService.authPost(
        API_BUSINESS_RESEND_EMAIL_CONFIRM,
        {
          ...values,
        }
      );
      if (response) {
        actions.resetForm();
        enqueueAlert(
          'Verification link has been sent to your mail address.',
          {
            alertProps: { severity: 'success' },
          }
        );
        history.push(FRONTEND_BUSINESS_CONFIRM_EMAIL);
      }
    } catch (error) {
      setError(
        (error as AxiosError).response
          ?.data as IBusinessFormErrorProps
      );
    }
  };

  const errorMessage = getErrorMessage(error);

  return (
    <FormSectionBase
      title="Lost Your Email Vertification Link?"
      subtitle="Don’t worry, we got you!"
      caption={
        <Caption
          caption="Already verified? You can log in with no issues then."
          link={
            isHubsUrl ? FRONTEND_HUBS_LOGIN_URL : FRONTEND_LOGIN_URL
          }
          ctaText="Log In"
        />
      }
    >
      <Box>
        <NotificationDialog
          open={error !== null}
          variant="error"
          agreeText="Try Again"
          onAgree={() => setError(null)}
          content={errorMessage}
        />
        <Box pb={4}>
          <Typography align="center">
            Just let us know the email you used to sign up with and
            we’ll send another confirmation link.
          </Typography>
        </Box>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FieldStyles>
              <MyInput
                placeholder="Work Email Address"
                name="email"
                type="email"
              />
            </FieldStyles>
            <AuthPageButton>Resend Confirmation</AuthPageButton>
          </Form>
        </Formik>
      </Box>
    </FormSectionBase>
  );
};

export default ResendConfirmation;

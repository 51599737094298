import React from 'react';
import { Switch, Route } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import ChallengeRoutes from 'business/organizer/library/pages/question-create-update/challenge/ChallengeRoutes';
import CodingRoutes from 'business/organizer/library/pages/question-create-update/coding/CodingRoutes';
import FreeTextRoutes from 'business/organizer/library/pages/question-create-update/free-text/FreeTextRoutes';
import FileUploadRoutes from 'business/organizer/library/pages/question-create-update/file-upload/FileUploadRoutes';
import McqRoutes from 'business/organizer/library/pages/question-create-update/mcq/McqRoutes';
// import ProjectRoutes from 'business/organizer/library/pages/question-create-update/project/ProjectRoutes';
import InterviewRoutes from 'business/organizer/library/pages/question-create-update/interview/InterviewRoutes';
import SqlRoutes from 'business/organizer/library/pages/question-create-update/sql/SqlRoutes';

const QuestionCreateUpdatePage = () => {
  const { url } = useCurrentUrl();

  return (
    <>
      <Switch>
        <Route path={`${url}/challenge`}>
          <ChallengeRoutes />
        </Route>
        <Route path={`${url}/coding`}>
          <CodingRoutes />
        </Route>
        <Route path={`${url}/free-text`}>
          <FreeTextRoutes />
        </Route>
        <Route path={`${url}/file-upload`}>
          <FileUploadRoutes />
        </Route>
        <Route path={`${url}/mcq`}>
          <McqRoutes />
        </Route>
        {/* <Route path={`${url}/project`}>
          <ProjectRoutes />
        </Route> */}
        <Route path={`${url}/interview`}>
          <InterviewRoutes />
        </Route>
        <Route path={`${url}/sql`}>
          <SqlRoutes />
        </Route>
      </Switch>
    </>
  );
};

export default QuestionCreateUpdatePage;

const ViewIcon: React.FC<{ color?: '#A3A3A3' | '#333333' }> = ({
  color = '#333333',
}) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.25 16C13.81 16 14.25 16.44 14.25 17C14.25 17.56 13.81 18 13.25 18C12.69 18 12.25 17.56 12.25 17C12.25 16.44 12.69 16 13.25 16ZM13.25 13C10.52 13 8.19 14.66 7.25 17C8.19 19.34 10.52 21 13.25 21C15.98 21 18.31 19.34 19.25 17C18.31 14.66 15.98 13 13.25 13ZM13.25 19.5C12.587 19.5 11.9511 19.2366 11.4822 18.7678C11.0134 18.2989 10.75 17.663 10.75 17C10.75 16.337 11.0134 15.7011 11.4822 15.2322C11.9511 14.7634 12.587 14.5 13.25 14.5C13.913 14.5 14.5489 14.7634 15.0178 15.2322C15.4866 15.7011 15.75 16.337 15.75 17C15.75 17.663 15.4866 18.2989 15.0178 18.7678C14.5489 19.2366 13.913 19.5 13.25 19.5ZM5.39 17.75L5.1 17L5.39 16.26C6.68 13.06 9.75 11 13.25 11C14.3 11 15.31 11.21 16.25 11.56V6L10.25 0H2.25C1.14 0 0.25 0.89 0.25 2V18C0.25 18.5304 0.460714 19.0391 0.835786 19.4142C1.21086 19.7893 1.71957 20 2.25 20H6.75C6.2 19.34 5.75 18.58 5.39 17.75ZM9.25 1.5L14.75 7H9.25V1.5Z"
      fill={color}
    />
  </svg>
);

export default ViewIcon;

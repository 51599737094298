import { useContext } from 'react';
import FreeTextUpdatePageContext from 'business/organizer/library/contexts/free-text-question/contexts/FreeTextUpdatePageContext';


const useFreeTextData = (): IFreeTextUpdatePageContext => {
    return useContext(
        FreeTextUpdatePageContext
    ) as IFreeTextUpdatePageContext;
};

export default useFreeTextData;

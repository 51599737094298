import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { HubsCandidateService } from 'hubs-candidate/services/hubsCandidateService';

const useTestList = (
  list: string,
  Service: typeof HubsCandidateService | typeof OrganizerService,
  params?: { page_size: number; page: number }
) => {
  const [testsList, setTestsList] = useState<any | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let mounted = true;
    const service = new Service();
    service
      .getTestLists({ list, ...(params || {}) })
      .then((response) => {
        if (mounted) {
          const { results, count } = response?.data;
          setCount(count);
          setTestsList(results);
        }
      })
      .catch((error) => setError(error));

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, params?.page, params?.page_size]);

  return { testsList, error, count, setTestsList };
};

export default useTestList;

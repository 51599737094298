import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import useTheme from '@material-ui/core/styles/useTheme';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  open,
  variant = 'success',
  title,
  children,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  dialogProps,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const colors: {
    [key in NotificationVariant]: string;
  } = {
    success: theme.palette.success.dark,
    warning: theme.palette.warning.light,
    error: theme.palette.error.dark,
    info: theme.palette.info.dark,
  };
  const icons: {
    [key in NotificationVariant]:
      | ((props: SvgIconProps) => JSX.Element)
      | null;
  } = {
    success: CheckCircleRoundedIcon,
    warning: WarningRoundedIcon,
    error: CancelRoundedIcon,
    info: null,
  };
  const VariantIcon = icons[variant];

  return (
    <Dialog
      {...dialogProps}
      open={open}
      maxWidth="sm"
      PaperProps={{
        style: {
          margin: theme.spacing(2),
        },
      }}
    >
      <Grid
        container
        direction="row"
        wrap="nowrap"
        className={classes.dialogGrid}
      >
        <Grid item xs={1} className={classes.dialogGridItem1}>
          <Box
            className={classes.dialogErrorBorder}
            style={{
              borderColor: colors[variant],
            }}
          ></Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.dialogErrorContent}>
            <Box className={classes.dialogContent}>
              <Box mx={2}>
                {VariantIcon !== null && (
                  <VariantIcon
                    fontSize="large"
                    htmlColor={colors[variant]}
                  />
                )}
              </Box>
              <Box>
                {title && (
                  <DialogTitle disableTypography={true}>
                    <Typography variant="h5">{title}</Typography>
                  </DialogTitle>
                )}
                <DialogContent>{children}</DialogContent>
              </Box>
            </Box>
            <Box width="100%">
              <DialogActions>
                {disagreeText && (
                  <Button
                    size="small"
                    onClick={onDisagree}
                    variant="outlined"
                    color="default"
                  >
                    {disagreeText}
                  </Button>
                )}
                {agreeText && (
                  <Button
                    size="small"
                    onClick={onAgree}
                    variant="outlined"
                    color="primary"
                  >
                    {agreeText}
                  </Button>
                )}
              </DialogActions>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& .MuiDialogTitle-root, & .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogContentText-root': {
      marginBottom: 0,
    },
  },
  dialogGrid: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(0),
  },
  dialogGridItem1: {
    flex: 0,
  },
  dialogErrorBorder: {
    borderLeft: theme.spacing(1),
    borderLeftStyle: 'solid',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    mx: theme.spacing(0.5),
  },
  dialogErrorContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
}));

export default ConfirmationDialog;

import React, { useState, useEffect } from 'react';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAsync from 'common/custom-hooks/useAsync';

const organizerService = new OrganizerService();
const useProctoringDetails = (apiUrl: string) => {
  const [params, setParams] = useState({ page_size: 10, page: 0 });
  const { run, data, status, error, setData } = useAsync();

  useEffect(() => {
    if (apiUrl) {
      run(
        organizerService.getEvents(apiUrl, {
          page_size: params.page_size,
          page: params.page + 1,
        })
      );
    }
  }, [apiUrl, params, run]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setData(null);
    setParams((params) => ({ ...params, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setData(null);
    setParams((params) => ({
      ...params,
      page: 0,
      page_size: parseInt(event.target.value),
    }));
  };

  return {
    handlePageChange,
    handleChangeRowsPerPage,
    data,
    status,
    error,
    params,
  };
};

export default useProctoringDetails;

// API urls
export const PROFILE_API_URL = '/api/users/?my=1&auth_type=business';
export const ORGANIZATION_API_URL =
  '/api/organizations/?auth_type=business';

//django url
export const PASSWORD_CHANGE_IFRAME_URL =
  '/profile/edit/password_change/';

export const JOB_TITLES = [
  {
    id: 1,
    label: 'Recruiting Team (HR/TA Specialist)',
    value: 'Recruiting Team (HR/TA Specialist)',
  },
  {
    id: 2,
    label: 'Hiring Manager (Tech Lead/Engineering Manager)',
    value: 'Hiring Manager (Tech Lead/Engineering Manager)',
  },
  { id: 3, label: 'Director/VP', value: 'Director/VP' },
  { id: 4, label: 'CXO', value: 'CXO' },
  { id: 5, label: 'Others', value: 'Others' },
  { id: 6, label: 'I am a Job Seeker', value: 'I am a Job Seeker' },
];

export const jobTitlesList = {
  edu_institution: [
    {
      id: 1,
      label: 'Professor',
      value: 'Professor',
    },
    {
      id: 2,
      label: 'Teaching Assistant',
      value: 'Teaching Assistant',
    },
    { id: 3, label: 'Others', value: 'Others' },
  ],
  edu_organization: [
    {
      id: 1,
      label: 'HR Team',
      value: 'HR Team',
    },
    {
      id: 2,
      label: 'L&D Team',
      value: 'L&D Team',
    },
    { id: 3, label: 'Director/VP', value: 'Director/VP' },
    { id: 4, label: 'CXO', value: 'CXO' },
    { id: 5, label: 'Others', value: 'Others' },
  ],
  skillspace_organization: [
    {
      id: 1,
      label: 'Recruiting Team (HR/TA Specialist)',
      value: 'Recruiting Team (HR/TA Specialist)',
    },
    {
      id: 2,
      label: 'Hiring Manager (Tech Lead/Engineering Manager)',
      value: 'Hiring Manager (Tech Lead/Engineering Manager)',
    },
    { id: 3, label: 'Director/VP', value: 'Director/VP' },
    { id: 4, label: 'CXO', value: 'CXO' },
    { id: 5, label: 'Others', value: 'Others' },
    {
      id: 6,
      label: 'I am a Job Seeker',
      value: 'I am a Job Seeker',
    },
  ],
};

export const AllJobTitlesList = [
  {
    id: 1,
    label: 'Professor',
    value: 'Professor',
  },
  {
    id: 2,
    label: 'Teaching Assistant',
    value: 'Teaching Assistant',
  },
  { id: 3, label: 'Others', value: 'Others' },
  {
    id: 4,
    label: 'Recruiting Team (HR/TA Specialist)',
    value: 'Recruiting Team (HR/TA Specialist)',
  },
  {
    id: 5,
    label: 'Hiring Manager (Tech Lead/Engineering Manager)',
    value: 'Hiring Manager (Tech Lead/Engineering Manager)',
  },
  { id: 6, label: 'Director/VP', value: 'Director/VP' },
  { id: 7, label: 'CXO', value: 'CXO' },
  {
    id: 8,
    label: 'I am a Job Seeker',
    value: 'I am a Job Seeker',
  },
  {
    id: 9,
    label: 'HR Team',
    value: 'HR Team',
  },
  {
    id: 10,
    label: 'L&D Team',
    value: 'L&D Team',
  },
];

export const TEAM_MANAGEMENT_EMPTY_IMAGE =
  '/static/images/business/organizer/team-management-empty.png';

export const HUBS_ORGANIZATION_TYPE = [
  {
    id: 1,
    label: 'Educational Institution',
    value: 'edu_institution',
  },
  {
    id: 2,
    label: 'Organization',
    value: 'edu_organization',
  },
];

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const FadeOutEffect: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.box__fade_out}>
      <Box mt={4}>{children}</Box>
    </Box>
  );
};

export default FadeOutEffect;

const useStyles = makeStyles((theme) => ({
  box__fade_out: {
    background:
      'linear-gradient(179.8deg, rgba(255, 255, 255, 0.28) -27.32%, rgba(255, 255, 255, 0.85) 10.43%, rgba(255, 255, 255, 0.97) 31.21%, #FFFFFF 93.61%)',
    width: '100%',
    height: '100px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
}));

import React, { useMemo, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import HeaderStyles from 'business/organizer/common/components/styles/HeaderStyles';
import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import SearchBar from 'common/components/search-bar/SearchBar';
import InterviewsListBase from 'business/organizer/interviews/components/InterviewsListBase';
import Sidebar from 'business/organizer/interviews/components/Sidebar';
import { HTTP_DEBOUNCE_TIME } from 'common/utils/constants';
import CreateInterview from 'business/organizer/interviews/components/CreateInterview';
import useInterviews from 'business/organizer/interviews/context/useInterviews';
import useAsync from 'common/custom-hooks/useAsync';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { INTERVIEWS_COUNT_API_URL } from 'business/organizer/interviews/utils/constants';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import useHeader from 'business/organizer/common/context/header/useHeader';
import useDebounce from 'common/custom-hooks/useDebounce';

const organizerService = new OrganizerService();
const Interviews = () => {
  const { url } = useCurrentUrl();
  const [search, setSearch] = useState('');
  const { isLoaded, setIsLoaded } = useInterviews();

  const { isViewer } = useHasPermission();
  const { setOpenSidebar } = useHeader();

  const { run, data: counts } = useAsync();

  useEffect(() => {
    if (!isLoaded) {
      run(organizerService.get(INTERVIEWS_COUNT_API_URL));
    }
  }, [run, isLoaded]);

  // show the min version of main sidebar to clearly shoow table
  useEffect(() => {
    setOpenSidebar(false);
    return () => {
      setOpenSidebar(true);
    };
  }, [setOpenSidebar]);

  const statusRoutes = useMemo(
    () => ({
      completed: {
        id: 1,
        label: 'Completed',
        value: 'completed',
        count: counts?.completed || 0,
        route: `${url}/completed`,
      },
      inProgress: {
        id: 2,
        label: 'In Progress',
        value: 'in-progress',
        count: counts?.in_progress || 0,
        route: `${url}/in-progress`,
      },
      notStarted: {
        id: 3,
        label: 'Not Started',
        value: 'not-started',
        count: counts?.not_started || 0,
        route: `${url}/not-started`,
      },
    }),
    [url, counts]
  );

  const all = useMemo(
    () => ({
      all: {
        id: 1,
        label: 'All Interviews',
        value: 'all',
        count: counts?.all || 0,
        route: `${url}/all`,
      },
    }),
    [url, counts]
  );

  const verdictRoutes = useMemo(
    () => ({
      shortlisted: {
        id: 1,
        label: 'shortlisted',
        value: 'Shortlisted',
        count: counts?.shortlisted || 0,
        route: `${url}/shortlisted`,
      },
      rejected: {
        id: 2,
        label: 'Rejected',
        value: 'rejected',
        count: counts?.rejected || 0,
        route: `${url}/rejected`,
      },
      pending: {
        id: 3,
        label: 'Pending',
        value: 'pending',
        count: counts?.pending || 0,
        route: `${url}/pending`,
      },
    }),
    [url, counts]
  );

  const handleSearch = useDebounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setIsLoaded(false);
    },
    HTTP_DEBOUNCE_TIME
  );

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Interviews', route: url }]}
      />
      <HeaderStyles>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={2}
        >
          <Box width="350px">
            <SearchBar handleSearch={handleSearch} />
          </Box>
          <Box>
            <HasPermission
              isViewer={isViewer}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <CreateInterview />
            </HasPermission>
          </Box>
        </Box>
      </HeaderStyles>
      <Container maxWidth={false}>
        <Box display="flex" py={12} gridColumnGap={16} width="100%">
          <Box width="100%">
            {/* Why keys? https://stackoverflow.com/questions/49001001/using-same-component-for-different-route-path-in-react-router-v4 */}
            <Switch>
              <Route
                key={statusRoutes.completed.label}
                path={statusRoutes.completed.route}
              >
                <InterviewsListBase
                  status="completed"
                  search={search}
                />
              </Route>
              <Route
                key={statusRoutes.inProgress.label}
                path={statusRoutes.inProgress.route}
              >
                <InterviewsListBase
                  status="in_progress"
                  search={search}
                />
              </Route>
              <Route
                key={statusRoutes.notStarted.label}
                path={statusRoutes.notStarted.route}
              >
                <InterviewsListBase
                  status="not_started"
                  search={search}
                />
              </Route>
              <Route
                key={verdictRoutes.shortlisted.label}
                path={verdictRoutes.shortlisted.route}
              >
                <InterviewsListBase
                  verdict="shortlisted"
                  search={search}
                />
              </Route>
              <Route
                key={verdictRoutes.rejected.label}
                path={verdictRoutes.rejected.route}
              >
                <InterviewsListBase
                  verdict="rejected"
                  search={search}
                />
              </Route>
              <Route
                key={verdictRoutes.pending.label}
                path={verdictRoutes.pending.route}
              >
                <InterviewsListBase
                  verdict="pending"
                  search={search}
                />
              </Route>
              <Route key={all.all.label} path={all.all.route}>
                <InterviewsListBase search={search} />
              </Route>
              <Redirect from={url} to={all.all.route} />
            </Switch>
          </Box>
          <Box position="sticky" mr={4}>
            <Sidebar
              all={Object.values(all)}
              statusRoutes={Object.values(statusRoutes)}
              verdictRoutes={Object.values(verdictRoutes)}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Interviews;

import React from 'react';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import useProgrammingLanguages from 'business/organizer/library/custom-hooks/useProgrammingLanguages';
import FilterByTagsBase from 'business/organizer/library/components/filter/FilterByTagsBase';

const FilterByLanguages: React.FC<{ tagName: LibraryTagName }> = ({
  tagName,
}) => {
  const { data: tagSuggestions } = useProgrammingLanguages();

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    // Internally, the autocomplete filter will check for the inputted text
    // in this string. So we include synonyms also.
    stringify: (tag: any) => `${tag.name}`,
    trim: true,
  });

  return (
    <FilterByTagsBase
      tagName={tagName}
      label="Programming Languages"
      inputPlaceholder="JavaScript"
      tagSuggestions={tagSuggestions}
      filterOptions={filterOptions}
    />
  );
};

export default FilterByLanguages;

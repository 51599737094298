import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import ImageHelper from 'common/components/image-helper/ImageHelper';
import {
  LEFT_QUOTE_IMAGE,
  RIGHT_QUOTE_IMAGE,
  TESTIMONIAL_IMAGE,
} from 'business/pre-subscription/auth/utils/constants';

const Testimonial = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={24}
      component="section"
    >
      <Box className={classes.box__testimonial}>
        <Box className={classes.box__quote_left}>
          <ImageHelper
            imageUrl={LEFT_QUOTE_IMAGE}
            altProp="quote-left"
          />
        </Box>
        <Box className={classes.box__testimonial_card}>
          <Box>
            <Typography>
              “We were highly satisfied by the outstanding support
              from the DPhi team and looking forward to continuing our
              association.”
            </Typography>
          </Box>
          <Box py={4} display="flex" justifyContent="flex-end">
            <Box width={150}>
              <Divider />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gridColumnGap={12}>
            <ImageHelper
              imageUrl={TESTIMONIAL_IMAGE}
              altProp="skillspace-user"
              lazyLoad
              className={classes.testimonial__image}
            />
            <Box className={classes.typography__name}>
              <Typography variant="h5">
                Subramanian Karunanithi
              </Typography>
              <Typography variant="h4" color="primary">
                Engineering Lead, Cisco
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.box__quote_right}>
          <ImageHelper
            imageUrl={RIGHT_QUOTE_IMAGE}
            altProp="quote-right"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonial;

const useStyles = makeStyles((theme) => ({
  box__testimonial: {
    position: 'relative',
    width: '80%',
    [theme.breakpoints.down(545)]: {
      width: 270,
    },
  },
  box__testimonial_card: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow:
      '0px 0px 16px rgba(10, 102, 194, 0.25), 0px 0px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: 16,
    padding: theme.spacing(6, 8),
    zIndex: 1,
  },
  box__quote_left: {
    width: '100%',
    position: 'absolute',
    top: -32,
    right: 42,
    [theme.breakpoints.down(545)]: {
      right: 24,
      top: -32,
    },
  },
  box__quote_right: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: -32,
    left: 42,
    [theme.breakpoints.down(545)]: {
      left: 24,
      bottom: -34,
    },
  },
  testimonial__image: {
    [theme.breakpoints.down(545)]: {
      width: 64,
      height: 64,
    },
  },
  typography__heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(6),
    },
  },
  typography__name: {
    [theme.breakpoints.down(545)]: {
      '& .MuiTypography-h5': {
        fontSize: theme.spacing(3.5),
      },
      '& .MuiTypography-h4': {
        fontSize: theme.spacing(3.5),
        fontWeight: 600,
      },
    },
  },
}));

import React from 'react';

const FacebookIcon = () => (
  <svg
    width="18"
    height="25"
    viewBox="0 0 18 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3283 21.475V13.1343H13.1421L13.5604 9.86869H10.3283V7.78861C10.3283 6.84626 10.5909 6.20107 11.9433 6.20107H13.657V3.28957C12.8232 3.20021 11.9851 3.15706 11.1465 3.16033C8.65936 3.16033 6.95174 4.67866 6.95174 7.46601V9.86258H4.15625V13.1282H6.95784V21.475H10.3283Z"
      fill="#1877F2"
    />
  </svg>
);

export default FacebookIcon;

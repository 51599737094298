import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core';

const QuestionsAttemptedBar: React.FC<{
  total_attempted_questions: number;
  total_questions: number;
}> = ({ total_attempted_questions, total_questions }) => {
  const percentage =
    (total_attempted_questions / total_questions) * 100;

  const getBackgroundColor = () => {
    if (percentage > 70) {
      return '#44924C';
    } else if (percentage > 40) {
      return '#FFC233';
    } else {
      return '#ED254E';
    }
  };
  const classes = useStyles({
    backgroundColor: getBackgroundColor(),
  });

  return (
    <div className={classes.questionsAttempted__root}>
      <Box
        width="80%"
        display="flex"
        flexDirection="column"
        gridRowGap={4}
      >
        <Typography>
          {total_attempted_questions}/{total_questions}
        </Typography>
        <Box width="100%">
          <LinearProgress value={percentage} variant="determinate" />
        </Box>
      </Box>
    </div>
  );
};

export default QuestionsAttemptedBar;

const useStyles = makeStyles<Theme, { backgroundColor: string }>(
  (theme) => ({
    questionsAttempted__root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiLinearProgress-root': {
        height: theme.spacing(2),
        borderRadius: theme.spacing(25),
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#F1F6F9',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
      },
    },
  })
);

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Question name should be at least 1 characters long')
    .required('Question name is required'),
  description: Yup.string(),
  time_duration: Yup.number()
    .required('Time is required.')
    .typeError('Time value must be number'),
  difficulty: Yup.string()
    .oneOf(
      ['easy', 'medium', 'hard'],
      'This is not the valid choice.'
    )
    .required('Difficulty level is required.'),
  interview_question_type: Yup.string()
    .oneOf(
      ['coding_challenge', 'whiteboard'],
      'This is not the valid choice.'
    )
    .required('Difficulty level is required.'),
  tags: Yup.array()
    .min(1, 'Please add at least 1 tag.')
    .required('Tags are required'),
});

export const interviewQuestionTypes = [
  {
    label: 'Coding Challenge',
    value: 'coding_challenge',
  },
  {
    label: 'Whiteboard',
    value: 'whiteboard',
  },
];

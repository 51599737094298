import React, { useState } from 'react';
import FreeTextUpdatePageContext from 'business/organizer/library/contexts/free-text-question/contexts/FreeTextUpdatePageContext';

const FreeTextUpdateProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [freeText, setFreeText] = useState<any | null>(null);

  return (
    <FreeTextUpdatePageContext.Provider
      value={{ freeText, setFreeText }}
    >
      {children}
    </FreeTextUpdatePageContext.Provider>
  );
};

export default FreeTextUpdateProvider;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import GenericNavbar from 'business/organizer/common/components/GenericNavbar';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import HubsCandidateProgressQuestionSummary from 'hubs-candidate/assessments/Pages/QuestionSummary';

const HubsCandidateDetailTabs: React.FC<{
  question_summary: any;
}> = ({ question_summary }) => {
  const { url } = useCurrentUrl();
  let tabs = [
    {
      id: 1,
      label: 'Questions',
      route: `${url}/questions`,
      badge: false,
    },
  ];

  return (
    <>
      <Box>
        <GenericNavbar tabs={tabs} />
      </Box>
      <Switch>
        <Route path={tabs[0].route}>
          <HubsCandidateProgressQuestionSummary
            question_summary={question_summary}
          />
        </Route>
        <Redirect from={url} to={tabs[0].route} />
      </Switch>
    </>
  );
};

export default HubsCandidateDetailTabs;

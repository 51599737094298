export const getProctor = (
  proctor_settings: ITestDetailProctorSettingsProps[] | undefined,
  name: string
) => proctor_settings?.find((proctor) => proctor.name === name);

export enum PROCTOR_NAME {
  COPY_DETECTION = 'copy-detection',
  WINDOW_SWITCHING = 'window-switching',
  AI_PROCTORING = 'face-image-proctoring',
}

export const getDuration = (value: number | null | undefined) => {
  return value === null || value === undefined ? null : value;
};

import { useEffect, useState } from 'react';
import DayJsUtils from '@date-io/dayjs';
import { Dayjs } from 'dayjs';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useFormikContext, ErrorMessage } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';

const DatePickerField: React.FC<IDatePickerFieldProps> = ({
  date,
  name,
  datePickerProps,
}) => {
  const { setFieldValue } =
    useFormikContext<IGenericDateTimePickerUseFormikContext>();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    null
  );

  useEffect(() => {
    if (!!date) {
      setSelectedDate(date as Dayjs);
      setFieldValue(name, date);
    }
  }, [date, name, setFieldValue]);

  const handleDateChange = (date: Dayjs | null) => {
    setFieldValue(name, date);
    setSelectedDate(date);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <KeyboardDatePicker
          value={selectedDate}
          id={`${name}-picker`}
          {...datePickerProps}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      <ErrorMessage name={name}>
        {(msg) => <FormHelperText error={true}>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

export default DatePickerField;

import React from 'react';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Logo from 'common/components/header/Logo';
import { SKILLSPACE_URL } from 'common/utils/constants';
import { HERO_IMAGE_RIGHT } from 'business/pre-subscription/auth/utils/constants';
import FormBoxStyles from 'business/pre-subscription/common/components/FormBoxStyles';
import theme from 'business/common/theme/theme';
import LogoLg from 'common/components/header/LogoLg';

const FormSectionBase: React.FC<{
  children: JSX.Element;
  title?: string;
  subtitle?: string;
  caption?: React.ReactChild;
  logo?: boolean;
}> = ({ children, title, subtitle, caption, logo = true }) => {
  const classes = useStyles();
  const isDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={classes.box__root} component="section">
      <Box display="flex" alignItems="center" pt={{ md: 6 }}>
        <Container
          maxWidth="sm"
          className={classes.container__right_container}
        >
          <FormBoxStyles p={6}>
            {logo && (
              <Box display="flex" justifyContent="center" pb={4}>
                <MuiLink href={SKILLSPACE_URL}>
                  {isDownSM ? <Logo /> : <LogoLg />}
                </MuiLink>
              </Box>
            )}
            {title && (
              <Typography variant="h4" color="primary" align="center">
                {title}
              </Typography>
            )}
            {subtitle && (
              <Box pt={1}>
                <Typography align="center">{subtitle}</Typography>
              </Box>
            )}
            <Box px={{ sm: 6 }} pt={6} component="main">
              {children}
            </Box>
          </FormBoxStyles>
          {caption}
        </Container>
      </Box>
    </Box>
  );
};

export default FormSectionBase;

const useStyles = makeStyles((theme) => ({
  box__root: {
    backgroundImage: `url(${HERO_IMAGE_RIGHT})`,
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  container__right_container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  box__show_below_md: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'unset',
    },
  },
}));

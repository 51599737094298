import React from 'react';
import QuestionListItemSkeleton from './QuestionListItem';
import { Grid } from '@material-ui/core';

const QuestionListSkeleton: React.FC = (): JSX.Element => {
  const itemCount = 4;
  return (
    <>
      <Grid container spacing={4}>
        {[...Array(itemCount)].map((_, idx) => (
          <Grid item xs={12} key={idx}>
            <QuestionListItemSkeleton />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default QuestionListSkeleton;

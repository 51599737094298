import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { toTitleSnakeCase } from 'common/utils/toTitleCase';

const LABELS: { [key: string]: string } = {
  invitation_sent_on: 'Invite Sent On',
  total_attempted_questions: 'Questions Attempted',
  total_score: 'Score',
  test_end_time: 'Completed On',
  test_start_time: 'Started On',
  rejected_on: 'Rejected On',
  shortlisted_on: 'Shortlisted On',
  invitation_expires_at: 'Invite Expires On',
  invite_cancelled_on: 'Invite Cancelled On',
  invite_accepted_on: 'Invite Accepted On',
};

const ShowAppliedParams: React.FC<
  Pick<
    IGetCandidateProgressListParams,
    'order' | 'order_by' | 'test_status'
  >
> = ({ order_by, order, test_status }) => {
  return (
    <Box py={2}>
      <Typography color="textSecondary" variant="body2">
        {!!order_by &&
          `Sorted by '${LABELS[order_by]}' in ${order} order`}
        {!!test_status?.length &&
          `${!!order_by ? ', ' : ''}Filtered by ${test_status.map(
            (status) => toTitleSnakeCase(status)
          )}`}
      </Typography>
    </Box>
  );
};

export default ShowAppliedParams;

import React from 'react';

import Editor from './Editor';
import SettingsProvider from './SettingsProvider';

const MonacoEditor: typeof Editor = (props) => {
  return (
    <SettingsProvider>
      <Editor {...props} />
    </SettingsProvider>
  );
};

export default MonacoEditor;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { getQuestionDisplayName } from 'business/organizer/common/utils/QuestionDisplayNames';

const QuestionItemType = ({ type }: { type: IQuestionType }) => {
  return (
    <Box
      py={1}
      px={2}
      border="0.1px solid #FBEED0"
      borderRadius={4}
      bgcolor="#fafafa"
    >
      <Typography color="textSecondary" variant="body2">
        {getQuestionDisplayName(type)}
      </Typography>
    </Box>
  );
};

export default QuestionItemType;

import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

import OrganizerContext from 'business/organizer/common/context/organizer/OrganizerContext';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useEmailVerification from 'business/pre-subscription/auth/context/email-verification/useEmailVerification';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import {
  HIRING_APP_TYPE_PARAM,
  HUBS_APP_TYPE,
  HUBS_APP_TYPE_PARAM,
} from 'common/utils/constants';

const organizerService = new OrganizerService();

const OrganizerProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [organizer, setOrganizer] = useState<IOrganizerProps | null>(
    null
  );
  const [error, setError] = useState<AxiosError | null>(null);
  const [isFreeTrial, setIsFreeTrial] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const { emailVerificationResponse } = useEmailVerification();
  const { isHubsUrl } = useCurrentUrl();

  useEffect(() => {
    let mounted = true;
    const freeTrialPlans = [
      'free-trial-edu-institution',
      'free-trial-edu-organization',
      'free-trial',
    ];

    if (!isLoaded) {
      organizerService
        .getOrganizer(
          isHubsUrl ||
            (emailVerificationResponse !== null &&
              emailVerificationResponse.app_type === HUBS_APP_TYPE)
            ? HUBS_APP_TYPE_PARAM
            : HIRING_APP_TYPE_PARAM
        )
        .then((response) => {
          if (mounted) {
            const { data } = response;
            setOrganizer(data[0]);
            setIsFreeTrial(
              freeTrialPlans.includes(
                data[0].current_subscription.plan.chargebee_plan_id
              )
            );
            setIsLoaded(true);
          }
        })
        .catch((error) => setError(error));
    }

    return () => {
      mounted = false;
    };
  }, [emailVerificationResponse, isHubsUrl, isLoaded]);

  return (
    <OrganizerContext.Provider
      value={{
        organizer,
        setOrganizer,
        error,
        isFreeTrial,
        setIsFreeTrial,
        isLoaded,
        setIsLoaded,
      }}
    >
      {children}
    </OrganizerContext.Provider>
  );
};

export default OrganizerProvider;

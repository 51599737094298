import { useClipboard } from 'use-clipboard-copy';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import CopyIcon from './icons/CopyIcon';

const CopyInterviewLink: React.FC<{
  interview: Interviews.IInterviewsListItem;
}> = ({ interview }) => {
  const clipboard = useClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    clipboard.copy(interview.interview_link);
    enqueueSnackbar('Interview link is copied.', {
      variant: 'success',
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
    });
  };

  return (
    <Button startIcon={<CopyIcon />} onClick={handleCopy}>
      Copy
    </Button>
  );
};

export default CopyInterviewLink;

import React, { useState } from 'react';
import InterviewContext from './InterviewsContext';

const InterviewsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <InterviewContext.Provider value={{ isLoaded, setIsLoaded }}>
      {children}
    </InterviewContext.Provider>
  );
};

export default InterviewsProvider;

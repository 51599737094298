import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import QuestionBase from 'business/organizer/library/components/question-create-update/common/QuestionBase';
import MyInput from 'common/components/form/MyInput';
import FieldLabel from 'business/organizer/library/components/question-create-update/common/FieldLabel';
import RichTextField from 'common/components/form/RichTextField';
import SelectField from 'common/components/form/SelectField';
import QuestionTagField from 'business/organizer/library/components/question-create-update/common/QuestionTagField';
import { difficultyItems } from 'business/organizer/library/components/question-create-update/free-text/utils/constants';
import {
  interviewQuestionTypes,
  validationSchema,
} from 'business/organizer/library/components/question-create-update/interview/helpers';
import InterviewFormActions from 'business/organizer/library/components/question-create-update/interview/InterviewFormActions';
import useAlert from 'common/components/alert-provider/useAlert';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import useInterviewQuestion from 'business/organizer/library/contexts/interview-question/useInterviewQuestion';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import { getTagsList } from 'business/organizer/library/utils/getTagsList';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import { UPDATE_LIBRARY } from 'business/organizer/library/contexts/library/libraryActionTypes';
import { hasMessageProperty } from 'common/utils/errors';

const libraryService = new LibraryService();
const InterviewPage: React.FC<InterviewQuestion.IInterviewPageProps> =
  ({ drawerName, interview }) => {
    const { enqueueAlert } = useAlert();
    const { dispatch } = useLibrary();

    const {
      interviewQuestionResponse,
      setInterviewQuestionResponse,
    } = useInterviewQuestion();

    const question = interview?.question;
    const initialState = {
      name: question?.name || '',
      description: question?.description || '',
      difficulty: question?.difficulty || '',
      time_duration: question?.time_duration || '',
      interview_question_type:
        question?.interview_question_type || '',
      tags: interview?.tags || [],
    };

    const handleSubmit = async (
      data: InterviewQuestion.IInitialState
    ) => {
      try {
        const {
          name,
          description,
          difficulty,
          time_duration,
          interview_question_type,
          tags,
        } = data;

        const questionData: { [key: string]: {} } = {
          type: 'interview',
          tags: getTagsList(tags),
          question: {
            name,
            description,
            difficulty,
            interview_question_type,
          },
        };

        if (time_duration) {
          questionData.question = {
            ...questionData.question,
            time_duration,
          };
        }

        const response =
          !!interview || !!interviewQuestionResponse
            ? await libraryService.patch(
                interview?.url ||
                  (interviewQuestionResponse?.url as string),
                questionData
              )
            : await libraryService.createQuestion(
                MY_LIBRARY_QUESTION_API_URL,
                questionData
              );

        setInterviewQuestionResponse(response?.data);
        dispatch({ type: UPDATE_LIBRARY, payload: { list: 'my' } });

        enqueueAlert('Question is created successfully.', {
          alertProps: { severity: 'success' },
        });
      } catch (error) {
        enqueueAlert(
          `${
            hasMessageProperty(error)
              ? error.message
              : '' ||
                `Something went wrong while ${
                  interview !== null ? 'updating' : 'adding'
                } question. Please try again after sometime`
          }`,
          {
            alertProps: { severity: 'error' },
          }
        );
      }
    };

    // reset context state(clear the effects)
    useEffect(() => {
      return () => {
        setInterviewQuestionResponse(null);
      };
    }, [setInterviewQuestionResponse]);

    return (
      <QuestionBase name="Interview" drawerProps={{ drawerName }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              gridRowGap={12}
            >
              <FieldLabel title="Question" isRequired={true}>
                <MyInput
                  name="name"
                  placeholder="Question"
                  aria-label="question name"
                />
              </FieldLabel>
              <FieldLabel
                title="Question Description"
                isRequired={false}
              >
                <RichTextField name="description" />
              </FieldLabel>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <FieldLabel
                    title="Max time duration"
                    isRequired={false}
                    tooltip
                    tooltipTitle="Mention maximum time duration to solve this question"
                  >
                    <MyInput
                      placeholder="Minutes"
                      name="time_duration"
                      type="number"
                      aria-label="time duration"
                    />
                  </FieldLabel>
                </Grid>
                <Grid item xs={4}>
                  <FieldLabel
                    title="Difficulty level"
                    isRequired={false}
                    tooltip
                    tooltipTitle="For your reference"
                  >
                    <SelectField
                      name="difficulty"
                      placeholder="Level of Difficulty"
                      items={difficultyItems}
                      aria-label="difficulty level"
                    />
                  </FieldLabel>
                </Grid>
                <Grid item xs={4}>
                  <FieldLabel
                    title="Interview Question Type"
                    isRequired={false}
                    tooltip
                    tooltipTitle="For your reference"
                  >
                    <SelectField
                      name="interview_question_type"
                      placeholder="Interview Question Type"
                      items={interviewQuestionTypes}
                      aria-label="interview question type"
                    />
                  </FieldLabel>
                </Grid>
              </Grid>
              <QuestionTagField />
            </Box>
            <Box display="flex" justifyContent="flex-end" py={8}>
              <InterviewFormActions />
            </Box>
          </Form>
        </Formik>
      </QuestionBase>
    );
  };

export default InterviewPage;

const TotalInterviewInvites = () => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 16V6H6V16H22ZM22 4C22.5304 4 23.0391 4.21071 23.4142 4.58579C23.7893 4.96086 24 5.46957 24 6V16C24 17.11 23.1 18 22 18H16V20H18V22H10V20H12V18H6C4.89 18 4 17.11 4 16V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H22ZM2 2V14H0V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H20V2H2Z"
      fill="#0A66C2"
    />
  </svg>
);

export default TotalInterviewInvites;

import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { Zoom } from '@material-ui/core';

import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import HubsCandidateProgressDetailPage from 'hubs-candidate/assessments/Pages/CandidateDetailsPage';

const HubsCandidateTestListItemButtons: React.FC<ITestActionsProps> =
  ({ testItem }) => {
    const { url } = useCurrentUrl();
    const view_result_url = `${url}/${testItem.id}/view_results`;
    const { toggleDrawer } = useToggleDrawer();
    const [resultsDrawer, setResultsDrawer] = useState(false);

    const attemptTetUrlIsEmpty: boolean =
      !testItem.attempt_test_url || testItem.attempt_test_url === '';

    const viewResultsUrlIsEmpty: boolean =
      !testItem.view_result_url || testItem.view_result_url === '';

    return (
      <>
        <Box display="flex" alignItems="center" gridColumnGap="12px">
          <Box>
            <Tooltip
              title={
                attemptTetUrlIsEmpty
                  ? 'You have already taken the test'
                  : 'Click to attempt the test'
              }
              arrow={true}
              placement="top"
              TransitionComponent={Zoom}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  href={`${testItem.attempt_test_url}`}
                  target="_blank"
                  disabled={attemptTetUrlIsEmpty}
                >
                  Attempt Test
                </Button>
              </span>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip
              title={
                viewResultsUrlIsEmpty
                  ? 'You have not taken the test yet'
                  : 'Click to view your result'
              }
              arrow={true}
              placement="top"
              TransitionComponent={Zoom}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={viewResultsUrlIsEmpty}
                  onClick={toggleDrawer(
                    true,
                    setResultsDrawer,
                    'results',
                    view_result_url
                  )}
                >
                  View Results
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <CustomDrawer
          drawer={resultsDrawer}
          setDrawer={setResultsDrawer}
          drawerName="results"
        >
          <Route path={view_result_url}>
            <>
              <CloseDrawerIcon
                closeQuestionDrawer={toggleDrawer(
                  false,
                  setResultsDrawer,
                  'results'
                )}
              />
              <HubsCandidateProgressDetailPage
                viewResultsUrl={testItem.view_result_url}
                testItem={testItem}
              />
            </>
          </Route>
        </CustomDrawer>
      </>
    );
  };

export default HubsCandidateTestListItemButtons;

import React from 'react';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import {
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import theme from 'business/common/theme/theme';
import { inputWithLabelRootStyles } from './utils';

const InputWithLabel: React.FC<IInputWithLabel> = ({
  title,
  children,
  isRequired,
  downMD = 'h6',
  upMD = 'h5',
  useStyles = inputWithLabelStyles,
  tooltip = false,
  tooltipTitle,
  tooltipPlacement = 'right',
  icon = <HelpIcon fontSize="inherit" />,
}) => {
  const classes = useStyles();
  const isDownMD = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <FormGroup className={classes.root}>
      <Box my={{ xs: 1, md: 2 }}>
        <FormLabel required={isRequired}>
          <Typography
            component="span"
            variant={isDownMD ? downMD : upMD}
            color="textPrimary"
          >
            {title}
          </Typography>
          {tooltip && (
            <Tooltip
              arrow
              title={typeof tooltipTitle === 'string' && tooltipTitle}
              placement={tooltipPlacement}
            >
              <IconButton style={{ fontSize: 14 }} size="small">
                {icon}
              </IconButton>
            </Tooltip>
          )}
        </FormLabel>
      </Box>
      {children}
    </FormGroup>
  );
};

export default InputWithLabel;

const inputWithLabelStyles = makeStyles((theme) => ({
  root: {
    ...inputWithLabelRootStyles,
  },
}));

import React from 'react';
import Typography from '@material-ui/core/Typography';

import LanguagesListBase from 'business/organizer/library/components/question-create-update/coding/languages/LanguagesListBase';
import SettingsBoxOuterContainer from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxOuterContainer';
import SettingsBoxInnerContainer from 'business/organizer/assessment/components/assessment-tests/test-detail/common/styles/SettingsBoxInnerContainer';

const LanguagesList = () => (
  <SettingsBoxOuterContainer>
    <SettingsBoxInnerContainer>
      <Typography variant="subtitle2">
        Select the programming languages you want to allow for the test
      </Typography>
      <Typography color="textSecondary">
        By default, all programming languages selected in a question are allowed. 
        You can, however, select the languages you wish to allow at the test level.
      </Typography>
      <LanguagesListBase />
    </SettingsBoxInnerContainer>
  </SettingsBoxOuterContainer>
);

export default LanguagesList;

import React from 'react';
import Box from '@material-ui/core/Box';

interface IConfirmationItemProps {
  children: React.ReactElement[];
  invitations: string[];
}

const ConfirmationItem: React.FC<IConfirmationItemProps> = ({
  invitations,
  children,
}) => {
  return (
    <>
      {!!invitations ? (
        <Box
          display="flex"
          alignItems="center"
          gridColumnGap={4}
          pb={2}
        >
          {children}
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

export default ConfirmationItem;

import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import WhatshotIcon from '@material-ui/icons/Whatshot';

import QuestionStatsItem from 'business/organizer/library/components/question-preview/common/QuestionStatsItem';
import { QuestionType } from 'business/organizer/library/utils/utils';

const QuestionItemStatsList: React.FC<IQuestionItemStatsListProps> =
  ({ difficulty, score, time_duration, type }) => {
    let statsData = [
      {
        id: 1,
        Icon: WhatshotIcon,
        content: difficulty || '-',
      },
      {
        id: 3,
        Icon: AccessTimeIcon,
        content: time_duration || '-',
      },
    ];

    if (type !== QuestionType.INTERVIEW) {
      statsData = [
        ...statsData,
        {
          id: 2,
          Icon: TrackChangesOutlinedIcon,
          content: `Score: ${score || '-'}`,
        },
      ];
      statsData.sort((prev, next) => prev.id - next.id);
    }

    return (
      <>
        {statsData.map(({ Icon, id, content }) => (
          <QuestionStatsItem
            key={id}
            IconImage={Icon}
            content={content}
          />
        ))}
      </>
    );
  };

export default QuestionItemStatsList;

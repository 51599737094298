import React from 'react';
import Box from '@material-ui/core/Box';
import { CSSProperties } from '@material-ui/styles';

const defaultStyles: CSSProperties = {
  maxWidth: '560px',
  position: 'fixed',
  left: '50%',
  top: 0,
  transform: 'translate(-50%, 0)',
  width: '100%',
  zIndex: 9999,
};

const AlertContainer: React.FC<IAlertContainerProps> = ({
  children,
  styles = defaultStyles,
}): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={5}
      mt={5}
      style={styles}
    >
      {children}
    </Box>
  );
};

export default AlertContainer;

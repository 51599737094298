const OpenMenuIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4"
      y="6"
      width="16"
      height="1.2"
      rx="0.6"
      fill="#ffffff"
    />
    <rect
      x="4"
      y="11"
      width="16"
      height="1"
      rx="0.5"
      fill="#ffffff"
    />
    <rect
      x="4"
      y="16"
      width="16"
      height="1"
      rx="0.5"
      fill="#ffffff"
    />
  </svg>
);

export default OpenMenuIcon;

import React, { useState, useEffect } from 'react';

import {
  ADD_QUESTION_TYPE,
  REMOVE_QUESTION_TYPE,
} from 'business/organizer/library/contexts/library/libraryActionTypes';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import FilterMenu from 'business/organizer/library/components/filter/FilterMenu';
import ListOfFilters from 'business/organizer/library/components/filter/ListOfFilters';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';

const FilterByType = () => {
  const { state, dispatch } = useLibrary();
  const { testId } = useTestId();

  const [questionType, setQuestionType] = useState<IFilterItemProps>({
    coding: false,
    challenge: false,
    free_text: false,
    file_upload: false,
    mcq: false,
    // project: false,
    // interview: false,
  });

  useEffect(() => {
    setQuestionType({
      coding: state.globalParams.type.includes('coding')
        ? true
        : false,
      challenge: state.globalParams.type.includes('challenge')
        ? true
        : false,
      free_text: state.globalParams.type.includes('free_text')
        ? true
        : false,
      file_upload: state.globalParams.type.includes('file_upload')
        ? true
        : false,
      mcq: state.globalParams.type.includes('mcq') ? true : false,
      // project: state.globalParams.type.includes('project')
      //   ? true
      //   : false,
      // interview: state.globalParams.type.includes('interview')
      //   ? true
      //   : false,
      sql: state.globalParams.type.includes('sql') ? true : false,
    });
  }, [state.globalParams.type]);

  let questionTypesData = [
    { label: 'Coding', value: 'coding' },
    { label: 'AI/ML Challenge', value: 'challenge' },
    { label: 'Free Text', value: 'free_text' },
    { label: 'File Upload', value: 'file_upload' },
    { label: 'MCQ', value: 'mcq' },
    // { label: 'Take Home Assignment', value: 'project' },
    { label: 'Sql', value: 'sql' },
  ];

  if (!testId) {
    questionTypesData = [
      ...questionTypesData,
      // { label: 'Interview', value: 'interview' },
    ];
  }

  const handleQuestionType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked, value } = event.target;
    setQuestionType({
      ...questionType,
      [name]: checked,
    });
    dispatch({
      type: checked ? ADD_QUESTION_TYPE : REMOVE_QUESTION_TYPE,
      payload: value,
    });
  };

  return (
    <FilterMenu label="Type Of Questions">
      <ListOfFilters
        data={questionTypesData}
        state={questionType}
        handleChange={handleQuestionType}
      />
    </FilterMenu>
  );
};

export default FilterByType;

import React, { useState } from 'react';

import useCustomStepper from 'common/components/custom-stepper/useCustomStepper';

import ChallengeContext from 'business/organizer/library/contexts/challenge-question/contexts/ChallengeContext';

import { LibraryService } from 'business/organizer/library/services/libraryService';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import useChallengeQuestionData from 'business/organizer/library/contexts/challenge-question/custom-hooks/useChallengeQuestionData';
import { stepsInfo } from 'business/organizer/library/pages/question-create-update/challenge/ChallengePage';
import useAlert from 'common/components/alert-provider/useAlert';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import { getTagsList } from 'business/organizer/library/utils/getTagsList';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { UPDATE_LIBRARY } from 'business/organizer/library/contexts/library/libraryActionTypes';

const libraryService = new LibraryService();

export enum StepLabel {
  PROBLEM_STATEMENT = 0,
  DATASET = 1,
  OTHER_DETAILS = 2,
}

const ChallengeQuestionProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { enqueueAlert } = useAlert();

  const { dispatch } = useLibrary();

  const { challenge } = useChallengeQuestionData();

  const { handleAddQuestionsToTest } = useAddQuestionsToTest();

  const [
    problemStatementResponse,
    setProblemStatementResponse,
  ] = useState<any | null>(null);

  const [
    dataset,
    setDataset,
  ] = useState<IChallengeQuestionDatasetProps>({
    questionResponse: null,
    evaluationResponse: null,
  });

  const [
    datasetFiles,
    setDatasetFiles,
  ] = useState<IDatasetFilesProps>({
    trainingDataset: null,
    testingDataset: null,
    evaluationDataset: null,
    sampleSubmission: null,
  });

  const [
    otherDetails,
    setOtherDetails,
  ] = useState<IChallengeQuestionOtherDetailsProps>({
    rulesResponse: null,
  });

  const {
    activeStep,
    prevStep,
    nextStep,
    completedSteps,
    setActiveStep,
  } = useCustomStepper(stepsInfo.length);

  const handleProblemStatementSubmit = async (
    data: IInitialStateChallengeFormProps,
    actions: any
  ) => {
    try {
      const {
        name,
        tags,
        time_duration,
        difficulty,
        description,
        score,
      } = data;

      const questionData = {
        question: {
          name,
          time_duration,
          difficulty,
          score,
          assessment_type: 'Maximize',
          overview: description,
        },
        type: 'challenge',
        tags: getTagsList(tags),
      };

      const response =
        challenge !== null ||
        completedSteps.has(StepLabel.PROBLEM_STATEMENT)
          ? await libraryService.patchQuestion(
              challenge?.url || problemStatementResponse?.url,
              questionData
            )
          : await libraryService.createQuestion(
              MY_LIBRARY_QUESTION_API_URL,
              questionData
            );
      if (response) {
        setProblemStatementResponse(response?.data);

        dispatch({ type: UPDATE_LIBRARY, payload: { list: 'my' } });

        /**we are doing this because we want formik should
         not show errors without user doing anthing in the next step
         because in the first step all the states
         are touched = true when we submit the form
         *  */
        actions.setTouched({});
        nextStep();
      }
    } catch (error) {
      enqueueAlert(
        `Not able to submit the form. Please fill the form correctly and try again.`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  const handleDatasetSubmit = async (
    data: IInitialStateChallengeFormProps,
    actions: any
  ) => {
    try {
      const {
        targetVariableName,
        dataDescription,
        evaluationMetric,
      } = data;

      const questionData = {
        question: {
          data: dataDescription,
        },
      };

      const metrics = {
        column: targetVariableName,
        evaluator: evaluationMetric,
      };

      const questionResponse = await libraryService.patchQuestion(
        challenge?.url || problemStatementResponse?.url,
        questionData
      );

      const evaluation_metrics_url = `/api/libraries/my/${
        challenge?.id || problemStatementResponse?.id
      }/metrics/`;

      const evaluationResponse =
        challenge?.question?.evaluation_metrics[0] ||
        completedSteps.has(StepLabel.DATASET)
          ? await libraryService.patchQuestion(
              challenge?.question?.evaluation_metrics[0]?.url ||
                dataset.evaluationResponse?.url,
              metrics
            )
          : await libraryService.createQuestion(
              evaluation_metrics_url,
              metrics
            );

      if (questionResponse && evaluationResponse) {
        setDataset({
          questionResponse: questionResponse.data,
          evaluationResponse: evaluationResponse?.data,
        });
        /**we are doing this because we want formik should
         not show errors without user doing anthing in the next step
         because in the first step all the states
         are touched = true when we submit the form
         *  */
        actions.setTouched({});
        nextStep();
      }
    } catch (error) {
      enqueueAlert(
        `Not able to submit the form. Please fill the form correctly and try again.`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  const handleOtherDetailsSubmit = async (
    data: IInitialStateChallengeFormProps
  ) => {
    try {
      const { rules, testId } = data;

      const questionData = {
        question: {
          evaluation: rules,
        },
      };

      const rulesResponse = await libraryService.patchQuestion(
        challenge?.url || problemStatementResponse?.url,
        questionData
      );

      if (rulesResponse) {
        // nextStep();
        setOtherDetails({
          rulesResponse: rulesResponse?.data,
        });

        enqueueAlert(
          `Challenge is ${
            challenge?.url ? 'updated' : 'created'
          } successfully.`,
          {
            alertProps: { severity: 'success' },
          }
        );
        if (testId && !challenge) {
          handleAddQuestionsToTest(testId, rulesResponse?.data?.id);
        }
      }
    } catch (error) {
      enqueueAlert(`Not able to submit the form. Please try again.`, {
        alertProps: { severity: 'error' },
      });
    }
  };

  return (
    <ChallengeContext.Provider
      value={{
        problemStatementResponse,
        activeStep,
        prevStep,
        handleProblemStatementSubmit,
        handleDatasetSubmit,
        handleOtherDetailsSubmit,
        setActiveStep,
        completedSteps,
        challenge,
        otherDetails,
        datasetFiles,
        setDatasetFiles,
        dataset,
        setProblemStatementResponse,
        setOtherDetails,
        setDataset,
      }}
    >
      {children}
    </ChallengeContext.Provider>
  );
};

export default ChallengeQuestionProvider;

import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';

import ConfirmationDialog from 'common/components/confirmation-dialog/ConfirmationDialog';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAlert from 'common/components/alert-provider/useAlert';

const organizerService = new OrganizerService();

const ExtendTestDuration: React.FC<IExtendTestDurationProps> = ({
  extendTestDurationDialog,
  closeExtendTestDurationDialog,
  extendTestDurationUrl,
  candidateEmail,
}) => {
  const { enqueueAlert } = useAlert();

  const [extendedTime, setExtendedTime] = useState('');

  const handleExtendTestDuration = async () => {
    try {
      let schema = Yup.number().required().positive().integer();
      let isValidTime = await schema.isValid(extendedTime);
      if (isValidTime) {
        const response = await organizerService.patch(
          extendTestDurationUrl,
          { extra_time: parseInt(extendedTime) }
        );
        console.log('test duration', response);
        closeExtendTestDurationDialog();
        enqueueAlert(
          `Test duration updated successfully for ${candidateEmail}`,
          {
            alertProps: { severity: 'success' },
          }
        );
      } else {
        enqueueAlert('Please add the valid valid time in mins.', {
          alertProps: { severity: 'error' },
        });
      }
    } catch (error) {
      console.log(error);
      enqueueAlert(
        'Error while extending the time duration, Please try again.',
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <ConfirmationDialog
      open={extendTestDurationDialog}
      variant="info"
      title="Extended test duration"
      disagreeText="Close"
      agreeText="Confirm"
      onAgree={() => handleExtendTestDuration()}
      onDisagree={closeExtendTestDurationDialog}
    >
      <Box
        py={2}
        display="flex"
        alignItems="center"
        gridColumnGap={8}
      >
        <Typography>Add time: </Typography>
        <TextField
          variant="outlined"
          placeholder="mins"
          value={extendedTime}
          onChange={(event) => setExtendedTime(event.target.value)}
        />
      </Box>
    </ConfirmationDialog>
  );
};

export default ExtendTestDuration;

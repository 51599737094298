import React from 'react';
import SpinnerButton from 'common/components/spinner-button/SpinnerButton';

const SaveButton: React.FC<IChallengeQuestionSaveButtonProps> = ({
  disabled,
  onClick,
  buttonText,
}) => {
  return (
    <SpinnerButton
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      color="primary"
      type="submit"
    >
      {buttonText}
    </SpinnerButton>
  );
};

export default SaveButton;

import React, { useState } from 'react';
import SqlContext from 'business/organizer/library/contexts/sql/SqlContext';

const SqlProvider = ({ children }: { children: JSX.Element }) => {
  const [sqlResponse, setSqlResponse] =
    useState<ISqlQuestionResponseProps | null>(null);

  return (
    <SqlContext.Provider
      value={{
        sqlResponse,
        setSqlResponse,
      }}
    >
      {children}
    </SqlContext.Provider>
  );
};

export default SqlProvider;

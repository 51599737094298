import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LibraryService } from 'business/organizer/library/services/libraryService';
import useAsync from 'common/custom-hooks/useAsync';

const libraryService = new LibraryService();

const useQuestionUpdateData = (param: string, apiUrl: string) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    run,
    data,
    error,
    status,
    reset,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    setData,
    setError,
  } = useAsync();

  useEffect(() => {
    run(libraryService.getQuestion(`${apiUrl}${param}/`));
  }, [apiUrl, param, run]);

  useEffect(() => {
    if (data) {
      const splitUrl = pathname.split('/');
      if (!splitUrl.includes(data.type)) {
        const newUrl = Object.assign([], splitUrl, {
          [splitUrl.length - 3]: data?.type,
        });
        history.push(newUrl.join('/'));
      }
    }
  }, [data, history, pathname]);

  return {
    questionItem: data,
    responseError: error,
    status,
    reset,
    setQuestionItem: setData,
    setResponseError: setError,
    isIdle,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useQuestionUpdateData;

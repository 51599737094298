import React from 'react';
import { Switch, Route } from 'react-router-dom';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import QuestionListPage from 'business/organizer/library/pages/question-list/QuestionListPage';
import QuestionsProvider from 'business/organizer/common/components/QuestionsProvider';
import IncompleteQuestionsProvider from 'business/organizer/library/contexts/incomplete-questions/IncompleteQuestionsProvider';
import GetTestsProvider from 'business/organizer/library/contexts/get-tests/GetTestsProvider';
import HasPermissionToInterviewPlan from 'business/organizer/common/components/HasPermissionToInterviewPlan';
import InterviewQuestionProvider from 'business/organizer/library/contexts/interview-question/InterviewQuestionProvider';

const LibraryPage: React.FC<ILibraryPageProps> = () => {
  const { url } = useCurrentUrl();

  return (
    <>
      <GenerateBreadcrumb data={[{ label: 'Library', route: url }]} />
      <HasPermissionToInterviewPlan pageName="library">
        <IncompleteQuestionsProvider>
          <QuestionsProvider>
            <Switch>
              <Route path={`${url}/`}>
                <GetTestsProvider>
                  <InterviewQuestionProvider>
                    <QuestionListPage />
                  </InterviewQuestionProvider>
                </GetTestsProvider>
              </Route>
            </Switch>
          </QuestionsProvider>
        </IncompleteQuestionsProvider>
      </HasPermissionToInterviewPlan>
    </>
  );
};

export default LibraryPage;

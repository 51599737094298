import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';

import PercentageCoveredWithLabel from 'common/components/utility-components/PercentageCoveredWithLabel';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import useAsync from 'common/custom-hooks/useAsync';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { getDayjsDate } from 'common/utils/dayjsUtils';
import ImageHelper from 'common/components/image-helper/ImageHelper';

const organizerService = new OrganizerService();
const ImageVerificationModal: React.FC<{
  apiUrl: string;
  onClose: () => void;
}> = ({ apiUrl, onClose }) => {
  const classes = useStyles();

  const {
    status,
    error,
    data,
    run,
  }: IUseAsyncReturnProps<IAbnormalEventProps> = useAsync();

  useEffect(() => {
    run(organizerService.get(apiUrl));
  }, [run, apiUrl]);

  return (
    <GenericResponseHandler status={status} error={error}>
      <>
        <>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box pb={6}>
            <Container maxWidth="xl">
              <Box pb={6}>
                <Typography align="center" variant="h4">
                  Candidate Image Verification
                </Typography>
              </Box>
            </Container>
            <Divider />
            <Container maxWidth="xl">
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                py={12}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">Original image</Typography>
                  <ImageHelper
                    imageUrl={data?.face_index_image}
                    altProp="orginal-image"
                    className={classes.imagehelper__candidate_image}
                  />
                  <Typography variant="body1">
                    Captured at{' '}
                    {getDayjsDate({
                      date: data?.face_index_created_at,
                      format: "DD MMM[']YY HH[:]mm:ss",
                    })}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    Image captured during test
                  </Typography>
                  <ImageHelper
                    imageUrl={data?.comparison_image}
                    altProp="orginal-image"
                    className={classes.imagehelper__candidate_image}
                  />
                  <Typography variant="body1">
                    Captured at{' '}
                    {getDayjsDate({
                      date: data?.created_at,
                      format: "DD MMM[']YY HH[:]mm:ss",
                    })}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h5" align="center">
                Confidence Score
              </Typography>
              <Box display="flex" justifyContent="center" pt={2}>
                <PercentageCoveredWithLabel
                  percentage={data?.confidence_score}
                  size={100}
                />
              </Box>
            </Container>
          </Box>
          <Box display="flex" justifyContent="flex-end" p={4}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </>
      </>
    </GenericResponseHandler>
  );
};

export default ImageVerificationModal;

const useStyles = makeStyles((theme) => ({
  imagehelper__candidate_image: {
    maxWidth: theme.spacing(75),
    maxHeight: theme.spacing(67),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
  },
}));

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import CandidateDetailPage from 'business/organizer/assessment/pages/candidate-progress/candidate-detail/CandidateDetailPage';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';

const CandidateDetailsDrawerBase: React.FC<ICandidateDetailsDrawerBaseProps> =
  ({ apiUrl, closeDrawer, navigationEl }) => {
    const { url } = useCurrentUrl();

    const { updatedScoreLifeCycle } = useCandidateProgressDetail();
    const { setHasChanges, hasChanges } = useCandidateProgress();

    const onCloseDrawer = (
      event:
        | React.KeyboardEvent<Element>
        | React.MouseEvent<Element, MouseEvent>
    ) => {
      closeDrawer(event);
      if (!!updatedScoreLifeCycle.length) {
        if (
          updatedScoreLifeCycle.length === 2 &&
          updatedScoreLifeCycle[0] !== updatedScoreLifeCycle[1]
        ) {
          setHasChanges(true);
          return;
        }
        setHasChanges(true);
      }
    };

    useEffect(() => {
      if (hasChanges) {
        // Close the drawer immedidately when user takes shortlist or reject action
        // @ts-ignore
        closeDrawer();
      }
    }, [closeDrawer, hasChanges]);

    return (
      <>
        <CloseDrawerIcon closeQuestionDrawer={onCloseDrawer} />
        {navigationEl}
        <Route path={`${url}/:candidateId`}>
          <CandidateDetailPage apiUrl={apiUrl} />
        </Route>
      </>
    );
  };

export default CandidateDetailsDrawerBase;

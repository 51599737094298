import { useContext } from 'react';
import AddQuestionsToTestContext from './AddQuestionsToTestContext';

const useAddQuestionsToTest = (): IAddQuestionsToTestContext => {
  return useContext(
    AddQuestionsToTestContext
  ) as IAddQuestionsToTestContext;
};

export default useAddQuestionsToTest;

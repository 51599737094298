import React from 'react';
import Container from '@material-ui/core/Container/Container';
import Box from '@material-ui/core/Box';

import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import SettingsUpdate from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/SettingsUpdate';
import HttpError from 'common/components/http-error/HttpError';
import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';

const Settings = () => {
  const { testDetail, testDetailError } = useTestDetail();
  const { url } = useCurrentUrl();

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Settings', route: url }]}
      />
      {testDetailError !== null ? (
        <HttpError axiosError={testDetailError} />
      ) : testDetail === null ? (
        <Container maxWidth={false}>
          <Box py={4}>
            <GenericListSkeleton items={3} />
          </Box>
        </Container>
      ) : (
        <SettingsUpdate testDetail={testDetail} />
      )}
    </>
  );
};

export default Settings;

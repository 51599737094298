import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import CandidateDetails from 'business/organizer/assessment/components/candidate-progress/candidate-detail/CandidateDetails';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import HttpError from 'common/components/http-error/HttpError';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import { getCandidateRegistrationField } from 'business/organizer/assessment/components/candidate-progress/candidate-detail/utils/helpers';
import CandidateDetailTabs from 'business/organizer/assessment/components/candidate-progress/candidate-detail/CandidateDetailTabs';
import Reviewed from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/Reviewed';

const organizerService = new OrganizerService();

const CandidateDetailPage = ({ apiUrl }: { apiUrl: string }) => {
  const classes = useStyles();

  const { setCandidateIds } = useCandidateProgress();
  const { setCandidateDetails, candidateDetails } =
    useCandidateProgressDetail();

  const [responseError, setResponseError] =
    useState<AxiosError | null>(null);

  useEffect(() => {
    let mounted = true;

    organizerService
      .get(apiUrl)
      .then((response) => {
        if (mounted) {
          // console.log('candidate detail', response?.data);
          setCandidateDetails(response?.data);
          setCandidateIds([response?.data.id]);
        }
      })
      .catch((error) => setResponseError(error));

    return () => {
      mounted = false;
      setCandidateDetails(null);
      setCandidateIds([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl]);

  const getRegistrationFields = (
    registration_responses: ICandidateRegistrationField[]
  ) => {
    if (!!registration_responses?.length) {
      return registration_responses.filter(
        ({ field_name }: ICandidateRegistrationField) =>
          ![
            'first_name',
            'last_name',
            'email_address',
            'privacy_policy',
            'candidate_consent',
          ].includes(field_name)
      );
    } else {
      return [];
    }
  };

  const filteredCandidateFields = getRegistrationFields(
    candidateDetails?.registration_responses as ICandidateRegistrationField[]
  );

  return (
    <Box bgcolor="#FAFAFA">
      {responseError !== null ? (
        <HttpError axiosError={responseError} />
      ) : candidateDetails === null ? (
        <Container maxWidth={false}>
          <Box py={4}>
            <GenericListSkeleton
              items={3}
              boxProps={{ height: '10px' }}
            />
          </Box>
        </Container>
      ) : (
        <>
          <Container
            maxWidth={false}
            className={classes.container__root}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={4}
            >
              <Box>
                <Box>
                  <Typography variant="h3">
                    {getCandidateRegistrationField(
                      candidateDetails?.registration_responses,
                      'first_name'
                    )}{' '}
                    {getCandidateRegistrationField(
                      candidateDetails?.registration_responses,
                      'last_name'
                    )}{' '}
                    <Typography variant="body1" component="span">
                      ({candidateDetails?.email})
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Reviewed
                  disableReject={
                    candidateDetails?.status === 'rejected'
                  }
                  disableShortlist={
                    candidateDetails?.status === 'shortlisted'
                  }
                />
              </Box>
            </Box>
            {!!filteredCandidateFields.length && (
              <Box p={4}>
                <Grid container style={{ gridRowGap: '16px' }}>
                  {filteredCandidateFields.map(
                    (
                      {
                        label,
                        response,
                      }: ICandidateRegistrationField,
                      idx: number
                    ) => (
                      <Grid key={idx} item xs={4}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gridRowGap={4}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {label}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                          >
                            {response}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            )}
          </Container>
          <Divider />
          <Box py={4}>
            <CandidateDetails />
          </Box>
          <Box py={4}>
            <CandidateDetailTabs />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CandidateDetailPage;

const useStyles = makeStyles((theme) => ({
  container__root: {
    background: '#f1f6f9',
  },
}));

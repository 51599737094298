import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';

const SubscribeTooltip: React.FC<ISubscribeTooltipProps> = ({
  children,
  position = 'top',
}) => {
  const { isSubscriptionExpired } = useSubscription();

  return (
    <>
      {isSubscriptionExpired ? (
        <Tooltip
          title="Your current plan has been expired. Subscribe now, to continue using Skillspace Assessment Platform."
          placement={position}
        >
          <span>{children}</span>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default SubscribeTooltip;

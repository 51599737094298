import LinkedInSvg from 'common/components/social-media-svg/LinkedInSvg';
import FacebookSvg from 'common/components/social-media-svg/FacebookSvg';
import YoutubeSvg from 'common/components/social-media-svg/YoutubeSvg';
import InstagramSvg from 'common/components/social-media-svg/InstagramSvg';

export const featuresList = [
  {
    id: 1,
    label: 'Code Assessment',
    route: 'https://skillspace.ai/coding-assessments/',
    isReactLink: false,
    isNewTab: false,
  },
  // {
  //   id: 2,
  //   label: 'Take Home Projects',
  //   route: 'https://skillspace.ai/take-home-assignments/',
  //   isReactLink: false,
  //   isNewTab: true,
  // },
  {
    id: 3,
    label: 'Data Science',
    route: 'https://skillspace.ai/data-science-assessments/',
    isReactLink: false,
    isNewTab: false,
  },
  {
    id: 4,
    label: 'AI Powered Proctoring',
    route: 'https://skillspace.ai/proctoring/',
    isReactLink: true,
    isNewTab: false,
  },
];

export const solutionsList = [
  {
    id: 1,
    label: 'Remote Hiring',
    route: 'https://skillspace.ai/remote-hiring/',
    isReactLink: false,
    isNewTab: false,
  },
  {
    id: 2,
    label: 'Professional Hiring',
    route: 'https://skillspace.ai/professional-hiring/',
    isReactLink: false,
    isNewTab: false,
  },
  {
    id: 3,
    label: 'University Hiring',
    route: 'https://skillspace.ai/university-hiring/',
    isReactLink: false,
    isNewTab: false,
  },
  {
    id: 4,
    label: 'Employee Engagement',
    route: 'https://skillspace.ai/employee-engagement/',
    isReactLink: false,
    isNewTab: false,
  },
];

export const organizationSectionData = [
  {
    id: 1,
    label: 'About Us',
    route: 'https://skillspace.ai/about-us/',
    isReactLink: false,
    isNewTab: false,
  },
  {
    id: 2,
    label: 'Contact Us',
    route: 'mailto:support@skillspace@ai',
    isReactLink: false,
    isNewTab: true,
  },
];

export const socialMediaLinks = [
  {
    id: 1,
    icon: FacebookSvg,
    route: 'https://www.facebook.com/dphi.tech/',
  },
  {
    id: 2,
    icon: LinkedInSvg,
    route: 'https://www.linkedin.com/company/dphi/',
  },
  {
    id: 3,
    icon: InstagramSvg,
    route: 'https://www.instagram.com/dphi.tech/',
  },
  {
    id: 4,
    icon: YoutubeSvg,
    route: 'https://www.youtube.com/c/dphiofficial/featured',
  },
];

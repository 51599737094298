import React from 'react';
import { Route } from 'react-router-dom';

import useQuestionPreviewData from 'business/organizer/library/pages/question-preview/useQuestionPreviewData';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import Preview from 'business/organizer/library/components/question-preview/common/Preview';
import useSql from 'business/organizer/library/contexts/sql/useSql';
import SqlSubQuestion from './SqlSubQuestion';

const SqlQPreview: React.FC<{ questionUrl: string }> = ({
  questionUrl,
}) => {
  const { url } = useCurrentUrl();

  const { sqlResponse } = useSql();
  const hasResponse = !!sqlResponse;
  const { questionItem, responseError } = useQuestionPreviewData(
    questionUrl,
    hasResponse
  );

  return (
    <Preview
      responseError={responseError}
      questionItem={questionItem}
      updatePageRoute={
        <Route path={`${url}/edit/:sqlId`}>Hello</Route>
      }
    >
      <SqlSubQuestion questionData={questionItem} />
    </Preview>
  );
};

export default SqlQPreview;

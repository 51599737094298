import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import AuthPageButton from 'business/pre-subscription/auth/components/common/AuthPageButton';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import MyInput from 'common/components/form/MyInput';
import MySelect from 'common/components/form/MySelect';
import SelectCountryCode from 'common/components/form/SelectCountryCode';
import BaseService from 'common/services/baseService';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import { getErrorMessage } from 'common/utils/errors';
import { JOB_TITLES } from 'business/organizer/settings/utils/constants';
import { getValidationSchema } from 'business/pre-subscription/auth/components/common/helpers';
import { FRONTEND_SIGNUP_URL } from 'common/utils/constants';

const baseService = new BaseService();
/**
 * In this component we separated the state because we want handle accessibility issue which is
 * when user press the @enter on keyboard we should be able to submit the form
 * and @firstStepDataRef is used to remember data so that we can use it while
 * making @post request.
 * */
const RequestDemoField: React.FC<{
  next: boolean;
  onNext: () => void;
}> = ({ next, onNext }) => {
  const classes = useStyles();
  const [error, setError] = useState<IBusinessFormErrorProps | null>(
    null
  );

  const firstStepDataRef = useRef({ email: '', current_role: '' });

  const firstStepState: RequestDemoFirstStep = {
    email: '',
    current_role: '',
  };

  const secondStepState: RequestDemoSecondStep = {
    first_name: '',
    last_name: '',
    country_code: '',
    contact_number: '',
    company_name: '',
  };

  const firstStepValidation =
    getValidationSchema<RequestDemoFirstStep>(firstStepState);
  const secondStepValidation =
    getValidationSchema<RequestDemoSecondStep>(secondStepState);

  const handleSubmit: AuthSubmissionHandler = async (
    data: IRequestDemoProps
  ) => {
    if (!next) {
      firstStepDataRef.current = {
        email: data.email,
        current_role: data.current_role,
      };
      onNext();
      return;
    }
    try {
      const response = await baseService.post('/api/demo-request/', {
        ...{
          ...data,
          email: firstStepDataRef.current.email,
          current_role: firstStepDataRef.current.current_role,
        },
        request_for: 'features',
      });
      if (response) {
        window.location.href = 'https://skillspace.ai/request-demo/';
      }
    } catch (error) {
      setError(
        (error as AxiosError).response
          ?.data as IBusinessFormErrorProps
      );
    }
  };

  const resetResponse = () => {
    setError(null);
  };

  const handleOnDisagree = () => {
    resetResponse();
  };

  const errorMessage = getErrorMessage(error);

  return (
    <>
      <NotificationDialog
        open={error !== null}
        variant="error"
        disagreeText="Try Again"
        onDisagree={handleOnDisagree}
        content={errorMessage}
      />
      <Formik
        initialValues={!next ? firstStepState : secondStepState}
        validationSchema={
          !next ? firstStepValidation : secondStepValidation
        }
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className={classes.formik__form}>
            {!next ? (
              <>
                <FieldStyles>
                  <MyInput
                    placeholder="Work Email Address"
                    name="email"
                    type="email"
                  />
                </FieldStyles>
                <FieldStyles>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.form_control}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      margin="dense"
                      shrink={false}
                    >
                      {/* @ts-ignore */}
                      {!values.current_role ? 'Job Title' : ''}
                    </InputLabel>
                    <MySelect
                      name="current_role"
                      placeholder="Job title"
                      type="text"
                    >
                      {JOB_TITLES.map((jobTitle: IJobTitleProps) => (
                        <MenuItem
                          key={jobTitle.id}
                          value={jobTitle.value}
                        >
                          {jobTitle.label}
                        </MenuItem>
                      ))}
                    </MySelect>
                  </FormControl>
                </FieldStyles>
              </>
            ) : (
              <>
                <FieldStyles>
                  <Box display="flex" gridColumnGap={12}>
                    <MyInput
                      placeholder="First Name"
                      name="first_name"
                      type="text"
                    />
                    <MyInput
                      placeholder="Last Name(optional)"
                      name="last_name"
                      type="text"
                    />
                  </Box>
                </FieldStyles>
                <FieldStyles>
                  <Box display="flex" gridColumnGap={12}>
                    <SelectCountryCode
                      placeholder="Ex: 91"
                      name="country_code"
                      type="number"
                    />
                    <MyInput
                      placeholder="Enter Phone number"
                      name="contact_number"
                      type="tel"
                    />
                  </Box>
                </FieldStyles>
                <FieldStyles>
                  <MyInput
                    name="company_name"
                    placeholder="Company Name"
                    type="text"
                  />
                </FieldStyles>
              </>
            )}
            <AuthPageButton>
              {!next ? 'Next' : 'Request Demo'}
            </AuthPageButton>
            {!next && (
              <>
                <Typography variant="body2" align="center">
                  Don’t worry! <strong>No credit card details</strong>{' '}
                  are required.
                </Typography>
                <Box
                  pt={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography color="textSecondary">
                    You can try Skillspace for FREE
                  </Typography>
                  <Link to={FRONTEND_SIGNUP_URL}>
                    <Button color="primary">Sign Up</Button>
                  </Link>
                </Box>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RequestDemoField;

const useStyles = makeStyles((theme) => ({
  formik__form: {
    '& .MuiButton-root': {
      width: '100%',
    },
  },
  box__request_demo: {
    textAlign: 'center',
    '& .MuiFormControl-root': {
      backgroundColor: '#fff',
      borderRadius: 28,
      '& .MuiOutlinedInput-adornedEnd': {
        borderRadius: 28,
        padding: theme.spacing(0.25),
        paddingLeft: theme.spacing(4),
      },
    },
    '& .MuiButton-root': {
      width: 225,
      [theme.breakpoints.down('sm')]: {
        width: 200,
        fontSize: 12,
      },
    },
  },
  form_control: {
    '& .MuiFormLabel-root': {
      color: '#c0c0c0',
    },
  },
}));

import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import QuestionListItem from 'business/organizer/library/components/question-list/QuestionListItem';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import {
  FETCH_LIBRARY_SUCCESS,
  FETCH_LIBRARY_FAILURE,
  LOAD_PAGE,
} from 'business/organizer/library/contexts/library/libraryActionTypes';
import { ListType } from 'business/organizer/library/pages/question-list/QuestionListPage';
import GenericError from 'common/components/generic-error/GenericError';
import HttpError from 'common/components/http-error/HttpError';
import QuestionListSkeleton from 'business/organizer/library/components/question-list/skeletons/QuestionList';
import { LIBRARY_QUESTIONS_PAGE_SIZE } from 'business/organizer/library/utils/constants';
import QuestionDrawerProvider from 'business/organizer/library/contexts/question-drawer/QuestionDrawerProvider';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';

const libraryService = new LibraryService();

const QuestionList: React.FC<IQuestionListProps> = ({
  breadcrumbLabel,
  list,
  apiUrl,
  navbarRef,
}) => {
  const { url } = useCurrentUrl();

  const { state, dispatch } = useLibrary();
  const { testId } = useTestId();

  const questionsState = state[list];

  useEffect(() => {
    let cancelLoad = false;

    const apiParams: IGetLibraryQuestionListParams = {
      type: [
        ...(state.globalParams.type.map((item) =>
          item.replace('_', '-')
        ) || []),
      ],
      difficulty: state.globalParams.difficulty,
      tags: state.globalParams.tags,
      programmingLanguages: state.globalParams.programmingLanguages,
      search: state.globalParams.search,
      page: questionsState.params.page,
    };
    if (testId) {
      apiParams.types = [
        'challenge',
        'coding',
        'free-text',
        'file-upload',
        'mcq',
        // 'project',
        'sql',
      ];
    }

    if (!questionsState.isLoaded) {
      libraryService
        .getLibraryQuestionList(apiUrl, apiParams)
        .then((response) => {
          if (!cancelLoad) {
            dispatch({
              type: FETCH_LIBRARY_SUCCESS,
              payload: { list, response },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_LIBRARY_FAILURE,
            payload: { list, error },
          });
        });
    }

    return () => {
      // We're unmounting. Don't load this request's data.
      cancelLoad = true;
    };
  }, [
    dispatch,
    list,
    state.globalParams.difficulty,
    state.globalParams.search,
    state.globalParams.type,
    state.globalParams.programmingLanguages,
    questionsState.params.page,
    questionsState.isLoaded,
    apiUrl,
    state.globalParams.tags,
    testId,
  ]);
  /** we added isLoaded as dependency because
   * we want to load the page when user clicks on current page
   * and we use it to update the changes in questions and library
   * */

  const handleLoadPage = useCallback(
    (page: number) => {
      dispatch({ type: LOAD_PAGE, payload: { list, page } });
      const navbar = ReactDOM.findDOMNode(
        navbarRef.current
      ) as Element;
      if (navbar)
        navbar.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    [dispatch, list, navbarRef]
  );

  const getEmptyPlaceholder = () => {
    const props: IListType = {
      [ListType.SHARED]: {
        heading: 'No question available',
        description: '',
        image: '',
      },
      [ListType.MY]: {
        heading: 'No question available',
        description: '',
        image: '',
      },
    };

    return (
      <GenericError
        heading={props[list].heading}
        description={props[list].description}
        imageUrl={props[list].image}
      />
    );
  };

  return (
    <div>
      {state[list].responseError !== null ? (
        <HttpError axiosError={questionsState.responseError} />
      ) : (
        <>
          <GenerateBreadcrumb
            data={[{ label: breadcrumbLabel, route: url }]}
          />
          {state[list].questions === null ? (
            <QuestionListSkeleton />
          ) : state[list].questions !== null &&
            state[list].questions.length === 0 ? (
            <Box m="auto">{getEmptyPlaceholder()}</Box>
          ) : (
            <>
              {
                <Grid container spacing={4}>
                  {state[list].questions.map((questionItem: any) => (
                    <Grid item xs={12} key={questionItem.id}>
                      <QuestionDrawerProvider>
                        <QuestionListItem
                          questionItem={questionItem}
                        />
                      </QuestionDrawerProvider>
                    </Grid>
                  ))}
                </Grid>
              }
              {questionsState.count > LIBRARY_QUESTIONS_PAGE_SIZE && (
                <Box
                  pb={6}
                  pt={12}
                  display="flex"
                  justifyContent="center"
                  m="auto"
                >
                  <Pagination
                    count={Math.ceil(
                      questionsState.count /
                        LIBRARY_QUESTIONS_PAGE_SIZE
                    )}
                    page={questionsState.params.page}
                    color="primary"
                    onChange={(e, page) => handleLoadPage(page)}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionList;

import React from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import Tooltip from '@material-ui/core/Tooltip';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core';

import BillingCardStyles from 'business/organizer/assessment/components/billing/common/BillingCardStyles';
// import theme from 'business/common/theme/theme';
import Features from 'business/organizer/assessment/components/billing/common/Features';

const BillingPlanItem: React.FC<IBillingPlanItemProps> = ({
  plan,
  planDescription,
  features,
  perInvitePrice,
  perInviteDiscountedPrice,
  children,
  monthly,
  showDiscount = true,
  discount,
}) => {
  const classes = useStyles();

  return (
    <BillingCardStyles>
      <Box py={4} height={40}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridColumnGap={8}
        >
          <Box display="flex">
            <Typography variant="subtitle2">$</Typography>
            <Typography
              variant="h2"
              className={classes.typography__discounted_price}
            >
              {perInviteDiscountedPrice}
            </Typography>
          </Box>
          {/* <Divider flexItem orientation="vertical" light />
          <Typography color="textSecondary">invite</Typography> */}
        </Box>
        {/* {showDiscount && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridColumnGap={4}
            pt={1}
          >
            <Typography
              color="textSecondary"
              className={classes.typography__price}
              align="center"
            >
              ${perInvitePrice}
            </Typography>
            <Box color={theme.palette.info.main}>
              <Typography color="inherit">
                {!!discount
                  ? `(${discount})`
                  : monthly
                  ? '(50% off)'
                  : '(63.33% off)'}
              </Typography>
            </Box>
            <Tooltip
              title={`Introductory ${
                !!discount
                  ? discount
                  : monthly
                  ? '50 % off'
                  : '63.33% off'
              }`}
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon
                className={classes.infoicon__invite}
              />
            </Tooltip>
          </Box>
        )} */}
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" p={4} height={320}>
        <Box textAlign="center" py={4}>
          <Typography variant="h4">{plan}</Typography>
          <Typography>{planDescription}</Typography>
        </Box>
        <Features features={features} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={8}
        gridRowGap={4}
        height={100}
      >
        {children}
      </Box>
    </BillingCardStyles>
  );
};

export default BillingPlanItem;

const useStyles = makeStyles((theme) => ({
  typography__price: {
    textDecoration: 'line-through',
  },
  infoicon__invite: {
    color: '#666',
    fontSize: 14,
  },
  typography__discounted_price: {
    fontSize: theme.spacing(8),
  },
}));

import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';

import FormError from 'business/organizer/library/components/common/FormError';
import SaveButton from 'business/organizer/library/components/common/SaveButton';

const SqlFormActions = ({
  isUpdate,
  testId,
}: {
  isUpdate: React.MutableRefObject<boolean>;
  testId: number;
}) => {
  const { isSubmitting } = useFormikContext<ISqlInitialStateProps>();

  return (
    <Box display="flex" alignItems="center" gridColumnGap={12}>
      <FormError />
      <Box position="relative">
        <Box display="flex">
          <SaveButton
            buttonText={
              !!testId
                ? `${
                    isUpdate.current ? 'Save' : 'Save & Add To Test'
                  }`
                : 'Save'
            }
            disabled={isSubmitting}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SqlFormActions;

import React from 'react';
import { useFormikContext } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormError = () => {
  const { errors, touched } = useFormikContext();

  return (
    <>
      {!!Object.keys(errors).find((error) =>
        Object.keys(touched).includes(error)
      ) && (
        <FormHelperText error={true}>
          *Please add all the fields correctly
        </FormHelperText>
      )}
    </>
  );
};

export default FormError;

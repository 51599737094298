import React from 'react';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';

const ListOfFilters: React.FC<IListOfFiltersProps> = ({
  data,
  state,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <List component="div" disablePadding>
      {data.map((filterItem) => (
        <MenuItem
          key={filterItem.label}
          className={classes.menuitem_checkbox}
        >
          <FormControlLabel
            control={
              <Checkbox
                name={filterItem.value}
                inputProps={{
                  'aria-label': filterItem.label,
                  value: filterItem.value,
                }}
                checked={
                  state instanceof Array
                    ? state.includes(filterItem.value)
                    : state[filterItem.value]
                }
                onChange={handleChange}
              />
            }
            label={filterItem.label}
          />
        </MenuItem>
      ))}
    </List>
  );
};

export default ListOfFilters;

const useStyles = makeStyles((theme) => ({
  menuitem_checkbox: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#233D4D',
    },
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1),
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.secondary,
    },
  },
}));

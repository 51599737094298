import React from 'react';

import ProblemStatement from 'business/organizer/library/pages/question-create-update/coding/steps/ProblemStatement';
import Testcases from 'business/organizer/library/pages/question-create-update/coding/steps/Testcases';

enum StepLabel {
  PROBLEM_STATEMENT = 0,
  TESTCASES = 1,
}

const RenderCodingStep: React.FC<IRenderCodingStepProps> = ({
  step,
}) => {
  switch (step) {
    case StepLabel.PROBLEM_STATEMENT:
      return <ProblemStatement />;
    case StepLabel.TESTCASES:
      return <Testcases />;
    default:
      return <p>Not Found</p>;
  }
};

export default RenderCodingStep;

import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';

import { StepLabel } from 'business/organizer/library/pages/question-create-update/challenge/ChallengePage';
import BackButton from 'business/organizer/library/components/common/BackButton';
import SaveButton from 'business/organizer/library/components/common/SaveButton';
import FormError from 'business/organizer/library/components/common/FormError';

const ChallengeFormActions: React.FC<IChallengeFormActionsProps> = ({
  activeStep,
  prevStep,
  isUpdate,
}) => {
  const { isSubmitting, values } = useFormikContext<{
    testId?: number;
  }>();
  return (
    <Box display="flex" alignItems="center" gridColumnGap={12}>
      <FormError />
      <Box position="relative">
        {activeStep === StepLabel.PROBLEM_STATEMENT ? (
          <>
            <SaveButton
              disabled={isSubmitting}
              buttonText={
                !!values.testId && !isUpdate
                  ? 'Next'
                  : 'Save & Proceed'
              }
            />
          </>
        ) : activeStep === StepLabel.DATASET ? (
          <Box position="relative" display="flex" gridColumnGap={10}>
            <BackButton prevStep={prevStep} />
            <SaveButton
              buttonText={
                !!values.testId && !isUpdate
                  ? 'Next'
                  : 'Save & Proceed'
              }
              disabled={isSubmitting}
            />
          </Box>
        ) : (
          <Box display="flex" gridColumnGap={10}>
            <BackButton prevStep={prevStep} />
            <SaveButton
              buttonText={
                !!values.testId
                  ? `${isUpdate ? 'Save' : 'Save & Add to test'}`
                  : 'Save'
              }
              disabled={isSubmitting}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChallengeFormActions;

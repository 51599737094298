import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import InviteStats from 'business/organizer/assessment/components/dashboard/InviteStats';
import BillingCbDetailsPortal from 'business/organizer/assessment/components/billing/BillingCbDetailsPortal';

const BillingHistory = () => {
  const { isFreeTrial } = useOrganizer();

  return (
    <Container maxWidth="lg">
      <Box py={6}>
        <Box display="flex" justifyContent="center" pb={4}>
          <Typography variant="h4" align="center">
            Current and Billing History
          </Typography>
        </Box>
        <Box py={4}>
          <InviteStats />
        </Box>
        <Box display="flex">
          {!isFreeTrial && <BillingCbDetailsPortal />}
        </Box>
      </Box>
    </Container>
  );
};

export default BillingHistory;

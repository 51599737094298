import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import EmptyPlaceholder from 'business/organizer/settings/components/team-management/EmptyPlaceholder';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import Members from 'business/organizer/settings/components/team-management/Members';
import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import InlineTextButton from 'common/components/utility-components/InlineTextButton';

const TeamManagement = () => {
  const { url } = useCurrentUrl();
  const { organizer } = useOrganizer();
  const { isAdmin, user } = useHasPermission();

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Team Management', route: url }]}
      />
      {!!user &&
        !!organizer &&
        (organizer?.has_access_to_team_management ? (
          isAdmin ? (
            <Container maxWidth="xl">
              {organizer !== null &&
                (organizer?.has_members ? (
                  <Members />
                ) : (
                  <EmptyPlaceholder />
                ))}
            </Container>
          ) : (
            <Box py={4}>
              <Typography variant="h5">
                You don't have permission to access this page.
              </Typography>
            </Box>
          )
        ) : (
          <Box py={4}>
            <Typography variant="h5">
              Your subscription does not have this feature enabled.
              Click{' '}
              <InlineTextButton
                variant="h5"
                // Open chat when clicked here
                // @ts-ignore
                onClick={() => $crisp.push(['do', 'chat:open'])}
              >
                here
              </InlineTextButton>{' '}
              to chat with us or write to us at support@skillspace.app
              if you have any question
            </Typography>
          </Box>
        ))}
    </>
  );
};

export default TeamManagement;

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CustomModal from 'common/components/custom-modal/CustomModal';
import InviteMemberForm from 'business/organizer/settings/components/team-management/InviteMemberForm';

const InviteMemberModal: React.FC<{
  open: boolean;
  onClose: () => void;
  member?: IOrganizationMember;
  onReset?: () => void;
  editUrl?: string;
}> = ({ open, onClose, member, onReset, editUrl }) => {
  return (
    <CustomModal
      open={open}
      width="unset"
      rootBoxProps={{ minWidth: 525 }}
    >
      <Box
        py={12}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3" align="center">
          Invite team member
        </Typography>
        <InviteMemberForm
          member={member}
          onReset={onReset}
          onClose={onClose}
          editUrl={editUrl}
        />
      </Box>
    </CustomModal>
  );
};

export default InviteMemberModal;

import React, { useState, useEffect } from 'react';
import { useFormikContext, useField, getIn } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { makeStyles } from '@material-ui/core';

import debounce from 'common/utils/debounce';
import BaseService from 'common/services/baseService';

const baseService = new BaseService();

const uploadAdapter = (loader: any) => {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file: File) => {
          body.append('upload', file);
          baseService
            .post('/api/uploads/', body)
            .then((res) => {
              resolve({
                default: `${res.data.url}`,
              });
            })
            .catch((err) => {
              console.error('error', err);
              reject(err);
            });
        });
      });
    },
  };
};

const uploadPlugin = (editor: any) => {
  editor.plugins.get('FileRepository').createUploadAdapter = (
    loader: any
  ) => {
    return uploadAdapter(loader);
  };
};

const RichTextField: React.FC<IRichText & any> = ({
  name,
  ckConfig,
  showError = true,
  ...otherProps
}) => {
  const { values, setFieldValue } =
    useFormikContext<IUseFormikContext>();
  const [field, meta] = useField<{}>({
    name,
    type: 'textarea',
  });
  const errorText = meta.error && meta.touched ? meta.error : '';

  const [isEditorReady, setIsEditorReady] = useState(false);

  // Render RichTextField after useEffect to avoid state update issue
  useEffect(() => {
    setIsEditorReady(true);
  }, []);
  const classes = useStyles();
  return isEditorReady ? (
    <div className={classes.root}>
      <CKEditor
        editor={CustomEditor}
        data={getIn(values, name)}
        config={{
          extraPlugins: [uploadPlugin],
          toolbar: {
            items: [
              'heading',
              '|',
              'fontSize',
              'fontColor',
              'fontBackgroundColor',
              '|',
              'alignment',
              '|',
              'bold',
              'italic',
              'underline',
              'blockQuote',
              '|',
              'bulletedList',
              'numberedList',
              '|',
              'outdent',
              'indent',
              'horizontalLine',
              '|',
              'code',
              '|',
              'strikethrough',
              'subscript',
              'superscript',
              'specialCharacters',
              '|',
              'imageUpload',
              'insertTable',
              'mediaEmbed',
              '|',
              'undo',
              'redo',
              '|',
              'findAndReplace',
            ],
            shouldNotGroupWhenFull: true,
          },
          ...(ckConfig || {}),
        }}
        {...otherProps}
        {...field}
        onChange={debounce((event: any, editor: any) => {
          console.log('editor', editor.getData());

          setFieldValue(name, editor.getData());
        }, 500)}
      />
      {!!errorText && showError ? (
        <FormHelperText error={!!errorText}>
          {meta.error}
        </FormHelperText>
      ) : null}
    </div>
  ) : (
    <></>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .cke_button': {
      '&:not(.cke_button_disabled)': {
        cursor: 'pointer',
      },
    },
    '& .ck-editor__editable_inline': {
      minHeight: 200,
      maxHeight: 500,
      fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
      color: theme.palette.text.primary,
    },
  },
}));

export default RichTextField;

import { useContext } from 'react';
import CodingUpdatePageContext from 'business/organizer/library/contexts/coding-question/contexts/CodingUpdatePageContext';

const useCodingQuestionData = (): ICodingQuestionUpdatePageContext => {
  return useContext(
    CodingUpdatePageContext
  ) as ICodingQuestionUpdatePageContext;
};

export default useCodingQuestionData;

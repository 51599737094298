import * as Yup from 'yup';

export const validationSchema = [
  Yup.object({
    name: Yup.string()
      .min(5, 'Must be 5 characters or more.')
      .required('This field is required.')
      .typeError('Text type is required'),
    description: Yup.string()
      .min(20, 'Must be 20 characters or more.')
      .required('This field is required.')
      .typeError('Text type is required'),
    tags: Yup.array()
      .min(1, 'Please add at least 1 tag.')
      .required('This field is required'),
    time_duration: Yup.number()
      .required('This field is required.')
      .typeError('Value must be numbers'),
    score: Yup.number()
      .required('This field is required.')
      .typeError('Value must be numbers'),
    difficulty: Yup.string()
      .oneOf(
        ['Easy', 'Medium', 'Hard'],
        'This is not the valid choice.'
      )
      .required('This field is required.'),
  }),
  Yup.object({
    dataDescription: Yup.string()
      .min(20, 'Must be 20 characters or more.')
      .required('This field is required.')
      .typeError('Text type is required.'),
    trainingDataset: Yup.mixed().required('This field is required.'),
    testingDataset: Yup.mixed().required('This field is required.'),
    evaluationDataset: Yup.mixed().required(
      'This field is required.'
    ),
    sampleSubmission: Yup.mixed().required('This field is required.'),
    targetVariableName: Yup.string().required(
      'This field is required.'
    ),
    evaluationMetric: Yup.number()
      .oneOf([1, 2, 3, 4, 5, 6], 'Please select valid option.')
      .required('This field is required.'),
  }),
  Yup.object({
    rules: Yup.string()
      .required('This field is required.')
      .typeError('Text type is required.'),
  }),
];

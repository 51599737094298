import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Sidebar from 'business/organizer/assessment/components/candidate-progress/candidate-list/Sidebar';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import AllCandidates from 'business/organizer/assessment/pages/candidate-progress/candidate-list/screens/AllCandidates';
import useCandidateListCount from 'business/organizer/assessment/components/candidate-progress/candidate-list/custom-hooks/useCandidateListCount';
import SubmissionsBase from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/SubmissionsBase';
import InvitesBase from 'business/organizer/assessment/components/candidate-progress/candidate-list/invites/InvitesBase';

const CandidateListPage = () => {
  const { url } = useCurrentUrl();
  const { count } = useCandidateListCount();

  const submissionRoutes = useMemo(
    () => ({
      readyForReview: {
        id: 1,
        label: 'Ready For Review',
        value: 'ready-for-review',
        count: count ? count?.ready_for_review : 0,
        route: `${url}/ready-for-review`,
      },
      shortlisted: {
        id: 2,
        label: 'Shortlisted',
        value: 'shortlisted',
        count: count ? count?.shortlisted : 0,
        route: `${url}/shortlisted`,
      },
      rejected: {
        id: 3,
        label: 'Rejected',
        value: 'rejected',
        count: count ? count?.rejected : 0,
        route: `${url}/rejected`,
      },
      inProgress: {
        id: 4,
        label: 'In Progress',
        value: 'in-progress',
        count: count ? count?.in_progress : 0,
        route: `${url}/in-progress`,
      },
    }),
    [count, url]
  );

  const inviteRoutes = useMemo(
    () => ({
      pending: {
        id: 1,
        label: 'Pending',
        value: 'pending',
        count: count ? count?.pending : 0,
        route: `${url}/pending`,
      },
      cancelled: {
        id: 2,
        label: 'Cancelled',
        value: 'cancelled',
        count: count ? count?.cancelled : 0,
        route: `${url}/cancelled`,
      },
      expired: {
        id: 3,
        label: 'Expired',
        value: 'expired',
        count: count ? count?.expired : 0,
        route: `${url}/expired`,
      },
      accepted: {
        id: 4,
        label: 'Accepted',
        value: 'accepted',
        count: count ? count?.accepted : 0,
        route: `${url}/accepted`,
      },
    }),
    [count, url]
  );

  const allRoutes = useMemo(
    () => ({
      all: {
        id: 1,
        label: 'All candidates',
        value: 'all',
        count: count ? count?.all_candidates : 0,
        route: `${url}/all`,
      },
    }),
    [count, url]
  );

  return (
    <Container maxWidth={false}>
      <Box display="flex">
        <Box position="sticky" py={4} mr={4}>
          <Sidebar
            allRoutes={Object.values(allRoutes)}
            submissionRoutes={Object.values(submissionRoutes)}
            inviteRoutes={Object.values(inviteRoutes)}
          />
        </Box>
        <Box width="100%">
          {/* Why keys? https://stackoverflow.com/questions/49001001/using-same-component-for-different-route-path-in-react-router-v4 */}
          <Switch>
            <Route path={allRoutes.all.route}>
              <AllCandidates />
            </Route>
            <Route
              key={submissionRoutes.readyForReview.label}
              path={submissionRoutes.readyForReview.route}
            >
              <SubmissionsBase candidateStatus="ready_for_review" />
            </Route>
            <Route
              key={submissionRoutes.shortlisted.label}
              path={submissionRoutes.shortlisted.route}
            >
              <SubmissionsBase
                label="Shortlisted"
                candidateStatus="shortlisted"
              />
            </Route>
            <Route
              key={submissionRoutes.rejected.label}
              path={submissionRoutes.rejected.route}
            >
              <SubmissionsBase
                label="Rejected"
                candidateStatus="rejected"
              />
            </Route>
            <Route
              key={submissionRoutes.inProgress.label}
              path={submissionRoutes.inProgress.route}
            >
              <SubmissionsBase candidateStatus="in_progress" />
            </Route>
            <Route
              key={inviteRoutes.pending.label}
              path={inviteRoutes.pending.route}
            >
              <InvitesBase inviteStatus="pending" showExpireCell />
            </Route>
            <Route
              key={inviteRoutes.cancelled.label}
              path={inviteRoutes.cancelled.route}
            >
              <InvitesBase
                inviteStatus="cancelled"
                showCancelledCell
              />
            </Route>
            <Route
              key={inviteRoutes.expired.label}
              path={inviteRoutes.expired.route}
            >
              <InvitesBase inviteStatus="expired" showExpireCell />
            </Route>
            <Route
              key={inviteRoutes.accepted.label}
              path={inviteRoutes.accepted.route}
            >
              <InvitesBase inviteStatus="accepted" showAcceptedCell />
            </Route>
            <Redirect from={url} to={allRoutes.all.route} />
          </Switch>
        </Box>
      </Box>
    </Container>
  );
};

export default CandidateListPage;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useQuestionSubmission from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useQuestionSubmission';
import QuestionSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionSolution';
import McqChoice from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/mcq/McqChoice';

const MCQSolution: React.FC<IMCQQuestionSolutionProps> = ({
  submissionUrl,
}) => {
  const { submission, responseError } = useQuestionSubmission(
    submissionUrl
  );

  return (
    <QuestionSolution
      submission={submission}
      responseError={responseError}
    >
      <>
        <Box py={2}>
          <Box py={2}>
            <Typography variant="h6">Candidate Solution</Typography>
          </Box>
          <Box>
            {submission?.submission?.question_choices.map(
              (choice: any, idx: number) => (
                <McqChoice
                  key={idx}
                  choice={choice}
                  userChoices={
                    submission?.submission?.submitted_choices
                  }
                />
              )
            )}
          </Box>
        </Box>
      </>
    </QuestionSolution>
  );
};

export default MCQSolution;

import React, { useState } from 'react';
import EmailVerificationContext from './EmailVerificationContext';

const EmailVerificationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [emailVerificationResponse, setEmailVerificationResponse] =
    useState<IEmailVerificationResponse | null>(null);

  return (
    <EmailVerificationContext.Provider
      value={{
        emailVerificationResponse,
        setEmailVerificationResponse,
      }}
    >
      {children}
    </EmailVerificationContext.Provider>
  );
};

export default EmailVerificationProvider;

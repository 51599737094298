export const questionTypesData = [
  { label: 'Coding', value: 'coding' },
  { label: 'AI/ML Challenge', value: 'challenge' },
  { label: 'Free Text', value: 'free_text' },
  { label: 'File Upload', value: 'file_upload' },
  { label: 'MCQ', value: 'mcq' },
  // { label: 'Take Home Assignment', value: 'project' },
  // { label: 'Interview', value: 'interview' },
];
export const difficultyLevelData = [
  { label: 'Easy', value: 'easy' },
  { label: 'Medium', value: 'medium' },
  { label: 'Hard', value: 'hard' },
];

import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import SubmitButton from 'common/components/form/SubmitButton';
import InputWithLabel from 'common/components/form/InputWithLabel';
import MyInput from 'common/components/form/MyInput';
import MySelect from 'common/components/form/MySelect';
import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAlert from 'common/components/alert-provider/useAlert';
import { hasMessageProperty } from 'common/utils/errors';
import { InviteMemberFormValidations } from 'business/organizer/settings/utils/validationSchema';
import useAsync from 'common/custom-hooks/useAsync';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';

const organizerService = new OrganizerService();
const InviteMemberForm: React.FC<{
  onClose: () => void;
  member?: IOrganizationMember;
  onReset?: () => void;
  editUrl?: string;
}> = ({ onClose, member, onReset, editUrl }) => {
  const classes = useStyles();
  const { organizer, setOrganizer } = useOrganizer();
  const { enqueueAlert } = useAlert();
  const {
    run,
    data: roles,
    status,
    error,
  }: IUseAsyncReturnProps<IOrganizerMemberRole[]> = useAsync();

  const initialState: IInviteMemberForm = {
    email: member?.to_email || '',
    permission: member?.user_role.id || null,
  };

  useEffect(() => {
    run(organizerService.get(organizer?.user_role_url as string));
  }, [organizer?.user_role_url, run]);

  const handleSubmit = async (data: IInviteMemberForm) => {
    try {
      editUrl
        ? await organizerService.patch(editUrl, {
            to_email: data.email,
            user_role: data.permission,
          })
        : await organizerService.post(
            `${organizer?.invitation_url}`,
            {
              to_email: data.email,
              user_role: data.permission,
            }
          );
      enqueueAlert(
        `Invitation is successfully sent to ${data.email}`
      );
      // Update the table when member is edited from table
      if (typeof onReset !== 'undefined') {
        onReset();
      }

      // When the first time invite is sent update the organizer state
      if (!organizer?.has_members) {
        setOrganizer((organizer) => {
          if (organizer) {
            return { ...organizer, has_members: true };
          }
          return organizer;
        });
      }
      onClose();
    } catch (error) {
      enqueueAlert(
        `${
          hasMessageProperty(error)
            ? error.message
            : `Something went wrong, try again after sometime`
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <GenericResponseHandler status={status} error={error}>
      <Formik
        initialValues={initialState}
        validationSchema={InviteMemberFormValidations}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <Box py={6}>
              <InputWithLabel
                title="Email Address"
                downMD="subtitle2"
                upMD="subtitle2"
                isRequired={true}
              >
                <MyInput
                  placeholder="Email"
                  name="email"
                  type="email"
                  muiInputProps={{
                    InputProps: {
                      readOnly: !!member,
                      className: clsx({
                        [classes.input__disabled_cursor]: !!member,
                      }),
                    },
                  }}
                />
              </InputWithLabel>
              <InputWithLabel
                title="Role"
                downMD="subtitle2"
                upMD="subtitle2"
                isRequired={true}
              >
                <FormControl variant="outlined" fullWidth>
                  <MySelect
                    name="permission"
                    placeholder="Select Role Access"
                    type="text"
                  >
                    {roles?.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        <Box>
                          <Typography variant="subtitle2">
                            {role.display_name}
                          </Typography>
                          <Typography color="textSecondary">
                            {role.description}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </MySelect>
                </FormControl>
              </InputWithLabel>
            </Box>
            <Box
              display="flex"
              gridColumnGap={12}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <SubmitButton
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                Send
              </SubmitButton>
            </Box>
          </Form>
        )}
      </Formik>
    </GenericResponseHandler>
  );
};

export default InviteMemberForm;

const useStyles = makeStyles((theme) => ({
  form: {
    width: '80%',
  },
  input__disabled_cursor: {
    '& .MuiInputBase-input': {
      cursor: 'no-drop',
    },
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';

import PricingFeatureIcon from 'business/organizer/assessment/components/billing/common/PricingFeatureIcon';

const Features: React.FC<{ features: string[] }> = ({ features }) => (
  <Box display="flex" flexDirection="column" ml={6}>
    {features.map((feature, idx) => (
      <Box key={idx} display="flex" gridColumnGap={4} py={1}>
        <Box pt={1}>
          <PricingFeatureIcon />
        </Box>
        <Box pr={2}>
          <Typography>{feature}</Typography>
        </Box>
      </Box>
    ))}
  </Box>
);

export default Features;

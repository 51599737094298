import React from 'react';
import { FileWithPath } from 'react-dropzone';
import Typography from '@material-ui/core/Typography';

import useFileField from 'common/components/form/file-field/useFileField';

const FileFieldResults: React.FC<{ showAcceptedFiles?: boolean }> = ({
  showAcceptedFiles = true,
}) => {
  const { acceptedFiles, fileRejections, maxFileSize } =
    useFileField();

  return (
    <>
      {acceptedFiles.length === 0 ? (
        <>
          {fileRejections.map(
            ({
              file,
              errors,
            }: {
              file: FileWithPath;
              errors: any;
            }) => (
              <Typography
                key={file.path}
                variant="body2"
                color="error"
              >
                {file.path}:{' '}
                {errors
                  .map((err: any) =>
                    err.code === 'file-too-large'
                      ? `File size is larger than ${(
                          maxFileSize /
                          1024 /
                          1024
                        ).toFixed(2)}MB`
                      : err.message
                  )
                  .join(', ')}
              </Typography>
            )
          )}
        </>
      ) : (
        <>
          {showAcceptedFiles &&
            acceptedFiles.map((file: FileWithPath, index) => (
              <Typography variant="body2" key={index}>
                {file.path}
                <br />
                {(file.size / 1024 / 1024).toFixed(2)} MB
              </Typography>
            ))}
        </>
      )}
    </>
  );
};

export default FileFieldResults;

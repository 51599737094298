import { useContext } from 'react';
import FileUploadUpdatePageContext from 'business/organizer/library/contexts/file-upload-question/contexts/FileUploadUpdatePageContext';

const useFileUploadData = (): IFileUploadUpdatePageContext => {
  return useContext(
    FileUploadUpdatePageContext
  ) as IFileUploadUpdatePageContext;
};

export default useFileUploadData;

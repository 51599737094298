import React from 'react';
import Box from '@material-ui/core/Box/Box';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
// import { makeStyles } from '@material-ui/core';

import useOrganizer from 'business/organizer/common/context/organizer/useOrganizer';
import BillingSupport from 'business/organizer/assessment/components/billing/common/BillingSupport';
import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';

const BuyButton: React.FC<IBillingBuyButtonProps> = ({
  totalPrice,
  totalDisCountedPrice,
  cbConfigMonthly,
  cbConfigYearly,
  monthly,
}) => {
  // const classes = useStyles();
  const { organizer, isFreeTrial } = useOrganizer();
  const { isPaidSubscriptionEnded } = useSubscription();

  const showBuyButton = isFreeTrial || isPaidSubscriptionEnded;

  return (
    <>
      <Typography variant="h5" align="center">
        Total:{' '}
        {/* {!!totalPrice && (
          <Typography
            component="span"
            className={classes.typography__price}
            color="textSecondary"
          >
            ${totalPrice}{' '}
          </Typography>
        )} */}
        ${totalDisCountedPrice}
      </Typography>
      {organizer && isPaidSubscriptionEnded ? null : !isFreeTrial &&
        !!organizer?.current_subscription &&
        [cbConfigMonthly.planId, cbConfigYearly.planId].includes(
          organizer?.current_subscription.plan
            .chargebee_plan_id as string
        ) ? (
        <Box display="flex" justifyContent="center" color="#444">
          <Button variant="contained" disabled={true}>
            Current plan
          </Button>
        </Box>
      ) : !isFreeTrial ? (
        <Box display="flex" justifyContent="center">
          <BillingSupport />
        </Box>
      ) : null}
      <Box display="flex" justifyContent="center">
        {/* @note:- Here we are rendering the monthly and yearly cta at the same time 
        because when the user toggles the button at that point we are not able to load 
        the yearly chargebee instance */}
        {/* Monthly */}
        <MuiLink
          // eslint-disable-next-line no-script-url
          href="javascript:void(0)"
          data-cb-type={cbConfigMonthly.type}
          data-cb-plan-id={cbConfigMonthly.planId}
          underline="none"
          style={{
            visibility: monthly ? 'visible' : 'hidden',
            display: monthly ? 'block' : 'none',
          }}
        >
          <Button
            style={{
              visibility: showBuyButton ? 'visible' : 'hidden',
              display: showBuyButton ? 'block' : 'none',
            }}
            variant="contained"
            color="primary"
          >
            Buy Now
          </Button>
        </MuiLink>
        {/* Yearly */}
        <MuiLink
          // eslint-disable-next-line no-script-url
          href="javascript:void(0)"
          data-cb-type={cbConfigYearly.type}
          data-cb-plan-id={cbConfigYearly.planId}
          underline="none"
          style={{
            visibility: !monthly ? 'visible' : 'hidden',
            display: !monthly ? 'block' : 'none',
          }}
        >
          <Button
            style={{
              visibility: showBuyButton ? 'visible' : 'hidden',
              display: showBuyButton ? 'block' : 'none',
            }}
            variant="contained"
            color="primary"
          >
            Buy Now
          </Button>
        </MuiLink>
      </Box>
    </>
  );
};

export default BuyButton;

// const useStyles = makeStyles((theme) => ({
//   typography__price: {
//     textDecoration: 'line-through',
//   },
// }));

import React, { useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import QuestionBase from 'business/organizer/library/components/question-create-update/common/QuestionBase';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import { hasMessageProperty } from 'common/utils/errors';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import useAlert from 'common/components/alert-provider/useAlert';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import { getTagsList } from 'business/organizer/library/utils/getTagsList';
import useSql from 'business/organizer/library/contexts/sql/useSql';
import { UPDATE_LIBRARY } from 'business/organizer/library/contexts/library/libraryActionTypes';
import FieldLabel from 'business/organizer/library/components/question-create-update/common/FieldLabel';
import MyInput from 'common/components/form/MyInput';
import RichTextField from 'common/components/form/RichTextField';
import SelectField from 'common/components/form/SelectField';
import { difficultyItems } from 'business/organizer/library/components/question-create-update/free-text/utils/constants';
import QuestionTagField from 'business/organizer/library/components/question-create-update/common/QuestionTagField';
import SqlFormActions from 'business/organizer/library/components/question-create-update/sql/SqlFormActions';
import SqlFileFieldBase from 'business/organizer/library/components/question-create-update/sql/SqlFileFieldBase';
import { validationSchema } from 'business/organizer/library/components/question-create-update/sql/helpers';

const libraryService = new LibraryService();
const SqlPage: React.FC<ISqlPageProps> = ({ drawerName, sql }) => {
  const { enqueueAlert } = useAlert();
  const { testId } = useTestId();
  const question = sql?.question;
  const isUpdate = useRef(!!question);
  const { handleAddQuestionsToTest } = useAddQuestionsToTest();
  const { dispatch } = useLibrary();
  const { setSqlResponse, sqlResponse } = useSql();

  const initialState: ISqlInitialStateProps = {
    name: question?.name || '',
    description: question?.description || '',
    difficulty: question?.difficulty || '',
    score: JSON.stringify(question?.score) || '',
    time_duration: JSON.stringify(question?.time_duration) || '',
    tags: sql?.tags || [],
    db_schema: question?.db_schema || '',
    expected_output:
      question?.sql_sub_questions[0]?.expected_output || '',
    // expected_result_query:
    //   question?.sql_sub_questions[0]?.expected_result_query || '',
  };

  const handleSubmit = async (data: ISqlInitialStateProps) => {
    try {
      const {
        name,
        description,
        difficulty,
        time_duration,
        tags,
        score,
        db_schema,
        expected_output,
        // expected_result_query,
      } = data;

      const questionData: { [key: string]: {} } = {
        type: 'sql',
        tags: getTagsList(tags),
        question: {
          name,
          description,
          difficulty,
          time_duration,
        },
      };

      if (description) {
        questionData.question = {
          ...questionData.question,
          description,
        };
      }

      const response =
        !!sql || !!sqlResponse
          ? await libraryService.patch(
              sql?.url || (sqlResponse?.url as string),
              questionData
            )
          : await libraryService.createQuestion(
              MY_LIBRARY_QUESTION_API_URL,
              questionData
            );

      const subQuestionData: { [key: string]: {} } = {
        // expected_result_query,
        score,
      };

      if (typeof db_schema !== 'string') {
        subQuestionData.db_schema = db_schema;
      }

      if (typeof expected_output !== 'string') {
        subQuestionData.expected_output = expected_output;
      }

      const subQuestionUrl =
        response?.data?.question?.sub_questions_url;

      if (!!subQuestionUrl) {
        await libraryService.patchFiles(
          subQuestionUrl,
          subQuestionData
        );
      }
      setSqlResponse(response?.data);

      if (!!testId && !sql) {
        handleAddQuestionsToTest(testId, response?.data?.id);
      }

      dispatch({ type: UPDATE_LIBRARY, payload: { list: 'my' } });

      enqueueAlert('Question is saved successfully.', {
        alertProps: { severity: 'success' },
      });
    } catch (error) {
      enqueueAlert(
        `${
          hasMessageProperty(error)
            ? error.message
            : '' ||
              `Something went wrong while ${
                sql !== null ? 'updating' : 'adding'
              } question. Please try again after sometime`
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  // reset context state(clear the effects)
  useEffect(() => {
    return () => {
      setSqlResponse(null);
    };
  }, [setSqlResponse]);

  return (
    <QuestionBase name="SQL" drawerProps={{ drawerName }}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box display="flex" flexDirection="column" gridRowGap={12}>
            <FieldLabel title="Question" isRequired={true}>
              <MyInput
                name="name"
                placeholder="Question"
                aria-label="qestion name"
              />
            </FieldLabel>
            <FieldLabel
              title="Question Description"
              isRequired={true}
            >
              <RichTextField name="description" />
            </FieldLabel>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <FieldLabel
                  title="Maximum Score"
                  isRequired={true}
                  tooltip
                  tooltipTitle="Add maximum score for this question"
                >
                  <MyInput
                    placeholder="Score"
                    name="score"
                    type="number"
                    aria-label="score"
                  />
                </FieldLabel>
              </Grid>
              <Grid item xs={4}>
                <FieldLabel
                  title="Max time duration"
                  isRequired={true}
                  tooltip
                  tooltipTitle="Mention maximum time duration to solve this question"
                >
                  <MyInput
                    placeholder="Minutes"
                    name="time_duration"
                    type="number"
                    aria-label="time duration"
                  />
                </FieldLabel>
              </Grid>
              <Grid item xs={4}>
                <FieldLabel
                  title="Difficulty level"
                  isRequired={true}
                  tooltip
                  tooltipTitle="For your reference"
                >
                  <SelectField
                    name="difficulty"
                    placeholder="Level of Difficulty"
                    items={difficultyItems}
                    aria-label="difficulty level"
                  />
                </FieldLabel>
              </Grid>
            </Grid>
            <FieldLabel
              title="DB Schema"
              isRequired={true}
              tooltip
              tooltipTitle="Accepts .db files only"
            >
              <SqlFileFieldBase name="db_schema" format=".db" />
            </FieldLabel>
            <FieldLabel
              title="Expected Output"
              isRequired={true}
              tooltip
              tooltipTitle="Accepts .txt files only"
            >
              <SqlFileFieldBase
                name="expected_output"
                format=".txt"
              />
            </FieldLabel>
            {/* <FieldLabel
              title="Expected Output Query"
              isRequired={false}
            >
              <MyInput
                placeholder="Expected Result Query"
                name="expected_result_query"
                aria-label="score"
                muiInputProps={{ multiline: true, rows: 4 }}
              />
            </FieldLabel> */}
            <QuestionTagField />
          </Box>
          <Box display="flex" justifyContent="flex-end" py={8}>
            <SqlFormActions testId={testId} isUpdate={isUpdate} />
          </Box>
        </Form>
      </Formik>
    </QuestionBase>
  );
};

export default SqlPage;

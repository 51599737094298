import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import FormBoxStyles from 'business/pre-subscription/common/components/FormBoxStyles';
import { FRONTEND_BUSINESS_RESEND_CONFIRMATION } from 'business/pre-subscription/auth/utils/constants';

const captionText =
  "*Already signed up, but didn't verify the email, request verification link here.";
const Caption = ({
  caption = captionText,
  link = FRONTEND_BUSINESS_RESEND_CONFIRMATION,
  ctaText = 'Resend Confirmation',
}) => (
  <FormBoxStyles
    px={2}
    py={4}
    my={4}
    display="flex"
    justifyContent="center"
    flexDirection="column"
  >
    <Typography align="center" variant="body2" color="textSecondary">
      {caption}
    </Typography>
    <Box display="flex" justifyContent="center">
      <Link to={link}>
        <Button color="primary">{ctaText}</Button>
      </Link>
    </Box>
  </FormBoxStyles>
);

export default Caption;

import { useLocation } from 'react-router-dom';
import PersistSidebar from 'common/components/utility-components/PersistSidebar';

const PersistCandidateDrawer: React.FC<{
  id: number;
  sidebar: boolean;
  onSidebar: (sidebar: boolean) => void;
}> = ({ id, sidebar, onSidebar }) => {
  const { pathname } = useLocation();

  return (
    <PersistSidebar
      endingPath={
        pathname.endsWith(`${id}`)
          ? `${id}`
          : pathname.endsWith(`${id}/questions`)
          ? `${id}/questions`
          : pathname.endsWith(`${id}/timeline`)
          ? `${id}/timeline`
          : pathname.endsWith(`${id}/proctoring`)
          ? `${id}/proctoring`
          : `${id}/questions/solution`
      }
      endPosition={
        pathname.endsWith(`${id}`) ||
        pathname.endsWith(`${id}/questions`) ||
        pathname.endsWith(`${id}/timeline`) ||
        pathname.endsWith(`${id}/proctoring`)
          ? undefined
          : /**
             * we are getting the index of "solution" string and adding its length in it
             * to get correct endPosition
             * */
            pathname.indexOf('solution') + 8
      }
      sidebarName="candidate"
      sidebar={sidebar}
      onSidebar={onSidebar}
    />
  );
};

export default PersistCandidateDrawer;

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const QuestionsSummaryTableHead: React.FC<{
  children: JSX.Element;
  showSolution?: boolean;
}> = ({ children, showSolution = true }) => {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      className={classes.table_container__root}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Score</TableCell>
            {showSolution && (
              <TableCell align="center">Solution</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuestionsSummaryTableHead;

const useStyles = makeStyles((theme) => ({
  table_container__root: {
    background: '#ffffff',

    '& .MuiTableCell-head': {
      fontSize: theme.spacing(3.5),
    },
    '& .MuiTableCell-body': {
      fontSize: theme.spacing(3.5),
      fontWeight: 400,
    },
  },
}));

import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import theme from 'business/common/theme/theme';
import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';
import McqChoiceBox from 'business/organizer/library/components/question-preview/mcq/McqChoiceBox';

const McqChoice: React.FC<ISolutionChoiceItemProps> = ({
  choice,
  userChoices,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="end" my={3}>
      <Box width="32px" height="26px" my={1.5}>
        {userChoices.includes(choice.id) ? (
          choice.is_correct_choice ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <CancelIcon color="error" />
          )
        ) : (
          choice.is_correct_choice && (
            <CheckCircleIcon color="primary" />
          )
        )}
      </Box>
      <Box
        bgcolor={theme.palette.text.secondary}
        className={classes.box__choice_icon}
      >
        <Box color="#fff" display="flex" alignItems="center">
          {userChoices.includes(choice.id) ? (
            <CheckCircleIcon color="inherit" />
          ) : (
            <FiberManualRecordIcon color="inherit" />
          )}
        </Box>
      </Box>
      <McqChoiceBox
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Box p={2}>
          <RichTextRenderer richTextHtml={choice.content} />
        </Box>
      </McqChoiceBox>
    </Box>
  );
};

export default McqChoice;

const useStyles = makeStyles((theme) => ({
  box__choice_item: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '0.5px solid #A3A3A3',
    borderRadius: 5,
    margin: theme.spacing(2, 0),
  },
  box__choice_icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    padding: theme.spacing(1.5, 0),
  },
}));

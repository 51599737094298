import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';

const TableBase: React.FC<ITableBaseProps> = ({
  tableProps,
  containerProps,
  children,
}) => {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      className={classes.tablecontainer__root}
      {...(containerProps || {})}
    >
      <Table {...(tableProps || {})}>{children}</Table>
    </TableContainer>
  );
};

export default TableBase;

const useStyles = makeStyles((theme) => ({
  tablecontainer__root: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#233D4D',
    },
    '& .MuiTableCell-head': {
      fontSize: theme.spacing(3.5),
    },
    '& .MuiTableCell-body': {
      fontSize: theme.spacing(3.5),
      fontWeight: 400,
    },
  },
}));

import React from 'react';
import MuiLink from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

import theme from 'business/common/theme/theme';

const DownloadCandidateList = ({
  link,
}: {
  link: string | undefined;
}) => (
  <Box p={4} display="flex">
    <MuiLink href={link} target="_blank">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gridColumnGap={8}
        color={theme.palette.info.main}
      >
        <CloudDownloadOutlinedIcon />
        <Typography variant="body1" color="inherit">
          Download(XLS)
        </Typography>
      </Box>
    </MuiLink>
  </Box>
);

export default DownloadCandidateList;

import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const FileUploadSolutionBase: React.FC<{
  submission: IFileUploadSubmissionBaseProps;
}> = ({ submission }) => {
  const file = submission?.file;
  return (
    <Box display="flex" alignItems="center" gridColumnGap={4}>
      <Tooltip
        title={
          !file ? 'No file uploaded' : 'Click to download the file'
        }
        arrow={true}
        placement="top"
        TransitionComponent={Zoom}
      >
        <span>
          <Button
            variant="text"
            color="primary"
            href={file}
            target="_blank"
            endIcon={<SystemUpdateAltIcon />}
            disabled={!file}
          >
            Download
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default FileUploadSolutionBase;

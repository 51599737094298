import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';
import FadeOutEffect from 'common/components/utility-components/FadeOutEffect';

const exampleTestcaseFileNames = [
  {
    id: 1,
    input: 'input00.txt',
    output: 'output00.txt',
  },
  {
    id: 2,
    input: 'input01.txt',
    output: 'output01.txt',
  },
  {
    id: 3,
    input: 'input02.txt',
    output: 'output02.txt',
  },
];

const TestCasesHeadSection = () => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const isHidden = expand;

  return (
    <div>
      <Box py={4}>
        <Typography variant="subtitle1">
          Test cases are the most important part of the programming
          problem which are used to evaluate code submissions. The
          user submitted solution will be executed independently for
          each test case in a sequence
        </Typography>
      </Box>
      <Box pb={4}>
        <Typography variant="subtitle1">
          To{' '}
          <Typography component="strong" variant="subtitle1">
            bulk upload test cases{' '}
          </Typography>
          and understand its naming convention,
        </Typography>
        <Box py={2} position="relative">
          <Typography variant="subtitle1" color="textSecondary">
            In order to bulk upload test cases, please upload .zip
            file containing input files named as input0.txt,
            input1.txt, etc and corresponding output files named as
            output0.txt, output1.txt etc. A sample .zip file can be
            downloaded here and example naming convention can be found
            below:
          </Typography>
          {!isHidden && (
            <FadeOutEffect>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => setExpand(!expand)}
              >
                Read more
              </Button>
            </FadeOutEffect>
          )}
        </Box>
        {expand && (
          <>
            <Box py={2}>
              <TableContainer
                className={classes.table__sample_format}
              >
                <Table aria-label="sample input/output file names table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">
                          <b>Input file name</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          <b>Corresponding output file name</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exampleTestcaseFileNames.map(
                      ({ id, input, output }) => (
                        <TableRow key={id}>
                          <TableCell align="left">
                            <Typography variant="body2">
                              {input}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body2">
                              {output}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box py={2}>
              <Typography variant="subtitle1" color="textSecondary">
                <Typography component="span">
                  <strong>Please note: </strong>
                </Typography>
                The first test case in the .zip file will be
                considered as sample test case and the default time
                limit and score per test case will be 10 (score) and 1
                (second) respectively. However, you can feel free to
                edit these settings.
              </Typography>
              <Box pt={2} display="flex" justifyContent="center">
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => setExpand(!expand)}
                >
                  Read less
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default TestCasesHeadSection;

const useStyles = makeStyles((theme) => ({
  table__sample_format: {
    width: '50%',
    border: '1px solid #ddd',
    boxShadow: 'none',
    '& .MuiTableCell-root': {
      padding: theme.spacing(2),
    },
  },
  typography__read_text: {
    color: theme.palette.info.main,
    cursor: 'pointer',
  },
}));

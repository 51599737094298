import React from 'react';
import Typography from '@material-ui/core/Typography';
import TableCell, {
  TableCellProps,
} from '@material-ui/core/TableCell';

const TypographyCell: React.FC<
  {
    title: string;
    subtitle: string;
  } & TableCellProps
> = ({ title, subtitle, ...otherProps }) => (
  <TableCell {...otherProps}>
    <>
      <Typography variant="subtitle2">{title || '-'}</Typography>
      <Typography variant="body2" color="textSecondary">
        {subtitle || '-'}
      </Typography>
    </>
  </TableCell>
);

export default TypographyCell;

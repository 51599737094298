import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const PercentageCovered: React.FC<
  { percentage: number } & CircularProgressProps
> = ({ percentage, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        thickness={4}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        thickness={4}
        value={percentage}
        {...props}
      />
    </div>
  );
};

export default PercentageCovered;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
  },
  bottom: {
    color:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.primary.main,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

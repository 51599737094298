import { Fragment } from 'react';
import Box from '@material-ui/core/Box';

import QuestionsSummaryTableHead from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionsSummaryTableHead';
import QuestionSummaryTable from 'hubs-candidate/common/components/candidate-detail/QuestionSummary';
import ViewSolution from 'hubs-candidate/common/components/candidate-progress/ViewSolution';
import { Container } from '@material-ui/core';

const HubsCandidateProgressQuestionSummary = ({
  question_summary,
}: {
  question_summary: any;
}) => {
  return (
    <>
      <Box py={4} width="100%">
        <Container maxWidth={false}>
          <QuestionsSummaryTableHead>
            {question_summary.map((question: any, idx: number) => (
              <Fragment key={question.id}>
                <QuestionSummaryTable
                  idx={idx}
                  key={idx}
                  questionName={question.question_name}
                  questionType={question.question_type}
                  questionScore={question.question_score}
                  score={question?.submission?.score}
                  showSolution={true}
                  children={
                    <>
                      <ViewSolution question={question} />
                    </>
                  }
                />
              </Fragment>
            ))}
          </QuestionsSummaryTableHead>
        </Container>
      </Box>
    </>
  );
};

export default HubsCandidateProgressQuestionSummary;

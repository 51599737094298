import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useAsync from 'common/custom-hooks/useAsync';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import McqChoice from './McqChoice';

const libraryService = new LibraryService();

const Choices = ({ choicesUrl }: { choicesUrl: string }) => {
  const {
    data: choices,
    error,
    status,
    run,
  }: IUseAsyncReturnProps<IChoiceItemProps[]> = useAsync();

  useEffect(() => {
    if (choicesUrl) {
      run(libraryService.get(choicesUrl));
    }
  }, [run, choicesUrl]);

  return (
    <GenericResponseHandler status={status} error={error}>
      <Box p={6}>
        <Box pt={6} pb={1}>
          <Typography variant="h6">Answer choices</Typography>
        </Box>
        {!!choices &&
          choices.map(({ id, ...props }) => (
            <McqChoice key={id} {...props} />
          ))}
      </Box>
    </GenericResponseHandler>
  );
};

export default Choices;

import React from 'react';
import { AxiosError } from 'axios';
import { Route } from 'react-router-dom';

import useQuestionPreviewData from 'business/organizer/library/pages/question-preview/useQuestionPreviewData';
import useMcq from 'business/organizer/library/contexts/mcq/useMcq';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import McqUpdatePage from 'business/organizer/library/pages/question-create-update/mcq/McqUpdatePage';
import Choices from './Choices';
import Preview from 'business/organizer/library/components/question-preview/common/Preview';

const McqPreview: React.FC<IQuestionPreviewProps> = ({
  questionUrl,
}) => {
  const { url } = useCurrentUrl();

  const { mcqChoices, mcqResponse } = useMcq();
  const hasResponse =
    !!mcqChoices?.create ||
    !!mcqChoices?.update ||
    !!mcqChoices?.remove ||
    !!mcqResponse;

  const {
    questionItem,
    responseError,
  }: {
    questionItem: IMcqQuestionResponseProps;
    responseError: AxiosError;
    status: IStatus;
  } = useQuestionPreviewData(questionUrl, hasResponse);

  return (
    <Preview
      responseError={responseError}
      questionItem={questionItem}
      updatePageRoute={
        <Route path={`${url}/edit/:mcqId`}>
          <McqUpdatePage />
        </Route>
      }
    >
      <Choices choicesUrl={questionItem?.question.choices_url} />
    </Preview>
  );
};

export default McqPreview;

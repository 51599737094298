import React from 'react';
import { v4 as uuid } from 'uuid';
import { useField, useFormikContext } from 'formik';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

const TagsField: React.FC<ITagsFieldProps> = ({ tagSuggestions }) => {
  const [field, meta] = useField({ name: 'tags' });
  const {
    values,
    setFieldValue,
  } = useFormikContext<ITagsFileUseFormikContext>();

  const errorText = meta.error && meta.touched ? meta.error : '';

  const tagsLoading = tagSuggestions === null;

  const customTagId = uuid();

  const config = {
    ignoreCase: true,
    // Internally, the autocomplete filter will check for the inputted text
    // in this string. So we include synonyms also.
    stringify: (tag: any) => `${tag.name} ${tag.synonyms.join(' ')}`,
    trim: true,
  };

  const filter = createFilterOptions<ITagField>(config);
  const filterOptions = (options: any, params: any) => {
    const filtered = filter(options, params);
    // Suggest the creation of a new value
    if (params.name !== '') {
      filtered.push({
        id: customTagId,
        isCustom: true,
        name: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  return (
    <div>
      <Autocomplete
        {...field}
        onChange={(_, value) => {
          if (value) {
            setFieldValue('tags', value);
          }
        }}
        multiple
        autoComplete
        filterSelectedOptions
        loading={tagsLoading}
        id="add-tags"
        options={tagSuggestions || []}
        defaultValue={values.tags || []}
        getOptionLabel={(option) => {
          if (option) {
            return option.name
              .replace('Add', '')
              .replaceAll('"', '')
              .trim();
          }
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        filterOptions={filterOptions}
        renderOption={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="e.g Data Science"
            variant="outlined"
            helperText={errorText}
            error={!!errorText}
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default TagsField;

export const UPLOAD_ICON = '/static/images/notebooks/Uploadicon.png';
export const TAGS_API_URL = '/api/tags/';

export const HTTP_DEBOUNCE_TIME = 750;

// Challenges
export const SUBMISSION_CSV_MIME =
  'text/csv, application/vnd.ms-excel, .csv';

export const FRONTEND_BUSINESS_HOMEPAGE_URL = '/';

//change this url
export const FRONTEND_BUSINESS_URL = '/';

export const FRONTEND_BUSINESS_AUTH_URL = '/auth/';
// Candidate URLs
export const FRONTNED_CANDIDATE_URL = '/candidate/';
export const FRONTEND_SETTINGS_URL = '/settings';

export const FRONTEND_REQUEST_DEMO = '/request-demo';

// Resource URLs
export const MATHJAX_JS =
  '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_SVG';

export const SERVER_ERROR_IMAGE = undefined;

export const GET_USER = '/api/user_profile/?my=1';

export const FRONTEND_LOGIN_URL = '/auth/login';
export const FRONTEND_SIGNUP_URL = '/auth/signup';

export const SKILLSPACE_URL = 'https://skillspace.ai/';

export const TERMS_OF_SERVICE_URL =
  'https://skillspace.ai/terms-of-service/';
export const PRIVACY_POLICY_URL =
  'https://skillspace.ai/privacy-policy/';

// Hubs Urls
export const FRONTEND_HUBS_AUTH_URL = '/hubs/auth';
export const FRONTEND_HUBS_LOGIN_URL = `${FRONTEND_HUBS_AUTH_URL}/login`;
export const FRONTEND_HUBS_SIGNUP_URL = `${FRONTEND_HUBS_AUTH_URL}/signup`;
export const FRONTEND_HUBS_URL = '/hubs';

export const APP_TYPE_PARAM = 'app_type';
export const HUBS_APP_TYPE = 'hubs';
export const HIRING_APP_TYPE = 'hiring';
export const HIRING_APP_TYPE_PARAM = `${APP_TYPE_PARAM}=${HIRING_APP_TYPE}`;
export const HUBS_APP_TYPE_PARAM = `${APP_TYPE_PARAM}=${HUBS_APP_TYPE}`;

export const FRONTNED_HUBS_CANDIDATE_URL = '/candidates/hubs';
export const FRONTEND_HUBS_CANDIDATE_AUTH_URL = `${FRONTNED_HUBS_CANDIDATE_URL}/auth`;
export const FRONTEND_HUBS_CANDIDATE_LOGIN_URL = `${FRONTEND_HUBS_CANDIDATE_AUTH_URL}/login`;
export const FRONTEND_HUBS_CANDIDATE_LOGOUT_URL = `${FRONTEND_HUBS_CANDIDATE_AUTH_URL}/logout`;
export const FRONTEND_HUBS_CANDIDATE_SIGNUP_URL = `${FRONTEND_HUBS_CANDIDATE_AUTH_URL}/signup`;
export const FRONTEND_HUBS_CANDIDATE_ASSESSMENT_URL = `${FRONTNED_HUBS_CANDIDATE_URL}/tests`;
export const FRONTEND_HUBS_CANDIDATE_SETTINGS_URL = `${FRONTNED_HUBS_CANDIDATE_URL}/settings`;
export const FRONTEND_HUBS_CANDIDATE_FORGOT_PASSWORD_URL = `${FRONTEND_HUBS_CANDIDATE_AUTH_URL}/forgot-password`;
export const FRONTEND_HUBS_CANDIDATE_CONFIRM_FORGOT_PASSWORD_URL = `${FRONTEND_HUBS_CANDIDATE_AUTH_URL}/confirm-password`;

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useIncompleteQuestions from 'business/organizer/library/contexts/incomplete-questions/useIncompleteQuestions';

const ShowIncompleteQuestions = () => {
  const { incompleteQuestions } = useIncompleteQuestions();

  const { length } = incompleteQuestions;
  return (
    <Box py={2}>
      {incompleteQuestions.slice(0, 1).map((question) => (
        <Typography variant="body1" color="secondary">
          - {question?.name}
        </Typography>
      ))}
      {length > 1 && (
        <Typography variant="body2" color="secondary">
          +{length - 1} more
        </Typography>
      )}
    </Box>
  );
};

export default ShowIncompleteQuestions;

import React from 'react';
import { TableCellProps } from '@material-ui/core/TableCell';

import TypographyCell from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/TypographyCell';
import { getDayjsDate } from 'common/utils/dayjsUtils';

const TimeCell: React.FC<{ date: string } & TableCellProps> = ({
  date,
  ...otherProps
}) => (
  <TypographyCell
    title={getDayjsDate({
      date,
      format: 'DD[/]MM[/]YY',
    })}
    subtitle={getDayjsDate({
      date,
      format: 'hh[:]mm[:]a',
    })}
    {...otherProps}
  />
);

export default TimeCell;

import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const GenericListSkeleton: React.FC<IGenericListSkeletonProps> = ({
  items,
  gridProps,
  gridItemProps,
  boxProps,
}) => {
  return (
    <Grid container direction="column" {...(gridProps || {})}>
      {[...Array(items)].map((_, idx) => (
        <Grid item xs={12} key={idx} {...(gridItemProps || {})}>
          <Box
            padding={1}
            width="100%"
            height="50px"
            {...(boxProps || {})}
          >
            <Skeleton
              width="100%"
              height="100%"
              component="div"
              variant="rect"
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default GenericListSkeleton;

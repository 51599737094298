import React, { useState, useCallback } from 'react';
import {
  DropEvent,
  FileRejection,
  FileWithPath,
} from 'react-dropzone';
import { ErrorMessage, useFormikContext } from 'formik';
import { AxiosError } from 'axios';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';

import InputWithLabel from 'common/components/form/InputWithLabel';
import useChallengeQuestion from 'business/organizer/library/contexts/challenge-question/custom-hooks/useChallengeQuestion';
import useAlert from 'common/components/alert-provider/useAlert';

import { LibraryService } from 'business/organizer/library/services/libraryService';
import FileField from 'common/components/form/file-field/FileField';
import FileFieldResults from 'common/components/form/file-field/FileFieldResults';
import ShowStatus from 'common/components/utility-components/ShowStatus';

const libraryService = new LibraryService();

const DatasetFileField: React.FC<IDatasetFileFieldProps> = ({
  title,
  nameAttribute,
  buttonText,
  isRequired,
  dataset,
  apiUrls,
  tooltipTitle,
}) => {
  // notifications
  const { enqueueAlert } = useAlert();

  // form fields
  const { setFieldValue, values } =
    useFormikContext<IUseFormikDatasetFileField>();

  const { setDatasetFiles } = useChallengeQuestion();

  const [status, setStatus] = useState<IStatus>('idle');
  const [file, setFile] = useState<null | FileWithPath>(null);
  const [error, setError] = useState<AxiosError | null>(null);

  // file upload handling
  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      setStatus('pending');
      setFile(acceptedFiles[0]);
      try {
        setError(null);

        const datasetResponse = !!apiUrls.editUrl
          ? await libraryService.patchFiles(
              apiUrls.editUrl,
              dataset(acceptedFiles[0])
            )
          : await libraryService.addFiles(
              apiUrls.createUrl,
              dataset(acceptedFiles[0])
            );

        setDatasetFiles((datasetFiles) => ({
          ...datasetFiles,
          [nameAttribute]: datasetResponse?.data,
        }));
        setFieldValue(
          nameAttribute,
          nameAttribute === 'evaluationDataset'
            ? datasetResponse?.data.prediction_csv
            : datasetResponse?.data.file
        );
        setStatus('resolved');
        enqueueAlert(`File uploaded successfully.`, {
          alertProps: { severity: 'success' },
        });
      } catch (error) {
        setStatus('rejected');
        setError((error as AxiosError).response?.data);
        enqueueAlert(
          `Not able to upload the file. Please try again.`,
          {
            alertProps: { severity: 'error' },
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nameAttribute, setFieldValue, setDatasetFiles]
  );

  const dropzoneOptions = {
    accept: '.csv',
    maxFiles: 1,
    onDrop,
    multiple: false,
    maxSize: 104857600, //100mb
  };

  return (
    <InputWithLabel
      title={title}
      downMD="subtitle2"
      upMD="subtitle2"
      isRequired={isRequired}
      tooltip
      tooltipTitle={tooltipTitle}
    >
      <Box display="flex" alignItems="center">
        <FileField
          dropzoneOptions={dropzoneOptions}
          name={nameAttribute}
        >
          <Box display="flex" alignItems="center" gridColumnGap={12}>
            <Button size="small" variant="outlined" color="primary">
              {buttonText}
            </Button>
            <ShowStatus status={status} />
            <Box display="flex">
              <Box>
                <ErrorMessage name={nameAttribute}>
                  {(msg) => (
                    <FormHelperText error={true}>
                      {msg}
                    </FormHelperText>
                  )}
                </ErrorMessage>
                {error !== null ? (
                  <Typography color="error" variant="body2">
                    *something went wrong on our side. Please try
                    again.
                  </Typography>
                ) : (
                  <FileFieldResults />
                )}
              </Box>
            </Box>
          </Box>
        </FileField>
        {/* This will show up when user will be come
             back to edit the files */}
        {file === null && !!values[nameAttribute] && (
          <Typography variant="body1">
            <MuiLink href={values[nameAttribute]} target="_blank">
              Download
            </MuiLink>
          </Typography>
        )}
      </Box>
    </InputWithLabel>
  );
};

export default DatasetFileField;

import React from 'react';
import Box from '@material-ui/core/Box';

const BillingFooterItem = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box
      width={270}
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderRadius={5}
      border="1px solid #DDE6ED"
      bgcolor="#fff"
      p={4}
      height={70}
    >
      {children}
    </Box>
  );
};

export default BillingFooterItem;

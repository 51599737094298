import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import useTestCases from 'business/organizer/library/contexts/coding-question/custom-hooks/useTestCases';
import TestCasesListItem from 'business/organizer/library/components/question-create-update/coding/testcases/TestCasesListItem';

const TestCasesList = () => {
  const { testCases } = useTestCases();

  return (
    <Box pt={4}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Test Case</TableCell>
              <TableCell align="center">Score</TableCell>
              <TableCell align="center">Time Limit</TableCell>
              <TableCell align="center">Input</TableCell>
              <TableCell align="center">Expected Output</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!testCases &&
              testCases.map((testCase: any, idx: number) => (
                <TestCasesListItem
                  key={testCase.id}
                  testCase={testCase}
                  idx={idx}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TestCasesList;

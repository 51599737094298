const CancelledInvitesIcon = () => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.09 14H2V4L10 9L18 4V9.09C18.72 9.21 19.39 9.46 20 9.81V2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H11.09C11.04 15.67 11 15.34 11 15C11 14.66 11.04 14.33 11.09 14ZM18 2L10 7L2 2H18ZM18.41 15L20.54 17.12L19.12 18.54L17 16.41L14.88 18.54L13.47 17.12L15.59 15L13.47 12.88L14.88 11.47L17 13.59L19.12 11.47L20.54 12.88L18.41 15Z"
      fill="#0A66C2"
    />
  </svg>
);

export default CancelledInvitesIcon;

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';

import { FRONTEND_BUSINESS_RESEND_CONFIRMATION } from 'business/pre-subscription/auth/utils/constants';
import theme from 'business/common/theme/theme';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';

const ConfirmEmail = () => {
  return (
    <FormSectionBase
      title="Verify Your Email Address"
      subtitle="We have sent you a verifcation link to the email address
provided during sign up. Please click the link to proceed"
    >
      <Box textAlign="center">
        <Typography align="center">
          Didn't see an email?{' '}
          <Link
            to={FRONTEND_BUSINESS_RESEND_CONFIRMATION}
            color={theme.palette.primary.main}
          >
            Resend Email
          </Link>
        </Typography>
      </Box>
    </FormSectionBase>
  );
};

export default ConfirmEmail;

import React, { useState, useEffect, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';

import CustomModal from 'common/components/custom-modal/CustomModal';
import useGetTests from 'business/organizer/library/contexts/get-tests/useGetTests';
import HttpError from 'common/components/http-error/HttpError';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import CreateTest from 'business/organizer/assessment/components/assessment-tests/test-list/CreateTest';
// import { HTTP_DEBOUNCE_TIME } from 'community/notebooks/utils/constants';

const AddQuestionsModal: React.FC<IAddQuestionsModalProps> = ({
  open,
  closeAddQuestionsModal,
  questionId,
}) => {
  const classes = useStyles();
  // const searchRef = useRef<HTMLInputElement | null>(null);
  // const [search, setSearch] = useState('');

  // const handleSearch = useMemo(
  //   () =>
  //     debounce((event: React.ChangeEvent<HTMLInputElement>) => {
  //       const value: string = event.target.value;
  //       setSearch(value);
  //     }, HTTP_DEBOUNCE_TIME),
  //   []
  // );

  const { tests, error } = useGetTests();
  const [addToTest, setAddToTest] = useState<number | null>(null);

  const {
    handleAddQuestionsToTest,
    setAddQuestionToTestResponse,
    addQuestionToTestResponse,
    setAddQuestionsToTest,
    addQuestionsToTest,
  } = useAddQuestionsToTest();

  const handleCloseModal = () => {
    closeAddQuestionsModal();
    setAddQuestionToTestResponse(null);
  };

  const handleAddQuestions = () => {
    if (typeof addToTest === 'number') {
      handleAddQuestionsToTest(addToTest, questionId);
    }
  };

  useEffect(() => {
    if (addQuestionToTestResponse) {
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addQuestionToTestResponse]);

  useEffect(() => {
    return () => {
      setAddQuestionsToTest(null);
    };
  }, [setAddQuestionsToTest]);

  const addQuestionToTest = useMemo(
    () =>
      !!questionId || !!addQuestionsToTest?.length
        ? (testId: number) =>
            handleAddQuestionsToTest(testId, questionId)
        : undefined,
    [addQuestionsToTest?.length, handleAddQuestionsToTest, questionId]
  );

  return (
    <CustomModal open={open}>
      <Box width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box py={2} px={4}>
            <Typography variant="h5">Add To Test</Typography>
          </Box>
          <Box color="#666" p={2}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon color="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Divider variant="fullWidth" />
        {error !== null ? (
          <HttpError axiosError={error} />
        ) : tests === null ? (
          <Box py={4}>
            <Container maxWidth="sm">
              <GenericListSkeleton items={3} />
            </Container>
          </Box>
        ) : (
          <>
            {!tests.length ? (
              <Box
                py={12}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6" align="center">
                  You haven't created any test yet. Please create one,
                </Typography>
                <Box py={2}>
                  <CreateTest addQuestionToTest={addQuestionToTest} />
                </Box>
              </Box>
            ) : (
              <>
                <Box py={4}>
                  <Container maxWidth="sm">
                    {/* <TextField
              id="nb-search"
              placeholder="Search For a Test"
              type="text"
              size="small"
              fullWidth
              variant="outlined"
              className={classes.typography_search}
              onChange={handleSearch}
              inputRef={searchRef}
            /> */}
                    <List
                      className={classes.list_test}
                      disablePadding
                    >
                      {tests.map((test: any) => (
                        <ListItem
                          key={test.id}
                          button
                          divider
                          onClick={() => setAddToTest(test.id)}
                          selected={addToTest === test.id}
                        >
                          <ListItemText>{test.name}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Container>
                </Box>
                <Box
                  p={4}
                  display="flex"
                  justifyContent="flex-end"
                  gridColumnGap={12}
                >
                  <Button
                    onClick={handleCloseModal}
                    variant="outlined"
                    color="primary"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={handleAddQuestions}
                    variant="contained"
                    color="primary"
                    disabled={!addToTest}
                  >
                    Confirm
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default AddQuestionsModal;

const useStyles = makeStyles((theme) => ({
  link__tests: {
    color: theme.palette.info.main,
  },
  list_test: {
    maxHeight: 300,
    overflowY: 'scroll',
    marginTop: 4,
    background: '#FFFFFF',
    width: '100%',
    border: '1px solid rgba(102, 102, 102, 0.8)',
    borderRadius: '2px',
    // '& .MuiCheckbox-colorSecondary.Mui-checked': {
    //   color: '#233D4D',
    // },
    // '& .MuiListItem-root': {
    //   paddingTop: 0,
    //   paddingBottom: 0,
    // },
  },
  // typography_search: {
  //   '& fieldset': {
  //     border: '1px solid rgba(102, 102, 102, 0.8)',
  //   },
  // },
}));

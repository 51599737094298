export const getQuestionDisplayName = (type: IQuestionType) => {
  switch (type) {
    case 'free-text':
      return 'Free Text';
    case 'file-upload':
      return 'File Upload';
    case 'challenge':
      return 'AI/ML Challenge';
    case 'coding':
      return 'Coding';
    case 'mcq':
      return 'MCQ';
    case 'project':
      return 'Take Home Assignment';
    case 'interview':
      return 'Interview';
    case 'sql':
      return 'Sql';
    default:
      return new Error(`${type} is not valid question type`);
  }
};

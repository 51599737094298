import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';

import RenderFileUploadStep from 'business/organizer/library/components/question-create-update/file-upload/RenderFileUploadStep';
import FileUploadFormActions from 'business/organizer/library/components/question-create-update/file-upload/form-actions/FileUploadFormActions';
import useFileUpload from 'business/organizer/library/contexts/file-upload-question/custom-hooks/useFileUpload';
import { validationSchema } from 'business/organizer/library/components/question-create-update/file-upload/utils/validationSchema';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import useIsUpdate from 'common/custom-hooks/useIsUpdate';
import QuestionBase from 'business/organizer/library/components/question-create-update/common/QuestionBase';

const FileUploadPage: React.FC<IFileUploadQuestionProps> = ({
  fileUpload,
  drawerName,
}) => {
  const { testId } = useTestId();

  const {
    handleProblemStatementSubmit,
    problemStatementResponse,
    setProblemStatementResponse,
  } = useFileUpload();

  const question = fileUpload?.question;
  const problemStatementResponseQuestion =
    problemStatementResponse?.question;

  // clear the effects
  useEffect(() => {
    return () => {
      setProblemStatementResponse(null);
    };
  }, [setProblemStatementResponse]);

  const [isUpdate] = useIsUpdate(!!question);

  const initialState: IInitialStateFileUploadFormProps = {
    name: question?.name
      ? problemStatementResponseQuestion?.name || question?.name
      : '',
    description: question?.description
      ? problemStatementResponseQuestion?.description ||
        question?.description
      : '',
    time_duration: question?.time_duration
      ? problemStatementResponseQuestion?.time_duration ||
        question?.time_duration
      : '',
    score: question?.score
      ? problemStatementResponseQuestion?.score || question?.score
      : '',
    difficulty: question?.difficulty
      ? problemStatementResponseQuestion?.difficulty ||
        question?.difficulty
      : '',
    tags: fileUpload?.tags || [],
    file_type: question?.file_type
      ? problemStatementResponseQuestion?.file_type ||
        question?.file_type
      : '',
    max_file_size: question?.max_file_size
      ? problemStatementResponseQuestion?.max_file_size ||
        question?.max_file_size
      : '',
    testId: !!testId ? testId : 0,
  };

  return (
    <QuestionBase name="File Upload" drawerProps={{ drawerName }}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema[0]}
        onSubmit={handleProblemStatementSubmit}
      >
        <Form>
          <RenderFileUploadStep />
          <Box display="flex" justifyContent="flex-end" py={8}>
            <FileUploadFormActions isUpdate={isUpdate} />
          </Box>
        </Form>
      </Formik>
    </QuestionBase>
  );
};

export default FileUploadPage;

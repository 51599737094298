import React, { useState } from 'react';

import QuestionDrawerContext from 'business/organizer/library/contexts/question-drawer/QuestionDrawerContext';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const QuestionDrawerProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { url: currentUrl } = useCurrentUrl();
  const [questionDrawer, setQuestionDrawer] = useState(false);

  return (
    <QuestionDrawerContext.Provider
      value={{
        questionDrawer,
        setQuestionDrawer,
        currentUrl,
      }}
    >
      {children}
    </QuestionDrawerContext.Provider>
  );
};

export default QuestionDrawerProvider;

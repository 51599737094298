import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack';
import { pasteEventHandler } from 'business/candidate/proctoring/utils/helpers';
import {
  getProctor,
  PROCTOR_NAME,
} from 'business/organizer/assessment/components/assessment-tests/test-detail/settings/utils/helpers';
import { getRandomNumber } from 'common/utils/getRandomNumber';

export const generateRandomTimeList = () => {
  return [
    getRandomNumber(1, 18) * 1000,
    getRandomNumber(21, 38) * 1000,
    getRandomNumber(41, 58) * 1000,
  ];
};

export const onPasteEvent = ({
  apiUrl,
  proctor_settings,
  event,
  enqueueSnackbar,
}: {
  apiUrl: string;
  proctor_settings: ITestDetailProctorSettingsProps[] | undefined;
  event: any;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
}) => {
  pasteEventHandler(
    apiUrl,
    // @ts-ignore
    event,
    !getProctor(proctor_settings, PROCTOR_NAME.COPY_DETECTION)
      ?.is_enabled
  );
  if (
    getProctor(proctor_settings, PROCTOR_NAME.COPY_DETECTION)
      ?.is_enabled
  ) {
    enqueueSnackbar(
      'Copy paste functionality is prohibited in this test!',
      {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        autoHideDuration: 3000,
      }
    );
  }
};

export const sqlLanguage = {
  id: 1,
  name: 'SQL',
  label: 'SQL',
  sample_code: "SELECT 'Hello world'",
  single_line_comment_synatx: '--',
};

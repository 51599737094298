import { useContext } from 'react';

import IncompleteQuestionsContext from 'business/organizer/library/contexts/incomplete-questions/IncompleteQuestionsContext';

const useIncompleteQuestions = (): IIncompleteQuestionsContext => {
  return useContext(
    IncompleteQuestionsContext
  ) as IIncompleteQuestionsContext;
};

export default useIncompleteQuestions;

import React from 'react';

const FilterIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99997 16V8.55172L15.7349 2.2069C15.8202 2.11284 15.8875 2.00095 15.933 1.87778C15.9785 1.75461 16.0013 1.62266 15.9999 1.48966V0.551724C15.9999 0.405398 15.9473 0.265065 15.8535 0.161596C15.7597 0.058128 15.6326 0 15.4999 0H0.499998C0.36739 0 0.240214 0.058128 0.146446 0.161596C0.0526782 0.265065 0 0.405398 0 0.551724V1.4731C9.34905e-05 1.60341 0.0235569 1.73241 0.0690382 1.85267C0.114519 1.97293 0.181119 2.08207 0.264999 2.17379L5.99998 8.59586V14.2234L9.99997 16Z"
      fill="#666666"
    />
  </svg>
);

export default FilterIcon;

import React from 'react';
import {
  Button,
  Box,
  makeStyles,
  ExtendButtonBase,
  ButtonTypeMap,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Display a circular spinner on disabled=true
 */
const SpinnerButton: ExtendButtonBase<
  ButtonTypeMap<{ showDisableSpinner?: boolean }>
> = ({ showDisableSpinner = true, ...otherProps }) => {
  const classes = useStyles();
  const { disabled } = otherProps;

  return (
    <Box position="relative">
      <Button
        variant="contained"
        color="primary"
        size="medium"
        {...otherProps}
      >
        {otherProps.children}
        {disabled && showDisableSpinner && (
          <CircularProgress
            size={24}
            className={classes.circular_progress}
          />
        )}
      </Button>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  circular_progress: {
    color: 'green[500]',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default SpinnerButton;

import React from 'react';
import Box from '@material-ui/core/Box';

const CandidateListNavbarStyles = ({
  children,
}: {
  children: JSX.Element;
}) => (
  <Box
    bgcolor="#fff"
    borderRadius={5}
    boxShadow="0px 6px 12px rgba(221, 230, 237, 0.5)"
    border="1px solid #FCFCFC"
  >
    {children}
  </Box>
);

export default CandidateListNavbarStyles;

import { useContext } from 'react';
import SqlContext from 'business/organizer/library/contexts/sql/SqlContext';

const useSql = (): ISqlContext => {
  const context = useContext(SqlContext) as ISqlContext;
  if (context === undefined) {
    throw new Error('useSql must be used within a SqlProvider.');
  }
  return context;
};

export default useSql;

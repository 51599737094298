import { useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';

import AuthPageButton from 'business/pre-subscription/auth/components/common/AuthPageButton';
import MyInput from 'common/components/form/MyInput';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import theme from 'business/common/theme/theme';
import {
  getErrorMessage,
  getValidationSchema,
} from 'business/pre-subscription/auth/components/common/helpers';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import {
  LOGIN_API_URL,
  FRONTEND_BUSINESS_FORGOT_PASSWORD,
} from 'business/pre-subscription/auth/utils/constants';
import {
  FRONTEND_BUSINESS_DASHBOARD_URL,
  SKILLSPACE_APP_TYPE_PARAM,
  HUBS_APP_TYPE_PARAM,
} from 'business/organizer/common/utils/constants';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import {
  FRONTEND_HUBS_SIGNUP_URL,
  FRONTEND_SIGNUP_URL,
} from 'common/utils/constants';
import AuthBlockFooter from 'business/pre-subscription/auth/components/common/AuthBlockFooter';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';

const businessAuthService = new BusinessAuthService();
const Login = () => {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);

  const [error, setError] = useState<IBusinessFormErrorProps | null>(
    null
  );

  const initialState = {
    email: '',
    password: '',
  };

  const redirectUrl = params.get('next');

  const { isHubsUrl } = useCurrentUrl();
  const dashboardUrl = isHubsUrl
    ? `/hubs${FRONTEND_BUSINESS_DASHBOARD_URL}`
    : `${FRONTEND_BUSINESS_DASHBOARD_URL}`;

  const validationSchema =
    getValidationSchema<IBusinessLoginFormProps>(initialState);

  const handleSubmit: AuthSubmissionHandler = async (
    values: IBusinessLoginFormProps
  ) => {
    try {
      const { email, password } = values;
      const data = {
        email,
        password,
      };

      const response = await businessAuthService.authPost(
        isHubsUrl
          ? `${LOGIN_API_URL}&${HUBS_APP_TYPE_PARAM}`
          : `${LOGIN_API_URL}&${SKILLSPACE_APP_TYPE_PARAM}`,
        data
      );
      if (response) {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          history.push(dashboardUrl);
        }
      }
    } catch (error) {
      setError(
        (error as AxiosError).response
          ?.data as IBusinessFormErrorProps
      );
    }
  };

  const resetResponse = () => {
    setError(null);
  };

  const errorMessage = getErrorMessage(error);

  return (
    <FormSectionBase
      title="Welcome Back!"
      subtitle={
        isHubsUrl
          ? ''
          : 'Get back to hiring the best employees, anywhere in the world'
      }
    >
      <>
        <NotificationDialog
          open={error !== null}
          variant="error"
          agreeText="Try Again"
          onAgree={resetResponse}
        >
          <RichTextRenderer richTextHtml={`<p>${errorMessage}</p>`} />
        </NotificationDialog>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FieldStyles>
              <MyInput
                placeholder="Work Email Address"
                name="email"
                type="email"
              />
            </FieldStyles>
            <FieldStyles>
              <MyInput
                placeholder="Password"
                name="password"
                type="password"
              />
            </FieldStyles>
            <FieldStyles display="flex">
              <Link
                to={
                  isHubsUrl
                    ? `/hubs${FRONTEND_BUSINESS_FORGOT_PASSWORD}`
                    : FRONTEND_BUSINESS_FORGOT_PASSWORD
                }
                color={theme.palette.info.main}
              >
                <Typography color="inherit">
                  Forgot Password?
                </Typography>
              </Link>
            </FieldStyles>
            <AuthPageButton>Login</AuthPageButton>
            <AuthBlockFooter
              title="Don't have an account ?"
              ctaLink={
                isHubsUrl
                  ? FRONTEND_HUBS_SIGNUP_URL
                  : FRONTEND_SIGNUP_URL
              }
              ctaText="Sign Up"
              redirectUrl="signup"
            />
          </Form>
        </Formik>
      </>
    </FormSectionBase>
  );
};

export default Login;

import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const McqChoiceBox: React.FC<BoxProps> = ({ ...props }) => {
  const classes = useStyles();
  return <Box className={classes.box__choice_item} {...props} />;
};

export default McqChoiceBox;

const useStyles = makeStyles((theme) => ({
  box__choice_item: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '0.5px solid #A3A3A3',
    borderRadius: 5,
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box/Box';

import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';

const QuestionPreviewSkeleton = () => {
  return (
    <Box p={6}>
      <GenericListSkeleton items={3} boxProps={{ height: '10px' }} />
      <GenericListSkeleton
        items={2}
        boxProps={{ height: '10px', width: '50%' }}
      />
    </Box>
  );
};

export default QuestionPreviewSkeleton;

import React from 'react';
import Box from '@material-ui/core/Box/Box';

const BillingCardStyles = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box
      bgcolor="#fff"
      border="0.942288px solid #CCCCCC"
      box-shadow="1.88458px 3.76915px 3.76915px rgba(68, 146, 76, 0.15)"
      borderRadius={5}
      width={302}
    >
      {children}
    </Box>
  );
};

export default BillingCardStyles;

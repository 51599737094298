import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useQuestionUpdateData from 'business/organizer/library/custom-hooks/useQuestionUpdateData';
import useFreeTextData from 'business/organizer/library/contexts/free-text-question/custom-hooks/useFreeTextData';
import FreeTextPage from 'business/organizer/library/pages/question-create-update/free-text/FreeTextPage';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import QuestionErrorHandler from 'business/organizer/library/components/question-create-update/common/QuestionErrorHandler';
import QuestionFormSkeleton from 'business/organizer/common/components/skeletons/QuestionFormSkeleton';

const FreeTextUpdatePage = () => {
  let { freeTextId } = useParams<{ freeTextId: string }>();
  const {
    questionItem,
    responseError,
    status,
  } = useQuestionUpdateData(freeTextId, MY_LIBRARY_QUESTION_API_URL);
  const { setFreeText } = useFreeTextData();

  useEffect(() => {
    if (questionItem) {
      setFreeText(questionItem);
    }
    return () => {
      setFreeText(null);
    };
  }, [setFreeText, questionItem]);

  return (
    <GenericResponseHandler
      status={status}
      errorPlaceholder={
        <QuestionErrorHandler error={responseError} />
      }
      skeleton={<QuestionFormSkeleton />}
    >
      <FreeTextPage freeText={questionItem} drawerName="edit" />
    </GenericResponseHandler>
  );
};

export default FreeTextUpdatePage;

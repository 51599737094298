import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box/Box';

interface IBreadcrumbDataProps {
  route: string;
  label: string;
}

interface IGenericBreadcrumbProps {
  breadcrumbData: IBreadcrumbDataProps[];
  icon?: React.ReactNode;
}

const GenericBreadcrumb: React.FC<IGenericBreadcrumbProps> = ({
  breadcrumbData,
  icon = <NavigateNextIcon fontSize="small" />,
}) => {
  const classes = useStyles();
  return (
    <>
      {breadcrumbData !== null && breadcrumbData.length > 0 && (
        <Box pt={4}>
          <Breadcrumbs separator={icon} aria-label="breadcrumb">
            {breadcrumbData !== null &&
              breadcrumbData.map(({ label, route }, idx) =>
                idx === breadcrumbData.length - 1 ? (
                  <Typography
                    key={label}
                    variant="body2"
                    color="textSecondary"
                  >
                    {label}
                  </Typography>
                ) : (
                  <Link key={label} to={route}>
                    <Typography
                      color="textPrimary"
                      variant="body2"
                      className={classes.link_breadcrumb}
                    >
                      {label}
                    </Typography>
                  </Link>
                )
              )}
          </Breadcrumbs>
        </Box>
      )}
    </>
  );
};

export default GenericBreadcrumb;

const useStyles = makeStyles((theme) => ({
  link_breadcrumb: {
    '&:hover': {
      color: theme.palette.info.dark,
      textDecoration: 'underline',
    },
  },
}));

import React from 'react';
import { useLocation } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';

import useSidebarTabs from 'business/organizer/common/components/navigation/useSidebarTabs';
import HasPermissionBase from '../HasPermissionBase';

const TabList = () => {
  const classes = useStyles();
  const tabs = useSidebarTabs();
  const location = useLocation();

  return (
    <List className={classes.list_tablist}>
      {Object.values(tabs).map(
        ({ id, route, Icon, name, RouteLink, disabled }) =>
          disabled ? (
            <HasPermissionBase
              key={id}
              condition={disabled}
              tooltipProps={{
                title: 'Only superadmins can access this page',
                arrow: true,
                placement: 'top',
              }}
            >
              <ListItem
                className={
                  location.pathname.startsWith(route)
                    ? classes.navlink_active
                    : classes.navlink
                }
                disabled={disabled}
                style={{ cursor: 'no-drop' }}
              >
                <ListItemIcon
                  style={{ minWidth: '44px' }}
                  color="#333333"
                >
                  {Icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h6' }}
                >
                  {name}
                </ListItemText>
              </ListItem>
            </HasPermissionBase>
          ) : (
            <RouteLink key={id} to={route}>
              <ListItem
                className={
                  location.pathname.startsWith(route)
                    ? classes.navlink_active
                    : classes.navlink
                }
                disabled={disabled}
              >
                <ListItemIcon
                  style={{ minWidth: '44px' }}
                  color="#333333"
                >
                  {Icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h6' }}
                >
                  {name}
                </ListItemText>
              </ListItem>
            </RouteLink>
          )
      )}
    </List>
  );
};

export default TabList;

const useStyles = makeStyles((theme) => ({
  list_tablist: {
    padding: theme.spacing(0),
    '& .MuiListItem-root': {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  navlink: {
    color: '#222222',
    backgroundColor: '#fff',
  },
  navlink_active: {
    backgroundColor: '#f1f6f9',
    borderLeft: '3px solid #042649',
    '& .MuiTypography-h6': {
      color: '#222222',
    },
    '& .MuiSvgIcon-root': {
      color: '#222222',
    },
  },
}));

import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import useTestQuestions from 'business/organizer/assessment/contexts/test-questions/useTestQuestions';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';

const QuestionsEmptyPlaceholder: React.FC<IQuestionsEmptyPlaceholderProps> =
  ({ anchorEl, handleClick, closeDropdownWithDelay }) => {
    const { setLibrary } = useTestQuestions();
    const { toggleDrawer } = useToggleDrawer();

    const { isSubscriptionExpired, isViewer } = useHasPermission();

    return (
      <>
        <Box
          width="100%"
          display="flex"
          gridColumnGap={12}
          margin={30}
        >
          <HasPermission
            isSubscriptionExpired={isSubscriptionExpired}
            isViewer={isViewer}
            tooltipProps={{ placement: 'top', arrow: true }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={toggleDrawer(true, setLibrary, 'library')}
              disabled={isSubscriptionExpired || isViewer}
            >
              Add From Library
            </Button>
          </HasPermission>
          <Typography variant="h6">or</Typography>
          <Box>
            <HasPermission
              isSubscriptionExpired={isSubscriptionExpired}
              isViewer={isViewer}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <Button
                onMouseEnter={handleClick}
                onMouseLeave={closeDropdownWithDelay}
                onClick={handleClick}
                variant="outlined"
                color="primary"
                endIcon={
                  anchorEl !== null ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
                disabled={isSubscriptionExpired || isViewer}
              >
                Create Custom Question
              </Button>
            </HasPermission>
          </Box>
        </Box>
      </>
    );
  };

export default QuestionsEmptyPlaceholder;

import React from 'react';
import { useEffect } from 'react';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useBreadcrumb from 'business/organizer/common/context/breadcrumb/useBreadcrumb';

/**
 * We created this as component instead of hook because
   we wanted it to run before the child components of page component
   for further info about why we did this way checckout:-

   https://stackoverflow.com/questions/58352375/what-is-the-correct-order-of-execution-of-useeffect-in-react-parent-and-child-co
   https://github.com/facebook/react/issues/15281

   So instead of hook we created a component and placed it in return part
   so it will always run before the other child because now
   it is not the effect it is also the child of that particular component.
 * */

const GenerateBreadcrumb: React.FC<IBreadcrumbDataListProps> = ({
  data,
}) => {
  const { url } = useCurrentUrl();
  const { setBreadcrumbData } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbData((breadcrumbData) => [
      ...(breadcrumbData || []),
      ...data,
    ]);
    return () => {
      setBreadcrumbData((breadcrumbData) =>
        breadcrumbData.slice(0, breadcrumbData.length - data.length)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, setBreadcrumbData]);

  return <></>;
};

export default GenerateBreadcrumb;

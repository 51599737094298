import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Question name should be at least 1 characters long')
    .required('Question name is required'),
  description: Yup.string().required('Description is required'),
  time_duration: Yup.number()
    .required('Time is required.')
    .typeError('Time value must be number'),
  score: Yup.number()
    .required('Score is required')
    .typeError('Score must be number'),
  difficulty: Yup.string()
    .oneOf(
      ['easy', 'medium', 'hard'],
      'This is not the valid choice.'
    )
    .required('Difficulty level is required.'),
  tags: Yup.array()
    .min(1, 'Please add at least 1 tag')
    .required('Tags are required'),
  db_schema: Yup.mixed().required('DB schema is required'),
  expected_output: Yup.mixed().required(
    'Expected output is required'
  ),
});

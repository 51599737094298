import React from 'react';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';

const Header = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <Box textAlign="center" pb={6}>
      <Box pb={2}>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box>
        <Typography>{subtitle}</Typography>
      </Box>
    </Box>
  );
};

export default Header;

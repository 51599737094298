export const addFilters = (
  filterName: string[],
  payload: string,
  storageName: string
) => {
  const filters = [...filterName, payload];
  sessionStorage.setItem(storageName, JSON.stringify(filters));
  return filters;
};

export const removeFilters = (
  filterName: string[],
  payload: string,
  storageName: string
) => {
  const updatedFilters = filterName.filter(
    (filterItem) => filterItem !== payload
  );
  sessionStorage.setItem(storageName, JSON.stringify(updatedFilters));
  return updatedFilters;
};

export const markTabsToUnload = (
  state: IInitialStateProps,
  actionValue: {}
): IInitialStateProps => {
  return {
    ...state,
    shared: {
      ...state.shared,
      questions: null,
      isLoaded: false,
      params: {
        page: 1,
      },
    },
    my: {
      ...state.my,
      questions: null,
      isLoaded: false,
      params: {
        page: 1,
      },
    },
    globalParams: {
      ...state.globalParams,
      ...actionValue,
    },
  };
};

export const clearFilters = (
  state: IInitialStateProps
): IInitialStateProps => {
  sessionStorage.removeItem('type');
  sessionStorage.removeItem('difficulty');
  sessionStorage.removeItem('tags');
  sessionStorage.removeItem('programmingLanguages');

  return markTabsToUnload(state, {
    type: [],
    difficulty: [],
    tags: [],
    programmingLanguages: [],
  });
};

export const filterByTags = (
  payload: IQuestionTags[],
  tagName: LibraryTagName
) => {
  sessionStorage.setItem(tagName, JSON.stringify(payload));
  return payload;
};

export const removeTag = (
  filterName: IQuestionTags[],
  payload: number,
  storageName: string
) => {
  const updatedFilters = filterName.filter(
    (filterItem) => filterItem.id !== payload
  );
  sessionStorage.setItem(storageName, JSON.stringify(updatedFilters));
  return updatedFilters;
};

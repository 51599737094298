import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, Theme } from '@material-ui/core/styles';

const ErrorHelperText: React.FC<IErrorHelperTextProps> = ({
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormHelperText
      error={true}
      classes={{ error: classes.error }}
      {...props}
    />
  );
};

export default ErrorHelperText;

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: theme.spacing(3),
  },
}));

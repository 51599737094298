import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useCandidateProgressList from 'business/organizer/assessment/components/candidate-progress/candidate-list/custom-hooks/useCandidateProgressList';
import HttpError from 'common/components/http-error/HttpError';
import CandidateListNavbarStyles from 'business/organizer/assessment/components/candidate-progress/candidate-list/styles/CandidateListNavbarStyles';
import DownloadCandidateList from 'business/organizer/assessment/components/candidate-progress/candidate-list/DownloadCandidateList';
import TableBase from 'common/components/table/TableBase';
import TableSortCell from 'common/components/table/TableSortCell';
import TablePaginationBoxStyles from 'business/organizer/assessment/components/candidate-progress/common/TablePaginationBoxStyles';
import TimeCell from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/TimeCell';
import Actions from 'business/organizer/assessment/components/candidate-progress/candidate-list/invites/Actions';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import ShowAppliedParams from '../common/ShowAppliedParams';
import { toTitleSnakeCase } from 'common/utils/toTitleCase';

const InvitesBase: React.FC<{
  inviteStatus: InviteStatus;
  showExpireCell?: boolean;
  showAcceptedCell?: boolean;
  showCancelledCell?: boolean;
}> = ({
  inviteStatus,
  showAcceptedCell,
  showCancelledCell,
  showExpireCell,
}) => {
  const { testDetail } = useTestDetail();
  const {
    data: candidates,
    error,
    params,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSort,
    reset,
  } = useCandidateProgressList<IInvitesProps>({
    apiUrl: testDetail?.invite_url as string,
    candidateStatus: inviteStatus,
  });

  const { setHasChanges } = useCandidateProgress();

  useEffect(() => {
    return () => {
      reset();
      setHasChanges(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error !== null) {
    return (
      <Box py={12}>
        <HttpError axiosError={error} />
      </Box>
    );
  }

  return (
    <>
      <Box py={4}>
        <CandidateListNavbarStyles>
          <Box display="flex" justifyContent="flex-end">
            <DownloadCandidateList
              link={`${testDetail?.candidate_download_url}?status=${inviteStatus}`}
            />
          </Box>
        </CandidateListNavbarStyles>
      </Box>
      <ShowAppliedParams
        order={params.order}
        order_by={params.order_by}
      />
      <TableBase tableProps={{ 'aria-label': 'candidate-invites' }}>
        <TableHead>
          <TableRow>
            <TableCell>Candidate</TableCell>
            <TableSortCell
              label="Invite sent on"
              align="center"
              onOrderBy={(order: IOrder) =>
                handleSort('invitation_sent_on', order)
              }
              isActive={params.order_by === 'invitation_sent_on'}
            />
            {showExpireCell && (
              <TableSortCell
                label="Invite expires on"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('invitation_expires_at', order)
                }
                isActive={params.order_by === 'invitation_expires_at'}
              />
            )}
            {showCancelledCell && (
              <TableSortCell
                label="Invite Cancelled on"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('invite_cancelled_on', order)
                }
                isActive={params.order_by === 'invite_cancelled_on'}
              />
            )}
            {showAcceptedCell && (
              <TableSortCell
                label="Invite accepted on"
                align="center"
                onOrderBy={(order: IOrder) =>
                  handleSort('invite_accepted_on', order)
                }
                isActive={params.order_by === 'invite_accepted_on'}
              />
            )}
            {showAcceptedCell && (
              <TableCell align="center">Test Status</TableCell>
            )}
            {/* actions column */}
            {!showAcceptedCell && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        {candidates !== null && (
          <TableBody>
            {candidates?.results.map((candidate) => (
              <TableRow key={candidate.id}>
                <TableCell>{candidate.email}</TableCell>
                <TimeCell
                  align="center"
                  date={candidate.invitation_sent_on}
                />
                {showExpireCell && (
                  <TimeCell
                    align="center"
                    date={candidate.invitation_expires_at}
                  />
                )}
                {showCancelledCell && (
                  <TimeCell
                    align="center"
                    date={candidate.invite_cancelled_on}
                  />
                )}
                {showAcceptedCell && (
                  <TimeCell
                    align="center"
                    date={candidate.invite_accepted_on}
                  />
                )}
                {showAcceptedCell && (
                  <TableCell align="center">
                    {!!candidate?.test_status
                      ? toTitleSnakeCase(candidate?.test_status)
                      : '-'}
                  </TableCell>
                )}
                {!showAcceptedCell && (
                  <TableCell>
                    <Actions
                      id={candidate.id}
                      cancelInvitationUrl={candidate.cancel_url}
                      email={candidate.email}
                      showCancelInvitation={
                        inviteStatus === 'pending'
                      }
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </TableBase>
      {candidates === null && <GenericListSkeleton items={3} />}
      {candidates?.results.length === 0 && (
        <Box py={12}>
          <Typography align="center" variant="h5">
            There are no candidates
          </Typography>
        </Box>
      )}
      {candidates?.results.length !== 0 && (
        <Box py={6}>
          <TablePaginationBoxStyles
            count={candidates?.count}
            rowsPerPage={params.pageSize}
            page={params.page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </>
  );
};

export default InvitesBase;

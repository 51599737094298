import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import FieldLabel from 'business/organizer/library/components/question-create-update/common/FieldLabel';
import MyInput from 'common/components/form/MyInput';
import { scheduleInterviewFormValidations } from '../utils/helpers';
import DatePickerField from 'common/components/form/DatePickerField';
import TimePickerField from 'common/components/form/TimePickerField';
import TimeZoneField from 'common/components/form/TimeZoneField';
import InterviewFormActions from 'business/organizer/interviews/components/common/InterviewFormActions';
import { getTimezone } from 'common/utils/dayjsUtils';
import { makeStyles } from '@material-ui/core';

const ScheduleInterviewForm: React.FC<Interviews.IInterviewFormProps> =
  ({ onClose, handleSubmit }) => {
    const classes = useStyles();

    const initialState = {
      email: '',
      title: '',
      interview_date: '',
      interview_time: '',
      interview_tz: getTimezone() || '',
    };

    return (
      <Formik
        initialValues={initialState}
        validationSchema={scheduleInterviewFormValidations}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Box
              p={8}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gridRowGap={12}
            >
              <FieldLabel title="Candidate Email" isRequired={true}>
                <MyInput
                  name="email"
                  placeholder="Email"
                  aria-label="email"
                />
              </FieldLabel>
              <FieldLabel title="Interview Title" isRequired={true}>
                <MyInput
                  name="title"
                  placeholder="Interview Title"
                  aria-label="Interview Title"
                />
              </FieldLabel>
              <Grid container spacing={4} className={classes.root}>
                <Grid item xs={6}>
                  <FieldLabel title="Date" isRequired={true}>
                    <DatePickerField
                      name="interview_date"
                      date={values.interview_date}
                      datePickerProps={{
                        format: 'YYYY-MM-DD',
                        variant: 'dialog',
                        inputVariant: 'outlined',
                        disablePast: true,
                        placeholder: 'Add Interview Date',
                      }}
                    />
                  </FieldLabel>
                </Grid>
                <Grid item xs={6}>
                  <FieldLabel title="Time" isRequired={true}>
                    <TimePickerField
                      name="interview_time"
                      time={values.interview_time}
                      timePickerProps={{
                        format: 'hh:m a',
                        variant: 'dialog',
                        inputVariant: 'outlined',
                        placeholder: 'Add Interview Time',
                      }}
                    />
                  </FieldLabel>
                </Grid>
              </Grid>
              <FieldLabel
                title="Interview Timezone"
                isRequired={true}
              >
                <TimeZoneField
                  name="interview_tz"
                  placeholder="Select Timezone"
                />
              </FieldLabel>
            </Box>
            <InterviewFormActions
              spinnerButtonName="Schedule Interview"
              onClose={onClose}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  };

export default ScheduleInterviewForm;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1.5),
    },
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';

const InviteStat: React.FC<IInviteStatProps> = ({
  noOfInvites,
  label,
  icon,
}) => {
  return (
    <Box>
      <Typography variant="h4">{noOfInvites}</Typography>
      <Box
        display="flex"
        justify-content="center"
        alignItems="center"
        gridColumnGap={4}
        fontSize={16}
        pt={2}
      >
        {icon}
        <Typography>{label}</Typography>
      </Box>
    </Box>
  );
};

export default InviteStat;

import React, { useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import ErrorBoundary from 'common/components/error-boundry/ErrorBoundary';
import DialogProvider from 'common/components/dialog-provider/DialogProvider';
import AlertProvider from 'common/components/alert-provider/AlertProvider';
import { CSSProperties } from '@material-ui/styles';

const alertStyles: CSSProperties = {
  maxWidth: 560,
  width: 350,
  position: 'fixed',
  right: 20,
  top: 0,
  zIndex: 9999,
};

const BaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const notistackRef = useRef<any>();
  const onDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <Router>
      <ErrorBoundary>
        <AlertProvider styles={alertStyles}>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <IconButton onClick={onDismiss(key)} size="small">
                <CloseIcon fontSize="medium" />
              </IconButton>
            )}
          >
            <DialogProvider>{children}</DialogProvider>
          </SnackbarProvider>
        </AlertProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default BaseProvider;

import React from 'react';

import Header from 'business/organizer/library/components/question-preview/common/Header';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useQuestionDrawer from 'business/organizer/library/contexts/question-drawer/useQuestionDrawer';
import PersistSidebar from 'common/components/utility-components/PersistSidebar';

const PreviewQuestionHeader: React.FC<IPreviewQuestionHeaderProps> = ({
  questionItem,
  questionStats,
  children,
}) => {
  const { questionDrawer, setQuestionDrawer } = useQuestionDrawer();
  const { toggleDrawer } = useToggleDrawer();

  const { url } = useCurrentUrl();

  const handleQuestionDrawer = (questionDrawer: boolean) => {
    setQuestionDrawer(questionDrawer);
  };

  return (
    <>
      <PersistSidebar
        endingPath={`/edit/${questionItem.id}`}
        sidebar={questionDrawer}
        onSidebar={handleQuestionDrawer}
        sidebarName="edit"
      />
      <Header
        name={questionItem.question.name}
        questionStats={questionStats}
        type={questionItem.type}
        id={questionItem.id}
        toggleDrawerOpen={toggleDrawer(
          true,
          setQuestionDrawer,
          'edit',
          `${url}/edit/${questionItem.id}`
        )}
        canEdit={questionItem.can_edit}
        isComplete={questionItem.is_complete}
        tags={questionItem.tags}
      />
      <CustomDrawer
        drawer={questionDrawer}
        setDrawer={setQuestionDrawer}
        drawerName="edit"
      >
        {children}
      </CustomDrawer>
    </>
  );
};

export default PreviewQuestionHeader;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import FreeTextPage from 'business/organizer/library/pages/question-create-update/free-text/FreeTextPage';
import FreeTextUpdatePage from 'business/organizer/library/pages/question-create-update/free-text/FreeTextUpdatePage';

const FreeTextRoutes = () => {
  const { url } = useCurrentUrl();
  return (
    <Switch>
      <Route path={`${url}/edit/:freeTextId`}>
        <FreeTextUpdatePage />
      </Route>
      <Route path={`${url}/create`}>
        <FreeTextPage drawerName="create" />
      </Route>
      <Redirect from={url} to={`${url}/create`} />
    </Switch>
  );
};

export default FreeTextRoutes;

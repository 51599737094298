import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import TablePaginationBoxStyles from 'business/organizer/assessment/components/candidate-progress/common/TablePaginationBoxStyles';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import useProctoringDetails from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useProctoringDetails';
import { getDayjsDate } from 'common/utils/dayjsUtils';

const Timeline = () => {
  const { candidateDetails } = useCandidateProgressDetail();

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    data,
    error,
    status,
    params,
  }: IUseProctoringDetailsProps<ICandidateTestTimelineProps> = useProctoringDetails(
    candidateDetails?.timeline_url as string
  );

  if (data && !data.results.length && error === null) {
    return (
      <Box px={4} py={12}>
        <Typography variant="h6" align="center">
          No event has been logged during the test.
        </Typography>
      </Box>
    );
  }

  return (
    <Box py={6}>
      <GenericResponseHandler status={status} error={error}>
        <Container maxWidth="xl">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Timestamp</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Event name</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {data?.results.map((timeline) => (
                    <TableRow key={timeline.id}>
                      <TableCell>
                        <Typography variant="body1">
                          {getDayjsDate({
                            date: timeline.created_at,
                            format: "DD MMM[']YY HH[:]mm:ss",
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {timeline.display_name}{' '}
                          {timeline.data.question_number
                            ? timeline.data.question_number
                            : null}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <Box pt={6}>
            {
              <TablePaginationBoxStyles
                rowsPerPageOptions={[2, 10, 25, 50, 100]}
                count={data?.count}
                rowsPerPage={params.page_size}
                page={params.page}
                onPageChange={handlePageChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            }
          </Box>
        </Container>
      </GenericResponseHandler>
    </Box>
  );
};

export default Timeline;

import { useMemo } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import GenericNavbar from 'business/organizer/common/components/GenericNavbar';
import Questions from 'business/organizer/assessment/pages/assessment-tests/test-detail/tabs/Questions';
import Settings from 'business/organizer/assessment/pages/assessment-tests/test-detail/tabs/Settings';
import CandidateProgress from 'business/organizer/assessment/pages/assessment-tests/test-detail/tabs/CandidateProgress';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useTestQuestions from 'business/organizer/assessment/contexts/test-questions/useTestQuestions';
import TestName from 'business/organizer/assessment/components/assessment-tests/test-detail/common/TestName';
import PublishTest from 'business/organizer/assessment/components/assessment-tests/test-detail/common/PublishTest';
import LibraryProvider from 'business/organizer/library/contexts/library/LibraryProvider';
import QuestionsProvider from 'business/organizer/common/components/QuestionsProvider';
import QuestionDrawerProvider from 'business/organizer/library/contexts/question-drawer/QuestionDrawerProvider';
import InviteRoutes from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/InviteRoutes';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';

const TestDetailPage = () => {
  const classes = useStyles();

  const { testStatus } = useParams<{
    testStatus: string;
  }>();
  const history = useHistory();
  const { pathname } = useLocation();

  const { testDetail } = useTestDetail();

  const { url, isHubsUrl } = useCurrentUrl();
  const { testQuestionsCount } = useTestQuestions();
  const { isViewer } = useHasPermission();

  const tabs = useMemo(
    () => [
      {
        id: 0,
        label: 'Questions',
        route: `${url}/questions`,
        count: !!testQuestionsCount
          ? testQuestionsCount
          : testDetail?.total_questions_count || 0,
        badge: true,
      },
      {
        id: 1,
        label: 'Settings',
        route: `${url}/settings`,
        count: 0,
        badge: false,
      },
      {
        id: 2,
        label: 'Invite',
        route: `${url}/invite`,
        count: 0,
        badge: false,
      },
      {
        id: 3,
        label: isHubsUrl ? 'Results' : 'Candidate Progress',
        route: `${url}/${
          isHubsUrl ? 'results' : 'candidate-progress'
        }`,
        count: 0,
        badge: false,
      },
    ],
    [
      isHubsUrl,
      testDetail?.total_questions_count,
      testQuestionsCount,
      url,
    ]
  );

  return (
    <>
      <GenerateBreadcrumb
        data={[
          {
            label: `${testStatus
              .charAt(0)
              .toUpperCase()}${testStatus.slice(1)}`,
            route: `${isHubsUrl ? '/hubs' : ''}/tests/${testStatus}`,
          },
        ]}
      />
      <Container
        maxWidth={false}
        className={classes.container__header}
      >
        <TestName />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <GenericNavbar tabs={tabs} />
          </Box>
          {testDetail?.status === 'active' ? (
            <>
              {!pathname.startsWith(`${url}/invite`) ? (
                <HasPermission
                  isViewer={isViewer}
                  tooltipProps={{ placement: 'top', arrow: true }}
                >
                  <Button
                    startIcon={<PersonIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(`${url}/invite`)}
                    disabled={isViewer}
                  >
                    Invite
                  </Button>
                </HasPermission>
              ) : null}
            </>
          ) : isViewer ? (
            <HasPermission
              isViewer={isViewer}
              tooltipProps={{ placement: 'top', arrow: true }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={isViewer}
              >
                Publish Test
              </Button>
            </HasPermission>
          ) : (
            <PublishTest />
          )}
        </Box>
      </Container>

      <Switch>
        <Route path={tabs[0].route}>
          <LibraryProvider>
            <QuestionsProvider>
              <QuestionDrawerProvider>
                <Questions />
              </QuestionDrawerProvider>
            </QuestionsProvider>
          </LibraryProvider>
        </Route>
        <Route path={tabs[1].route}>
          <Settings />
        </Route>
        <Route path={tabs[2].route}>
          <InviteRoutes />
        </Route>
        <Route path={tabs[3].route}>
          <CandidateProgress />
        </Route>
        <Redirect from={url} to={tabs[0].route} />
      </Switch>
    </>
  );
};

export default TestDetailPage;

const useStyles = makeStyles((theme) => ({
  container__header: {
    background: '#f1f6f9',
    boxShadow: '0px 4px 8px rgba(221, 230, 237, 0.5)',
    position: 'sticky',
    top: 64,
    zIndex: 9,
  },
}));

import React, { useState } from 'react';

import ChallengeUpdatePageContext from 'business/organizer/library/contexts/challenge-question/contexts/ChallengeUpdatePageContext';

const ChallengeUpdatePageProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [challenge, setChallenge] = useState<any | null>(null);

  return (
    <ChallengeUpdatePageContext.Provider
      value={{ challenge, setChallenge }}
    >
      {children}
    </ChallengeUpdatePageContext.Provider>
  );
};

export default ChallengeUpdatePageProvider;

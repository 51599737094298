import React from 'react';

import SettingsContext from './SettingsContext';

export default function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(
      'useSettings must be used within a SettingsProvider.'
    );
  }
  return context;
}

export const LANGUAGE_DEFAULT_CONTENT: { [key: string]: string } = {
  python: 'print("hello world!")',
  cpp: `#include <iostream>

int main() {
    std::cout << "Hello World!";
    return 0;
}`,
  javascript: `console.log("hello world")`,
  java: `class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello, World!"); 
    }
}`,
  c: `#include <stdio.h>

int main(void) {
	// your code goes here
	return 0;
}
`,
  'c#': `using System;

public class Test
{
	public static void Main()
	{
		// your code goes here
	}
}
`,
  ada: `With Ada.Text_IO; Use Ada.Text_IO;  
With Ada.Integer_Text_IO; Use Ada.Integer_Text_IO;

procedure Program is
begin
  -- your code goes here
end Program;
`,
  php: `<?php

// your code goes here
`,
  ruby: `# cook your code here
`,
  go: `package main
import "fmt"

func main(){
	// your code goes here
}
`,
  nodejs: `process.stdin.resume();
process.stdin.setEncoding('utf8');

// your code goes here

`,
  rust: `// This is a comment, and is ignored by the compiler
// You can test this code by clicking the "Run" button over there ->
// or if you prefer to use your keyboard, you can use the "Ctrl + Enter" shortcut

// This code is editable, feel free to hack it!
// You can always return to the original code by clicking the "Reset" button ->

// This is the main function
fn main() {
    // Statements here are executed when the compiled binary is called

    // Print text to the console
    println!("Hello World!");
}
`,
  clojure: `(ns clojure.examples.hello
  (:gen-class))
(defn hello-world []
  (println "Hello World"))
(hello-world)`,
  typescript: `console.log('hello world!');`,
  awk: `awk 'BEGIN {print "Hello world"}'
  `,
};

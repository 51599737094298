import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { ORGANIZATION_API_URL } from 'business/organizer/settings/utils/constants';
import HttpError from 'common/components/http-error/HttpError';
import OrganizationDetailsUpdate from 'business/organizer/settings/components/organization/OrganizationDetailsUpdate';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';

const organizerService = new OrganizerService();

const OrganizationInfo = () => {
  const [organizationDetails, setOrganizationDetails] = useState<
    any | null
  >(null);
  const [organizationDetailsError, setOrganizationDetailsError] =
    useState<AxiosError | null>(null);

  useEffect(() => {
    let mounted = true;
    organizerService
      .authGet(ORGANIZATION_API_URL)
      .then((response) => {
        if (mounted) {
          setOrganizationDetails(response?.data[0]);
        }
      })
      .catch((error) => setOrganizationDetailsError(error));

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      {organizationDetailsError !== null ? (
        <HttpError axiosError={organizationDetailsError} />
      ) : organizationDetails === null ? (
        <GenericListSkeleton
          items={4}
          boxProps={{ height: '16px', paddingTop: '12px' }}
        />
      ) : (
        <OrganizationDetailsUpdate
          organizationDetails={organizationDetails}
        />
      )}
    </>
  );
};

export default OrganizationInfo;

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import MySelect from 'common/components/form/MySelect';

const SelectField: React.FC<ISelectFieldProps> = ({
  name,
  placeholder,
  type = 'string',
  items,
}) => (
  <FormControl variant="outlined">
    <MySelect name={name} placeholder={placeholder} type={type}>
      {items.map(({ label, value }, index) => (
        <MenuItem key={index} value={value}>
          {label}
        </MenuItem>
      ))}
    </MySelect>
  </FormControl>
);

export default SelectField;

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Question name should be at least 1 characters long')
    .required('Question name is required'),
  description: Yup.string(),
  has_multiple_correct_answers: Yup.boolean(),
  choices: Yup.array()
    .of(
      Yup.object().shape({
        content: Yup.string().required(
          'Choice description is required'
        ),
        is_correct_choice: Yup.boolean(),
      })
    )
    .min(2, 'Please add at least 2 choices.')
    .required('Choices are required'),
  time_duration: Yup.number()
    .required('Time is required.')
    .typeError('Time value must be number'),
  score: Yup.number()
    .required('Score is required.')
    .typeError('Score must be number'),
  difficulty: Yup.string()
    .oneOf(
      ['easy', 'medium', 'hard'],
      'This is not the valid choice.'
    )
    .required('Difficulty level is required.'),
  tags: Yup.array()
    .min(1, 'Please add at least 1 tag.')
    .required('Tags are required'),
});

import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box/Box';

import CustomPlan from 'business/organizer/assessment/components/billing/common/CustomPlan';
import BillingPlanItem from 'business/organizer/assessment/components/billing/common/BillingPlanItem';
import useUserContext from 'common/components/get-user/useUserContext';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';

export const getDefaultBoxProps = (props?: BoxProps): BoxProps => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridColumnGap: '30px',
    ...props,
  };
};

const HubsBillingCards: React.FC<IHubsBillingCardsProps> = ({
  customCta,
  getParentBoxProps,
  hubsInstituteStaterPlanCta,
  hubsOrganizationStaterPlanCta,
  monthly,
}) => {
  const parentBoxProps = getParentBoxProps
    ? getParentBoxProps
    : getDefaultBoxProps();

  const { user } = useUserContext();

  return (
    <Box py={6}>
      <Box {...parentBoxProps}>
        {!!user && user?.org_type === 'edu_institution' ? (
          <>
            <BillingPlanItem
              perInviteDiscountedPrice={monthly ? 2 : 1.6}
              perInvitePrice={2}
              plan="Starter Plan"
              planDescription="For Assessing"
              features={[
                'Upto 50 student learning accounts',
                'Unlimited access to assessments',
                'Upto 3 admin accounts',
                'Email and chat support',
              ]}
              monthly={monthly}
              showDiscount={!monthly}
              discount="20% off"
            >
              {hubsInstituteStaterPlanCta}
            </BillingPlanItem>

            <CustomPlan
              planTitle="Custom Plan"
              planSubtitle="Based on quantity and billing timeline"
              planSubtitleDescription="Connect with us and create a plan that suits your requirement"
              planDescription={[
                'Custom no. of student learning accounts',
                'Unlimited access to assessments',
                'Custom no. of admin accounts',
                'Dedicated support',
                'AI-Powered Proctoring',
                // 'Real-world project assessments',
              ]}
            >
              {customCta}
            </CustomPlan>
          </>
        ) : user?.org_type === 'edu_organization' ? (
          <>
            <BillingPlanItem
              perInviteDiscountedPrice={monthly ? 20 : 16}
              perInvitePrice={20}
              plan="Starter Plan"
              planDescription="For Upskilling"
              features={[
                'Upto 5 employee learning accounts',
                'Unlimited access to assessments',
                'Email and chat support',
              ]}
              monthly={monthly}
              showDiscount={!monthly}
              discount="20% off"
            >
              {hubsOrganizationStaterPlanCta}
            </BillingPlanItem>

            <CustomPlan
              planTitle="Custom Plan"
              planSubtitle="Based on quantity and billing timeline"
              planSubtitleDescription="Connect with us and create a plan that suits your requirement"
              planDescription={[
                'Custom no. of invites',
                'Premium Questions Library',
                // 'Interview scheduler (coming soon)',
                'AI Powered Proctoring',
                'ATS Integration',
                'API access',
                'Dedicated Customer Support',
              ]}
            >
              {customCta}
            </CustomPlan>
          </>
        ) : (
          <GenericListSkeleton items={3} />
        )}
      </Box>
    </Box>
  );
};

export default HubsBillingCards;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import CustomMuiLink from 'common/components/custom-mui-link/CustomMuiLink';

const FooterInfoItem: React.FC<IFooterInfoItemProps> = ({
  label,
  route,
}) => (
  <CustomMuiLink href={route}>
    <Typography variant="body2" color="textSecondary">
      {label}
    </Typography>
  </CustomMuiLink>
);

export default FooterInfoItem;

import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';

import ReminderBox from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/styles/ReminderBox';
import RichTextField from 'common/components/form/RichTextField';

const Reminder = () => {
  const {
    handleBlur,
    values,
  } = useFormikContext<IInviteFormInitialState>();

  return (
    <>
      {values.expireDate ? (
        <ReminderBox
          title="Invite with an expiry date"
          subtitle="Remind candidates # number of days before invite expires"
        />
      ) : (
        <ReminderBox
          title="Invite without an expiry date"
          subtitle="Remind candidates in # number of days"
        />
      )}
      <Box pt={4} pb={2}>
        <RichTextField
          name="reminderBodyContent"
          onBlur={handleBlur}
        />
      </Box>
    </>
  );
};

export default Reminder;

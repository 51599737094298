import React, { useState, useEffect } from 'react';

import {
  ADD_DIFFICULTY_LEVEL,
  REMOVE_DIFFICULTY_LEVEL,
} from 'business/organizer/library/contexts/library/libraryActionTypes';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import FilterMenu from 'business/organizer/library/components/filter/FilterMenu';
import ListOfFilters from 'business/organizer/library/components/filter/ListOfFilters';
import { difficultyLevelData } from 'business/organizer/library/components/filter/utils';

const FilterByDifficultyLevel = () => {
  const { state, dispatch } = useLibrary();

  const [
    difficultyLevel,
    setDifficultyLevel,
  ] = useState<IFilterItemProps>({
    easy: false,
    medium: false,
    hard: false,
  });

  useEffect(() => {
    setDifficultyLevel({
      easy: state.globalParams.difficulty.includes('easy')
        ? true
        : false,
      medium: state.globalParams.difficulty.includes('medium')
        ? true
        : false,
      hard: state.globalParams.difficulty.includes('hard')
        ? true
        : false,
    });
  }, [state.globalParams.difficulty, state.globalParams.type]);

  const handleDifficultyLevel = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked, value } = event.target;
    setDifficultyLevel({
      ...difficultyLevel,
      [name]: checked,
    });
    dispatch({
      type: checked ? ADD_DIFFICULTY_LEVEL : REMOVE_DIFFICULTY_LEVEL,
      payload: value,
    });
  };

  return (
    <FilterMenu label="Difficulty Level">
      <ListOfFilters
        data={difficultyLevelData}
        state={difficultyLevel}
        handleChange={handleDifficultyLevel}
      />
    </FilterMenu>
  );
};

export default FilterByDifficultyLevel;

import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

const CustomStepper: React.FC<ICustomStepper> = ({
  currentStep,
  completedSteps,
  stepsInfo,
  ...otherProps
}) => {
  const getSteps = () => {
    return stepsInfo;
  };
  const steps = getSteps();

  const isStepComplete = (currentStep: number) => {
    return completedSteps.has(currentStep);
  };
  return (
    <Stepper {...otherProps} activeStep={currentStep} style={{background:"#F8F9FE"}}>
      {steps.map((label: string, index: number) => {
        const stepProps: { completedSteps?: boolean } = {};
        return (
          <Step key={label} {...stepProps}>
            <StepButton completed={isStepComplete(index)}>
              <Typography variant="subtitle1">{label}</Typography>
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CustomStepper;

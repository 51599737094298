import * as Yup from 'yup';

export const validationSchema = Yup.object().shape(
  {
    expireDate: Yup.date().nullable().notRequired(),
    bulk_invite_csv: Yup.mixed().when('toField', {
      is: function (val: string[]) {
        return !val || val.length === 0;
      },
      then: Yup.mixed()
        .test(
          'is-valid',
          'Please select a valid csv file',
          (value) => {
            return value instanceof File;
          }
        )
        .required(
          'Please add csv for bulk invites or emails for individual'
        ),
      otherwise: Yup.mixed(),
    }),
    toField: Yup.array().when('bulk_invite_csv', {
      is: function (csv: File) {
        return !csv;
      },
      then: Yup.array()
        .min(1, 'Please add the valid email address.')
        .required('Please add at least one email address.'),
      otherwise: Yup.array(),
    }),
    rejectedMails: Yup.array().max(
      0,
      'Please add valid email address.'
    ),
    email: Yup.string().email('Please add the valid email address.'),
    subject: Yup.string()
      .typeError('Please add the text as subject.')
      .required('Subject is required.'),
    body: Yup.string()
      .typeError('This field is required.')
      .required('Body is required.'),
    showReminderSettings: Yup.boolean(),
    noOfDaysForReminder: Yup.number()
      .notRequired()
      .nullable()
      .when('showReminderSettings', {
        is: function (showReminderSettings: boolean) {
          return showReminderSettings;
        },
        then: Yup.number()
          .typeError('Please add number.')
          .required('Number of days is required'),
      }),
    reminderBodyContent: Yup.string()
      .notRequired()
      .when('showReminderSettings', {
        is: function (showReminderSettings: boolean) {
          return showReminderSettings;
        },
        then: Yup.string()
          .typeError('Please add text.')
          .required('Reminder body is required.'),
      }),
  },
  [
    ['toField', 'bulk_invite_csv'],
    ['showReminderSettings', 'noOfDaysForReminder'],
    ['showReminderSettings', 'reminderBodyContent'],
  ]
);

export const checkEmail = async (email: string) => {
  let schema = Yup.array()
    .min(1, 'Please add the valid email address.')
    .of(Yup.string().email());
  let isValidEmail = await schema.isValid([email]);

  return isValidEmail;
};

import ProctoringService from 'business/candidate/proctoring/services/proctoringService';

const proctoringService = new ProctoringService();

export const proctoringEventListener = async (
  url: string,
  data: {
    name: DPHIEVENT;
    data: object;
  }
) => {
  return await proctoringService.postEvent(url, data);
};

export const pasteEventHandler = (
  url: string,
  event: Event,
  allowPaste: boolean = false
) => {
  proctoringEventListener(url, { name: 'tried_pasting', data: {} });
  if (!allowPaste) {
    event.preventDefault();
    return false;
  }
};

export const hasImageCaptureProperty = 'ImageCapture' in window;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';

import Dashboard from 'business/organizer/assessment/pages/Dashboard';
import SettingsPage from 'business/organizer/settings/pages/SettingsPage';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import AssessmentPage from 'business/organizer/assessment/AssessmentPage';
import useBreadcrumb from 'business/organizer/common/context/breadcrumb/useBreadcrumb';
import GenericBreadcrumb from 'common/components/generic-breadcrumb/GenericBreadcrumb';
import Navigation from 'business/organizer/common/components/navigation/Navigation';
import BillingPage from 'business/organizer/assessment/pages/BillingPage';
import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
import TestsProvider from 'business/organizer/assessment/contexts/assessment-tests/TestsProvider';
import useUserContext from 'common/components/get-user/useUserContext';
import CustomModal from 'common/components/custom-modal/CustomModal';
import SignUpSecondStep from 'business/pre-subscription/auth/components/signup/SignUpSecondStep';
import InterviewsPage from 'business/organizer/interviews/pages/InterviewsPage';
import HubsToAssessmentModal from 'business/pre-subscription/auth/components/common/HubsToAssessment';
import AssessmentToHubsModal from 'business/pre-subscription/auth/components/common/AssessmentToHubs';

const OrganizerPage = () => {
  const classes = useStyles();
  const { url, isHubsUrl } = useCurrentUrl();
  const { breadcrumbData } = useBreadcrumb();

  const { isFreeTrialEnded } = useSubscription();
  const { user } = useUserContext();
  const canProceeed = () => {
    if (isHubsUrl) {
      return user?.subscription_list?.includes('hubs');
    } else {
      return user?.subscription_list?.includes('hiring');
    }
  };

  return (
    <Box display="flex" className={classes.box__organizer_page}>
      {!!user && !user?.first_name && (
        <CustomModal width="70%" open={true}>
          <SignUpSecondStep />
        </CustomModal>
      )}
      {!!user &&
        !!user?.first_name &&
        (isHubsUrl && !user?.subscription_list?.includes('hubs') ? (
          <CustomModal width="70%" open={true}>
            <AssessmentToHubsModal />
          </CustomModal>
        ) : (
          !isHubsUrl &&
          !user?.subscription_list?.includes('hiring') && (
            <CustomModal width="70%" open={true}>
              <HubsToAssessmentModal />
            </CustomModal>
          )
        ))}
      <Navigation />
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        alignItems="flex-start"
        mt={16}
      >
        {isFreeTrialEnded ? (
          <>
            <Route path={`${url}/billing`}>
              <BillingPage />
            </Route>
            <Redirect from={url} to={`${url}/billing`} />
          </>
        ) : (
          <>
            {canProceeed() && (
              <>
                <Container
                  maxWidth={false}
                  className={classes.container__breadcrumb}
                >
                  <GenericBreadcrumb
                    breadcrumbData={breadcrumbData}
                  />
                </Container>
                <TestsProvider>
                  <Switch>
                    <Route
                      exact
                      path={`${url}/dashboard`}
                      component={Dashboard}
                    />
                    {!isHubsUrl && (
                      <Route path={`${url}/interviews`}>
                        <InterviewsPage />
                      </Route>
                    )}
                    <Route
                      path={`${url}/settings`}
                      component={SettingsPage}
                    />
                    <Route path={`${url}/billing`}>
                      <BillingPage />
                    </Route>
                    <Route
                      path={`${url}/`}
                      component={AssessmentPage}
                    />
                  </Switch>
                </TestsProvider>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default OrganizerPage;

const useStyles = makeStyles((theme) => ({
  box__organizer_page: {
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: theme.spacing(3.5),
    },
  },
  container__breadcrumb: {
    background: '#f1f6f9',
  },
}));

import { useState, useEffect } from 'react';

type useIsUpdateReturnProps = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

const useIsUpdate = (
  booleanValue: boolean
): useIsUpdateReturnProps => {
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (booleanValue) {
      setIsUpdate(true);
    }
  }, [booleanValue]);

  return [isUpdate, setIsUpdate];
};

export default useIsUpdate;

import React from 'react';
import Box from '@material-ui/core/Box';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';

import TestInfoItem from 'business/organizer/assessment/components/common/TestInfoItem';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const TestInfo: React.FC<ITestInfoProps> = ({
  total_score,
  assessment_duration,
  start_datetime,
  end_datetime,
}) => {
  return (
    <Box display="flex" gridColumnGap={16}>
      <TestInfoItem
        tooltipTitle="Total Score"
        Icon={TrackChangesOutlinedIcon}
      >
        {!!total_score ? total_score : 'Not Specified'}
      </TestInfoItem>
      <TestInfoItem
        tooltipTitle="Total duration"
        Icon={QueryBuilderIcon}
      >
        {!!assessment_duration
          ? `${assessment_duration}`
          : 'Not specified'}
      </TestInfoItem>
      <TestInfoItem
        tooltipTitle="Start date - End date"
        Icon={EventAvailableIcon}
      >
        {!start_datetime && !end_datetime
          ? 'Not Specified'
          : `${
              start_datetime
                ? dayjs(start_datetime)
                    .tz(dayjs.tz.guess())
                    .format('MMM DD, YY, HH:mm a')
                : 'No start date specified'
            } -
        ${
          end_datetime
            ? dayjs(end_datetime)
                .tz(dayjs.tz.guess())
                .format('MMM DD, YY, HH:mm a')
            : 'No end date specified'
        }`}
      </TestInfoItem>
    </Box>
  );
};

export default TestInfo;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import useTests from 'business/organizer/assessment/contexts/assessment-tests/useTests';
import RoleItemStyles from 'business/organizer/assessment/components/assessment-tests/test-list/choose-role/RoleItemStyles';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const CustomRole = () => {
  const classes = useStyles();
  const {
    handleCustomRole,
    customRole,
    selectedRole,
    onCustomRole,
  } = useTests();

  const {isHubsUrl} = useCurrentUrl();

  return (
    <Box>
      <Box pb={2}>
        <RoleItemStyles
          handleClick={handleCustomRole}
          styleCondition={customRole}
        >
          <Typography variant="h6" align="center" color="inherit">
            Other
          </Typography>
        </RoleItemStyles>
      </Box>
      {customRole && (
        <Box className={classes.box_textfield}>
          <TextField
            color="primary"
            id="custom-role"
            label={isHubsUrl ? "Custom Test" : 'Custom Role'}
            variant="outlined"
            value={selectedRole}
            onChange={(event) => onCustomRole(event.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomRole;

const useStyles = makeStyles((theme) => ({
  box_textfield: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

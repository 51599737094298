import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import {
  DropEvent,
  FileRejection,
  FileWithPath,
} from 'react-dropzone';

const useOnDropFile = ({ name }: { name: string }) => {
  const { setFieldValue } = useFormikContext();

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFieldValue(name, acceptedFiles[0]);
    },
    [name, setFieldValue]
  );

  return { onDrop };
};

export default useOnDropFile;

import React from 'react';
import { useFormikContext } from 'formik';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core';
import { checkEmail } from './utils/validationSchema';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';

const InviteToField = () => {
  const classes = useStyles();
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext<IInviteFormInitialState>();
  const { isViewer } = useHasPermission();

  const addedEmails = [
    ...(values.toField || []),
    ...(values.rejectedMails || []),
  ];

  const handleKeyPress = async (
    event:
      | React.KeyboardEvent
      | React.MouseEvent
      | React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      event &&
      (((event.type === 'keydown' || event.type === 'keyup') &&
        ((event as React.KeyboardEvent).key === ' ' ||
          (event as React.KeyboardEvent).key === 'Enter' ||
          (event as React.KeyboardEvent).key === ',')) ||
        event.type === 'blur')
    ) {
      event.preventDefault();

      const { email } = values;

      if (!email.length) {
        return;
      }

      if (touched.email && errors.email) {
        setFieldValue('rejectedMails', [
          ...(values.rejectedMails || []),
          email,
        ]);
      } else {
        const isValidEmail = await checkEmail(email);

        if (isValidEmail) {
          setFieldValue('toField', [
            ...(values.toField || []),
            email,
          ]);
        } else {
          setFieldValue('rejectedMails', [
            ...(values.rejectedMails || []),
            email,
          ]);
        }
      }
      setFieldValue('email', '');
    }
  };

  const handleDelete = (chip: string) => {
    if (values.rejectedMails.includes(chip)) {
      setFieldValue(
        'rejectedMails',
        values.rejectedMails.filter((field) => field !== chip)
      );
    } else {
      setFieldValue(
        'toField',
        values.toField.filter((field) => field !== chip)
      );
    }
  };

  return (
    <Box
      bgcolor={!!values.bulk_invite_csv ? '#D8D8D8' : '#fff'}
      px={2}
      boxSizing="border-box"
      display="flex"
      flexWrap="wrap"
      flexGrow={1}
      borderRadius={4}
      border="0.5px solid rgb(192,192,192)"
      className={classes.input_email_field}
    >
      {!addedEmails.length && (
        <Box py={2}>
          <Typography
            component="span"
            color="textSecondary"
            variant="subtitle1"
          >
            To:
          </Typography>
        </Box>
      )}
      {!!addedEmails.length && (
        <Box display="flex" flexWrap="wrap" gridGap={4} py={2}>
          {addedEmails.map((chip, idx) => (
            <Chip
              key={idx}
              label={chip}
              onDelete={() => handleDelete(chip)}
              color={
                values.rejectedMails.includes(chip)
                  ? 'secondary'
                  : 'default'
              }
            />
          ))}
        </Box>
      )}
      <Box
        minWidth={!!addedEmails.length ? '80px' : '90%'}
        display="flex"
      >
        <HasPermission
          isViewer={isViewer}
          tooltipProps={{ placement: 'top', arrow: true }}
        >
          <Input
            id="email"
            name="email"
            type="email"
            className={classes.input_element}
            onKeyDown={handleKeyPress}
            disabled={isViewer || !!values.bulk_invite_csv}
            disableUnderline
            fullWidth
            onChange={handleChange}
            onBlur={(event) => {
              handleBlur(event);
              handleKeyPress(event);
            }}
            value={values.email}
          />
        </HasPermission>
      </Box>
    </Box>
  );
};

export default InviteToField;

const useStyles = makeStyles((theme) => ({
  input_element: {
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  input_email_field: {
    boxSizing: 'border-box',
    '& .MuiChip-colorSecondary': {
      backgroundColor: '#e53e3e',
    },
    width: '100%',
    '&:hover': {
      border: '1px solid  #000',
    },
    '&:focus-within': {
      boxSizing: 'border-box',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

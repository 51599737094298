import React, { useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import {
  DropEvent,
  FileRejection,
  FileWithPath,
} from 'react-dropzone';

import MyFileField from 'common/components/form/MyFileField';

const LogoUpload: React.FC<IFileUploadProps> = ({
  initialStateLogo,
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const [imagePreviewUrl, setImagePreviewUrl] = useState<any | null>(
    initialStateLogo
  );

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFieldValue('logo', acceptedFiles[0]);
      let reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    [setFieldValue]
  );

  const clearSelectedLogo = () => {
    setImagePreviewUrl(null);
    setFieldValue('logo', '');
  };

  const dropzoneOptions = {
    accept: '.png, .svg, .jpg, .jpeg',
    maxFiles: 1,
    onDrop,
    multiple: false,
    maxSize: 10485760, //10MB
  };

  return (
    <Box
      display="flex"
      flex-direction="column"
      justifyContent="space-between"
    >
      <MyFileField
        dropzoneOptions={dropzoneOptions}
        name="logo"
        buttonText="Choose file"
        icon={false}
      />

      {imagePreviewUrl && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Avatar
            alt="Logo"
            src={imagePreviewUrl}
            className={classes.avatar__large}
          />
          <Button
            variant="text"
            color="default"
            size="small"
            onClick={clearSelectedLogo}
          >
            Remove
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LogoUpload;

const useStyles = makeStyles((theme) => ({
  input__upload: {
    display: 'none',
  },
  avatar__large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    border: '1px solid #ddd',
    '& .MuiAvatar-img': {
      height: 'auto',
    },
  },
}));

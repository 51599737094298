import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const InlineTextButton: React.FC<IInlineTextButtonProps> = ({
  ...props
}) => {
  const classes = useStyles();
  return (
    <Typography
      component="span"
      className={classes.typography__inline_button}
      {...props}
    />
  );
};

export default InlineTextButton;

const useStyles = makeStyles((theme) => ({
  typography__inline_button: {
    cursor: 'pointer',
    color: theme.palette.info.main,
  },
}));

/**
 * Default options to enqueueAlert()
 */
const DEFAULTS: AlertOptions = {
  autohideDuration: 2000,
  preventDuplicate: false,
  persist: false,
};

export default DEFAULTS;

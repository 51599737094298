import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CustomMuiLink from 'common/components/custom-mui-link/CustomMuiLink';

const FooterSection: React.FC<IFooterSectionProps> = ({
  footerData,
  label,
  RouteLink,
}) => {
  return (
    <>
      <Box px={4}>
        <Typography variant="h6">{label}</Typography>
      </Box>
      <List>
        {footerData.map(
          ({ id, route, label, isReactLink, isNewTab }) => (
            <ListItem key={id}>
              {isReactLink ? (
                <RouteLink
                  to={route}
                  target={isNewTab ? '_blank' : ''}
                >
                  <ListItemText>{label}</ListItemText>
                </RouteLink>
              ) : (
                <CustomMuiLink
                  href={route}
                  target={isNewTab ? '_blank' : ''}
                >
                  <ListItemText>{label}</ListItemText>
                </CustomMuiLink>
              )}
            </ListItem>
          )
        )}
      </List>
    </>
  );
};

export default FooterSection;

import { useState } from 'react';
import { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MySelect from 'common/components/form/MySelect';
import MenuItem from '@material-ui/core/MenuItem';

import AuthPageButton from 'business/pre-subscription/auth/components/common/AuthPageButton';
import MyInput from 'common/components/form/MyInput';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import {
  getValidationSchema,
  getErrorMessage,
} from 'business/pre-subscription/auth/components/common/helpers';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import { SIGNUP_API_URL } from 'business/pre-subscription/auth/utils/constants';
import {
  HUBS_APP_TYPE_PARAM,
  SKILLSPACE_APP_TYPE_PARAM,
} from 'business/organizer/common/utils/constants';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import { HUBS_ORGANIZATION_TYPE } from 'business/organizer/settings/utils/constants';

import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import AuthBlockFooter from 'business/pre-subscription/auth/components/common/AuthBlockFooter';

const businessAuthService = new BusinessAuthService();
const SignUpForm: React.FC<ISignUpFormProps> = ({
  hasInvitation,
  inviteData,
  invitationId,
}) => {
  const classes = useStyles();

  const [error, setError] = useState<IBusinessFormErrorProps | null>(
    null
  );

  const { isHubsUrl } = useCurrentUrl();
  const singUpApiUrl = `${SIGNUP_API_URL}&${
    isHubsUrl ? HUBS_APP_TYPE_PARAM : SKILLSPACE_APP_TYPE_PARAM
  }`;

  const initialState: SignUpFormFirstStep = {
    email: hasInvitation ? (inviteData?.email as string) : '',
    password: '',
    company_name: hasInvitation
      ? (inviteData?.organization_name as string)
      : '',
    organization_type: inviteData?.organization_type,
  };
  const validationSchema =
    getValidationSchema<SignUpFormFirstStep>(initialState);

  const handleSubmit: AuthSubmissionHandler = async (
    values: SignUpFormFirstStep
  ) => {
    try {
      const { email, password, company_name, organization_type } =
        values;
      const data = {
        email: email,
        password1: password,
        organization_name: company_name,
        organization_type: organization_type,
      };

      const response = await businessAuthService.authPost(
        hasInvitation
          ? `${singUpApiUrl}&invitation=${invitationId}`
          : singUpApiUrl,
        data
      );
      if (response) {
        window.location.href = 'https://skillspace.ai/signup';
      }
    } catch (error) {
      setError(
        (error as AxiosError).response
          ?.data as IBusinessFormErrorProps
      );
    }
  };

  const resetResponse = () => {
    setError(null);
  };

  const handleOnDisagree = () => {
    resetResponse();
  };

  const isError = error !== null;

  const errorMessage = getErrorMessage(error);

  return (
    <>
      <NotificationDialog
        open={isError}
        variant="error"
        disagreeText="Try Again"
        onDisagree={handleOnDisagree}
        content={errorMessage}
      />
      <Box className={classes.box_root}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <FieldStyles>
                <MyInput
                  placeholder="Work Email Address"
                  name="email"
                  type="email"
                  muiInputProps={{
                    InputProps: {
                      readOnly: hasInvitation,
                      className: clsx({
                        [classes.input__disabled_cursor]:
                          hasInvitation,
                      }),
                    },
                  }}
                />
              </FieldStyles>
              <FieldStyles>
                <MyInput
                  name="company_name"
                  placeholder="Company Name"
                  type="text"
                  muiInputProps={{
                    InputProps: {
                      readOnly: hasInvitation,
                      className: clsx({
                        [classes.input__disabled_cursor]:
                          hasInvitation,
                      }),
                    },
                  }}
                />
              </FieldStyles>
              <FieldStyles>
                <MyInput
                  placeholder="Password"
                  name="password"
                  type="password"
                />
              </FieldStyles>
              {isHubsUrl ? (
                <FieldStyles>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.form_control}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      margin="dense"
                      shrink={false}
                    >
                      {!values.organization_type
                        ? 'Organization Type'
                        : ''}
                    </InputLabel>

                    <MySelect
                      name="organization_type"
                      placeholder="Organization Type"
                      type="text"
                    >
                      {HUBS_ORGANIZATION_TYPE.map(
                        (hubsTitle: IJobTitleProps) => (
                          <MenuItem
                            key={hubsTitle.id}
                            value={hubsTitle.value}
                          >
                            {hubsTitle.label}
                          </MenuItem>
                        )
                      )}
                    </MySelect>
                  </FormControl>
                </FieldStyles>
              ) : (
                <></>
              )}
              <AuthPageButton>Sign Up</AuthPageButton>
              <Typography variant="body2" align="center">
                Don't worry! <strong>No credit card details</strong>{' '}
                are required.
              </Typography>
              <AuthBlockFooter />
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default SignUpForm;

const useStyles = makeStyles((theme) => ({
  box_root: {
    '& .MuiTypography-body2': {
      color: '#666',
    },
    '& .MuiButton-root': {
      width: '100%',
    },
  },
  box__button: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(4, 0),
    '& .MuiButton-root': {
      width: '100%',
    },
  },
  fieldStyles__MyCheckbox: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiIconButton-colorSecondary': {
      color: '#233D4D',
    },
  },
  form_control: {
    '& .MuiFormLabel-root': {
      color: '#c0c0c0',
    },
  },
  input__disabled_cursor: {
    '& .MuiInputBase-input': {
      cursor: 'no-drop',
    },
  },
}));

import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Container from '@material-ui/core/Container';
import Input from '@material-ui/core/Input';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core';

import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';
import ExpiryDatePicker from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/ExpiryDatePicker';
import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import { validationSchema } from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/utils/validationSchema';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import {
  subjectContent,
  bodyContent,
  reminderBodyContent,
} from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/utils/constants';
import { addDays } from 'common/utils/addDays';
import { getErrorMessage } from 'common/utils/errors';
import ConfirmationDialog from 'common/components/confirmation-dialog/ConfirmationDialog';
import RichTextField from 'common/components/form/RichTextField';
import ConfirmationItem from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/ConfirmationItem';
import InviteField from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/InviteField';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import SpinnerButton from 'common/components/spinner-button/SpinnerButton';
import ReminderSwitch from 'business/organizer/assessment/components/assessment-tests/test-detail/invites/ReminderSwitch';

const organizerService = new OrganizerService();

const Invite: React.FC<IInviteCandidateProps> = ({
  invitationResponse,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useCurrentUrl();
  const { testDetail } = useTestDetail();
  const [inviteResponse, setInviteResponse] = useState<any | null>(
    null
  );
  const [error, setError] = useState<IGetErrorMessageProps | null>(
    null
  );
  const { isViewer } = useHasPermission();

  const initialState: IInviteFormInitialState = {
    expireDate: invitationResponse?.valid_till || addDays(7),
    toField: invitationResponse?.to_email
      ? [invitationResponse?.to_email]
      : [],
    bulk_invite_csv: null,
    rejectedMails: [],
    email: '',
    subject: subjectContent,
    body: bodyContent,
    showReminderSettings: false,
    noOfDaysForReminder: null,
    reminderBodyContent: reminderBodyContent,
  };

  const handleSubmit = async (data: IInviteFormInitialState) => {
    try {
      const {
        expireDate,
        toField,
        subject,
        body,
        bulk_invite_csv,
        showReminderSettings,
        noOfDaysForReminder,
        reminderBodyContent,
      } = data;

      let inviteData: IInviteDataProps = {
        email_subject: subject,
        email_content: body,
      };

      // check if expiry date is present
      if (!!expireDate) {
        inviteData = {
          ...inviteData,
          ...(expireDate !== '' && {
            valid_till:
              expireDate !== null
                ? new Date(expireDate).toISOString()
                : '',
          }),
        };
      }

      if (bulk_invite_csv && !invitationResponse) {
        inviteData = {
          ...inviteData,
          bulk_invitation_csv: bulk_invite_csv,
        };
      } else {
        inviteData = {
          ...inviteData,
          //condionally add  expireDate to the object based on it's value
          to_emails: toField,
        };
      }

      // send reminder data
      if (showReminderSettings) {
        inviteData.reminder_settings = {
          number_of_days_for_reminder: noOfDaysForReminder as number,
          reminder_data: {
            email_subject: subject,
            email_content: reminderBodyContent,
          },
        };
      }
      const response = bulk_invite_csv
        ? await organizerService.bulkInviteCandidates(
            `${testDetail?.invite_url.split('?')[0]}bulk-upload/?${
              testDetail?.invite_url.split('?')[1]
            }`,
            inviteData
          )
        : await organizerService.inviteCandidates(
            testDetail?.invite_url as string,
            inviteData
          );

      if (response) {
        setInviteResponse(response?.data);
      }
    } catch (error) {
      const isApiError = (e: any): e is AxiosError => {
        return e;
      };
      if (isApiError(error)) {
        setError(error?.response?.data);
      }
    }
  };

  const closeSuccessNotification = () => {
    setInviteResponse(null);
    if (!!invitationResponse) {
      history.goBack();
    }
  };

  const errorMessage = getErrorMessage(error);

  return (
    <>
      <GenerateBreadcrumb data={[{ label: 'Invite', route: url }]} />
      <Container maxWidth={false} style={{ marginTop: '8px' }}>
        {testDetail === null ? (
          <Box py={8}>
            <GenericListSkeleton items={4} />
          </Box>
        ) : (
          <>
            {testDetail?.status !== 'active' ? (
              <Box py={12}>
                <Typography variant="h5" align="center">
                  Publish the test to invite the candidates
                </Typography>
              </Box>
            ) : (
              <>
                <ConfirmationDialog
                  open={error !== null || inviteResponse !== null}
                  variant={error !== null ? 'error' : 'info'}
                  disagreeText={
                    !!invitationResponse ? 'Close & Go Back' : 'Close'
                  }
                  onDisagree={
                    error !== null
                      ? () => setError(null)
                      : () => closeSuccessNotification()
                  }
                >
                  <>
                    {error !== null ? (
                      errorMessage
                    ) : inviteResponse !== null ? (
                      <>
                        {!invitationResponse ? (
                          <Box pr={8} pb={3}>
                            <ConfirmationItem
                              invitations={
                                inviteResponse?.new_invitations
                              }
                            >
                              <CheckIcon
                                color="primary"
                                fontSize="small"
                              />
                              <Typography>
                                {' '}
                                {
                                  inviteResponse?.new_invitations
                                } new{' '}
                                {inviteResponse?.new_invitations === 1
                                  ? 'invitation is'
                                  : 'invitations are'}{' '}
                                sent.
                              </Typography>
                            </ConfirmationItem>
                            <ConfirmationItem
                              invitations={
                                inviteResponse?.resent_invites
                              }
                            >
                              <RefreshIcon
                                color="secondary"
                                fontSize="small"
                              />
                              <Typography>
                                {' '}
                                {inviteResponse?.resent_invites}{' '}
                                {inviteResponse?.resent_invites === 1
                                  ? 're-invitation is'
                                  : 're-invitations are'}{' '}
                                sent.
                              </Typography>
                            </ConfirmationItem>
                            <ConfirmationItem
                              invitations={
                                inviteResponse?.failed_invites
                              }
                            >
                              <ClearIcon
                                color="error"
                                fontSize="small"
                              />
                              <Typography>
                                {' '}
                                {inviteResponse?.failed_invites}{' '}
                                {inviteResponse?.failed_invites === 1
                                  ? 'invitation is '
                                  : 'invitations are'}
                                failed, Please try after 1 hour.
                              </Typography>
                            </ConfirmationItem>
                          </Box>
                        ) : (
                          <Box pb={4}>
                            <Typography>
                              {inviteResponse?.resent_invites ||
                              inviteResponse?.new_invitations
                                ? `Reinvitation is successfully sent to ${invitationResponse?.to_email}`
                                : 'Reinvitation is failed, Please try after 1 hour.'}
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                </ConfirmationDialog>
                <Box className={classes.container__invite_container}>
                  <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      getFieldProps,
                      handleBlur,
                      errors,
                      touched,
                      isSubmitting,
                    }) => (
                      <Form>
                        <Box
                          className={classes.box__expiry_datepicker}
                        >
                          <ExpiryDatePicker />
                        </Box>

                        {/* show errors */}
                        <Box>
                          {!!Object.keys(errors).find((error) =>
                            Object.keys(touched).includes(error)
                          ) && (
                            <FormHelperText error={true}>
                              {errors.subject ||
                                errors.toField ||
                                errors.bulk_invite_csv ||
                                errors.body ||
                                errors.rejectedMails}
                            </FormHelperText>
                          )}
                        </Box>
                        <Box
                          className={
                            classes.box__invite_form_container
                          }
                        >
                          <InviteField
                            invitationResponse={invitationResponse}
                          />
                          <Box py={2}>
                            <Box
                              px={2}
                              boxSizing="border-box"
                              display="flex"
                              flexWrap="wrap"
                              flexGrow={1}
                              borderRadius={4}
                              border="0.5px solid rgb(192,192,192)"
                              className={classes.input_email_field}
                            >
                              <Box py={2}>
                                <Typography
                                  component="span"
                                  color="textSecondary"
                                  variant="subtitle1"
                                >
                                  Subject:
                                </Typography>
                              </Box>
                              <Box display="flex" minWidth="90%">
                                <HasPermission
                                  isViewer={isViewer}
                                  tooltipProps={{
                                    placement: 'top',
                                    arrow: true,
                                  }}
                                >
                                  <Input
                                    type="text"
                                    className={classes.input_element}
                                    disableUnderline
                                    fullWidth
                                    disabled={true}
                                    {...getFieldProps('subject')}
                                  />
                                </HasPermission>
                              </Box>
                            </Box>
                          </Box>
                          <HasPermission
                            isViewer={isViewer}
                            tooltipProps={{
                              placement: 'left-start',
                              arrow: true,
                            }}
                          >
                            <Box
                              py={2}
                              disabled = {true}
                              style={{
                                pointerEvents: 'none'
                              }}
                            >
                              <RichTextField
                                name="body"
                                onBlur={handleBlur}
                              />
                            </Box>
                          </HasPermission>
                          {!invitationResponse && <ReminderSwitch />}
                        </Box>
                        <Box
                          py={4}
                          display="flex"
                          justifyContent="flex-end"
                          maxWidth="800px"
                        >
                          <HasPermission
                            isViewer={isViewer}
                            tooltipProps={{
                              placement: 'top',
                              arrow: true,
                            }}
                          >
                            <SpinnerButton
                              type="submit"
                              disabled={isViewer || isSubmitting}
                              showDisableSpinner={!isViewer}
                            >
                              Send
                            </SpinnerButton>
                          </HasPermission>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Invite;

const useStyles = makeStyles((theme) => ({
  container__invite_container: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    background: '#FFFFFF',
    padding: theme.spacing(6),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(0),
  },
  box__expiry_datepicker: {
    width: theme.spacing(100),
    // marginTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  box__invite_form_container: {
    width: theme.spacing(200),
  },
  input_element: {
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  input_email_field: {
    boxSizing: 'border-box',
    '& .MuiChip-colorSecondary': {
      backgroundColor: '#e53e3e',
    },
    width: '100%',
    '&:hover': {
      border: '1px solid  #000',
    },
    '&:focus-within': {
      boxSizing: 'border-box',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

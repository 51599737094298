import * as Yup from 'yup';

export const validationSchema = [
  Yup.object({
    name: Yup.string()
      .min(5, 'Must be 5 characters or more.')
      .required('This field is required.')
      .typeError('Text type is required'),
    description: Yup.string()
      .min(20, 'Must be 20 characters or more.')
      .required('This field is required.')
      .typeError('Text type is required'),
    tags: Yup.array()
      .min(1, 'Please add at least 1 tag.')
      .required('This field is required'),
    languages: Yup.array()
      .min(1, 'Please add at least 1 language.')
      .required('This field is required'),
    time_duration: Yup.number()
      .required('This field is required.')
      .typeError('Value must be numbers'),
    difficulty: Yup.string()
      .oneOf(
        ['easy', 'medium', 'hard'],
        'This is not the valid choice.'
      )
      .required('This field is required.'),
  }),
];

export const testcasesFormValidation = (updateForm?: boolean) => {
  let errors: any = {
    score: Yup.number()
      .required('This field is required.')
      .typeError('Value must be numbers'),
    time_limit: Yup.number()
      .positive('Value must be positive.')
      .max(15, 'Must be 15 seconds or less.')
      .required('This field is required.')
      .typeError('Value must be a number'),
  };

  !updateForm &&
    (errors.input_file = Yup.mixed().required(
      'This field is required.'
    ));
  !updateForm &&
    (errors.output_file = Yup.mixed().required(
      'This field is required.'
    ));

  const validation = Yup.object({
    ...errors,
  });
  return validation;
};

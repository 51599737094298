import React from 'react';
import {
  Field,
  useFormikContext,
  FieldProps,
  ErrorMessage,
} from 'formik';
import Box from '@material-ui/core/Box';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core';

import RichTextField from 'common/components/form/RichTextField';
import theme from 'business/common/theme/theme';
import ErrorHelperText from 'common/components/form/ErrorHelperText';
import { hasInvalidAnswers } from 'business/organizer/library/components/question-create-update/mcq/utils/helpers';

const ChoicesItem: React.FC<IMcqChoiceProps> = ({
  arrayHelpers,
  content,
  is_correct_choice,
  index,
  id,
  onSetDeletedChoices,
}) => {
  const classes = useStyles();
  const {
    values,
    setFieldValue,
  } = useFormikContext<IMcqInitialStateProps>();

  const { has_multiple_correct_answers, choices } = values;

  const handleDelete = () => {
    if (id) {
      onSetDeletedChoices(id);
    }
    arrayHelpers.remove(index);
  };

  const handleSelectAnswer = () => {
    const isCorrect = !is_correct_choice;
    if (
      has_multiple_correct_answers ||
      hasInvalidAnswers(choices, has_multiple_correct_answers)
    ) {
      setFieldValue(`choices.${index}.is_correct_choice`, isCorrect);
    } else {
      values.choices.map((_, idx) => {
        if (idx === index) {
          return setFieldValue(
            `choices.${idx}.is_correct_choice`,
            isCorrect
          );
        } else {
          return setFieldValue(
            `choices.${idx}.is_correct_choice`,
            false
          );
        }
      });
    }
  };

  return (
    <>
      <Box className={classes.box__choice_item}>
        <Box
          bgcolor={
            is_correct_choice
              ? theme.palette.primary.main
              : theme.palette.text.secondary
          }
          className={classes.box__choice_icon}
          onClick={handleSelectAnswer}
        >
          <Field
            name={`choices.${index}.is_correct_choice`}
            render={({ field }: FieldProps) => (
              <Box
                {...field}
                display="flex"
                alignItems="center"
                color="#fff"
              >
                {is_correct_choice ? (
                  <CheckCircleIcon color="inherit" />
                ) : (
                  <FiberManualRecordIcon color="inherit" />
                )}
              </Box>
            )}
          />
        </Box>
        <Box width="100%">
          <RichTextField
            name={`choices.${index}.content`}
            showError={false}
          />
        </Box>
        <Box alignSelf="center">
          <DeleteIcon
            className={classes.icon__cursor}
            onClick={handleDelete}
          />
        </Box>
      </Box>
      <ErrorMessage name={`choices.${index}.content`}>
        {(msg) => <ErrorHelperText>{msg}</ErrorHelperText>}
      </ErrorMessage>
    </>
  );
};

export default ChoicesItem;

const useStyles = makeStyles((theme) => ({
  box__choice_item: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2, 0),
  },
  box__choice_icon: {
    display: 'flex',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    cursor: 'pointer',
  },
  icon__cursor: {
    cursor: 'pointer',
  },
}));

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import QuestionFormItemSkeleton from 'business/organizer/common/components/skeletons/QuestionFormItemSkeleton';

const QuestionFormSkeleton = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Divider />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box width="50%" py={4}>
              <QuestionFormItemSkeleton />
            </Box>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <Box py={4} width="100%">
        <Grid
          container
          spacing={5}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <QuestionFormItemSkeleton />
          </Grid>
          <Grid item xs={12}>
            <QuestionFormItemSkeleton />
          </Grid>
          <Grid item xs={4}>
            <QuestionFormItemSkeleton />
          </Grid>
          <Grid item xs={4}>
            <QuestionFormItemSkeleton />
          </Grid>
          <Grid item xs={4}>
            <QuestionFormItemSkeleton />
          </Grid>
          <Grid item xs={12}>
            <QuestionFormItemSkeleton />
          </Grid>
        </Grid>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        py={4}
      >
        <Box width="25%">
          <QuestionFormItemSkeleton />
        </Box>
      </Box>
    </>
  );
};

export default QuestionFormSkeleton;

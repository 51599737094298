import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core';

import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import QuestionCreateUpdatePage from 'business/organizer/library/pages/question-create-update/QuestionCreateUpdatePage';
import useQuestionDrawer from 'business/organizer/library/contexts/question-drawer/useQuestionDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import useDropdown from 'common/components/use-dropdown/useDropdown';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useAlert from 'common/components/alert-provider/useAlert';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import { closeMenu } from 'business/organizer/common/utils/closeMenu';
import { hasMessageProperty } from 'common/utils/errors';
import {
  HUBS_APP_TYPE_PARAM,
  SKILLSPACE_APP_TYPE_PARAM,
} from 'business/organizer/common/utils/constants';

const organizerService = new OrganizerService();

const QuestionItemActions: React.FC<IQuestionItemActionsProps> = ({
  is_shared,
  can_edit,
  id,
  type,
  testQuestionUrl,
  onSetIsHidden,
  isHidden,
  onSetHiddenResponse,
  onSetDeleteResponse,
  testStatus,
  questionUrl,
  cloneUrl,
  onCloneQuestion,
  questionName,
  handleSnackbar,
}) => {
  const { enqueueAlert } = useAlert();

  const classes = useStyles();
  const { url, isHubsUrl } = useCurrentUrl();
  const history = useHistory();
  const { anchorEl, handleClick, handleClose } = useDropdown();

  const { questionDrawer, setQuestionDrawer } = useQuestionDrawer();
  const { toggleDrawer } = useToggleDrawer();

  const [showDialog, setShowDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const openQuestionDrawer = (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    toggleDrawer(
      true,
      setQuestionDrawer,
      'edit',
      `${url}/questions/${type}/edit/${id}`
    )(event);
    handleClose();
  };

  const handleDeleteQuestion = async (
    testQuestionUrl: string,
    onSetDeleteResponse: () => void
  ) => {
    try {
      const response = await organizerService.delete(testQuestionUrl);
      if (response) {
        onSetDeleteResponse();
      }
      setDeleteDialog(false);
      enqueueAlert(
        'Question is successfully removed from the test.',
        {
          alertProps: { severity: 'success' },
        }
      );
    } catch (error) {
      setDeleteDialog(false);
      enqueueAlert(
        `${
          hasMessageProperty(error)
            ? error?.message
            : '' || 'Error while deleting question, please try again.'
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  const handleQuestionVisibility = async (
    isHidden: boolean,
    testQuestionUrl: string,
    onSetIsHidden: (value: boolean) => void,
    onSetHiddenResponse: (data: any) => void
  ) => {
    try {
      testQuestionUrl = `${testQuestionUrl}?${
        isHubsUrl ? HUBS_APP_TYPE_PARAM : SKILLSPACE_APP_TYPE_PARAM
      }`;
      const response = await organizerService.patch(testQuestionUrl, {
        is_hidden: isHidden,
      });
      onSetHiddenResponse(response?.data);
      onSetIsHidden(isHidden);

      setShowDialog(false);
      enqueueAlert(
        `${
          isHidden
            ? 'This question will not be visible in the test now'
            : 'This question is added to the test again'
        }`,
        {
          alertProps: { severity: 'success' },
        }
      );
    } catch (error) {
      setShowDialog(false);
      enqueueAlert(
        `${
          hasMessageProperty(error)
            ? error?.message
            : '' || 'Error while hiding question, please try again.'
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  const handleClone = async (
    cloneUrl: string,
    onCloneQuestion: () => void,
    questionName: string
  ) => {
    if (handleSnackbar) {
      handleSnackbar(true);
    }
    try {
      const response = await organizerService.post(cloneUrl, {});

      if (response) {
        onCloneQuestion();
        if (is_shared) {
          history.push('./my');
        }
        if (handleSnackbar) {
          handleSnackbar(false);
        }
        enqueueAlert(`${questionName} is cloned successfully.`, {
          alertProps: { severity: 'success' },
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      handleClose();
      enqueueAlert(
        `${
          hasMessageProperty(error)
            ? error?.message
            : '' ||
              'Error while cloning the question, please try again.'
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <>
      {testStatus && testQuestionUrl && onSetDeleteResponse && (
        <NotificationDialog
          open={deleteDialog}
          variant="info"
          disagreeText="No"
          onDisagree={() => setDeleteDialog(false)}
          agreeText="Yes"
          onAgree={() =>
            handleDeleteQuestion(testQuestionUrl, onSetDeleteResponse)
          }
          content="Are you sure you want to remove this question from the test?"
        />
      )}
      {testQuestionUrl && onSetIsHidden && onSetHiddenResponse ? (
        <NotificationDialog
          open={showDialog}
          variant="info"
          disagreeText="No"
          onDisagree={() => setShowDialog(false)}
          agreeText="Yes"
          onAgree={() =>
            handleQuestionVisibility(
              isHidden ? false : true,
              testQuestionUrl,
              onSetIsHidden,
              onSetHiddenResponse
            )
          }
          content={
            isHidden
              ? 'Do you want to add this question again into the test?'
              : 'Are you sure you want to remove this question from the test? ?'
          }
        />
      ) : (
        ''
      )}
      <IconButton
        onClick={handleClick}
        className={classes.icon_button__question_action}
      >
        <MoreVertIcon />
      </IconButton>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
      >
        {can_edit && (
          <MenuItem onClick={openQuestionDrawer}>Edit</MenuItem>
        )}
        {testQuestionUrl &&
          (testStatus && testStatus === 'draft' ? (
            <MenuItem
              onClick={() =>
                closeMenu(setDeleteDialog, true, handleClose)
              }
            >
              Delete
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() =>
                closeMenu(setShowDialog, true, handleClose)
              }
            >
              {isHidden ? 'Show' : 'Hide'}
            </MenuItem>
          ))}
        {!testQuestionUrl &&
          questionUrl &&
          onCloneQuestion &&
          questionName && (
            <MenuItem
              onClick={() =>
                !!cloneUrl &&
                handleClone(cloneUrl, onCloneQuestion, questionName)
              }
            >
              Clone
            </MenuItem>
          )}
      </DropdownMenu>
      <CustomDrawer
        drawer={questionDrawer}
        setDrawer={setQuestionDrawer}
        drawerName="edit"
      >
        <Route path={`${url}/questions`}>
          <QuestionCreateUpdatePage />
        </Route>
      </CustomDrawer>
    </>
  );
};

export default QuestionItemActions;

const useStyles = makeStyles((theme) => ({
  icon_button__question_action: {
    padding: '9px',
  },
}));

const dropdownStyles = makeStyles((theme) => ({
  box_root: {
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(68, 146, 76, 0.04)',
    },
    // enable pointer events on the popover menu
    '& .MuiPopover-paper': {
      pointerEvents: 'initial',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 1.5px 3px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}));

import BaseService from 'common/services/baseService';

export class BusinessAuthService extends BaseService {
  currentUrl = window.location.pathname;

  async authGet(url: string, params?: object) {
    return await this.get(url, {
      app_type: this.appTypeParam,
      ...(params || {}),
    });
  }

  async authPost(url: string, params?: object) {
    return await this.post(url, {
      app_type: this.appTypeParam,
      ...(params || {}),
    });
  }
}

import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import RichTextRenderer from 'common/components/rich-text-renderer/RichTextRenderer';

const Body: React.FC<IQuestionPreviewBodyProps> = ({ content }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <RichTextRenderer richTextHtml={content} />
    </Box>
  );
};

export default Body;

const useStyles = makeStyles((theme) => ({
  root: {
    wordBreak: 'break-word',
    padding: theme.spacing(6),
    '& *': {
      maxWidth: '100%',
    },
  },
}));

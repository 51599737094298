import React from 'react';

import useQuestionSubmission from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useQuestionSubmission';
import QuestionSolution from 'business/organizer/assessment/components/candidate-progress/candidate-detail/common/QuestionSolution';
import TestcasesTable from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/TestcasesTable';
import CompilationInfo from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CompilationInfo';
import CandidateCode from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CandidateCode';
import { sqlLanguage } from 'business/candidate/assessments/components/session/helpers';

const SqlSolution: React.FC<ISqlQuestionSolutionProps> = ({
  submissionUrl,
}) => {
  const {
    submission,
    responseError,
  }: IQuestionSubmissionProps<ISqlQuestionSubmissionProps> =
    useQuestionSubmission(submissionUrl);

  return (
    <QuestionSolution
      submission={submission}
      responseError={responseError}
    >
      <>
        <TestcasesTable
          testCases={submission?.submission?.sub_questions}
        />
        <CompilationInfo
          streamData={submission?.submission?.stream_data}
        />
        {!!submission && (
          <CandidateCode
            language={sqlLanguage}
            code={submission?.submission?.sub_questions[0]?.code}
            menuOption={sqlLanguage}
          />
        )}
      </>
    </QuestionSolution>
  );
};

export default SqlSolution;

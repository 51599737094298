import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const QuestionStatsItem: React.FC<IQuestionStatsItemProps> = ({
    IconImage,
    content
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <IconImage fontSize="small" color="secondary" />
            <Typography variant="body1" color="textSecondary">{content}</Typography>
        </Box>
    )
}

export default QuestionStatsItem;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        gridColumnGap: theme.spacing(1),
    }
}));
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import { CLEAR_FILTERS } from 'business/organizer/library/contexts/library/libraryActionTypes';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import FilterMenu from 'business/organizer/library/components/filter/FilterMenu';
import ActiveFilterIcon from 'business/organizer/library/components/filter/ActiveFilterIcon';
import FilterIcon from 'business/organizer/library/components/filter/FilterIcon';
import FilterByTags from 'business/organizer/library/components/filter/FilterByTags';
import FilterByType from 'business/organizer/library/components/filter/FilterByType';
import FilterByDifficultyLevel from 'business/organizer/library/components/filter/FilterByDifficultyLevel';
import FilterByLanguages from 'business/organizer/library/components/filter/FilterByLanguages';

const Filters = () => {
  const classes = useStyles();
  const { state, dispatch } = useLibrary();

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Box display="flex" alignItems="center" gridColumnGap={4}>
          <Typography variant="h6">Filters</Typography>
          {!!state.globalParams.type.length ||
          !!state.globalParams.difficulty.length ||
          !!state.globalParams.tags.length ||
          !!state.globalParams.programmingLanguages.length ? (
            <ActiveFilterIcon />
          ) : (
            <FilterIcon />
          )}
        </Box>
        <Button
          color="primary"
          size="small"
          onClick={handleClearFilters}
        >
          Clear All
        </Button>
      </Box>
      <FilterMenu label="Tags">
        <FilterByTags tagName="tags" />
      </FilterMenu>
      <Divider variant="middle" className={classes.divider} />
      <FilterByType />
      <Divider variant="middle" className={classes.divider} />
      <FilterByDifficultyLevel />
      <Divider variant="middle" className={classes.divider} />
      <FilterMenu label="Programming Languages">
        <FilterByLanguages tagName="programmingLanguages" />
      </FilterMenu>
    </div>
  );
};

export default Filters;

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(3, 0),
  },
}));

import React, { useState } from 'react';
import ProjectContext from 'business/organizer/library/contexts/project-question/ProjectContext';

const ProjectProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [
    projectQuestionResponse,
    setProjectQuestionResponse,
  ] = useState<IProjectQuestionResponseProps | null>(null);

  return (
    <ProjectContext.Provider
      value={{ projectQuestionResponse, setProjectQuestionResponse }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useQuestionUpdateData from 'business/organizer/library/custom-hooks/useQuestionUpdateData';
import useCodingQuestionData from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestionData';
import CodingPage from 'business/organizer/library/pages/question-create-update/coding/CodingPage';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import QuestionErrorHandler from 'business/organizer/library/components/question-create-update/common/QuestionErrorHandler';
import QuestionFormSkeleton from 'business/organizer/common/components/skeletons/QuestionFormSkeleton';

const CodingUpdatePage = () => {
  const { codingId } = useParams<{ codingId: string }>();
  const {
    questionItem,
    responseError,
    status,
  } = useQuestionUpdateData(codingId, MY_LIBRARY_QUESTION_API_URL);

  const { setCoding } = useCodingQuestionData();

  useEffect(() => {
    if (questionItem) {
      setCoding(questionItem);
    }
    return () => {
      setCoding(null);
    };
  }, [setCoding, questionItem]);

  return (
    <GenericResponseHandler
      status={status}
      error={responseError}
      errorPlaceholder={
        <QuestionErrorHandler error={responseError} />
      }
      skeleton={<QuestionFormSkeleton />}
    >
      <CodingPage coding={questionItem} drawerName="edit" />
    </GenericResponseHandler>
  );
};

export default CodingUpdatePage;

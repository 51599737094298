import * as Yup from 'yup';

export const validationSchema = Yup.object().shape(
  {
    name: Yup.string()
      .min(4, 'Must be 4 characters or more')
      .required('This field is required')
      .typeError('Text type is required'),
    end_datetime: Yup.date().when(['start_datetime'], {
      is: (startDate: Date) => !!startDate,
      then: Yup.date()
        .min(
          Yup.ref('start_datetime'),
          'End date should be greater than the start date.'
        )
        .nullable(),
      otherwise: Yup.date().nullable(),
    }),
    start_datetime: Yup.date().when('end_datetime', {
      is: (endDate: Date) => !!endDate,
      then: Yup.date().typeError(
        "You can't add the end date without start date."
      ),
      otherwise: Yup.date().nullable(),
    }),
  },
  [['start_datetime', 'end_datetime']]
);

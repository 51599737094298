import React from 'react';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core';

// import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';
import getDrawerWidth from 'business/organizer/library/custom-hooks/getDrawerWidth';
// import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const CustomDrawer: React.FC<ICustomDrawerProps> = ({
  drawerName,
  drawer,
  setDrawer,
  children,
  drawerWidth,
}) => {
  const { drawerList } = useDrawer();
  const classes = useStyles({
    drawerWidth:
      typeof drawerWidth !== 'undefined'
        ? drawerWidth
        : getDrawerWidth(drawerList, drawerName),
  });
  // const { url } = useCurrentUrl();
  // const { toggleDrawer } = useToggleDrawer();

  return (
    <Drawer
      anchor="right"
      open={drawer}
      // onClose={toggleDrawer(false, setDrawer, drawerName, url)}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        // This is needed to allow the focus in CKEditor dropdowns to persist
        // https://stackoverflow.com/questions/42566003/ckeditor-drop-down-issue-on-bootstrap-modal-open-and-close-immediately-on-click
        disableEnforceFocus: true,
      }}
    >
      <Box className={classes.box__drawer_children}>{children}</Box>
    </Drawer>
  );
};

export default CustomDrawer;

const useStyles = makeStyles<Theme, { drawerWidth: number }>(
  (theme) => ({
    drawer: {
      width: ({ drawerWidth }) => `${drawerWidth}%`,
    },
    drawerPaper: {
      width: ({ drawerWidth }) => `${drawerWidth}%`,
      overflow: 'unset',
    },
    box__drawer_children: {
      overflowY: 'auto',
    },
  })
);

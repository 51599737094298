import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

const NavigationButton: React.FC<INavigationButton> = ({
  onClick,
  Icon,
  disabled,
  title,
  top,
}) => {
  return (
    <Box
      position="absolute"
      left="-41px"
      top={top}
      bgcolor="#FFFFFF"
      borderRadius="50%"
    >
      <Tooltip title={title} placement="bottom" arrow>
        <span>
          <IconButton
            size="small"
            onClick={onClick}
            disabled={disabled}
          >
            <Icon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default NavigationButton;

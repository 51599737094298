import React, { memo } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

import MyTypography from 'common/components/my-typography/MyTypography';

const Faqs: React.FC<IFaqsProps> = ({ faqs, responsiveVariants }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <Box py={{ xs: 5, md: 10 }}>
        <Box pb={{ xs: 3, md: 6 }} textAlign="center">
          <MyTypography
            content="Frequently Asked Questions"
            responsiveVariants={responsiveVariants}
          />
        </Box>
        <Grid container>
          {faqs.map(({ id, question, answer }) => (
            <Grid item xs={12} key={id}>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <MyTypography
                    content={question}
                    responsiveVariants={{ downXl: 'h6' }}
                  />
                </AccordionSummary>
                <AccordionDetails
                  className={classes.accordionDetails}
                >
                  <MyTypography
                    content={answer}
                    responsiveVariants={{ downXl: 'body1' }}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default memo(Faqs);

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    minHeight: '0 !important',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    paddingBottom: theme.spacing(6),
  },
  accordion: {
    boxShadow: 'none',
    borderBottom: '1px solid #f8f8f8',
  },
}));

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import InputWithLabel from 'common/components/form/InputWithLabel';
import MySelect from 'common/components/form/MySelect';

const items = [
  { label: 'Root Mean Squared Error', value: 1 },
  { label: 'Root Mean Squared Log Error', value: 2 },
  { label: 'F1 Score', value: 3 },
  { label: 'Accuracy Score', value: 4 },
  { label: 'Weighted F1 Score', value: 5 },
  { label: 'Mean Squared Error', value: 6 },
];

const EvaluationMetric = () => {
  return (
    <InputWithLabel
      title="Evaluation Metric"
      downMD="subtitle2"
      upMD="subtitle2"
      isRequired={true}
      tooltip
      tooltipTitle="By using the evaluation metric chosen below, model predictions of candidate will be compared with the true target variable predictions."
    >
      <FormControl variant="outlined">
        <MySelect name="evaluationMetric" type="text">
          {items.map(({ label, value }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </MySelect>
      </FormControl>
    </InputWithLabel>
  );
};

export default EvaluationMetric;

import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';

import FormError from 'business/organizer/library/components/common/FormError';
import SaveButton from 'business/organizer/library/components/common/SaveButton';
import {
  hasInvalidAnswers,
  hasInvalidChoices,
} from 'business/organizer/library/components/question-create-update/mcq/utils/helpers';

const McqFormActions = ({
  isUpdate,
  testId,
}: {
  isUpdate: React.MutableRefObject<boolean>;
  testId: number;
}) => {
  const {
    isSubmitting,
    values,
  } = useFormikContext<IMcqInitialStateProps>();
  const { has_multiple_correct_answers, choices } = values;

  const canNotSubmit =
    hasInvalidChoices(choices) ||
    hasInvalidAnswers(choices, has_multiple_correct_answers);

  return (
    <Box display="flex" alignItems="center" gridColumnGap={12}>
      <FormError />
      <Box position="relative">
        <Box display="flex">
          <SaveButton
            buttonText={
              !!testId
                ? `${
                    isUpdate.current ? 'Save' : 'Save & Add To Test'
                  }`
                : 'Save'
            }
            disabled={canNotSubmit ? false : isSubmitting}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default McqFormActions;

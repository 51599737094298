import React from 'react';

import LazyImage from '../lazy-load/LazyImage';

const ImageHelper: React.FC<IImageHelper> = ({ imageUrl, altProp, lazyLoad, ...otherProps }) => {
    return (lazyLoad ? (
        <LazyImage
            src={imageUrl}
            alt={altProp}
            {...otherProps}
        />
    ) : (
            <img
                src={imageUrl}
                alt={altProp}
                {...otherProps}
            />
        )


    );
};

export default ImageHelper;
import React, { useState } from 'react';

import { LibraryService } from 'business/organizer/library/services/libraryService';
import AddQuestionsToTestContext from 'business/organizer/library/contexts/add-questions-to-test/AddQuestionsToTestContext';
import useAlert from 'common/components/alert-provider/useAlert';
import { hasMessageProperty } from 'common/utils/errors';

const libraryService = new LibraryService();

const AddQuestionsToTestProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { enqueueAlert } = useAlert();

  const [addQuestionsToTest, setAddQuestionsToTest] = useState<
    number[] | null
  >(null);
  const [addQuestionToTestResponse, setAddQuestionToTestResponse] =
    useState<any | null>(null);
  const [unCheck, setUnCheck] = useState(true);

  const handleClearQuestions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUnCheck(event?.target.checked);
    setAddQuestionsToTest((addQuestionsToTest: any) =>
      addQuestionsToTest.splice(0, addQuestionsToTest.length)
    );
  };

  const handleAddQuestionsToTest = async (
    testId: number,
    questionId?: number
  ) => {
    try {
      const questions = {
        library_question: !!questionId
          ? [questionId]
          : addQuestionsToTest,
      };

      const response: any = await libraryService.addQuestionsToTest(
        `/api/assessments/${testId}/questions/`,
        questions
      );
      const { success, failed } = response?.data;

      setAddQuestionToTestResponse(response?.data);

      if (!!success?.length) {
        enqueueAlert(
          `${
            !!questionId ? 'Question' : `${success.length} questions`
          } added to test successfully.`,
          {
            alertProps: { severity: 'success' },
          }
        );
      }

      if (!!failed?.length) {
        enqueueAlert(
          `Note: Duplicate questions not allowed. Questions already added were ignored.`,
          {
            alertProps: { severity: 'info' },
          }
        );
      }
    } catch (error) {
      enqueueAlert(
        `Not able to add the ${
          !!questionId ? 'question' : 'questions'
        }. Please try again. ${
          hasMessageProperty(error) ? error.message : ''
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  return (
    <AddQuestionsToTestContext.Provider
      value={{
        addQuestionsToTest,
        setAddQuestionsToTest,
        unCheck,
        handleClearQuestions,
        setUnCheck,
        handleAddQuestionsToTest,
        addQuestionToTestResponse,
        setAddQuestionToTestResponse,
      }}
    >
      {children}
    </AddQuestionsToTestContext.Provider>
  );
};

export default AddQuestionsToTestProvider;

import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import TestList from 'business/organizer/assessment/components/dashboard/common/TestList';

const TestStats: React.FC<ITestStatsProps> = ({
  list,
  count,
  title,
  color,
}) => {
  const [expand, setExpand] = useState(false);
  return (
    <Box
      bgcolor="#fff"
      boxShadow="0px 6px 12px #DDE6ED"
      border="1px solid #FCFCFC"
      px={4}
      py={2}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setExpand(!expand)}
        style={{ cursor: 'pointer' }}
      >
        <Box display="flex" alignItems="center" gridColumnGap={8}>
          <FiberManualRecordIcon fontSize="small" color={color} />
          <Typography variant="h4">
            {title} ({count})
          </Typography>
        </Box>
        <IconButton>
          {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {expand && (
        <Box py={4}>
          <TestList list={list} />
        </Box>
      )}
    </Box>
  );
};

export default TestStats;

import { makeStyles } from '@material-ui/core';

// these are the styles for dropdown menu
export const dropdownStyles = makeStyles((theme) => ({
  box_root: {
    // disable pointer events on the overlay
    pointerEvents: 'none',
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(68, 146, 76, 0.04)',
    },
    // enable pointer events on the popover menu
    '& .MuiPopover-paper': {
      pointerEvents: 'initial',
      boxShadow: 'rgb(0 0 0 / 15%) 0px 1.5px 3px',
      marginTop: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}));

import { AxiosRequestConfig } from 'axios';
import BaseService from 'common/services/baseService';
import objectToFormData from 'common/utils/objectToFormData';
import {
  APP_TYPE_PARAM,
  ORGANIZATION_API_URL,
  TEST_API_URL,
} from 'business/organizer/common/utils/constants';

export class OrganizerService extends BaseService {
  /**
   * Query the organizer services from here
   *  */

  currentUrl = window.location.pathname;

  async authGet(url: string, params?: object) {
    return await this.get(url, {
      app_type: this.appTypeParam,
      ...(params || {}),
    });
  }

  async getTestLists(params?: object) {
    return await this.get(TEST_API_URL, {
      app_type: this.appTypeParam,
      ...(params || {}),
    });
  }

  async getTests(
    url: string,
    { page_size = 100, page = 1, search }: IGetTestsParams
  ) {
    const params: any = { page, page_size };

    if (search) {
      params.q = search;
    }

    return await this.get(url, params);
  }
  /**
   * create a new test
   * @param url API endpoint to POST
   * @param testData
   * @param axiosConfig The configs we want to send
   */
  async createTest(
    url: string,
    testData: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.post(
      `${url}&${APP_TYPE_PARAM}=${this.appTypeParam}`,
      testData,
      axiosConfig
    );
  }

  /**
   * patch a test
   * @param url API endpoint to PATCH
   * @param testData
   * @param axiosConfig The configs we want to send
   */
  async patchTest(
    url: string,
    testData: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.patch(url, testData, axiosConfig);
  }

  /**
   * archive test
   * @param url API endpoint to POST
   * @param {}
   * @param axiosConfig The configs we want to send
   */
  async archiveTest(url: string) {
    return await this.post(url, {});
  }

  /**
   * publish test
   * @param url API endpoint to POST
   * @param {}
   * @param axiosConfig The configs we want to send
   */
  async publishtTest(url: string) {
    return await this.post(url, {});
  }

  async getCandidateRegistrationFields(url: string) {
    return await this.get(url);
  }

  /**
   * send invites
   * @param url API endpoint to POST
   * @param inviteData
   * @param axiosConfig The configs we want to send
   */
  async inviteCandidates(
    url: string,
    inviteData: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.post(url, inviteData, axiosConfig);
  }

  async bulkInviteCandidates(
    url: string,
    inviteData: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    const formData = objectToFormData(inviteData);
    return await this.post(url, formData, axiosConfig);
  }

  async patchProfile(
    url: string,
    profileData: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    return await this.patch(
      `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`,
      profileData,
      axiosConfig
    );
  }

  async patchOrganization(
    url: string,
    organizationData: object,
    axiosConfig?: AxiosRequestConfig
  ) {
    const formData = objectToFormData(organizationData);
    return await this.patch(
      `${url}?${APP_TYPE_PARAM}=${this.appTypeParam}`,
      formData,
      axiosConfig
    );
  }

  /**
   * get candidate progress list
   * @param url
   * @param {}
   * */
  async getCandidateProgressList(
    url: CandidateUrlType,
    {
      status,
      pageSize,
      /** we added page number 0 instead of 1
       * because the TablePagination componet by mui's pagination
       * starts from 0
       * */
      page = 0,
      search,
      order,
      order_by,
      test_status,
      score,
    }: IGetCandidateProgressListParams
  ) {
    const params: any = {
      page_size: pageSize,
      page: page + 1,
      order,
    };
    if (!!status?.length) {
      params.status = status.join(',');
    }
    if (!!test_status?.length) {
      params.test_status = test_status.join(',');
    }
    if (!!order_by) {
      params.order_by = order_by;
    }
    if (!!search) {
      params.search = search;
    }
    if (!!score) {
      params.score = score;
    }

    return await this.get(url, params);
  }

  /**
   * shortlist or reject candidate
   * @param url API endpoint to POST
   * @param {}
   * @param axiosConfig The configs we want to send
   */
  async shortlistOrReject(url: string, data: {}) {
    return await this.post(url, data);
  }

  // Get events happened during test
  async getEvents(
    url: string,
    params: { page: number; page_size: number }
  ) {
    return await this.get(url, params);
  }

  // get organization memebers
  async getOrganizationMembers(
    url: string,
    { page, pageSize }: { page: number; pageSize: number }
  ) {
    const params: any = {
      page_size: pageSize,
      page: page + 1,
    };

    return await this.get(url, params);
  }

  async getPlagiarismReportList(
    url: string,
    { all = 0 }: { all?: 1 | 0 } = {}
  ) {
    return await this.get(url, { all });
  }

  async createPlagiarismReport(url: string) {
    return await this.post(url, {});
  }
  /**
   * Get list of interviews
   * */
  async getInterviewsList(
    url: string,
    {
      status,
      verdict,
      pageSize,
      page = 0,
      search,
    }: IGetInterviewsListProps
  ) {
    const params: any = {
      page_size: pageSize,
      page: page + 1,
    };
    if (search) {
      params.search = search;
    }
    if (status) {
      params.status = status;
    }
    if (verdict) {
      params.verdict = verdict;
    }

    return await this.get(url, params);
  }

  /**
   * Fetch the notebook html content from the url and return
   * @param url
   */
  async getNotebookHtml(url: string) {
    const response = await this.get(url);
    return response.data;
  }

  /**
   * Get organizer
   * */
  async getOrganizer(appType: string) {
    return await this.get(`${ORGANIZATION_API_URL}?${appType}`);
  }
}

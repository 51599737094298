import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const AddOnBase: React.FC<{
  title: string;
  children: React.ReactNode;
  boxProps?: BoxProps;
}> = ({ title, children, boxProps }) => {
  const classes = useStyles();
  return (
    <Box
      bgcolor="#fff"
      borderRadius={30}
      boxShadow="0px 4px 100px 0px rgba(10, 102, 194, 0)"
      p={8}
      width={400}
      {...(boxProps || {})}
    >
      <Typography
        variant="h4"
        className={classes.typography__heading}
        color="primary"
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default AddOnBase;

const useStyles = makeStyles((theme: Theme) => ({
  typography__heading: {
    fontWeight: theme.spacing(150),
    height: theme.spacing(15),
  },
}));

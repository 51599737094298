export const sortBy = (
  obj: { [key: string]: { [key: string]: any } },
  by: string
) => {
  const order: string[] = [];
  const res: any = {};

  Object.keys(obj).forEach((key) => {
    return (order[obj[key][by] - 1] = key);
  });

  order.forEach((key) => {
    res[key] = obj[key];
  });
  return res;
};

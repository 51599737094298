import FacebookIcon from 'common/utils/assests/FacebookIcon';
import InstagramIcon from 'common/utils/assests/InstagramIcon';
import LinkedInIcon from 'common/utils/assests/LinkedInIcon';
import YoutubeIcon from 'common/utils/assests/YoutubeIcon';

export const socialMediaLinks = [
  {
    id: 1,
    icon: FacebookIcon,
    route: 'https://www.facebook.com/dphi.tech/',
  },
  {
    id: 2,
    icon: LinkedInIcon,
    route: 'https://www.linkedin.com/company/dphi/',
  },
  {
    id: 3,
    icon: InstagramIcon,
    route: 'https://www.instagram.com/dphi.tech/',
  },
  {
    id: 4,
    icon: YoutubeIcon,
    route: 'https://www.youtube.com/c/dphiofficial/featured',
  },
];

export const CHARGEBEE_ID =
  process.env.NODE_ENV === 'development' ||
  window.location.hostname === 'stage.skillspace.ai'
    ? 'dphi-test'
    : 'dphi';

export const CHARGEBEE_WEBSITE =
  'https://js.chargebee.com/v2/chargebee.js';

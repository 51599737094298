import React from 'react';
import { useState } from 'react';
import BreadcrumbContext from './BreadcrumbContext';

const BreadcrumbProvider: React.FC<IBreadcrumbProviderProps> = ({
  children,
}) => {
  const [breadcrumbData, setBreadcrumbData] = useState<
    IBreadcrumbDataProps[]
  >([]);
  return (
    <BreadcrumbContext.Provider
      value={{ breadcrumbData, setBreadcrumbData }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbProvider;

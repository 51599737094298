import theme from 'business/common/theme/theme';

export const inputWithLabelRootStyles = {
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.secondary.main,
  },
  '& .MuiFormHelperText-contained': {
    margin: 'unset',
  },
  '& .MuiFormHelperText-root': {
    fontSize: theme.spacing(3),
  },
};

import React from 'react';
import { ErrorMessage } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FileField from 'common/components/form/file-field/FileField';
import FileFieldResults from 'common/components/form/file-field/FileFieldResults';

const MyFileField: React.FC<IMyFileField> = ({
  dropzoneOptions,
  name,
  buttonText,
  icon = true,
}) => {
  return (
    <>
      <FileField dropzoneOptions={dropzoneOptions} name={name}>
        <>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={icon && <AddIcon fontSize="small" />}
          >
            {buttonText}
          </Button>
          <ErrorMessage name={name}>
            {(msg) => (
              <FormHelperText error={true}>{msg}</FormHelperText>
            )}
          </ErrorMessage>
          <FileFieldResults />
        </>
      </FileField>
    </>
  );
};

export default MyFileField;

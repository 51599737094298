import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import HasPermission from 'business/organizer/common/components/HasPermission';

const LanguageListItem: React.FC<ILanguageListItemProps> = ({
  id,
  name,
  selectAllLanguages,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } =
    useFormikContext<IUseFormikContextLanguageProps>();

  const [addedLanguage, setAddedLanguage] = useState(false);
  const { isViewer } = useHasPermission();

  useEffect(() => {
    setAddedLanguage(
      selectAllLanguages || values.languages.includes(id)
        ? true
        : false
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllLanguages]);

  const handleAddedLanguages = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setAddedLanguage(checked);
    if (checked) {
      setFieldValue('languages', [...(values.languages || []), id]);
    } else {
      setFieldValue(
        'languages',
        values.languages.filter((language) => language !== id)
      );
    }
  };

  return (
    <HasPermission
      isViewer={isViewer}
      tooltipProps={{ placement: 'top', arrow: true }}
    >
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            color="secondary"
            name="selectAll"
            checked={addedLanguage}
            onChange={handleAddedLanguages}
            disabled={isViewer}
          />
        }
        label={`${name}`}
      />
    </HasPermission>
  );
};

export default LanguageListItem;

const useStyles = makeStyles((theme) => ({
  checkbox: {
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
}));

import { Switch, Route } from 'react-router-dom';

import TestListPage from 'business/organizer/assessment/pages/assessment-tests/TestListPage';
import TestDetailPage from 'business/organizer/assessment/pages/assessment-tests/test-detail/TestDetailPage';
import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import TestQuestionsProvider from 'business/organizer/assessment/contexts/test-questions/TestQuestionsProvider';
import TestDetailProvider from 'business/organizer/assessment/contexts/test-detail/TestDetailProvider';
import IncompleteQuestionsProvider from 'business/organizer/library/contexts/incomplete-questions/IncompleteQuestionsProvider';
import AddQuestionsToTestProvider from 'business/organizer/library/contexts/add-questions-to-test/AddQuestionsToTestProvider';
import HasPermissionToInterviewPlan from 'business/organizer/common/components/HasPermissionToInterviewPlan';
import useCount from 'business/organizer/assessment/custom-hooks/useCount';

const TestsPage = () => {
  const { url } = useCurrentUrl();
  const { testsCount } = useCount();

  return (
    <>
      <GenerateBreadcrumb data={[{ label: 'Tests', route: url }]} />
      <HasPermissionToInterviewPlan pageName="tests">
        <Switch>
          <Route path={`${url}/:testStatus/:testId`}>
            <AddQuestionsToTestProvider>
              <TestDetailProvider>
                <TestQuestionsProvider>
                  <IncompleteQuestionsProvider>
                    <TestDetailPage />
                  </IncompleteQuestionsProvider>
                </TestQuestionsProvider>
              </TestDetailProvider>
            </AddQuestionsToTestProvider>
          </Route>
          <Route path={`${url}`}>
            <TestListPage testsCount={testsCount} />
          </Route>
        </Switch>
      </HasPermissionToInterviewPlan>
    </>
  );
};

export default TestsPage;

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';
import PercentageCovered from './PercentageCovered';

const PercentageCoveredWithLabel: React.FC<
  { percentage: number } & CircularProgressProps
> = ({ percentage, ...props }) => {
  return (
    <Box position="relative" display="inline-flex">
      <PercentageCovered percentage={percentage} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${percentage}%`}</Typography>
      </Box>
    </Box>
  );
};

export default PercentageCoveredWithLabel;

import { useState, useEffect } from 'react';
import { OrganizerService } from 'business/organizer/common/services/organizerService';

const organizerService = new OrganizerService();

const useQuestionSubmission = (submissionUrl: string) => {
  const [submission, setSubmission] = useState<any | null>(null);
  const [responseError, setResponseError] = useState<any | null>(
    null
  );

  useEffect(() => {
    let mounted = true;

    organizerService
      .get(submissionUrl)
      .then((response) => {
        if (mounted) {
          // console.log('solution', response?.data);
          setSubmission(response?.data);
        }
      })
      .catch((error) => setResponseError(error));

    return () => {
      setSubmission(null);
      mounted = false;
    };
  }, [submissionUrl]);

  return { submission, responseError };
};

export default useQuestionSubmission;

import { useContext } from 'react';
import McqContext from 'business/organizer/library/contexts/mcq/McqContext';

const useMcq = (): IMcqContext => {
  const context = useContext(McqContext) as IMcqContext;
  if (context === undefined) {
    throw new Error('useMcq must be used within a McqProvider.');
  }
  return context;
};

export default useMcq;

import React from 'react';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useField, Field, FieldAttributes } from 'formik';

const MySelect: React.FC<FieldAttributes<{}>> = ({
  children,
  ...props
}) => {
  const [field, meta] = useField<{}>({ ...props, type: 'select' });
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <>
      <Field
        type="select"
        as={Select}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...field}
        error={!!errorText}
      >
        {children}
      </Field>
      {meta.touched && meta.error ? (
        <FormHelperText error={!!errorText}>
          {meta.error}
        </FormHelperText>
      ) : null}
    </>
  );
};

export default MySelect;

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';

import useCodingQuestion from 'business/organizer/library/contexts/coding-question/custom-hooks/useCodingQuestion';
import QuestionFormStepper from 'business/organizer/library/components/common/QuestionFormStepper';
import { validationSchema } from 'business/organizer/library/components/question-create-update/coding/utils/validationSchema';
import RenderCodingStep from 'business/organizer/library/components/question-create-update/coding/RenderCodingStep';
import CodingFormActions from 'business/organizer/library/components/question-create-update/coding/CodingFormActions';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import useIsUpdate from 'common/custom-hooks/useIsUpdate';
import QuestionBase from 'business/organizer/library/components/question-create-update/common/QuestionBase';

export const stepsInfo = ['Problem Statement', 'Test Cases'];

const CodingPage: React.FC<ICodingQuestionPageProps> = ({
  coding,
  drawerName,
}) => {
  const { testId } = useTestId();
  const {
    activeStep,
    completedSteps,
    setActiveStep,
    handleProblemStatement,
    problemStatementResponse,
    setProblemStatementResponse,
  } = useCodingQuestion();

  const question = coding?.question;
  const problemStatementResponseQuestion =
    problemStatementResponse?.question;

  // clear the effects
  useEffect(() => {
    return () => {
      setProblemStatementResponse(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isUpdate] = useIsUpdate(!!question);

  const initialState = {
    name: question?.name
      ? problemStatementResponseQuestion?.name || question?.name
      : '',
    description: question?.description
      ? problemStatementResponseQuestion?.description ||
        question?.description
      : '',
    difficulty: question?.difficulty
      ? problemStatementResponseQuestion?.difficulty ||
        question?.difficulty
      : '',
    time_duration: question?.time_duration
      ? problemStatementResponseQuestion?.time_duration ||
        question?.time_duration
      : '',
    languages: !!question?.programming_languages.length
      ? problemStatementResponseQuestion?.programming_languages.map(
          (language: ILanguageListItemProps) => language.id
        ) ||
        question?.programming_languages.map(
          (language: ILanguageListItemProps) => language.id
        )
      : [],
    tags: coding?.tags || [],
    testId: !!testId ? testId : 0,
  };

  return (
    <QuestionBase
      name="Coding"
      drawerProps={{ drawerName, setActiveStep, completedSteps }}
      stepper={
        <QuestionFormStepper
          stepsInfo={stepsInfo}
          activeStep={activeStep}
          completedSteps={completedSteps}
        />
      }
    >
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema[activeStep]}
        onSubmit={handleProblemStatement}
      >
        <Form>
          <RenderCodingStep step={activeStep} />
          <Box display="flex" justifyContent="flex-end" py={8}>
            <CodingFormActions isUpdate={isUpdate} />
          </Box>
        </Form>
      </Formik>
    </QuestionBase>
  );
};

export default CodingPage;

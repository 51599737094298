import { useEffect } from 'react';

import useAlert from 'common/components/alert-provider/useAlert';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { TESTS_COUNT_API_URL } from 'business/organizer/common/utils/constants';
import useTests from 'business/organizer/assessment/contexts/assessment-tests/useTests';

const organizerService = new OrganizerService();

const useCount = () => {
  const { enqueueAlert } = useAlert();
  const {
    testsCount,
    setTestsCount,
    publishResponse,
    archiveResponse,
  } = useTests();

  useEffect(() => {
    let mounted = true;

    organizerService
      .authGet(TESTS_COUNT_API_URL)
      .then((response) => {
        if (mounted) {
          const { active, archive, draft } = response?.data;
          setTestsCount({ active, archive, draft });
        }
      })
      .catch((error) =>
        enqueueAlert(
          `Not able to get the counts: ${error.message}.`,
          {
            alertProps: { severity: 'error' },
          }
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTestsCount, publishResponse, archiveResponse]);

  return { testsCount };
};

export default useCount;

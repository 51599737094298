import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';

import {
  getErrorMessage,
  getValidationSchema,
} from 'business/pre-subscription/auth/components/common/helpers';
import MyInput from 'common/components/form/MyInput';
import FieldStyles from 'business/pre-subscription/auth/components/signup/FieldStyles';
import AuthPageButton from 'business/pre-subscription/auth/components/common/AuthPageButton';
import { BusinessAuthService } from 'business/pre-subscription/auth/services/businessAuthService';
import {
  API_PASSWORD_RESET_URL,
  FRONTEND_BUSINESS_LOGIN_URL,
} from 'business/pre-subscription/auth/utils/constants';
import NotificationDialog from 'common/components/notification-dialog/NotificationDialog';
import AuthBlockFooter from 'business/pre-subscription/auth/components/common/AuthBlockFooter';
import FormSectionBase from 'business/pre-subscription/common/components/FormSectionBase';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import {
  FRONTNED_HUBS_CANDIDATE_URL,
  FRONTEND_HUBS_CANDIDATE_LOGIN_URL,
} from 'common/utils/constants';

const businessAuthService = new BusinessAuthService();

const ForgotPassword: React.FC<IForgotPasswordPageProps> = ({
  apiUrl = API_PASSWORD_RESET_URL,
  redirectUrl = FRONTEND_BUSINESS_LOGIN_URL,
}) => {
  const history = useHistory();

  const [response, setResponse] =
    useState<IBusinessFormSuccessProps | null>(null);
  const [error, setError] = useState<IBusinessFormErrorProps | null>(
    null
  );

  const initialState = {
    email: '',
  };
  const validationSchema = getValidationSchema(initialState);
  const { isHubsUrl } = useCurrentUrl();

  const handleSubmit: AuthSubmissionHandler = async (
    values,
    actions
  ) => {
    try {
      const response = await businessAuthService.authPost(apiUrl, {
        ...values,
      });
      actions.resetForm();
      setResponse(response?.data);
    } catch (error) {
      setError(
        (error as AxiosError).response
          ?.data as IBusinessFormErrorProps
      );
    }
  };

  const resetResponse = () => {
    setError(null);
    setResponse(null);
  };

  const errorMessage = getErrorMessage(error);

  return (
    <FormSectionBase
      title="Forgot Your Password?"
      subtitle="Don’t worry, we got you!"
    >
      <Box>
        <NotificationDialog
          open={error !== null}
          variant="error"
          agreeText="Try Again"
          onAgree={resetResponse}
          content={errorMessage}
        />
        <NotificationDialog
          open={response !== null}
          variant="success"
          agreeText="Go to Login"
          onAgree={() =>
            history.push(
              `${
                window.location.pathname.startsWith('/hubs')
                  ? '/hubs'
                  : ''
              }${redirectUrl}`
            )
          }
          disagreeText="Close"
          onDisagree={resetResponse}
          content={response?.detail}
        />
        <Box pb={4}>
          <Typography align="center">
            Just let us know your email and we will send a password
            reset link.
          </Typography>
        </Box>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FieldStyles>
              <MyInput
                placeholder="Email Address"
                name="email"
                type="email"
              />
            </FieldStyles>
            <AuthPageButton>Send Link</AuthPageButton>
          </Form>
        </Formik>
        <AuthBlockFooter
          ctaLink={
            window.location.pathname.startsWith(
              FRONTNED_HUBS_CANDIDATE_URL
            )
              ? FRONTEND_HUBS_CANDIDATE_LOGIN_URL
              : isHubsUrl
              ? `/hubs${FRONTEND_BUSINESS_LOGIN_URL}`
              : FRONTEND_BUSINESS_LOGIN_URL
          }
        />
      </Box>
    </FormSectionBase>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core';

const FilterMenu: React.FC<IFilterMenuProps> = ({
  label,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(true);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setOpen(!open)}
        className={classes.box__label}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
        <IconButton
          className={classes.icon_button__dropdown}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <ExpandLess htmlColor={theme.palette.text.primary} />
          ) : (
            <ExpandMore htmlColor={theme.palette.text.primary} />
          )}
        </IconButton>
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </>
  );
};

export default FilterMenu;

const useStyles = makeStyles((theme) => ({
  icon_button__dropdown: {
    padding: theme.spacing(0),
  },
  box__label: {
    cursor: 'pointer',
    '&:hover': {
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
      },
    },
  },
}));

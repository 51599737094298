import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import ChallengePage from 'business/organizer/library/pages/question-create-update/challenge/ChallengePage';
import ChallengeUpdatePage from 'business/organizer/library/pages/question-create-update/challenge/ChallengeUpdatePage';

const ChallengeRoutes = () => {
  const { url } = useCurrentUrl();
  return (
    <Switch>
      <Route path={`${url}/edit/:challengeId`}>
        <ChallengeUpdatePage />
      </Route>
      <Route path={`${url}/create`}>
        <ChallengePage drawerName="create" />
      </Route>
      <Redirect from={url} to={`${url}/create`} />
    </Switch>
  );
};

export default ChallengeRoutes;

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useClipboard } from 'use-clipboard-copy';
import { Theme } from '@material-ui/core';

const CopyUrlField: React.FC<ICopyUrlFieldProps> = ({
  copyLink,
  variant = 'contained',
  paddingLeft,
}) => {
  const classes = useStyles({ paddingLeft });
  const clipboard = useClipboard();
  const [copy, setCopy] = React.useState<boolean>(false);
  const onClickCopy = () => {
    setCopy(true);
    clipboard.copy(copyLink);
  };
  return (
    <Box>
      <Grid item container>
        <Grid item xs={12} className={classes.inputbox}>
          <TextField
            className={classes.textFeild}
            ref={clipboard.target}
            id={copyLink}
            onClick={onClickCopy}
            value={copyLink}
            size="small"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  color="primary"
                  variant={variant}
                  className={classes.button}
                  size="small"
                >
                  {copy ? 'Copied!' : 'Copy'}
                </Button>
              ),
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CopyUrlField;

const useStyles = makeStyles<
  Theme,
  { paddingLeft: number | undefined }
>((theme) => ({
  button: {
    borderRadius: '0px 30px 30px 0px',
    padding: theme.spacing(0.5, 3),
  },
  textFeild: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(8),
    width: '-webkit-fill-available',
    paddingLeft: ({ paddingLeft }) =>
      typeof paddingLeft === 'undefined'
        ? theme.spacing(4)
        : paddingLeft,
    paddingRight: theme.spacing(4),
    '& .MuiInputBase-input': {
      color: theme.palette.text.secondary,
    },
  },
  inputbox: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiOutlinedInput-root': {
      paddingLeft: theme.spacing(2.5),
      padding: theme.spacing(0),
      borderRadius: theme.spacing(8),
    },
  },
}));

import React from 'react';

import TestcasesTable from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/TestcasesTable';
import CompilationInfo from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CompilationInfo';
import CandidateCode from 'business/organizer/assessment/components/candidate-progress/candidate-detail/solutions/coding/CandidateCode';
import { sqlLanguage } from 'business/candidate/assessments/components/session/helpers';

const SqlSolutionBase: React.FC<{
  submission: ISqlSubmissionBaseProps;
}> = ({ submission }) => {
  return (
    <>
      <TestcasesTable testCases={submission?.sub_questions} />
      <CompilationInfo streamData={submission?.stream_data} />
      {!!submission && (
        <CandidateCode
          language={sqlLanguage}
          code={
            !!submission?.sub_questions &&
            submission?.sub_questions?.length >= 1
              ? submission?.sub_questions[0]?.code
              : null
          }
          menuOption={sqlLanguage}
        />
      )}
    </>
  );
};

export default SqlSolutionBase;

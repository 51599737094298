import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import OrganizationInfo from 'business/organizer/settings/components/organization/OrganizationInfo';

const Organization = () => {
  const { url } = useCurrentUrl();
  const { isSuperAdmin, user } = useHasPermission();

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Personal', route: url }]}
      />
      {!!user &&
        (isSuperAdmin ? (
          <OrganizationInfo />
        ) : (
          <Box py={4}>
            <Typography variant="h5">
              You don't have permission to access this page.
            </Typography>
          </Box>
        ))}
    </>
  );
};

export default Organization;

import React, { useState } from 'react';
import TableCell, {
  TableCellProps,
} from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

const TableSortCell: React.FC<
  {
    onOrderBy: (order: IOrder) => void;
    label: string;
    active?: boolean;
    isActive: boolean;
  } & TableCellProps
> = ({
  onOrderBy,
  label,
  active = true,
  isActive,
  ...otherProps
}) => {
  const classes = useStyles();
  const [direction, setDirection] = useState(false);

  const handleSortClick = (order: IOrder) => {
    setDirection(!direction);
    onOrderBy(order);
  };

  return (
    <TableCell
      className={isActive ? classes.active : classes.inActive}
      {...otherProps}
    >
      {label}
      <Tooltip
        title={`${direction ? 'asc' : 'desc'}`}
        placement="right-end"
        arrow
      >
        <TableSortLabel
          active={active}
          direction={direction ? 'asc' : 'desc'}
          onClick={() => handleSortClick(direction ? 'desc' : 'asc')}
        />
      </Tooltip>
    </TableCell>
  );
};

export default TableSortCell;

const useStyles = makeStyles((theme) => ({
  active: {
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':
      {
        color: '#222222',
      },
  },
  inActive: {
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':
      {
        color: '#666666',
      },
  },
}));

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

import Logo from 'common/components/header/Logo';
import HubsLogo from 'common/components/header/HubsLogo';
import OrganizerProfile from 'business/organizer/common/components/navigation/OrganizerProfile';
import TabList from 'business/organizer/common/components/navigation/TabList';
import CloseMenuIcon from 'business/organizer/common/components/navigation/assets/CloseMenuIcon';
import OpenMenuIcon from 'business/organizer/common/components/navigation/assets/OpenMenuIcon';
import useHeader from 'business/organizer/common/context/header/useHeader';
import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';

const Navigation = () => {
  const classes = useStyles();

  const { openSidebar, setOpenSidebar } = useHeader();
  const { isFreeTrialEnded } = useSubscription();
  const { isHubsUrl } = useCurrentUrl();

  const handleOpenSidebar = () => setOpenSidebar(!openSidebar);

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openSidebar,
        })}
        color="inherit"
        style={{ background: '#042649', border: 'none' }}
      >
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton
                color="inherit"
                aria-label="drawer-control"
                onClick={handleOpenSidebar}
                edge="start"
                className={classes.menuButton}
              >
                {openSidebar ? <CloseMenuIcon /> : <OpenMenuIcon />}
              </IconButton>
              <Box pt={2} pl={2}>
                {isHubsUrl ? (
                  <HubsLogo textColor="#ffffff" />
                ) : (
                  <Logo textColor="#ffffff" />
                )}
              </Box>
            </Box>
            <OrganizerProfile />
          </Box>
        </Toolbar>
      </AppBar>
      {!isFreeTrialEnded && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openSidebar,
            [classes.drawerClose]: !openSidebar,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openSidebar,
              [classes.drawerClose]: !openSidebar,
            }),
          }}
        >
          <Box mt={16}>
            <TabList />
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default Navigation;

const drawerWidth = 175;

/** when sidebar is closed it only shows the icons because we have
 * given margin left 60 px and body we have margn top 64px
 * if you are changng something in design make sure you can test
 * these 2 things and make changes accordingly
 * */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none',
      border: '1px solid rgba(204, 204, 204, 0.5)',
      padding: theme.spacing(0.25, 0),
    },
    appBarShift: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarMini: {
      marginLeft: 60,
      width: `calc(100% - 64px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      paddingLeft: theme.spacing(3.5),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(15),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';

import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';
import useCloseQuestionDrawer from 'business/organizer/library/custom-hooks/useCloseQuestionDrawer';

const QuestionBase: React.FC<IQuestionBaseProps> = ({
  name,
  children,
  drawerProps,
  stepper,
}) => {
  const closeQuestionDrawer = useCloseQuestionDrawer({
    ...drawerProps,
  });

  return (
    <>
      <CloseDrawerIcon closeQuestionDrawer={closeQuestionDrawer} />
      <Box width="100%">
        <AppBar
          position="sticky"
          color="transparent"
          style={{ backgroundColor: '#F8F9FE', boxShadow: 'none' }}
          component="section"
        >
          <Container maxWidth="xl">
            <Box py={4}>
              <Typography variant="h5">{name} Question</Typography>
            </Box>
          </Container>
        </AppBar>
        {stepper}
        <Container maxWidth="xl" component="section">
          {children}
        </Container>
      </Box>
    </>
  );
};

export default QuestionBase;

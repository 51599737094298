import BaseService from 'common/services/baseService';
import getCookie from 'common/utils/getCookie';

/**
 * A singleton service for authentication.
 */
class AuthService extends BaseService {
  /**
   * True if user is logged in.
   */
  get isAuthenticated(): Boolean {
    return Boolean(parseInt(getCookie('auth') || '0'));
  }

  /**
   * Return the current user
   */
  get user(): IUser {
    throw new Error('Not implemented!');
  }
}

const authService = new AuthService();
Object.freeze(authService);

export default authService;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import CandidateProgressDetailProvider from 'business/organizer/assessment/contexts/candidate-progress-detail/CandidateProgressDetailProvider';
import PersistSidebar from 'common/components/utility-components/PersistSidebar';
import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';
import useDropdown from 'common/components/use-dropdown/useDropdown';
import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import ExtendTestDuration from 'business/organizer/assessment/components/candidate-progress/candidate-list/ExtendTestDuration';
import CandidateDetailsDrawer from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/CandidateDetailsDrawer';
import { dropdownStyles } from 'business/organizer/common/utils/styles/dropdownStyles';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import ViewIcon from 'business/organizer/interviews/components/icons/ViewIcon';

const Actions: React.FC<ICandidateSubmissionActionsProps> = ({
  candidate,
  candidateList,
  idx,
  disableView,
}) => {
  const { url } = useCurrentUrl();

  const { toggleDrawer } = useToggleDrawer();
  const { anchorEl, handleClick, handleClose } = useDropdown();

  const [candidateInfoDrawer, setCandidateInfoDrawer] =
    useState(false);
  const [extendTestDurationDialog, setExtendTestDuartionDialog] =
    useState(false);

  const { pathname } = useLocation();
  const { drawerList } = useDrawer();
  const { isViewer } = useHasPermission();

  const { id, extend_test_duration_url, email } = candidate;

  return (
    <>
      {!disableView && !drawerList.includes('candidate') && (
        <PersistSidebar
          endingPath={
            pathname.endsWith(`${id}`)
              ? `${id}`
              : pathname.endsWith(`${id}/questions`)
              ? `${id}/questions`
              : pathname.endsWith(`${id}/timeline`)
              ? `${id}/timeline`
              : pathname.endsWith(`${id}/proctoring`)
              ? `${id}/proctoring`
              : `${id}/questions/solution`
          }
          endPosition={
            pathname.endsWith(`${id}`) ||
            pathname.endsWith(`${id}/questions`) ||
            pathname.endsWith(`${id}/timeline`) ||
            pathname.endsWith(`${id}/proctoring`)
              ? undefined
              : /**
                 * we are getting the index of "solution" string and adding its length in it
                 * to get correct endPosition
                 * */
                pathname.indexOf('solution') + 8
          }
          sidebarName="candidate"
          sidebar={candidateInfoDrawer}
          onSidebar={(drawer: boolean) =>
            setCandidateInfoDrawer(drawer)
          }
        />
      )}
      <Box display="flex" fontSize={14}>
        {!disableView && (
          <Link to={`${url}/${id}`}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={toggleDrawer(
                  true,
                  setCandidateInfoDrawer,
                  'candidate'
                )}
                size="small"
              >
                <ViewIcon />
              </IconButton>
            </Box>
          </Link>
        )}
        <HasPermission
          isViewer={isViewer}
          tooltipProps={{ placement: 'top', arrow: true }}
        >
          <IconButton
            onClick={anchorEl !== null ? handleClose : handleClick}
            size="small"
            disabled={isViewer}
          >
            <MoreVertIcon fontSize="inherit" />
          </IconButton>
        </HasPermission>
      </Box>
      {!disableView && (
        <CustomDrawer
          drawer={candidateInfoDrawer}
          setDrawer={setCandidateInfoDrawer}
          drawerName="candidate"
        >
          <CandidateProgressDetailProvider>
            <CandidateDetailsDrawer
              closeDrawer={toggleDrawer(
                false,
                setCandidateInfoDrawer,
                'candidate'
              )}
              candidateList={candidateList}
              idx={idx}
            />
          </CandidateProgressDetailProvider>
        </CustomDrawer>
      )}
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        useStyles={dropdownStyles}
      >
        <MenuItem onClick={() => setExtendTestDuartionDialog(true)}>
          Extend test duration
        </MenuItem>
        <ExtendTestDuration
          extendTestDurationDialog={extendTestDurationDialog}
          closeExtendTestDurationDialog={() =>
            setExtendTestDuartionDialog(false)
          }
          extendTestDurationUrl={extend_test_duration_url}
          candidateEmail={email}
        />
      </DropdownMenu>
    </>
  );
};

export default Actions;

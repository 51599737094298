import { useContext } from 'react';
import ProjectContext from 'business/organizer/library/contexts/project-question/ProjectContext';

const useProjectQuestion = (): IProjectQuestionContext => {
  const context = useContext(
    ProjectContext
  ) as IProjectQuestionContext;
  if (context === undefined) {
    throw new Error(
      'useProjectQuestion must be used within a ProjectProvider.'
    );
  }
  return context;
};

export default useProjectQuestion;

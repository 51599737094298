import React, { useState } from 'react';

import CandidateProgressContext from 'business/organizer/assessment/contexts/candidate-progress/CandidateProgressContext';
import useAsync from 'common/custom-hooks/useAsync';

const CandidateProgressProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [hasChanges, setHasChanges] = useState(true);
  const [candidateIds, setCandidateIds] = useState<number[]>([]);
  const [search, setSearch] = useState<string>('');

  const { run, ...otherAsyncProps } = useAsync();

  return (
    <CandidateProgressContext.Provider
      value={{
        setCandidateIds,
        candidateIds,
        setSearch,
        search,
        setHasChanges,
        hasChanges,
        run,
        ...otherAsyncProps,
      }}
    >
      {children}
    </CandidateProgressContext.Provider>
  );
};

export default CandidateProgressProvider;

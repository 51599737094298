import { createContext } from 'react';

const InterviewQuestionContext =
  createContext<InterviewQuestion.IInterviewQuestionContext | null>(
    null
  );

InterviewQuestionContext.displayName = 'InterviewQuestionContext';

export default InterviewQuestionContext;

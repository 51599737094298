import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';

import { validationSchema } from 'business/organizer/library/components/question-create-update/challenge/utils/validationSchema';
import ChallengeFormActions from 'business/organizer/library/components/question-create-update/challenge/form-actions/ChallengeFormActions';
import RenderChallengeStep from 'business/organizer/library/components/question-create-update/challenge/RenderChallengeStep';
import useChallengeQuestion from 'business/organizer/library/contexts/challenge-question/custom-hooks/useChallengeQuestion';
import QuestionFormStepper from 'business/organizer/library/components/common/QuestionFormStepper';
import { getDataset } from 'business/organizer/library/utils/getDataset';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import useIsUpdate from 'common/custom-hooks/useIsUpdate';
import QuestionBase from 'business/organizer/library/components/question-create-update/common/QuestionBase';

export enum StepLabel {
  PROBLEM_STATEMENT = 0,
  DATASET = 1,
  OTHER_DETAILS = 2,
}

export const stepsInfo = [
  'Problem Statement',
  'Dataset',
  'Other Details',
];

const ChallengePage: React.FC<IChallenegeQuestionProps> = ({
  challenge,
  drawerName,
}) => {
  const { testId } = useTestId();
  const {
    activeStep,
    prevStep,
    completedSteps,
    handleProblemStatementSubmit,
    handleDatasetSubmit,
    handleOtherDetailsSubmit,
    setActiveStep,
    problemStatementResponse,
    dataset,
    datasetFiles,
    otherDetails,
    setProblemStatementResponse,
    setDataset,
    setOtherDetails,
    setDatasetFiles,
  } = useChallengeQuestion();

  const question = challenge?.question;
  const problemStatementResponseQuestion =
    problemStatementResponse?.question;

  const [isUpdate] = useIsUpdate(!!question);

  const initialState: IInitialStateChallengeFormProps = {
    name: question?.name
      ? problemStatementResponseQuestion?.name || question?.name
      : '',
    description: question?.overview
      ? problemStatementResponseQuestion?.overview ||
        question?.overview
      : '',
    time_duration: question?.time_duration
      ? problemStatementResponseQuestion?.time_duration ||
        question?.time_duration
      : '',
    score: question?.score
      ? problemStatementResponseQuestion?.score || question?.score
      : '',
    difficulty: question?.difficulty
      ? problemStatementResponseQuestion?.question?.difficulty ||
        question?.difficulty
      : '',
    tags: challenge?.tags || [],
    dataDescription: question?.data
      ? dataset.questionResponse?.question?.data || question?.data
      : '',
    trainingDataset: question
      ? datasetFiles.trainingDataset?.file ||
        getDataset(question?.datasets, 'train')
      : '',
    testingDataset: question
      ? datasetFiles.testingDataset?.file ||
        getDataset(question?.datasets, 'test')
      : '',
    evaluationDataset: question?.prediction_csv
      ? datasetFiles.evaluationDataset?.prediction_csv ||
        question?.prediction_csv
      : '',
    sampleSubmission: question
      ? datasetFiles.sampleSubmission?.file ||
        getDataset(question?.datasets, 'sample_submission')
      : '',
    targetVariableName: question?.evaluation_metrics[0]?.column
      ? dataset?.evaluationResponse?.column ||
        question?.evaluation_metrics[0]?.column
      : '',
    rules: question?.evaluation
      ? otherDetails?.rulesResponse?.question?.evaluation ||
        question?.evaluation
      : '',
    evaluationMetric: question?.evaluation_metrics[0]?.evaluator?.id
      ? dataset?.evaluationResponse?.evaluator?.id ||
        question?.evaluation_metrics[0]?.evaluator?.id
      : '',
    testId: !!testId ? testId : 0,
  };

  // clear the effects
  useEffect(() => {
    return () => {
      setProblemStatementResponse(null);
      setDataset({
        questionResponse: null,
        evaluationResponse: null,
      });
      setDatasetFiles({
        trainingDataset: null,
        testingDataset: null,
        evaluationDataset: null,
        sampleSubmission: null,
      });
      setOtherDetails({ rulesResponse: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitMethods = [
    handleProblemStatementSubmit,
    handleDatasetSubmit,
    handleOtherDetailsSubmit,
  ];

  return (
    <QuestionBase
      name="AI/Machine Learning Challenge"
      drawerProps={{ drawerName, setActiveStep, completedSteps }}
      stepper={
        <QuestionFormStepper
          stepsInfo={stepsInfo}
          activeStep={activeStep}
          completedSteps={completedSteps}
        />
      }
    >
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema[activeStep]}
        onSubmit={submitMethods[activeStep]}
      >
        <Form>
          <RenderChallengeStep step={activeStep} />
          <Box display="flex" justifyContent="flex-end" py={8}>
            <ChallengeFormActions
              activeStep={activeStep}
              prevStep={prevStep}
              isUpdate={isUpdate}
            />
          </Box>
        </Form>
      </Formik>
    </QuestionBase>
  );
};

export default ChallengePage;

import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import CustomStepper from 'common/components/custom-stepper/CustomStepper';

const QuestionFormStepper: React.FC<IQuestionFormStepperProps> = ({
  stepsInfo,
  activeStep,
  completedSteps,
}) => {
  return (
    <Box width="100%">
      <Divider variant="fullWidth" />
      <CustomStepper
        stepsInfo={stepsInfo}
        currentStep={activeStep}
        completedSteps={completedSteps}
        alternativeLabel
      />
      <Divider />
    </Box>
  );
};

export default QuestionFormStepper;

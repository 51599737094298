import React, { useState } from 'react';

import CodingUpdatePageContext from 'business/organizer/library/contexts/coding-question/contexts/CodingUpdatePageContext';

const CodingUpdatePageProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [coding, setCoding] = useState<any | null>(null);

  return (
    <CodingUpdatePageContext.Provider value={{ coding, setCoding }}>
      {children}
    </CodingUpdatePageContext.Provider>
  );
};

export default CodingUpdatePageProvider;

import React from 'react';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import useQuestionDrawer from 'business/organizer/library/contexts/question-drawer/useQuestionDrawer';

const useCloseQuestionDrawer = (
  drawerProps: IQuestionDrawerCloseProps
) => {
  const { drawerName, setActiveStep, completedSteps } = drawerProps;

  const { toggleDrawer } = useToggleDrawer();
  const { setQuestionDrawer, currentUrl } = useQuestionDrawer();

  const closeQuestionDrawer = (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    toggleDrawer(
      false,
      setQuestionDrawer,
      drawerName,
      currentUrl
    )(event);
    if (completedSteps && setActiveStep) {
      setActiveStep(0);
      completedSteps.clear();
    }
  };

  return closeQuestionDrawer;
};

export default useCloseQuestionDrawer;

import React from 'react';

const LibrarySvg: React.FC<ISvgIconProps> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4556 2.71094H21.7779V16.9434C21.7779 18.0645 20.8206 18.9766 19.6995 18.9766H13.4841C13.2041 19.7655 12.4503 20.332 11.5667 20.332C10.6831 20.332 9.92926 19.7655 9.64927 18.9766H3.43385C2.3127 18.9766 1.35547 18.0645 1.35547 16.9434V2.71094H0.677734C0.303128 2.71094 0 3.01407 0 3.38867V19.6543C0 20.0289 0.303128 20.332 0.677734 20.332H8.2938C8.57375 21.121 9.32761 21.6875 10.2112 21.6875H12.9221C13.8057 21.6875 14.5595 21.121 14.8395 20.332H22.4556C22.8302 20.332 23.1333 20.0289 23.1333 19.6543V3.38867C23.1333 3.01407 22.8302 2.71094 22.4556 2.71094Z"
      fill={color}
    />
    <path
      d="M10.8887 17.1245V1.57885C9.9506 0.794892 8.44431 0 6.14453 0C5.76992 0 5.4668 0.303128 5.4668 0.677734V14.2324C5.4668 14.607 5.76992 14.9102 6.14453 14.9102C9.27946 14.9102 10.5868 16.6441 10.8887 17.1245Z"
      fill={color}
    />
    <path
      d="M19.6992 17.6211C20.0738 17.6211 20.377 17.318 20.377 16.9434V2.0332C20.377 1.6586 20.0738 1.35547 19.6992 1.35547H14.2773C13.5116 1.35547 12.8123 1.62046 12.2441 2.05051V18.2988C12.2441 17.9249 12.5479 17.6211 12.9219 17.6211H19.6992Z"
      fill={color}
    />
    <path
      d="M3.43359 1.35547C3.05899 1.35547 2.75586 1.6586 2.75586 2.0332V16.9434C2.75586 17.318 3.05899 17.6211 3.43359 17.6211H9.57152C9.19194 17.161 8.19653 16.2656 6.14453 16.2656C5.02338 16.2656 4.11133 15.3536 4.11133 14.2324V1.35547H3.43359Z"
      fill={color}
    />
  </svg>
);

export default LibrarySvg;

import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import useAsync from 'common/custom-hooks/useAsync';
import { InterviewService } from 'interview/services/http/InterviewService';
import HasPermission from 'business/organizer/common/components/HasPermission';
import useHasPermission from 'business/organizer/common/custom-hooks/useHasPermission';
import useAlert from 'common/components/alert-provider/useAlert';
import useDialog from 'common/components/dialog-provider/useDialog';
import InterviewDetails from 'business/organizer/interviews/components/report/InterviewDetails';
import WhiteboardSection from 'business/organizer/interviews/components/report/WhiteboardSection';
import CodeSection from 'business/organizer/interviews/components/report/CodeSection';
import useInterviews from 'business/organizer/interviews/context/useInterviews';

const interviewService = new InterviewService();
const ReportDrawer: React.FC<{
  interview: Interviews.IInterviewsListItem;
}> = ({ interview }) => {
  const classes = useStyles();
  const { enqueueAlert } = useAlert();
  const { confirm } = useDialog();
  const {
    data: interviewDetails,
    error,
    status,
    run,
    setData,
  }: IUseAsyncReturnProps<Interview.IInterviewDetailProps> = useAsync();

  const { isViewer } = useHasPermission();
  const { setIsLoaded } = useInterviews();

  /**
   * Instead of directly updating the isLoaded global state we are updating this local state
   * so that drawer should not close automatically when shortlisted or rejected and for
   * updating the status in list when the component is unmounting at that point we can check
   * if the organizer has taken the action or not and based on that we will update the isLoaded
   * state.
   * */
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    run(interviewService.get(interview.url));
  }, [interview.url, run]);

  useEffect(() => {
    return () => {
      if (hasChanged) setIsLoaded(false);
    };
  }, [hasChanged, setIsLoaded]);

  const handleVerdict = (verdict: 'shortlisted' | 'rejected') => {
    if (interviewDetails?.verdict === verdict) return;

    confirm(
      `Do you want to ${
        verdict === 'shortlisted' ? 'shortlist' : 'reject'
      } ${interviewDetails?.candidate.email} ?`,
      '',
      {
        showIcon: false,
        onDisagree: (close, resolve) => {
          if (resolve) {
            resolve(false);
          }
          close();
        },
      }
    )
      .then((confimed) => {
        if (confimed) {
          return interviewService.patch(interview.url, {
            verdict,
          });
        }
      })
      .then((res) => {
        if (res) {
          setData({ ...interviewDetails, verdict: res.data.verdict });
          setHasChanged(true);
          enqueueAlert(
            `${interviewDetails?.candidate.email} is ${verdict}.`,
            {
              alertProps: { severity: 'success' },
            }
          );
        }
      })
      .catch((err) => {
        enqueueAlert(
          `Error while ${
            verdict === 'shortlisted' ? 'shortlisting' : 'rejecting'
          } ${interviewDetails?.candidate.email}, ${err.message}`,
          {
            alertProps: { severity: 'success' },
          }
        );
      });
  };

  return (
    <GenericResponseHandler status={status} error={error}>
      {interviewDetails && (
        <Box bgcolor="#FAFAFA">
          {/* header */}
          <Container
            maxWidth={false}
            className={classes.container__root}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={4}
              px={2}
            >
              <Box>
                <Typography variant="h4">
                  {interviewDetails?.candidate.email}
                </Typography>
              </Box>
              <Box display="flex" gridColumnGap={6}>
                <HasPermission
                  isViewer={isViewer}
                  tooltipProps={{ placement: 'top', arrow: true }}
                >
                  <Button
                    onClick={() => handleVerdict('shortlisted')}
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={
                      isViewer ||
                      interviewDetails.verdict === 'shortlisted'
                    }
                  >
                    Shortlist
                  </Button>
                </HasPermission>
                <HasPermission
                  isViewer={isViewer}
                  tooltipProps={{ placement: 'top', arrow: true }}
                >
                  <Button
                    onClick={() => handleVerdict('rejected')}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={
                      isViewer ||
                      interviewDetails.verdict === 'rejected'
                    }
                  >
                    Reject
                  </Button>
                </HasPermission>
              </Box>
            </Box>
          </Container>
          {/* details */}
          <Box px={4} py={8}>
            <InterviewDetails interviewDetails={interviewDetails} />
          </Box>
          {/* code */}
          <CodeSection interviewDetails={interviewDetails} />
          {/* notes */}
          <Container
            maxWidth={false}
            className={classes.container__root}
          >
            <Box py={2}>
              <Typography variant="h6">Interview Notes</Typography>
            </Box>
          </Container>
          <Container maxWidth={false}>
            <Box
              borderRadius={8}
              bgcolor="#fff"
              p={4}
              my={6}
              className={classes.box__summary}
            >
              <Typography>
                {interviewDetails?.summary ||
                  'Notes are not available of this interview'}
              </Typography>
            </Box>
          </Container>
          {/* whiteboard */}
          <WhiteboardSection
            whiteboardElements={interviewDetails.whiteboard?.data}
          />
        </Box>
      )}
    </GenericResponseHandler>
  );
};

export default ReportDrawer;

const useStyles = makeStyles((theme: Theme) => ({
  container__root: {
    background: '#f1f6f9',
  },
  box__summary: {
    wordBreak: 'break-word',
  },
}));

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ImageVerificationDrawer from 'business/organizer/assessment/components/candidate-progress/candidate-detail/proctoring/ImageVerification';
import TablePaginationBoxStyles from 'business/organizer/assessment/components/candidate-progress/common/TablePaginationBoxStyles';
import GenericResponseHandler from 'common/components/response-handler/GenericResponseHandler';
import useProctoringDetails from 'business/organizer/assessment/components/candidate-progress/candidate-detail/custom-hooks/useProctoringDetails';
import useCandidateProgressDetail from 'business/organizer/assessment/contexts/candidate-progress-detail/useCandidateProgressDetail';
import { getDayjsDate } from 'common/utils/dayjsUtils';

const AbnormalEvents = () => {
  const { candidateDetails } = useCandidateProgressDetail();
  const {
    handleChangeRowsPerPage,
    handlePageChange,
    params,
    status,
    error,
    data,
  }: IUseProctoringDetailsProps<ICandidateProctoringDetailsProps> = useProctoringDetails(
    candidateDetails?.abnormal_events_url as string
  );

  return (
    <>
      <Box py={6}>
        <Typography align="center" variant="h5">
          Abnormal Events
        </Typography>
      </Box>
      {data && !data.results.length && error === null ? (
        <Typography variant="h5" align="center">
          There are no abnormal events found during the test.
        </Typography>
      ) : (
        <Container maxWidth="xl">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      Image timestamp
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">
                      Confidence score
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6">
                      Candidate image
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <GenericResponseHandler status={status} error={error}>
                  <>
                    {data?.results.map(
                      ({ id, created_at, confidence_score, url }) => (
                        <TableRow key={id}>
                          <TableCell>
                            <Typography variant="body1">
                              {getDayjsDate({
                                date: created_at,
                                format: "DD MMM[']YY HH[:]mm:ss",
                              })}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1">
                              {confidence_score}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <ImageVerificationDrawer
                              apiUrl={url as string}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </>
                </GenericResponseHandler>
              </TableBody>
            </Table>
          </TableContainer>
          <Box pt={6}>
            <TablePaginationBoxStyles
              rowsPerPageOptions={[2, 10, 25, 50, 100]}
              count={data?.count}
              rowsPerPage={params.page_size}
              page={params.page}
              onPageChange={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      )}
    </>
  );
};

export default AbnormalEvents;

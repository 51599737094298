import React, { useState } from 'react';
import Button from '@material-ui/core/Button/Button';
import Box from '@material-ui/core/Box/Box';

import ConfirmationDialog from 'common/components/confirmation-dialog/ConfirmationDialog';
import { Typography } from '@material-ui/core';
import CopyUrlField from 'common/components/share/CopyUrlField';

const BillingSupport = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ConfirmationDialog
        open={open}
        disagreeText="Close"
        onDisagree={() => setOpen(false)}
        variant="info"
      >
        <Box textAlign="center">
          <Box pb={2}>
            <Typography align="center" variant="h4">
              Thank you for choosing our service
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography align="center">
              Please drop us a line with your requirements on the
              following email.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box width={280}>
              <CopyUrlField copyLink="support@skillspace.app" />
            </Box>
          </Box>
        </Box>
      </ConfirmationDialog>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Contact us
      </Button>
    </>
  );
};

export default BillingSupport;

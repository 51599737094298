import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AxiosError } from 'axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useClipboard } from 'use-clipboard-copy';
import CheckCircle from '@material-ui/icons/CheckCircle';

import { OrganizerService } from 'business/organizer/common/services/organizerService';
import { INTERVIEW_API_URL } from 'business/organizer/interviews/utils/constants';
import useInterviews from 'business/organizer/interviews/context/useInterviews';
import redirect from 'common/utils/redirect';
import { getErrorMessage } from 'common/utils/errors';
import ScheduleInterviewForm from 'business/organizer/interviews/components/ScheduleInterviewForm';
import InstantInterviewForm from 'business/organizer/interviews/components/InstantInterviewForm';
import MailIcon from 'business/organizer/interviews/components/icons/MailIcon';
import { getDayjsDate } from 'common/utils/dayjsUtils';

const Actions: React.FC<{
  onClose: () => void;
  ctaText?: string;
  children?: React.ReactNode;
}> = ({ onClose, ctaText = 'Close', children }) => (
  <Box
    bgcolor="#FAFAFA"
    display="flex"
    justifyContent="flex-end"
    gridColumnGap={12}
    borderRadius={8}
    px={8}
    py={4}
  >
    <Button variant="outlined" color="primary" onClick={onClose}>
      {ctaText}
    </Button>
    {children}
  </Box>
);

const organizerService = new OrganizerService();
const CreateInterviewForm: React.FC<{
  onClose: () => void;
  isScheduled: boolean;
}> = ({ onClose, isScheduled }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [response, setResponse] =
    useState<Interviews.ICreateInterviewResponse | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);

  const clipboard = useClipboard();
  const [copy, setCopy] = useState(false);

  const { setIsLoaded } = useInterviews();

  const handleSubmit = (
    data:
      | Interviews.IScheduledInterviewFormProps
      | Interviews.ICreateInterviewFormProps
  ) => {
    let requestData = { ...data };
    if (isScheduled) {
      requestData = {
        ...data,
        interview_date: getDayjsDate({
          date: (data as Interviews.IScheduledInterviewFormProps)
            .interview_date,
          format: 'DD-MM-YYYY',
        }),
        interview_time: getDayjsDate({
          date: (data as Interviews.IScheduledInterviewFormProps)
            .interview_date,
          format: 'hh:m a',
        }),
      };
    }
    organizerService
      .post(INTERVIEW_API_URL, requestData)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const closeCopyLinkModal = () => {
    onClose();
    if (!pathname.endsWith('all')) {
      redirect(history, './all');
    }
  };

  const onCopyLink = () => {
    clipboard.copy(response?.interview_link);
    setCopy(true);
  };

  // load the page when new interview is created and the modal is closing
  useEffect(() => {
    return () => {
      if (response !== null) {
        setIsLoaded(false);
      }
    };
  }, [setIsLoaded, response]);

  return (
    <Box>
      {error !== null ? (
        <Box p={4}>
          <Typography color="error">
            {error?.response
              ? getErrorMessage(error.response.data)
              : 'Error, while generating a link, please try again.'}
          </Typography>
          <Actions onClose={onClose} />
        </Box>
      ) : response !== null ? (
        <>
          {isScheduled ? (
            <>
              <Box p={8} display="flex" gridColumnGap={8}>
                <MailIcon />
                <Typography>
                  We have mailed the interview link to{' '}
                  <Typography component="span" color="primary">
                    you
                  </Typography>{' '}
                  and the{' '}
                  <Typography component="span" color="primary">
                    candidate
                  </Typography>
                  .
                </Typography>
              </Box>
              <Actions onClose={closeCopyLinkModal} ctaText="Close" />
            </>
          ) : (
            <>
              <Box p={8}>
                <Typography>Interview link:</Typography>
                <Box pt={2} pb={4}>
                  <TextField
                    value={response.interview_link}
                    size="small"
                    variant="outlined"
                    focused={copy}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Box>
                <Typography>
                  Copy above link and share it with candidate.
                </Typography>
              </Box>
              <Actions
                onClose={closeCopyLinkModal}
                ctaText="Close"
                children={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onCopyLink}
                    startIcon={copy ? <CheckCircle /> : undefined}
                  >
                    {copy ? 'Copied' : 'Copy'}
                  </Button>
                }
              />
            </>
          )}
        </>
      ) : isScheduled ? (
        <ScheduleInterviewForm
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      ) : (
        <InstantInterviewForm
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Box>
  );
};

export default CreateInterviewForm;

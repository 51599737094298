import { Switch, Route, Redirect } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import SqlPage from 'business/organizer/library/pages/question-create-update/sql/SqlPage';
import SqlUpdatePage from 'business/organizer/library/pages/question-create-update/sql/SqlUpdatePage';

const SqlRoutes = () => {
  const { url } = useCurrentUrl();
  return (
    <Switch>
      <Route path={`${url}/edit/:sqlId`}>
        <SqlUpdatePage />
      </Route>
      <Route path={`${url}/create`}>
        <SqlPage drawerName="create" />
      </Route>
      <Redirect from={url} to={`${url}/create`} />
    </Switch>
  );
};

export default SqlRoutes;

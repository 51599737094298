import { useContext } from 'react';
import InterviewQuestionContext from 'business/organizer/library/contexts/interview-question/InterviewQuestionContext';

const useInterviewQuestion =
  (): InterviewQuestion.IInterviewQuestionContext => {
    const context = useContext(
      InterviewQuestionContext
    ) as InterviewQuestion.IInterviewQuestionContext;
    if (context === undefined) {
      throw new Error(
        'useInterviewQuestion must be used within a McqProvider.'
      );
    }
    return context;
  };

export default useInterviewQuestion;

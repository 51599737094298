import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import useCandidateProgress from 'business/organizer/assessment/contexts/candidate-progress/useCandidateProgress';
import TypographyCell from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/TypographyCell';
import TimeCell from 'business/organizer/assessment/components/candidate-progress/candidate-list/common/TimeCell';
import Actions from 'business/organizer/assessment/components/candidate-progress/candidate-list/submissions/Actions';
import {
  getDifferenceInMins,
  getDurationString,
} from 'common/utils/dayjsUtils';
import { sliceString } from 'common/utils/sliceString';
import QuestionsAttemptedBar from '../common/QuestionsAttemptedBar';
// import useDrawer from 'business/organizer/library/contexts/drawer/useDrawer';

const SubmissionsBaseItem: React.FC<ISubmissionsBaseItemProps> = ({
  label,
  candidate,
  candidateList,
  candidateStatus,
  idx,
}) => {
  const { candidateIds, setCandidateIds } = useCandidateProgress();
  // const [hover, setHover] = useState(false);

  const handleCandidateCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    candidateId: number,
    isSelected: boolean
  ) => {
    if (isSelected) {
      setCandidateIds((candidateIds) =>
        candidateIds.filter((id) => id !== candidateId)
      );
    } else {
      setCandidateIds((candidateIds) => [
        ...(candidateIds || []),
        candidateId,
      ]);
    }
  };

  const duration = getDurationString(
    getDifferenceInMins(
      candidate.test_start_time,
      candidate.test_end_time
    )
  );

  // candidate.test_start_time,
  //           candidate.test_end_time
  // const { drawerList } = useDrawer();

  // const handleMouseLeave = () => {
  //   // dont update hover state when drawer is open
  //   if (!drawerList.includes('candidate')) {
  //     setHover(false);
  //   }
  // };

  return (
    <TableRow
      component="div"
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={handleMouseLeave}
      // hover={hover}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={candidateIds.includes(candidate.id)}
          onChange={(event) =>
            handleCandidateCheck(
              event,
              candidate?.id,
              candidateIds.includes(candidate.id)
            )
          }
          inputProps={{
            'aria-labelledby': 'check',
          }}
        />
      </TableCell>
      <TypographyCell
        title={sliceString(
          `${candidate.first_name} ${candidate.last_name}`,
          36
        )}
        subtitle={sliceString(candidate.email, 30)}
      />
      {candidateStatus !== 'in_progress' && (
        <TableCell align="center">
          {candidate.total_score}/{candidate.total_questions_score}
        </TableCell>
      )}
      {candidateStatus !== 'in_progress' && (
        <TableCell align="center">
          <QuestionsAttemptedBar
            total_questions={candidate.total_questions}
            total_attempted_questions={
              candidate.total_attempted_questions
            }
          />
        </TableCell>
      )}
      {candidateStatus === 'in_progress' && (
        <TimeCell align="center" date={candidate.test_start_time} />
      )}
      {candidateStatus !== 'in_progress' && (
        <TableCell align="center">
          {!duration.trim().length ? '-' : duration}
        </TableCell>
      )}
      {label && (
        <TimeCell
          align="center"
          date={
            candidate?.shortlisted_on ||
            (candidate?.rejected_on as string)
          }
        />
      )}
      {candidateStatus === 'ready_for_review' && (
        <TimeCell align="center" date={candidate.test_end_time} />
      )}
      {candidateStatus === 'in_progress' && (
        <TimeCell align="center" date={candidate.invited_on} />
      )}
      <TableCell>
        <Actions
          idx={idx}
          candidate={candidate}
          candidateList={candidateList}
          disableView={candidateStatus === 'in_progress'}
        />
      </TableCell>
    </TableRow>
  );
};

export default SubmissionsBaseItem;

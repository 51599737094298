import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import GenerateBreadcrumb from 'business/organizer/common/components/GenerateBreadcrumb';
import { OrganizerService } from 'business/organizer/common/services/organizerService';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import { PROFILE_API_URL } from 'business/organizer/settings/utils/constants';
import HttpError from 'common/components/http-error/HttpError';
import PersonalDetailsUpdate from 'business/organizer/settings/components/personal/PersonalDetailsUpdate';
import GenericListSkeleton from 'common/components/skeletons/GenericListSkeleton';

const organizerService = new OrganizerService();

const Personal = () => {
  const { url } = useCurrentUrl();
  const [personalDetails, setPersonalDetails] = useState<any | null>(
    null
  );
  const [personalDetailsError, setPersonalDetailsError] =
    useState<AxiosError | null>(null);

  useEffect(() => {
    let mounted = true;
    organizerService
      .authGet(PROFILE_API_URL)
      .then((response) => {
        if (mounted) {
          console.log(response, '<<<<<');
          setPersonalDetails(response?.data?.results[0]);
        }
      })
      .catch((error) => setPersonalDetailsError(error));

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <GenerateBreadcrumb
        data={[{ label: 'Personal', route: url }]}
      />
      {personalDetailsError !== null ? (
        <HttpError axiosError={personalDetailsError} />
      ) : personalDetails === null ? (
        <GenericListSkeleton
          items={4}
          boxProps={{ height: '16px', paddingTop: '12px' }}
        />
      ) : (
        <PersonalDetailsUpdate personalDetails={personalDetails} />
      )}
    </>
  );
};

export default Personal;

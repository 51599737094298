import React, { useState, useEffect } from 'react';

import SettingsContext from './SettingsContext';

const settingsKey = 'editorSettings';

const saveSettings = (settings: IEditorSettings) => {
  window.localStorage.setItem(settingsKey, JSON.stringify(settings));
};

const loadSettings = (): IEditorSettings => {
  const settings = window.localStorage.getItem(settingsKey);

  if (settings) {
    return JSON.parse(settings);
  }
  return {
    theme: 'vs-light',
    fontSize: 14,
  };
};

/**
 * Provider for monaco editor settings. This component is also responsible for
 * loading/storing the preferences to/from localStorage
 * @param param0
 * @returns
 */
const SettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState<IEditorSettings>(
    loadSettings()
  );

  useEffect(() => {
    saveSettings(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(settings)]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import useTestDetail from 'business/organizer/assessment/contexts/test-detail/useTestDetail';
import CustomDrawer from 'business/organizer/common/components/CustomDrawer';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import QuestionCreateUpdatePage from 'business/organizer/library/pages/question-create-update/QuestionCreateUpdatePage';
import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import useQuestionDrawer from 'business/organizer/library/contexts/question-drawer/useQuestionDrawer';

const CreateCustomQuestionDrawer = () => {
  const { questionDrawer, setQuestionDrawer } = useQuestionDrawer();

  const { testParams } = useTestDetail();
  const { setTestId } = useTestId();
  const { url } = useCurrentUrl();

  useEffect(() => {
    setTestId(parseInt(testParams.testId));
    return () => {
      setTestId(null);
    };
  }, [setTestId, testParams.testId]);

  return (
    <CustomDrawer
      drawer={questionDrawer}
      setDrawer={setQuestionDrawer}
      drawerName="create"
    >
      <Route path={`${url}/my/questions`}>
        <QuestionCreateUpdatePage />
      </Route>
    </CustomDrawer>
  );
};

export default CreateCustomQuestionDrawer;

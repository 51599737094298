import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

import UserContext from 'common/components/get-user/UserContext';
import authService from 'common/services/authService';
import BaseService from 'common/services/baseService';
import {
  GET_USER,
  HIRING_APP_TYPE_PARAM,
  HUBS_APP_TYPE,
} from 'common/utils/constants';
import useCurrentUrl from '../current-url/useCurrentUrl';
import { HUBS_APP_TYPE_PARAM } from 'business/organizer/common/utils/constants';
import useEmailVerification from 'business/pre-subscription/auth/context/email-verification/useEmailVerification';

const baseService = new BaseService();

const UserProvider: React.FC<IUserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IGetUserProps | null>(null);
  const [responseError, setResponseError] =
    useState<AxiosError | null>(null);

  const { isHubsUrl } = useCurrentUrl();
  const { emailVerificationResponse } = useEmailVerification();

  useEffect(() => {
    if (authService.isAuthenticated) {
      baseService
        .get(
          `${GET_USER}&${
            isHubsUrl ||
            (emailVerificationResponse !== null &&
              emailVerificationResponse.app_type === HUBS_APP_TYPE)
              ? HUBS_APP_TYPE_PARAM
              : HIRING_APP_TYPE_PARAM
          }`
        )
        .then((response) => setUser(response.data[0]))
        .catch((error) => setResponseError(error));
    }
  }, [emailVerificationResponse, isHubsUrl]);

  return (
    <UserContext.Provider value={{ user, responseError, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MuiLink from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';

import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';

const SampleCsvDropdown: React.FC<IMenuProps> = ({
  anchorEl,
  ...props
}) => {
  const classes = useStyles();

  return (
    <DropdownMenu anchorEl={anchorEl} {...props}>
      <Box p={2}>
        <Box>
          <Typography variant="subtitle2">
            Bulk Upload CSV Sample Format
          </Typography>
        </Box>
        <Divider />
        <Box pt={2} display="flex" justifyContent="flex-end">
          <Typography variant="body2">
            <MuiLink
              href="/static/images/business/organizer/sample-invites-csv - Sheet1.csv"
              target="_blank"
            >
              Download Sample File
            </MuiLink>
          </Typography>
        </Box>
        <Box mt={3}>
          <TableContainer className={classes.table__sample_format}>
            <Table aria-label="sample emails table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="body2">
                      <b>email</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {['abc@gmail.com', 'bcd@gmail.com'].map((email) => (
                  <TableRow key={email}>
                    <TableCell align="left">
                      <Typography variant="body2">{email}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </DropdownMenu>
  );
};

export default SampleCsvDropdown;

const useStyles = makeStyles((theme) => ({
  table__sample_format: {
    border: '1px solid #ddd',
    boxShadow: 'none',
    '& .MuiTableCell-root': {
      padding: theme.spacing(2),
    },
  },
}));

import React, { useState } from 'react';
import { AxiosResponse } from 'axios';

import TestQuestionsContext from 'business/organizer/assessment/contexts/test-questions/TestQuestionsContext';

const TestQuestionsProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [testQuestions, setTestQuestions] = useState<any | null>(
    null
  );

  const [testQuestionTypes, setTestQuestionTypes] =
    useState<ITestQuestionTypesProps>({
      coding: null,
      challenge: null,
      freeText: null,
      fileUpload: null,
      mcq: null,
      // project: null,
      sql: null,
    });
  const [testQuestionsCount, setTestQuestionsCount] =
    useState<number>(0);
  const [library, setLibrary] = useState(false);

  const [hiddenQuestionResponse, setHiddenQuestionResponse] =
    useState<AxiosResponse | null>(null);

  /**
   * This state is used  to update the question list when question is deleted
   * it can be true or false
   * */
  const [isQuestionDeleted, setIsQuestionDeleted] =
    useState<boolean>(false);

  return (
    <TestQuestionsContext.Provider
      value={{
        testQuestions,
        setTestQuestions,
        testQuestionsCount,
        setTestQuestionsCount,
        library,
        setLibrary,
        testQuestionTypes,
        setTestQuestionTypes,
        setHiddenQuestionResponse,
        hiddenQuestionResponse,
        isQuestionDeleted,
        setIsQuestionDeleted,
      }}
    >
      {children}
    </TestQuestionsContext.Provider>
  );
};

export default TestQuestionsProvider;

import React, { useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ClickAwayListener, makeStyles } from '@material-ui/core';

import FilterIcon from 'business/organizer/library/components/filter/FilterIcon';
import ActiveFilterIcon from 'business/organizer/library/components/filter/ActiveFilterIcon';
import DropdownMenu from 'common/components/header/dropdowns/common/DropdownMenu';
import useDropdown from 'common/components/use-dropdown/useDropdown';
import ListOfFilters from 'business/organizer/library/components/filter/ListOfFilters';

const TableFilterCell: React.FC<ITableFilterCellProps> = ({
  title,
  value,
  onFilter,
  items,
  ...otherProps
}) => {
  const classes = useStyles();
  const { anchorEl, toggleDropdown, handleClose } = useDropdown();

  useEffect(() => {
    if (!!value.length && anchorEl !== null) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TableCell className={classes.tablecell_filter} {...otherProps}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="subtitle2">{title}</Typography>
        <IconButton size="small" onClick={toggleDropdown}>
          {!!value?.length ? <ActiveFilterIcon /> : <FilterIcon />}
        </IconButton>
        <DropdownMenu anchorEl={anchorEl} handleClose={handleClose}>
          <ClickAwayListener onClickAway={handleClose}>
            <Box margin={2}>
              <ListOfFilters
                data={items}
                state={value}
                handleChange={onFilter}
              />
            </Box>
          </ClickAwayListener>
        </DropdownMenu>
      </Box>
    </TableCell>
  );
};

export default TableFilterCell;

const useStyles = makeStyles((theme) => ({
  tablecell_filter: {
    '& .MuiTypography-subtitle2': {
      fontWeight: theme.spacing(150),
    },
  },
}));

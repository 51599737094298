import React from 'react';

const ActiveFilterIcon = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99997 19V11.5517L15.7349 5.2069C15.8202 5.11284 15.8875 5.00095 15.933 4.87778C15.9785 4.75461 16.0013 4.62266 15.9999 4.48966V3.55172C15.9999 3.4054 15.9473 3.26506 15.8535 3.1616C15.7597 3.05813 15.6326 3 15.4999 3H0.499998C0.36739 3 0.240214 3.05813 0.146446 3.1616C0.0526782 3.26506 0 3.4054 0 3.55172V4.4731C9.34905e-05 4.60341 0.0235569 4.73241 0.0690382 4.85267C0.114519 4.97293 0.181119 5.08207 0.264999 5.17379L5.99998 11.5959V17.2234L9.99997 19Z"
        fill="#666666"
      />
      <circle cx="17.5" cy="3.5" r="3.5" fill="#44924C" />
    </svg>
  );
};

export default ActiveFilterIcon;

import React, { useState, useRef, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import QuestionBase from 'business/organizer/library/components/question-create-update/common/QuestionBase';
import { validationSchema } from 'business/organizer/library/components/question-create-update/mcq/utils/validationSchema';
import McqFormActions from 'business/organizer/library/components/question-create-update/mcq/McqFormActions';
import RichTextField from 'common/components/form/RichTextField';
import MyInput from 'common/components/form/MyInput';
import FieldLabel from 'business/organizer/library/components/question-create-update/common/FieldLabel';
import SelectField from 'common/components/form/SelectField';
import QuestionTagField from 'business/organizer/library/components/question-create-update/common/QuestionTagField';
import { difficultyItems } from 'business/organizer/library/components/question-create-update/free-text/utils/constants';
import ChoicesBase from 'business/organizer/library/components/question-create-update/mcq/ChoicesBase';
import Choices from 'business/organizer/library/components/question-create-update/mcq/Choices';
import useMcq from 'business/organizer/library/contexts/mcq/useMcq';
import useAlert from 'common/components/alert-provider/useAlert';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import { MY_LIBRARY_QUESTION_API_URL } from 'business/organizer/library/utils/constants';
import { getTagsList } from 'business/organizer/library/utils/getTagsList';
import {
  hasInvalidAnswers,
  hasInvalidChoices,
} from 'business/organizer/library/components/question-create-update/mcq/utils/helpers';
import useLibrary from 'business/organizer/library/contexts/library/useLibrary';
import { UPDATE_LIBRARY } from 'business/organizer/library/contexts/library/libraryActionTypes';
import useTestId from 'business/organizer/assessment/contexts/get-testId/useTestId';
import useAddQuestionsToTest from 'business/organizer/library/contexts/add-questions-to-test/useAddQuestionsToTest';
import { hasMessageProperty } from 'common/utils/errors';

const libraryService = new LibraryService();

const McqPage: React.FC<IMcqPageProps> = ({ drawerName, mcq }) => {
  const { enqueueAlert } = useAlert();

  const { testId } = useTestId();
  const { dispatch } = useLibrary();
  const { handleAddQuestionsToTest } = useAddQuestionsToTest();

  const [deletedChoices, setDeletedChoices] = useState<number[]>([]);

  const { setMcqResponse, setMcqChoices, mcqChoices, mcqResponse } =
    useMcq();

  const onSetDeletedChoices = (id: number) => {
    setDeletedChoices((deletedChoices) => [
      ...(deletedChoices || []),
      id,
    ]);
  };

  const question = mcq?.question;
  const isUpdate = useRef(!!question);

  const initialState: IMcqInitialStateProps = {
    name: question?.name || '',
    description: question?.description || '',
    has_multiple_correct_answers:
      question?.has_multiple_correct_answers || false,
    choices: [
      { content: '', is_correct_choice: false },
      { content: '', is_correct_choice: false },
    ],
    difficulty: question?.difficulty || '',
    score: JSON.stringify(question?.score) || '',
    time_duration: JSON.stringify(question?.time_duration) || '',
    tags: mcq?.tags || [],
  };

  const handleSubmit = async (data: IMcqInitialStateProps) => {
    try {
      const {
        choices,
        name,
        description,
        difficulty,
        score,
        time_duration,
        has_multiple_correct_answers,
        tags,
      } = data;

      if (
        hasInvalidChoices(choices) ||
        hasInvalidAnswers(choices, has_multiple_correct_answers)
      ) {
        return;
      }

      const questionData: { [key: string]: {} } = {
        type: 'mcq',
        tags: getTagsList(tags),
        question: {
          name,
          time_duration,
          difficulty,
          score,
          has_multiple_correct_answers,
        },
      };

      if (description) {
        questionData.question = {
          ...questionData.question,
          description,
        };
      }

      const response =
        !!mcq || !!mcqResponse
          ? await libraryService.patch(
              mcq?.url || (mcqResponse?.url as string),
              questionData
            )
          : await libraryService.createQuestion(
              MY_LIBRARY_QUESTION_API_URL,
              questionData
            );

      const choicesUrl = response?.data?.question.choices_url;
      let deleteResponse: AxiosResponse | null = null;
      if (!!deletedChoices.length) {
        deleteResponse = await libraryService.delete(choicesUrl, {
          choices: deletedChoices,
        });
        // reset delete state
        setDeletedChoices([]);
      }

      const updateChoices = choices.filter(({ id }) => id);
      let updateResponse: AxiosResponse | null = null;
      if (!!updateChoices.length) {
        updateResponse = await libraryService.patch(
          choicesUrl,
          updateChoices
        );
      }

      const createChoices = choices.filter(({ id }) => !id);
      let createResponse: AxiosResponse | null = null;
      if (!!createChoices.length) {
        createResponse = await libraryService.post(
          choicesUrl,
          createChoices
        );
      }
      /**
       * we are updating state at the bottom because we want
       * to make get request to choices when we do all other actions like
       * patch, delete, post
       * */
      setMcqResponse(response?.data);
      setMcqChoices((mcqChoices) => ({
        ...mcqChoices,
        remove: deleteResponse?.data,
      }));
      setMcqChoices((mcqChoices) => ({
        ...mcqChoices,
        update: updateResponse?.data,
      }));
      setMcqChoices((mcqChoices) => ({
        ...mcqChoices,
        create: createResponse?.data,
      }));
      if (!!testId && !mcq) {
        handleAddQuestionsToTest(testId, response?.data?.id);
      }
      dispatch({ type: UPDATE_LIBRARY, payload: { list: 'my' } });

      enqueueAlert('Question is created successfully.', {
        alertProps: { severity: 'success' },
      });
    } catch (error) {
      enqueueAlert(
        `${
          hasMessageProperty(error)
            ? error.message
            : '' ||
              `Something went wrong while ${
                mcq !== null ? 'updating' : 'adding'
              } question. Please try again after sometime`
        }`,
        {
          alertProps: { severity: 'error' },
        }
      );
    }
  };

  // reset context state(clear the effects)
  useEffect(() => {
    return () => {
      setMcqChoices({
        create: null,
        update: null,
        remove: null,
      });
      setMcqResponse(null);
    };
  }, [setMcqChoices, setMcqResponse]);

  return (
    <QuestionBase name="Multiple Choice" drawerProps={{ drawerName }}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <FieldLabel title="Question" isRequired={true}>
            <MyInput
              name="name"
              placeholder="Question"
              aria-label="question name"
            />
          </FieldLabel>
          <FieldLabel title="Question Description" isRequired={false}>
            <RichTextField name="description" />
          </FieldLabel>
          {!!mcqChoices?.create ||
          !!mcqChoices?.update ||
          !!mcqChoices?.remove ||
          !!mcq ? (
            <Choices
              choicesUrl={question?.choices_url}
              onSetDeletedChoices={onSetDeletedChoices}
            />
          ) : (
            <ChoicesBase onSetDeletedChoices={onSetDeletedChoices} />
          )}
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <FieldLabel
                title="Maximum Score"
                isRequired={false}
                tooltip
                tooltipTitle="Add maximum score for this question"
              >
                <MyInput
                  placeholder="Score"
                  name="score"
                  type="number"
                  aria-label="question score"
                />
              </FieldLabel>
            </Grid>
            <Grid item xs={4}>
              <FieldLabel
                title="Max time duration"
                isRequired={false}
                tooltip
                tooltipTitle="Mention maximum time duration to solve this question"
              >
                <MyInput
                  placeholder="Minutes"
                  name="time_duration"
                  type="number"
                  aria-label="time duration"
                />
              </FieldLabel>
            </Grid>
            <Grid item xs={4}>
              <FieldLabel
                title="Difficulty level"
                isRequired={false}
                tooltip
                tooltipTitle="For your reference"
              >
                <SelectField
                  name="difficulty"
                  placeholder="Level of Difficulty"
                  items={difficultyItems}
                  aria-label="difficulty level"
                />
              </FieldLabel>
            </Grid>
          </Grid>
          <QuestionTagField />
          <Box display="flex" justifyContent="flex-end" py={8}>
            <McqFormActions isUpdate={isUpdate} testId={testId} />
          </Box>
        </Form>
      </Formik>
    </QuestionBase>
  );
};

export default McqPage;

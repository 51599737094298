import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

import useSubscription from 'business/organizer/assessment/custom-hooks/useSubscription';
import InlineTextButton from 'common/components/utility-components/InlineTextButton';
import React from 'react';

const HasPermissionToInterviewPlan: React.FC<{
  children: React.ReactNode;
  pageName: string;
}> = ({ children, pageName }) => {
  const { isInterviewPlan, organizer } = useSubscription();

  return (
    <>
      {organizer &&
        (isInterviewPlan ? (
          <Box p={4}>
            <Typography variant="h5">
              Your subscription does not have {pageName} feature
              enabled to get access upgrade your plan{' '}
              <MuiLink href="/billing">here</MuiLink> or
              <InlineTextButton
                // Open chat when clicked here
                // @ts-ignore
                onClick={() => $crisp.push(['do', 'chat:open'])}
                variant="h5"
              >
                {' '}
                chat with us.
              </InlineTextButton>
            </Typography>
          </Box>
        ) : (
          children
        ))}
    </>
  );
};

export default HasPermissionToInterviewPlan;

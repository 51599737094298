import React from 'react';
import { Switch, Route } from 'react-router-dom';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import RenderQuestionPreview from 'business/organizer/library/pages/question-preview/RenderQuestionPreview';
import useToggleDrawer from 'business/organizer/library/custom-hooks/useToggleDrawer';
import CloseDrawerIcon from 'business/organizer/library/components/question-create-update/common/CloseDrawerIcon';

const QuestionPreviewPage: React.FC<IQuestionPreviewPageProps> = ({
  questionUrl,
  setPreviewDrawer,
}) => {
  const { url } = useCurrentUrl();
  const { toggleDrawer } = useToggleDrawer();

  return (
    <>
      <CloseDrawerIcon
        closeQuestionDrawer={toggleDrawer(
          false,
          setPreviewDrawer,
          'preview'
        )}
      />
      <Switch>
        <Route path={`${url}/:questionType/preview/:questionId`}>
          <RenderQuestionPreview questionUrl={questionUrl} />
        </Route>
      </Switch>
    </>
  );
};

export default QuestionPreviewPage;

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface IImage {
  alt: string,
  src: string,
}

const LazyImage: React.FC<IImage> = ({ alt, src, ...otherProps }) => {
  return (
    <LazyLoadImage
      alt={alt}
      src={src} // use normal <img> attributes as props
      {...otherProps} />
  )
}

export default LazyImage;
import { useLayoutEffect, useMemo, useRef } from 'react';
import debounce from 'common/utils/debounce';

const useDebounce = (callback: Function, delay: number) => {
  const callbackRef = useRef(callback);

  // https://epicreact.dev/how-react-uses-closures-to-avoid-bugs/
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(
    () => debounce((...args) => callbackRef.current(...args), delay),
    [delay]
  );
};

export default useDebounce;

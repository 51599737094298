import { useEffect } from 'react';

import useAsync from 'common/custom-hooks/useAsync';
import { LibraryService } from 'business/organizer/library/services/libraryService';
import { PROGRAMMING_LANGUAGES_API_URL } from 'business/organizer/library/utils/constants';

const libraryService = new LibraryService();

const useProgrammingLanguages = (): Omit<
  IUseAsyncReturnProps<IProgrammingLanguage[]>,
  'run'
> => {
  const {
    run,
    data,
    ...otheProps
  }: IUseAsyncReturnProps<IProgrammingLanguage[]> = useAsync();

  useEffect(() => {
    run(
      libraryService.getProgrammingLanguages(
        PROGRAMMING_LANGUAGES_API_URL
      )
    );
  }, [run]);

  return { data, ...otheProps };
};

export default useProgrammingLanguages;

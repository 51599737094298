import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import TestInfo from 'business/organizer/assessment/components/common/TestInfo';
import IncompleteLabel from 'business/organizer/common/components/IncompleteLabel';
import TestViewPermissionChecker from './TestViewPermissionChecker';
import TestListItemButtons from './TestListItemButtons';
import HubsCandidateTestListItemButtons from 'hubs-candidate/common/components/TestListItemButtons';

const TestListItem: React.FC<ITestListItemProps> = ({
  testItem,
  isHubsCandidate = false,
}) => {
  const { url } = useCurrentUrl();
  const classes = useStyles();

  return (
    <Box className={classes.box__list_item} p={3}>
      <Grid container>
        <Grid item xs={10} className={classes.grid__test_name}>
          <Box display="flex" alignItems="center">
            {isHubsCandidate ? (
              <Typography variant="h5">{testItem.name}</Typography>
            ) : (
              <Link to={`${url}/${testItem.id}`}>
                <Typography
                  variant="h5"
                  className={classes.typography__test_name}
                >
                  {testItem.name}
                </Typography>
              </Link>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="flex-end">
            {!isHubsCandidate && (
              <TestViewPermissionChecker testItem={testItem} />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={2.5}>
            <TestInfo
              total_score={testItem?.total_score}
              assessment_duration={testItem?.assessment_duration}
              start_datetime={testItem?.start_datetime}
              end_datetime={testItem?.end_datetime}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent={
              !testItem?.is_complete ? 'space-between' : 'flex-end'
            }
            gridColumnGap={15}
            alignItems="center"
            pr={2}
            pt={4}
          >
            {!testItem?.is_complete && (
              <Box>
                <IncompleteLabel
                  label="Incomplete questions"
                  hint="There are one or more incomplete questions in this test. Candidates might face problems while answering those. Please complete those questions by opening the test"
                />
              </Box>
            )}
            {isHubsCandidate ? (
              <HubsCandidateTestListItemButtons testItem={testItem} />
            ) : (
              testItem?.status !== 'draft' && (
                <TestListItemButtons testItem={testItem} />
              )
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TestListItem;

const useStyles = makeStyles((theme) => ({
  box__list_item: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #DDE6ED',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#233D4D',
    },
  },
  grid__test_name: {
    display: 'flex',
    alignItems: 'center',
  },
  icon_button__question_action: {
    padding: '9px',
  },
  typography_incomplete_test: {
    display: 'inline-flex',
    padding: theme.spacing(0, 1.5),
    borderRadius: theme.spacing(1),
    background: '#FBEED0',
    alignItems: 'center',
  },
  icon_incomplete_test: {
    color: '#666666',
    paddingLeft: theme.spacing(1),
  },
  typography__test_name: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

import React from 'react';
import Typography from '@material-ui/core/Typography';

import RoleItemStyles from 'business/organizer/assessment/components/assessment-tests/test-list/choose-role/RoleItemStyles';
import useTests from 'business/organizer/assessment/contexts/assessment-tests/useTests';

const RoleItem: React.FC<IRoleItemProps> = ({ id, name }) => {
  const { selectedRole, handleSelectedRole } = useTests();
  return (
    <RoleItemStyles
      handleClick={() => handleSelectedRole(id)}
      styleCondition={!!selectedRole && selectedRole === id}
    >
      <Typography variant="h6" align="center" color="inherit">
        {name}
      </Typography>
    </RoleItemStyles>
  );
};

export default RoleItem;

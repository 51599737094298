import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import useCurrentUrl from 'common/components/current-url/useCurrentUrl';
import GenericNavbar from 'business/organizer/common/components/GenericNavbar';
import CreateTest from 'business/organizer/assessment/components/assessment-tests/test-list/CreateTest';
import TestList from 'business/organizer/assessment/components/assessment-tests/test-list/TestList';

const TestListPage: React.FC<IHubsCandidateTestListPageProps> = ({
  testsCount,
  isHubsCandidate,
}) => {
  const { url } = useCurrentUrl();
  const classes = useStyles();

  const tabs = useMemo(() => {
    let tabs = [
      {
        id: 0,
        label: 'Active',
        route: `${url}/active`,
        count:
          testsCount && typeof testsCount.active === 'number'
            ? testsCount.active
            : 0,
        badge: true,
      },
      {
        id: 2,
        label: 'Archive',
        route: `${url}/archive`,
        count:
          testsCount && typeof testsCount.archive === 'number'
            ? testsCount.archive
            : 0,
        badge: true,
      },
    ];

    if (!isHubsCandidate) {
      tabs.push({
        id: 1,
        label: 'Draft',
        route: `${url}/draft`,
        count:
          testsCount && typeof testsCount.draft === 'number'
            ? testsCount.draft
            : 0,
        badge: true,
      });

      // sort the tabs list to show them in correct order
      tabs.sort((prev, next) => prev.id - next.id);
    }

    return tabs;
  }, [url, testsCount, isHubsCandidate]);

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.container__header}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <GenericNavbar tabs={tabs} />
          </Box>
          {!isHubsCandidate && (
            <Box>
              <CreateTest />
            </Box>
          )}
        </Box>
      </Container>
      <Box display="flex" width="100%" pt={8} pb={10} maxWidth={1000}>
        <Container maxWidth={false}>
          <Switch>
            {/* Why keys? https://stackoverflow.com/questions/49001001/using-same-component-for-different-route-path-in-react-router-v4 */}
            <Route key="active" path={`${url}/active`}>
              <TestList
                list="active"
                isHubsCandidate={isHubsCandidate}
              />
            </Route>
            <Route key="draft" path={`${url}/draft`}>
              <TestList
                list="draft"
                isHubsCandidate={isHubsCandidate}
              />
            </Route>
            <Route key="archive" path={`${url}/archive`}>
              <TestList
                list="archive"
                isHubsCandidate={isHubsCandidate}
              />
            </Route>
            <Redirect from={url} to={`${url}/active`} />
          </Switch>
        </Container>
      </Box>
    </>
  );
};

export default TestListPage;

const useStyles = makeStyles((theme) => ({
  container__header: {
    background: '#f1f6f9',
    paddingTop: theme.spacing(2),
    boxShadow: '0px 4px 8px rgba(221, 230, 237, 0.5)',
    position: 'sticky',
    top: 64,
    zIndex: 9,
  },
}));
